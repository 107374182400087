import { Stack, Rating, RatingSize, Image, ImageFit } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';
import { GetReviews } from '../services/DBConnect';
import { IReview, TReview } from '../constants/types';
import HeaderInfoComponent from './headerInfoComponent';
import GlobalProperties from '../helpers/GlobalProperties';

export interface ICarouselComponentProps {
    isDev: boolean;
    animationsEnabled: boolean;
}

export interface ICarouselComponentState {
    reviews: TReview[]
    fading: string;
    average: number;
    count: number;
    percTillNext: number;
    startSwipe: number;
    endSwipe: number;
};


export default class CarouselComponent extends React.Component<ICarouselComponentProps, ICarouselComponentState> {

    constructor(props: ICarouselComponentProps) {
        super(props);
        this.setOrder = this.setOrder.bind(this);
        this.nextReview = this.nextReview.bind(this);
        this.heartBeat = this.heartBeat.bind(this);

        this.state = {
            reviews : [],
            fading: styles.elementToFadeIn,
            average: 10,
            count: 0,
            percTillNext: 0,
            startSwipe: 0,
            endSwipe: 0
        }

        this.heartBeat();
    }

    componentDidMount(): void {
        GetReviews(this.props.isDev).then(rev => {
            let reviews: IReview[] = [];
            rev.reviews.sort((a,b) => new Date(a.date) > new Date(b.date) ? -1 : 1);
            GlobalProperties.CAROUSEL_PROMO_SET_ID ? reviews.push({id:-2, comment:"", date:null, name:"", stars:10}) 
                                                   : reviews.push(rev.reviews[rev.reviews.length-1]);
            reviews.push({id:-1, comment:"", date:null, name:"", stars:10, child: <HeaderInfoComponent />});
            
            for (let i=0,l=rev.reviews.length-1; i<l; i++) {
                reviews.push(rev.reviews[i]);
            }

            let average = Math.round(rev.average*10) / 10;
            this.setState({reviews: reviews, average: average, count: rev.count});
        });
    }

    componentDidUpdate(prevProps: Readonly<ICarouselComponentProps>, prevState: Readonly<ICarouselComponentState>, snapshot?: any): void {
        if (this.props.animationsEnabled != prevProps.animationsEnabled) {
            if (this.props.animationsEnabled) {
                this.setState({percTillNext: 0}, () => {
                    this.heartBeat();
                });
            }
        }
    }

    heartBeat() {
        if (this.props.animationsEnabled) {
            this.setState({percTillNext: this.state.percTillNext + 10});
            setTimeout(() => {
                if (this.state.percTillNext + 10 >= 100) {
                    this.setOrder();
                } else {
                    this.heartBeat();
                }
            }, 1000);
        }
    }

    setOrder() {
        if (this.props.animationsEnabled) {
            let reviews = this.state.reviews;
            let newreviews : TReview[] = [];
            for (let i=1; i<reviews.length; i++) {
                newreviews.push(reviews[i]);
            }
            newreviews.push(reviews[0]);
            this.setState({fading:styles.moveLeft});
            setTimeout(() => {
                this.setState({ reviews: newreviews, fading: styles.elementToFadeIn, percTillNext: 0 });
                this.heartBeat();
            }, 1000);
        }
    }

    nextReview() {
        if (this.state.fading === styles.elementToFadeIn) {
            this.setState({fading:styles.moveLeft, percTillNext: 0});
            setTimeout(() => {
                this.setState({fading: styles.elementToFadeIn, percTillNext: 0, reviews: this.cycleNextReview(this.state.reviews)});
            }, 1000);
        }
    }

    previousReview() {
        if (this.state.fading === styles.elementToFadeIn) {
            this.setState({fading:styles.moveRight, percTillNext: 0});
            setTimeout(() => {
                this.setState({fading: styles.elementToFadeIn, percTillNext: 0, reviews: this.cycleNextReview(this.state.reviews, true)});
            }, 1000);
        }
    }

    cycleNextReview(reviews: IReview[], previous: boolean = false) : IReview[] {
        let newreviews : IReview[] = [];
        if (previous) {
            newreviews.push(reviews[reviews.length-1]);
            for (let i=0; i<reviews.length-1; i++) {
                newreviews.push(reviews[i]);
            }
        } else {
            for (let i=1; i<reviews.length; i++) {
                newreviews.push(reviews[i]);
            }
            newreviews.push(reviews[0]);
        }
        return newreviews;
    }

    swipeEnd(e) {
        if (this.state.startSwipe != -1 && this.state.endSwipe != -1) {
            if ((this.state.endSwipe - this.state.startSwipe) > 40) {
                this.previousReview();
            } else if ((this.state.startSwipe - this.state.endSwipe) > 40) {
                this.nextReview();
            }
            this.setState({startSwipe: -1, endSwipe: -1});
        }
    }

    public render(): React.ReactElement<ICarouselComponentProps> {
        return (
            <div style={{maxWidth:"100%"}}>
                <Stack horizontal>
                    <Stack verticalAlign='center' horizontalAlign='start'>
                        <Image {...{imageFit: ImageFit.centerContain,
                                    className: styles.previousReview,
                                    src: process.env.PUBLIC_URL + `/assets/previoussmall.webp`,
                                    styles: props => ({ root: { margin: 0, zIndex: 1} }),
                                    onClick: () => { this.previousReview(); }
                                }} alt="" />
                    </Stack>
                    <div style={{maxWidth:"100%",paddingLeft:5}} 
                         onTouchStart={(e) => { this.setState({startSwipe: e.targetTouches[0].clientX}); }}
                         onTouchMove={(e) => { this.setState({endSwipe: e.targetTouches[0].clientX}); }}
                         onTouchEnd={this.swipeEnd.bind(this)}>
                        <ReviewComponent reviews={this.state.reviews} average={this.state.average} fading={this.state.fading} count={this.state.count}/>
                    </div>
                    <Stack verticalAlign='center' horizontalAlign='end'>
                        <div className={styles.progressbar} style={this.props.animationsEnabled ? {background:`radial-gradient(closest-side, transparent, transparent 80% 100%), conic-gradient(lightblue ${this.state.percTillNext}%, transparent 0)`} : {}}>
                            <Image {...{imageFit: ImageFit.centerContain,
                                        className: styles.nextReview,
                                        src: process.env.PUBLIC_URL + `/assets/nextsmall.webp`,
                                        styles: props => ({ root: { margin: 0, zIndex: 1} }),
                                        onClick: () => { this.nextReview(); }
                                    }} alt="" />
                        </div>
                    </Stack>
                </Stack>
            </div>
        );
    }
}

class ReviewComponent extends React.Component<any, any> { //TODO
    constructor(props: ICarouselComponentProps) {
        super(props);
    }

    public render(): React.ReactElement<ICarouselComponentProps> {

        return (
            <div>
                <Stack horizontal tokens={{childrenGap: 5, padding: 5}} style={{marginLeft:-305,overflow:"hidden"}}>
                    {this.props.reviews.map(review => {
                        return review.child ? 
                        <Stack horizontalAlign='center' horizontal={false} className={`${styles.aPanel} ${this.props.fading} ${styles.reviewComponent}`} style={{overflow:"hidden"}}>
                            {review.child}
                        </Stack> :  review.id === -2 ?
                                        <Stack horizontal={false} horizontalAlign='center' verticalAlign='end' style={{paddingBottom:0}} className={`${styles.aPanel} ${this.props.fading} ${styles.reviewComponent}`}>
                                            <div className={styles.carouselPromo}></div>
                                        </Stack>
                                    :  <Stack className={`${styles.aPanel} ${this.props.fading} ${styles.reviewComponent}`} style={{overflow:"hidden",paddingLeft:5}}>
                                            <Stack horizontal horizontalAlign='start' style={{height:"100%"}}>
                                                <div className={styles.bPanel} style={{whiteSpace:"nowrap",height:25, backgroundColor:"lightgray"}}>
                                                    <Stack horizontal>
                                                        <span style={{color:"rgb(110,110,110)",float:"right"}}>{review.stars}</span>
                                                        <span style={{fontSize:"smaller"}}>/10&nbsp;</span>
                                                    </Stack>
                                                </div>
                                                <p className={styles.reviewText}>{review.comment}</p>
                                            </Stack>
                                            <Stack horizontal verticalAlign='end' horizontalAlign='end'>
                                                {/* <b style={{color:"black"}}>{review.name}</b>, {(new Date(review.date)).toLocaleDateString()} */}
                                                <i style={{color:"darkgray",paddingRight:"5px"}}>{review.name}</i>
                                            </Stack>
                                        </Stack>
                    })}
                </Stack>
            </div>
        );
    }
}