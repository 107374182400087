import { Stack, Image, Label } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';
import { TSet } from '../constants/types';
import { NavLink } from 'react-router-dom';

export interface ISetsMenuComponentProps {
    sets: TSet[];
    onSetSelectedSet: Function;
    selectedSetKey: number;
}

export interface ISetsMenuComponentState {
    
};

export default class SetsMenuComponent extends React.Component<ISetsMenuComponentProps, ISetsMenuComponentState> {
    constructor(props: ISetsMenuComponentProps) {
        super(props);
    }

    public render(): React.ReactElement<ISetsMenuComponentProps> {
        return (
            <div>
                {this.props.sets.sort((a,b) => a.id < b.id ? 1 : -1).map((set,index) => {
                    if (set?.parentId === 136 || set?.id === 136 || set.id === this.props.selectedSetKey) {
                        return  <NavLink to={"/Pokemon Kaarten/"+set.name}>
                                    <div onClick={() => { this.props.onSetSelectedSet(set.id); }} className={`${styles.setLogoContainer} ${this.props.selectedSetKey === set.id ? styles.selectedSetLogoMenu : ""}`}>
                                        <Stack style={{width:"100%"}} horizontalAlign='center' >
                                            {/* <Label className={styles.setLogoMenuCountLabel}>{set.cardcount ? set.cardcount : 0}</Label> */}
                                            <Image className={styles.setLogoMenu} src={process.env.PUBLIC_URL + `/assets/pokemon/${set.id.toString()}/${set.id.toString()}.webp`} alt={set.name} />
                                        </Stack>
                                    </div>
                                </NavLink>
                    }
                })}
            </div>
        );
    }
}