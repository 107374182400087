import { Stack, Image, FontIcon, Label, ImageFit, PrimaryButton } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';

export interface IBannerComponentProps {
    content: JSX.Element;
}

export interface IBannerComponentState {
    moveDown: string;
};
  

export default class BannerComponent extends React.Component<IBannerComponentProps, IBannerComponentState> {
    constructor(props: IBannerComponentProps) {
        super(props);
        this.state = {
            moveDown: ""
        };
        setTimeout(() => {
            this.setState({moveDown: styles.moveDown});
        }, 200);
    }

    public render(): React.ReactElement<IBannerComponentProps> {
        return (   
            <Stack>
                <Stack className={`${styles.aPanel} ${this.state.moveDown}`} style={{marginTop:-150,backgroundColor:"white"}}>
                    {this.props.content}
                </Stack>
            </Stack>
        );
    }
}