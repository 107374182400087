import { IReview, TProduct, TReviews, TSet, TSetAmmounts } from "../constants/types";

export function Init(getAvailable: boolean=true, isDev : boolean = false) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }
    return fetch(`${hostUrl}/Init?getAvailable=${getAvailable}`, {
        method: 'GET',
    }).then((response) => response.json()).then((data) => data);
}

export function GetConfiguration(isDev : boolean = false) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }
    return fetch(`${hostUrl}/GetConfiguration`, {
        method: 'GET',
    }).then((response) => response.json()).then((data) => data);
}

export function GetConnect(isMobile: boolean, isDev : boolean = false) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }
    return fetch(`${hostUrl}/GetConnect?isMobile=${isMobile}`, {
        method: 'GET',
    });
}

export function  GetSets(isDev : boolean = false, onlyAvailable : boolean = false) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }
    return fetch(`${hostUrl}/sets?onlyAvailable=${onlyAvailable}`, {
        method: 'GET',
    }).then((response) => response.json()).then((data) => data as TSet[]);
}

export function GetFiatPrices(isDev : boolean = false) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }
    return fetch(`${hostUrl}/FiatPrices`, {
        method: 'GET',
        mode: 'cors',
    }).then((response) => response.json()).then((data) => data as number[] );
}

export function GetBTCPrice(isDev : boolean = false) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }
    return fetch(`${hostUrl}/BTCPrice`, {
        method: 'GET',
        mode: 'cors',
    }).then((response) => response.json()).then((data) => data as number );
}

export function GetEURPrice(isDev : boolean = false) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }
    return fetch(`${hostUrl}/EURPrice`, {
        method: 'GET',
        mode: 'cors',
    }).then((response) => response.json()).then((data) => data as number );
}

export function GetUSDPrice(isDev : boolean = false) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }
    return fetch(`${hostUrl}/USDPrice`, {
        method: 'GET',
        mode: 'cors',
    }).then((response) => response.json()).then((data) => data as number );
}

export function GetReviews(isDev: boolean = false) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }

    return fetch(`${hostUrl}/GetReviews`, {
        method: 'GET',
    }).then((response) => response.json()).then((data) => data as TReviews );
}

export function CardAllUpdates(isDev : boolean = false) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }

    return fetch(`${hostUrl}/CardUpdates`, {
        method: 'GET',
    }).then((response) => response.json()).then((data) => data as TProduct[] );
}

export function CardUpdates(setname:string, isDev : boolean = false) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }

    return fetch(`${hostUrl}/CardUpdates?setname=${encodeURIComponent(setname)}`, {
        method: 'GET',
    }).then((response) => response.json()).then((data) => data as TProduct[] );
}

export function SearchCards(searchTerm:string, getAvailableOnly:boolean, isDev : boolean = false) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }

    console.log(`${hostUrl}/SearchCards?searchTerm=${encodeURIComponent(searchTerm)}&getAvailable=${!getAvailableOnly}`);

    return fetch(`${hostUrl}/SearchCards?searchTerm=${encodeURIComponent(searchTerm)}&getAvailable=${!getAvailableOnly}`, {
        method: 'GET',
    }).then((response) => response.json()).then((data) => data as TProduct[] );
}

export function GetCard(setname:string, cardname:string, version:string, isDev : boolean = false) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }

    return fetch(`${hostUrl}/card?setname=${encodeURIComponent(setname)}&cardname=${cardname}&version=${version}`, {
        method: 'GET',
    }).then((response) => response.json()).then((data) => data as TProduct[] );
}

export function GetCards(setname:string, getAvailableOnly:boolean, isDev : boolean = false) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }

    return fetch(`${hostUrl}/cards?setname=${encodeURIComponent(setname)}&getAvailable=${!getAvailableOnly}`, {
        method: 'GET',
    }).then((response) => response.json()).then((data) => data as TProduct[] );
}

export function GetProduct(id:number, title:string, isDev : boolean = false) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }

    return fetch(`${hostUrl}/Product`, {
        method: "Post", 
        mode: "cors", 
        headers: {
         "Accept": "application/json",
         "Content-Type": "application/json",
         "Accept-Encoding": "gzip, deflate ,br"
        },
        referrerPolicy: "origin-when-cross-origin", 
        body: JSON.stringify({id: id, title: title}), 
    }).then((response) => response.json()).then((data) => data); 
}

export function GetProducts(type:string, getAvailableOnly:boolean, isDev : boolean = false) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }

    return fetch(`${hostUrl}/Products`, {
        method: "Post", 
        mode: "cors", 
        headers: {
         "Accept": "application/json",
         "Content-Type": "application/json",
         "Accept-Encoding": "gzip, deflate ,br"
        },
        referrerPolicy: "origin-when-cross-origin", 
        body: JSON.stringify({type: type, getAvailable: getAvailableOnly}), 
    }).then((response) => response.json()).then((data) => data); //return data.sort((a,b)=> a.available > b.available ? 1 : -1)
}

export function GetSetCardCount(setname:string, isDev : boolean = false) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }

    return fetch(`${hostUrl}/CardsCount?setname=${encodeURIComponent(setname)}`, {
        method: 'GET',
    }).then((response) => response.json()).then((data) => data as number );
}

export function GetCardsCountAll(isDev : boolean = false, minlimit : number = 0) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }

    return fetch(`${hostUrl}/CardsCountAll?minlimit=${minlimit}`, {
        method: 'GET',
    }).then((response) => response.json()).then((data) => data as TSetAmmounts[]);
}

export function SetAlert(productID:number, setID:number, setName:string, email:string, isDev : boolean = false) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }
    //(int productID, int setID, string setName, string email) 
    return fetch(`${hostUrl}/SetProductAlert?productID=${productID}&setID=${setID}&setName=${setName}&email=${email}`, {
        method: 'GET',
    }).then((response) => {return true;}).catch(err => {
        return false;
    });
}