import { Stack, Label, FontIcon, IStackTokens } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';
import { TProduct, TSize } from '../constants/types';
import Product from './productComponent';

export interface IboostersComponentProps {
    satprice: number;
    eurprice: number;
    windowSize: TSize;
    onImageClick: Function;
    isdev: boolean;
    onBuyClick: Function;
    products : TProduct[];
    showMinimize?: boolean;
    SetSelectedProductForAlert?: Function;
    AlertCoachmarkVisible: boolean;
}

export interface IboostersComponentState {
    show : boolean;
};
  

export default class Boosters extends React.Component<IboostersComponentProps, IboostersComponentState> {
    private productTokens: IStackTokens = {
        childrenGap: 10,
        padding: 5
    };

    constructor(props: IboostersComponentProps) {
        super(props);

        this.state = {
            show: true
        };
    }

    public render(): React.ReactElement<IboostersComponentProps> {
        return (   
            <Stack className={styles.mainSection}>
                <Stack horizontal style={{width:"100%", cursor:"pointer"}} onClick={() => {this.setState({show: !this.state.show})}}>
                    <Stack horizontal className={styles.boosterLabelMain}>
                        <Label className={styles.boosterLabel} >Boosters</Label>
                    </Stack>
                    {this.props.showMinimize && <Stack horizontal horizontalAlign='end' style={{width:"100%",padding:5}} >
                        <FontIcon aria-label="Hide" 
                                iconName={this.state.show ? "ChevronUp" : "ChevronDown"}
                                className={styles.hideBoosterImage} />
                    </Stack>}
                </Stack>
                <hr className={styles.mainhr} />
                {this.props.products.length ? this.state.show && <Stack horizontal wrap tokens={this.productTokens} horizontalAlign='start' style={{maxWidth:"",padding:0}}>
                                        {this.props.products.map(product => {
                                            return <div style={{maxWidth:this.props.windowSize.x < 960 ? 140 : ""}}>
                                                        <Product hideSwipe={this.props.windowSize.x < 800 ? false : true} 
                                                                 SetSelectedProductForAlert={this.props.SetSelectedProductForAlert}
                                                                 AlertCoachmarkVisible={this.props.AlertCoachmarkVisible}
                                                                 onBuyClick={this.props.onBuyClick} 
                                                                 imageSize={{x: this.props.windowSize.x <= 960 ? 136 : 144, y: 200}} 
                                                                 eurprice={this.props.eurprice} 
                                                                 satprice={this.props.satprice} 
                                                                 onImageClick={this.props.onImageClick} 
                                                                 product={product} />
                                                    </div>
                                        })}
                                    </Stack>
                                    : <Stack horizontal horizontalAlign='center'><Label>Uitverkocht</Label></Stack>}
            </Stack>
        );
    }
}