import { Stack, Image, FontIcon, Label, IStackTokens, ImageFit } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';
import GlobalProperties from '../helpers/GlobalProperties';
import { NavLink } from 'react-router-dom';

export interface IFooterComponentProps {
}

export interface IFooterComponentState {
};
  

export default class Footer extends React.Component<IFooterComponentProps, IFooterComponentState> {
    private footerTokens: IStackTokens = { childrenGap: '10' };
    
    constructor(props: IFooterComponentProps) {
        super(props);
    }

    public render(): React.ReactElement<IFooterComponentProps> {
        return (   
            <div className={`${styles.divTable}`}>
                <div className={styles.divTableRow}>
                    <div className={`${styles.divTableCell}`}>
                        <div className={styles.footerMain}>
                            <Stack horizontal horizontalAlign='center' tokens={this.footerTokens} className={styles.footerContact}>
                                <div style={{display:"table"}}>
                                    <div style={{display:"table-cell",verticalAlign:"middle"}}>
                                        <div>
                                            <Label>Pokemoncollectie.nl - Contact : </Label>
                                        </div>
                                    </div>
                                </div>
                                <a href="mailto:contact@pokemoncollectie.nl" className={styles.contactUrl} >
                                <FontIcon aria-label="Email" iconName="mail" className={styles.contactImage} 
                                        onClick={() => {window.open("mailto:contact@pokemoncollectie.nl");}}/>
                                </a>
                                <Image className={styles.whatsappImage} 
                                    src={process.env.PUBLIC_URL + `/assets/whatsapp2.webp`} 
                                    onClick={() => { window.open("https://wa.me/+31616033524"); }}
                                    alt="" imageFit={ImageFit.contain} /> 
                            </Stack>
                            <Stack horizontal>
                                <Stack style={{whiteSpace:"nowrap", minWidth: "40%", paddingLeft:20}} >
                                    
                                    <span style={{color:"gray",fontSize:"large"}}>Informatie</span>
                                    
                                    {/* <NavLink to='/Algemene Voorwaarden' style={{textDecoration:"none",color:"darkgray",paddingTop:5}}>
                                        <span style={{cursor:"pointer"}} onClick={()=>{ }}>Algemene Voorwaarden</span>
                                    </NavLink> */}
                                    <NavLink to='/Privacybeleid' style={{textDecoration:"none",color:"darkgray",paddingTop:5}}>
                                        <span style={{cursor:"pointer",fontSize:"smaller"}} onClick={()=>{ }}>Privacybeleid</span>
                                    </NavLink>
                                    <NavLink to='/Retourbeleid' style={{textDecoration:"none",color:"darkgray",paddingTop:5}}>
                                        <span style={{cursor:"pointer",fontSize:"smaller"}} onClick={()=>{ }}>Retourbeleid</span>
                                    </NavLink>
                                    <NavLink to='/Over Ons' style={{textDecoration:"none",color:"darkgray",paddingTop:5}}>
                                        <span style={{cursor:"pointer",fontSize:"smaller"}} onClick={()=>{ }}>Over Ons</span>
                                    </NavLink>
                                </Stack>
                            </Stack>
                            
                            <Label className={styles.footerText}>
                                <br/>
                                <br/>
                            </Label>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}