import { Stack, Rating, RatingSize, Image, ImageFit } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';

//TODO move this
export type TCarouselItem = {
    imageUrl?: string;
    quickLinkImageUrl?: string;
    quickLinkOnClick?: Function;
    targetUrl?: string;
    content?: JSX.Element;
}

export interface IMainCarouselComponentProps {
    isDev: boolean;
    animationsEnabled: boolean;
    items: TCarouselItem[];
    maxWidth: number;
    switchSpeed?: number;
}

export interface IMainCarouselComponentState {
    items: TCarouselItem[];
    fading: string;
    count: number;
    percTillNext: number;
    startSwipe: number;
    endSwipe: number;
    switchSpeed: number;
};


export default class MainCarouselComponent extends React.Component<IMainCarouselComponentProps, IMainCarouselComponentState> {

    constructor(props: IMainCarouselComponentProps) {
        super(props);

        let items: TCarouselItem[] = [];
        if (props.items.length) {
            items.push(props.items[props.items.length-1]);
            for (let i=0,l=props.items.length-1; i<l; i++) {
                items.push(props.items[i]);
            }
        }

        this.state = {
            switchSpeed : props.switchSpeed ? props.switchSpeed : 25,
            items : items,
            fading: styles.elementToFadeIn,
            count: props.items.length,
            percTillNext: 0,
            startSwipe: 0,
            endSwipe: 0
        }

        this.heartBeat();
    }

    componentDidUpdate(prevProps: Readonly<IMainCarouselComponentProps>, prevState: Readonly<IMainCarouselComponentState>, snapshot?: any): void {
        if (this.props.animationsEnabled != prevProps.animationsEnabled) {
            if (this.props.animationsEnabled) {
                this.setState({percTillNext: 0}, () => {
                    this.heartBeat();
                });
            }
        }
        if (this.props.maxWidth != prevProps.maxWidth) {
            let items: TCarouselItem[] = [];

            this.state.items.forEach(item => {
                const filtered = this.props.items.filter(f => f.content.props.to === item.content.props.to);
                items.push(filtered[0]);
            });

            this.setState({items:items});
        }
    }

    heartBeat = () => {
        if (this.props.animationsEnabled) {
            this.setState({percTillNext: this.state.percTillNext + this.state.switchSpeed});
            setTimeout(() => {
                if (this.state.percTillNext + this.state.switchSpeed >= 100) {
                    this.setOrder();
                } else {
                    this.heartBeat();
                }
            }, 1000);
        }
    }

    setOrder = () => {
        if (this.props.animationsEnabled) {
            let items = this.state.items;
            let newitems : TCarouselItem[] = [];
            for (let i=1; i<items.length; i++) {
                newitems.push(items[i]);
            }
            newitems.push(items[0]);
            this.setState({fading:styles.mainMoveLeft});
            setTimeout(() => {
                this.setState({ items: newitems, fading: styles.elementToFadeIn, percTillNext: 0 });
                this.heartBeat();
            }, 1000);
        }
    }

    nextItem = () => {
        if (this.state.fading === styles.elementToFadeIn) {
            this.setState({fading:styles.mainMoveLeft, percTillNext: 0});
            setTimeout(() => {
                this.setState({fading: styles.elementToFadeIn, percTillNext: 0, items: this.cycleNextItem(this.state.items)});
            }, 1000);
        }
    }

    previousItem = () => {
        if (this.state.fading === styles.elementToFadeIn) {
            this.setState({fading:styles.mainMoveRight, percTillNext: 0});
            setTimeout(() => {
                this.setState({fading: styles.elementToFadeIn, percTillNext: 0, items: this.cycleNextItem(this.state.items, true)});
            }, 1000);
        }
    }

    cycleNextItem(items: TCarouselItem[], previous: boolean = false) : TCarouselItem[] {
        let newitems : TCarouselItem[] = [];
        if (previous) {
            newitems.push(items[items.length-1]);
            for (let i=0; i<items.length-1; i++) {
                newitems.push(items[i]);
            }
        } else {
            for (let i=1; i<items.length; i++) {
                newitems.push(items[i]);
            }
            newitems.push(items[0]);
        }
        return newitems;
    }

    swipeEnd = (e) => {
        if (this.state.startSwipe != -1 && this.state.endSwipe != -1) {
            if ((this.state.endSwipe - this.state.startSwipe) > 40) {
                this.previousItem();
            } else if ((this.state.startSwipe - this.state.endSwipe) > 40) {
                this.nextItem();
            }
            this.setState({startSwipe: -1, endSwipe: -1});
        }
    }

    gotoItem = (index:number) => {
        let items: TCarouselItem[] = [];
        if (this.props.items.length) {
            items.push(index === 0 ? this.props.items[this.state.items.length-1] : this.props.items[index-1]);
            for (let i=index,l=this.props.items.length-1; i<=l; i++) {
                items.push(this.props.items[i]);
            }
            for (let i=0,l=index; i<l; i++) {
                items.push(this.props.items[i]);
            }
        }
        this.setState({percTillNext: 0, items:items});
    }

    public render(): React.ReactElement<ICarouselComponentProps> {
        return (
            <div style={{maxWidth:"100%"}}>
                <Stack horizontal style={{position:"relative"}}>
                    <div style={{position:"absolute", left:"50%", bottom:"5px",verticalAlign:"center", transform: "translate(-50%, 0%)", zIndex:1}}>
                        {this.props.items.map((item,index) => {
                            return <Image {...{imageFit: ImageFit.centerContain,
                                               className: this.state.items[1]?.quickLinkImageUrl === item.quickLinkImageUrl ? styles.carouselQuickLink : styles.carouselQuickLinkDisabled,
                                               src: item.quickLinkImageUrl,
                                               onClick: () => { this.gotoItem(index); }
                                          }} alt="" />
                        })}
                    </div>
                    <Stack verticalAlign='center' horizontalAlign='start'>
                        <Image {...{imageFit: ImageFit.centerContain,
                                    className: styles.mainpreviousReview,
                                    src: process.env.PUBLIC_URL + `/assets/prevdis.webp`,
                                    onClick: () => { this.previousItem(); }
                                }} alt="" />
                    </Stack>
                    <div style={{width:"100%"}} 
                         onTouchStart={(e) => { this.setState({startSwipe: e.targetTouches[0].clientX}); }}
                         onTouchMove={(e) => { this.setState({endSwipe: e.targetTouches[0].clientX}); }}
                         onTouchEnd={this.swipeEnd.bind(this)}>
                        <ItemComponent items={this.state.items} fading={this.state.fading} maxWidth={this.props.maxWidth} />
                    </div>
                    <Stack verticalAlign='center' horizontalAlign='end'>
                        <Image {...{imageFit: ImageFit.centerContain,
                                    className: styles.mainnextReview,
                                    src: process.env.PUBLIC_URL + `/assets/nextdis.webp`,
                                    onClick: () => { this.nextItem(); }
                                }} alt="" />
                    </Stack>
                </Stack>
            </div>
        );
    }
}

export interface ICarouselComponentProps {
    fading: string;
    items: TCarouselItem[];
    maxWidth: number;
}

class ItemComponent extends React.Component<ICarouselComponentProps, any> { //TODO
    constructor(props: ICarouselComponentProps) {
        super(props);
    }

    public render(): React.ReactElement<ICarouselComponentProps> {
        return (
            <div>
                <Stack horizontal style={{marginLeft:-this.props.maxWidth, overflow:"hidden"}}>
                    {this.props.items.map((item: TCarouselItem, index: number) => {
                        return <Stack styles={{root:{width:this.props.maxWidth, maxWidth:this.props.maxWidth, minWidth:this.props.maxWidth, overflow:"hidden"}}} horizontal={false} className={`${this.props.fading} ${styles.itemComponent2}`}>
                                    <Stack horizontal horizontalAlign='center'>
                                        {item?.content}
                                    </Stack>
                               </Stack>
                    })}
                </Stack>
            </div>
        );
    }
}