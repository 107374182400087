import { Stack, Image, PrimaryButton, FontIcon, TextField, DefaultButton, Dialog, DialogType, Label, Rating, RatingSize } from '@fluentui/react';
import React, { FormEvent } from 'react';

export interface IreviewComponentProps {
    id: string;
    isDev: boolean;
}

export interface IreviewComponentState {
    review: string;
    stars:number;
    suggestions:string;
    name:string;
    disabled:boolean;
    found:string;
};
  

export default class Review extends React.Component<IreviewComponentProps, IreviewComponentState> {
    constructor(props: IreviewComponentProps) {
        super(props);
        this.save = this.save.bind(this);

        this.state = {
            name: "",
            suggestions: "",
            review: "",
            stars: 1,
            disabled: false,
            found: "",
        }
    }

    private save() {
        this.setState({disabled: true});
        let hostUrl = "https://pokemoncollectie.nl/Backend"
        if (this.props.isDev) {
            hostUrl = "https://localhost:7254";
        }

        fetch(`${hostUrl}/SetReview?reviewid=${encodeURIComponent(this.props.id)}&name=${encodeURIComponent(this.state.name === "" ? "Anoniem" : this.state.name)}&review=${encodeURIComponent(this.state.review === "" ? "-" : this.state.review)}&stars=${this.state.stars}&found=${encodeURIComponent(this.state.found === "" ? "-" : this.state.found)}&suggestions=${encodeURIComponent(this.state.suggestions === "" ? "-" : this.state.suggestions)}`, {
            method: 'GET',
        }).then((response) => response.json()).then((data) => {
            if (data === true) {
                alert("Bedankt voor je beoordeling, zodra we het hebben verwerkt zal het worden getoond op onze website.");
                window.location.href = window.location.href.split('?')[0];
            } else {
                alert('Er is iets mis gegaan.')
            }
        });
    }

    public render(): React.ReactElement<IreviewComponentProps> {
        return (   
            <Stack tokens={{ maxWidth: 320}} style={{backgroundColor:"white",margin:20,padding:5,borderRadius:10}}>
                <h1>Wat vind je van ons?</h1>
                <Label>Naam (Wordt getoond op de website)</Label>
                <TextField disabled={this.state.disabled} maxLength={100} value={this.state.name} placeholder={"Anoniem"} onChange={(e,v) => {this.setState({name: v})}} />
                <br/>
                <Label>Je review</Label>
                <TextField disabled={this.state.disabled} multiline maxLength={500} value={this.state.review} onChange={(e,v) => {this.setState({review: v})}} />
                <br/>
                <Label>Hoeveel sterren krijgen we van je?</Label>
                <Rating max={10}
                        disabled={this.state.disabled} 
                        size={RatingSize.Large}
                        defaultRating={0}
                        onChange={(e,r) => this.setState({stars: r})}
                    />
                <br/>
                <Label>Hoe heb je ons gevonden?</Label>
                <TextField maxLength={100} value={this.state.found} placeholder={"-"} onChange={(e,v) => {this.setState({found: v})}} />
                <Label>Je suggesties</Label>
                <TextField disabled={this.state.disabled} multiline maxLength={500} value={this.state.suggestions} onChange={(e,v) => {this.setState({suggestions: v})}} />
                <br />
                <PrimaryButton text={"Verzend"} onClick={this.save} disabled={this.state.disabled} />
            </Stack>
        );
    }
}