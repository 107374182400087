import { Image, ImageFit, Label, PrimaryButton, Stack} from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';
import Header from './headerComponent';
import Footer from './footerComponent';
import { NavLink } from 'react-router-dom';
import GlobalProperties from '../helpers/GlobalProperties';
import { Nederlands } from '../constants/languages/lang';
import MainComponent from './mainComponent';


export interface IPromoComponentProps {
}

export interface IPromoComponentState {
};
  

export default class Marktplaats extends React.Component<IPromoComponentProps, IPromoComponentState> {
    constructor(props: IPromoComponentProps) {
        super(props);
    }

    public render(): React.ReactElement<IPromoComponentProps> {
        return (   
            <Stack horizontalAlign='center' verticalAlign='start' tokens={{padding:5}}>
                <Header />
                <div className={styles.mainSection} style={{fontSize:window.innerWidth<600?"x-small":"normal"}}>
                    <Stack horizontalAlign='center' className={styles.aPanel}>
                        <span style={{color:'blue',fontSize:'xx-large',fontFamily: "Flexo-Demi"}}>{GlobalProperties.Strings.Trust}</span>
                    </Stack>
                    <br/>
                    <span style={{color:'black',fontFamily: "Flexo-Demi"}}>
                        <div dangerouslySetInnerHTML={{ __html: GlobalProperties.Strings.MarktplaatsPart1 }} />
                    </span>
                    <br/>
                    <br/>
                    <Stack className={styles.aPanel}>
                        <Stack horizontal className={styles.promoLabelMain}>
                            <Label className={styles.boosterLabel}>Hoe werkt het?</Label>
                        </Stack>
                        <br/>
                        <span style={{color:'black',fontFamily: "Flexo-Demi"}}>
                            <div dangerouslySetInnerHTML={{ __html: GlobalProperties.Strings.MarktplaatsPart2 }} />
                        </span>
                        <br/>
                        <span style={{color:'black',fontSize:'normal',fontFamily: "Flexo-Demi", fontWeight:"bolder"}}>
                            <div dangerouslySetInnerHTML={{ __html: GlobalProperties.Strings.MarktplaatsPart3 }} />
                        </span>
                    </Stack>
                    <br/>
                    <Stack horizontal horizontalAlign='center' tokens={{childrenGap:20}}>
                        <NavLink to="/">
                            <PrimaryButton text={GlobalProperties.Strings.Back} />
                        </NavLink>
                        <a href='https://www.marktplaats.nl/u/mark-pokemoncollectie/50043038/' target='_blank'>
                            <PrimaryButton text='Marktplaats.nl'/>
                        </a>
                    </Stack>
                </div>
                <Footer />
            </Stack>
        );
    }
}