import { Label } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';

export interface IPulseLabelv2ComponentProps {
    text?: string;
    className?: string;
    style?: React.CSSProperties;
}

export interface IPulseLabelv2ComponentState {
    pulse: boolean;
};
  

export default class PulseLabelv2 extends React.Component<IPulseLabelv2ComponentProps, IPulseLabelv2ComponentState> {
    constructor(props: IPulseLabelv2ComponentProps) {
        super(props);

        this.state = {pulse:false};
    }

    componentDidUpdate(prevProps: Readonly<IPulseLabelv2ComponentProps>, prevState: Readonly<IPulseLabelv2ComponentState>, snapshot?: any): void {
        if (prevProps.text != this.props.text) {
            this.setState({pulse: true});
            setTimeout(() => {
                this.setState({pulse: false});
            }, 2000);
        }
    }

    componentDidMount(): void {

    }

    onImageClick(url:string) {

    }

    public render(): React.ReactElement<IPulseLabelv2ComponentProps> {
        return (   
            <span style={this.props.style} className={`${this.props.className} ${this.state.pulse ? styles.pulse : ""}`}>{this.props.text}</span>
        );
    }
}