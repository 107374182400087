import * as React from 'react';
import { Slide, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GlobalProperties from '../helpers/GlobalProperties';

export interface IShowToastProps {
  message: string;
  isError?: boolean;
  timeout?: number;
  callback: Function;
}

export interface IShowToastState {
  notify : Function;
}

export default class ShowToastComponent extends React.Component<IShowToastProps, IShowToastState> {
  private notify = (message:string) => { toast.info(message) };

  constructor(props: IShowToastProps) {
    super(props);

    this.setState({
      notify: () => {}
    });
  }

  componentDidUpdate(prevProps: Readonly<IShowToastProps>, prevState: Readonly<IShowToastState>, snapshot?: any): void {
    if (this.props.message?.length) {
      if (this.props.isError) {
        this.notify = (message:string) => { toast.error(message) };
      } else {
        this.notify = (message:string) => { toast.info(message) };
      }
      
      this.notify(this.props.message);
      
      this.props.callback();
    }
  }

  public render(): React.ReactElement<IShowToastProps> {     
    return(
      <ToastContainer style={{borderRadius: "5px", left: "auto", width: GlobalProperties.IsMobile ? "300px" : "500px"}}
                      autoClose={this.props.timeout ? this.props.timeout : 1000} 
                      position={GlobalProperties.IsMobile ? 'bottom-right'  : 'bottom-right'  }
                      newestOnTop={true} 
                      limit={4} 
                      transition={Slide} />
    )
  }
}
