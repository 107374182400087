import { Stack, Label, PrimaryButton, Image, FontIcon } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';
import { TShoppingCart, TShoppingCartItem, TSize } from '../constants/types';
import { CalculatePrices } from '../helpers/General';
import GlobalProperties from '../helpers/GlobalProperties';
import ShowToastComponent from './showToastComponent';

export interface IshoppingcartComponentProps {
    satprice: number;
    windowsSize: TSize;
    onSmallCartClick: Function;
    shoppingcart: TShoppingCart;
    onCheckOut: Function;
    showPopup: Function;
    ShowMessage: Function;
    eurprice: number;
    usdprice: number;
}

export interface IshoppingcartComponentState {
    totalprice: number;
    sendCost: number;
    changed: boolean;
    itemCount : number;
};
  

export default class Shoppingcart extends React.Component<IshoppingcartComponentProps, IshoppingcartComponentState> {
    constructor(props: IshoppingcartComponentProps) {
        super(props);
        this.setAmmount = this.setAmmount.bind(this);
        this.removeFromCart = this.removeFromCart.bind(this);
        this.preRender = this.preRender.bind(this);
        const sendcost = Math.round((6.49+(6.49*(1-this.props.eurprice))) / props.satprice);
        this.state = { totalprice: sendcost, sendCost: sendcost, itemCount: 0, changed: false};
    }

    componentDidUpdate(prevProps: Readonly<IshoppingcartComponentProps>, prevState: Readonly<IshoppingcartComponentState>, snapshot?: any): void {
        if (this.props != prevProps) {
            this.preRender();
        }
    }

    componentDidMount(): void {
        this.preRender();
    }

    removeFromCart(item: TShoppingCartItem) {
        let newArray: TShoppingCartItem[] = [];
        this.props.shoppingcart.items.forEach(i => {
            if (item.product.id != i.product.id) {
                newArray.push(i);
            }
        });
        this.props.shoppingcart.items = newArray; //Yuck, TODO. Never change props
        this.preRender();
        this.props.ShowMessage(`${item.product.title} ${GlobalProperties.Strings.RemovedFromCart}`)
    }

    setAmmount(item: TShoppingCartItem, ammount:number) {
        let filtered = this.props.shoppingcart.items.filter(i => i.product.id === item.product.id);
        if (ammount <= item.product.available && ammount > 0) {
            if (filtered[0].ammount < ammount) {
                GlobalProperties.ShowMessage(`${filtered[0].product.title} ${GlobalProperties.Strings.AddedToCart}`, 1000, false);
            } else {
                GlobalProperties.ShowMessage(`${filtered[0].product.title} ${GlobalProperties.Strings.RemovedFromCart}`, 1000, false);
            }

            filtered[0].ammount = ammount; //Yuck, TODO. Never change props
            this.preRender();
        } else {
            if (filtered[0].ammount < ammount) {
                GlobalProperties.ShowMessage(GlobalProperties.Strings.NotAvailable, 2000, true);
            } else {
                GlobalProperties.ShowMessage(GlobalProperties.Strings.MinimalInCart, 2000, true);
            }
        }
    }

    preRender() {
        const [total, sendcost, itemCount] = CalculatePrices(this.props.satprice, this.props.eurprice, this.props.usdprice, this.props.shoppingcart);
        if (this.state.itemCount != itemCount) {
            this.setState({changed: true});
            setTimeout(() => {
                this.setState({changed: false});
            }, 500);
        }
        this.setState({totalprice: total, sendCost: sendcost, itemCount: itemCount});
    }

    public render(): React.ReactElement<IshoppingcartComponentProps> {
        return (   
            this.props.windowsSize.x > 960 ? 
                <Stack className={styles.winkelwagen} verticalAlign='center'>
                    <div className={styles.shoppingCartTitleContainerBig}>
                        <div className={styles.shoppingCartTitle}>
                            {GlobalProperties.Strings.Shoppingcart}
                        </div>
                    </div>
                    {this.state.itemCount != 0 ?
                        <div>
                            <div className={styles.divTable} style={{maxHeight:this.props.windowsSize.y-100}}>
                                {this.props.shoppingcart.items.map(item => {
                                    return <div className={styles.divTableRow}>
                                                <div className={styles.divTableCell} style={{verticalAlign:"middle"}}>
                                                    <Stack horizontal verticalAlign='center'>
                                                        <FontIcon iconName="Trash" className={styles.delete} onClick={() => { this.removeFromCart(item); }}/>
                                                    </Stack>
                                                </div>
                                                <div className={styles.divTableCell} style={{verticalAlign:"middle"}}>
                                                    <Stack horizontal verticalAlign='center'>
                                                        <img className={styles.checkoutCardImg} 
                                                             onClick={() => {this.props.showPopup(item.product.imageUrls[0]);}}
                                                             src={item.product.imageUrls[0]} alt="" />
                                                        <Label className={styles.shoppingcartAmmount}>{item.product.title}</Label>
                                                    </Stack>
                                                </div>
                                                <div className={styles.divTableCell} style={{verticalAlign:"middle"}}>
                                                    <Stack horizontal>
                                                        <FontIcon iconName="SkypeCircleMinus" className={styles.minus} onClick={() => { this.setAmmount(item, item.ammount-1); }}/>
                                                        <Label className={styles.shoppingcartAmmount}>x {item.ammount}</Label>
                                                        <FontIcon iconName="CirclePlus" className={styles.plus} onClick={() => { this.setAmmount(item, item.ammount+1); }}/>
                                                    </Stack>
                                                </div>
                                                <div className={styles.divTableCell} style={{verticalAlign:"middle"}}>
                                                    <Stack horizontalAlign='end'>
                                                        <Label>€ {(item.ammount * Math.round(100* item.product.priceInUSD * this.props.eurprice) / 100).toFixed(2)}</Label>
                                                        <Stack horizontal>
                                                            <Image className={styles.btciconsmallcard}
                                                                style={{ cursor: "default", paddingTop:4.5 }}
                                                                src={`${process.env.PUBLIC_URL}/assets/btcicon.png`}
                                                            />
                                                            <Label style={{fontSize:"smaller"}}>{item.ammount * Math.round(item.product.priceInUSD / this.props.satprice)} sats</Label>
                                                        </Stack>
                                                    </Stack>
                                                </div>
                                        </div>
                                            
                                })}
                            </div>
                            <hr />
                            <div className={styles.divTable}>                        
                                {this.state.itemCount > 0 &&
                                <div className={styles.divTableRow}>
                                    <div className={styles.divTableCell}>
                                        <Label>{GlobalProperties.Strings.SendingCost}</Label>
                                    </div>
                                    <div className={styles.divTableCell}>
                                    </div>
                                    <div className={styles.divTableCell}>
                                        <Stack horizontalAlign='end'>
                                            <Label>€ {(Math.round(this.state.sendCost * this.props.satprice * 100 * this.props.eurprice) / 100).toFixed(2)}</Label>
                                            <Stack horizontal>
                                                <Image className={styles.btciconsmallcard}
                                                    style={{ cursor: "default", paddingTop:4.5 }}
                                                    src={`${process.env.PUBLIC_URL}/assets/btcicon.png`}
                                                />
                                                <Label style={{fontSize:"smaller"}}>{this.state.sendCost} sats</Label>
                                            </Stack>
                                        </Stack>
                                    </div>
                                </div>}
                                </div>
                            <hr />
                            <div className={styles.divTable}>
                                {this.state.itemCount > 0 &&
                                <div className={styles.divTableRow}>
                                    <div className={styles.divTableCell}>
                                        <Label>{GlobalProperties.Strings.Total}</Label>
                                    </div>
                                    <div className={styles.divTableCell}>
                                    </div>
                                    <div className={styles.divTableCell}>
                                        <Stack horizontalAlign='end'>
                                            <Label>€ {(Math.round(100 * this.state.totalprice * this.props.satprice * this.props.eurprice) / 100).toFixed(2)}</Label>
                                            <Stack horizontal>
                                                <Image className={styles.btciconsmallcard}
                                                    style={{ cursor: "default", paddingTop:5 }}
                                                    src={`${process.env.PUBLIC_URL}/assets/btcicon.png`}
                                                />
                                                <Label style={{fontSize:"smaller"}}>{this.state.totalprice} sats</Label>
                                            </Stack>
                                        </Stack>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    : <div className={styles.emptyCartLabel}>{GlobalProperties.Strings.ShoppingcartEmpty}</div>}
                    {this.state.itemCount !== 0 &&
                    <Label className={styles.priceWarning}>* {GlobalProperties.Strings.PaymentNote}</Label>}
                    <PrimaryButton disabled={this.state.itemCount === 0} className={styles.checkoutButton} text={GlobalProperties.Strings.CheckOut} onClick={() => {this.props.onCheckOut();}}>
                        <FontIcon aria-label="Afrekenen" iconName="LightningBolt" />
                    </PrimaryButton>
                    <Stack horizontalAlign='center'>
                        <a className={styles.keepShoppingLink} onClick={()=>{this.props.onSmallCartClick();}}>Verder gaan met winkelen</a>
                    </Stack>
                </Stack>
            : <div className={`${styles.shoppingCartTitleContainer} ${this.state.changed ? styles.added : ""}`}> 
                <Label className={styles.itemCountLabel}>{this.state.itemCount}</Label>  
                <FontIcon className={`${styles.shoppingCartIcon}`} iconName={this.props.shoppingcart.items.length > 0 ? "shoppingcartsolid" : "shoppingcart"} onClick={() => {this.props.onSmallCartClick(); }}/>
              </div>
        );
    }
}