import { Label } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';
import { BooleanLiteral } from 'typescript';

export interface IbtcPriceComponentProps {
    btcprice: number;
    eurprice: number;
}

export interface IbtcPriceComponentState {
    pulse: boolean;
};
  

export default class BTCPriceComponent extends React.Component<IbtcPriceComponentProps, IbtcPriceComponentState> {
    constructor(props: IbtcPriceComponentProps) {
        super(props);

        this.state = {pulse:false};
    }

    componentDidUpdate(prevProps: Readonly<IbtcPriceComponentProps>, prevState: Readonly<IbtcPriceComponentState>, snapshot?: any): void {
        if (prevProps.btcprice != this.props.btcprice || prevProps.eurprice != this.props.eurprice) {
            this.setState({pulse: true});
            setTimeout(() => {
                this.setState({pulse: false});
            }, 2000);
        }
    }

    componentDidMount(): void {

    }

    onImageClick(url:string) {

    }

    public render(): React.ReactElement<IbtcPriceComponentProps> {
        return (   
            <Label className={`${styles.btcPriceLabel} ${this.state.pulse ? styles.pulse : ""}`}>1 ₿ = € {Math.round(this.props.btcprice * this.props.eurprice *100)/100}</Label>
        );
    }
}