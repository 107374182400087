import { Image, ImageFit, Label, Stack} from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';


export interface IPromoComponentProps {
    windowWidth: number;
    satprice: number;
    eurprice: number;
}

export interface IPromoComponentState {

};
  

export default class PromoComponent extends React.Component<IPromoComponentProps, IPromoComponentState> {
    constructor(props: IPromoComponentProps) {
        super(props);
    }

    public render(): React.ReactElement<IPromoComponentProps> {
        return (   
            <Stack horizontalAlign='center' verticalAlign='start' tokens={{padding:5}}>
                <Image src={`${process.env.PUBLIC_URL}/assets/promo_PF.webp`} alt="" imageFit={ImageFit.cover} />
                <Stack className={styles.aPanel}>
                    <Stack horizontalAlign='center'>
                        <span style={{color:'blue',fontSize:'larger'}}>Paldean Fates komt er aan!!!</span>
                    </Stack>
                    <Label style={{fontSize:'normal'}}>De schijnwerpers glinsteren op Shiny Pokémon die hun noodlottige terugkeer maken naar de Pokémon TCG! Shiny Pikachu baant zich een weg voorwaarts terwijl Tinkaton, Ceruledge, Dondozo en meer dan 100 andere Shiny Pokémon volgen. Ondertussen verschijnen Great Tusk en Iron Treads als Ancient en Future Pokémon ex, en Charizard, Forretress en Espathra pronken met hun eigen unieke vaardigheden als Shiny Tera Pokémon ex. Laat wat licht schijnen en ontdek sprankelende wonderen in de Scarlet & Violet-Paldean Fates- uitbreiding!</Label>
                    <Stack horizontal horizontalAlign='center'>
                        <Image style={{width:200}} src={`${process.env.PUBLIC_URL}/assets/pokemon/199/199.webp`} alt="" imageFit={ImageFit.cover} />
                    </Stack>
                    <Stack>
                        <Stack horizontal className={styles.promoLabelMain}>
                            <Label className={styles.boosterLabel}>Kaarten</Label>
                        </Stack>
                        <Stack horizontal>
                            <Image src={`${process.env.PUBLIC_URL}/assets/promocards.webp`} alt="" imageFit={ImageFit.cover} />
                            <Stack>
                                <p className={styles.cardpriceEUR} style={{marginLeft:-2,marginTop:3}}>€ 0,11 - ?</p>
                                <p className={styles.cardpriceBTC} style={{marginLeft:-2,marginTop:3}}>{Math.round((0.11 / this.props.eurprice) / this.props.satprice)} sats</p>
                            </Stack>
                        </Stack>
                        <br/>

                        <Stack horizontal className={styles.promoLabelMain}>
                            <Label className={styles.boosterLabel}>Booster</Label>
                        </Stack>
                        <Stack horizontal>
                            <Image style={{maxHeight:400}} src={`${process.env.PUBLIC_URL}/assets/products/4/5.webp`} alt="" imageFit={ImageFit.cover} />
                            <Stack>
                                <p className={styles.cardpriceEUR} style={{marginLeft:-2,marginTop:3}}>€ 5,75</p>      
                                <p className={styles.cardpriceBTC} style={{marginLeft:-2,marginTop:3}}>{Math.round((5.75 / this.props.eurprice) / this.props.satprice)} sats</p>     
                            </Stack>       
                        </Stack>
                        <br/>

                        <Stack horizontal className={styles.promoLabelMain}>
                            <Label className={styles.boosterLabel}>Elite Trainer Box</Label>
                        </Stack>
                        <Stack horizontal>
                            <Image src={`${process.env.PUBLIC_URL}/assets/products/16/1.webp`} alt="" imageFit={ImageFit.cover} />
                            <Stack>
                                <p className={styles.cardpriceEUR} style={{marginLeft:-2}}>€ 57,99</p>
                                <p className={styles.cardpriceBTC} style={{marginLeft:-2,marginTop:3}}>{Math.round((57.99 / this.props.eurprice) / this.props.satprice)} sats</p> 
                            </Stack>         
                        </Stack>
                        <br/>

                        <Stack horizontal className={styles.promoLabelMain}>
                            <Label className={styles.boosterLabel}>Booster Bundle</Label>
                        </Stack>
                        <Stack horizontal>
                            <Image style={{maxHeight:400}} src={`${process.env.PUBLIC_URL}/assets/products/17/boosterbundle.webp`} alt="" imageFit={ImageFit.cover} />
                            <Stack>
                                <p className={styles.cardpriceEUR} style={{marginLeft:-2,marginTop:3}}>€ 33.99</p> 
                                <p className={styles.cardpriceBTC} style={{marginLeft:-2,marginTop:3}}>{Math.round((33.99 / this.props.eurprice) / this.props.satprice)} sats</p>    
                            </Stack>           
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        );
    }
}