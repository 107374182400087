import { Label } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';

export interface IpulseLabelComponentProps {
    text?: string;
    className?: string;
    style?: React.CSSProperties;
}

export interface IpulseLabelComponentState {
    pulse: boolean;
};
  

export default class PulseLabel extends React.Component<IpulseLabelComponentProps, IpulseLabelComponentState> {
    constructor(props: IpulseLabelComponentProps) {
        super(props);

        this.state = {pulse:false};
    }

    componentDidUpdate(prevProps: Readonly<IpulseLabelComponentProps>, prevState: Readonly<IpulseLabelComponentState>, snapshot?: any): void {
        if (prevProps.text != this.props.text) {
            this.setState({pulse: true});
            setTimeout(() => {
                this.setState({pulse: false});
            }, 2000);
        }
    }

    componentDidMount(): void {

    }

    onImageClick(url:string) {

    }

    public render(): React.ReactElement<IpulseLabelComponentProps> {
        return (   
            <Label style={this.props.style} className={`${this.props.className} ${this.state.pulse ? styles.pulse : ""}`}>{this.props.text}</Label>
        );
    }
}