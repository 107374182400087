import { Stack, Image, ImageFit, Label, IStackTokens, Dialog, DialogType, initializeIcons, TextField, DefaultButton, PrimaryButton, FontIcon, ProgressIndicator, Toggle, Panel, PanelType, IContextualMenuItem, ChoiceGroup, IChoiceGroupOption, IconButton, MessageBar, MessageBarType, Spinner, Checkbox, Link } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';
import PokemonCards from './cardsComponent';
import { GetCards, GetSets, GetBTCPrice, GetSetCardCount, GetFiatPrices, GetCardsCountAll, GetProducts, GetConnect, CardAllUpdates, Init, SetAlert, SearchCards, GetEURPrice, GetUSDPrice} from '../services/DBConnect';
import { ProductType, TInvoice, TProduct, TSale, TSaleProduct, TSet, TShoppingCart, TSize } from '../constants/types';
import Shoppingcart from './shoppingcartComponent';
import { Helmet } from 'react-helmet';
import { CheckIDealPayment, CheckInvoice, CheckInvoiceV3, GetBTCOnchain, GetIDealPayment, GetInvoiceV3 } from '../services/Payment';
import CheckoutComponent from './checkout';
import Globals, { Filters, IsNull } from '../services/Globals';
import SetsMenuComponent from './SetsMenuComponent';
import Boosters from './boostersComponent';
import Collections from './collectionsComponent';
import { ProcessProducts, getFilteredCards } from '../helpers/General';
import Misc from './miscComponent';
import Review from './review';
import { NavLink } from 'react-router-dom';
import CarouselComponent from './carouselComponent';
import PromoComponent from './promoComponent';
import Promo2Component from './promo2Component';
import Header from './headerComponent';
import Footer from './footerComponent';
import PulseLabel from './pulseLabelComponent';
import Search from './searchComponent';
import { FiltersComponent } from './filtersComponent';
import ProductViewerComponent from './productViewerComponent';
import GlobalProperties from '../helpers/GlobalProperties';
import { English, Nederlands } from '../constants/languages/lang';
import NonNLbannerComponent from './nonNLbannerComponent';
import BannerComponent from './bannerComponent';
import EmailAlertComponent from './emailAlertComponent';
import ShowToastComponent from './showToastComponent';
import Producten from './productenComponent';
import WelcomeComponent from './welcomeComponent';

// Under construction
//                  {(!this.state.isDebug) && <Dialog
//                                             hidden={false}
//                                             dialogContentProps={{type:DialogType.normal}}
//                                             modalProps={{styles: {main : { maxHeight: "100%!important", maxWidth: "100%!important", backgroundColor: "transparent", boxShadow: "none", overflowY:"hidden"}}}}
//                                         >
//                                             <Image src={process.env.PUBLIC_URL + `/assets/underc.webp`} alt="" />
//                                         </Dialog>}

export interface ImainComponentProps {
    firstComponent: ProductType;
    showSearch?: boolean;
    showText?: string;
}

export interface ImainComponentState {
    showFooter:boolean;
    firstLoad?:boolean;
    toastTimeout?: number;
    searchTime?: number;
    isToastError?: boolean;
    keywords: string;
    searchAll: boolean;
    selectedAlertCard: TProduct;
    showcards: boolean;
    cards: TProduct[];
    updatedCards: TProduct[];
    allcards: TProduct[];
    filteredCards: TProduct[];
    btcprice: number;
    eurprice: number;
    usdprice: number;
    satprice: number;
    shoppingkart: TShoppingCart;
    popupUrl?: string;
    selectedSetKey: number;
    selectedBaseSetKey: number;
    sets: TSet[];
    allsets: TSet[];
    availablesets: TSet[];
    searchText?: string;
    pageIndex: number;
    pageSize: number;
    numberOfPages: number;
    pageCardSet: TProduct[];
    windowSize:TSize;
    showCartPopup:boolean;
    isDebug:boolean;
    init:boolean;
    invoice?: TInvoice;
    message?: any;
    dev: boolean;
    showCheckOut: boolean;
    invoiceTimoutPercentage: number;
    invoiceCreatedAt?: Date;
    invoiceTimedOut?: boolean;
    showAvailableOnly: boolean;
    showSettings: boolean;
    setsMenu: IContextualMenuItem[];
    allMenu: IContextualMenuItem[];
    selectedRarity?: string;
    selectedVersion?: string;
    paymentSuccess: boolean;
    onChainAddress?: string;
    startPrice: number;
    endPrice: number;
    popupImgStyle?: any;
    animationsEnabled: boolean;
    currentFilter: string;
    waitingIdealPayment: boolean;
    showLocaleBanner: boolean;
    reviewId: string;
    showOnly?: ProductType;
    boosters: TProduct[];
    allBoosters: TProduct[];
    collections: TProduct[];
    allCollections: TProduct[];
    producten: TProduct[];
    misc: TProduct[];
    allMisc: TProduct[];
    showAddedPanel: boolean;
    showReviews: boolean;
    popUpSwipeLeft?: Function;
    popUpSwipeRight?: Function;
    startSwipe?: number;
    endSwipe?: number;
    showFilterDialog: boolean;
    footerMessage?: string;
    showBanner: boolean;
    keepFilterMenuOpen: boolean;
    userEmail:string;
    alertCoachmarkVisible:boolean;
    toastMessage:string;
};

export default class MainComponent extends React.Component<ImainComponentProps, ImainComponentState> {
    private cardTokens: IStackTokens = {
        childrenGap: 10,
        padding: 5
    };

    private filterOptions: IChoiceGroupOption[];

    private sliderValueFormat = (value: number) => value === 10000 ? "Max" : `${value}`;

    constructor(props: ImainComponentProps) {
        super(props);

        console.warn = console.error = () => {}; //disable warnings
        const isDev = Globals.HasParam('dev');

        this.state = {
            showFooter: false,
            firstLoad: props.firstComponent === ProductType.All ? true : false,
            keywords: "Pokemon, kaarten, Bitcoin, BTC, Pokémon, lightning, collectie",
            searchAll: true,
            showFilterDialog: false,
            updatedCards: [],
            showcards : true,
            paymentSuccess: false,
            showCheckOut: false,
            showCartPopup: false,
            pageCardSet : [],
            pageIndex: 0,
            pageSize: window.innerWidth < 960 ? 20 : 36,
            numberOfPages: 0,
            sets: [],
            allsets: [],
            availablesets: [],
            filteredCards: [], 
            cards: [],
            allcards: [],
            btcprice: 99999999,
            eurprice: 99999999,
            usdprice: 99999999,
            satprice: 99999999,
            shoppingkart: {items: []},
            windowSize: {x: window.innerWidth > 1232 ? 1232 : window.innerWidth, y: window.innerHeight},
            isDebug : Globals.HasParam('debug'),
            dev: isDev,
            reviewId: Globals.GetParam('reviewid'),
            init: true,
            invoiceTimoutPercentage: 0,
            showAvailableOnly: true,
            showSettings: false,
            setsMenu: [],
            allMenu: [],
            startPrice: 0,
            endPrice: 500000,
            animationsEnabled: false,
            currentFilter: Filters.CardNumberDown,
            waitingIdealPayment: false,
            showLocaleBanner: false,
            boosters: [],
            allBoosters: [],
            collections: [],
            allCollections: [],
            producten: [],
            misc: [],
            allMisc: [],
            showOnly: props.firstComponent,
            showReviews: true,
            showAddedPanel: false,
            selectedSetKey: -1,
            selectedBaseSetKey: -1,
            showBanner: false,
            selectedAlertCard: null,
            keepFilterMenuOpen: false,
            userEmail: "",
            alertCoachmarkVisible: false,
            toastMessage: GlobalProperties.ToastMessage
        };

        initializeIcons();
        GlobalProperties.Strings = Nederlands;
        GlobalProperties.ShowMessage = this.ShowMessage;
        GlobalProperties.IsMobile = window.innerWidth < 960 ? true : false;
        GlobalProperties.PageWidth = window.innerWidth-25 > 1232 ? 1232 : window.innerWidth;
        GlobalProperties.IsDebug = Globals.HasParam('debug');

        this.filterOptions = [
            { key: Filters.PriceUp, text: GlobalProperties.Strings.PriceHighToLow },
            { key: Filters.PriceDown, text: GlobalProperties.Strings.PriceToLowHigh },
            { key: Filters.CardNumberUp, text: GlobalProperties.Strings.CardNumberHighToLow  },
            { key: Filters.CardNumberDown, text: GlobalProperties.Strings.CardNumberLowToHigh },
        ];

        //Nog niet, nog eerst even valideren dat dit voldoende werkt
        // fetch(`${process.env.PUBLIC_URL}/cache/init.json`).then(response => response.json())
        //     .then(values => {
        //         this.InitStoredValues(values, isDev);
        //     }).catch( err => {
                Init(this.state.showAvailableOnly, isDev).then(values => {
                    this.InitStoredValues(values, isDev);
                 });
        //    });
    }

    Refresh = () => {
        this.filterOptions = [
            { key: Filters.PriceUp, text: GlobalProperties.Strings.PriceHighToLow },
            { key: Filters.PriceDown, text: GlobalProperties.Strings.PriceToLowHigh },
            { key: Filters.CardNumberUp, text: GlobalProperties.Strings.CardNumberHighToLow  },
            { key: Filters.CardNumberDown, text: GlobalProperties.Strings.CardNumberLowToHigh },
        ];
        this.setState({btcprice: this.state.btcprice-0.001}); 
        this.setSetMenuItems(this.state.sets, this.state.showAvailableOnly);
    }

    ShowAlertCoachmarks = () => {
        this.setState({
            showAvailableOnly: true,
            alertCoachmarkVisible: true
        }, () => {
            this.toggleAvailable();
        });
    }

    InitStoredValues = (values, isDev : boolean) => {
        GlobalProperties.Init(values.config, this.Refresh );
        this.setState({ btcprice: values.btcPrice, 
                        satprice: values.btcPrice/100000000,
                        usdprice: values.usdPrice, 
                        eurprice: values.eurPrice,
                        selectedSetKey: GlobalProperties.selectedSetKey},
                    () => {
                        const pathName = decodeURI(window.location.pathname);
                        const subString = pathName.substring(1, pathName.length);
                        const parts = subString.split('/');
                        if (parts.length === 2 && parts[0] === "Pokemon Kaarten") {
                            values.sets.forEach(set => {
                                if (set.name === parts[1]) {
                                    GlobalProperties.selectedSetName = set.name;
                                    GlobalProperties.selectedSetKey = set.id;
                                    GlobalProperties.selectedBaseSetKey = set.parentid;
                                    this.setState({selectedSetKey: set.id, selectedBaseSetKey: set.parentid}, () => {
                                        this.ProcessSets(values.sets,true);
                                        this.SetCards(set.name, this.state.showAvailableOnly); 
                            
                                        //this.ProcessCards(values.cards, GlobalProperties.selectedSetName);
                                    });
                                }
                            });
                        } else {
                            this.ProcessSets(values.sets,true);
                            this.ProcessCards(values.cards, GlobalProperties.selectedSetName);
                        }
                        setTimeout(() => {
                            GetConnect(GlobalProperties.IsMobile, isDev);
                            this.setState({animationsEnabled: true});
                        }, 2000);
                    });
        //Give client some breathing time before loading in booster images
        setTimeout(() => {
            this.setState({ boosters: ProcessProducts(values.boosters),
                            collections: ProcessProducts(values.collections),
                            misc: ProcessProducts(values.misc)}, this.updateProducten );
            if (!this.state.showAvailableOnly) {
                this.setState({ allBoosters: ProcessProducts(values.boosters),
                                allCollections: ProcessProducts(values.collections),
                                allMisc: ProcessProducts(values.misc)} );
            }
        }, 500);
        //Start the price polling
        setTimeout(() => {
            this.SetPrice();
        }, 10000);

        //console.log(GlobalProperties);
        //Check if we got a site message to show
        if (GlobalProperties.SITE_MESSAGE_START_DATE <= new Date() && GlobalProperties.SITE_MESSAGE_END_DATE >= new Date()) {
            this.setState({showBanner: true});
        }
    }

    componentDidUpdate = (prevProps: Readonly<ImainComponentProps>, prevState: Readonly<ImainComponentState>, snapshot?: any): void => {
        if (this.props.firstComponent != this.state.showOnly) {
            this.setState({showOnly: prevProps.firstComponent});

            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 200);
        }

        if (prevState.selectedSetKey != this.state.selectedSetKey) {
            let keywords = "Pokemon, kaarten, Bitcoin, BTC, Pokémon, lightning, collectie";
            const pathName = decodeURI(window.location.pathname);
            const prodName = pathName.substring(1,pathName.length);
            const parts = prodName.split('/');
            if (parts.length>2) {
                let [setname, cardname, number, version] = (parts[1]+parts[2]).split(" - ");
            
                if (setname === undefined) {
                    setname = this.state.sets.find(set => set.id == this.state.selectedSetKey)?.name;
                }

                keywords = `${setname}, ${keywords}`;

                if (version !== undefined) {
                    keywords = `${cardname} - ${version}, ${keywords}`;
                }

                if (cardname !== undefined) {
                    keywords = `${cardname}, ${keywords}`;
                }
            } else {
                if (parts.length>1) {
                    let [setname] = (parts[1]).split(" - ");
                    keywords = `${setname}, ${keywords}`;
                } else {
                    keywords = `${GlobalProperties.selectedSetName}, ${keywords}`;
                }
            }

            this.updateProducten();

            this.setState({keywords: keywords});
        }
    }

    updateProducten = () => {
        let producten: TProduct[] = [];
        this.state.misc.forEach(prod => {
            if (prod.setID === this.state.selectedSetKey || prod.showInSets.filter(sn => sn === this.state.selectedSetKey.toString()).length > 0) {
                producten.push(prod);
            }
        });
        this.state.boosters.forEach(prod => {
            if (prod.setID === this.state.selectedSetKey || prod.showInSets.filter(sn => sn === this.state.selectedSetKey.toString()).length > 0) {
                producten.push(prod);
            }
        });
        this.state.collections.forEach(prod => {
            if (prod.setID === this.state.selectedSetKey || prod.showInSets.filter(sn => sn === this.state.selectedSetKey.toString()).length > 0) {
                producten.push(prod);
            }
        });

        let availableProds = producten.filter(p => p.available > 0);
        let notavailableProds = producten.filter(p => p.available === 0);

        availableProds.sort((a,b) => 
            a.order < b.order ? -1 : 1
        );

        notavailableProds.sort((a,b) => 
            a.order < b.order ? -1 : 1
        );

        this.setState({producten: availableProds.concat(notavailableProds)});
    }

    componentDidMount = () => {
        window.addEventListener('resize', this.updateSize);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateSize);
    }

    updateSize = () => {
        GlobalProperties.IsMobile = window.innerWidth < 960 ? true : false;
        this.setState({windowSize : {x: window.innerWidth > 1232 ? 1232 : window.innerWidth, y: window.innerHeight}}, () => {
            console.log("Resized");
        });
    }

    SetPrice = () : Promise<any> => {
        return GetBTCPrice(this.state.dev).then(price => {
            if (price != this.state.btcprice) {
                this.setState({btcprice: price, satprice: price/100000000});
            }
            
            GetFiatPrices(this.state.dev).then(prices => {
                if (prices[0] != this.state.usdprice || prices[1] != this.state.eurprice) {
                    this.setState({usdprice: prices[1], eurprice: prices[0]});
                }
                setTimeout(() => {
                    this.SetPrice();
                }, 30000);
            }).catch(err => {
                setTimeout(() => {
                    this.SetPrice();
                }, 10000);
            });
        }).catch(err => {
            console.log(err);
            setTimeout(() => {
                this.SetPrice();
            }, 10000);
        });
    }

    SetCards = (setname:string, availableOnly: boolean = true, isAllCards: boolean = false, callback: Function = null) : Promise<void> => {
        return new Promise((resolve, reject) => {
            if (IsNull(setname)) {
                this.setState({cards: [], pageCardSet: [], init: false}, () => {this.onPagingChange(0);} );
                if (isAllCards === true) {
                    this.setState({allcards: []}, () => { 
                        if (this.state.searchText) {
                            this.onSearchBoxChange(this.state.searchText);
                        }
                        if (callback) {
                            callback();
                        }
                        resolve();
                    });
                }
            } else {
                if (this.state.sets.length) {
                    GetCards(setname, availableOnly, this.state.dev).then(cards => {
                        this.ProcessCards(cards, setname, callback, isAllCards);
                        if (this.state.searchText) {
                            this.onSearchBoxChange(this.state.searchText);
                        }
                    }).catch(err => {
                        console.log(err);
                    });
                    resolve();
                }
            }
        });
    }

    ProcessCards = (cards: TProduct[], setname: string, callback: Function = null, isAllCards:boolean = false) => {
        const setid = this.state.sets.filter(set => set.name === setname)[0]?.id;
        cards.forEach(card => {
            if (card.number > 99) {
                card.scardnumber = `${card.number}`;
            } else if (card.number > 9) {
                card.scardnumber = `0${card.number}`;
            } else {
                card.scardnumber = `00${card.number}`;
            }
            if (card.size > 99) {
                card.scardnumber += `/${card.size}`;
            } else if (card.size > 9) {
                card.scardnumber += `/0${card.size}`;
            } else {
                card.scardnumber += `/00${card.size}`;
            }

            //TODO: move to backend, minimale prijs toevoegen
            card.priceInUSD = Math.round(card.priceInUSD * 100) /100;
            card.cost = Math.round(card.priceInUSD / this.state.satprice);
            card.scost = `${card.cost}`;
            card.setID = setid;
            let imageUrls: string[] = [];
            card.imageNames?.split(',').forEach(imageName => {
                imageUrls.push(`${process.env.PUBLIC_URL}/assets/pokemon/${card.setID}/${card.number.toString()}/${imageName}`);
            });
            imageUrls.push(`${process.env.PUBLIC_URL}/assets/pokemon/cardback.webp`);
            card.imageUrls = imageUrls;
        });

        cards.sort((a,b) => a.priceInUSD < b.priceInUSD ? 1 : -1);

        const currentPageSet = this.getCurrentPageSet(0, cards);
        this.setState({cards: cards, filteredCards:[], pageCardSet: currentPageSet, init: false}, () => {
            this.filter(this.state.currentFilter);
            if (callback) {
                callback();
            }
        });
        if (isAllCards === true) {
            this.setState({allcards: cards}, () => { 
                this.filter(this.state.currentFilter);
                if (callback) {
                    callback();
                }
            });
        }
    }

    SetSets = (showAvailableOnly: boolean = true) : Promise<void> => {
        return new Promise((resolve, reject) => {
            GetSets(this.state.dev, false).then(sets => {
                this.ProcessSets(sets, showAvailableOnly);
                resolve();
            });
        });
    }

    ProcessSets = (sets : TSet[], showAvailableOnly: boolean) => {
        const oldSets = this.state.sets;
        let newSets : TSet[] = oldSets;
        let hiddensetids = GlobalProperties.HIDDEN_SETS.split(',');
        sets.forEach(oset => {
            oset.releasedate = new Date(oset.releasedate);
            if (hiddensetids.indexOf(oset.id.toString()) === -1) {
                if (newSets.filter(s => s.id === oset.id).length === 0) {
                    newSets.push(oset);
                }
            }
        });
        this.setState({sets: newSets});
        this.setSetMenuItems(newSets, showAvailableOnly);
        this.GetSetsCardCountAll(newSets);
    }

    GetSetsCardCountAll = (sets: TSet[]) => {
        GetCardsCountAll(this.state.dev, 1).then(cardCounts=> {
            sets.forEach(set => {
                const fsets = cardCounts.filter(c => c.setname === set.name);
                if (fsets.length === 1) {
                    set.cardcount = fsets[0].ammount;
                }
            });
            //this.setState({sets: sets});
            this.setSetMenuItems(sets, this.state.showAvailableOnly);
        });
    }

    GetSetsCardCount = (sets: TSet[], showAvailableOnly: boolean) => {
        if (this.state.showAvailableOnly) {
            sets.forEach(set => {
                GetSetCardCount(set.name, this.state.dev).then(cardCount => {
                    set.cardcount = cardCount;
                    this.setSetMenuItems(sets, showAvailableOnly);
                });
            });
            this.setState({sets: sets});
        }
    }

    setSetMenuItems = (sets: TSet[], showAvailableOnly:boolean) => {
        let basesets : TSet[] = [];
        let expsets : TSet[] = [];

        sets.sort((a,b) => a.releasedate <= b.releasedate ? 1 : -1);

        sets.forEach(set => {
            if (!IsNull(set.parentId) && set.parentId !== 0) {
                if (this.state.showAvailableOnly) { //
                    if (set.cardcount > 0) {
                        expsets.push(set);
                    }
                } else {
                    expsets.push(set);
                }
            }
        });

        if (this.state.showAvailableOnly) {
            expsets.forEach(set => {
                const baseset = sets.filter(s => s.id === set.parentId)[0];
                const addedbs = basesets.filter(s => s.id === baseset.id);
                if (!addedbs.length) {
                    basesets.push(baseset);
                }
            });
        } else {
            sets.forEach(set => {
                if (IsNull(set.parentId) || set.parentId === 0) {
                    basesets.push(set);
                }
            });
        }

        const currentBaseSet = sets.filter(set => set.id === this.state.selectedBaseSetKey)[0];
        let currentexpsets = expsets.filter(set => set.parentId === this.state.selectedBaseSetKey);
        currentexpsets.push(currentBaseSet);

        let menuItems: IContextualMenuItem[] = [];
        menuItems.push({key: "search", text: "Zoeken in alle sets", onRender(item , dismissMenu) {
            return  <NavLink to='/Zoeken' style={{textDecoration:"none",color:'black'}}>
                        <Stack style={{whiteSpace:"nowrap"}} verticalAlign='center' horizontalAlign='start' horizontal className={styles.menuItem}>
                            <IconButton iconProps={{iconName: 'Search'}}
                                        styles={{
                                            icon: {paddingLeft:0, color: 'blue', fontSize: parentThis.state.windowSize.x > 960 ? 16 : 14},
                                            root: {
                                                margin: 0,
                                                padding: 0,
                                        }}} />
                            <span className={styles.menuItem} >{GlobalProperties.Strings.SearchInAllSets}</span>
                        </Stack>
                    </NavLink>
        }});
        basesets.forEach(baseset => {
            let submenuItems: IContextualMenuItem[] = [];
            let subitems = expsets.filter(set => set.parentId === baseset.id);
            subitems.forEach(subitem => {
                let setName = subitem.name;
                if (subitem.cardcount) {
                    setName += ` (${subitem.cardcount})`;
                }
                submenuItems.push({
                    key: subitem.id.toString(),
                    text: setName,
                    //onClick: () => { this.onSetSelectedSet(subitem.id); },
                    onRender(item :IContextualMenuItem , dismissMenu) {
                        return <NavLink to='/Pokemon Kaarten' style={{textDecoration:"none",color:'black'}}>
                                    <div className={styles.menuItem} onClick={()=>{parentThis.onSetSelectedSet(subitem.id); dismissMenu(null,true); }}>
                                        {setName}
                                    </div>
                               </NavLink>
                }});
            });
            if (submenuItems.length) {
                let setName = baseset.name;
                if (baseset.cardcount) {
                   setName += ` (${baseset.cardcount})`;
                }
                submenuItems.push({
                    key: baseset.id.toString(),
                    text: setName,
                    //onClick: () => { this.onSetSelectedBaseSet(baseset.id); }
                    onRender(item :IContextualMenuItem , dismissMenu) {
                        return <NavLink to='/Pokemon Kaarten' style={{textDecoration:"none",color:'black'}}>
                                    <div className={styles.menuItem} onClick={()=>{parentThis.onSetSelectedSet(baseset.id); dismissMenu(null,true); }}>
                                        {setName}
                                    </div>
                               </NavLink>
                }});
                menuItems.push({
                    key: baseset.id.toString()+"_"+baseset.id.toString(),
                    text: baseset.expansion,
                    items: submenuItems,
                } as IContextualMenuItem);
            } else {
                let setName = baseset.name;
                if (baseset.cardcount) {
                   setName += ` (${baseset.cardcount})`;
                }
                menuItems.push({
                    key: baseset.id.toString(),
                    text: setName,
                    onMouseDown: () => { this.onSetSelectedBaseSet(baseset.id); }
                } as IContextualMenuItem);
            }
        });

        let allMenuItems: IContextualMenuItem[] = [];
        <NavLink to='/'>
            <PrimaryButton text='Alles' style={{width:"100%"}} onClick={()=>{this.setState({showSettings:false});}} />
        </NavLink>
        const parentThis = this;
        allMenuItems.push({key: "All", text: "Home", onRender(item , dismissMenu) {
            return <NavLink to='/' style={{textDecoration:"none"}} className={styles.menuItem}>
                        <div onClick={()=>{parentThis.setState({showSettings:false}); dismissMenu(null,true);}}>
                            <Stack horizontal>
                                <IconButton iconProps={{iconName: 'Home'}}
                                        styles={{
                                        icon: {paddingLeft:0, marginLeft:-2, color: 'blue', fontSize: parentThis.state.windowSize.x > 960 ? 16 : 14},
                                        root: {
                                            margin: 0,
                                            padding: 0,
                                        }}} />
                                <span className={styles.menuItem} >Home</span>
                            </Stack>
                        </div>
                   </NavLink>
        }});
        allMenuItems.push({key: "Cards", text: GlobalProperties.Strings.Cards, items: menuItems});

        let productMenuItems: IContextualMenuItem[] = [];
        productMenuItems.push({key: "Boosters", text: "Boosters", onRender(item :IContextualMenuItem, dismissMenu) {
            return <NavLink to='/Pokemon Boosters' style={{textDecoration:"none"}} className={styles.menuItem} onClick={()=>{parentThis.setState({showSettings:false}); dismissMenu(null,true);}}>
                        <div className={styles.menuItem} >
                            Boosters
                        </div>
                   </NavLink>
        }});
        productMenuItems.push({key: "Collections", text: GlobalProperties.Strings.Collections , onRender(item :IContextualMenuItem, dismissMenu) {
            return <NavLink to='/Pokemon Collecties' style={{textDecoration:"none"}} className={styles.menuItem} onClick={()=>{parentThis.setState({showSettings:false}); dismissMenu(null,true);}}>
                        <div className={styles.menuItem} >
                            {GlobalProperties.Strings.Collections}
                        </div>
                   </NavLink>
        }});
        productMenuItems.push({key: "Misc", text: GlobalProperties.Strings.Misc, onRender(item :IContextualMenuItem, dismissMenu) {
            return <NavLink to='/Pokemon Diverse' style={{textDecoration:"none"}} className={styles.menuItem} onClick={()=>{parentThis.setState({showSettings:false}); dismissMenu(null,true);}}>
                        <div className={styles.menuItem} >
                            {GlobalProperties.Strings.Misc}
                        </div>
                   </NavLink>
        }});
        allMenuItems.push({key: "Products", text: GlobalProperties.Strings.Products, items: productMenuItems});
        allMenuItems.push({key: "Marktplaats", onRender(item , dismissMenu) {
            return <NavLink to='/Marktplaats' style={{textDecoration:"none"}} className={styles.menuItem}>
                        <div onClick={()=>{parentThis.setState({showSettings:false}); dismissMenu(null,true);}}>
                            <Stack horizontal verticalAlign='center'>
                                <Image style={{width:16,paddingLeft:5,paddingRight:10}} src={process.env.PUBLIC_URL + `/assets/marktplaatsIcon.webp`} />
                                <span className={styles.menuItem} >{GlobalProperties.Strings.BuyOnMarktplaats}</span>
                            </Stack>
                        </div>
                   </NavLink>
        }});

        this.setState({setsMenu: menuItems,allMenu: allMenuItems});
        if (showAvailableOnly === false) {
            if (!this.state.allcards.length)
                this.setState({allsets: sets});
        } else {
            if (!this.state.availablesets.length)
                this.setState({availablesets: sets});
        }
    }

    onBuyClick = (product:TProduct) : boolean => {
        let shoppingkart : TShoppingCart = this.state.shoppingkart;
        let filtered = shoppingkart.items.filter( i => i.product.id === product.id);
        if (filtered.length) {
            if (filtered[0].ammount+1 <= product.available) {
                filtered[0].ammount++;
                this.setState({shoppingkart: shoppingkart});
                this.setState({toastMessage: `${product.title} ${GlobalProperties.Strings.AddedToCart}`});
                return true;
            }
        } else {
            shoppingkart.items.push({ammount: 1, product: product});
            this.setState({shoppingkart: shoppingkart});
            this.setState({toastMessage: `${product.title} ${GlobalProperties.Strings.AddedToCart}`});
            return true;
        }
        this.setState({toastMessage: GlobalProperties.Strings.NotAvailable, isToastError: true});
        return false;
    }

    onImageClick = (url:string) => {
        this.setState({popupUrl: url});
    }

    onSetSelectedSet = (value?: string | number) => {
        const setid : number = value as number;
        this.setState({ showOnly: ProductType.Cards, 
                        selectedSetKey: setid,
                        searchText: null,
                        searchAll: false,
                        showSettings: false}, ()=>{ 
                            this.SetCards(this.state.sets.filter(set => set.id == setid)[0].name, this.state.showAvailableOnly); 
                        });
    }

    clearSearch = () => { //nasty but effective
        this.setState({searchText: ""});
    }

    onSetSelectedBaseSet = (value?: string | number) => {
        const setid : number  = value as number;
        let currentexpsets    = this.state.sets.filter(set => set.parentId === setid);
        const currentBaseSet  = this.state.sets.filter(set => set.id === setid)[0];
        let currentexpsetid = currentexpsets.length ? currentexpsets[0].id : -1;
        let currentexpsetname = "";
        if (currentBaseSet.size && currentBaseSet.size > 0) {
            currentexpsetid = currentBaseSet.id;
            currentexpsetname = currentBaseSet.name;
        }
        this.setState({selectedBaseSetKey: setid, selectedSetKey: currentexpsetid, showSettings: false});
        this.SetCards(currentexpsetname, this.state.showAvailableOnly);
    }

    getCurrentPageSet = (newIndex:number, currentSet:TProduct[]) : TProduct[] => {
        const startIndex = newIndex*this.state.pageSize;
        let endIndex = startIndex+this.state.pageSize;
        if (endIndex > currentSet.length) {
            endIndex = currentSet.length;
        }
        const currentPageSet = currentSet.slice(startIndex, endIndex);
        this.setState({ pageIndex: newIndex,
                        numberOfPages: Math.round(currentSet.length/this.state.pageSize)});
        return currentPageSet;
    }

    onPagingChange = (newIndex:number) => {
        const currentSet = this.state.filteredCards.length ? this.state.filteredCards : this.state.cards;
        const currentPageSet = this.getCurrentPageSet(newIndex, currentSet);
        this.setState({ pageIndex: newIndex,
                        pageCardSet: currentPageSet
        });
    }

    onSmallCartClick = () => {
        this.setState({showCartPopup: true});
    }

    onPreCheckOut = () => {
        this.setState({showCheckOut: true, showCartPopup: false});
    }

    onCheckOutIDeal = (sale: TSale) => {
        let productids: TSaleProduct[] = [];
        this.state.shoppingkart.items.forEach(item => {
            for (let i=0; i<item.ammount; i++) {
                productids.push({id: item.product.id, setid: item.product.isProduct ? -1 : item.product.setID, ammount: item.ammount });
            }
        });
        sale.products = productids;
        //console.log(sale);
        let windowReference = window.open();
        GetIDealPayment(this.state.dev, sale).then(value => {
            //console.log(value);
            if (value.success != false) {
                windowReference.location = value.paymentResponse.links.checkout.href;
                setTimeout(() => {
                    this.CheckIDealComplete(value.paymentResponse.id);
                }, 5000);
                this.setState({waitingIdealPayment: true});
            } else {
                alert(value.errorString);
            }
        }).catch(err => {
            console.log(err);
            alert("Interne server fout.");
        });
    }

    CheckIDealComplete = (id:string) => {
        const interval = 2000;
        if (id && !this.state.paymentSuccess) {
            CheckIDealPayment(this.state.dev, id).then(result=> {
                //console.log({result});
                if (result.success === true) {
                    switch (result.paymentResponse.status) {
                        case "open" :
                            setTimeout(() => {
                                this.CheckIDealComplete(id);
                            }, interval);
                            break;
                        case "paid" :
                            this.setState({shoppingkart: {items: []}, paymentSuccess: true});
                            break;
                        default :
                            alert("Betaling niet gelukt. Status: " + result.paymentResponse.status);
                            break;
                    }
                } else {
                    console.log(result);
                    //alert("Betaling verwerking niet gelukt. "+result.errorString);
                }
            }).catch(err => {
                console.log(err);
                console.log("Unable to check iDeal payment");
            });
        } else {
            console.log("Unable to check iDeal payment");
        }
    }

    onCheckOut = (sale: TSale) => {
        let cardids: TSaleProduct[] = [];
        this.state.shoppingkart.items.forEach(item => {
            for (let i=0; i<item.ammount; i++) {
                cardids.push({id: item.product.id, setid: item.product.isProduct ? -1 : item.product.setID, ammount: item.ammount });
            }
        });
        sale.products = cardids;
        GetInvoiceV3(this.state.dev, sale).then(value => {
            if (value.success != false) {
                this.setState({invoice: value, invoiceTimoutPercentage: 0, invoiceCreatedAt: new Date()}, this.CheckInvoiceComplete);
            } else {
                alert(value.errorString);
            }
        }).catch(err => {
            alert("Interne server fout.");
        });
    }

    CheckInvoiceComplete = () => {
        if (this.state.invoice) {
            if (this.state.invoice.id)
            CheckInvoiceV3(this.state.dev, this.state.invoice.id).then((result : Boolean) => {
                if (result) {
                    this.setState({shoppingkart: {items: []}, paymentSuccess: true});
                } else {
                    const totalSeconds = 900; //15 minutes x 60 seconds
                    const interval = 5000;

                    if (this.state.invoiceCreatedAt) {
                        const ms = (new Date()).getTime() - this.state.invoiceCreatedAt?.getTime();
                        const perc = ((ms+interval) / 1000) / totalSeconds;
                        this.setState({invoiceTimoutPercentage : perc}, () => {
                            if (perc < 100) {
                                setTimeout(() => {
                                    this.CheckInvoiceComplete();
                                }, interval);
                            } else {
                                this.setState({invoiceTimedOut : true});
                            }
                        });
                    }
                }
            });
        }
    }

    ShowMessage = (msg: any, timeout: number = 2000, isError:boolean = true) => {
        setTimeout(() => {
            this.setState({toastMessage: msg, isToastError: isError, toastTimeout: timeout});
        }, 100);
    }

    ClosePayment = () => {
        this.SetSets(true).then(() => {
            this.SetCards(GlobalProperties.selectedSetName).then(()=>{
                this.setState({showCheckOut: false, invoice:undefined, paymentSuccess: false, waitingIdealPayment: false});
            });
        });
    }

    toggleAvailable = () => {
        if (this.state.showAvailableOnly === true) {
            this.SetCards(this.state.sets.filter(set => set.id === this.state.selectedSetKey)[0]?.name, false, true);
        } else {
            this.SetCards(this.state.sets.filter(set => set.id === this.state.selectedSetKey)[0]?.name, true, false);
        }
        
        if (this.state.showAvailableOnly === true) {
            let boosters : TProduct[] = this.state.boosters;
            let allBoosters : TProduct[] = this.state.allBoosters;
            let collections : TProduct[] = this.state.collections;
            let allCollections : TProduct[] = this.state.allCollections;
            let misc : TProduct[] = this.state.misc;
            let allMisc : TProduct[] = this.state.allMisc;

            if (this.state.allBoosters.length) {
                boosters = this.state.allBoosters;
            } else {
                GetProducts(ProductType.Booster, this.state.showAvailableOnly, this.state.dev).then(prods => {
                    const products = ProcessProducts(prods);
                    boosters = products;
                    allBoosters = products;
                }).catch(ex => {
                    console.log(ex);
                });
            }

            if (this.state.allCollections.length) {
                collections = this.state.allCollections;
            } else {
                GetProducts(ProductType.Collection, this.state.showAvailableOnly, this.state.dev).then(prods => {
                    const products = ProcessProducts(prods);
                    collections = products;
                    allCollections = products;
                }).catch(ex => {
                    console.log(ex);
                });
            }

            if (this.state.allMisc.length) {
                misc =this.state.allMisc;
            } else {
                GetProducts(ProductType.Misc, this.state.showAvailableOnly, this.state.dev).then(prods => {
                    const products = ProcessProducts(prods);
                    misc = products;
                    allMisc = products;
                }).catch(ex => {
                    console.log(ex);
                });
            }

            this.setState({
                boosters: boosters,
                collections: collections,
                misc: misc,
                allBoosters: allBoosters,
                allCollections: allCollections,
                allMisc: allMisc
            }, this.updateProducten);
        } else {
            this.setState({
                boosters: this.state.allBoosters.filter(prod => prod.available > 0),
                collections: this.state.allCollections.filter(prod => prod.available > 0),
                misc: this.state.allMisc.filter(prod => prod.available > 0),
            }, this.updateProducten);
        }

        this.setState({showAvailableOnly: !this.state.showAvailableOnly, showSettings: false}, () => {
            this.setSetMenuItems(this.state.sets, this.state.showAvailableOnly);
        }); 
    }

    copyToClipboard = (text:string) => {
        let clipBoardElem = document.createElement("input");
        document.body.appendChild(clipBoardElem);
        clipBoardElem.value = text;
        clipBoardElem.select();
        let successfulCopy = document.execCommand('copy');
        document.body.removeChild(clipBoardElem);
        if (successfulCopy) {
            this.setState({toastMessage: "Gekopieerd naar klembord"});
        } else {
            alert("Kopieren naar klembord mislukt");
        }
    }

    doSearch = () => {
        const newValue = this.state.searchText;
        if (newValue && newValue.length > 0) {
            this.state.searchAll && this.setState({ selectedSetKey: null });
            if (this.state.searchAll === false) {
                let cards = getFilteredCards(this.state.cards, this.state.currentFilter);
                if (cards?.length) {
                    const sstring = newValue?.toLocaleLowerCase();
                    if (sstring && !IsNull(sstring)) {
                        const filteredCards = cards.filter(card =>
                            card.title?.toLocaleLowerCase().indexOf(sstring) !== -1 ||
                            card.rarety?.toLocaleLowerCase().indexOf(sstring) !== -1 ||
                            card.scardnumber?.indexOf(sstring) !== -1 ||
                            card.version?.toLocaleLowerCase().indexOf(sstring) !== -1
                        );
                        const currentPageSet = this.getCurrentPageSet(0, filteredCards);
                        this.setState({
                            showSettings: false,
                            pageCardSet: currentPageSet,
                            filteredCards: filteredCards,
                        });
                    } else {
                        let currentPageSet = this.getCurrentPageSet(0, cards);
                        this.setState({
                            showSettings: false,
                            pageCardSet: currentPageSet,
                            filteredCards: cards
                        });
                    }
                }
            } else {
                if (newValue) {
                    SearchCards(newValue, this.state.showAvailableOnly, this.state.dev).then(scards => {
                        let cards = getFilteredCards(scards, this.state.currentFilter);
                        cards.forEach(card => {
                            if (card.number > 99) {
                                card.scardnumber = `${card.number}`;
                            } else if (card.number > 9) {
                                card.scardnumber = `0${card.number}`;
                            } else {
                                card.scardnumber = `00${card.number}`;
                            }
                            card.scardnumber += `/${card.size}`;

                            //TODO: move to backend, minimale prijs toevoegen
                            card.priceInUSD = Math.round(card.priceInUSD * 100) /100;
                            card.cost = Math.round(card.priceInUSD / this.state.satprice);
                            card.scost = `${card.cost}`;
                            let imageUrls: string[] = [];
                            card.imageNames.split(',').forEach(imageName => {
                                imageUrls.push(`${process.env.PUBLIC_URL}/assets/pokemon/${card.setID}/${card.number.toString()}/${imageName}`);
                            });
                            imageUrls.push(`${process.env.PUBLIC_URL}/assets/pokemon/cardback.webp`);
                            card.imageUrls = imageUrls;
                        });

                        cards.sort((a,b) => a.priceInUSD < b.priceInUSD ? 1 : -1);
                        let currentPageSet = this.getCurrentPageSet(0, cards);
                        this.setState({
                            showSettings: false,
                            pageCardSet: currentPageSet,
                            filteredCards: cards
                        });
                    });
                } else {
                    let cards = getFilteredCards(this.state.cards, this.state.currentFilter);
                    if (cards?.length) {
                        const sstring = newValue?.toLocaleLowerCase();
                        if (sstring && !IsNull(sstring)) {
                            const filteredCards = cards.filter(card =>
                                card.title?.toLocaleLowerCase().indexOf(sstring) !== -1 ||
                                card.rarety?.toLocaleLowerCase().indexOf(sstring) !== -1 ||
                                card.scardnumber?.indexOf(sstring) !== -1 ||
                                card.version?.toLocaleLowerCase().indexOf(sstring) !== -1
                            );
                            const currentPageSet = this.getCurrentPageSet(0, filteredCards);
                            this.setState({
                                showSettings: false,
                                pageCardSet: currentPageSet,
                                filteredCards: filteredCards,
                            });
                        } else {
                            let currentPageSet = this.getCurrentPageSet(0, cards);
                            this.setState({
                                showSettings: false,
                                pageCardSet: currentPageSet,
                                filteredCards: cards
                            });
                        }
                    }
                }
            }
        } else {
            const currentPageSet = this.getCurrentPageSet(0, this.state.cards);
            this.setState({
                showSettings: false,
                filteredCards: this.state.cards,
                pageCardSet: currentPageSet,
            });
        }
    }

    checkSearchTimeOut = () => {
        if (Date.now() - this.state.searchTime > 300) {
            this.setState({searchTime:null}, this.doSearch);
        } else {
            setTimeout(() => {
                this.checkSearchTimeOut();
            }, 100);
        }
    }

    onSearchBoxChange = (newValue?: string | undefined) => {
        if (this.state.firstLoad) {
            this.setState({firstLoad:false});
        }
        if (newValue === "") {
            this.setState({searchText: newValue, searchTime: null, selectedSetKey: this.state.cards.length ? this.state.cards[0].setID : null}, this.doSearch);
        } else {
            this.setState({searchText: newValue, searchTime: Date.now()}, this.checkSearchTimeOut);
        }
    }

    onRarityChoice = (e:any, option:any) => {
        let cards = this.state.cards;
        if (!IsNull(this.state.searchText) && this.state.filteredCards?.length) {
            cards = this.state.filteredCards;
        }
        if (!IsNull(this.state.selectedVersion)) {
            cards = cards.filter(card =>
                card.version === this.state.selectedVersion
            );
        }
        if (cards?.length) {
            const filteredCards = cards.filter(card =>
                card.rarety === option.text
            );
            const currentPageSet = this.getCurrentPageSet(0, filteredCards);
            this.setState({
                pageCardSet: currentPageSet,
                filteredCards: filteredCards,
                showSettings: false,
                selectedRarity: option.text
            }, () => { if (!IsNull(this.state.searchText)) {this.onSearchBoxChange(this.state.searchText); }});
        }
    }

    onVersionChoice = (e:any, option:any) => {
        let cards = this.state.cards;
        if (!IsNull(this.state.searchText) && this.state.filteredCards?.length) {
            cards = this.state.filteredCards;
        }
        if (!IsNull(this.state.selectedRarity)) {
            cards = cards.filter(card =>
                card.rarety === this.state.selectedRarity
            );
        }
        if (cards?.length) {
            const filteredCards = cards.filter(card =>
                card.version === option.text
            );
            const currentPageSet = this.getCurrentPageSet(0, filteredCards);
            this.setState({
                pageCardSet: currentPageSet,
                filteredCards: filteredCards,
                showSettings: false,
                selectedVersion: option.text
            }, () => { if (!IsNull(this.state.searchText)) {this.onSearchBoxChange(this.state.searchText); }});
        }
    }

    onPriceChoice = (e:any, max:number, range:[number,number] | undefined) => {
        const start = range? range[0]: 0;
        let end = range? range[1]: 500000;
        if (end === 10000) {
            end = 999999999;
        }
        let cards = this.state.cards;
        if (this.state.filteredCards?.length) {
            cards = this.state.filteredCards;
        }
        if (cards?.length) {
            const filteredCards = cards.filter(card => {
                const price = Math.round(card.priceInUSD / this.state.satprice)
                return price >= start && price <= end;
            });
            const currentPageSet = this.getCurrentPageSet(0, filteredCards);
            this.setState({
                pageCardSet: currentPageSet,
                filteredCards: filteredCards,
                showSettings: false
            });
        }
    }

    loadMore = () => {
        const newIndex = this.state.pageIndex === 0 ? 2 : this.state.pageIndex+1;
        const currentSet = this.state.filteredCards.length ? this.state.filteredCards : this.state.cards;
        let endIndex = this.state.pageSize*newIndex;
        if (endIndex > currentSet.length) {
            endIndex = currentSet.length;
        }

        const currentPageSet = currentSet.slice(0, endIndex);

        // console.log("loadMore");
        // console.log({newIndex});
        // console.log({endIndex});

        this.setState({ pageIndex: newIndex,
                        pageCardSet: currentPageSet,
                        numberOfPages: currentSet.length/this.state.pageSize });
    }

    onOnChainPayment = () => {
        if (this.state.invoice) {
            if (this.state.invoice.id) {
                GetBTCOnchain(this.state.dev, this.state.invoice.id).then(btcAddress => {
                    if (btcAddress.Success) {
                        this.setState({onChainAddress: btcAddress.Address});
                    } else {
                        //TODO: handle error
                        alert(btcAddress.ErrorString);
                    }
                }).catch(err => {
                    alert(err);
                });
            }
        }
    }

    onMouseOverImg = (event:any) => {
        console.log("onMouseOverImg");
        console.log(event.clientX === undefined ? event.touches[0].clientX : event.clientX)
        // var perspectivePx = 800;

        // var trackingAreaShiftX = event.target.offsetHeight;
        // var trackingAreaShiftY = event.target.offsetLeft;

        // var halfTrackingAreaWidth = event.target.width / 2;
        // var halfTrackingAreaHeight = event.target.height / 2;

        // var mouseCoordinateCorrectionX = trackingAreaShiftX + halfTrackingAreaWidth;
        // var mouseCoordinateCorrectionY = trackingAreaShiftY + halfTrackingAreaHeight;


        //     //  Translate cooridnates of the mouse ponter
        //     var x = event.clientX - mouseCoordinateCorrectionX;
        //     var y = event.clientY - mouseCoordinateCorrectionY;
        //     //  Calculate degrees of rotation with respect to their maximum values
        //     var rotationY = x * 10 / halfTrackingAreaWidth;
        //     var rotationX = -y * 10 / halfTrackingAreaHeight;
        //     //  Construct CSS transform setting string
        //     var transform = `perspective(${perspectivePx}px) rotate3d(1, 0, 0, ${rotationX}deg) rotate3d(0, 1, 0, ${rotationY}deg)`;
        //     //  Apply the transformation



        // // const x = event.clientX === undefined ? event.touches[0].clientX : event.clientX;
        // // const y = event.clientY === undefined ? event.touches[0].clientY : event.clientY;

        // // console.log(`${x} ${y}`);

        // // const width = window.innerWidth;
        // // const height = window.innerHeight;

        // // const windowCenterX = width / 2;
        // // const windowCenterY = height / 2;

        // // const transformedX = x - windowCenterX;
        // // const transformedY = y - windowCenterY;

        // // const transform = `perspective(${perspectivePx}px) rotate3d(1, 0, 0, ${transformedY/20}deg) rotate3d(0, 1, 0, ${transformedX/20}deg)`;

        // let style = {transform: transform};
        // this.setState({popupImgStyle: style});
    }

    filter = (filter: string = Filters.CardNumberDown) => {
        let cards: TProduct[] = [];

        if (this.state.searchAll) {
            cards = getFilteredCards(this.state.filteredCards?.length ? this.state.filteredCards : this.state.cards, filter);
        } else {
            cards = getFilteredCards(this.state.cards, filter);
        }

        const currentPageSet = this.getCurrentPageSet(0, cards);
        this.setState({currentFilter: filter, filteredCards:cards, pageCardSet: currentPageSet, showSettings: false}, () => {this.onPagingChange(0);} );
    }

    swipeEnd = (e) => {
        if (this.state.startSwipe != -1 && e.changedTouches[0].clientX != -1) {
            if (this.state.popUpSwipeRight && (e.changedTouches[0].clientX - this.state.startSwipe) > 20) {
                this.state.popUpSwipeRight();
            } else if (this.state.popUpSwipeLeft && (this.state.startSwipe - e.changedTouches[0].clientX) > 20) {
                this.state.popUpSwipeLeft();
            }
            this.setState({startSwipe: -1, endSwipe: -1});
        }
    }

    ShowImagePopup = (imageUrl: string, onSwipeLeft: Function, onSwipeRight: Function) => {
        this.setState({popupUrl: imageUrl, popUpSwipeLeft: onSwipeLeft, popUpSwipeRight: onSwipeRight});
    }

    SetSelectedProductForAlert = (prod: TProduct) => {
        this.setState({selectedAlertCard: prod});
    }

    SetAlertCallback = (email?: string, prod?: TProduct) => {
        if (email && prod) {
            SetAlert(prod.id, prod.setID, prod.name, email).then(result => {
                if (result) {
                    this.ShowMessage(GlobalProperties.Strings.AlertAdded,3000,false);
                }
            }).catch(err => {
                console.log(err);
                this.ShowMessage(GlobalProperties.Strings.AlertFailed,5000);
            });
        }
        this.setState({selectedAlertCard: null, userEmail: email});
    }
 
    public UpdateStateExtern = (props:string[], vals: any[]) : Promise<void>=> {
        return new Promise((resolve, reject) => {
            let stateObj = {};
            for (let i=0, l=props.length; i<l; i++) {
                const prop = props[i];
                const val = vals[i];
                stateObj[prop] = val;
                this.setState(stateObj, () => {
                    resolve();
                });
            }
        });
    }

    GetStateVarExtern = (prop:string) : any => {
        return this.state[prop];
    }

    public render(): React.ReactElement<ImainComponentProps> {       
        if (this.state.isDebug) console.log(this.state);    
        
        return (
            this.state.init == true ? <div></div> : this.props.showSearch ? <Search animationsEnabled={this.state.animationsEnabled} 
                                                                                    windowSize={this.state.windowSize} 
                                                                                    eurprice={this.state.eurprice} 
                                                                                    satprice={this.state.satprice} 
                                                                                    usdprice={this.state.usdprice}
                                                                                    onBuyClick={this.onBuyClick}
                                                                                    isDev={this.state.dev} 
                                                                                    onImageClick={this.onImageClick}
                                                                                    onPreCheckOut={this.onPreCheckOut}
                                                                                    onSmallCartClick={this.onSmallCartClick}
                                                                                    allMenu={this.state.allMenu}
                                                                                    shoppingkart={this.state.shoppingkart}  /> :
            <div className={styles.mainDiv}>
                <ShowToastComponent message={this.state.toastMessage} isError={this.state.isToastError} timeout={this.state.toastTimeout} callback={() => {this.setState({toastMessage: undefined, isToastError: undefined, toastTimeout: undefined})}}/>
                <Helmet>
                    <title>Pokémon Collectie</title>
                    <meta property="og:image" content="https://pokemoncollectie.nl/assets/metalogo.webp" />
                    <meta name="description" content="De nieuwste Pokémon kaarten, boosters en producten." />
                    <meta name="keywords" content={this.state.keywords} />
                    <meta name="author" content="DITC - Mark" />
                </Helmet>
{/* Added Panel settings */}
                <Panel
                    isOpen={this.state.showAddedPanel}
                    customWidth={this.state.windowSize.x > 960 ? `${this.state.windowSize.x/2}px` : '350px'}
                    type={PanelType.customNear}
                    isHiddenOnDismiss
                    isLightDismiss
                    onDismiss={(ev) => {if (this.state.showAddedPanel === true) { ev.preventDefault(); } else { this.setState({ showAddedPanel: false }); }}}
                    closeButtonAriaLabel="Sluiten"// You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                    hasCloseButton={false}
                    onLightDismissClick={() => {this.setState({ showAddedPanel: false });}}
                >
                    {this.state.showAddedPanel &&
                        <Stack>
                            <Stack horizontalAlign='center'>
                                <Stack horizontal style={{zIndex:1,position:"absolute",backgroundColor:"white",width:"95%",marginTop:-5}} tokens={{padding:5}}>
                                    <Stack style={{width:"100%"}}>
                                        <span style={{width:"100%",fontSize:"large",fontWeight:"600"}}>{GlobalProperties.Strings.RecentlyAdded}</span>
                                    </Stack>
                                    <Stack horizontalAlign='end'>
                                        <span style={{fontSize:"large",fontWeight:"600",marginRight:20,cursor:"pointer"}} onClick={() => { this.setState({ showAddedPanel: false }); }}>X</span>
                                    </Stack>
                                </Stack>
                                <IconButton /> {/* No clue why but somehow an orange/blue border is added on the first iconbutton in this component */}
                                {this.state.updatedCards.map(card => {
                                    return <Stack style={{width:"100%"}} horizontal tokens={{padding:5}}>
                                                <Stack horizontalAlign='center' >
                                                    <Image className={styles.setLogoMenuSmall} src={process.env.PUBLIC_URL + `/assets/pokemon/${card.setID}/${card.setID}.webp`} />
                                                </Stack>
                                                <Stack verticalAlign='center'>
                                                    <span style={{whiteSpace:"nowrap"}}>{card.lastAddedAmmout.toString()}x&nbsp;</span>
                                                </Stack>
                                                <Stack verticalAlign='center'>
                                                    <img className={styles.checkoutCardImg}
                                                            onClick={() => {this.setState({popupUrl: card.imageUrls[0], popUpSwipeLeft: null, popUpSwipeRight: null});}}
                                                            src={card.imageUrls[0]} alt="" />
                                                </Stack>
                                                <Stack verticalAlign='center' style={{paddingLeft:2,width:"100%"}}>
                                                    {card.scardnumber} - {card.title}
                                                    <PulseLabel style={{fontSize:"small!important",fontWeight:"bold"}} text={`€ ${parseFloat((card.priceInUSD * this.state.eurprice).toString()).toFixed(2)}`} />
                                                </Stack>
                                                <NavLink to='/' style={{textDecoration:"none"}}>
                                                    <Stack verticalAlign='center' horizontalAlign='center' className={styles.removeActive}>
                                                            <IconButton iconProps={{iconName: 'View'}}
                                                                        onClick={()=>{
                                                                            if (this.state.selectedSetKey === card.setID) {
                                                                                this.setState({showAddedPanel: false, searchText: card.scardnumber});
                                                                                this.onSearchBoxChange(card.scardnumber);
                                                                            } else {
                                                                                this.setState({ showOnly: ProductType.Cards, 
                                                                                                selectedSetKey: card.setID, 
                                                                                                showSettings: false}, ()=> { 
                                                                                                    this.SetCards(this.state.sets.filter(set => set.id == card.setID)[0].name, this.state.showAvailableOnly, false, () => {
                                                                                                        this.setState({showAddedPanel: false, searchText: card.scardnumber});
                                                                                                        this.onSearchBoxChange(card.scardnumber);
                                                                                                    }); 
                                                                                                });
                                                                            }
                                                                        }}
                                                                        styles={{icon: {fontSize: 20},
                                                                                root: {
                                                                                    border: 0,
                                                                                    margin: 0,
                                                                                    padding: 0,
                                                                                }}} />
                                                            <span style={{cursor:"pointer",fontSize:"xx-small"}}>{GlobalProperties.Strings.View}</span>
                                                    </Stack>
                                                </NavLink>
                                        </Stack>
                                })}
                                {this.state.updatedCards.length >= 199 && <Label>{GlobalProperties.Strings.RecentlyAddedMore1} <NavLink to='/Zoeken'>{GlobalProperties.Strings.SearchLowerCase}</NavLink> {GlobalProperties.Strings.RecentlyAddedMore2}</Label>}
                                {this.state.updatedCards.length === 0 && <Label>{GlobalProperties.Strings.RecentlyAddedNone}</Label>}
                                <br/>
                                <PrimaryButton text={GlobalProperties.Strings.Close} onClick={() => {this.setState({ showAddedPanel: false });}} style={{maxWidth:200}} />
                            </Stack>
                        </Stack>
                    }
                </Panel>
{/* Settings Panel settings */}
                <Panel
                    headerText="Menu"
                    isOpen={this.state.showSettings}
                    customWidth={this.state.windowSize.x > 960 ? '350px' : '280px'}
                    type={PanelType.custom}
                    isHiddenOnDismiss={true}
                    isLightDismiss
                    onDismiss={() => {this.setState({ showSettings: false });}}
                    closeButtonAriaLabel="Sluiten"// You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                >
                    {this.state.showSettings &&
                        <Stack tokens={this.cardTokens} horizontalAlign='start'>
                            <div className={styles.filterContainers}>
                                <PrimaryButton style={{width:"100%"}} menuProps={{items: this.state.allMenu, shouldFocusOnMount: true, subMenuHoverDelay: 250}} >Menu</PrimaryButton>
                            </div>
                            <div className={styles.filterContainers}>
                                <Stack>
                                    <Label style={{fontWeight:"bold",paddingBottom:5,marginBottom:5}}>Site taal / language</Label>
                                    <Stack horizontal>
                                        <div style={{paddingTop:5,paddingRight:10,cursor:"pointer"}} className={styles.nl} onClick={()=>{GlobalProperties.Strings = Nederlands; GlobalProperties.Refresh(); }}></div>
                                        <Label style={{cursor:"pointer"}} onClick={()=>{GlobalProperties.Strings = Nederlands; GlobalProperties.Refresh(); }}>Nederlands</Label>
                                    </Stack>
                                    <Stack horizontal>
                                        <div style={{paddingTop:5,paddingRight:10,cursor:"pointer"}} className={styles.eng} onClick={()=>{GlobalProperties.Strings = English; GlobalProperties.Refresh(); }}></div>
                                        <Label style={{cursor:"pointer"}} onClick={()=>{GlobalProperties.Strings = English; GlobalProperties.Refresh(); }}>English</Label>
                                    </Stack>
                                    <Label style={{marginTop:5}}>* Delivery is only done within the Netherlands. For delivery outsite the Netherlands please contact us for options.</Label>
                                </Stack>
                            </div>
                            <div className={styles.filterContainers}>
                                <ChoiceGroup disabled={this.state.showOnly != ProductType.All && this.state.showOnly != ProductType.Cards} className={styles.filterChoice} defaultSelectedKey="B" selectedKey={this.state.currentFilter} options={this.filterOptions} onChange={(ev, option) => { this.filter(option ? option.key : Filters.PriceUp); }} label="Filters" required={true} />
                            </div>
                            <div className={styles.filterContainers}>
                                <Toggle label={GlobalProperties.Strings.Animations} checked={this.state.animationsEnabled} inlineLabel onChange={() => {this.setState({animationsEnabled: !this.state.animationsEnabled });}} />
                            </div>
                            <div className={styles.filterContainers}>
                                <Toggle label={GlobalProperties.Strings.SoldoutToggle} checked={!this.state.showAvailableOnly} inlineLabel onChange={this.toggleAvailable} />
                            </div>
                            <div className={styles.filterContainers}>
                                <Toggle label={GlobalProperties.Strings.SearchInAllSets} checked={this.state.searchAll} inlineLabel onChange={() => {this.setState({searchAll: !this.state.searchAll}, ()=> { this.setState({keepFilterMenuOpen: true}); this.state.searchText && this.onSearchBoxChange(this.state.searchText); }); }}/>
                            </div>
                            <div className={styles.filterContainers}>
                                <NavLink to='/Zoeken' style={{textDecoration:"none",color:'black'}}>
                                    <Stack style={{whiteSpace:"nowrap"}} verticalAlign='center' horizontalAlign='start' horizontal>
                                        <IconButton iconProps={{iconName: 'Search'}}
                                                    styles={{
                                                        icon: {color: 'blue', fontSize: this.state.windowSize.x > 960 ? 16 : 14},
                                                        root: {
                                                            height: "auto",
                                                            margin: 2,
                                                            padding: 2,
                                                    }}} />
                                        <Label style={{cursor:"pointer"}}>{GlobalProperties.Strings.SearchInAllSets}</Label>
                                    </Stack>
                                </NavLink>
                            </div>
                            <Footer />
                        </Stack>
                    }
                </Panel>
{/* Shopping cart */}
                <Dialog
                    hidden={!this.state.showCartPopup}
                    dialogContentProps={{type:DialogType.normal}}
                    onDismiss={() => {this.setState({showCartPopup : false});}}
                    modalProps={{styles: {main : { maxHeight: "100%!important", width: 800, height: 800, maxWidth: "100%!important", backgroundColor: "transparent", boxShadow: "none", overflowY:"auto"}}}}
                >
                    <Stack horizontalAlign='center'>
                        <Shoppingcart ShowMessage={this.ShowMessage} eurprice={this.state.eurprice} usdprice={this.state.usdprice} showPopup={(v:string) => {this.setState({popupUrl: v});}} satprice={this.state.satprice} shoppingcart={this.state.shoppingkart} windowsSize={{x: 961, y: 0}} onCheckOut={this.onPreCheckOut} onSmallCartClick={()=>{this.setState({showCartPopup : false});}}/>
                    </Stack>
                </Dialog>
{/* PopUp image popup*/}
                <Dialog
                    hidden={!this.state.popupUrl}
                    modalProps={{isBlocking: false, styles: { main : { maxHeight: this.state.windowSize.y - ((this.state.windowSize.y/100) *10), width: "100%", maxWidth: "90%!important", backgroundColor: "transparent", boxShadow: "none", overflowY:"visible", overflowX:"visible"}}}}
                    onDismiss={() => {this.setState({popupUrl : undefined})}}
                >
                    <Stack horizontalAlign='center'>
                        <Image  className={styles.popupImage}
                                style={{maxHeight: this.state.windowSize.y - ((this.state.windowSize.y/100) * 25), height: this.state.windowSize.y - ((this.state.windowSize.y/100) * 25)}}
                                //onTouchMove={this.onMouseOverImg}
                                onError={(e) => { e.currentTarget.onerror = null;
                                                  e.currentTarget.src=`${this.state.popupUrl}`}}
                                onClick={() => {this.setState({popupUrl : undefined})}}
                                src={this.state.popupUrl?.replace(".webp",".png")}
                                alt=""
                                //onMouseMove={this.onMouseOverImg}
                                onTouchStart={(e) => { this.setState({startSwipe: e.targetTouches[0].clientX}); }}
                                onTouchMove={(e) => { this.setState({endSwipe: e.targetTouches[0].clientX}); }}
                                onTouchEnd={this.swipeEnd.bind(this)}
                        />
                    </Stack>
                </Dialog>
{/* Invoice popup */}
                <Dialog
                    hidden={!this.state.invoice}
                    dialogContentProps={{type:DialogType.normal}}
                    onDismiss={() => {this.ClosePayment();}}
                    modalProps={this.state.paymentSuccess ? {styles: {scrollableContent : {maxHeight:"100%",maxWidth:"100%",width:"100%"}, main : { maxHeight: "100%!important", width: "100%", height: "100%", maxWidth: "95%!important", backgroundColor: "transparent", boxShadow: "none", overflowY:"hidden"}}}
                                                        : {styles: {scrollableContent : {maxHeight:"100%",maxWidth:"100%"}, main : { maxHeight: "100%!important", width: 800, maxWidth: "95%!important", backgroundColor: "white", boxShadow: "none", overflowY:"hidden"}}}}
                >
                    <Stack className={styles.invoiceMain} horizontalAlign='center'> {/* TODO: component van maken */}
                        <h2>Betaling {this.state.invoice?.sats} sats, € {Math.round(this.state.invoice?.sats * this.state.satprice * this.state.eurprice * 100) / 100}</h2>
                        {this.state.onChainAddress ?
                            <div>
                                <br />
                                <br />
                                <Label><u>Uw uniek betaal adres:</u></Label>
                                <div className={`${styles.aPanel} ${styles.clickable}`} onClick={() => {this.state.invoice?.invoice && this.copyToClipboard(this.state.onChainAddress?this.state.onChainAddress:"");}}>
                                    <Stack horizontal className={`${styles.clickable} ${styles.invoiceStack}`} verticalAlign='center'>
                                        <FontIcon aria-label="Kopieer" iconName="copy" className={styles.copyInvoiceIcon} />
                                        <Label className={`${styles.invoiceLabel} ${styles.onchainLabel}`}>{this.state.onChainAddress}</Label>
                                    </Stack>
                                </div>
                                <br />
                                <Label><u>Bitcoin bedrag:</u></Label>
                                <div className={`${styles.aPanel} ${styles.clickable}`} onClick={() => {this.state.invoice?.invoice && this.copyToClipboard((this.state.invoice?.sats/100000000).toString());}}>
                                    <Stack horizontal className={`${styles.clickable} ${styles.invoiceStack}`} verticalAlign='center'>
                                        <FontIcon aria-label="Kopieer" iconName="copy" className={styles.copyInvoiceIcon} />
                                        <Label className={`${styles.invoiceLabel} ${styles.onchainLabel}`}>BTC {this.state.invoice?.sats/100000000}</Label>
                                    </Stack>
                                </div>
                                <Stack horizontalAlign='center'>
                                    <ProgressIndicator className={styles.invoiceProgressIndicator} />
                                    <br/>
                                    <Stack horizontalAlign='start'>
                                        <Label><b>Let op:</b></Label>
                                        <li>Betaal uw bestelling in 1 transactie.</li>
                                        <li>Uw transactie is valide na 6 bevestigingen.</li>
                                        <li>Indien uw betaling na 48 uur niet door ons is ontvangen word uw bestelling verwijderd.</li>
                                    </Stack>
                                    <br/>
                                    <Label>Zodra uw betaling door ons is ontvangen ontvangt u een bevestiging van uw bestelling.</Label>
                                    <br/>
                                    <PrimaryButton text="Sluiten" onClick={()=>{this.ClosePayment();}}/>
                                </Stack>
                            </div> :
                            this.state.paymentSuccess ?
                                <div style={{height: "100%", width: "100%" }}>
                                    <Stack horizontalAlign='center'>
                                        <img className={styles.invoiceImage} onClick={() => {this.ClosePayment();}} src={`${process.env.PUBLIC_URL}/assets/success.gif`} alt="" />
                                        <h2>Betaling ontvangen</h2>
                                        <Label className={styles.betalingOntvangenLabel}>We sturen een bevestiging van je bestelling naar je mailbox.</Label>
                                        <PrimaryButton text="Sluiten" onClick={() => {this.ClosePayment();}} />
                                        <br/>
                                    </Stack>
                                    <MessageBar messageBarType={MessageBarType.severeWarning}>We horen van sommige klanten dat onze emails naar de spam folder worden gezet.</MessageBar>
                                </div>
                            :   <div>
                                    <img className={styles.invoiceImage} onClick={() => {this.ClosePayment();}} src={`data:image/jpeg;base64,${this.state.invoice?.qrCode}`} alt="" />
                                    <Label>Scan de QR Code of kopieer de invoice naar je betaal wallet.</Label>
                                    <div className={`${styles.aPanel} ${styles.clickable}`} onClick={() => {this.copyToClipboard(this.state.invoice?.invoice);}}>
                                        <Stack horizontal className={`${styles.invoiceStack} ${styles.clickable}`} verticalAlign='center'>
                                            <FontIcon aria-label="Kopieer" iconName="copy" className={styles.copyInvoiceIcon} />
                                            <Label className={styles.invoiceLabel}>{this.state.invoice?.invoice}</Label>
                                        </Stack>
                                    </div>
                                    {this.state.invoiceTimoutPercentage > 0.04 ?
                                    <ProgressIndicator className={styles.invoiceProgressIndicator} percentComplete={this.state.invoiceTimoutPercentage}/> :
                                    <ProgressIndicator className={styles.invoiceProgressIndicator} />}
                                    <Stack horizontal horizontalAlign='center'>
                                        <PrimaryButton text="Via onchain transactie betalen" onClick={this.onOnChainPayment} />
                                    </Stack>
                                </div>}
                    </Stack>
                </Dialog>
{/* iDeal popup */}
                <Dialog
                    hidden={!this.state.waitingIdealPayment}
                    dialogContentProps={{type:DialogType.normal}}
                    onDismiss={() => {this.ClosePayment();}}
                    modalProps={this.state.paymentSuccess ? {styles: {root :  { backgroundImage: "url('"+process.env.PUBLIC_URL+"/assets/party.gif')"}, main : { maxHeight: "100%!important", maxWidth: "100%!important", backgroundColor: "transparent", boxShadow: "none", overflowY:"auto"}}}
                                                        : {styles: {main : { maxHeight: "100%!important", maxWidth: "100%!important", backgroundColor: "white", boxShadow: "none", overflowY:"auto"}}}}
                >
                    {this.state.paymentSuccess ?
                        <div style={{height: "100%", width: "100%"}}>
                            <Stack horizontalAlign='center' style={{borderRadius:10,backgroundColor: "white"}}>
                                <img className={styles.invoiceImage} onClick={() => {this.ClosePayment();}} src={`${process.env.PUBLIC_URL}/assets/success.gif`} alt="" />
                                <h2>Betaling ontvangen</h2>
                                <Label className={styles.betalingOntvangenLabel}>We sturen een bevestiging van je bestelling naar je mailbox.</Label>
                                <MessageBar messageBarType={MessageBarType.severeWarning}>We horen van sommige klanten dat onze emails naar de spam folder worden gezet.</MessageBar><br/>
                                <PrimaryButton text="Sluiten" onClick={() => {this.ClosePayment();}} />
                                <br/>
                            </Stack>
                        </div>
                        :
                        <Stack horizontalAlign='center'>
                            <h2>We wachten op uw betaling</h2>
                            <ProgressIndicator className={styles.invoiceProgressIndicator} />
                            <label>Zodra we de betaling hebben ontvangen ontvangt u van ons een email met uw bestelling.</label>
                        </Stack>}
                </Dialog>
                {this.state.selectedAlertCard && <EmailAlertComponent card={this.state.selectedAlertCard} userEmail={this.state.userEmail} callBack={this.SetAlertCallback}/>}
                <Stack horizontalAlign='center'>
                    {this.state.showLocaleBanner && <NonNLbannerComponent discard={() => {this.setState({showLocaleBanner: false});}}/>}
                    {this.state.showBanner && <BannerComponent content=<div>
                                                                            <span dangerouslySetInnerHTML={{ __html: GlobalProperties.SITE_MESSAGE.replace("[LINKTOALERTHOWTO]","") }} />
                                                                            <Stack horizontal horizontalAlign='center'>
                                                                                {GlobalProperties.SITE_MESSAGE.indexOf("[LINKTOALERTHOWTO]") !== -1 && 
                                                                                    <DefaultButton text={GlobalProperties.Strings.ShowHow} style={{marginTop: 15}} onClick={this.ShowAlertCoachmarks} /> }
                                                                                <PrimaryButton text={GlobalProperties.Strings.Hide} style={{marginTop: 15, marginLeft: 5}} onClick={() => {this.setState({showBanner: false, alertCoachmarkVisible: false});}} />
                                                                            </Stack>
                                                                        </div> />}
                
                    <Header btcprice={this.state.btcprice} eurprice={this.state.eurprice}  />

                    {this.state.reviewId ? <Review id={this.state.reviewId} isDev={this.state.dev} />
                        : this.state.showCheckOut || this.state.invoice ? <CheckoutComponent onCheckOutIDeal={this.onCheckOutIDeal} usdprice={this.state.usdprice} eurprice={this.state.eurprice} debug={this.state.isDebug} ShowMessage={this.ShowMessage} onCancel={()=>{this.setState({showCheckOut:false});}} onCheckOut={this.onCheckOut} satprice={this.state.satprice} shoppingcart={this.state.shoppingkart} />
                        :
                    <div className={styles.mainSection} >
                        {GlobalProperties.PROMO1_IMAGE_URL && //<NavLink to='/Promo'>
                            <Image className={`${styles.promoLogo} ${this.state.animationsEnabled && styles.pulse5} ${styles.promoRight}`} src={`${process.env.PUBLIC_URL}${GlobalProperties.PROMO1_IMAGE_URL}`} alt="" imageFit={ImageFit.cover} />
                        //</NavLink>
                        }
                        {GlobalProperties.PROMO2_IMAGE_URL && <NavLink to='/Promo2'>
                            <Stack horizontalAlign='end'>
                                <Image className={`${styles.promoLogo} ${this.state.animationsEnabled && styles.pulse5}`} src={`${process.env.PUBLIC_URL}${GlobalProperties.PROMO2_IMAGE_URL}`} alt="" imageFit={ImageFit.cover} />
                            </Stack>
                        </NavLink>}
{/* Header menu  */}    <Stack className={styles.headerMainMenu} style={{overflowY:'visible'}} horizontalAlign='start'>
                            <Stack horizontal horizontalAlign="start" style={{width:"100%"}}  >
                                <Stack horizontal style={{width:"100%"}} horizontalAlign='center'>
                                    <Stack verticalAlign='center' style={{paddingLeft:5}}>
                                        <FiltersComponent   content={   <Stack>
                                                                            <Stack verticalAlign='center' horizontal onClick={() => {this.setState({searchAll: !this.state.searchAll}, ()=> { this.setState({keepFilterMenuOpen: true}); this.state.searchText && this.onSearchBoxChange(this.state.searchText); }); }}>
                                                                                <Toggle style={{marginRight:5,marginBottom:0}} checked={this.state.searchAll} inlineLabel />
                                                                                <Label style={{fontSize:this.state.windowSize.x > 400 ? "small" : "x-small",float:"right",color:"gray",cursor:"pointer",marginTop:-10}}>{GlobalProperties.Strings.SearchInAllSets}</Label>
                                                                            </Stack>
                                                                            <Stack horizontal verticalAlign='center' onClick={() => {this.setState({keepFilterMenuOpen: true}); this.toggleAvailable(); }}>
                                                                                <Toggle style={{marginRight:5,marginBottom:-7}} checked={!this.state.showAvailableOnly} inlineLabel />
                                                                                <Label style={{fontSize:this.state.windowSize.x > 400 ? "small" : "x-small",float:"right",color:"gray",cursor:"pointer",marginTop:-3}} >{GlobalProperties.Strings.SoldoutToggle}</Label>
                                                                            </Stack>
                                                                            <ChoiceGroup className={styles.filterChoice} defaultSelectedKey="B" 
                                                                                            selectedKey={this.state.currentFilter} 
                                                                                            options={this.filterOptions} 
                                                                                        //  label="Filters"
                                                                                            onChange={(ev, option) => { this.filter(option? option.key : Filters.PriceUp); }} />
                                                                        </Stack>} 
                                                            preDisable={() => { let val=this.state.keepFilterMenuOpen; 
                                                                                this.setState({keepFilterMenuOpen: false}); 
                                                                                return val; }} />
                                        <span style={{fontSize:"xx-small", padding:"0px",paddingLeft:"2px",margin:"0px"}}>Filter</span>
                                    </Stack>
                                    <div className={`${styles.mainMenuItems} ${styles.searchBox}`}>
                                        <Stack horizontal tokens={this.cardTokens}>
                                            <TextField  type="Search"
                                                        iconProps={{
                                                            iconName:'search',
                                                            onClick:()=> {this.onSearchBoxChange(this.state.searchText)}
                                                        }}
                                                        disabled={this.state.showOnly != ProductType.All && this.state.showOnly != ProductType.Cards}
                                                        className={styles.searchBox}
                                                        value={this.state.searchText}
                                                        placeholder={this.state.searchAll ? GlobalProperties.Strings.SearchInAllSets : GlobalProperties.Strings.SearchInSet}
                                                        onChange={(e,newvalue) => this.onSearchBoxChange(newvalue)} />
                                        </Stack>
                                    </div>
                                    <Stack horizontalAlign='end' tokens={this.cardTokens}>
                                        <Image className={styles.menuIcon} src={`${process.env.PUBLIC_URL}/assets/Hamburger_icon.svg.webp`} alt="Menu" onClick={() => {this.setState({ showSettings: true });}}/>
                                        <span style={{fontSize:"xx-small", padding:"0px",paddingRight:"8px",margin:"0px",marginTop:"-5px"}}>Menu</span>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack style={{whiteSpace:"nowrap",width:"100%"}} wrap verticalAlign='center' horizontalAlign='start' horizontal className={styles.mainButtonSection} >
                                
                            </Stack>
                        </Stack>
                        {this.state.firstLoad ? <WelcomeComponent ShowAlertCoachmarks={this.ShowAlertCoachmarks} 
                                                                  GetStateVarExtern={this.GetStateVarExtern} 
                                                                  UpdateStateExtern={this.UpdateStateExtern} 
                                                                  onSetSelectedSet={this.onSetSelectedSet} 
                                                                  DoSearch={this.onSearchBoxChange} 
                                                                  Sets={this.state.sets} /> 
                                              : this.props.showText ? <div style={{paddingLeft:10,color:"rgb(100,100,100)"}}>
                                                                        <div dangerouslySetInnerHTML={{ __html: GlobalProperties.Strings[this.props.showText] }} />
                                                                      </div> :
                        <div className={`${styles.divTable}`}>
                            <div className={styles.divTableRow}>
                                <div className={`${styles.divTableCell} ${styles.cardsSection}`}>
                                    {this.state.showOnly === ProductType.Single ?
                                            <ProductViewerComponent SetSelectedProductForAlert={this.SetSelectedProductForAlert} 
                                                                    SetSelectedSet={this.onSetSelectedSet}
                                                                    allProducts={this.state.boosters.concat(this.state.collections.concat(this.state.misc))} 
                                                                    windowSize={this.state.windowSize} 
                                                                    onBuyClick={this.onBuyClick} 
                                                                    EurPrice={this.state.eurprice} 
                                                                    SatPrice={this.state.satprice} 
                                                                    onImageClick={this.ShowImagePopup}/>
                                        : this.state.showOnly === ProductType.Booster ?
                                            <Boosters SetSelectedProductForAlert={this.SetSelectedProductForAlert} AlertCoachmarkVisible={this.state.alertCoachmarkVisible} products={this.state.boosters} onBuyClick={this.onBuyClick} isdev={this.state.dev} eurprice={this.state.eurprice} satprice={this.state.satprice} onImageClick={this.ShowImagePopup} windowSize={this.state.windowSize} />
                                        : this.state.showOnly === ProductType.Collection ?
                                            <Collections SetSelectedProductForAlert={this.SetSelectedProductForAlert} AlertCoachmarkVisible={this.state.alertCoachmarkVisible} products={this.state.collections} onBuyClick={this.onBuyClick} isdev={this.state.dev} eurprice={this.state.eurprice} satprice={this.state.satprice} onImageClick={this.ShowImagePopup} windowSize={this.state.windowSize} />
                                        : this.state.showOnly === ProductType.Misc ?
                                            <Misc SetSelectedProductForAlert={this.SetSelectedProductForAlert} AlertCoachmarkVisible={this.state.alertCoachmarkVisible} products={this.state.misc} onBuyClick={this.onBuyClick} isdev={this.state.dev} eurprice={this.state.eurprice} satprice={this.state.satprice} onImageClick={this.ShowImagePopup} windowSize={this.state.windowSize} />
                                        : this.state.showOnly === ProductType.Promo ?
                                            <PromoComponent  windowWidth={this.state.windowSize.x} eurprice={this.state.eurprice} satprice={this.state.satprice} />
                                        : this.state.showOnly === ProductType.Promo2 ?
                                            <Promo2Component windowWidth={this.state.windowSize.x} eurprice={this.state.eurprice} satprice={this.state.satprice} />
                                        : <div></div>}                                                                 
                                    {this.state.showOnly == ProductType.All || this.state.showOnly == ProductType.Cards ? this.state.pageCardSet.length ?
                                                                    <Stack className={styles.mainSection}>
                                                                        <Stack horizontal style={{width:"100%", cursor:"pointer"}} > 
                                                                            <Stack horizontal >
                                                                                {/* <Stack>
                                                                                    <Label className={styles.boosterLabel} >{GlobalProperties.Strings.Cards}</Label>
                                                                                </Stack> */}
                                                                            </Stack>
                                                                        </Stack>
                                                                        {/* <hr className={styles.mainhr} /> */}
                                                                        {this.state.showcards && <Stack horizontal wrap tokens={this.cardTokens} horizontalAlign='start' style={{maxWidth:""}}>
                                                                                                    {this.state.pageCardSet.map(card => {
                                                                                                        return <PokemonCards hideSwipe={this.state.windowSize.x < 800 ? false : true} 
                                                                                                                                AlertCoachmarkVisible={this.state.alertCoachmarkVisible}
                                                                                                                                eurprice={this.state.eurprice} 
                                                                                                                                animationsEnabled={this.state.animationsEnabled && IsNull(this.state.popupUrl)} 
                                                                                                                                card={card} 
                                                                                                                                onBuyClick={this.onBuyClick} 
                                                                                                                                satprice={this.state.satprice} 
                                                                                                                                SetSelectedProductForAlert={this.SetSelectedProductForAlert}
                                                                                                                                onImageClick={this.ShowImagePopup}/>
                                                                                                    })}
                                                                                                </Stack>}
                                                                        <br/>
                                                                        <Stack horizontal horizontalAlign='center' tokens={this.cardTokens}>
                                                                            <PrimaryButton onClick={() => {this.state.numberOfPages > this.state.pageIndex && this.loadMore();}} className={`${styles.loadMoreButton} ${this.state.numberOfPages <= this.state.pageIndex ? styles.disabledButton : styles.pulse}`} text={GlobalProperties.Strings.LoadMoreCards} />
                                                                            <DefaultButton onClick={()=>{window.scroll(0, 0);}}>{GlobalProperties.Strings.ScrollToTop}</DefaultButton>
                                                                        </Stack>
                                                                    </Stack>
                                                                : <Stack horizontalAlign='center'>
                                                                        <Image src={process.env.PUBLIC_URL + `/assets/NothingFound.webp`} alt="" imageFit={ImageFit.contain} style={{height:300}}/>
                                                                        <Label className={styles.nothingFoundLabel}>{GlobalProperties.Strings.NoCardsAvailable}</Label>
                                                                        <Toggle label={GlobalProperties.Strings.SoldoutToggle} checked={!this.state.showAvailableOnly} inlineLabel onChange={this.toggleAvailable} />
                                                                    </Stack> : <div></div>}
                                    {(this.state.showOnly === ProductType.Cards || this.state.showOnly === ProductType.All) && this.state.producten.length > 0 && <Producten products={this.state.producten} onBuyClick={this.onBuyClick} isdev={this.state.dev} eurprice={this.state.eurprice} satprice={this.state.satprice} onImageClick={this.ShowImagePopup} windowSize={this.state.windowSize} AlertCoachmarkVisible={this.state.alertCoachmarkVisible} SetSelectedProductForAlert={this.SetSelectedProductForAlert} />}
                                </div>
                                <div className={`${styles.divTableCell}`}>
                                    <div className={`${styles.shoppingcart}`}>
                                        <Stack style={{width:"100%"}} horizontalAlign='end' >
                                            <Shoppingcart eurprice={this.state.eurprice} 
                                                        showPopup={(v:string) => {this.setState({popupUrl: v});}} 
                                                        ShowMessage={this.ShowMessage}
                                                        satprice={this.state.satprice} 
                                                        usdprice={this.state.usdprice} 
                                                        shoppingcart={this.state.shoppingkart} 
                                                        windowsSize={this.state.windowSize} 
                                                        onCheckOut={this.onPreCheckOut} 
                                                        onSmallCartClick={this.onSmallCartClick}/>
                                        </Stack>
                                    </div>
                                    <div>
                                        {(this.state.showOnly == ProductType.All || this.state.showOnly == ProductType.Cards) &&
                                            <SetsMenuComponent sets={this.state.sets} onSetSelectedSet={this.onSetSelectedSet} selectedSetKey={this.state.selectedSetKey} />}
                                        <div className={`${styles.setLogoContainer}`}>
                                            <Stack style={{width:"100%"}} horizontalAlign='center' horizontal>
                                                <IconButton iconProps={{iconName: 'AddNotes'}} styles={{
                                                    icon: {color: 'blue', fontSize: this.state.windowSize.x > 960 ? 28 : 14},
                                                    root: {
                                                        margin: 0,
                                                        padding: 0,
                                                        selectors: {
                                                        '& .ms-Button-icon:hover, .ms-Button-flexContainer:hover': {
                                                            backgroundColor: 'lightblue',
                                                            color: 'darkblue'
                                                        },
                                                        }
                                                    },
                                                    }} title="Meer" src={`${process.env.PUBLIC_URL}/assets/Hamburger_icon.svg.webp`} text={this.state.sets.find(set => set.id == this.state.selectedSetKey)?.name} menuProps={{items: this.state.allMenu, shouldFocusOnMount: true, subMenuHoverDelay: 250}} />
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {this.state.windowSize.x < 400 && this.state.showOnly === ProductType.All && !this.state.firstLoad &&
                                                                            this.state.showReviews &&
                                                                            <div style={{maxWidth: this.state.windowSize.x - 25, overflow:"hidden"}}>
                                                                                <CarouselComponent animationsEnabled={this.state.animationsEnabled} isDev={this.state.dev} />
                                                                            </div>
                                                                        }
{/* Footer */}
                        {this.state.firstLoad ? null : <Footer />}
                    </div>}
                </Stack>
            </div>
        );
    }
}