import { Stack, Image, IStackTokens, ImageFit } from '@fluentui/react';
import React from 'react';
import { TProduct, TSet } from '../constants/types';
import { NavLink } from 'react-router-dom';
import styles from '../styles/pokemon.module.scss';
import CarouselComponent from './carouselComponent';
import MainCarouselComponent, { TCarouselItem } from './mainCarouselComponent';
import GlobalProperties from '../helpers/GlobalProperties';
import PokemonCards from './cardsComponent';

export interface IWelcomeComponentProps {
    UpdateStateExtern: Function;
    GetStateVarExtern: Function;
    onSetSelectedSet: Function;
    ShowAlertCoachmarks: Function;
    Sets: TSet[];
    DoSearch: Function;
}

export interface IWelcomeComponentState {
    carouselItems: TCarouselItem[];
    maxIndex: number;
    sets: TSet[];
    cards: TProduct[];
};

export default class WelcomeComponent extends React.Component<IWelcomeComponentProps, IWelcomeComponentState> {    
    constructor(props: IWelcomeComponentProps) {
        super(props);

        this.state = {
            cards: [],
            sets: props.Sets,
            maxIndex: 12,
            carouselItems: this.getCarouselItems(props.Sets),
        };

        this.GetRarecards();
    }

    componentDidMount(): void {
        window.onscroll = this.onScroll;
        window.onresize = this.OnResize;
    }

    componentWillUnmount(): void {
        window.onscroll = null;
        window.onresize = null;
    }

    getCarouselItems = (sets: TSet[] = []) : TCarouselItem[] => {
        if (sets.length === 0){
            sets=this.props.Sets;
        }
        let carouselitems : TCarouselItem[] = [];
        let csets : TSet[] = [];
        GlobalProperties.MainCarouselSets?.forEach((setid:number) => {
            csets = csets.concat(sets.filter(s => s.id === setid));
        });

        csets.forEach(set => {
            carouselitems.push({
                quickLinkImageUrl: process.env.PUBLIC_URL + `/assets/pokemon/${set.id.toString()}/${set.id}.webp`,
                quickLinkOnClick: () => { this.onSetSelectedSet(set.id); },
                content: <NavLink to={`/Pokemon Kaarten/${set.name}`}>
                            <div onClick={() => { this.onSetSelectedSet(set.id); }} >                                                               
                                <Image style={{maxWidth:GlobalProperties.PageWidth}} className={styles.mainSelectedSetLogo} src={process.env.PUBLIC_URL + `/assets/pokemon/${set.id.toString()}/logo.webp`} alt={set.name} /> 
                            </div>
                         </NavLink>
            });
        });
        return carouselitems;
    }

    OnResize = () => {
        GlobalProperties.PageWidth = window.innerWidth-25 > 1232 ? 1232 : window.innerWidth;
        
        this.setState({carouselItems: this.getCarouselItems(this.state.sets)});
    }

    onScroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
            this.setState({maxIndex: this.state.maxIndex + 6});
        }
        if (this.state.maxIndex >= this.state.sets.length) {
            this.props.UpdateStateExtern(["showFooter"],[true]).then(() => {
                window.onscroll = null;
            }).catch(ex=>{});
        }
    }

    onSetSelectedSet = (setId:number) => {
        this.props.UpdateStateExtern(["firstLoad","showFooter"],[false,true]).then(() => {
            this.props.onSetSelectedSet(setId);
        }).catch(err => {});
    }

    GetRarecards = () => {
        let hostUrl = "https://pokemoncollectie.nl/Backend"
        if (this.props.GetStateVarExtern("dev")) {
            hostUrl = "https://localhost:7254";
        }
    
        fetch(`${hostUrl}/GetRarecards?max=10`, {
            method: 'GET',
        }).then((response) => response.json()).then((data: TProduct[]) => {
            data.forEach(card=> {
                if (card.number > 99) {
                    card.scardnumber = `${card.number}`;
                } else if (card.number > 9) {
                    card.scardnumber = `0${card.number}`;
                } else {
                    card.scardnumber = `00${card.number}`;
                }
                if (card.size > 99) {
                    card.scardnumber += `/${card.size}`;
                } else if (card.size > 9) {
                    card.scardnumber += `/0${card.size}`;
                } else {
                    card.scardnumber += `/00${card.size}`;
                }
    
                //TODO: move to backend, minimale prijs toevoegen
                card.setName = this.state.sets.filter(set => set.id === card.setID)[0]?.name;
                card.priceInUSD = Math.round(card.priceInUSD * 100) /100;
                card.scost = `${card.cost}`;
                let imageUrls: string[] = [];
                card.imageNames?.split(',').forEach(imageName => {
                    imageUrls.push(`${process.env.PUBLIC_URL}/assets/pokemon/${card.setID}/${card.number.toString()}/${imageName}`);
                });
                imageUrls.push(`${process.env.PUBLIC_URL}/assets/pokemon/cardback.webp`);
                card.imageUrls = imageUrls;
            });
            this.setState({cards: data});
        });
    }

    public render(): React.ReactElement<IWelcomeComponentProps> { 
        const animationsEnabled = this.props.GetStateVarExtern("animationsEnabled");  
       
        return (
            <div>
                {GlobalProperties.IsDebug && <div>Outer: {window.outerWidth} - {window.outerHeight} Inner: {window.innerWidth} - {window.innerHeight}</div>}
                <div className={styles.mainSection} >
                    <Stack horizontalAlign='center'>
                        <div style={{maxWidth: GlobalProperties.PageWidth, overflow:"hidden"}}>
                            <MainCarouselComponent isDev={false} switchSpeed={20} animationsEnabled={animationsEnabled} items={this.state.carouselItems} maxWidth={GlobalProperties.PageWidth} />
                            <div style={{maxWidth: GlobalProperties.PageWidth-10, overflow:"hidden"}}>
                                <CarouselComponent animationsEnabled={false} isDev={this.props.GetStateVarExtern("dev")} />
                            </div>
                        </div>
                        <Stack horizontal style={{maxWidth:"100%",overflow:"hidden",marginBottom:8}}>
                            {this.state.cards.map((card,index) => {
                                if ((GlobalProperties.PageWidth > 960 && (200*(1+index) < GlobalProperties.PageWidth)) || 
                                    (GlobalProperties.PageWidth <= 960 && (80*(index+1) < GlobalProperties.PageWidth))){
                                    const r = Math.floor(Math.random() * 4);
                                    let shimmerStyle = styles[`cardshimmer${r}`];
                                    return <NavLink to={"/Pokemon Kaarten/"+card.setName} onClick={ ()=>{ this.onSetSelectedSet(card.setID); this.props.UpdateStateExtern(["firstLoad","searchAll","selectedSetKey"],[false,false,card.setID]).then(() => { this.props.DoSearch(card.title); }); }}>
                                                <Stack verticalAlign='center' horizontalAlign='center' style={{overflow:"hidden", paddingLeft:10}}>
                                                    <div className={`${styles.cardshine} ${styles.cardCompImgMain}`}>
                                                        <Image {...{imageFit: ImageFit.centerContain,
                                                                    className: styles.cardCompImgMain,
                                                                    src: card?.imageUrls?.length && card.imageUrls[0],
                                                                    onError: ( e ) => { e.currentTarget.onerror = null; 
                                                                                        e.currentTarget.src=`${process.env.PUBLIC_URL}/assets/NoImgFound.webp`},
                                                                    styles: props => ({ root: { margin: 0, cursor: "pointer", backgroundColor: "transparent" } }),
                                                                }} alt="" />
                                                        <div className={shimmerStyle}></div>
                                                    </div>
                                                </Stack>
                                            </NavLink>
                                            
                                }
                            })}
                        </Stack>
                        <div style={{maxWidth: GlobalProperties.PageWidth, overflow:"hidden",width:"100%"}}>
                            {this.state.sets.map((set,index) => {
                                if (index < this.state.maxIndex && set.size > 0) {
                                    return  <NavLink to={"/Pokemon Kaarten/"+set.name}>
                                                <div onClick={() => { this.onSetSelectedSet(set.id); }} 
                                                     style={{position:"relative", float:"left"}}>
                                                    <Image style={{ maxWidth:(GlobalProperties.PageWidth/3)-18,
                                                                    minWidth:(GlobalProperties.PageWidth/3)-18,
                                                                    minHeight:(GlobalProperties.PageWidth/4),
                                                                    maxHeight:(GlobalProperties.PageWidth/4),
                                                                    position:"relative",
                                                                    width:"100%"}} 
                                                           className={`${styles.cPanel} ${styles.setLogoMenuWelcome}`} src={process.env.PUBLIC_URL + `/assets/pokemon/${set.id.toString()}/${set.id.toString()}.webp`} 
                                                           alt={set.name} />    
                                                    {(set.cardcount === undefined || set.cardcount === 0) && <Image {...{imageFit: ImageFit.centerContain,
                                                                className: styles.soldOut,
                                                                src: process.env.PUBLIC_URL + `/assets/soldout.webp`,
                                                                styles: props => ({ root: { right:0, margin: 0, zIndex: 1} }),
                                                            }} alt="" />}    
                                                    {GlobalProperties.IsDebug && <div>{set.id}</div>}                                            
                                                </div>
                                            </NavLink>
                                    }
                                }
                            )}
                        </div>
                        {this.props.GetStateVarExtern("showFooter") === false && <div>
                                                                            Meer sets aan het laden...
                                                                          </div>}
                    </Stack>
                </div>
            </div>
        );
    }
}