import React, { useEffect, useLayoutEffect, useState } from 'react';
import { PricingType, TProduct, TProductTag, TShoppingCart } from '../constants/types';
import { Filters } from '../services/Globals';

export function openTab(url : string) {
  const a = document.createElement("a");
  a.setAttribute('href', url);
  a.setAttribute('target', '_blank');
  a.click();
}

export function getFilteredCards(cards : TProduct[], filter: string = Filters.PriceUp) : TProduct[] {
  switch (filter) {
      case Filters.PriceUp: 
          cards.sort((a,b) => a.priceInUSD < b.priceInUSD ? 1 : -1);
          break;
      case Filters.PriceDown: 
          cards.sort((a,b) => a.priceInUSD > b.priceInUSD ? 1 : -1);
          break;
      case Filters.CardNumberUp: 
          cards.sort((a,b) => a.number < b.number ? 1 : -1);
          break;
      case Filters.CardNumberDown: 
          cards.sort((a,b) => a.number > b.number ? 1 : -1);
          break;
      default:
          break;
  }
  return cards;
}

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export function IPAddress() {
  return fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => data.ip)
      .catch(error => console.log(error));
}

export function CalculatePrices(satprice: number, eurprice: number, usdprice: number, shoppingcart:TShoppingCart) : [number,number,number]{
  let total = 0;
  let sendcost = Math.round((6.49*usdprice) / satprice);
  let itemCount = 0;
  shoppingcart?.items.forEach(item => {
      itemCount = itemCount + item.ammount;
      total = total + item.ammount * Math.round(item.product.priceInUSD / satprice);
  });
  total += sendcost;
  return [total, sendcost, itemCount];
}

export function calculateProductBTCPrice(product: TProduct, satprice: number, eurprice: number) {
  switch (product.pricedIn) {
      case PricingType.EUR:
          product.priceInUSD = product.priceInEUR / eurprice;
          break;
      case PricingType.USD:
          break;
      default:
          break;
  }
  product.priceInSats = Math.round(product.priceInUSD / satprice);
  return product;
}

export function ProcessProducts(prods) : TProduct[]  {
  //console.log({prods});
  let products: TProduct[] = [];
  prods.forEach(prod => {
      //console.log({prod});
      let imgUrls : string[] = [];
      prod.imageNames.split(',').forEach(imgUrl => {
          imgUrls.push(process.env.PUBLIC_URL + `/assets/Products/${prod.id}/${imgUrl}`);
      });
      let tags: TProductTag[] = [];
      prod.tags.forEach(tag => {
          tags.push({title: tag.title, value: tag.value});
      });
      products.push({
          isProduct: prod.isProduct,
          available: prod.available,
          description: prod.description,
          id: prod.id,
          order: prod.order,
          priceInEUR: prod.priceInEUR,
          title: prod.title,
          priceInUSD: prod.priceInUSD,
          priceInSats: 0, 
          tags: tags, 
          imageUrls: imgUrls, 
          pricedIn: PricingType.EUR,
          setID: prod.setID,
          showInSets: prod.showInSets
      });
  });
  
  products.sort((a,b) => a.order < b.order ? -1 : 1);
  products.sort((a,b)=> a.available > b.available ? -1 : 1);
  //console.log({products});
  return products;
}