import * as React from 'react';
import { Callout } from '@fluentui/react';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { IconButton } from '@fluentui/react/lib/Button';
import styles from '../styles/pokemon.module.scss';

type Props = {
    content: JSX.Element;
    preDisable: Function;
}

export const FiltersComponent : React.FunctionComponent<Props> = (props) => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId('callout-button');

  return (
    <>
      <IconButton iconProps={{iconName: 'Settings'}}
                  id={buttonId}
                  className={styles.filterButton}
                  onMouseEnter={() => { !isCalloutVisible && toggleIsCalloutVisible(); }}
                  onTouchStart={() => { !isCalloutVisible && toggleIsCalloutVisible(); }}
                  styles={{
                    icon: {color: 'black', fontSize: 24},
                    root: {
                        width: 22,
                        height: "auto",
                        margin: 2,
                        padding: 2,
                    }}}  />
      {isCalloutVisible && (
        <Callout
          style={{padding:10}}
          role="dialog"
          gapSpace={0}
          target={`#${buttonId}`}
          onMouseLeave={toggleIsCalloutVisible}
          onDismiss={() => { toggleIsCalloutVisible(); }}
          onMouseUp={() => { setTimeout(() => {
            if (props.preDisable() === false) {
              toggleIsCalloutVisible();
            }
          }, 50);  }}
          setInitialFocus
        >
          {props.content}
        </Callout>
      )}
    </>
  );
};
