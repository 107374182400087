import { Stack, Image, PrimaryButton, FontIcon, TextField, DefaultButton, Dialog, DialogType, Label } from '@fluentui/react';
import React, { FormEvent } from 'react';
import styles from '../styles/pokemon.module.scss';
import { TSale, TShoppingCart } from '../constants/types';
import Globals, { GetAdress, IsNull } from '../services/Globals';
import { CalculatePrices } from '../helpers/General';
import GlobalProperties from '../helpers/GlobalProperties';

export interface IcheckoutComponentProps {
    satprice: number;
    shoppingcart: TShoppingCart;
    onCheckOut: Function;
    onCheckOutIDeal: Function;
    onCancel: Function;
    ShowMessage: Function;
    debug: boolean;
    eurprice: number;
    usdprice: number;
}

export interface IcheckoutComponentState {
    postalCode?: string;
    homeNumber?: string;
    street?: string;
    city?: string;
    name?: string;
    mail?: string;
    mail2?: string;
    addition?: string;
    popupUrl?: string;
    checkoutDisabled: boolean;
    sendCost: number;
    totalCost: number;
};
  

export default class CheckoutComponent extends React.Component<IcheckoutComponentProps, IcheckoutComponentState> {
    constructor(props: IcheckoutComponentProps) {
        super(props);
        this.getAdress = this.getAdress.bind(this);
        this.onPostalCodeChange = this.onPostalCodeChange.bind(this);
        this.onHomeNumberChange = this.onHomeNumberChange.bind(this);
        this.onMailChange = this.onMailChange.bind(this);
        this.validation = this.validation.bind(this);
        this.onNameChange = this.onNameChange.bind(this);
        this.onaAdditionChange = this.onaAdditionChange.bind(this);
        this.onMail2Change = this.onMail2Change.bind(this);
        this.onCheckOut = this.onCheckOut.bind(this);

        if (props.debug) {
            this.state = {
                mail: "pokemoncollectie@outlook.com",
                mail2: "pokemoncollectie@outlook.com",
                name: "Pietje Puk",
                postalCode: "1122AB",
                street: "Kerkstraat",
                homeNumber: "69420",
                city: "Schrubbekutteveen",
                checkoutDisabled: false,
                sendCost: 0,
                totalCost: 0
            };
        } else {
            this.state = {
                postalCode: undefined,
                homeNumber: undefined,
                street: undefined,
                city: undefined,
                checkoutDisabled: false,
                sendCost: 0,
                totalCost: 0
            };
        }
    }

    componentDidMount(): void {
        const [total,sendcost,itemcount] = CalculatePrices(this.props.satprice, this.props.eurprice, this.props.usdprice, this.props.shoppingcart);
        this.setState({sendCost: sendcost, totalCost: total});
    }

    private getAdress(postalCode:string, homeNumber:string) {
        if (postalCode && homeNumber) {
            GetAdress(postalCode, homeNumber).then(data => {
                if (data.response.numFound >= 1) {
                    this.setState({
                        street: data.response.docs[0].straatnaam,
                        city: data.response.docs[0].woonplaatsnaam
                    });
                }
            });
        }
    }

    private onPostalCodeChange(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
        const postalCode = newValue?.replace(/\s/g, "");
        this.setState({postalCode: postalCode});
        if (this.state.homeNumber !== undefined && postalCode !== undefined) {
            this.getAdress(postalCode, this.state.homeNumber);
        }
    }

    private onHomeNumberChange(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
        this.setState({homeNumber: newValue});
        if (this.state.postalCode !== undefined && newValue !== undefined) {
            this.getAdress(this.state.postalCode, newValue);
        }
    }

    private onNameChange(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
        this.setState({name: newValue});
    }

    private onaAdditionChange(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
        this.setState({addition: newValue});
        if (this.state.homeNumber !== undefined && this.state.postalCode !== undefined && this.state.city === undefined) {
            this.getAdress(this.state.postalCode, this.state.homeNumber);
        }
    }

    private onMailChange(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
        let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (newValue && reg.test(newValue) === true) 
        {
            this.setState({mail: newValue});
        }
    }

    private onMail2Change(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) {
        let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (newValue && reg.test(newValue) == true) 
        {
            this.setState({mail2: newValue});
        }
    }

    private validation() : boolean {
        let rtnVal = true;
        let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (!this.state.mail || reg.test(this.state.mail) === false) {
            this.props.ShowMessage("Vul aub een geldig email adres in", 2000);
            rtnVal = false;
        }
        if (this.state.mail?.toLocaleLowerCase() != this.state.mail2?.toLocaleLowerCase()) {
            this.props.ShowMessage("De ingevulde email adressen zijn niet gelijk", 2000);
            rtnVal = false;
        }
        reg = /^[a-z ,.'-]+$/i;
        if (!this.state.name || reg.test(this.state.name) === false) {
            this.props.ShowMessage("Vul aub een geldige naam in", 2000);
            rtnVal = false;
        }
        reg = /^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i;
        if (!this.state.postalCode || reg.test(this.state.postalCode) === false) {
            this.props.ShowMessage("Vul aub een geldige postcode in", 2000);
            rtnVal = false;
        }
        //reg = /^[A-Za-z]$/i;
        if (!this.state.street) { 
            this.props.ShowMessage("Vul aub een geldige straatnaam in", 2000);
            rtnVal = false;
        }
        reg = /^\d+$/;
        if (!this.state.homeNumber || reg.test(this.state.homeNumber) === false) {
            this.props.ShowMessage("Vul aub een geldig huisnummer in", 2000);
            rtnVal = false;
        }
        //reg = /^[1-9][0-9]{6}$/i;
        if (!this.state.city) { 
            this.props.ShowMessage("Vul aub een geldige plaatsnaam in", 2000);
            rtnVal = false;
        }
        return rtnVal;
    }

    private onCheckOut() {
        if (this.validation()) {
            this.setState({checkoutDisabled: true });
            let homeNumber = this.state.homeNumber?.toString();
            if (!IsNull(this.state.addition)) {
                homeNumber += " "+this.state.addition;
            }
            this.props.onCheckOut({ email: this.state.mail2, name: this.state.name, postalCode: this.state.postalCode, street: this.state.street, number: homeNumber, city: this.state.city })
        } else {
            
        }
    }

    private onCheckOutIDeal() {
        if (this.validation()) {
            this.setState({checkoutDisabled: true });
            let homeNumber = this.state.homeNumber?.toString();
            if (!IsNull(this.state.addition)) {
                homeNumber += " "+this.state.addition;
            }
            this.props.onCheckOutIDeal({ email: this.state.mail2, name: this.state.name, postalCode: this.state.postalCode, street: this.state.street, number: homeNumber, city: this.state.city });
        } else {
            
        }
    }

    public render(): React.ReactElement<IcheckoutComponentProps> {
        return (   
            <Stack className={styles.preCheckOutMain} tokens={{ maxWidth: 320}}>
                <Dialog
                    hidden={!this.state.popupUrl}
                    dialogContentProps={{type:DialogType.normal}}
                    onDismiss={() => {this.setState({popupUrl : undefined})}}
                    modalProps={{styles: {main : { maxHeight: "100%!important", width: 800, height: 800, maxWidth: "95%!important", backgroundColor: "transparent", boxShadow: "none", overflowY:"hidden"}}}}
                >
                    <Image className={styles.popupImage} onClick={() => {this.setState({popupUrl : undefined})}} src={this.state.popupUrl} alt="" /> 
                </Dialog>
                <Stack horizontal tokens={{ childrenGap: 20, padding: 2 }}>
                    <Label>{GlobalProperties.Strings.CheckOutMainText}</Label>
                </Stack>
                <hr style={{width: "100%"}} />
                <Stack horizontal tokens={{ childrenGap: 20, padding: 2 }}>
                    <TextField inputMode='email' type={"email"} className={styles.checkoutName} label={"Email *"} placeholder={"voorbeeld@mail.com"} onChange={this.onMailChange} />
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20, padding: 2 }}>
                    <TextField inputMode='email' type={"email"} className={styles.checkoutName} label={`${GlobalProperties.Strings.ReAddEmail} *`} placeholder={"voorbeeld@mail.com"} onChange={this.onMail2Change} />
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20, padding: 2 }}>
                    <TextField type="fullname" className={styles.checkoutName} label={`${GlobalProperties.Strings.RecipientName} *`} placeholder={GlobalProperties.Strings.FullName} onChange={this.onNameChange} />
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20, padding: 2 }}>
                    <TextField type="postalcode" label={`${GlobalProperties.Strings.Postalcode} *`} placeholder={"1122XY"} maxLength={6} onChange={this.onPostalCodeChange} />
                    <TextField type="homenumber" className={styles.checkoutNumber} label={`${GlobalProperties.Strings.Number} *`} placeholder={"1"} onChange={this.onHomeNumberChange} />
                    <TextField type="" className={styles.chechoutToevoeging} label={`${GlobalProperties.Strings.Addition}`} placeholder={""} onChange={this.onaAdditionChange} />
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 20, padding: 2 }}>
                    <TextField label={`${GlobalProperties.Strings.Street} *`} placeholder={""} value={this.state.street} onChange={(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => { this.setState({street : newValue}); }}/>
                    <TextField label={`${GlobalProperties.Strings.City} *`} placeholder={""} value={this.state.city} onChange={(event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string | undefined) => { this.setState({city : newValue}); }}/>
                </Stack>
                <hr style={{width: "100%"}} />
                <Stack horizontal tokens={{ childrenGap: 3, padding: 1 }}>
                    <div className={styles.checkoutCardMain}>
                        {this.props.shoppingcart.items.map(item => {
                            if (item.product.imageUrls.length > 0) {
                                return <div className={styles.checkoutCardWrapper}>
                                            <Stack horizontal verticalAlign='center'>
                                                <img onClick={() => {this.setState({popupUrl: item.product.imageUrls[0] })}} className={styles.checkoutCardImg} src={item.product.imageUrls[0]} alt="" />
                                                {item.ammount > 1 && `x${item.ammount}`}
                                            </Stack>
                                        </div>;
                            }
                        })}
                    </div>
                </Stack>
                <hr style={{width: "100%"}} />
                <Stack horizontal horizontalAlign='space-between'>
                    <Label>{GlobalProperties.Strings.SendingCost}</Label>
                    <Stack horizontal>
                        <Image className={styles.btciconsmallcard}
                            style={{ cursor: "default", paddingTop:2 }}
                            src={`${process.env.PUBLIC_URL}/assets/btcicon.png`}
                        />
                        <Label>{this.state.sendCost} sats</Label>
                    </Stack>
                </Stack>
                <Stack horizontal horizontalAlign='space-between'>
                    <Label></Label>
                    <Label style={{fontSize:"smaller"}}>€ {(Math.round(this.state.sendCost * this.props.satprice * 100 * this.props.eurprice) / 100).toFixed(2)}</Label>
                </Stack>
                <Stack horizontal horizontalAlign='space-between'>
                    <Label>{GlobalProperties.Strings.Total}</Label>
                    <Stack horizontal>
                        <Image className={styles.btciconsmallcard}
                            style={{ cursor: "default", paddingTop:2 }}
                            src={`${process.env.PUBLIC_URL}/assets/btcicon.png`}
                        />
                        <Label><b>{this.state.totalCost} sats</b></Label>
                    </Stack>
                </Stack>
                <Stack horizontal horizontalAlign='space-between'>
                    <Label></Label>
                    <Label style={{fontSize:"smaller"}}>€ {(Math.round(100 * this.state.totalCost * this.props.satprice * this.props.eurprice) / 100).toFixed(2)}</Label>
                </Stack>
                <Label className={styles.priceWarning}>* {GlobalProperties.Strings.PaymentNote}</Label>
                <hr style={{width: "100%"}} />
                <Stack horizontal tokens={{ childrenGap: 20, padding: 2 }}>
                    <PrimaryButton disabled={this.state.checkoutDisabled} className={styles.checkoutButton} text={GlobalProperties.Strings.CheckOutWithBitcoin} onClick={() => { this.onCheckOut();}}>
                        <FontIcon aria-label="Afrekenen" iconName="LightningBolt" />
                    </PrimaryButton> 
                </Stack>
                <hr style={{width: "100%"}} />
                <Stack horizontal horizontalAlign='space-between'>
                    <Label>{GlobalProperties.Strings.IDealCost}</Label>
                    <Label>€ 0.40</Label>
                </Stack>
                <Stack horizontal horizontalAlign='space-between'>
                    <Label>{GlobalProperties.Strings.Total}</Label>
                    <Label><b>€ {(((((this.state.totalCost * this.props.satprice) * this.props.eurprice)+0.40) * 100) / 100).toFixed(2)}</b></Label>
                </Stack>
                <hr style={{width: "100%"}} />
                <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: 20, padding: 2 }}>
                    <PrimaryButton disabled={this.state.checkoutDisabled} className={styles.checkoutButton} text={GlobalProperties.Strings.CheckOutWithIDeal} onClick={() => { this.onCheckOutIDeal();}}>
                        <Image style={{maxHeight: 20, cursor: "pointer", backgroundColor: "white", borderRadius: "5px", padding: 2, margin: 2}} src={`${process.env.PUBLIC_URL}/assets/idealicon.webp`} />
                    </PrimaryButton> 
                </Stack>
                <hr style={{width: "100%"}} />
                <Stack horizontal horizontalAlign='start' tokens={{ childrenGap: 20, padding: 2 }}>
                    <DefaultButton className={styles.checkoutButton} text={GlobalProperties.Strings.KeepShopping} onClick={() => {this.props.onCancel();}} />
                </Stack>
            </Stack>
        );
    }
}