import * as React from 'react';
import { Coachmark, DirectionalHint, IPositioningContainerProps, TeachingBubbleContent } from '@fluentui/react';
import { useBoolean } from '@fluentui/react-hooks';
import styles from '../styles/pokemon.module.scss';
import { isComputedPropertyName } from 'typescript';

type Props = {
    Visible: boolean;
    OnClick: Function;
}

export const AlertButtonComponent : React.FunctionComponent<Props> = (props) => {
  const targetButton = React.useRef<HTMLDivElement>(null);
  let [isCoachmarkVisible, { setFalse: hideCoachmark, setTrue: showCoachmark }] = useBoolean(props.Visible);
  const positioningContainerProps = React.useMemo(
    () => ({
      directionalHint: DirectionalHint.topCenter,
      doNotLayer: false,
    }),
    [DirectionalHint.bottomRightEdge],
  );
  
  isCoachmarkVisible = props.Visible;

  return (
    <>
      <div style={{padding:2, backgroundColor:"lightgreen", borderRadius:2}}>
          <div className={`${styles.emailAlertButton} ${styles.buyButton}`}
               ref={targetButton}
               style={{height:"100%", width:100, maxHeight:"75%", verticalAlign:"middle"}} 
               onClick={() => { props.OnClick(); }} /> 
      </div>
      {isCoachmarkVisible && (
        <Coachmark
          target={targetButton.current}
          positioningContainerProps={positioningContainerProps}
          ariaAlertText="A coachmark has appeared"
          ariaDescribedBy="coachmark-desc1"
          ariaLabelledBy="coachmark-label1"
          ariaDescribedByText="Press enter or alt + C to open the coachmark notification"
          ariaLabelledByText="Coachmark notification"
        >
        </Coachmark>
      )}
    </>
  );
};
