import { Stack, Image, ImageFit, Label, PrimaryButton, DefaultButton, FontIcon, IStackTokens } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';
import {  TProduct, TSize } from '../constants/types';
import { calculateProductBTCPrice } from '../helpers/General';
import PulseLabel from './pulseLabelComponent';
import GlobalProperties from '../helpers/GlobalProperties';
import { AlertButtonComponent } from './alertButtonComponent';

export interface IproductComponentProps {
    product: TProduct;
    onImageClick: Function;
    eurprice: number;
    satprice: number;
    imageSize?: TSize;
    onBuyClick: Function;
    hideSwipe: boolean;
    SetSelectedProductForAlert?: Function;
    AlertCoachmarkVisible: boolean;
}

export interface IproductComponentState {
    selectedImageIdex: number;
    added: boolean;
    notAdded: boolean;
    product: TProduct;
    startSwipe: number;
    endSwipe: number;
    swipeHidden: boolean;
};
  

export default class Product extends React.Component<IproductComponentProps, IproductComponentState> {
    constructor(props: IproductComponentProps) {
        super(props);
        this.previousImage = this.previousImage.bind(this);
        this.nextImage = this.nextImage.bind(this);

        this.state = {
            product: calculateProductBTCPrice(props.product, props.satprice, props.eurprice),
            selectedImageIdex: 0,
            added: false,
            notAdded: false,
            startSwipe: -1,
            swipeHidden: this.props.hideSwipe,
            endSwipe: -1
        };
    }

    componentDidUpdate(prevProps: Readonly<IproductComponentProps>, prevState: Readonly<IproductComponentState>, snapshot?: any): void {
        if (prevProps.satprice != this.props.satprice) {
            this.setState({product: calculateProductBTCPrice(this.props.product, this.props.satprice, this.props.eurprice)});
        }
    }

    componentDidMount(): void {
        if (this.props.hideSwipe == false) {
            this.swipeTimeout();
        }
    }

    swipeTimeout() {
        setTimeout(() => {
            this.setState({swipeHidden: true});
                setTimeout(() => {
                    this.setState({swipeHidden: false});
                    this.swipeTimeout();
                }, 60000);
        }, 2000);
    }

    tryAddToChart() {
        let done = false;
        if (this.state.product.available > 0) {
            done = this.props.onBuyClick(this.state.product); 
        }
        if (done) {
            this.setState({added:true});
            setTimeout(() => {
                this.setState({added:false});
            }, 500);
        } else {
            this.setState({notAdded:true});
            setTimeout(() => {
                this.setState({notAdded:false});
            }, 500);
        }
    }

    swipeEnd(e) {
        if (this.state.startSwipe != -1 && this.state.endSwipe != -1) {
            if ((this.state.endSwipe - this.state.startSwipe) > 20) {
                this.state.selectedImageIdex > 0 ? this.setState({selectedImageIdex : this.state.selectedImageIdex-1}) : this.setState({selectedImageIdex : this.props.product.imageUrls.length -1});
            } else if ((this.state.startSwipe - this.state.endSwipe) > 20) {
                this.state.selectedImageIdex+1 < this.props.product.imageUrls.length ? this.setState({selectedImageIdex : this.state.selectedImageIdex+1}) : this.setState({selectedImageIdex : 0});
            }
            this.setState({startSwipe: -1, endSwipe: -1});
        }
    }

    private nextImage() {
        let newIndex = this.state.selectedImageIdex + 1;
        if (newIndex < this.props.product.imageUrls.length) {
            this.setState({selectedImageIdex : newIndex});
        } else {
            this.setState({selectedImageIdex : 0});
            newIndex = 0;
        }
        this.props.onImageClick(this.props.product.imageUrls[newIndex], this.nextImage, this.previousImage);
    }

    private previousImage() {
        let newIndex = this.state.selectedImageIdex - 1;
        if (this.state.selectedImageIdex > 0) {
            this.setState({selectedImageIdex : this.state.selectedImageIdex - 1});
        } else {
            this.setState({selectedImageIdex : this.props.product.imageUrls.length -1});
            newIndex = this.props.product.imageUrls.length -1;
        }
        this.props.onImageClick(this.props.product.imageUrls[newIndex], this.nextImage, this.previousImage);
    }

    public render(): React.ReactElement<IproductComponentProps> {
        return (   
            <Stack className={styles.product}>
                <Stack style={{height:"100%"}}>
                    <Stack horizontal >
                        <div className={`${styles.productTitleContainer}`}>
                            <Stack horizontal horizontalAlign='space-between'>
                                <Stack horizontal horizontalAlign='start'>
                                    <div className={styles.productTitle}>
                                        {this.state.product.title}
                                    </div>
                                </Stack>
                                <Stack horizontal horizontalAlign='end' className={styles.cardHeaderBanner}>
                                    {this.state.product.available === 0 && <Image {...{imageFit: ImageFit.centerContain,
                                            className: styles.soldOut,
                                            src: process.env.PUBLIC_URL + `/assets/soldout.webp`,
                                            styles: props => ({ root: { margin: 0, zIndex: 1} }),
                                        }} alt="" />}
                                </Stack>
                            </Stack>
                        </div>
                    </Stack> {/* style={{backgroundColor:'white', borderRadius:10}} */}
                    <Stack horizontal verticalAlign='center' horizontalAlign='center' onClick={ () => {this.props.onImageClick(this.state.product.imageUrls[this.state.selectedImageIdex],
                                                                                                                               this.nextImage.bind(this), 
                                                                                                                               this.previousImage.bind(this)); }}>
                        <Image {...{imageFit: ImageFit.centerContain,
                                    className: styles.productCompImg,
                                    src: this.state.product.imageUrls[this.state.selectedImageIdex],
                                    width: this.props.imageSize?.x,
                                    height: this.props.imageSize?.y,
                                    onTouchStart: (e) => { this.setState({startSwipe: e.targetTouches[0].clientX}); },
                                    onTouchMove: (e) => { this.setState({endSwipe: e.targetTouches[0].clientX}); },
                                    onTouchEnd: this.swipeEnd.bind(this),
                                    onError: ( e ) => { e.currentTarget.onerror = null; 
                                                        e.currentTarget.src=`${process.env.PUBLIC_URL}/assets/NoImgFound.webp`},
                                    styles: props => ({ root: { margin: 0, cursor: "pointer" }, image: {  } }),
                                }} alt="" />
                        <Image {...{imageFit: ImageFit.centerContain,
                                        className: styles.swipe,
                                        width: 80,
                                        height: 80,
                                        src: process.env.PUBLIC_URL + `/assets/swipe.gif`,
                                        styles: props => ({ root: { margin: 0, zIndex: 1, visibility:this.state.swipeHidden ? "hidden" : "visible" } }),
                                    }} alt="" />
                    </Stack>
                    <Stack horizontalAlign='center' horizontal verticalAlign='center'>
                        <FontIcon aria-label="Volgende" className={styles.prevNextIcon} onClick={() => {this.state.selectedImageIdex > 0 ? this.setState({selectedImageIdex : this.state.selectedImageIdex-1}) : this.setState({selectedImageIdex : this.state.product.imageUrls.length -1})}} iconName="ChevronLeft" />
                        {this.state.selectedImageIdex+1} / {this.state.product.imageUrls.length} 
                        <FontIcon aria-label="Volgende" className={styles.prevNextIcon} onClick={() => {this.state.selectedImageIdex+1 < this.state.product.imageUrls.length ? this.setState({selectedImageIdex : this.state.selectedImageIdex+1}) : this.setState({selectedImageIdex : 0})}} iconName="ChevronRight" />
                    </Stack>
                    <Stack className={styles.productInfoMain}>
                        <Stack className={styles.productInfo}>
                            <Label>{this.state.product.description}</Label>
                            <hr className={styles.cardInfohr} />
                            <div className={styles.divTable} style={{width:"max-content"}}>
                                {this.state.product.tags.map(tag => {
                                        return  <div className={styles.divTableRow}>
                                                    <div className={styles.divTableCell}>
                                                            <Label><b>{tag.title}</b></Label>
                                                    </div>
                                                    <div className={styles.divTableCell}>
                                                            <Label>&nbsp;&nbsp;{tag.value}</Label>
                                                    </div>
                                                </div>
                                })}
                            </div>
                        </Stack>
                        <Stack verticalAlign='end' style={{height:"100%"}}>
                            {/* <hr className={styles.cardInfohr} />
                            <Stack horizontal horizontalAlign='space-between'>
                                <div className={styles.divTable} style={{width:"max-content"}}>
                                    <div className={styles.divTableRow}>
                                        <div className={styles.divTableCell}>
                                                <Label><b>{GlobalProperties.Strings.Available}</b></Label>
                                        </div>
                                        <div className={styles.divTableCell}>
                                            <PulseLabel text={`\xa0\xa0${this.state.product.available.toString()}`} />
                                        </div>
                                    </div>
                                </div>
                            </Stack> */}
                            <hr className={styles.cardInfohr} />
                            <Stack horizontal horizontalAlign='space-between'>
                                <Stack>
                                    <PulseLabel style={{fontSize:"small!important",fontWeight:"bold"}} text={`€ ${parseFloat(this.state.product.priceInEUR.toFixed(2))}`} />
                                    <Stack horizontal>
                                        <Image className={styles.btciconsmall}
                                            style={{ cursor: "default" }}
                                            src={`${process.env.PUBLIC_URL}/assets/btcicon.png`}
                                        />
                                        <PulseLabel style={{fontSize:"small!important"}} text={`${this.state.product.priceInSats} sats`} />
                                    </Stack>
                                </Stack>
                                <Stack horizontal horizontalAlign='end' className={styles.butButtonDiv}>
                                    {this.state.product.priceInUSD != 0 && this.state.product.available > 0 ?
                                        <FontIcon style={{width:100, maxHeight:"90%", verticalAlign:"middle", fontSize:25, backgroundColor:"#30a7d7"}} 
                                                  aria-label={GlobalProperties.Strings.AddToCart} 
                                                  onClick={() => {this.tryAddToChart();}} 
                                                  className={`${styles.buyButton} ${this.state.added ? styles.added : this.state.notAdded ? styles.notAdded : ""}`} 
                                                  iconName={this.state.added ? "ShoppingCartSolid" : "ShoppingCart"} />
                                        : <AlertButtonComponent Visible={this.props.AlertCoachmarkVisible} OnClick={() => { this.props.SetSelectedProductForAlert(this.state.product); }} />
                                    }
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        );
    }
}