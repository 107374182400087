import { Stack, Image, FontIcon, Label, ImageFit, PrimaryButton } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';
import BannerComponent from './bannerComponent';

export interface INonNLbannerComponentProps {
    discard: Function;
}

export interface INonNLbannerComponentState {
    moveDown: string;
    content: JSX.Element;
};
  

export default class NonNLbannerComponent extends React.Component<INonNLbannerComponentProps, INonNLbannerComponentState> {
    constructor(props: INonNLbannerComponentProps) {
        super(props);
        this.state = {
            moveDown: "",
            content: <div>
                        <Stack horizontal horizontalAlign='center'>
                            <div className={styles.allert}></div>
                        </Stack>
                        <Stack horizontal>
                            <Label style={{marginTop:10}}>We currently only support delivery inside The Netherlands. For orders outsite The Netherlands please contact us for options :&nbsp;</Label>
                            <a href="mailto:contact@pokemoncollectie.nl" className={styles.contactUrl} >
                                <FontIcon aria-label="Email" iconName="mail" className={styles.contactImage} 
                                        onClick={() => {window.open("mailto:contact@pokemoncollectie.nl");}}/>
                            </a>
                            <Label style={{marginTop:10}}>&nbsp;-&nbsp;</Label>
                            <Image className={styles.whatsappImage} 
                                src={process.env.PUBLIC_URL + `/assets/whatsapp2.webp`} 
                                onClick={() => { window.open("https://wa.me/+31616033524"); }}
                                alt="" imageFit={ImageFit.contain} />
                        </Stack>
                        <Stack horizontal horizontalAlign='center'>
                            <PrimaryButton text='Close' onClick={() => {this.props.discard();}} />
                        </Stack>
                    </div>
        };

        setTimeout(() => {
            this.setState({moveDown: styles.moveDown});
        }, 200);
    }

    public render(): React.ReactElement<INonNLbannerComponentProps> {
        return (   
            <BannerComponent content={this.state.content} />
        );
    }
}