export class Globals {
  private _searchParams = new URLSearchParams(window.location.search);

  get SearchParams() {
    return this._searchParams;
  }
  
  HasParam(paramName:string) : boolean {
    return this._searchParams.has(paramName)
  }

  GetParam(paramName:string) : string {
    if (this.HasParam(paramName)) {
      return this._searchParams.get(paramName);
    } else {
      return null;
    }
  }
}

export default new Globals();

export function GetAdress(postalCode: string, houseNumber: string) {
    return fetch(`https://api.pdok.nl/bzk/locatieserver/search/v3_1/free?fq=postcode:${postalCode}&fq=huisnummer=${houseNumber}`)
      .then(response => response.json())
      .then(data => data)
      .catch(error => console.log(error));
}

export function IsNull(obj : any) : boolean {
  if (obj === undefined || obj === null || obj === "") { 
    return true; 
  } else {
    return false;
  }
}

export enum Filters {
  PriceUp = "PriceUp",
  PriceDown = "PriceDown",
  CardNumberUp = "CardNumberUp",
  CardNumberDown = "CardNumberDown"
}