import React from 'react';
import './App.css';
import MainComponent from './components/mainComponent';
import { Routes, Route } from 'react-router-dom';
import { ProductType } from './constants/types';
import Marktplaats from './components/marktplaatsComponent';
import GlobalProperties from './helpers/GlobalProperties';

const App: React.FunctionComponent = () => {
  return (
    <div>
      <Main />
    </div>
  );
};

const Main = () => (
  <Routes>
    <Route path='/' element={<MainComponent firstComponent={ProductType.All} />}></Route>
    <Route path='/Algemene Voorwaarden' element={<MainComponent firstComponent={ProductType.Cards} showText={"AlgemeneVoorwaarden"} />}></Route>
    <Route path='/Privacybeleid' element={<MainComponent firstComponent={ProductType.Cards} showText={"PrivacyBeleid"} />}></Route>
    <Route path='/Retourbeleid' element={<MainComponent firstComponent={ProductType.Cards} showText={"Retourbeleid"} />}></Route>
    <Route path='/Over Ons' element={<MainComponent firstComponent={ProductType.Cards} showText={"SiteText"} />}></Route>
    <Route path='/Pokemon Kaarten' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Boosters' element={<MainComponent firstComponent={ProductType.Booster} />}></Route>
    <Route path='/Pokemon Collecties' element={<MainComponent firstComponent={ProductType.Collection} />}></Route>
    <Route path='/Pokemon Diverse' element={<MainComponent firstComponent={ProductType.Misc} />}></Route>
    <Route path='/Promo' element={<MainComponent firstComponent={ProductType.Promo} />}></Route>
    <Route path='/Promo2' element={<MainComponent firstComponent={ProductType.Promo2} />}></Route>
    <Route path='/Marktplaats' element={<Marktplaats />}></Route>
    <Route path='/Zoeken' element={<MainComponent firstComponent={ProductType.All} showSearch={true} />}></Route>
    <Route path='/Pokemon Kaarten/Surging Sparks' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Stellar Crown' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Ancient Origins' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Astral Radiance' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Astral Radiance Trainer Gallery' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Battle Styles' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Black and White' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Black and White Promos' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Boundaries Crossed' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/BREAKpoint' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/BREAKthrough' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Brilliant Stars' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Brilliant Stars Trainer Gallery' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Burning Shadows' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Call of Legends' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Celebrations' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Celestial Storm' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path="/Pokemon Kaarten/Champion's Path" element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Chilling Reign' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Cosmic Eclipse' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Crimson Invasion' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Crown Zenith' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Crystal Guardians' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Dark Explorers' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Darkness Ablaze' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Delta Species' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Deoxys' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Detective Pikachu' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Diamond & Pearl' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Double Crisis' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Dragon' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Dragon Frontiers' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Dragon Majesty' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Dragons Exalted' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Emerald' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Emerging Powers' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Evolutions' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Evolving Skies' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Ex Series' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Fates Collide' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/FireRed & LeafGreen' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Flashfire' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Forbidden Light' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Furious Fists' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Fusion Strike' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Generations' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Great Encounters' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Guardians Rising' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/HeartGold and SoulSilver' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Hidden Fates' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Hidden Legends' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Holon Phantoms' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Kalos Starter Set' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Legend Maker' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Legendary Treasures' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Legends Awakened' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Lost Origin' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Lost Origin Trainer Gallery' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Lost Thunder' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Majestic Dawn' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Mysterious Treasures' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Next Destinies' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Noble Victories' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Obsidian Flames' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Paldea Evolved' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Paldean Fates' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Paradox Rift' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Phantom Forces' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Plasma Blast' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Plasma Freeze' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Plasma Storm' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Platinum' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Pokemon Go' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Power Keepers' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Primal Clash' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Rebel Clash' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Rising Rivals' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Roaring Skies' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Ruby & Sapphire' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Sandstorm' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Scarlet & Violet Base Set' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Scarlet & Violet Promo' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Secret Wonders' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Shining Fates' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Shining Legends' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Shrouded Fable' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Silver Tempest' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Steam Siege' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Stormfront' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Sun and Moon' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Sun and Moon Black Star Promos' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Supreme Victors' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/SV: 151' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Sword & Shield Promo' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Sword and Shield' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Team Magma vs Team Aqua' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Team Rocket Returns' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Team Up' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Temporal Forces' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Twilight Masquerade' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Trainer Kit' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Trainer Kit 2' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Triumphant' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Ultra Prism' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Unbroken Bonds' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Undaunted' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Unified Minds' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Unleashed' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Unseen Forces' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/Vivid Voltage' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/Pokemon Kaarten/X and Y' element={<MainComponent firstComponent={ProductType.Cards} />}></Route>
    <Route path='/151 Poster Collectie' element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path='/151' element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path='/Paradox Rift' element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path='/Paldean Fates' element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path='/Paldea Evolved Elite Trainer Box' element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path='/Paldea Evolved' element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path='/151 Poster' element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path='/Pokemon TCG dobbelstenen' element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path='/Pokemon TCG markers' element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path='/Sleeves' element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path='/Energy bundel' element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path='/TCG Online code bundel' element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path='/Brilliant Stars' element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path='/Brilliant Stars 3 booster blister' element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path='/Paldean Fates Elite Trainer Box' element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path='/Paldean Fates Booster Bundle' element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Oddish - 001/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Oddish - 001/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Gloom - 002/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Gloom - 002/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Bellossom - 003/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Bellossom - 003/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Tangela - 004/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Tangela - 004/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Tangrowth - 005/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Tangrowth - 005/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Scyther - 006/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Scyther - 006/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Sunkern - 007/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Sunkern - 007/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Yanma - 008/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Yanma - 008/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Yanmega - 009/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Yanmega - 009/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Kricketot - 010/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Kricketot - 010/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Cherubi - 011/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Cherubi - 011/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Carnivine - 012/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Carnivine - 012/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Leafeon V - 013/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Leafeon VSTAR - 014/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Grubbin - 015/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Grubbin - 015/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Zarude - 016/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Zarude - 016/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Calyrex - 017/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Calyrex - 017/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Charizard V - 018/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Charizard VSTAR - 019/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Radiant Charizard - 020/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Entei - 021/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Entei - 021/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Simisear V - 022/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Simisear VSTAR - 023/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Larvesta - 024/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Larvesta - 024/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Volcarona - 025/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Volcarona - 025/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Volcanion - 026/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Volcanion - 026/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Salandit - 027/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Salandit - 027/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Salazzle - 028/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Salazzle - 028/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Seel - 029/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Seel - 029/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Galarian Mr. Mime - 030/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Galarian Mr. Mime - 030/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Wailmer - 031/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Wailmer - 031/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Wailord - 032/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Wailord - 032/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Corphish - 033/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Corphish - 033/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Snorunt - 034/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Snorunt - 034/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Luvdisc - 035/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Luvdisc - 035/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Kyogre - 036/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Kyogre - 036/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Kyogre V - 037/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Glaceon V - 038/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Shinx - 039/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Shinx - 039/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Shinx - 040/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Shinx - 040/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Luxio - 041/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Luxio - 041/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Luxio - 042/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Luxio - 042/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Luxray - 043/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Luxray - 043/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Luxray - 044/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Luxray - 044/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Rotom V - 045/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Rotom VSTAR - 046/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Emolga - 047/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Emolga - 047/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Eelektrik - 048/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Eelektrik - 048/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Helioptile - 049/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Helioptile - 049/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Heliolisk - 050/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Heliolisk - 050/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Radiant Charjabug - 051/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Zeraora - 052/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Zeraora - 052/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Zeraora V - 053/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Zeraora VMAX - 054/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Zeraora VSTAR - 055/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Pincurchin - 056/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Pincurchin - 056/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Exeggcute - 057/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Exeggcute - 057/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Exeggutor - 058/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Exeggutor - 058/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Mewtwo - 059/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Mewtwo - 059/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Mew V - 060/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Girafarig - 061/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Girafarig - 061/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Lunatone - 062/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Lunatone - 062/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Dusclops - 063/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Dusclops - 063/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Tapu Lele - 064/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Tapu Lele - 064/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Hatterene V - 065/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Hatterene VMAX - 066/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Enamorus - 067/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Enamorus - 067/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Graveler - 068/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Graveler - 068/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Solrock - 069/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Solrock - 069/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Baltoy - 070/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Baltoy - 070/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Riolu - 071/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Riolu - 071/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Pancham - 072/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Pancham - 072/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Rockruff - 073/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Rockruff - 073/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Lycanroc - 074/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Lycanroc - 074/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Koffing - 075/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Koffing - 075/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Absol - 076/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Absol - 076/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Purrloin - 077/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Purrloin - 077/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Liepard - 078/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Liepard - 078/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Krokorok - 079/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Krokorok - 079/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Pangoro - 080/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Pangoro - 080/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Skrelp - 081/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Skrelp - 081/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Dragalge - 082/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Dragalge - 082/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Hoopa - 083/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Hoopa - 083/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Galarian Meowth - 084/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Galarian Meowth - 084/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Galarian Perrserker - 085/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Galarian Perrserker - 085/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Scizor - 086/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Scizor - 086/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Aron - 087/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Aron - 087/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Lairon - 088/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Lairon - 088/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Aggron - 089/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Aggron - 089/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Metang - 090/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Metang - 090/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Pawniard - 091/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Pawniard - 091/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Pawniard - 092/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Pawniard - 092/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Bisharp - 093/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Bisharp - 093/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Zacian - 094/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Zacian - 094/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Zacian V - 095/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Zacian VSTAR - 096/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Zamazenta - 097/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Zamazenta - 097/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Zamazenta V - 098/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Zamazenta VSTAR - 099/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Rayquaza V - 100/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Rayquaza VMAX - 101/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Rayquaza VMAX - 102/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Duraludon V - 103/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Duraludon VMAX - 104/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Radiant Eternatus - 105/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Tauros - 106/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Tauros - 106/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Ditto - 107/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Ditto - 107/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Eevee V - 108/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Snorlax - 109/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Snorlax - 109/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Starly - 110/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Starly - 110/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Bidoof - 111/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Bidoof - 111/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Chatot - 112/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Chatot - 112/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Regigigas V - 113/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Regigigas VSTAR - 114/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Shaymin - 115/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Shaymin - 115/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Stoutland V - 116/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Yungoos - 117/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Yungoos - 117/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Gumshoos - 118/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Gumshoos - 118/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Oranguru - 119/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Oranguru - 119/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Greedent V - 120/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Wooloo - 121/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Wooloo - 121/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Dubwool - 122/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Dubwool - 122/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Bea - 123/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Bea - 123/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Bede - 124/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Bede - 124/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Crushing Hammer - 125/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Crushing Hammer - 125/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Digging Duo - 126/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Digging Duo - 126/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Energy Retrieval - 127/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Energy Retrieval - 127/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Energy Search - 128/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Energy Search - 128/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Energy Switch - 129/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Energy Switch - 129/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Friends in Hisui - 130/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Friends in Hisui - 130/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Friends in Sinnoh - 131/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Friends in Sinnoh - 131/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Great Ball - 132/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Great Ball - 132/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Hop - 133/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Hop - 133/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Leon - 134/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Leon - 134/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Lost Vacuum - 135/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Lost Vacuum - 135/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Nessa - 136/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Nessa - 136/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Poke Ball - 137/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Poke Ball - 137/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Pokemon Catcher - 138/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Pokemon Catcher - 138/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Potion - 139/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Potion - 139/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Raihan - 140/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Raihan - 140/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Rare Candy - 141/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Rare Candy - 141/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Rescue Carrier - 142/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Rescue Carrier - 142/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Sky Seal Stone - 143/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Switch - 144/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Switch - 144/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Trekking Shoes - 145/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Trekking Shoes - 145/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Ultra Ball - 146/159 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Ultra Ball - 146/159 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Elesa's Sparkle - 147/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Friends in Hisui (Full Art) - 148/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Friends in Sinnoh (Full Art) - 149/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Professor's Research (Full Art) - 150/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Volo (Full Art) - 151/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Grass Energy (Texture Full Art) - 152/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Fire Energy (Texture Full Art) - 153/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Water Energy (Texture Full Art) - 154/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Lightning Energy (Texture Full Art) - 155/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Psychic Energy (Texture Full Art) - 156/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Fighting Energy (Texture Full Art) - 157/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Darkness Energy (Texture Full Art) - 158/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Metal Energy (Texture Full Art) - 159/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crown Zenith - Pikachu (Secret) - 160/159 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Venonat - 001/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Venonat - 001/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Venomoth - 002/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Venomoth - 002/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Spinarak - 003/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Spinarak - 003/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Ariados - 004/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Ariados - 004/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Sunkern - 005/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Sunkern - 005/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Sunflora - 006/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Sunflora - 006/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Serperior V - 007/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Serperior VSTAR - 008/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Petilil - 009/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Petilil - 009/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Hisuian Lilligant - 010/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Hisuian Lilligant - 010/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Foongus - 011/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Foongus - 011/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Amoonguss - 012/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Amoonguss - 012/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Durant - 013/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Durant - 013/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Virizion - 014/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Virizion - 014/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Chesnaught V - 015/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Radiant Tsareena - 016/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Vulpix - 017/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Vulpix - 017/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Ninetales - 018/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Ninetales - 018/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Growlithe - 019/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Growlithe - 019/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Arcanine - 020/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Arcanine - 020/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Ponyta - 021/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Ponyta - 021/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Rapidash - 022/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Rapidash - 022/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Victini - 023/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Victini - 023/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Reshiram V - 024/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Fennekin - 025/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Fennekin - 025/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Braixen - 026/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Braixen - 026/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Delphox - 027/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Delphox - 027/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Fletchinder - 028/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Fletchinder - 028/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Talonflame - 029/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Talonflame - 029/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Litten - 030/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Litten - 030/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Torracat - 031/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Torracat - 031/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Incineroar - 032/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Incineroar - 032/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Alolan Vulpix V - 033/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Alolan Vulpix VSTAR - 034/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Omastar V - 035/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Articuno - 036/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Articuno - 036/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Wailmer - 037/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Wailmer - 037/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Wailord - 038/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Wailord - 038/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Feebas - 039/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Feebas - 039/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Milotic - 040/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Milotic - 040/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Snorunt - 041/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Snorunt - 041/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Glalie - 042/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Glalie - 042/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Froslass - 043/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Froslass - 043/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Relicanth - 044/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Relicanth - 044/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Phione - 045/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Phione - 045/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Keldeo - 046/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Keldeo - 046/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Dewpider - 047/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Dewpider - 047/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Araquanid - 048/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Araquanid - 048/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Pikachu - 049/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Pikachu - 049/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Raichu - 050/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Raichu - 050/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Chinchou - 051/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Chinchou - 051/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Lanturn - 052/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Lanturn - 052/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Rotom - 053/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Rotom - 053/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Emolga - 054/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Emolga - 054/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Stunfisk - 055/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Stunfisk - 055/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Zeraora - 056/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Zeraora - 056/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Regieleki V - 057/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Regieleki VMAX - 058/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Radiant Alakazam - 059/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Drowzee - 060/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Drowzee - 060/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Hypno - 061/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Hypno - 061/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Jynx - 062/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Jynx - 062/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Misdreavus - 063/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Misdreavus - 063/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Mismagius - 064/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Mismagius - 064/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Unown V - 065/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Unown VSTAR - 066/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Ralts - 067/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Ralts - 067/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Kirlia - 068/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Kirlia - 068/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Gardevoir - 069/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Gardevoir - 069/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Mawile V - 070/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Mawile VSTAR - 071/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Meditite - 072/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Meditite - 072/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Medicham - 073/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Medicham - 073/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Chimecho - 074/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Chimecho - 074/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Sigilyph - 075/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Sigilyph - 075/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Solosis - 076/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Solosis - 076/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Duosion - 077/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Duosion - 077/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Reuniclus - 078/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Reuniclus - 078/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Elgyem - 079/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Elgyem - 079/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Beheeyem - 080/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Beheeyem - 080/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Espurr - 081/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Espurr - 081/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Meowstic - 082/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Meowstic - 082/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Swirlix - 083/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Swirlix - 083/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Slurpuff - 084/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Slurpuff - 084/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Dedenne - 085/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Dedenne - 085/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Indeedee - 086/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Indeedee - 086/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Dreepy - 087/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Dreepy - 087/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Drakloak - 088/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Drakloak - 088/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Dragapult - 089/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Dragapult - 089/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Hisuian Arcanine V - 090/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Phanpy - 091/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Phanpy - 091/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Donphan - 092/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Donphan - 092/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Baltoy - 093/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Baltoy - 093/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Claydol - 094/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Claydol - 094/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Anorith - 095/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Anorith - 095/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Armaldo - 096/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Armaldo - 096/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Terrakion - 097/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Terrakion - 097/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Hawlucha - 098/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Hawlucha - 098/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Sandygast - 099/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Sandygast - 099/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Palossand - 100/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Palossand - 100/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Stonjourner - 101/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Stonjourner - 101/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Ursaluna V - 102/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Zubat - 103/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Zubat - 103/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Golbat - 104/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Golbat - 104/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Crobat - 105/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Crobat - 105/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Murkrow - 106/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Murkrow - 106/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Honchkrow - 107/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Honchkrow - 107/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Skuntank V - 108/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Croagunk - 109/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Croagunk - 109/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Toxicroak - 110/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Toxicroak - 110/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Sandile - 111/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Sandile - 111/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Krokorok - 112/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Krokorok - 112/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Krookodile - 113/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Krookodile - 113/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Mareanie - 114/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Mareanie - 114/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Toxapex - 115/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Toxapex - 115/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Morpeko - 116/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Morpeko - 116/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Beldum - 117/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Beldum - 117/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Metang - 118/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Metang - 118/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Metagross - 119/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Metagross - 119/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Radiant Jirachi - 120/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Ferroseed - 121/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Ferroseed - 121/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Ferrothorn - 122/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Ferrothorn - 122/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Klink - 123/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Klink - 123/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Klang - 124/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Klang - 124/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Klinklang - 125/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Klinklang - 125/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Cobalion - 126/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Cobalion - 126/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Togedemaru - 127/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Togedemaru - 127/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Magearna V - 128/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Dratini - 129/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Dratini - 129/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Dragonair - 130/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Dragonair - 130/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Dragonite - 131/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Dragonite - 131/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Noibat - 132/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Noibat - 132/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Noivern - 133/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Noivern - 133/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Zygarde - 134/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Zygarde - 134/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Regidrago V - 135/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Regidrago VSTAR - 136/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Smeargle - 137/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Smeargle - 137/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Lugia V - 138/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Lugia VSTAR - 139/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Ho-Oh V - 140/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Spinda - 141/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Spinda - 141/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Swablu - 142/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Swablu - 142/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Altaria - 143/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Altaria - 143/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Buneary - 144/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Buneary - 144/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Lopunny - 145/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Lopunny - 145/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Archen - 146/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Archen - 146/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Archeops - 147/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Archeops - 147/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Rufflet - 148/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Rufflet - 148/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Hisuian Braviary - 149/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Hisuian Braviary - 149/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Fletchling - 150/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Fletchling - 150/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Brandon - 151/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Brandon - 151/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Candice - 152/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Candice - 152/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Capturing Aroma - 153/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Capturing Aroma - 153/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Earthen Seal Stone - 154/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Emergency Jelly - 155/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Emergency Jelly - 155/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Forest Seal Stone - 156/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Furisode Girl - 157/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Furisode Girl - 157/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Gym Trainer - 158/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Gym Trainer - 158/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Lance - 159/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Lance - 159/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Leafy Camo Poncho - 160/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Leafy Camo Poncho - 160/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Primordial Altar - 161/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Primordial Altar - 161/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Professor Laventon - 162/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Professor Laventon - 162/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Quad Stone - 163/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Quad Stone - 163/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Serena - 164/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Serena - 164/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Unidentified Fossil - 165/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Unidentified Fossil - 165/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Wallace - 166/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Wallace - 166/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Worker - 167/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Worker - 167/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Regenerative Energy - 168/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Regenerative Energy - 168/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - V Guard Energy - 169/195 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - V Guard Energy - 169/195 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Serperior V (Full Art) - 170/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Chesnaught V (Full Art) - 171/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Reshiram V (Full Art) - 172/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Alolan Vulpix V (Full Art) - 173/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Omastar V (Full Art) - 174/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Regieleki V (Full Art) - 175/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Unown V - 176/195 (Full Art) - 176/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Unown V - 177/195 (Alternate Full Art) - 177/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Mawile V (Full Art) - 178/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Hisuian Arcanine V (Full Art) - 179/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Skuntank V (Full Art) - 180/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Skuntank V (Alternate Full Art) - 181/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Magearna V (Full Art) - 182/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Regidrago V (Full Art) - 183/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Regidrago V (Alternate Art) - 184/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Lugia V (Full Art) - 185/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Lugia V (Alt Art) - 186/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Ho-Oh V (Full Art) - 187/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Brandon (Full Art) - 188/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Candice (Full Art) - 189/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Furisode Girl (Full Art) - 190/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Gym Trainer (Full Art) - 191/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Lance (Full Art) - 192/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Serena (Full Art) - 193/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Wallace (Full Art) - 194/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Worker (Full Art) - 195/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Serperior VSTAR (Secret) - 196/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Alolan Vulpix VSTAR (Secret) - 197/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Regieleki VMAX (Secret) - 198/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Unown VSTAR (Secret) - 199/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Mawile VSTAR (Secret) - 200/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Regidrago VSTAR (Secret) - 201/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Lugia VSTAR (Secret) - 202/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Brandon (Secret) - 203/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Candice (Secret) - 204/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Furisode Girl (Secret) - 205/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Lance (Secret) - 206/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Serena (Secret) - 207/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Wallace (Secret) - 208/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Worker (Secret) - 209/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Serperior VSTAR (Secret) - 210/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Lugia VSTAR (Secret) - 211/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Energy Switch (Secret) - 212/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Gapejaw Bog - 213/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - Leafy Camo Poncho (Secret) - 214/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Silver Tempest - V Guard Energy (Secret) - 215/195 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Flareon - 001/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Vaporeon - 002/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Octillery - 003/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Jolteon - 004/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Zekrom - 005/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Dusknoir - 006/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Dedenne - 007/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Alcremie - 008/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Ariados - 009/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Houndoom - 010/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Eevee - 011/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Oranguru - 012/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Boltund V - 013/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Sylveon V - 014/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Sylveon VMAX - 015/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Mimikyu V - 016/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Mimikyu VMAX - 017/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Single Strike Urshifu V - 018/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Single Strike Urshifu VMAX - 019/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Rapid Strike Urshifu V - 020/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Rapid Strike Urshifu VMAX - 021/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Umbreon V - 022/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Umbreon VMAX - 023/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Acerola's Premonition - 024/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Cafe Master - 025/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Gloria - 026/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Rapid Strike Style Mustard - 027/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Single Strike Style Mustard - 028/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Single Strike Urshifu VMAX (Secret) - 029/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars Trainer Gallery - Rapid Strike Urshifu VMAX (Secret) - 030/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Caterpie - 001/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Caterpie - 001/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Metapod - 002/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Metapod - 002/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Butterfree - 003/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Butterfree - 003/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Shroomish - 004/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Shroomish - 004/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Breloom - 005/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Breloom - 005/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Breloom V - 006/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Pansage - 007/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Pansage - 007/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Simisage - 008/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Simisage - 008/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sewaddle - 009/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sewaddle - 009/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Swadloon - 010/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Swadloon - 010/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Leavanny - 011/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Leavanny - 011/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Maractus - 012/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Maractus - 012/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Shelmet - 013/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Shelmet - 013/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Accelgor - 014/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Accelgor - 014/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Virizion - 015/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Virizion - 015/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Phantump - 016/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Phantump - 016/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Trevenant - 017/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Trevenant - 017/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Grubbin - 018/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Grubbin - 018/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Dewpider - 019/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Dewpider - 019/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Araquanid - 020/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Araquanid - 020/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Tsareena V - 021/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Rillaboom V - 022/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Rillaboom VMAX - 023/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Gossifleur - 024/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Gossifleur - 024/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Eldegoss - 025/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Eldegoss - 025/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Appletun V - 026/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Zarude - 027/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Zarude - 027/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Vulpix (28) - 028/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Vulpix (28) - 028/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Vulpix (29) - 029/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Vulpix (29) - 029/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Ninetales (30) - 030/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Ninetales (30) - 030/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Ninetales (31) - 031/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Ninetales (31) - 031/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Growlithe - 032/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Growlithe - 032/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Arcanine - 033/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Arcanine - 033/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Slugma - 034/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Slugma - 034/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Magcargo - 035/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Magcargo - 035/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Victini - 036/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Victini - 036/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Pansear - 037/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Pansear - 037/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Simisear - 038/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Simisear - 038/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Chandelure V - 039/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Chandelure VMAX - 040/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Heatmor - 041/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Heatmor - 041/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Oricorio - 042/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Oricorio - 042/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Cinderace V (43) - 043/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Cinderace V (44) - 044/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Cinderace VMAX - 045/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sizzlipede (46) - 046/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sizzlipede (46) - 046/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sizzlipede (47) - 047/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sizzlipede (47) - 047/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Centiskorch (48) - 048/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Centiskorch (48) - 048/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Centiskorch (49) - 049/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Centiskorch (49) - 049/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Shellder - 050/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Shellder - 050/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Cloyster - 051/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Cloyster - 051/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Staryu - 052/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Staryu - 052/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Starmie - 053/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Starmie - 053/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Lapras - 054/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Lapras - 054/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Totodile - 055/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Totodile - 055/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Croconaw - 056/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Croconaw - 056/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Feraligatr - 057/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Feraligatr - 057/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Marill - 058/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Marill - 058/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Azumarill - 059/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Azumarill - 059/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Qwilfish - 060/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Qwilfish - 060/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Mantine - 061/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Mantine - 061/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Mudkip - 062/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Mudkip - 062/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Marshtomp - 063/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Marshtomp - 063/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Swampert - 064/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Swampert - 064/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Clamperl - 065/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Clamperl - 065/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Huntail - 066/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Huntail - 066/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Gorebyss - 067/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Gorebyss - 067/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Panpour - 068/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Panpour - 068/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Simipour - 069/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Simipour - 069/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Basculin - 070/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Basculin - 070/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Galarian Darumaka - 071/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Galarian Darumaka - 071/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Galarian Darmanitan - 072/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Galarian Darmanitan - 072/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Greninja V - 073/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Clauncher - 074/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Clauncher - 074/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Clawitzer - 075/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Clawitzer - 075/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Crabominable V - 076/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Pyukumuku - 077/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Pyukumuku - 077/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Inteleon V - 078/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Inteleon VMAX - 079/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Chewtle - 080/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Chewtle - 080/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Drednaw - 081/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Drednaw - 081/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Arrokuda - 082/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Arrokuda - 082/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Barraskewda - 083/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Barraskewda - 083/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Snom - 084/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Snom - 084/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Frosmoth - 085/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Frosmoth - 085/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Pikachu V - 086/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Voltorb - 087/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Voltorb - 087/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Electrode - 088/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Electrode - 088/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Plusle - 089/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Plusle - 089/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Minun - 090/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Minun - 090/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Shinx - 091/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Shinx - 091/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Luxio - 092/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Luxio - 092/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Luxray - 093/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Luxray - 093/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Rotom - 094/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Rotom - 094/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Tynamo - 095/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Tynamo - 095/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Eelektrik - 096/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Eelektrik - 096/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Eelektross - 097/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Eelektross - 097/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Helioptile - 098/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Helioptile - 098/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Heliolisk - 099/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Heliolisk - 099/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Charjabug - 100/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Charjabug - 100/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Vikavolt - 101/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Vikavolt - 101/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Zeraora - 102/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Zeraora - 102/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Boltund V - 103/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Boltund VMAX - 104/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Toxel (105) - 105/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Toxel (105) - 105/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Toxel (106) - 106/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Toxel (106) - 106/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Toxtricity (107) - 107/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Toxtricity (107) - 107/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Toxtricity (108) - 108/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Toxtricity (108) - 108/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Morpeko (109) - 109/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Morpeko (109) - 109/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Jigglypuff - 110/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Jigglypuff - 110/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Wigglytuff - 111/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Wigglytuff - 111/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Jynx - 112/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Jynx - 112/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Mew V - 113/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Mew VMAX - 114/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Snubbull - 115/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Snubbull - 115/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Granbull - 116/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Granbull - 116/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Galarian Corsola - 117/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Galarian Corsola - 117/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Galarian Cursola - 118/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Galarian Cursola - 118/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Mawile - 119/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Mawile - 119/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Deoxys - 120/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Deoxys - 120/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Munna - 121/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Munna - 121/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Musharna - 122/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Musharna - 122/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sigilyph - 123/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sigilyph - 123/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Meloetta - 124/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Meloetta - 124/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sandygast - 125/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sandygast - 125/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Palossand - 126/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Palossand - 126/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Indeedee - 127/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Indeedee - 127/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Dreepy - 128/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Dreepy - 128/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Drakloak - 129/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Drakloak - 129/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Dragapult - 130/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Dragapult - 130/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sandshrew - 131/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sandshrew - 131/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sandslash - 132/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sandslash - 132/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Mankey - 133/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Mankey - 133/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Primeape - 134/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Primeape - 134/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Geodude - 135/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Geodude - 135/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Graveler - 136/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Graveler - 136/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Golem - 137/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Golem - 137/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Onix - 138/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Onix - 138/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Steelix - 139/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Steelix - 139/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Gligar - 140/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Gligar - 140/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Gliscor - 141/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Gliscor - 141/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Makuhita - 142/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Makuhita - 142/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Hariyama - 143/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Hariyama - 143/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Baltoy - 144/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Baltoy - 144/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Claydol - 145/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Claydol - 145/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Lucario V - 146/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Drilbur - 147/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Drilbur - 147/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Landorus - 148/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Landorus - 148/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Pancham - 149/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Pancham - 149/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Stufful - 150/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Stufful - 150/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Bewear - 151/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Bewear - 151/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Clobbopus - 152/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Clobbopus - 152/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Grapploct - 153/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Grapploct - 153/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Falinks (154) - 154/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Falinks (154) - 154/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Falinks (155) - 155/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Falinks (155) - 155/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Gengar V - 156/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Gengar VMAX - 157/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Tyranitar V - 158/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Galarian Zigzagoon - 159/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Galarian Zigzagoon - 159/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Galarian Linoone - 160/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Galarian Linoone - 160/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Galarian Obstagoon - 161/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Galarian Obstagoon - 161/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Carvanha - 162/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Carvanha - 162/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sharpedo - 163/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sharpedo - 163/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Absol - 164/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Absol - 164/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Croagunk - 165/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Croagunk - 165/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Toxicroak - 166/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Toxicroak - 166/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Darkrai - 167/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Darkrai - 167/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Trubbish - 168/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Trubbish - 168/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Garbodor - 169/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Garbodor - 169/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Zorua - 170/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Zorua - 170/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Zoroark - 171/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Zoroark - 171/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Vullaby - 172/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Vullaby - 172/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Mandibuzz - 173/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Mandibuzz - 173/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Pangoro - 174/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Pangoro - 174/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Yveltal - 175/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Yveltal - 175/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Impidimp - 176/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Impidimp - 176/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Morgrem - 177/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Morgrem - 177/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Grimmsnarl - 178/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Grimmsnarl - 178/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Morpeko (179) - 179/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Morpeko (179) - 179/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Galarian Meowth - 180/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Galarian Meowth - 180/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Galarian Perrserker - 181/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Galarian Perrserker - 181/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Skarmory - 182/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Skarmory - 182/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Excadrill - 183/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Excadrill - 183/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Durant - 184/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Durant - 184/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Genesect V - 185/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Klefki - 186/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Klefki - 186/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Togedemaru - 187/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Togedemaru - 187/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Meltan - 188/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Meltan - 188/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Melmetal - 189/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Melmetal - 189/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Corviknight - 190/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Corviknight - 190/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Cufant - 191/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Cufant - 191/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Copperajah - 192/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Copperajah - 192/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Latias - 193/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Latias - 193/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Latios - 194/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Latios - 194/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Goomy - 195/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Goomy - 195/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sliggoo - 196/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sliggoo - 196/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Goodra - 197/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Goodra - 197/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Turtonator - 198/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Turtonator - 198/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Meowth - 199/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Meowth - 199/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Persian - 200/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Persian - 200/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Dodrio V - 201/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Chansey - 202/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Chansey - 202/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Blissey - 203/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Blissey - 203/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Kangaskhan - 204/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Kangaskhan - 204/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Eevee - 205/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Eevee - 205/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Snorlax - 206/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Snorlax - 206/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Dunsparce - 207/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Dunsparce - 207/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Stantler - 208/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Stantler - 208/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Smeargle - 209/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Smeargle - 209/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Skitty - 210/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Skitty - 210/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Delcatty - 211/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Delcatty - 211/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Buneary - 212/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Buneary - 212/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Lopunny - 213/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Lopunny - 213/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Bunnelby - 214/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Bunnelby - 214/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Diggersby - 215/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Diggersby - 215/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Hawlucha - 216/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Hawlucha - 216/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Greedent V - 217/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Greedent VMAX - 218/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Rookidee - 219/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Rookidee - 219/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Corvisquire - 220/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Corvisquire - 220/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Wooloo (221) - 221/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Wooloo (221) - 221/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Wooloo (222) - 222/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Wooloo (222) - 222/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Dubwool - 223/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Dubwool - 223/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Adventurer's Discovery - 224/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Adventurer's Discovery - 224/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Battle VIP Pass - 225/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Battle VIP Pass - 225/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Bug Catcher - 226/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Bug Catcher - 226/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Chili & Cilan & Cress - 227/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Chili & Cilan & Cress - 227/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Cook - 228/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Cook - 228/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Cram-o-matic - 229/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Cram-o-matic - 229/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Cross Switcher - 230/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Cross Switcher - 230/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Crossceiver - 231/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Crossceiver - 231/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Dancer - 232/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Dancer - 232/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Elesa's Sparkle - 233/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Elesa's Sparkle - 233/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Farewell Bell - 234/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Farewell Bell - 234/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Judge - 235/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Judge - 235/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Power Tablet - 236/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Power Tablet - 236/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Quick Ball - 237/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Quick Ball - 237/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Schoolboy - 238/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Schoolboy - 238/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Schoolgirl - 239/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Schoolgirl - 239/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Shauna - 240/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Shauna - 240/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sidney - 241/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sidney - 241/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Skaters' Park - 242/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Skaters' Park - 242/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Spongy Gloves - 243/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Spongy Gloves - 243/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Fusion Strike Energy - 244/264 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Fusion Strike Energy - 244/264 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Celebi V (Alternate Full Art) - 245/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Tsareena V (Full Art) - 246/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Chandelure V (Full Art) - 247/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Crabominable V (Full Art) - 248/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Boltund V (Full Art) - 249/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Mew V (Full Art) - 250/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Mew V (Alternate Full Art) - 251/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sandaconda V (Alternate Full Art) - 252/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Hoopa V (Full Art) - 253/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Genesect V (Full Art) - 254/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Genesect V (Alternate Full Art) - 255/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Greedent V (Full Art) - 256/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Greedent V (Alternate Full Art) - 257/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Chili & Cilan & Cress (Full Art) - 258/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Dancer (Full Art) - 259/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Elesa's Sparkle (Full Art) - 260/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Schoolboy (Full Art) - 261/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Schoolgirl (Full Art) - 262/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Shauna (Full Art) - 263/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sidney (Full Art) - 264/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Chandelure VMAX (Secret) - 265/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Inteleon VMAX (Alternate Art Secret) - 266/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Boltund VMAX (Secret) - 267/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Mew VMAX (Secret) - 268/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Mew VMAX (Alternate Art Secret) - 269/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Espeon VMAX (Alternate Art Secret) - 270/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Gengar VMAX (Alternate Art Secret) - 271/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Greedent VMAX (Secret) - 272/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Chili & Cilan & Cress (Secret) - 273/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Dancer (Secret) - 274/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Elesa's Sparkle (Secret) - 275/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Schoolboy (Secret) - 276/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Schoolgirl (Secret) - 277/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Shauna (Secret) - 278/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Sidney (Secret) - 279/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Flaaffy (Secret) - 280/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Power Tablet (Secret) - 281/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Training Court (Secret) - 282/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Grass Energy (Secret) - 283/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fusion Strike - Fire Energy (Secret) - 284/264 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Ho-Oh - 001/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Reshiram - 002/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Kyogre - 003/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Palkia - 004/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Pikachu - 005/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Flying Pikachu V - 006/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Flying Pikachu VMAX - 007/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Surfing Pikachu V - 008/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Surfing Pikachu VMAX - 009/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Zekrom - 010/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Mew - 011/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Xerneas - 012/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Cosmog - 013/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Cosmoem - 014/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Lunala - 015/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Zacian V - 016/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Groudon - 017/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Zamazenta V - 018/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Yveltal - 019/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Dialga - 020/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Solgaleo - 021/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Lugia - 022/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Professor's Research - 023/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Professor's Research (Full Art) - 024/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celebrations - Mew (Secret) - 025/25 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Pinsir - 001/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Pinsir - 001/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Hoppip - 002/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Hoppip - 002/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Skiploom - 003/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Skiploom - 003/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Jumpluff - 004/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Jumpluff - 004/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Seedot - 005/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Seedot - 005/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Tropius - 006/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Tropius - 006/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Leafeon V - 007/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Leafeon VMAX - 008/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Petilil - 009/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Petilil - 009/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Lilligant - 010/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Lilligant - 010/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Dwebble - 011/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Dwebble - 011/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Crustle - 012/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Crustle - 012/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Trevenant V - 013/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Trevenant VMAX - 014/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Gossifleur - 015/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Gossifleur - 015/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Eldegoss - 016/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Eldegoss - 016/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Applin - 017/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Applin - 017/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Flareon VMAX - 018/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Entei - 019/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Entei - 019/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Victini - 020/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Victini - 020/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Volcarona V - 021/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Litleo - 022/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Litleo - 022/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Pyroar - 023/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Pyroar - 023/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Psyduck - 024/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Psyduck - 024/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Golduck - 025/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Golduck - 025/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Tentacool - 026/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Tentacool - 026/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Tentacruel - 027/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Tentacruel - 027/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Gyarados V - 028/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Gyarados VMAX - 029/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Vaporeon VMAX - 030/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Suicune V - 031/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Lotad - 032/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Lotad - 032/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Lombre - 033/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Lombre - 033/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Ludicolo - 034/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Ludicolo - 034/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Carvanha - 035/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Carvanha - 035/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Sharpedo - 036/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Sharpedo - 036/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Feebas - 037/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Feebas - 037/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Milotic - 038/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Milotic - 038/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Luvdisc - 039/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Luvdisc - 039/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Glaceon V - 040/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Glaceon VMAX - 041/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Tympole - 042/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Tympole - 042/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Cryogonal - 043/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Cryogonal - 043/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Bergmite - 044/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Bergmite - 044/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Avalugg - 045/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Avalugg - 045/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Wishiwashi - 046/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Wishiwashi - 046/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Eiscue - 047/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Eiscue - 047/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Arctovish V - 048/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Pikachu - 049/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Pikachu - 049/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Raichu - 050/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Raichu - 050/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Jolteon VMAX - 051/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Chinchou - 052/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Chinchou - 052/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Lanturn - 053/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Lanturn - 053/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Mareep - 054/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Mareep - 054/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Flaaffy - 055/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Flaaffy - 055/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Ampharos - 056/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Ampharos - 056/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Emolga - 057/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Emolga - 057/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Dracozolt V - 058/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Dracozolt VMAX - 059/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Regieleki - 060/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Regieleki - 060/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Drowzee - 061/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Drowzee - 061/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Hypno - 062/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Hypno - 062/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Galarian Articuno - 063/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Galarian Articuno - 063/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Espeon V - 064/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Espeon VMAX - 065/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Wobbuffet - 066/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Wobbuffet - 066/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Sableye - 067/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Sableye - 067/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Woobat - 068/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Woobat - 068/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Swoobat - 069/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Swoobat - 069/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Golurk V - 070/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Flabebe - 071/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Flabebe - 071/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Floette - 072/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Floette - 072/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Florges - 073/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Florges - 073/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Sylveon V - 074/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Sylveon VMAX - 075/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Pumpkaboo - 076/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Pumpkaboo - 076/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Gourgeist - 077/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Gourgeist - 077/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Cutiefly - 078/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Cutiefly - 078/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Ribombee - 079/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Ribombee - 079/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Marshadow - 080/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Marshadow - 080/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Hitmonchan - 081/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Hitmonchan - 081/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Galarian Zapdos - 082/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Galarian Zapdos - 082/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Medicham V - 083/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Hippopotas - 084/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Hippopotas - 084/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Hippowdon - 085/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Hippowdon - 085/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Roggenrola - 086/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Roggenrola - 086/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Boldore - 087/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Boldore - 087/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Gigalith - 088/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Gigalith - 088/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Palpitoad - 089/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Palpitoad - 089/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Seismitoad - 090/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Seismitoad - 090/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Lycanroc V - 091/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Lycanroc VMAX - 092/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Galarian Moltres - 093/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Galarian Moltres - 093/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Umbreon V - 094/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Umbreon VMAX - 095/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Nuzleaf - 096/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Nuzleaf - 096/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Shiftry - 097/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Shiftry - 097/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Scraggy - 098/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Scraggy - 098/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Scrafty - 099/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Scrafty - 099/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Garbodor V - 100/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Garbodor VMAX - 101/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Zorua - 102/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Zorua - 102/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Zoroark - 103/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Zoroark - 103/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Nickit - 104/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Nickit - 104/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Thievul - 105/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Thievul - 105/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Altaria - 106/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Altaria - 106/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Bagon - 107/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Bagon - 107/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Shelgon - 108/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Shelgon - 108/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Salamence - 109/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Salamence - 109/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Rayquaza V - 110/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Rayquaza VMAX - 111/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Dialga - 112/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Dialga - 112/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Deino - 113/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Deino - 113/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Zweilous - 114/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Zweilous - 114/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Hydreigon - 115/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Hydreigon - 115/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Kyurem - 116/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Kyurem - 116/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Noivern V - 117/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Zygarde - 118/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Zygarde - 118/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Drampa - 119/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Drampa - 119/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Flapple - 120/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Flapple - 120/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Appletun - 121/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Appletun - 121/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Duraludon V - 122/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Duraludon VMAX - 123/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Regidrago - 124/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Regidrago - 124/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Eevee - 125/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Eevee - 125/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Teddiursa - 126/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Teddiursa - 126/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Ursaring - 127/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Ursaring - 127/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Smeargle - 128/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Smeargle - 128/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Slakoth - 129/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Slakoth - 129/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Vigoroth - 130/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Vigoroth - 130/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Slaking - 131/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Slaking - 131/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Swablu - 132/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Swablu - 132/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Lillipup - 133/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Lillipup - 133/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Herdier - 134/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Herdier - 134/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Stoutland - 135/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Stoutland - 135/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Rufflet - 136/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Rufflet - 136/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Braviary - 137/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Braviary - 137/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Fletchling - 138/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Fletchling - 138/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Fletchinder - 139/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Fletchinder - 139/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Talonflame - 140/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Talonflame - 140/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Aroma Lady - 141/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Aroma Lady - 141/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Boost Shake - 142/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Boost Shake - 142/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Copycat - 143/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Copycat - 143/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Crystal Cave - 144/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Crystal Cave - 144/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Digging Gloves - 145/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Digging Gloves - 145/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Dream Ball - 146/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Dream Ball - 146/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Elemental Badge - 147/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Elemental Badge - 147/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Full Face Guard - 148/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Full Face Guard - 148/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Gordie - 149/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Gordie - 149/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Lucky Ice Pop - 150/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Lucky Ice Pop - 150/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Moon & Sun Badge - 151/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Moon & Sun Badge - 151/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Raihan - 152/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Raihan - 152/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Rapid Strike Scroll of the Flying Dragon - 153/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Rapid Strike Scroll of the Flying Dragon - 153/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Rescue Carrier - 154/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Rescue Carrier - 154/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Ribbon Badge - 155/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Ribbon Badge - 155/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Rubber Gloves - 156/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Rubber Gloves - 156/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Shopping Center - 157/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Shopping Center - 157/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Single Strike Scroll of the Fanged Dragon - 158/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Single Strike Scroll of the Fanged Dragon - 158/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Snow Leaf Badge - 159/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Snow Leaf Badge - 159/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Spirit Mask - 160/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Spirit Mask - 160/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Stormy Mountains - 161/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Stormy Mountains - 161/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Switching Cups - 162/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Switching Cups - 162/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Toy Catcher - 163/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Toy Catcher - 163/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Zinnia's Resolve - 164/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Zinnia's Resolve - 164/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Treasure Energy - 165/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Treasure Energy - 165/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Leafeon V (Full Art) - 166/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Leafeon V (Alternate Full Art) - 167/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Trevenant V (Full Art) - 168/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Flareon V (Full Art) - 169/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Volcarona V (Full Art) - 170/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Gyarados V (Full Art) - 171/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Vaporeon V (Full Art) - 172/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Suicune V (Full Art) - 173/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Glaceon V (Full Art) - 174/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Glaceon V (Alternate Art Full) - 175/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Arctovish V (Full Art) - 176/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Jolteon V (Full Art) - 177/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Dracozolt V (Full Art) - 178/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Espeon V (Full Art) - 179/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Espeon V (Alternate Full Art) - 180/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Golurk V (Full Art) - 181/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Golurk V (Alternate Full Art) - 182/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Sylveon V (Full Art) - 183/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Sylveon V (Alternate Full Art) - 184/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Medicham V (Full Art) - 185/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Medicham V (Alternate Full Art) - 186/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Lycanroc V (Full Art) - 187/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Umbreon V (Full Art) - 188/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Umbreon V (Alternate Full Art) - 189/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Garbodor V (Full Art) - 190/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Dragonite V (Full Art) - 191/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Dragonite V (Alternate Full Art) - 192/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Rayquaza V (Full Art) - 193/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Rayquaza V (Alternate Full Art) - 194/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Noivern V (Full Art) - 195/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Noivern V (Alternate Full Art) - 196/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Duraludon V (Full Art) - 197/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Duraludon V (Alternate Full Art) - 198/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Aroma Lady (Full Art) - 199/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Copycat (Full Art) - 200/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Gordie (Full Art) - 201/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Raihan (Full Art) - 202/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Zinnia's Resolve (Full Art) - 203/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Leafeon VMAX (Secret) - 204/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Leafeon VMAX - 205/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Trevenant VMAX (Secret) - 206/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Gyarados VMAX (Secret) - 207/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Glaceon VMAX (Secret) - 208/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Glaceon VMAX (Alternate Art) - 209/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Dracozolt VMAX (Secret) - 210/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Sylveon VMAX (Secret) - 211/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Sylveon VMAX (Alternate Art Secret) - 212/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Lycanroc VMAX (Secret) - 213/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Umbreon VMAX (Secret) - 214/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Umbreon VMAX (Alternate Art Secret) - 215/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Garbodor VMAX (Secret) - 216/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Rayquaza VMAX (Secret) - 217/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Rayquaza VMAX (Alternate Art Secret) - 218/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Duraludon VMAX (Secret) - 219/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Duraludon VMAX (Alternate Art Secret) - 220/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Aroma Lady (Secret) - 221/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Copycat (Secret) - 222/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Gordie (Secret) - 223/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Raihan (Secret) - 224/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Zinnia's Resolve (Secret) - 225/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Froslass (Secret) - 226/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Inteleon (Secret) - 227/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Cresselia (Secret) - 228/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Boost Shake (Secret) - 229/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Crystal Cave (Secret) - 230/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Full Face Guard (Secret) - 231/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Stormy Mountains (Secret) - 232/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Toy Catcher (Secret) - 233/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Turffield Stadium (Secret) - 234/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Lightning Energy (Secret) - 235/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Darkness Energy (Secret) - 236/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolving Skies - Metal Energy (Secret) - 237/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Bellsprout - 001/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Bellsprout - 001/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Weepinbell - 002/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Weepinbell - 002/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Victreebel - 003/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Victreebel - 003/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Cacnea - 004/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Cacnea - 004/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Cacturne - 005/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Cacturne - 005/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Kricketune V - 006/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Cherubi - 007/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Cherubi - 007/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Cherrim - 008/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Cherrim - 008/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Carnivine - 009/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Carnivine - 009/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Durant - 010/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Durant - 010/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Scatterbug - 011/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Scatterbug - 011/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Spewpa - 012/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Spewpa - 012/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Vivillon - 013/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Vivillon - 013/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Fomantis - 014/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Fomantis - 014/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Lurantis - 015/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Lurantis - 015/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Tapu Bulu - 016/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Tapu Bulu - 016/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Blipbug - 017/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Blipbug - 017/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Flapple V - 018/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Flapple VMAX - 019/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Entei - 020/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Entei - 020/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Victini V - 021/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Victini VMAX - 022/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Tepig - 023/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Tepig - 023/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Pignite - 024/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Pignite - 024/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Emboar - 025/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Emboar - 025/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Heatmor - 026/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Heatmor - 026/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Salandit - 027/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Salandit - 027/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Salazzle - 028/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Salazzle - 028/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Sizzlipede - 029/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Sizzlipede - 029/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Centiskorch - 030/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Centiskorch - 030/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Horsea - 031/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Horsea - 031/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Seadra - 032/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Seadra - 032/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Kingdra - 033/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Kingdra - 033/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Galarian Mr. Mime - 034/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Galarian Mr. Mime - 034/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Galarian Mr. Rime - 035/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Galarian Mr. Rime - 035/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Remoraid - 036/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Remoraid - 036/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Octillery - 037/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Octillery - 037/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Corphish - 038/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Corphish - 038/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Crawdaunt - 039/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Crawdaunt - 039/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Empoleon V - 040/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Frillish - 041/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Frillish - 041/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Jellicent - 042/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Jellicent - 042/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Bruxish - 043/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Bruxish - 043/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Electabuzz - 044/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Electabuzz - 044/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Electivire - 045/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Electivire - 045/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Shinx - 046/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Shinx - 046/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Luxio - 047/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Luxio - 047/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Luxray - 048/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Luxray - 048/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Pachirisu - 049/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Pachirisu - 049/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Tapu Koko V - 050/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Tapu Koko VMAX - 051/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Yamper - 052/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Yamper - 052/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Boltund - 053/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Boltund - 053/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Galarian Slowpoke - 054/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Galarian Slowpoke - 054/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Spoink - 055/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Spoink - 055/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Grumpig - 056/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Grumpig - 056/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Baltoy - 057/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Baltoy - 057/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Claydol - 058/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Claydol - 058/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Chimecho - 059/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Chimecho - 059/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Espurr - 060/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Espurr - 060/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Meowstic - 061/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Meowstic - 061/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Mimikyu V - 062/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Necrozma V - 063/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Dottler - 064/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Dottler - 064/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Orbeetle - 065/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Orbeetle - 065/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Mankey - 066/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Mankey - 066/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Primeape - 067/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Primeape - 067/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Onix - 068/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Onix - 068/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Cubone - 069/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Cubone - 069/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Marowak - 070/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Marowak - 070/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Gligar - 071/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Gligar - 071/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Gliscor - 072/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Gliscor - 072/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Timburr - 073/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Timburr - 073/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Gurdurr - 074/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Gurdurr - 074/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Conkeldurr - 075/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Conkeldurr - 075/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Mienfoo - 076/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Mienfoo - 076/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Mienshao - 077/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Mienshao - 077/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Rolycoly - 078/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Rolycoly - 078/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Carkol - 079/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Carkol - 079/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Coalossal - 080/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Coalossal - 080/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Silicobra - 081/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Silicobra - 081/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Sandaconda - 082/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Sandaconda - 082/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Falinks - 083/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Falinks - 083/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Stonjourner - 084/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Stonjourner - 084/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Single Strike Urshifu V - 085/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Single Strike Urshifu VMAX - 086/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Rapid Strike Urshifu V - 087/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Rapid Strike Urshifu VMAX - 088/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Zubat - 089/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Zubat - 089/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Golbat - 090/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Golbat - 090/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Crobat - 091/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Crobat - 091/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Galarian Slowbro - 092/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Galarian Slowbro - 092/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Murkrow - 093/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Murkrow - 093/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Honchkrow - 094/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Honchkrow - 094/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Houndour - 095/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Houndour - 095/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Houndoom - 096/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Houndoom - 096/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Tyranitar V - 097/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Morpeko - 098/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Morpeko - 098/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Steelix - 099/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Steelix - 099/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Mawile - 100/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Mawile - 100/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Bronzor - 101/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Bronzor - 101/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Bronzong - 102/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Bronzong - 102/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Pawniard - 103/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Pawniard - 103/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Bisharp - 104/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Bisharp - 104/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Honedge - 105/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Honedge - 105/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Doublade - 106/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Doublade - 106/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Aegislash (107) - 107/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Aegislash (107) - 107/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Aegislash (108) - 108/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Aegislash (108) - 108/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Corviknight V - 109/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Corviknight VMAX - 110/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Spearow - 111/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Spearow - 111/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Fearow - 112/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Fearow - 112/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Lickitung - 113/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Lickitung - 113/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Lickilicky - 114/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Lickilicky - 114/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Glameow - 115/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Glameow - 115/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Purugly - 116/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Purugly - 116/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Stoutland V - 117/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Bouffalant - 118/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Bouffalant - 118/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Drampa - 119/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Drampa - 119/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Indeedee - 120/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Indeedee - 120/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Bruno - 121/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Bruno - 121/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Camping Gear - 122/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Camping Gear - 122/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Cheryl - 123/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Cheryl - 123/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Energy Recycler - 124/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Energy Recycler - 124/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Escape Rope - 125/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Escape Rope - 125/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Exp. Share - 126/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Exp. Share - 126/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Fan of Waves - 127/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Fan of Waves - 127/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Korrina's Focus - 128/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Korrina's Focus - 128/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Level Ball - 129/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Level Ball - 129/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Phoebe - 130/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Phoebe - 130/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Rapid Strike Scroll of Swirls - 131/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Rapid Strike Scroll of Swirls - 131/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Rapid Strike Style Mustard - 132/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Rapid Strike Style Mustard - 132/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Single Strike Scroll of Scorn - 133/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Single Strike Scroll of Scorn - 133/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Single Strike Style Mustard - 134/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Single Strike Style Mustard - 134/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Sordward & Shielbert - 135/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Sordward & Shielbert - 135/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Tool Jammer - 136/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Tool Jammer - 136/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Tower of Darkness - 137/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Tower of Darkness - 137/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Tower of Waters - 138/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Tower of Waters - 138/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Urn of Vitality - 139/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Urn of Vitality - 139/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Rapid Strike Energy - 140/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Rapid Strike Energy - 140/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Single Strike Energy - 141/163 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Single Strike Energy - 141/163 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Kricketune V (Full Art) - 142/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Flapple V (Full Art) - 143/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Victini V (Full Art) - 144/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Empoleon V (Full Art) - 145/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Empoleon V (Alternate Full Art) - 146/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Tapu Koko V (Full Art) - 147/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Mimikyu V (Full Art) - 148/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Necrozma V (Full Art) - 149/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Single Strike Urshifu V (Full Art) - 150/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Single Strike Urshifu V (Alternate Full Art) - 151/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Rapid Strike Urshifu V (Full Art) - 152/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Rapid Strike Urshifu V (Alternate Full Art) - 153/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Tyranitar V (Full Art) - 154/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Tyranitar V (Alternate Full Art) - 155/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Corviknight V (Full Art) - 156/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Stoutland V (Full Art) - 157/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Bruno (Full Art) - 158/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Cheryl (Full Art) - 159/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Korrina's Focus (Full Art) - 160/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Phoebe (Full Art) - 161/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Rapid Strike Style Mustard (Full Art) - 162/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Single Strike Style Mustard (Full Art) - 163/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Flapple VMAX (Secret) - 164/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Victini VMAX (Secret) - 165/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Tapu Koko VMAX (Secret) - 166/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Single Strike Urshifu VMAX (Secret) - 167/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Single Strike Urshifu VMAX (Alternate Art Secret) - 168/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Rapid Strike Urshifu VMAX (Secret) - 169/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Rapid Strike Urshifu VMAX (Alternate Art Secret) - 170/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Corviknight VMAX (Secret) - 171/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Bruno (Secret) - 172/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Cheryl (Secret) - 173/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Korrina's Focus (Secret) - 174/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Phoebe (Secret) - 175/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Rapid Strike Style Mustard (Secret) - 176/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Single Strike Style Mustard (Secret) - 177/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Octillery (Secret) - 178/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Houndoom (Secret) - 179/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Exp. Share (Secret) - 180/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Level Ball (Secret) - 181/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Rapid Strike Energy (Secret) - 182/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Battle Styles - Single Strike Energy (Secret) - 183/163 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Yanma - 001/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Yanma - 001/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Yanmega - 002/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Yanmega - 002/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Celebi - 003/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Celebi - 003/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Cacnea - 004/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Cacnea - 004/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Tropius - 005/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Tropius - 005/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Rowlet - 006/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Rowlet - 006/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Dartrix - 007/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Dartrix - 007/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Decidueye - 008/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Decidueye - 008/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Dhelmise V - 009/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Dhelmise VMAX - 010/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Grookey - 011/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Grookey - 011/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Thwackey - 012/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Thwackey - 012/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Rillaboom - 013/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Rillaboom - 013/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Gossifleur - 014/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Gossifleur - 014/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Eldegoss - 015/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Eldegoss - 015/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Zarude - 016/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Zarude - 016/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Reshiram - 017/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Cinderace V - 018/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Cinderace VMAX - 019/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Horsea - 020/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Horsea - 020/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Kyogre - 021/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Buizel - 022/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Buizel - 022/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Floatzel - 023/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Floatzel - 023/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Manaphy - 024/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Manaphy - 024/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Volcanion - 025/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Volcanion - 025/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Chewtle - 026/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Chewtle - 026/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Drednaw - 027/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Drednaw - 027/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Cramorant - 028/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Cramorant - 028/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Snom - 029/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Snom - 029/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Frosmoth - 030/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Frosmoth - 030/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Shinx - 031/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Shinx - 031/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Luxio - 032/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Luxio - 032/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Luxray - 033/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Luxray - 033/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Rotom - 034/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Rotom - 034/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Morpeko (035) - 035/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Morpeko (035) - 035/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Morpeko (036) - 036/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Morpeko (036) - 036/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Morpeko V - 037/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Morpeko VMAX - 038/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Indeedee V - 039/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Trapinch - 040/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Trapinch - 040/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Koffing - 041/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Koffing - 041/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Galarian Weezing - 042/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Galarian Weezing - 042/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Spinarak - 043/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Spinarak - 043/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Crobat V - 044/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Crobat VMAX - 045/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Yveltal - 046/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Nickit - 047/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Nickit - 047/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Thievul - 048/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Thievul - 048/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Cufant - 049/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Cufant - 049/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Ditto V - 050/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Ditto VMAX - 051/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Eevee - 052/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Eevee - 052/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Greedent V - 053/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Cramorant V - 054/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Cramorant VMAX - 055/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Indeedee - 056/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Indeedee - 056/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Ball Guy - 057/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Ball Guy - 057/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Boss's Orders - 058/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Boss's Orders - 058/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Gym Trainer - 059/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Gym Trainer - 059/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Professor's Research - 060/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Professor's Research - 060/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Rusted Shield - 061/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Rusted Shield - 061/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Rusted Sword - 062/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Rusted Sword - 062/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Team Yell Towel - 063/72 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Team Yell Towel - 063/72 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Alcremie V (Full Art) - 064/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Ball Guy (Full Art) - 065/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Bird Keeper (Full Art) - 066/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Cara Liss (Full Art) - 067/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Gym Trainer (Full Art) - 068/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Piers (Full Art) - 069/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Poke Kid (Full Art) - 070/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Rose (Full Art) - 071/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Skyla (Full Art) - 072/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Fates - Alcremie VMAX (Secret) - 073/72 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Weedle - 001/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Weedle - 001/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Kakuna - 002/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Kakuna - 002/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Beedrill - 003/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Beedrill - 003/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Exeggcute - 004/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Exeggcute - 004/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Exeggutor - 005/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Exeggutor - 005/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Yanma - 006/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Yanma - 006/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Yanmega - 007/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Yanmega - 007/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Pineco - 008/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Pineco - 008/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Celebi - 009/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Seedot - 010/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Seedot - 010/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Nuzleaf - 011/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Nuzleaf - 011/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Shiftry - 012/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Shiftry - 012/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Nincada - 013/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Nincada - 013/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Ninjask - 014/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Ninjask - 014/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Shaymin - 015/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Shaymin - 015/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Genesect - 016/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Genesect - 016/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Skiddo - 017/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Skiddo - 017/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Gogoat - 018/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Gogoat - 018/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Dhelmise - 019/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Dhelmise - 019/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Orbeetle V - 020/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Orbeetle VMAX - 021/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Zarude V - 022/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Charmander - 023/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Charmander - 023/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Charmeleon - 024/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Charmeleon - 024/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Charizard - 025/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Charizard - 025/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Flareon - 026/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Flareon - 026/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Slugma - 027/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Slugma - 027/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Magcargo - 028/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Magcargo - 028/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Talonflame V - 029/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Vaporeon - 030/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Vaporeon - 030/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Wailmer - 031/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Wailmer - 031/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Wailord - 032/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Wailord - 032/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Oshawott - 033/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Oshawott - 033/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Dewott - 034/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Dewott - 034/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Samurott - 035/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Samurott - 035/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Galarian Darmanitan V - 036/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Galarian Darmanitan VMAX - 037/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Chewtle - 038/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Chewtle - 038/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Drednaw - 039/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Drednaw - 039/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Cramorant - 040/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Cramorant - 040/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Arrokuda - 041/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Arrokuda - 041/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Barraskewda - 042/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Barraskewda - 042/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Pikachu V - 043/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Pikachu VMAX - 044/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Voltorb - 045/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Voltorb - 045/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Electrode - 046/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Electrode - 046/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Jolteon - 047/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Jolteon - 047/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Zapdos - 048/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Zapdos - 048/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Ampharos V - 049/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Raikou - 050/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Electrike - 051/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Electrike - 051/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Manectric - 052/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Manectric - 052/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Blitzle - 053/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Blitzle - 053/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Zebstrika - 054/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Zebstrika - 054/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Joltik - 055/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Joltik - 055/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Galvantula - 056/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Galvantula - 056/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Tynamo - 057/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Tynamo - 057/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Eelektrik - 058/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Eelektrik - 058/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Eelektross - 059/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Eelektross - 059/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Zekrom - 060/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Zekrom - 060/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Zeraora - 061/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Zeraora - 061/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Pincurchin - 062/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Pincurchin - 062/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Clefairy - 063/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Clefairy - 063/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Clefable - 064/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Clefable - 064/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Girafarig - 065/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Girafarig - 065/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Shedinja - 066/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Shedinja - 066/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Shuppet - 067/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Shuppet - 067/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Banette - 068/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Banette - 068/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Duskull - 069/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Duskull - 069/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Dusclops - 070/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Dusclops - 070/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Dusknoir - 071/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Dusknoir - 071/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Chimecho - 072/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Chimecho - 072/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Woobat - 073/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Woobat - 073/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Swoobat - 074/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Swoobat - 074/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Cottonee - 075/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Cottonee - 075/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Whimsicott - 076/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Whimsicott - 076/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Dedenne - 077/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Dedenne - 077/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Xerneas - 078/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Xerneas - 078/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Diancie - 079/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Diancie - 079/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Milcery - 080/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Milcery - 080/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Alcremie - 081/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Alcremie - 081/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Zacian - 082/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Wooper - 083/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Wooper - 083/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Quagsire - 084/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Quagsire - 084/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Shuckle - 085/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Shuckle - 085/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Phanpy - 086/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Phanpy - 086/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Donphan - 087/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Donphan - 087/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Hitmontop - 088/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Hitmontop - 088/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Regirock - 089/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Regirock - 089/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Riolu - 090/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Riolu - 090/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Drilbur - 091/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Drilbur - 091/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Terrakion - 092/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Terrakion - 092/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Zygarde - 093/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Zygarde - 093/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Rockruff - 094/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Rockruff - 094/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Lycanroc - 095/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Lycanroc - 095/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Mudbray - 096/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Mudbray - 096/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Mudsdale - 097/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Mudsdale - 097/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Coalossal V - 098/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Coalossal VMAX - 099/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Clobbopus - 100/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Clobbopus - 100/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Grapploct - 101/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Grapploct - 101/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Zamazenta - 102/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Poochyena - 103/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Poochyena - 103/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Mightyena - 104/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Mightyena - 104/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Sableye - 105/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Sableye - 105/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Drapion V - 106/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Sandile - 107/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Sandile - 107/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Krokorok - 108/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Krokorok - 108/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Krookodile - 109/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Krookodile - 109/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Trubbish - 110/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Trubbish - 110/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Garbodor - 111/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Garbodor - 111/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Galarian Meowth - 112/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Galarian Meowth - 112/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Galarian Perrserker - 113/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Galarian Perrserker - 113/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Forretress - 114/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Forretress - 114/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Steelix V - 115/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Beldum - 116/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Beldum - 116/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Metang - 117/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Metang - 117/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Metagross - 118/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Metagross - 118/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Jirachi - 119/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Lucario - 120/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Lucario - 120/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Dialga - 121/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Dialga - 121/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Excadrill - 122/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Excadrill - 122/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Ferroseed - 123/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Ferroseed - 123/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Ferrothorn - 124/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Ferrothorn - 124/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Galarian Stunfisk - 125/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Galarian Stunfisk - 125/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Aegislash V - 126/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Aegislash VMAX - 127/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Magearna - 128/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Magearna - 128/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Duraludon - 129/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Duraludon - 129/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Eevee - 130/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Eevee - 130/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Snorlax - 131/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Snorlax - 131/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Lugia - 132/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Lugia - 132/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Taillow - 133/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Taillow - 133/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Swellow - 134/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Swellow - 134/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Whismur - 135/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Whismur - 135/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Loudred - 136/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Loudred - 136/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Exploud - 137/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Exploud - 137/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Rayquaza - 138/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Chatot - 139/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Chatot - 139/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Togekiss V - 140/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Togekiss VMAX - 141/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Tornadus - 142/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Tornadus - 142/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Pikipek - 143/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Pikipek - 143/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Trumbeak - 144/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Trumbeak - 144/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Toucannon - 145/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Toucannon - 145/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Allister - 146/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Allister - 146/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Bea - 147/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Bea - 147/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Beauty - 148/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Beauty - 148/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Cara Liss - 149/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Cara Liss - 149/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Circhester Bath - 150/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Circhester Bath - 150/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Drone Rotom - 151/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Drone Rotom - 151/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Hero's Medal - 152/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Hero's Medal - 152/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - League Staff - 153/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - League Staff - 153/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Leon - 154/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Leon - 154/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Memory Capsule - 155/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Memory Capsule - 155/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Moomoo Cheese - 156/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Moomoo Cheese - 156/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Nessa - 157/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Nessa - 157/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Opal - 158/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Opal - 158/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Rocky Helmet - 159/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Rocky Helmet - 159/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Telescopic Sight - 160/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Telescopic Sight - 160/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Wyndon Stadium - 161/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Wyndon Stadium - 161/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Aromatic Grass Energy - 162/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Aromatic Grass Energy - 162/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Coating Metal Energy - 163/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Coating Metal Energy - 163/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Stone Fighting Energy - 164/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Stone Fighting Energy - 164/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Wash Water Energy - 165/185 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Wash Water Energy - 165/185 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Orbeetle V (Full Art) - 166/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Zarude V (Full Art) - 167/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Talonflame V (Full Art) - 168/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Galarian Darmanitan V (Full Art) - 169/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Pikachu V (Full Art) - 170/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Ampharos V (Full Art) - 171/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Alakazam V (Full Art) - 172/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Coalossal V (Full Art) - 173/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Galarian Sirfetch'd V (Full Art) - 174/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Drapion V (Full Art) - 175/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Steelix V (Full Art) - 176/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Aegislash V (Full Art) - 177/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Togekiss V (Full Art) - 178/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Allister (Full Art) - 179/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Bea (Full Art) - 180/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Beauty (Full Art) - 181/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Leon (Full Art) - 182/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Nessa (Full Art) - 183/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Opal (Full Art) - 184/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Pokemon Center Lady (Full Art) - 185/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Orbeetle VMAX (Secret) - 186/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Galarian Darmanitan VMAX (Secret) - 187/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Pikachu VMAX (Secret) - 188/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Coalossal VMAX (Secret) - 189/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Aegislash VMAX (Secret) - 190/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Togekiss VMAX (Secret) - 191/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Allister (Secret) - 192/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Bea (Secret) - 193/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Beauty (Secret) - 194/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Leon (Secret) - 195/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Nessa (Secret) - 196/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Opal (Secret) - 197/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Galarian Obstagoon (Secret) - 198/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Oranguru (Secret) - 199/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Cape of Toughness (Secret) - 200/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Hero's Medal (Secret) - 201/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Memory Capsule (Secret) - 202/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Vivid Voltage - Telescopic Sight (Secret) - 203/185 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Venusaur V - 001/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Weedle - 002/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Weedle - 002/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Kakuna - 003/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Kakuna - 003/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Beedrill - 004/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Beedrill - 004/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Eldegoss V - 005/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Vulpix - 006/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Vulpix - 006/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Victini - 007/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Victini - 007/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Incineroar V - 008/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Sizzlipede - 009/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Sizzlipede - 009/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Centiskorch - 010/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Centiskorch - 010/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Carvanha - 011/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Carvanha - 011/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Sharpedo - 012/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Sharpedo - 012/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Wailord V - 013/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Drednaw V - 014/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Drednaw VMAX - 015/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Gardevoir V - 016/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Gardevoir VMAX - 017/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Hatenna - 018/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Hatenna - 018/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Hattrem - 019/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Hattrem - 019/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Hatterene - 020/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Hatterene - 020/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Galarian Cursola V - 021/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Alcremie V - 022/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Alcremie VMAX - 023/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Machop - 024/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Machop - 024/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Machoke - 025/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Machoke - 025/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Machamp - 026/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Machamp - 026/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Lucario V - 027/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Zygarde - 028/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Zygarde - 028/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Rockruff - 029/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Rockruff - 029/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Lycanroc - 030/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Lycanroc - 030/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Rolycoly - 031/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Rolycoly - 031/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Grapploct V - 032/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Ekans - 033/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Ekans - 033/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Arbok - 034/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Arbok - 034/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Galarian Zigzagoon - 035/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Galarian Zigzagoon - 035/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Galarian Linoone - 036/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Galarian Linoone - 036/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Galarian Obstagoon - 037/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Galarian Obstagoon - 037/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Absol - 038/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Absol - 038/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Purrloin - 039/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Purrloin - 039/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Liepard - 040/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Liepard - 040/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Scraggy - 041/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Scraggy - 041/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Scrafty - 042/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Scrafty - 042/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Trubbish - 043/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Trubbish - 043/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Inkay - 044/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Inkay - 044/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Malamar - 045/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Malamar - 045/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Nickit - 046/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Nickit - 046/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Duraludon V - 047/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Swablu - 048/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Swablu - 048/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Altaria - 049/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Altaria - 049/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Bede - 050/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Bede - 050/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Full Heal - 051/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Full Heal - 051/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Great Ball - 052/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Great Ball - 052/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Hop - 053/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Hop - 053/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Hyper Potion - 054/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Hyper Potion - 054/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Kabu - 055/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Kabu - 055/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Marnie - 056/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Marnie - 056/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Milo - 057/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Milo - 057/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Piers - 058/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Piers - 058/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Poke Ball - 059/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Poke Ball - 059/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Pokemon Center Lady - 060/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Pokemon Center Lady - 060/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Potion - 061/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Potion - 061/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Professor's Research - 062/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Professor's Research - 062/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Rotom Bike - 063/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Rotom Bike - 063/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Rotom Phone - 064/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Rotom Phone - 064/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Sonia - 065/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Sonia - 065/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Suspicious Food Tin - 066/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Suspicious Food Tin - 066/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Team Yell Grunt - 067/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Team Yell Grunt - 067/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Turffield Stadium - 068/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Turffield Stadium - 068/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Drednaw V (Full Art) - 069/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Gardevoir V (Full Art) - 070/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Galarian Cursola V (Full Art) - 071/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Grapploct V (Full Art) - 072/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Hop (Full Art) - 073/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Charizard VMAX (Secret) - 074/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Drednaw VMAX (Secret) - 075/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Gardevoir VMAX (Secret) - 076/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Kabu (Secret) - 077/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Piers (Secret) - 078/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Charizard V (Secret) - 079/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Champion's Path - Suspicious Food Tin (Secret) - 080/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Butterfree V - 001/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Butterfree VMAX - 002/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Paras - 003/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Paras - 003/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Parasect - 004/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Parasect - 004/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Carnivine - 005/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Carnivine - 005/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Pansage - 006/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Pansage - 006/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Simisage - 007/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Simisage - 007/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Karrablast - 008/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Karrablast - 008/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Shelmet - 009/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Shelmet - 009/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Accelgor - 010/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Accelgor - 010/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Rowlet - 011/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Rowlet - 011/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Dartrix - 012/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Dartrix - 012/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Decidueye - 013/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Decidueye - 013/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Bounsweet - 014/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Bounsweet - 014/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Steenee - 015/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Steenee - 015/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Tsareena - 016/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Tsareena - 016/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Wimpod - 017/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Wimpod - 017/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Golisopod - 018/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Golisopod - 018/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Charizard V - 019/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Charizard VMAX - 020/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Houndoom V - 021/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Torchic - 022/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Torchic - 022/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Combusken - 023/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Combusken - 023/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Blaziken - 024/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Blaziken - 024/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Heatran - 025/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Heatran - 025/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Pansear - 026/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Pansear - 026/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Simisear - 027/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Simisear - 027/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Galarian Darmanitan (28) - 028/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Galarian Darmanitan (28) - 028/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Larvesta - 029/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Larvesta - 029/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Volcarona - 030/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Volcarona - 030/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Fletchinder - 031/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Fletchinder - 031/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Talonflame - 032/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Talonflame - 032/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Centiskorch V - 033/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Centiskorch VMAX - 034/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Galarian Mr. Mime - 035/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Galarian Mr. Mime - 035/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Galarian Mr. Rime - 036/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Galarian Mr. Rime - 036/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Suicune - 037/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Suicune - 037/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Feebas - 038/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Feebas - 038/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Milotic - 039/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Milotic - 039/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Relicanth - 040/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Relicanth - 040/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Panpour - 041/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Panpour - 041/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Simipour - 042/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Simipour - 042/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Galarian Darumaka - 043/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Galarian Darumaka - 043/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Galarian Darmanitan (44) - 044/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Galarian Darmanitan (44) - 044/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Vanillite - 045/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Vanillite - 045/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Vanillish - 046/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Vanillish - 046/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Vanilluxe - 047/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Vanilluxe - 047/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Cubchoo - 048/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Cubchoo - 048/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Beartic - 049/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Beartic - 049/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Wishiwashi - 050/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Wishiwashi - 050/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Mareanie - 051/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Mareanie - 051/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Toxapex - 052/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Toxapex - 052/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Dracovish - 053/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Dracovish - 053/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Arctovish - 054/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Arctovish - 054/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Mareep - 055/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Mareep - 055/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Flaaffy - 056/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Flaaffy - 056/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Ampharos - 057/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Ampharos - 057/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Electrike - 058/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Electrike - 058/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Manectric - 059/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Manectric - 059/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Vikavolt V - 060/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Tapu Koko - 061/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Tapu Koko - 061/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Toxel - 062/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Toxel - 062/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Toxtricity - 063/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Toxtricity - 063/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Pincurchin - 064/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Pincurchin - 064/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Dracozolt - 065/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Dracozolt - 065/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Arctozolt - 066/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Arctozolt - 066/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Jigglypuff - 067/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Jigglypuff - 067/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Wigglytuff - 068/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Wigglytuff - 068/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Mew V - 069/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Snubbull - 070/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Snubbull - 070/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Granbull - 071/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Granbull - 071/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Lunatone - 072/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Lunatone - 072/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Gothita - 073/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Gothita - 073/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Gothorita - 074/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Gothorita - 074/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Gothitelle - 075/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Gothitelle - 075/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Golett - 076/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Golett - 076/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Golurk - 077/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Golurk - 077/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Dedenne - 078/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Dedenne - 078/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Morelull - 079/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Morelull - 079/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Shiinotic - 080/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Shiinotic - 080/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Mimikyu - 081/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Mimikyu - 081/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Sinistea - 082/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Sinistea - 082/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Polteageist - 083/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Polteageist - 083/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Diglett - 084/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Diglett - 084/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Dugtrio - 085/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Dugtrio - 085/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Larvitar - 086/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Larvitar - 086/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Pupitar - 087/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Pupitar - 087/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Tyranitar - 088/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Tyranitar - 088/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Trapinch - 089/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Trapinch - 089/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Vibrava - 090/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Vibrava - 090/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Flygon - 091/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Flygon - 091/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Solrock - 092/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Solrock - 092/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Hippopotas - 093/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Hippopotas - 093/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Hippowdon - 094/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Hippowdon - 094/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Rhyperior V - 095/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Diggersby - 096/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Diggersby - 096/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Passimian - 097/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Passimian - 097/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Galarian Sirfetch'd - 098/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Galarian Sirfetch'd - 098/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Galarian Slowbro V - 099/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Grimer - 100/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Grimer - 100/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Muk - 101/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Muk - 101/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Spinarak - 102/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Spinarak - 102/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Ariados - 103/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Ariados - 103/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Crobat V - 104/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Darkrai - 105/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Darkrai - 105/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Purrloin - 106/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Purrloin - 106/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Liepard - 107/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Liepard - 107/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Deino - 108/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Deino - 108/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Zweilous - 109/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Zweilous - 109/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Hydreigon - 110/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Hydreigon - 110/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Hoopa - 111/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Hoopa - 111/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Nickit - 112/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Nickit - 112/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Thievul - 113/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Thievul - 113/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Grimmsnarl V - 114/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Grimmsnarl VMAX - 115/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Eternatus V - 116/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Eternatus VMAX - 117/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Scizor V - 118/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Scizor VMAX - 119/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Skarmory - 120/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Skarmory - 120/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Aron - 121/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Aron - 121/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Lairon - 122/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Lairon - 122/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Aggron - 123/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Aggron - 123/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Escavalier - 124/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Escavalier - 124/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Klink - 125/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Klink - 125/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Klang - 126/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Klang - 126/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Klinklang - 127/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Klinklang - 127/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Galarian Stunfisk V - 128/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Meltan - 129/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Meltan - 129/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Melmetal - 130/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Melmetal - 130/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Cufant - 131/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Cufant - 131/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Copperajah - 132/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Copperajah - 132/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Kangaskhan - 133/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Kangaskhan - 133/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Tauros - 134/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Tauros - 134/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Sentret - 135/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Sentret - 135/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Furret - 136/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Furret - 136/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Dunsparce - 137/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Dunsparce - 137/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Teddiursa - 138/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Teddiursa - 138/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Ursaring - 139/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Ursaring - 139/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Lugia - 140/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Lugia - 140/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Skitty - 141/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Skitty - 141/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Delcatty - 142/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Delcatty - 142/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Salamence V - 143/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Salamence VMAX - 144/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Starly - 145/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Starly - 145/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Staravia - 146/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Staravia - 146/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Staraptor - 147/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Staraptor - 147/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Ducklett - 148/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Ducklett - 148/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Swanna - 149/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Swanna - 149/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Bunnelby - 150/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Bunnelby - 150/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Fletchling - 151/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Fletchling - 151/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Skwovet - 152/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Skwovet - 152/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Greedent - 153/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Greedent - 153/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Rookidee - 154/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Rookidee - 154/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Corvisquire - 155/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Corvisquire - 155/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Corviknight - 156/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Corviknight - 156/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Big Parasol - 157/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Big Parasol - 157/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Billowing Smoke - 158/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Billowing Smoke - 158/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Bird Keeper - 159/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Bird Keeper - 159/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Cape of Toughness - 160/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Cape of Toughness - 160/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Familiar Bell - 161/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Familiar Bell - 161/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Glimwood Tangle - 162/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Glimwood Tangle - 162/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Kabu - 163/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Kabu - 163/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Old PC - 164/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Old PC - 164/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Piers - 165/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Piers - 165/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Pokemon Breeder's Nurturing - 166/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Pokemon Breeder's Nurturing - 166/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Rare Fossil - 167/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Rare Fossil - 167/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Rose - 168/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Rose - 168/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Rose Tower - 169/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Rose Tower - 169/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Spikemuth - 170/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Spikemuth - 170/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Struggle Gloves - 171/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Struggle Gloves - 171/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Turbo Patch - 172/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Turbo Patch - 172/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Yell Horn - 173/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Yell Horn - 173/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Heat R Energy - 174/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Heat R Energy - 174/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Hiding D Energy - 175/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Hiding D Energy - 175/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Powerful C Energy - 176/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Powerful C Energy - 176/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Butterfree V (Full Art) - 177/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Houndoom V (Full Art) - 178/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Centiskorch V (Full Art) - 179/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Vikavolt V (Full Art) - 180/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Rhyperior V (Full Art) - 181/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Crobat V (Full Art) - 182/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Scizor V (Full Art) - 183/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Galarian Stunfisk V (Full Art) - 184/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Salamence V (Full Art) - 185/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Kabu (Full Art) - 186/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Piers (Full Art) - 187/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Pokemon Breeder's Nurturing (Full Art) - 188/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Rose (Full Art) - 189/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Butterfree VMAX (Secret) - 190/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Centiskorch VMAX (Secret) - 191/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Eternatus VMAX (Secret) - 192/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Scizor VMAX (Secret) - 193/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Salamence VMAX (Secret) - 194/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Pokemon Breeder's Nurturing (Secret) - 195/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Rose (Secret) - 196/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Rillaboom (Secret) - 197/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Coalossal (Secret) - 198/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Big Parasol (Secret) - 199/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Turbo Patch (Secret) - 200/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Darkness Ablaze - Capture Energy (Secret) - 201/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Caterpie - 001/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Caterpie - 001/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Metapod - 002/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Metapod - 002/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Butterfree - 003/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Butterfree - 003/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Butterfree - 003/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Scyther - 004/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Scyther - 004/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Shuckle - 005/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Shuckle - 005/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Heracross - 006/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Heracross - 006/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Lotad - 007/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Lotad - 007/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Lombre - 008/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Lombre - 008/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Ludicolo - 009/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Ludicolo - 009/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Surskit - 010/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Surskit - 010/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Masquerain - 011/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Masquerain - 011/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Snover - 012/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Snover - 012/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Abomasnow - 013/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Abomasnow - 013/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Phantump - 014/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Phantump - 014/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Trevenant - 015/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Trevenant - 015/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Grubbin - 016/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Grubbin - 016/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Rillaboom V - 017/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Rillaboom VMAX - 018/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Eldegoss V - 019/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Applin (20) - 020/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Applin (20) - 020/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Applin (21) - 021/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Applin (21) - 021/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Flapple - 022/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Flapple - 022/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Appletun - 023/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Appletun - 023/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Vulpix - 024/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Vulpix - 024/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Ninetales - 025/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Ninetales - 025/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Ninetales V - 026/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Growlithe - 027/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Growlithe - 027/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Arcanine - 028/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Arcanine - 028/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Magmar - 029/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Magmar - 029/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Magmortar - 030/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Magmortar - 030/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Litwick - 031/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Litwick - 031/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Lampent - 032/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Lampent - 032/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Chandelure - 033/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Chandelure - 033/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Heatmor - 034/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Heatmor - 034/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Cinderace V - 035/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Cinderace VMAX - 036/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Mr. Mime - 037/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Mr. Mime - 037/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Mr. Rime - 038/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Mr. Rime - 038/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Magikarp - 039/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Magikarp - 039/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Gyarados - 040/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Gyarados - 040/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Wingull - 041/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Wingull - 041/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Pelipper - 042/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Pelipper - 042/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Milotic V - 043/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Tympole - 044/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Tympole - 044/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Palpitoad - 045/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Palpitoad - 045/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Seismitoad - 046/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Seismitoad - 046/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Darumaka - 047/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Darumaka - 047/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Darmanitan - 048/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Darmanitan - 048/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Inteleon V - 049/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Inteleon VMAX - 050/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Cramorant - 051/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Cramorant - 051/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Arrokuda - 052/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Arrokuda - 052/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Barraskewda - 053/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Barraskewda - 053/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Eiscue - 054/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Eiscue - 054/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Eiscue V - 055/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Voltorb - 056/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Voltorb - 056/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Electrode - 057/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Electrode - 057/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Electabuzz - 058/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Electabuzz - 058/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Electivire - 059/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Electivire - 059/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Shinx - 060/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Shinx - 060/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Luxio - 061/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Luxio - 061/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Luxray - 062/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Luxray - 062/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Helioptile - 063/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Helioptile - 063/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Heliolisk - 064/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Heliolisk - 064/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Charjabug - 065/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Charjabug - 065/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Vikavolt - 066/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Vikavolt - 066/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Boltund V - 067/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Toxel - 068/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Toxel - 068/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Toxtricity - 069/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Toxtricity - 069/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Toxtricity V - 070/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Toxtricity VMAX - 071/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Pincurchin V - 072/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Morpeko - 073/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Morpeko - 073/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Clefairy - 074/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Clefairy - 074/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Clefable - 075/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Clefable - 075/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Natu - 076/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Natu - 076/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Xatu - 077/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Xatu - 077/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Corsola - 078/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Corsola - 078/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Cursola - 079/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Cursola - 079/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Sigilyph - 080/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Sigilyph - 080/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Sandygast - 081/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Sandygast - 081/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Palossand - 082/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Palossand - 082/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Hatenna - 083/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Hatenna - 083/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Hattrem - 084/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Hattrem - 084/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Hatterene - 085/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Hatterene - 085/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Milcery - 086/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Milcery - 086/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Alcremie - 087/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Alcremie - 087/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Indeedee - 088/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Indeedee - 088/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Dreepy - 089/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Dreepy - 089/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Drakloak - 090/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Drakloak - 090/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Dragapult - 091/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Dragapult - 091/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Dragapult V - 092/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Dragapult VMAX - 093/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Farfetch'd - 094/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Farfetch'd - 094/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Sirfetch'd - 095/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Sirfetch'd - 095/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Nosepass - 096/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Nosepass - 096/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Meditite - 097/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Meditite - 097/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Medicham - 098/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Medicham - 098/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Barboach - 099/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Barboach - 099/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Whiscash - 100/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Whiscash - 100/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Yamask - 101/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Yamask - 101/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Runerigus - 102/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Runerigus - 102/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Binacle - 103/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Binacle - 103/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Barbaracle - 104/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Barbaracle - 104/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Rolycoly - 105/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Rolycoly - 105/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Carkol - 106/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Carkol - 106/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Coalossal - 107/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Coalossal - 107/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Sandaconda V - 108/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Falinks - 109/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Falinks - 109/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Falinks V - 110/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Stonjourner - 111/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Stonjourner - 111/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Koffing - 112/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Koffing - 112/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Weezing - 113/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Weezing - 113/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Stunky - 114/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Stunky - 114/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Skuntank - 115/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Skuntank - 115/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Spiritomb - 116/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Spiritomb - 116/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Trubbish - 117/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Trubbish - 117/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Garbodor - 118/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Garbodor - 118/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Vullaby - 119/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Vullaby - 119/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Mandibuzz - 120/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Mandibuzz - 120/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Malamar V - 121/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Malamar VMAX - 122/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Impidimp - 123/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Impidimp - 123/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Morgrem - 124/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Morgrem - 124/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Grimmsnarl - 125/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Grimmsnarl - 125/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Meowth - 126/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Meowth - 126/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Perrserker - 127/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Perrserker - 127/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Scizor - 128/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Scizor - 128/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Bronzor - 129/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Bronzor - 129/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Bronzong - 130/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Bronzong - 130/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Probopass - 131/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Probopass - 131/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Durant - 132/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Durant - 132/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Honedge - 133/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Honedge - 133/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Doublade - 134/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Doublade - 134/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Aegislash - 135/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Aegislash - 135/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Copperajah V - 136/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Copperajah VMAX - 137/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Duraludon - 138/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Duraludon - 138/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Zacian - 139/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Zacian - 139/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Zamazenta - 140/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Zamazenta - 140/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Snorlax - 141/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Snorlax - 141/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Chatot - 142/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Chatot - 142/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Pidove - 143/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Pidove - 143/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Tranquill - 144/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Tranquill - 144/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Unfezant - 145/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Unfezant - 145/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Bunnelby - 146/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Bunnelby - 146/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Diggersby - 147/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Diggersby - 147/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Hawlucha - 148/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Hawlucha - 148/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Stufful - 149/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Stufful - 149/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Bewear - 150/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Bewear - 150/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Skwovet - 151/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Skwovet - 151/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Greedent - 152/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Greedent - 152/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Dubwool V - 153/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Boss's Orders - 154/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Boss's Orders - 154/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Burning Scarf - 155/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Burning Scarf - 155/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Capacious Bucket - 156/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Capacious Bucket - 156/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Cursed Shovel - 157/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Cursed Shovel - 157/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Dan - 158/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Dan - 158/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Full Heal - 159/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Full Heal - 159/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galar Mine - 160/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galar Mine - 160/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Milo - 161/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Milo - 161/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Nugget - 162/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Nugget - 162/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Oleana - 163/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Oleana - 163/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Poke Ball - 164/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Poke Ball - 164/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Scoop Up Net - 165/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Scoop Up Net - 165/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Skyla - 166/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Skyla - 166/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Sonia - 167/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Sonia - 167/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Tool Scrapper - 168/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Tool Scrapper - 168/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Training Court - 169/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Training Court - 169/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Turffield Stadium - 170/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Turffield Stadium - 170/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Capture Energy - 171/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Capture Energy - 171/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Horror P Energy - 172/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Horror P Energy - 172/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Speed L Energy - 173/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Speed L Energy - 173/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Twin Energy - 174/192 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Twin Energy - 174/192 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Rillaboom V (Full Art) - 175/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Eldegoss V (Full Art) - 176/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Ninetales V (Full Art) - 177/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Cinderace V (Full Art) - 178/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Milotic V (Full Art) - 179/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Inteleon V (Full Art) - 180/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Boltund V (Full Art) - 181/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Toxtricity V (Full Art) - 182/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Dragapult V (Full Art) - 183/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Sandaconda V (Full Art) - 184/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Falinks V (Full Art) - 185/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Malamar V (Full Art) - 186/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Copperajah V (Full Art) - 187/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Dubwool V (Full Art) - 188/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Boss's Orders (Full Art) - 189/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Milo (Full Art) - 190/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Oleana (Full Art) - 191/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Sonia (Full Art) - 192/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Rillaboom VMAX (Secret) - 193/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Cinderace VMAX (Secret) - 194/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Inteleon VMAX (Secret) - 195/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Toxtricity VMAX (Secret) - 196/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Dragapult VMAX (Secret) - 197/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Malamar VMAX (Secret) - 198/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Copperajah VMAX (Secret) - 199/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Boss's Orders (Secret) - 200/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Milo (Secret) - 201/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Oleana (Secret) - 202/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Sonia (Secret) - 203/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Frosmoth (Secret) - 204/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Galarian Perrserker (Secret) - 205/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Big Charm (Secret) - 206/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Scoop Up Net (Secret) - 207/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Tool Scrapper (Secret) - 208/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rebel Clash - Twin Energy (Secret) - 209/192 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Celebi V - 001/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Roselia (002) - 002/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Roselia (002) - 002/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Roselia (003) - 003/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Roselia (003) - 003/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Roserade - 004/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Roserade - 004/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Cottonee - 005/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Cottonee - 005/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Whimsicott - 006/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Whimsicott - 006/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Maractus - 007/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Maractus - 007/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Durant - 008/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Durant - 008/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Dhelmise V - 009/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Grookey (010) - 010/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Grookey (010) - 010/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Grookey (011) - 011/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Grookey (011) - 011/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Thwackey (012) - 012/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Thwackey (012) - 012/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Thwackey (013) - 013/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Thwackey (013) - 013/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Rillaboom (014) - 014/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Rillaboom (014) - 014/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Rillaboom (015) - 015/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Rillaboom (015) - 015/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Blipbug (016) - 016/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Blipbug (016) - 016/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Blipbug (017) - 017/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Blipbug (017) - 017/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Dottler - 018/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Dottler - 018/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Orbeetle - 019/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Orbeetle - 019/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Gossifleur - 020/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Gossifleur - 020/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Eldegoss - 021/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Eldegoss - 021/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Vulpix - 022/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Vulpix - 022/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Ninetales - 023/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Ninetales - 023/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Torkoal V - 024/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Victini V - 025/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Heatmor - 026/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Heatmor - 026/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Salandit - 027/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Salandit - 027/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Salazzle - 028/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Salazzle - 028/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Turtonator - 029/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Turtonator - 029/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Scorbunny (030) - 030/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Scorbunny (030) - 030/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Scorbunny (031) - 031/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Scorbunny (031) - 031/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Raboot (032) - 032/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Raboot (032) - 032/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Raboot (033) - 033/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Raboot (033) - 033/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Cinderace (034) - 034/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Cinderace (034) - 034/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Cinderace (035) - 035/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Cinderace (035) - 035/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Cinderace (036) - 036/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Cinderace (036) - 036/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sizzlipede (037) - 037/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sizzlipede (037) - 037/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sizzlipede (038) - 038/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sizzlipede (038) - 038/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Centiskorch - 039/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Centiskorch - 039/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Shellder - 040/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Shellder - 040/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Cloyster - 041/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Cloyster - 041/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Krabby (042) - 042/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Krabby (042) - 042/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Krabby (043) - 043/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Krabby (043) - 043/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Kingler - 044/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Kingler - 044/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Goldeen (045) - 045/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Goldeen (045) - 045/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Goldeen (046) - 046/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Goldeen (046) - 046/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Seaking - 047/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Seaking - 047/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Lapras - 048/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Lapras - 048/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Lapras V - 049/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Lapras VMAX - 050/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Qwilfish - 051/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Qwilfish - 051/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Mantine - 052/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Mantine - 052/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Keldeo V - 053/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sobble (054) - 054/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sobble (054) - 054/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sobble (055) - 055/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sobble (055) - 055/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Drizzile (056) - 056/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Drizzile (056) - 056/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Drizzile (057) - 057/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Drizzile (057) - 057/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Inteleon (058) - 058/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Inteleon (058) - 058/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Inteleon (059) - 059/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Inteleon (059) - 059/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Chewtle - 060/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Chewtle - 060/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Drednaw - 061/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Drednaw - 061/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Cramorant - 062/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Cramorant - 062/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Snom - 063/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Snom - 063/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Frosmoth - 064/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Frosmoth - 064/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Pikachu - 065/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Pikachu - 065/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Raichu - 066/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Raichu - 066/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Chinchou (067) - 067/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Chinchou (067) - 067/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Chinchou (068) - 068/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Chinchou (068) - 068/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Lanturn - 069/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Lanturn - 069/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Joltik - 070/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Joltik - 070/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Galvantula - 071/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Galvantula - 071/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Tapu Koko V - 072/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Yamper (073) - 073/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Yamper (073) - 073/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Yamper (074) - 074/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Yamper (074) - 074/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Boltund (075) - 075/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Boltund (075) - 075/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Boltund (076) - 076/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Boltund (076) - 076/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Pincurchin - 077/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Pincurchin - 077/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Morpeko - 078/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Morpeko - 078/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Morpeko V - 079/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Morpeko VMAX - 080/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Galarian Ponyta - 081/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Galarian Ponyta - 081/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Galarian Rapidash - 082/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Galarian Rapidash - 082/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Gastly - 083/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Gastly - 083/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Haunter - 084/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Haunter - 084/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Gengar - 085/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Gengar - 085/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Wobbuffet V - 086/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Munna - 087/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Munna - 087/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Musharna - 088/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Musharna - 088/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sinistea - 089/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sinistea - 089/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Polteageist - 090/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Polteageist - 090/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Indeedee V - 091/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Diglett - 092/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Diglett - 092/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Dugtrio - 093/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Dugtrio - 093/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Hitmonlee - 094/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Hitmonlee - 094/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Hitmonchan - 095/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Hitmonchan - 095/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Rhyhorn (096) - 096/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Rhyhorn (096) - 096/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Rhyhorn (097) - 097/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Rhyhorn (097) - 097/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Rhydon - 098/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Rhydon - 098/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Rhyperior - 099/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Rhyperior - 099/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sudowoodo - 100/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sudowoodo - 100/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Baltoy (101) - 101/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Baltoy (101) - 101/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Baltoy (102) - 102/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Baltoy (102) - 102/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Claydol - 103/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Claydol - 103/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Regirock V - 104/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Mudbray - 105/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Mudbray - 105/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Mudsdale - 106/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Mudsdale - 106/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Silicobra (107) - 107/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Silicobra (107) - 107/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Silicobra (108) - 108/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Silicobra (108) - 108/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sandaconda (109) - 109/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sandaconda (109) - 109/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sandaconda (110) - 110/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sandaconda (110) - 110/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Clobbopus (111) - 111/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Clobbopus (111) - 111/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Clobbopus (112) - 112/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Clobbopus (112) - 112/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Grapploct - 113/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Grapploct - 113/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Stonjourner - 114/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Stonjourner - 114/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Stonjourner V - 115/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Stonjourner VMAX - 116/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Galarian Zigzagoon - 117/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Galarian Zigzagoon - 117/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Galarian Linoone - 118/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Galarian Linoone - 118/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Galarian Obstagoon - 119/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Galarian Obstagoon - 119/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sableye V - 120/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Skorupi - 121/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Skorupi - 121/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Drapion - 122/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Drapion - 122/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Croagunk - 123/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Croagunk - 123/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Toxicroak - 124/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Toxicroak - 124/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Nickit - 125/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Nickit - 125/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Thievul - 126/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Thievul - 126/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Galarian Meowth - 127/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Galarian Meowth - 127/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Galarian Perrserker - 128/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Galarian Perrserker - 128/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Mawile - 129/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Mawile - 129/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Ferroseed - 130/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Ferroseed - 130/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Ferrothorn - 131/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Ferrothorn - 131/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Galarian Stunfisk - 132/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Galarian Stunfisk - 132/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Pawniard - 133/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Pawniard - 133/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Bisharp - 134/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Bisharp - 134/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Corviknight - 135/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Corviknight - 135/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Cufant - 136/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Cufant - 136/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Copperajah - 137/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Copperajah - 137/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Zacian V - 138/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Zamazenta V - 139/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Snorlax - 140/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Snorlax - 140/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Snorlax V - 141/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Snorlax VMAX - 142/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Hoothoot - 143/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Hoothoot - 143/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Noctowl - 144/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Noctowl - 144/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Minccino (145) - 145/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Minccino (145) - 145/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Minccino (146) - 146/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Minccino (146) - 146/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Cinccino - 147/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Cinccino - 147/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Oranguru - 148/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Oranguru - 148/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Drampa - 149/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Drampa - 149/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Rookidee - 150/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Rookidee - 150/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Corvisquire - 151/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Corvisquire - 151/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Wooloo (152) - 152/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Wooloo (152) - 152/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Wooloo (153) - 153/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Wooloo (153) - 153/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Dubwool - 154/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Dubwool - 154/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Cramorant V - 155/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Air Balloon - 156/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Air Balloon - 156/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Bede - 157/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Bede - 157/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Big Charm - 158/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Big Charm - 158/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Crushing Hammer - 159/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Crushing Hammer - 159/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Energy Retrieval - 160/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Energy Retrieval - 160/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Energy Search - 161/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Energy Search - 161/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Energy Switch - 162/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Energy Switch - 162/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Evolution Incense - 163/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Evolution Incense - 163/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Great Ball - 164/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Great Ball - 164/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Hop - 165/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Hop - 165/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Hyper Potion - 166/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Hyper Potion - 166/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Lucky Egg - 167/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Lucky Egg - 167/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Lum Berry - 168/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Lum Berry - 168/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Marnie - 169/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Marnie - 169/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Metal Saucer - 170/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Metal Saucer - 170/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Ordinary Rod - 171/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Ordinary Rod - 171/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Pal Pad - 172/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Pal Pad - 172/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Poke Kid - 173/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Poke Kid - 173/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Pokegear 3.0 - 174/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Pokegear 3.0 - 174/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Pokemon Catcher - 175/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Pokemon Catcher - 175/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Pokemon Center Lady - 176/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Pokemon Center Lady - 176/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Potion - 177/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Potion - 177/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Professor's Research - 178/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Professor's Research - 178/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Quick Ball - 179/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Quick Ball - 179/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Rare Candy - 180/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Rare Candy - 180/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Rotom Bike - 181/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Rotom Bike - 181/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sitrus Berry - 182/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sitrus Berry - 182/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Switch - 183/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Switch - 183/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Team Yell Grunt - 184/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Team Yell Grunt - 184/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Vitality Band - 185/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Vitality Band - 185/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Aurora Energy - 186/202 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Aurora Energy - 186/202 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Dhelmise V (Full Art) - 187/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Torkoal V (Full Art) - 188/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Lapras V (Full Art) - 189/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Morpeko V (Full Art) - 190/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Wobbuffet V (Full Art) - 191/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Indeedee V (Full Art) - 192/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Stonjourner V (Full Art) - 193/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Sableye V (Full Art) - 194/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Zacian V (Full Art) - 195/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Zamazenta V (Full Art) - 196/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Snorlax V (Full Art) - 197/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Cramorant V (Full Art) - 198/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Bede (Full Art) - 199/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Marnie (Full Art) - 200/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Professor's Research (Full Art) - 201/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Team Yell Grunt (Full Art) - 202/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Lapras VMAX (Secret) - 203/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Morpeko VMAX (Secret) - 204/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Stonjourner VMAX (Secret) - 205/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Snorlax VMAX (Secret) - 206/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Bede (Secret) - 207/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Marnie (Secret) - 208/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Professor's Research (Secret) - 209/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Team Yell Grunt (Secret) - 210/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Zacian V (Secret) - 211/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Zamazenta V (Secret) - 212/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Air Balloon (Secret) - 213/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Metal Saucer (Secret) - 214/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Ordinary Rod (Secret) - 215/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword and Shield - Quick Ball (Secret) - 216/202 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Venusaur & Snivy GX - 001/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Oddish - 002/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Oddish - 002/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Gloom - 003/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Gloom - 003/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Vileplume GX - 004/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Tangela - 005/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Tangela - 005/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Tangrowth - 006/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Tangrowth - 006/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Sunkern - 007/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Sunkern - 007/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Sunflora - 008/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Sunflora - 008/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Heracross - 009/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Heracross - 009/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lileep - 010/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lileep - 010/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Cradily - 011/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Cradily - 011/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Tropius - 012/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Tropius - 012/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Kricketot - 013/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Kricketot - 013/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Kricketune - 014/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Kricketune - 014/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Deerling - 015/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Deerling - 015/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Sawsbuck - 016/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Sawsbuck - 016/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Rowlet - 17/236 - 017/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Rowlet - 17/236 - 017/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Rowlet - 18/236 - 018/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Rowlet - 18/236 - 018/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Dartrix - 019/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Dartrix - 019/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Decidueye - 020/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Decidueye - 020/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Buzzwole - 021/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Buzzwole - 021/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Charizard & Braixen GX - 022/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Ponyta - 023/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Ponyta - 023/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Rapidash - 024/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Rapidash - 024/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Flareon - 025/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Flareon - 025/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Slugma - 026/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Slugma - 026/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Magcargo - 027/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Magcargo - 027/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Entei - 028/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Entei - 028/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Torkoal - 029/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Torkoal - 029/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Victini - 030/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Victini - 030/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Tepig - 031/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Tepig - 031/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Pignite - 032/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Pignite - 032/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Emboar - 033/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Emboar - 033/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Larvesta - 034/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Larvesta - 034/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Volcarona GX - 035/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Litleo - 036/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Litleo - 036/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Pyroar - 037/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Pyroar - 037/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Blastoise & Piplup GX - 038/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Alolan Vulpix - 039/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Alolan Vulpix - 039/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Psyduck - 040/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Psyduck - 040/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Golduck - 041/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Golduck - 041/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Vaporeon - 042/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Vaporeon - 042/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Sneasel - 043/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Sneasel - 043/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Weavile - 044/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Weavile - 044/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Wailmer - 045/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Wailmer - 045/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Wailord - 046/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Wailord - 046/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Snorunt - 047/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Snorunt - 047/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Glalie - 048/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Glalie - 048/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Spheal - 49/236 - 049/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Spheal - 49/236 - 049/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Spheal - 50/236 - 050/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Spheal - 50/236 - 050/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Sealeo - 051/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Sealeo - 051/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Walrein - 052/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Walrein - 052/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Kyogre - 053/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Kyogre - 053/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Piplup - 054/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Piplup - 054/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Prinplup - 055/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Prinplup - 055/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Empoleon - 056/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Empoleon - 056/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Phione - 057/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Phione - 057/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Tympole - 058/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Tympole - 058/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Ducklett - 059/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Ducklett - 059/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Swanna - 060/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Swanna - 060/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Black Kyurem - 061/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Black Kyurem - 061/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Wishiwashi - 062/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Wishiwashi - 062/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Wishiwashi GX - 063/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Dewpider - 064/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Dewpider - 064/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Araquanid - 065/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Araquanid - 065/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Pikachu - 066/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Pikachu - 066/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Raichu - 067/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Raichu - 067/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Magnemite - 068/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Magnemite - 068/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Magneton - 069/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Magneton - 069/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Jolteon - 070/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Jolteon - 070/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Chinchou - 071/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Chinchou - 071/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lanturn - 072/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lanturn - 072/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Togedemaru - 73/236 - 073/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Togedemaru - 73/236 - 073/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Togedemaru - 74/236 - 074/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Togedemaru - 74/236 - 074/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Solgaleo & Lunala GX - 075/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Koffing - 076/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Koffing - 076/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Weezing - 077/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Weezing - 077/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Natu - 078/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Natu - 078/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Xatu - 079/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Xatu - 079/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Ralts - 080/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Ralts - 080/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Kirlia - 081/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Kirlia - 081/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Gallade - 082/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Gallade - 082/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Duskull - 083/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Duskull - 083/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Dusclops - 084/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Dusclops - 084/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Dusknoir - 085/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Dusknoir - 085/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Rotom - 086/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Rotom - 086/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Woobat - 087/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Woobat - 087/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Swoobat - 088/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Swoobat - 088/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Golett - 089/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Golett - 089/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Golurk - 090/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Golurk - 090/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Skrelp - 091/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Skrelp - 091/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Dragalge - 092/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Dragalge - 092/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Phantump - 093/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Phantump - 093/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Trevenant - 094/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Trevenant - 094/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Oricorio GX - 095/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Mimikyu (96) - 096/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Mimikyu (96) - 096/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Mimikyu (97) - 097/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Mimikyu (97) - 097/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Dhelmise - 098/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Dhelmise - 098/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Dhelmise - 099/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Dhelmise - 099/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Cosmog - 100/236 - 100/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Cosmog - 100/236 - 100/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Cosmoem - 101/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Cosmoem - 101/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lunala - 102/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lunala - 102/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Marshadow - 103/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Marshadow - 103/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Blacephalon - 104/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Blacephalon - 104/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Onix - 105/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Onix - 105/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Nosepass - 106/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Nosepass - 106/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Trapinch - 107/236 - 107/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Trapinch - 107/236 - 107/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Trapinch - 108/236 - 108/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Trapinch - 108/236 - 108/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Vibrava - 109/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Vibrava - 109/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Flygon GX - 110/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Anorith - 111/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Anorith - 111/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Armaldo - 112/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Armaldo - 112/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Groudon - 113/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Groudon - 113/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Drilbur - 114/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Drilbur - 114/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Excadrill - 115/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Excadrill - 115/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Palpitoad - 116/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Palpitoad - 116/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Seismitoad - 117/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Seismitoad - 117/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Throh - 118/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Throh - 118/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Pancham - 119/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Pancham - 119/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Pangoro - 120/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Pangoro - 120/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Crabrawler - 121/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Crabrawler - 121/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Crabominable - 122/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Crabominable - 122/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Rockruff - 123/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Rockruff - 123/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lycanroc - 124/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lycanroc - 124/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Passimian - 125/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Passimian - 125/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Sandygast - 126/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Sandygast - 126/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Palossand - 127/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Palossand - 127/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Alolan Meowth - 128/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Alolan Meowth - 128/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Alolan Persian GX - 129/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Alolan Grimer - 130/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Alolan Grimer - 130/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Alolan Muk - 131/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Alolan Muk - 131/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Carvanha - 132/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Carvanha - 132/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Absol - 133/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Absol - 133/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Pawniard - 134/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Pawniard - 134/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Bisharp - 135/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Bisharp - 135/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Guzzlord - 136/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Guzzlord - 136/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Alolan Sandshrew - 137/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Alolan Sandshrew - 137/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Alolan Sandslash - 138/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Alolan Sandslash - 138/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Steelix - 139/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Steelix - 139/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Mawile - 140/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Mawile - 140/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Probopass - 141/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Probopass - 141/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Solgaleo - 142/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Solgaleo - 142/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Togepi & Cleffa & Igglybuff GX - 143/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Clefairy - 144/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Clefairy - 144/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Alolan Ninetales - 145/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Alolan Ninetales - 145/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Azurill - 146/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Azurill - 146/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Cottonee - 147/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Cottonee - 147/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Whimsicott - 148/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Whimsicott - 148/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Flabebe - 149/236 - 149/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Flabebe - 149/236 - 149/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Flabebe - 150/236 - 150/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Flabebe - 150/236 - 150/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Floette - 151/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Floette - 151/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Florges - 152/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Florges - 152/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Swirlix - 153/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Swirlix - 153/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Slurpuff - 154/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Slurpuff - 154/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Sylveon - 155/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Sylveon - 155/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Arceus & Dialga & Palkia GX - 156/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Reshiram & Zekrom GX - 157/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Naganadel & Guzzlord GX - 158/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Drampa - 159/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Drampa - 159/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Jangmo-o - 160/236 - 160/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Jangmo-o - 160/236 - 160/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Jangmo-o - 161/236 - 161/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Jangmo-o - 161/236 - 161/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Hakamo-o - 162/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Hakamo-o - 162/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Kommo-o - 163/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Kommo-o - 163/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Ultra Necrozma - 164/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Ultra Necrozma - 164/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Mega Lopunny & Jigglypuff GX - 165/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Eevee - 166/236 - 166/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Eevee - 166/236 - 166/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Eevee - 167/236 - 167/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Eevee - 167/236 - 167/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Igglybuff - 168/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Igglybuff - 168/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Aipom - 169/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Aipom - 169/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Ambipom - 170/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Ambipom - 170/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Teddiursa - 171/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Teddiursa - 171/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Ursaring - 172/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Ursaring - 172/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Zangoose - 173/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Zangoose - 173/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lillipup - 174/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lillipup - 174/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Herdier - 175/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Herdier - 175/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Stoutland - 176/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Stoutland - 176/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Rufflet - 177/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Rufflet - 177/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Braviary - 178/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Braviary - 178/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Helioptile - 179/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Helioptile - 179/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Heliolisk - 180/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Heliolisk - 180/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Stufful - 181/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Stufful - 181/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Bewear - 182/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Bewear - 182/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Type: Null - 183/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Type: Null - 183/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Silvally GX - 184/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Beastite - 185/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Beastite - 185/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Bellelba & Brycen-Man - 186/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Bellelba & Brycen-Man - 186/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Chaotic Swell - 187/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Chaotic Swell - 187/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Clay - 188/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Clay - 188/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Cynthia & Caitlin - 189/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Cynthia & Caitlin - 189/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Dragonium Z: Dragon Claw - 190/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Dragonium Z: Dragon Claw - 190/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Erika - 191/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Erika - 191/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Great Catcher - 192/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Great Catcher - 192/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Guzma & Hala - 193/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Guzma & Hala - 193/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Island Challenge Amulet - 194/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Island Challenge Amulet - 194/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lana's Fishing Rod - 195/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lana's Fishing Rod - 195/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lillie's Full Force - 196/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lillie's Full Force - 196/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Roxie - 197/173 (JP Tag All Stars) - 197/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lillie's Poke Doll - 197/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lillie's Poke Doll - 197/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Mallow & Lana - 198/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Mallow & Lana - 198/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Misty & Lorelei - 199/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Misty & Lorelei - 199/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - N's Resolve - 200/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - N's Resolve - 200/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Professor Oak's Setup - 201/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Professor Oak's Setup - 201/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Red & Blue - 202/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Red & Blue - 202/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Roller Skater - 203/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Roller Skater - 203/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Rosa - 204/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Rosa - 204/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Roxie - 205/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Roxie - 205/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Tag Call - 206/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Tag Call - 206/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Unidentified Fossil - 207/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Unidentified Fossil - 207/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Will - 208/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Will - 208/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Draw Energy - 209/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Draw Energy - 209/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Venusaur & Snivy GX (Full Art) - 210/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Vileplume GX (Full Art) - 211/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Charizard & Braixen GX (Full Art) - 212/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Volcarona GX (Full Art) - 213/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Blastoise & Piplup GX (Full Art) - 214/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Blastoise & Piplup GX (Alternate Art) - 215/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Solgaleo & Lunala GX (Full Art) - 216/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Oricorio GX (Full Art) - 217/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Flygon GX (Full Art) - 218/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Alolan Persian GX (Full Art) - 219/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Arceus & Dialga & Palkia GX (Full Art) - 220/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Arceus & Dialga & Palkia GX (Alternate Art) - 221/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Reshiram & Zekrom GX (Full Art) - 222/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Naganadel & Guzzlord GX (Full Art) - 223/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Naganadel & Guzzlord GX (Alternate Art) - 224/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Mega Lopunny & Jigglypuff GX (Full Art) - 225/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Mega Lopunny & Jigglypuff GX (Alternate Art) - 226/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Silvally GX (Full Art) - 227/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Cynthia & Caitlin (Full Art) - 228/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Guzma & Hala (Full Art) - 229/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lillie's Full Force (Full Art) - 230/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Mallow & Lana (Full Art) - 231/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - N's Resolve (Full Art) - 232/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Professor Oak's Setup (Full Art) - 233/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Red & Blue (Full Art) - 234/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Roller Skater (Full Art) - 235/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Rosa (Full Art) - 236/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Torkoal (Secret) - 237/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Weavile (Secret) - 238/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Piplup (Secret) - 239/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Wishiwashi (Secret) - 240/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Pikachu (Secret) - 241/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Magnemite (Secret) - 242/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Koffing (Secret) - 243/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Gallade (Secret) - 244/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Mimikyu (Secret) - 245/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Excadrill (Secret) - 246/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Steelix (Secret) - 247/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Stoutland (Secret) - 248/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Venusaur & Snivy GX (Secret) - 249/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Vileplume GX (Secret) - 250/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Charizard & Braixen GX (Secret) - 251/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Volcarona GX (Secret) - 252/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Blastoise & Piplup GX (Secret) - 253/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Solgaleo & Lunala GX (Secret) - 254/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Oricorio GX (Secret) - 255/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Flygon GX (Secret) - 256/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Alolan Persian GX (Secret) - 257/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Arceus & Dialga & Palkia GX (Secret) - 258/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Reshiram & Zekrom GX (Secret) - 259/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Naganadel & Guzzlord GX (Secret) - 260/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Mega Lopunny & Jigglypuff GX (Secret) - 261/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Silvally GX (Secret) - 262/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Giant Hearth (Secret) - 263/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Great Catcher (Secret) - 264/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Island Challenge Amulet (Secret) - 265/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lana's Fishing Rod (Secret) - 266/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Lillie's Poke Doll (Secret) - 267/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Martial Arts Dojo (Secret) - 268/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Power Plant (Secret) - 269/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Tag Call (Secret) - 270/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Cosmic Eclipse - Draw Energy (Secret) - 271/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Caterpie - 001/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Caterpie - 001/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Metapod - 002/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Metapod - 002/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Butterfree - 003/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Butterfree - 003/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Paras - 004/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Paras - 004/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Scyther - 005/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Scyther - 005/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Pinsir GX - 006/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Charmander - 007/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Charmander - 007/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Charmeleon - 008/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Charmeleon - 008/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Charizard GX - 009/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Magmar - 010/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Magmar - 010/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Psyduck - 011/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Psyduck - 011/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Slowpoke - 012/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Slowpoke - 012/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Staryu - 013/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Staryu - 013/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Starmie GX - 014/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Magikarp - 015/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Magikarp - 015/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Gyarados GX - 016/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Lapras - 017/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Lapras - 017/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Vaporeon - 018/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Vaporeon - 018/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Pikachu - 019/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Pikachu - 019/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Raichu GX - 020/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Voltorb - 021/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Voltorb - 021/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Electrode - 022/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Electrode - 022/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Jolteon - 023/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Jolteon - 023/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Zapdos - 024/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Zapdos - 024/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Ekans (25) - 025/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Ekans (25) - 025/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Ekans (26) - 026/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Ekans (26) - 026/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Arbok - 027/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Arbok - 027/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Koffing - 028/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Koffing - 028/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Weezing - 029/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Weezing - 029/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Jynx - 030/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Jynx - 030/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Mewtwo GX - 031/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Mew - 032/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Mew - 032/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Geodude - 033/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Geodude - 033/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Graveler - 034/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Graveler - 034/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Golem - 035/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Golem - 035/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Onix GX - 036/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Cubone - 037/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Cubone - 037/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Clefairy (38) - 038/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Clefairy (38) - 038/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Clefairy (39) - 039/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Clefairy (39) - 039/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Clefable - 040/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Clefable - 040/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Jigglypuff - 041/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Jigglypuff - 041/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Wigglytuff GX - 042/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Mr. Mime - 043/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Mr. Mime - 043/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Moltres & Zapdos & Articuno GX - 044/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Farfetch'd - 045/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Farfetch'd - 045/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Chansey - 046/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Chansey - 046/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Kangaskhan - 047/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Kangaskhan - 047/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Eevee (48) - 048/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Eevee (48) - 048/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Eevee (49) - 049/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Eevee (49) - 049/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Snorlax - 050/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Snorlax - 050/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Bill's Analysis - 051/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Bill's Analysis - 051/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Blaine's Last Stand - 052/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Blaine's Last Stand - 052/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Brock's Grit - 053/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Brock's Grit - 053/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Brock's Pewter City Gym - 054/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Brock's Pewter City Gym - 054/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Brock's Training - 055/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Brock's Training - 055/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Erika's Hospitality - 056/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Erika's Hospitality - 056/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Giovanni's Exile - 057/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Giovanni's Exile - 057/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Jessie & James - 058/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Jessie & James - 058/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Koga's Trap - 059/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Koga's Trap - 059/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Lt. Surge's Strategy - 060/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Lt. Surge's Strategy - 060/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Misty's Cerulean City Gym - 061/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Misty's Cerulean City Gym - 061/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Misty's Determination - 062/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Misty's Determination - 062/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Misty's Water Command - 063/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Misty's Water Command - 063/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Pokemon Center Lady - 064/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Pokemon Center Lady - 064/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Sabrina's Suggestion - 065/68 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Sabrina's Suggestion - 065/68 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Moltres & Zapdos & Articuno GX (Full Art) - 066/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Giovanni's Exile (Full Art) - 067/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Jessie & James (Full Art) - 068/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Fates - Moltres & Zapdos & Articuno GX (Secret) - 069/68 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Rowlet & Alolan Exeggutor GX - 001/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Yanma - 002/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Yanma - 002/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Yanmega - 003/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Yanmega - 003/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Celebi - 004/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Celebi - 004/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Shroomish - 005/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Shroomish - 005/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Sewaddle - 006/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Sewaddle - 006/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Sewaddle - 007/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Sewaddle - 007/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Swadloon - 008/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Swadloon - 008/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Leavanny - 009/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Leavanny - 009/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dwebble - 010/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dwebble - 010/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Crustle - 011/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Crustle - 011/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Karrablast - 012/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Karrablast - 012/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Foongus - 013/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Foongus - 013/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Amoonguss - 014/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Amoonguss - 014/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Fomantis - 015/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Fomantis - 015/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Lurantis - 016/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Lurantis - 016/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Bounsweet - 017/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Bounsweet - 017/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Steenee - 018/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Steenee - 018/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tsareena - 019/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tsareena - 019/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dhelmise - 020/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dhelmise - 020/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Magmar - 021/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Magmar - 021/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Magmortar - 022/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Magmortar - 022/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Numel - 023/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Numel - 023/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Camerupt - 024/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Camerupt - 024/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Heatran GX - 025/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Victini - 026/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Victini - 026/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Litwick (27) - 027/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Litwick (27) - 027/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Litwick (28) - 028/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Litwick (28) - 028/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Lampent - 029/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Lampent - 029/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Chandelure - 030/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Chandelure - 030/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Fletchinder - 031/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Fletchinder - 031/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Talonflame - 032/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Talonflame - 032/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Salandit (33) - 033/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Salandit (33) - 033/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Salazzle (34) - 034/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Salazzle (34) - 034/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Slowpoke & Psyduck GX - 035/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Lapras - 036/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Lapras - 036/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Snorunt - 037/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Snorunt - 037/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Froslass - 038/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Froslass - 038/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Finneon - 039/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Finneon - 039/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Lumineon - 040/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Lumineon - 040/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Snover - 041/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Snover - 041/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Abomasnow - 042/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Abomasnow - 042/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Basculin - 043/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Basculin - 043/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tirtouga - 044/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tirtouga - 044/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Carracosta - 045/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Carracosta - 045/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Cryogonal - 046/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Cryogonal - 046/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Keldeo GX - 047/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dewpider - 048/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dewpider - 048/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Araquanid - 049/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Araquanid - 049/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Wimpod - 050/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Wimpod - 050/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Golisopod - 051/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Golisopod - 051/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Pyukumuku - 052/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Pyukumuku - 052/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tapu Fini - 053/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tapu Fini - 053/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Raichu & Alolan Raichu GX - 054/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Pikachu (55) - 055/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Pikachu (55) - 055/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Pikachu (56) - 056/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Pikachu (56) - 056/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Alolan Raichu - 057/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Alolan Raichu - 057/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Magnemite - 058/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Magnemite - 058/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Magneton - 059/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Magneton - 059/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Magnezone - 060/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Magnezone - 060/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Joltik - 061/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Joltik - 061/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Galvantula - 062/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Galvantula - 062/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tynamo (63) - 063/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tynamo (63) - 063/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tynamo (64) - 064/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tynamo (64) - 064/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Eelektrik - 065/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Eelektrik - 065/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Eelektross - 066/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Eelektross - 066/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Stunfisk - 067/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Stunfisk - 067/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Thundurus - 068/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Thundurus - 068/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tapu Koko - 069/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tapu Koko - 069/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Xurkitree - 070/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Xurkitree - 070/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Mewtwo & Mew GX - 071/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Espeon & Deoxys GX - 072/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Exeggcute - 073/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Exeggcute - 073/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Exeggutor - 074/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Exeggutor - 074/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Alolan Marowak - 075/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Alolan Marowak - 075/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Jynx - 076/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Jynx - 076/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Wynaut - 077/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Wynaut - 077/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Latios GX - 078/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Jirachi GX - 079/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Drifloon - 080/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Drifloon - 080/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Drifblim - 081/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Drifblim - 081/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Skorupi - 082/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Skorupi - 082/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Uxie - 083/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Uxie - 083/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Mesprit - 084/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Mesprit - 084/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Azelf - 085/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Azelf - 085/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Giratina - 086/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Giratina - 086/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Cresselia - 087/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Cresselia - 087/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Munna - 088/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Munna - 088/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Musharna - 089/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Musharna - 089/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Elgyem - 090/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Elgyem - 090/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Beheeyem - 091/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Beheeyem - 091/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Honedge (92) - 092/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Honedge (92) - 092/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Honedge (93) - 093/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Honedge (93) - 093/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Doublade - 094/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Doublade - 094/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Aegislash - 095/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Aegislash - 095/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Mareanie - 096/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Mareanie - 096/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Toxapex - 097/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Toxapex - 097/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Salandit (98) - 098/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Salandit (98) - 098/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Salazzle (99) - 099/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Salazzle (99) - 099/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Cosmog - 100/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Cosmog - 100/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Necrozma - 101/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Necrozma - 101/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Poipole - 102/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Poipole - 102/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Onix - 103/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Onix - 103/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Steelix - 104/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Steelix - 104/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Cubone - 105/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Cubone - 105/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Aerodactyl GX - 106/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Heracross - 107/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Heracross - 107/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Breloom - 108/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Breloom - 108/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Meditite - 109/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Meditite - 109/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Medicham - 110/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Medicham - 110/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Relicanth - 111/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Relicanth - 111/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Gible - 112/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Gible - 112/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Gabite - 113/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Gabite - 113/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Garchomp - 114/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Garchomp - 114/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Riolu (115) - 115/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Riolu (115) - 115/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Riolu (116) - 116/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Riolu (116) - 116/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Lucario - 117/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Lucario - 117/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Drilbur - 118/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Drilbur - 118/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Excadrill - 119/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Excadrill - 119/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Archen - 120/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Archen - 120/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Archeops - 121/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Archeops - 121/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Terrakion - 122/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Terrakion - 122/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Meloetta - 123/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Meloetta - 123/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Zygarde - 124/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Zygarde - 124/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Umbreon & Darkrai GX - 125/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Mega Sableye & Tyranitar GX - 126/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Alolan Grimer - 127/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Alolan Grimer - 127/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Murkrow (128) - 128/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Murkrow (128) - 128/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Murkrow (129) - 129/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Murkrow (129) - 129/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Honchkrow - 130/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Honchkrow - 130/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Sneasel - 131/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Sneasel - 131/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Weavile GX - 132/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Sableye - 133/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Sableye - 133/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Drapion - 134/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Drapion - 134/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Purrloin - 135/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Purrloin - 135/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Liepard - 136/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Liepard - 136/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Scraggy - 137/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Scraggy - 137/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Scrafty - 138/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Scrafty - 138/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Yveltal - 139/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Yveltal - 139/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Hoopa - 140/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Hoopa - 140/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Mawile GX - 141/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Escavalier - 142/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Escavalier - 142/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Cottonee - 143/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Cottonee - 143/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Whimsicott - 144/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Whimsicott - 144/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dedenne - 145/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dedenne - 145/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Garchomp & Giratina GX - 146/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dratini (147) - 147/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dratini (147) - 147/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dratini (148) - 148/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dratini (148) - 148/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dragonair (149) - 149/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dragonair (149) - 149/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dragonair (150) - 150/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dragonair (150) - 150/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dragonite - 151/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dragonite - 151/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dragonite GX - 152/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Latias - 153/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Latias - 153/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Axew - 154/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Axew - 154/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Fraxure - 155/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Fraxure - 155/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Haxorus - 156/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Haxorus - 156/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Druddigon - 157/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Druddigon - 157/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Noibat - 158/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Noibat - 158/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Noivern - 159/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Noivern - 159/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Naganadel GX - 160/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Lickitung - 161/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Lickitung - 161/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Lickilicky - 162/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Lickilicky - 162/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Kangaskhan - 163/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Kangaskhan - 163/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tauros - 164/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tauros - 164/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Hoothoot - 165/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Hoothoot - 165/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Noctowl - 166/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Noctowl - 166/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Slakoth (167) - 167/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Slakoth (167) - 167/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Slakoth (168) - 168/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Slakoth (168) - 168/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Vigoroth - 169/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Vigoroth - 169/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Slaking - 170/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Slaking - 170/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Bidoof - 171/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Bidoof - 171/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Bibarel - 172/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Bibarel - 172/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Munchlax - 173/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Munchlax - 173/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Pidove - 174/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Pidove - 174/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tranquill - 175/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tranquill - 175/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Unfezant - 176/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Unfezant - 176/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Audino - 177/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Audino - 177/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tornadus - 178/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tornadus - 178/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Fletchling - 179/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Fletchling - 179/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Yungoos - 180/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Yungoos - 180/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Gumshoos - 181/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Gumshoos - 181/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Oranguru - 182/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Oranguru - 182/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Type: Null - 183/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Type: Null - 183/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Silvally - 184/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Silvally - 184/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Komala - 185/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Komala - 185/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Blaine's Quiz Show - 186/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Blaine's Quiz Show - 186/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Blizzard Town - 187/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Blizzard Town - 187/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Blue's Tactics - 188/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Blue's Tactics - 188/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Bug Catcher - 189/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Bug Catcher - 189/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Channeler - 190/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Channeler - 190/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Cherish Ball - 191/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Cherish Ball - 191/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Coach Trainer - 192/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Coach Trainer - 192/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dark City - 193/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dark City - 193/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Ear-Ringing Bell - 194/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Ear-Ringing Bell - 194/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Flyinium Z: Air Slash - 195/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Flyinium Z: Air Slash - 195/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Giant Bomb - 196/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Giant Bomb - 196/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Giant Hearth - 197/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Giant Hearth - 197/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Great Potion - 198/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Great Potion - 198/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Grimsley - 199/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Grimsley - 199/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Hapu - 200/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Hapu - 200/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Karate Belt - 201/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Karate Belt - 201/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Misty's Favor - 202/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Misty's Favor - 202/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Normalium Z: Tackle - 203/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Normalium Z: Tackle - 203/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Poke Maniac - 204/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Poke Maniac - 204/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Pokemon Research Lab - 205/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Pokemon Research Lab - 205/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Reset Stamp - 206/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Reset Stamp - 206/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Slumbering Forest - 207/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Slumbering Forest - 207/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Stadium Nav - 208/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Stadium Nav - 208/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tag Switch - 209/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tag Switch - 209/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Unidentified Fossil - 210/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Unidentified Fossil - 210/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - U-Turn Board - 211/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - U-Turn Board - 211/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Recycle Energy - 212/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Recycle Energy - 212/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Weakness Guard Energy - 213/236 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Weakness Guard Energy - 213/236 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Rowlet & Alolan Exeggutor GX (Full Art) - 214/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Rowlet & Alolan Exeggutor GX (Alternate Full Art) - 215/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Heatran GX (Full Art) - 216/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Slowpoke & Psyduck GX (Full Art) - 217/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Slowpoke & Psyduck GX (Alternate Full Art) - 218/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Keldeo GX (Full Art) - 219/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Raichu & Alolan Raichu GX (Full Art) - 220/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Raichu & Alolan Raichu GX (Alternate Full Art) - 221/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Mewtwo & Mew GX (Full Art) - 222/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Latios GX (Full Art) - 223/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Aerodactyl GX (Full Art) - 224/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Mega Sableye & Tyranitar GX (Full Art) - 225/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Mega Sableye & Tyranitar GX (Alternate Art) - 226/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Mawile GX (Full Art) - 227/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Garchomp & Giratina GX (Full Art) - 228/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dragonite GX (Full Art) - 229/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Naganadel GX (Full Art) - 230/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Blue's Tactics (Full Art) - 231/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Channeler (Full Art) - 232/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Coach Trainer (Full Art) - 233/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Grimsley (Full Art) - 234/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Misty's Favor (Full Art) - 235/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Poke Maniac (Full Art) - 236/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Rowlet & Alolan Exeggutor GX (Secret) - 237/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Heatran GX (Secret) - 238/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Slowpoke & Psyduck GX (Secret) - 239/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Keldeo GX (Secret) - 240/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Raichu & Alolan Raichu GX (Secret) - 241/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Mewtwo & Mew GX (Secret) - 242/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Latios GX (Secret) - 243/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Aerodactyl GX (Secret) - 244/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Mega Sableye & Tyranitar GX (Secret) - 245/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Mawile GX (Secret) - 246/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Garchomp & Giratina GX (Secret) - 247/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Dragonite GX (Secret) - 248/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Naganadel GX (Secret) - 249/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Cherish Ball (Secret) - 250/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Giant Bomb (Secret) - 251/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Karate Belt (Secret) - 252/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Reset Stamp (Secret) - 253/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Tag Switch (Secret) - 254/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - U-Turn Board (Secret) - 255/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Viridian Forest (Secret) - 256/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Recycle Energy (Secret) - 257/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unified Minds - Weakness Guard Energy (Secret) - 258/236 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Pheromosa & Buzzwole GX - 001/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Caterpie - 002/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Caterpie - 002/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Metapod - 003/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Metapod - 003/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Butterfree - 004/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Butterfree - 004/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Oddish - 005/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Oddish - 005/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Oddish - 006/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Oddish - 006/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Gloom - 007/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Gloom - 007/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Vileplume - 008/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Vileplume - 008/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Venonat - 009/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Venonat - 009/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Venonat (10) - 010/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Venonat (10) - 010/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Venomoth - 011/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Venomoth - 011/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Venomoth GX - 012/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Bellsprout - 013/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Bellsprout - 013/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Weepinbell - 014/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Weepinbell - 014/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Victreebel - 015/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Victreebel - 015/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Tangela - 016/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Tangela - 016/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Tangrowth - 017/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Tangrowth - 017/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Grubbin - 018/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Grubbin - 018/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Kartana - 019/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Kartana - 019/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Reshiram & Charizard GX - 020/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Growlithe - 021/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Growlithe - 021/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Arcanine - 022/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Arcanine - 022/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Darumaka - 023/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Darumaka - 023/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Darmanitan - 024/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Darmanitan - 024/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Volcanion - 025/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Volcanion - 025/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Litten (26) - 026/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Litten (26) - 026/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Litten (27) - 027/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Litten (27) - 027/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Torracat - 028/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Torracat - 028/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Incineroar - 029/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Incineroar - 029/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Salandit - 030/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Salandit - 030/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Salazzle - 031/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Salazzle - 031/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Blacephalon - 032/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Blacephalon - 032/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Squirtle - 033/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Squirtle - 033/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Wartortle - 034/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Wartortle - 034/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Blastoise GX - 035/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Poliwag (36) - 036/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Poliwag (36) - 036/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Poliwag (37) - 037/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Poliwag (37) - 037/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Poliwhirl - 038/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Poliwhirl - 038/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Poliwrath - 039/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Poliwrath - 039/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Tentacool - 040/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Tentacool - 040/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Tentacruel - 041/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Tentacruel - 041/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Slowpoke - 042/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Slowpoke - 042/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Slowbro - 043/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Slowbro - 043/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Seel - 044/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Seel - 044/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Dewgong - 045/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Dewgong - 045/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Krabby - 046/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Krabby - 046/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Kingler - 047/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Kingler - 047/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Goldeen - 048/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Goldeen - 048/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Seaking - 049/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Seaking - 049/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Kyurem - 050/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Kyurem - 050/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Froakie - 051/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Froakie - 051/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Frogadier - 052/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Frogadier - 052/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Pyukumuku - 053/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Pyukumuku - 053/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Pikachu - 054/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Pikachu - 054/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Raichu - 055/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Raichu - 055/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Stunfisk - 056/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Stunfisk - 056/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Dedenne GX - 057/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Charjabug - 058/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Charjabug - 058/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Vikavolt - 059/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Vikavolt - 059/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Zeraora - 060/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Zeraora - 060/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Muk & Alolan Muk GX - 061/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Ekans - 062/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Ekans - 062/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Arbok - 063/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Arbok - 063/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Zubat - 064/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Zubat - 064/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Golbat - 065/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Golbat - 065/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Crobat - 066/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Crobat - 066/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Gastly (67) - 067/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Gastly (67) - 067/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Gastly (68) - 068/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Gastly (68) - 068/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Haunter - 069/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Haunter - 069/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Gengar - 070/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Gengar - 070/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Drowzee - 071/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Drowzee - 071/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Hypno - 072/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Hypno - 072/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Koffing - 073/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Koffing - 073/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Weezing - 074/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Weezing - 074/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Mewtwo - 075/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Mewtwo - 075/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Mew - 076/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Mew - 076/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Misdreavus - 077/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Misdreavus - 077/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Mismagius - 078/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Mismagius - 078/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Espurr - 079/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Espurr - 079/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Meowstic - 080/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Meowstic - 080/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Marshadow - 081/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Marshadow - 081/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Marshadow & Machamp GX - 082/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Sandshrew - 083/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Sandshrew - 083/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Sandslash - 084/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Sandslash - 084/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Diglett - 085/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Diglett - 085/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Dugtrio - 086/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Dugtrio - 086/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Geodude - 087/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Geodude - 087/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Graveler - 088/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Graveler - 088/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Golem - 089/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Golem - 089/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Cubone - 090/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Cubone - 090/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Marowak - 091/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Marowak - 091/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Rhyhorn (92) - 092/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Rhyhorn (92) - 092/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Rhyhorn (93) - 093/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Rhyhorn (93) - 093/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Rhydon - 094/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Rhydon - 094/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Rhyperior - 095/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Rhyperior - 095/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Wooper - 096/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Wooper - 096/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Quagsire - 097/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Quagsire - 097/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Gligar - 098/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Gligar - 098/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Gliscor - 099/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Gliscor - 099/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Tyrogue - 100/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Tyrogue - 100/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Hitmontop - 101/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Hitmontop - 101/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Riolu - 102/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Riolu - 102/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Landorus - 103/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Landorus - 103/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Crabrawler - 104/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Crabrawler - 104/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Crabominable - 105/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Crabominable - 105/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Stakataka - 106/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Stakataka - 106/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Greninja & Zoroark GX - 107/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Murkrow - 108/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Murkrow - 108/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Honchkrow GX - 109/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Carvanha - 110/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Carvanha - 110/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Sharpedo - 111/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Sharpedo - 111/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Spiritomb - 112/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Spiritomb - 112/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Sandile (113) - 113/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Sandile (113) - 113/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Sandile (114) - 114/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Sandile (114) - 114/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Krokorok - 115/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Krokorok - 115/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Krookodile - 116/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Krookodile - 116/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Greninja - 117/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Greninja - 117/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Inkay - 118/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Inkay - 118/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Malamar - 119/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Malamar - 119/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Lucario & Melmetal GX - 120/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Alolan Diglett - 121/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Alolan Diglett - 121/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Alolan Dugtrio - 122/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Alolan Dugtrio - 122/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Aron - 123/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Aron - 123/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Lairon - 124/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Lairon - 124/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Aggron - 125/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Aggron - 125/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Lucario - 126/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Lucario - 126/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Genesect - 127/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Genesect - 127/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Meltan - 128/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Meltan - 128/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Melmetal - 129/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Melmetal - 129/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Gardevoir & Sylveon GX - 130/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Cleffa - 131/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Cleffa - 131/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Clefairy - 132/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Clefairy - 132/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Clefable - 133/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Clefable - 133/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Jigglypuff - 134/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Jigglypuff - 134/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Wigglytuff - 135/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Wigglytuff - 135/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Togepi - 136/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Togepi - 136/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Togetic - 137/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Togetic - 137/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Togekiss - 138/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Togekiss - 138/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Cottonee - 139/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Cottonee - 139/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Whimsicott GX - 140/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Spritzee - 141/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Spritzee - 141/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Aromatisse - 142/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Aromatisse - 142/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Rattata - 143/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Rattata - 143/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Raticate - 144/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Raticate - 144/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Spearow - 145/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Spearow - 145/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Fearow - 146/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Fearow - 146/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Meowth - 147/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Meowth - 147/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Persian - 148/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Persian - 148/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Persian GX - 149/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Doduo - 150/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Doduo - 150/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Dodrio - 151/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Dodrio - 151/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Lickitung - 152/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Lickitung - 152/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Lickilicky - 153/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Lickilicky - 153/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Porygon (154) - 154/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Porygon (154) - 154/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Porygon (155) - 155/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Porygon (155) - 155/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Porygon2 - 156/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Porygon2 - 156/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Porygon-Z - 157/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Porygon-Z - 157/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Snorlax - 158/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Snorlax - 158/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Glameow - 159/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Glameow - 159/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Purugly - 160/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Purugly - 160/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Happiny - 161/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Happiny - 161/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Chatot - 162/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Chatot - 162/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Celesteela GX - 163/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Beast Bringer - 164/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Beast Bringer - 164/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Chip-Chip Ice Axe - 165/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Chip-Chip Ice Axe - 165/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Devolution Spray Z - 166/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Devolution Spray Z - 166/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Dusk Stone - 167/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Dusk Stone - 167/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Dust Island - 168/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Dust Island - 168/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Electromagnetic Radar - 169/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Electromagnetic Radar - 169/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Energy Spinner - 170/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Energy Spinner - 170/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Fairy Charm Ability - 171/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Fairy Charm Ability - 171/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Fairy Charm L - 172/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Fairy Charm L - 172/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Fire Crystal - 173/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Fire Crystal - 173/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Giovanni's Exile - 174/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Giovanni's Exile - 174/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Green's Exploration - 175/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Green's Exploration - 175/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Janine - 176/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Janine - 176/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Koga's Trap - 177/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Koga's Trap - 177/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Lt. Surge's Strategy - 178/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Lt. Surge's Strategy - 178/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Martial Arts Dojo - 179/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Martial Arts Dojo - 179/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Metal Core Barrier - 180/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Metal Core Barrier - 180/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Molayne - 181/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Molayne - 181/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Pokegear 3.0 - 182/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Pokegear 3.0 - 182/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Power Plant - 183/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Power Plant - 183/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Red's Challenge - 184/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Red's Challenge - 184/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Samson Oak - 185/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Samson Oak - 185/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Stealthy Hood - 186/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Stealthy Hood - 186/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Surprise Box - 187/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Surprise Box - 187/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Ultra Forest Kartenvoy - 188/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Ultra Forest Kartenvoy - 188/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Welder - 189/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Welder - 189/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Triple Acceleration Energy - 190/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Triple Acceleration Energy - 190/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Pheromosa & Buzzwole GX (191) (Full Art) - 191/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Pheromosa & Buzzwole GX (192) (Alternate Full Art) - 192/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Venomoth GX (Full Art) - 193/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Reshiram & Charizard GX (Full Art) - 194/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Dedenne GX (Full Art) - 195/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Muk & Alolan Muk GX (196) (Full Art) - 196/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Muk & Alolan Muk GX (197) (Full Art) - 197/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Marshadow & Machamp GX (198) (Full Art) - 198/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Marshadow & Machamp GX (199) (Full Art) - 199/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Greninja & Zoroark GX (200) (Full Art) - 200/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Greninja & Zoroark GX (201) (Full Art) - 201/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Honchkrow GX (Full Art) - 202/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Lucario & Melmetal GX (Full Art) - 203/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Gardevoir & Sylveon GX (204) (Full Art) - 204/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Gardevoir & Sylveon GX (205) (Full Art) - 205/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Whimsicott GX (Full Art) - 206/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Persian GX (Full Art) - 207/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Celesteela GX (Full Art) - 208/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Green's Exploration (Full Art) - 209/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Janine (Full Art) - 210/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Koga's Trap (Full Art) - 211/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Molayne (Full Art) - 212/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Red's Challenge (Full Art) - 213/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Welder (Full Art) - 214/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Pheromosa & Buzzwole GX (Secret) - 215/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Venomoth GX (Secret) - 216/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Reshiram & Charizard GX (Secret) - 217/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Blastoise GX (Secret) - 218/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Dedenne GX (Secret) - 219/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Muk & Alolan Muk GX (Secret) - 220/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Marshadow & Machamp GX (Secret) - 221/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Greninja & Zoroark GX (Secret) - 222/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Honchkrow GX (Secret) - 223/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Lucario & Melmetal GX (Secret) - 224/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Gardevoir & Sylveon GX (Secret) - 225/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Whimsicott GX (Secret) - 226/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Persian GX (Secret) - 227/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Celesteela GX (Secret) - 228/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Beast Bringer (Secret) - 229/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Electromagnetic Radar (Secret) - 230/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Fire Crystal (Secret) - 231/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Metal Core Barrier (Secret) - 232/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Pokegear 3.0 (Secret) - 233/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unbroken Bonds - Triple Acceleration Energy (Secret) - 234/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Detective Pikachu - Bulbasaur (Holo Common) - 001/18 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Detective Pikachu - Ludicolo - 002/18 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Detective Pikachu - Morelull (Holo Common) - 003/18 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Detective Pikachu - Charmander (Holo Common) - 004/18 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Detective Pikachu - Charizard - 005/18 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Detective Pikachu - Arcanine - 006/18 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Detective Pikachu - Psyduck (Holo Common) - 007/18 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Detective Pikachu - Magikarp (Holo Common) - 008/18 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Detective Pikachu - Greninja - 009/18 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Detective Pikachu - Detective Pikachu - 010/18 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Detective Pikachu - Mr. Mime - 011/18 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Detective Pikachu - Mewtwo - 012/18 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Detective Pikachu - Machamp - 013/18 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Detective Pikachu - Jigglypuff (Holo Common) - 014/18 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Detective Pikachu - Snubbull (Holo Common) - 015/18 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Detective Pikachu - Lickitung (Holo Common) - 016/18 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Detective Pikachu - Ditto - 017/18 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Detective Pikachu - Slaking - 018/18 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Celebi & Venusaur GX - 001/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Weedle - 002/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Weedle - 002/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Weedle - 003/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Weedle - 003/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Kakuna - 004/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Kakuna - 004/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Beedrill - 005/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Beedrill - 005/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Paras - 006/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Paras - 006/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Parasect - 007/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Parasect - 007/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Exeggcute - 008/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Exeggcute - 008/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pinsir - 009/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pinsir - 009/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Shaymin Prism Star - 010/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Charmander (11) - 011/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Charmander (11) - 011/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Charmander (12) - 012/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Charmander (12) - 012/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Charmeleon - 013/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Charmeleon - 013/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Charizard - 014/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Charizard - 014/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Vulpix - 015/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Vulpix - 015/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Ninetales - 016/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Ninetales - 016/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Ponyta - 017/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Ponyta - 017/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Rapidash - 018/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Rapidash - 018/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Moltres - 019/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Moltres - 019/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Litten - 020/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Litten - 020/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Torracat - 021/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Torracat - 021/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Squirtle (22) - 022/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Squirtle (22) - 022/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Squirtle (23) - 023/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Squirtle (23) - 023/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Wartortle - 024/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Wartortle - 024/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Blastoise - 025/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Blastoise - 025/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Psyduck - 026/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Psyduck - 026/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Golduck - 027/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Golduck - 027/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Staryu - 028/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Staryu - 028/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Magikarp - 029/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Magikarp - 029/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Gyarados - 030/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Gyarados - 030/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Lapras - 031/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Lapras - 031/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Articuno - 032/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Articuno - 032/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pikachu & Zekrom GX - 033/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Geodude (34) - 034/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Geodude (34) - 034/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Geodude (35) - 035/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Geodude (35) - 035/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Graveler - 036/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Graveler - 036/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Golem - 037/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Golem - 037/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Voltorb - 038/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Voltorb - 038/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Electrode - 039/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Electrode - 039/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Zapdos - 040/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Zapdos - 040/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Mareep - 041/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Mareep - 041/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Flaaffy - 042/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Flaaffy - 042/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Ampharos GX - 043/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Blitzle - 044/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Blitzle - 044/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Zebstrika - 045/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Zebstrika - 045/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Emolga - 046/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Emolga - 046/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Joltik - 047/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Joltik - 047/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Galvantula - 048/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Galvantula - 048/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Helioptile - 049/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Helioptile - 049/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Heliolisk - 050/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Heliolisk - 050/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Tapu Koko Prism Star - 051/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Zeraora - 052/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Zeraora - 052/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Gengar & Mimikyu GX - 053/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Nidoran F - 054/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Nidoran F - 054/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Nidorina - 055/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Nidorina - 055/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Nidoqueen - 056/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Nidoqueen - 056/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Nidoran M - 057/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Nidoran M - 057/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Nidorino - 058/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Nidorino - 058/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Nidoking - 059/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Nidoking - 059/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Tentacool - 060/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Tentacool - 060/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Tentacruel - 061/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Tentacruel - 061/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Grimer - 062/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Grimer - 062/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Muk - 063/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Muk - 063/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Marowak - 064/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Marowak - 064/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Starmie - 065/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Starmie - 065/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Mr. Mime - 066/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Mr. Mime - 066/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Mr. Mime GX - 067/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Jynx - 068/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Jynx - 068/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Cosmog - 069/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Cosmog - 069/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Cosmoem - 070/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Cosmoem - 070/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Mankey - 071/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Mankey - 071/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Primeape - 072/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Primeape - 072/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Hitmonlee - 073/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Hitmonlee - 073/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Hitmonchan - 074/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Hitmonchan - 074/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Omanyte - 075/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Omanyte - 075/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Omastar - 076/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Omastar - 076/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Kabuto - 077/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Kabuto - 077/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Kabutops - 078/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Kabutops - 078/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Larvitar - 079/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Larvitar - 079/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pupitar - 080/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pupitar - 080/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pancham - 081/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pancham - 081/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Lycanroc GX - 082/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Grimer - 083/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Grimer - 083/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Muk - 084/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Muk - 084/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Tyranitar - 085/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Tyranitar - 085/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Poochyena - 086/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Poochyena - 086/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Mightyena - 087/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Mightyena - 087/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Absol - 088/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Absol - 088/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Spiritomb - 089/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Spiritomb - 089/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Zorua - 090/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Zorua - 090/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Zoroark - 091/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Zoroark - 091/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Vullaby - 092/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Vullaby - 092/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Mandibuzz - 093/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Mandibuzz - 093/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pangoro - 094/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pangoro - 094/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Yveltal - 095/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Yveltal - 095/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Hoopa GX - 096/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Incineroar GX - 097/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Skarmory - 098/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Skarmory - 098/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Jirachi - 099/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Jirachi - 099/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Bronzor - 100/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Bronzor - 100/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Bronzong - 101/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Bronzong - 101/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Ferroseed - 102/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Ferroseed - 102/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Ferrothorn - 103/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Ferrothorn - 103/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pawniard - 104/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pawniard - 104/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Bisharp - 105/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Bisharp - 105/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Cobalion GX - 106/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Honedge - 107/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Honedge - 107/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Doublade - 108/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Doublade - 108/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Aegislash - 109/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Aegislash - 109/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Klefki - 110/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Klefki - 110/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Ninetales - 111/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Ninetales - 111/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Mimikyu - 112/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Mimikyu - 112/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Latias & Latios GX - 113/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Exeggutor (114) - 114/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Exeggutor (114) - 114/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Exeggutor (115) - 115/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Alolan Exeggutor (115) - 115/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Dratini (116) - 116/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Dratini (116) - 116/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Dratini (117) - 117/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Dratini (117) - 117/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Dragonair - 118/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Dragonair - 118/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Dragonite - 119/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Dragonite - 119/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Eevee & Snorlax GX - 120/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pidgey (121) - 121/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pidgey (121) - 121/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pidgey (122) - 122/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pidgey (122) - 122/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pidgeotto - 123/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pidgeotto - 123/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pidgeot - 124/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pidgeot - 124/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Meowth - 125/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Meowth - 125/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Persian - 126/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Persian - 126/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Farfetch'd - 127/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Farfetch'd - 127/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Kangaskhan - 128/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Kangaskhan - 128/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Tauros - 129/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Tauros - 129/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Aerodactyl - 130/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Aerodactyl - 130/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Lugia - 131/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Lugia - 131/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Zangoose - 132/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Zangoose - 132/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Bill's Analysis - 133/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Bill's Analysis - 133/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Black Market Prism Star - 134/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Brock's Grit - 135/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Brock's Grit - 135/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Buff Padding - 136/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Buff Padding - 136/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Dana - 137/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Dana - 137/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Dangerous Drill - 138/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Dangerous Drill - 138/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Electrocharger - 139/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Electrocharger - 139/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Erika's Hospitality - 140/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Erika's Hospitality - 140/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Evelyn - 141/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Evelyn - 141/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Fairy Charm UB - 142/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Fairy Charm UB - 142/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Grass Memory - 143/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Grass Memory - 143/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Ingo & Emmet - 144/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Ingo & Emmet - 144/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Jasmine - 145/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Jasmine - 145/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Judge Whistle - 146/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Judge Whistle - 146/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Lavender Town - 147/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Lavender Town - 147/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Metal Goggles - 148/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Metal Goggles - 148/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Morgan - 149/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Morgan - 149/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Nanu - 150/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Nanu - 150/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Nita - 151/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Nita - 151/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pokemon Communication - 152/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pokemon Communication - 152/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Return Label - 153/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Return Label - 153/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Sabrina's Suggestion - 154/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Sabrina's Suggestion - 154/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Unidentified Fossil - 155/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Unidentified Fossil - 155/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Viridian Forest - 156/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Viridian Forest - 156/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Water Memory - 157/181 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Water Memory - 157/181 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Wondrous Labyrinth Prism Star - 158/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Celebi & Venusaur GX (Full Art) - 159/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Magikarp & Wailord GX (Full Art) - 160/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Magikarp & Wailord GX (Alternate Full Art) - 161/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pikachu & Zekrom GX (Full Art) - 162/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Ampharos GX (Full Art) - 163/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Gengar & Mimikyu GX (Full Art) - 164/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Gengar & Mimikyu GX (Alternate Full Art) - 165/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Hoopa GX (Full Art) - 166/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Incineroar GX (Full Art) - 167/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Cobalion GX (Full Art) - 168/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Latias & Latios GX (Full Art) - 169/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Latias & Latios GX (Alternate Full Art) - 170/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Eevee & Snorlax GX (Full Art) - 171/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Brock's Grit (Full Art) - 172/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Dana (Full Art) - 173/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Erika's Hospitality (Full Art) - 174/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Evelyn (Full Art) - 175/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Ingo & Emmet (Full Art) - 176/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Jasmine (Full Art) - 177/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Morgan (Full Art) - 178/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Nanu (Full Art) - 179/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Nita (Full Art) - 180/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Sabrina's Suggestion (Full Art) - 181/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Celebi & Venusaur GX (Secret) - 182/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Magikarp & Wailord GX (Secret) - 183/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pikachu & Zekrom GX (Secret) - 184/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Ampharos GX (Secret) - 185/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Gengar & Mimikyu GX (Secret) - 186/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Hoopa GX (Secret) - 187/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Incineroar GX (Secret) - 188/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Cobalion GX (Secret) - 189/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Latias & Latios GX (Secret) - 190/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Eevee & Snorlax GX (Secret) - 191/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Dangerous Drill (Secret) - 192/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Electrocharger (Secret) - 193/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Judge Whistle (Secret) - 194/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Metal Goggles (Secret) - 195/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Up - Pokemon Communication (Secret) - 196/181 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Tangela - 001/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Tangela - 001/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Tangrowth - 002/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Tangrowth - 002/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Scyther - 003/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Scyther - 003/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Pinsir - 004/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Pinsir - 004/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Chikorita - 005/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Chikorita - 005/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Chikorita - 006/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Chikorita - 006/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Bayleef - 007/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Bayleef - 007/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Meganium - 008/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Meganium - 008/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Spinarak - 009/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Spinarak - 009/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Ariados - 010/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Ariados - 010/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Hoppip (11) - 011/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Hoppip (11) - 011/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Hoppip (12) - 012/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Hoppip (12) - 012/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Skiploom - 013/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Skiploom - 013/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Jumpluff - 014/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Jumpluff - 014/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Pineco - 015/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Pineco - 015/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Shuckle - 016/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Shuckle - 016/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Shuckle GX - 017/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Heracross - 018/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Heracross - 018/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Celebi Prism Star - 019/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Treecko - 020/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Treecko - 020/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Grovyle - 021/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Grovyle - 021/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Sceptile GX - 022/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Wurmple (23) - 023/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Wurmple (23) - 023/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Wurmple (24) - 024/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Wurmple (24) - 024/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Silcoon - 025/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Silcoon - 025/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Beautifly - 026/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Beautifly - 026/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Cascoon - 027/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Cascoon - 027/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Dustox - 028/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Dustox - 028/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Nincada - 029/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Nincada - 029/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Ninjask - 030/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Ninjask - 030/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Combee - 031/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Combee - 031/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Vespiquen - 032/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Vespiquen - 032/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Shaymin - 033/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Shaymin - 033/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Virizion GX - 034/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Skiddo - 035/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Skiddo - 035/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Gogoat - 036/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Gogoat - 036/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Tapu Bulu - 037/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Tapu Bulu - 037/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Moltres - 038/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Moltres - 038/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Cyndaquil (39) - 039/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Cyndaquil (39) - 039/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Cyndaquil (40) - 040/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Cyndaquil (40) - 040/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Quilava - 041/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Quilava - 041/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Typhlosion - 042/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Typhlosion - 042/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Slugma - 043/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Slugma - 043/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Magcargo GX - 044/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Houndour - 045/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Houndour - 045/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Houndoom - 046/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Houndoom - 046/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Entei - 047/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Entei - 047/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Heatran - 048/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Heatran - 048/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Victini - 049/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Victini - 049/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Litleo - 050/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Litleo - 050/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Pyroar - 051/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Pyroar - 051/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Blacephalon GX - 052/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Alolan Vulpix - 053/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Alolan Vulpix - 053/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Slowpoke - 054/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Slowpoke - 054/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Slowking - 055/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Slowking - 055/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Lapras - 056/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Lapras - 056/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Delibird - 057/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Delibird - 057/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Mantine - 058/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Mantine - 058/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Suicune - 059/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Suicune - 059/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Suicune GX - 060/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Cubchoo - 061/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Cubchoo - 061/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Beartic - 062/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Beartic - 062/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - White Kyurem - 063/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - White Kyurem - 063/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Popplio (64) - 064/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Popplio (64) - 064/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Popplio (65) - 065/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Popplio (65) - 065/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Brionne - 066/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Brionne - 066/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Primarina - 067/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Primarina - 067/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Mareanie (68) - 068/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Mareanie (68) - 068/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Toxapex - 069/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Toxapex - 069/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Bruxish - 070/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Bruxish - 070/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Electabuzz - 071/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Electabuzz - 071/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Electivire - 072/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Electivire - 072/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Chinchou - 073/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Chinchou - 073/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Lanturn - 074/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Lanturn - 074/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Mareep (75) - 075/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Mareep (75) - 075/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Mareep (76) - 076/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Mareep (76) - 076/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Flaaffy - 077/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Flaaffy - 077/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Ampharos - 078/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Ampharos - 078/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Raikou - 079/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Raikou - 079/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Pachirisu - 080/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Pachirisu - 080/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Blitzle - 081/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Blitzle - 081/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Zebstrika - 082/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Zebstrika - 082/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Stunfisk - 083/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Stunfisk - 083/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Dedenne (84) - 084/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Dedenne (84) - 084/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Tapu Koko - 085/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Tapu Koko - 085/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Zeraora GX - 086/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Natu - 087/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Natu - 087/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Xatu - 088/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Xatu - 088/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Espeon - 089/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Espeon - 089/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Unown (90) - 090/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Unown (90) - 090/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Unown (91) - 091/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Unown (91) - 091/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Unown (92) - 092/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Unown (92) - 092/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Wobbuffet - 093/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Wobbuffet - 093/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Girafarig - 094/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Girafarig - 094/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Shedinja - 095/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Shedinja - 095/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Sableye - 096/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Sableye - 096/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Giratina - 097/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Giratina - 097/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Sigilyph GX - 098/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Yamask - 099/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Yamask - 099/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Cofagrigus - 100/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Cofagrigus - 100/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Litwick - 101/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Litwick - 101/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Lampent - 102/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Lampent - 102/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Chandelure - 103/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Chandelure - 103/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Meloetta - 104/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Meloetta - 104/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Mareanie (105) - 105/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Mareanie (105) - 105/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Nihilego - 106/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Nihilego - 106/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Poipole - 107/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Poipole - 107/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Naganadel - 108/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Naganadel - 108/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Onix - 109/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Onix - 109/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Sudowoodo - 110/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Sudowoodo - 110/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Phanpy - 111/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Phanpy - 111/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Donphan - 112/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Donphan - 112/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Hitmontop - 113/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Hitmontop - 113/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Larvitar (114) - 114/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Larvitar (114) - 114/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Larvitar (115) - 115/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Larvitar (115) - 115/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Pupitar - 116/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Pupitar - 116/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Carbink (117) - 117/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Carbink (117) - 117/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Alolan Meowth - 118/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Alolan Meowth - 118/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Alolan Persian - 119/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Alolan Persian - 119/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Umbreon - 120/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Umbreon - 120/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Tyranitar GX - 121/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Alolan Diglett - 122/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Alolan Diglett - 122/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Alolan Dugtrio - 123/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Alolan Dugtrio - 123/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Forretress - 124/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Forretress - 124/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Steelix - 125/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Steelix - 125/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Scizor - 126/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Scizor - 126/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Dialga - 127/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Dialga - 127/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Durant - 128/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Durant - 128/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Cobalion - 129/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Cobalion - 129/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Genesect GX - 130/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Magearna - 131/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Magearna - 131/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Alolan Ninetales GX - 132/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Jigglypuff - 133/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Jigglypuff - 133/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Wigglytuff - 134/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Wigglytuff - 134/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Marill - 135/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Marill - 135/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Azumarill - 136/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Azumarill - 136/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Snubbull - 137/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Snubbull - 137/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Granbull - 138/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Granbull - 138/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Ralts - 139/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Ralts - 139/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Kirlia - 140/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Kirlia - 140/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Gardevoir - 141/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Gardevoir - 141/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Dedenne (142) - 142/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Dedenne (142) - 142/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Carbink (143) - 143/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Carbink (143) - 143/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Xerneas Prism Star - 144/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Cutiefly - 145/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Cutiefly - 145/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Ribombee - 146/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Ribombee - 146/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Morelull - 147/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Morelull - 147/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Shiinotic - 148/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Shiinotic - 148/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Mimikyu GX - 149/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Tapu Lele - 150/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Tapu Lele - 150/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Tapu Fini - 151/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Tapu Fini - 151/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Chansey - 152/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Chansey - 152/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Blissey - 153/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Blissey - 153/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Ditto Prism Star - 154/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Eevee - 155/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Eevee - 155/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Stantler - 156/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Stantler - 156/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Smeargle - 157/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Smeargle - 157/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Miltank - 158/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Miltank - 158/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Lugia GX - 159/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Ho-Oh - 160/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Ho-Oh - 160/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Kecleon (161) - 161/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Kecleon (161) - 161/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Kecleon (162) - 162/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Kecleon (162) - 162/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Pikipek (163) - 163/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Pikipek (163) - 163/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Pikipek (164) - 164/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Pikipek (164) - 164/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Trumbeak - 165/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Trumbeak - 165/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Toucannon - 166/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Toucannon - 166/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Adventure Bag - 167/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Adventure Bag - 167/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Aether Foundation Employee - 168/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Aether Foundation Employee - 168/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Choice Helmet - 169/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Choice Helmet - 169/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Counter Gain - 170/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Counter Gain - 170/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Custom Catcher - 171/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Custom Catcher - 171/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Electropower - 172/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Electropower - 172/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Faba - 173/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Faba - 173/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Fairy Charm G - 174/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Fairy Charm G - 174/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Fairy Charm P - 175/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Fairy Charm P - 175/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Fairy Charm F - 176/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Fairy Charm F - 176/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Fairy Charm O - 177/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Fairy Charm O - 177/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Heat Factory Prism Star - 178/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Kahili - 179/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Kahili - 179/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Life Forest Prism Star - 180/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Lost Blender - 181/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Lost Blender - 181/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Lusamine Prism Star - 182/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Mina - 183/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Mina - 183/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Mixed Herbs - 184/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Mixed Herbs - 184/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Moomoo Milk - 185/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Moomoo Milk - 185/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Morty - 186/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Morty - 186/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Net Ball - 187/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Net Ball - 187/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Professor Elm's Lecture - 188/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Professor Elm's Lecture - 188/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Sightseer - 189/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Sightseer - 189/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Spell Tag - 190/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Spell Tag - 190/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Thunder Mountain Prism Star - 191/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Wait and See Hammer - 192/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Wait and See Hammer - 192/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Whitney - 193/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Whitney - 193/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Memory Energy - 194/214 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Memory Energy - 194/214 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Shuckle GX (Full Art) - 195/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Sceptile GX (Full Art) - 196/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Virizion GX (Full Art) - 197/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Magcargo GX (Full Art) - 198/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Blacephalon GX (Full Art) - 199/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Suicune GX (Full Art) - 200/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Zeraora GX (Full Art) - 201/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Sigilyph GX (Full Art) - 202/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Tyranitar GX (Full Art) - 203/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Genesect GX (Full Art) - 204/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Alolan Ninetales GX (Full Art) - 205/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Mimikyu GX (Full Art) - 206/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Lugia GX (Full Art) - 207/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Faba (Full Art) - 208/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Judge (Full Art) - 209/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Kahili (Full Art) - 210/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Mina (Full Art) - 211/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Morty (Full Art) - 212/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Professor Elm's Lecture (Full Art) - 213/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Whitney (Full Art) - 214/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Shuckle GX (Secret) - 215/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Sceptile GX (Secret) - 216/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Virizion GX (Secret) - 217/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Magcargo GX (Secret) - 218/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Blacephalon GX (Secret) - 219/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Suicune GX (Secret) - 220/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Zeraora GX (Secret) - 221/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Sigilyph GX (Secret) - 222/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Tyranitar GX (Secret) - 223/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Genesect GX (Secret) - 224/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Alolan Ninetales GX (Secret) - 225/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Mimikyu GX (Secret) - 226/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Lugia GX (Secret) - 227/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Adventure Bag (Secret) - 228/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Choice Helmet (Secret) - 229/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Counter Gain (Secret) - 230/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Custom Catcher (Secret) - 231/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Electropower (Secret) - 232/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Lost Blender (Secret) - 233/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Net Ball (Secret) - 234/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Spell Tag (Secret) - 235/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Thunder - Wait and See Hammer (Secret) - 236/214 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Charmander - 001/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Charmander - 001/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Charmeleon - 002/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Charmeleon - 002/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Charizard - 003/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Charizard - 003/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Torchic - 004/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Torchic - 004/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Combusken - 005/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Combusken - 005/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Blaziken - 006/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Blaziken - 006/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Victini Prism Star - 007/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Darumaka - 008/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Darumaka - 008/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Darmanitan - 009/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Darmanitan - 009/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Heatmor - 010/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Heatmor - 010/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Reshiram GX - 011/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Litten - 012/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Litten - 012/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Salandit - 013/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Salandit - 013/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Salazzle - 014/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Salazzle - 014/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Horsea (15) - 015/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Horsea (15) - 015/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Horsea (16) - 016/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Horsea (16) - 016/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Seadra - 017/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Seadra - 017/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Kingdra GX - 018/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Magikarp - 019/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Magikarp - 019/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Gyarados - 020/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Gyarados - 020/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Lapras - 021/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Lapras - 021/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Totodile - 022/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Totodile - 022/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Croconaw - 023/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Croconaw - 023/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Feraligatr - 024/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Feraligatr - 024/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Wooper - 025/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Wooper - 025/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Quagsire - 026/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Quagsire - 026/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Corsola - 027/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Corsola - 027/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Feebas - 028/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Feebas - 028/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Milotic - 029/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Milotic - 029/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Phione - 030/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Phione - 030/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Wishiwashi - 031/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Wishiwashi - 031/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Trapinch - 032/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Trapinch - 032/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Hydreigon - 033/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Hydreigon - 033/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Dratini (34) - 034/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Dratini (34) - 034/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Dratini (35) - 035/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Dratini (35) - 035/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Dragonair - 036/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Dragonair - 036/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Dragonite GX - 037/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Vibrava - 038/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Vibrava - 038/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Flygon - 039/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Flygon - 039/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Altaria - 040/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Altaria - 040/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Altaria GX - 041/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Bagon - 042/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Bagon - 042/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Shelgon - 043/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Shelgon - 043/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Salamence GX - 044/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Druddigon - 045/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Druddigon - 045/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Zekrom - 046/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Zekrom - 046/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Kyurem - 047/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Kyurem - 047/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - White Kyurem GX - 048/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Zygarde - 049/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Zygarde - 049/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Turtonator - 050/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Turtonator - 050/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Drampa - 051/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Drampa - 051/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Jangmo-o - 052/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Jangmo-o - 052/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Hakamo-o - 053/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Hakamo-o - 053/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Kommo-o - 054/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Kommo-o - 054/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Kangaskhan - 055/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Kangaskhan - 055/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Swablu (56) - 056/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Swablu (56) - 056/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Swablu (57) - 057/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Swablu (57) - 057/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Blaine's Last Stand - 058/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Blaine's Last Stand - 058/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Dragon Talon - 059/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Dragon Talon - 059/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Fiery Flint - 060/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Fiery Flint - 060/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Lance Prism Star - 061/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Switch Raft - 062/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Switch Raft - 062/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Wela Volcano Park - 063/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Wela Volcano Park - 063/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Zinnia - 064/70 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Zinnia - 064/70 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Reshiram GX (Full Art) - 065/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Kingdra GX (Full Art) - 066/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Dragonite GX (Full Art) - 067/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Altaria GX (Full Art) - 068/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Blaine's Last Stand (Full Art) - 069/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Zinnia (Full Art) - 070/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Reshiram GX (Secret) - 071/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Altaria GX (Secret) - 072/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Salamence GX (Secret) - 073/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - White Kyurem GX (Secret) - 074/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Dragon Talon (Secret) - 075/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Fiery Flint (Secret) - 076/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Switch Raft (Secret) - 077/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Majesty - Ultra Necrozma GX (Secret) - 078/70 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Bellsprout - 001/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Bellsprout - 001/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Weepinbell - 002/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Weepinbell - 002/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Victreebel - 003/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Victreebel - 003/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Scyther - 004/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Scyther - 004/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Spinarak - 005/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Spinarak - 005/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Ariados - 006/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Ariados - 006/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Treecko - 007/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Treecko - 007/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Treecko - 008/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Treecko - 008/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Grovyle - 009/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Grovyle - 009/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Sceptile - 010/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Sceptile - 010/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Seedot (11) - 011/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Seedot (11) - 011/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Seedot (12) - 012/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Seedot (12) - 012/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Nuzleaf - 013/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Nuzleaf - 013/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Shiftry GX - 014/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Surskit - 015/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Surskit - 015/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Masquerain - 016/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Masquerain - 016/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Volbeat - 017/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Volbeat - 017/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Illumise - 018/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Illumise - 018/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Cacnea - 019/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Cacnea - 019/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Cacturne - 020/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Cacturne - 020/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Tropius - 021/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Tropius - 021/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Dhelmise - 022/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Dhelmise - 022/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Slugma - 023/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Slugma - 023/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Magcargo - 024/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Magcargo - 024/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Torchic (25) - 025/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Torchic (25) - 025/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Torchic (26) - 026/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Torchic (26) - 026/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Combusken - 027/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Combusken - 027/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Blaziken GX - 028/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Torkoal - 029/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Torkoal - 029/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Oricorio (30) - 030/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Oricorio (30) - 030/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Articuno GX - 031/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Mudkip (32) - 032/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Mudkip (32) - 032/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Mudkip (33) - 033/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Mudkip (33) - 033/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Marshtomp - 034/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Marshtomp - 034/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Swampert - 035/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Swampert - 035/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Lotad - 036/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Lotad - 036/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Lombre - 037/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Lombre - 037/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Ludicolo - 038/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Ludicolo - 038/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Wailmer - 039/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Wailmer - 039/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Wailord - 040/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Wailord - 040/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Clamperl - 041/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Clamperl - 041/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Huntail - 042/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Huntail - 042/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Gorebyss - 043/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Gorebyss - 043/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Luvdisc - 044/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Luvdisc - 044/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Regice - 045/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Regice - 045/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Kyogre - 046/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Kyogre - 046/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Voltorb - 047/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Voltorb - 047/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Electrode GX - 048/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Chinchou - 049/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Chinchou - 049/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Lanturn - 050/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Lanturn - 050/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Electrike - 051/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Electrike - 051/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Manectric - 052/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Manectric - 052/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Plusle - 053/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Plusle - 053/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Minun - 054/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Minun - 054/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Oricorio (55) - 055/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Oricorio (55) - 055/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Mr. Mime GX - 056/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Gulpin - 057/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Gulpin - 057/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Swalot - 058/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Swalot - 058/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Spoink - 059/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Spoink - 059/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Grumpig - 060/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Grumpig - 060/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Lunatone - 061/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Lunatone - 061/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Solrock - 062/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Solrock - 062/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Shuppet (63) - 063/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Shuppet (63) - 063/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Shuppet (64) - 064/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Shuppet (64) - 064/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Banette - 065/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Banette - 065/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Banette GX - 066/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Deoxys (67) - 067/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Deoxys (67) - 067/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Deoxys (68) - 068/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Deoxys (68) - 068/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Deoxys (69) - 069/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Deoxys (69) - 069/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Lunala - 070/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Lunala - 070/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Onix - 071/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Onix - 071/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Phanpy - 072/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Phanpy - 072/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Donphan - 073/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Donphan - 073/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Larvitar - 074/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Larvitar - 074/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Pupitar - 075/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Pupitar - 075/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Meditite - 076/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Meditite - 076/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Medicham - 077/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Medicham - 077/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Baltoy - 078/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Baltoy - 078/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Claydol - 079/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Claydol - 079/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Regirock - 080/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Regirock - 080/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Groudon - 081/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Groudon - 081/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Palossand GX - 082/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Minior - 083/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Minior - 083/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Alolan Rattata - 084/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Alolan Rattata - 084/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Alolan Raticate GX - 085/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Sneasel - 086/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Sneasel - 086/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Tyranitar - 087/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Tyranitar - 087/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Sableye - 088/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Sableye - 088/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Steelix - 089/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Steelix - 089/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Scizor GX - 090/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Mawile - 091/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Mawile - 091/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Beldum (92) - 092/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Beldum (92) - 092/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Beldum (93) - 093/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Beldum (93) - 093/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Metang - 094/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Metang - 094/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Metagross - 095/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Metagross - 095/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Registeel - 096/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Registeel - 096/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Jirachi Prism Star - 097/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Heatran - 098/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Heatran - 098/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Solgaleo - 099/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Solgaleo - 099/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Celesteela - 100/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Celesteela - 100/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Kartana - 101/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Kartana - 101/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Stakataka GX - 102/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Bagon (103) - 103/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Bagon (103) - 103/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Bagon (104) - 104/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Bagon (104) - 104/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Shelgon - 105/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Shelgon - 105/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Salamence - 106/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Salamence - 106/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Latias Prism Star - 107/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Latios Prism Star - 108/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Rayquaza GX - 109/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Dunsparce - 110/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Dunsparce - 110/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Wingull - 111/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Wingull - 111/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Pelipper - 112/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Pelipper - 112/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Slakoth - 113/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Slakoth - 113/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Vigoroth - 114/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Vigoroth - 114/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Slaking - 115/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Slaking - 115/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Whismur (116) - 116/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Whismur (116) - 116/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Whismur (117) - 117/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Whismur (117) - 117/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Loudred - 118/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Loudred - 118/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Exploud - 119/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Exploud - 119/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Skitty - 120/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Skitty - 120/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Delcatty - 121/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Delcatty - 121/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Kecleon - 122/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Kecleon - 122/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Acro Bike - 123/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Acro Bike - 123/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Apricorn Maker - 124/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Apricorn Maker - 124/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Beast Ball - 125/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Beast Ball - 125/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Bill's Maintenance - 126/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Bill's Maintenance - 126/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Copycat - 127/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Copycat - 127/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Energy Recycle System - 128/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Energy Recycle System - 128/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Energy Switch - 129/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Energy Switch - 129/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Fisherman - 130/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Fisherman - 130/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Friend Ball - 131/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Friend Ball - 131/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Hiker - 133/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Hiker - 133/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Hustle Belt - 134/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Hustle Belt - 134/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Last Chance Potion - 135/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Last Chance Potion - 135/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Life Herb - 136/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Life Herb - 136/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Lisia - 137/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Lisia - 137/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Lure Ball - 138/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Lure Ball - 138/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - The Masked Royal - 139/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - The Masked Royal - 139/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - PokeNav - 140/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - PokeNav - 140/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Rainbow Brush - 141/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Rainbow Brush - 141/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Rare Candy - 142/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Rare Candy - 142/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Shrine of Punishment - 143/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Shrine of Punishment - 143/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Sky Pillar - 144/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Sky Pillar - 144/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Steven's Resolve - 145/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Steven's Resolve - 145/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Super Scoop Up - 146/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Super Scoop Up - 146/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Switch - 147/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Switch - 147/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Tate & Liza - 148/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Tate & Liza - 148/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - TV Reporter - 149/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - TV Reporter - 149/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Underground Expedition - 150/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Underground Expedition - 150/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Rainbow Energy - 151/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Rainbow Energy - 151/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Shiftry GX (Full Art) - 152/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Blaziken GX (Full Art) - 153/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Articuno GX (Full Art) - 154/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Electrode GX (Full Art) - 155/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Mr. Mime GX (Full Art) - 156/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Banette GX (Full Art) - 157/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Scizor GX (Full Art) - 158/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Stakataka GX (Full Art) - 159/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Rayquaza GX (Full Art) - 160/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Apricorn Maker (Full Art) - 161/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Bill's Maintenance (Full Art) - 162/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Copycat (Full Art) - 163/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Lisia (Full Art) - 164/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Steven's Resolve (Full Art) - 165/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Tate & Liza (Full Art) - 166/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - TV Reporter (Full Art) - 167/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Underground Expedition (Full Art) - 168/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Shiftry GX (Secret) - 169/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Blaziken GX (Secret) - 170/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Articuno GX (Secret) - 171/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Electrode GX (Secret) - 172/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Mr. Mime GX (Secret) - 173/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Banette GX (Secret) - 174/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Scizor GX (Secret) - 175/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Stakataka GX (Secret) - 176/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Rayquaza GX (Secret) - 177/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Acro Bike (Secret) - 178/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Hustle Belt (Secret) - 179/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Life Herb (Secret) - 180/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - PokeNav (Secret) - 181/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Rainbow Brush (Secret) - 182/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Rainbow Energy (Secret) - 183/168 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Hau - 132/168 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Celestial Storm - Hau - 132/168 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Exeggcute - 001/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Exeggcute - 001/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Alolan Exeggutor - 002/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Alolan Exeggutor - 002/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Snover - 003/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Snover - 003/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Abomasnow - 004/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Abomasnow - 004/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Scatterbug - 005/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Scatterbug - 005/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Scatterbug - 006/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Scatterbug - 006/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Spewpa - 007/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Spewpa - 007/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Vivillon - 008/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Vivillon - 008/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Skiddo - 009/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Skiddo - 009/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Gogoat - 010/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Gogoat - 010/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Pheromosa - 011/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Pheromosa - 011/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Alolan Marowak - 012/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Alolan Marowak - 012/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Heatran - 013/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Heatran - 013/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Fennekin (14) - 014/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Fennekin (14) - 014/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Fennekin (15) - 015/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Fennekin (15) - 015/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Braixen - 016/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Braixen - 016/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Delphox - 017/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Delphox - 017/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Litleo - 018/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Litleo - 018/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Pyroar - 019/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Pyroar - 019/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Palkia GX - 020/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Froakie (21) - 021/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Froakie (21) - 021/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Froakie (22) - 022/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Froakie (22) - 022/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Frogadier - 023/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Frogadier - 023/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Greninja GX - 024/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Clauncher - 025/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Clauncher - 025/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Clawitzer - 026/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Clawitzer - 026/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Amaura - 027/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Amaura - 027/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Aurorus - 028/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Aurorus - 028/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Bergmite - 029/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Bergmite - 029/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Avalugg - 030/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Avalugg - 030/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Volcanion Prism Star - 031/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Dewpider - 032/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Dewpider - 032/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Araquanid - 033/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Araquanid - 033/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Magnemite - 034/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Magnemite - 034/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Magneton - 035/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Magneton - 035/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Magnezone - 036/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Magnezone - 036/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Helioptile - 037/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Helioptile - 037/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Heliolisk - 038/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Heliolisk - 038/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Xurkitree - 039/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Xurkitree - 039/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Rotom - 040/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Rotom - 040/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Uxie - 041/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Uxie - 041/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Mesprit - 042/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Mesprit - 042/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Azelf - 043/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Azelf - 043/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Espurr - 044/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Espurr - 044/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Meowstic - 045/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Meowstic - 045/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Honedge (46) - 046/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Honedge (46) - 046/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Honedge (47) - 047/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Honedge (47) - 047/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Doublade - 048/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Doublade - 048/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Aegislash - 049/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Aegislash - 049/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Inkay - 050/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Inkay - 050/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Malamar - 051/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Malamar - 051/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Skrelp - 052/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Skrelp - 052/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Dragalge - 053/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Dragalge - 053/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Hoopa - 054/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Hoopa - 054/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Poipole - 055/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Poipole - 055/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Naganadel GX - 056/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Cubone - 057/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Cubone - 057/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Torterra - 058/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Torterra - 058/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Infernape - 059/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Infernape - 059/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Gible - 060/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Gible - 060/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Gabite - 061/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Gabite - 061/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Garchomp - 062/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Garchomp - 062/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Croagunk - 063/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Croagunk - 063/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Toxicroak - 064/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Toxicroak - 064/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Pancham - 065/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Pancham - 065/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Binacle - 066/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Binacle - 066/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Barbaracle - 067/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Barbaracle - 067/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Tyrunt - 068/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Tyrunt - 068/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Tyrantrum - 069/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Tyrantrum - 069/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Hawlucha - 070/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Hawlucha - 070/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Zygarde (71) - 071/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Zygarde (71) - 071/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Zygarde (72) - 072/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Zygarde (72) - 072/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Zygarde GX - 073/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Diancie Prism Star - 074/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Rockruff - 075/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Rockruff - 075/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Lycanroc - 076/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Lycanroc - 076/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Buzzwole - 077/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Buzzwole - 077/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Pangoro - 078/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Pangoro - 078/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Yveltal GX - 079/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Guzzlord - 080/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Guzzlord - 080/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Empoleon - 081/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Empoleon - 081/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Dialga GX - 082/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Flabebe (83) - 083/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Flabebe (83) - 083/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Flabebe (84) - 084/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Flabebe (84) - 084/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Floette - 085/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Floette - 085/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Florges - 086/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Florges - 086/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Sylveon - 087/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Sylveon - 087/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Dedenne - 088/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Dedenne - 088/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Klefki - 089/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Klefki - 089/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Xerneas GX - 090/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Goomy (91) - 091/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Goomy (91) - 091/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Goomy (92) - 092/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Goomy (92) - 092/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Sliggoo - 093/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Sliggoo - 093/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Goodra - 094/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Goodra - 094/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Ultra Necrozma GX - 095/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Arceus Prism Star - 096/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Bunnelby - 097/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Bunnelby - 097/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Diggersby - 098/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Diggersby - 098/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Furfrou - 099/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Furfrou - 099/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Noibat - 100/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Noibat - 100/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Noivern - 101/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Noivern - 101/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Beast Ring - 102/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Beast Ring - 102/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Bonnie - 103/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Bonnie - 103/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Crasher Wake - 104/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Crasher Wake - 104/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Diantha - 105/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Diantha - 105/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Eneporter - 106/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Eneporter - 106/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Fossil Excavation Map - 107/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Fossil Excavation Map - 107/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Judge - 108/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Judge - 108/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Lady - 109/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Lady - 109/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Lysandre Prism Star - 110/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Lysandre Labs - 111/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Lysandre Labs - 111/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Metal Frying Pan - 112/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Metal Frying Pan - 112/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Mysterious Treasure - 113/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Mysterious Treasure - 113/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Ultra Recon Squad - 114/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Ultra Recon Squad - 114/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Ultra Space - 115/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Ultra Space - 115/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Unidentified Fossil - 116/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Unidentified Fossil - 116/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Beast Energy Prism Star - 117/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Unit Energy FDY - 118/131 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Unit Energy FDY - 118/131 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Palkia GX (Full Art) - 119/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Greninja GX (Full Art) - 120/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Naganadel GX (Full Art) - 121/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Lucario GX (Full Art) - 122/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Zygarde GX (Full Art) - 123/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Yveltal GX (Full Art) - 124/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Dialga GX (Full Art) - 125/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Xerneas GX (Full Art) - 126/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Ultra Necrozma GX (Full Art) - 127/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Bonnie (Full Art) - 128/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Crasher Wake (Full Art) - 129/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Diantha (Full Art) - 130/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Ultra Recon Squad (Full Art) - 131/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Palkia GX (Secret) - 132/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Greninja GX (Secret) - 133/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Naganadel GX (Secret) - 134/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Lucario GX (Secret) - 135/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Zygarde GX (Secret) - 136/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Yveltal GX (Secret) - 137/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Dialga GX (Secret) - 138/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Xerneas GX (Secret) - 139/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Ultra Necrozma GX (Secret) - 140/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Beast Ring (Secret) - 141/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Eneporter (Secret) - 142/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Energy Recycler (Secret) - 143/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Metal Frying Pan (Secret) - 144/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Mysterious Treasure (Secret) - 145/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Forbidden Light - Unit Energy FDY (Secret) - 146/131 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Exeggcute - 001/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Exeggcute - 001/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Yanma - 002/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Yanma - 002/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Yanmega - 003/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Yanmega - 003/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Roselia - 004/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Roselia - 004/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Roserade - 005/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Roserade - 005/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Turtwig - 006/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Turtwig - 006/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Turtwig - 007/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Turtwig - 007/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Grotle - 008/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Grotle - 008/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Torterra - 009/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Torterra - 009/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Cherubi - 010/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Cherubi - 010/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Cherrim - 011/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Cherrim - 011/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Carnivine - 012/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Carnivine - 012/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Leafeon GX - 013/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Mow Rotom - 014/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Mow Rotom - 014/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Shaymin (15) - 015/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Shaymin (15) - 015/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Dewpider - 016/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Dewpider - 016/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Araquanid - 017/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Araquanid - 017/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Magmar - 018/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Magmar - 018/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Magmortar - 019/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Magmortar - 019/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Chimchar (20) - 020/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Chimchar (20) - 020/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Chimchar (21) - 021/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Chimchar (21) - 021/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Monferno - 022/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Monferno - 022/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Infernape - 023/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Infernape - 023/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Heat Rotom - 024/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Heat Rotom - 024/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Salandit - 025/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Salandit - 025/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Salazzle - 026/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Salazzle - 026/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Turtonator - 027/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Turtonator - 027/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Alolan Sandshrew - 028/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Alolan Sandshrew - 028/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Alolan Sandslash - 029/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Alolan Sandslash - 029/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Alolan Vulpix - 030/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Alolan Vulpix - 030/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Piplup (31) - 031/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Piplup (31) - 031/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Piplup (32) - 032/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Piplup (32) - 032/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Prinplup - 033/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Prinplup - 033/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Empoleon - 034/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Empoleon - 034/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Buizel - 035/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Buizel - 035/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Floatzel - 036/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Floatzel - 036/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Snover - 037/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Snover - 037/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Abomasnow - 038/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Abomasnow - 038/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Glaceon GX - 039/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Wash Rotom - 040/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Wash Rotom - 040/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Frost Rotom - 041/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Frost Rotom - 041/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Manaphy - 042/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Manaphy - 042/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Electabuzz - 043/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Electabuzz - 043/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Electivire - 044/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Electivire - 044/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Shinx (45) - 045/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Shinx (45) - 045/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Shinx (46) - 046/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Shinx (46) - 046/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Luxio - 047/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Luxio - 047/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Luxray - 048/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Luxray - 048/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Pachirisu - 049/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Pachirisu - 049/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Rotom - 050/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Rotom - 050/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Drifloon - 051/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Drifloon - 051/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Drifblim - 052/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Drifblim - 052/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Spiritomb - 053/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Spiritomb - 053/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Skorupi - 054/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Skorupi - 054/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Drapion - 055/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Drapion - 055/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Croagunk - 056/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Croagunk - 056/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Toxicroak - 057/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Toxicroak - 057/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Giratina Prism Star - 058/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Cresselia - 059/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Cresselia - 059/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Cosmog - 060/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Cosmog - 060/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Cosmoem - 061/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Cosmoem - 061/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Lunala Prism Star - 062/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Dawn Wings Necrozma GX - 063/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Cranidos - 064/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Cranidos - 064/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Rampardos - 065/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Rampardos - 065/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Riolu - 066/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Riolu - 066/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Lucario - 067/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Lucario - 067/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Hippopotas - 068/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Hippopotas - 068/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Hippowdon - 069/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Hippowdon - 069/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Passimian - 070/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Passimian - 070/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Murkrow - 071/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Murkrow - 071/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Honchkrow - 072/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Honchkrow - 072/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Sneasel - 073/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Sneasel - 073/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Weavile - 074/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Weavile - 074/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Stunky - 075/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Stunky - 075/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Skuntank - 076/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Skuntank - 076/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Darkrai Prism Star - 077/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Alolan Diglett - 078/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Alolan Diglett - 078/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Alolan Dugtrio - 079/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Alolan Dugtrio - 079/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Magnemite (80) - 080/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Magnemite (80) - 080/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Magnemite (81) - 081/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Magnemite (81) - 081/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Magneton - 082/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Magneton - 082/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Magnezone - 083/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Magnezone - 083/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Shieldon - 084/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Shieldon - 084/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Bastiodon - 085/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Bastiodon - 085/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Bronzor - 086/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Bronzor - 086/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Bronzong - 087/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Bronzong - 087/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Heatran - 088/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Heatran - 088/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Solgaleo Prism Star - 089/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Dusk Mane Necrozma GX - 090/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Magearna - 091/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Magearna - 091/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Morelull - 092/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Morelull - 092/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Shiinotic - 093/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Shiinotic - 093/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Tapu Lele - 094/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Tapu Lele - 094/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Alolan Exeggutor - 095/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Alolan Exeggutor - 095/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Gible (96) - 096/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Gible (96) - 096/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Gible (97) - 097/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Gible (97) - 097/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Gabite - 098/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Gabite - 098/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Garchomp - 099/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Garchomp - 099/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Dialga GX - 100/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Palkia GX - 101/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Lickitung - 102/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Lickitung - 102/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Lickilicky - 103/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Lickilicky - 103/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Eevee (104) - 104/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Eevee (104) - 104/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Eevee (105) - 105/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Eevee (105) - 105/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Buneary - 106/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Buneary - 106/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Lopunny - 107/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Lopunny - 107/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Glameow - 108/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Glameow - 108/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Purugly - 109/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Purugly - 109/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Fan Rotom - 110/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Fan Rotom - 110/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Shaymin (111) - 111/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Shaymin (111) - 111/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Yungoos - 112/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Yungoos - 112/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Gumshoos - 113/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Gumshoos - 113/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Oranguru - 114/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Oranguru - 114/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Type: Null - 115/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Type: Null - 115/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Silvally GX - 116/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Drampa - 117/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Drampa - 117/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Ancient Crystal - 118/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Ancient Crystal - 118/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Cynthia - 119/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Cynthia - 119/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Cyrus Prism Star - 120/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Electric Memory - 121/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Electric Memory - 121/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Escape Board - 122/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Escape Board - 122/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Fire Memory - 123/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Fire Memory - 123/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Gardenia - 124/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Gardenia - 124/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Lillie - 125/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Lillie - 125/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Looker - 126/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Looker - 126/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Looker Whistle - 127/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Looker Whistle - 127/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Mars - 128/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Mars - 128/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Missing Clover - 129/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Missing Clover - 129/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Mt. Coronet - 130/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Mt. Coronet - 130/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Order Pad - 131/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Order Pad - 131/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Pal Pad - 132/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Pal Pad - 132/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Pokemon Fan Club - 133/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Pokemon Fan Club - 133/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Unidentified Fossil - 134/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Unidentified Fossil - 134/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Volkner - 135/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Volkner - 135/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Super Boost Energy Prism Star - 136/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Unit Energy GRW - 137/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Unit Energy GRW - 137/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Unit Energy LPM - 138/156 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Unit Energy LPM - 138/156 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Leafeon GX (Full Art) - 139/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Pheromosa GX (Full Art) - 140/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Glaceon GX (Full Art) - 141/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Xurkitree GX (Full Art) - 142/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Dawn Wings Necrozma GX (Full Art) - 143/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Celesteela GX (Full Art) - 144/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Dusk Mane Necrozma GX (Full Art) - 145/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Dialga GX (Full Art) - 146/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Palkia GX (Full Art) - 147/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Cynthia (Full Art) - 148/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Gardenia (Full Art) - 149/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Lana (Full Art) - 150/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Lillie (Full Art) - 151/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Looker (Full Art) - 152/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Lusamine (Full Art) - 153/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Mars (Full Art) - 154/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Pokemon Fan Club (Full Art) - 155/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Volkner (Full Art) - 156/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Leafeon GX (Secret Rare) - 157/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Pheromosa GX (Secret Rare) - 158/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Glaceon GX (Secret Rare) - 159/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Xurkitree GX (Secret Rare) - 160/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Dawn Wings Necrozma GX (Secret Rare) - 161/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Celesteela GX (Secret Rare) - 162/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Dusk Mane Necrozma GX (Secret Rare) - 163/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Dialga GX (Secret Rare) - 164/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Palkia GX (Secret Rare) - 165/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Crushing Hammer (Secret Rare) - 166/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Escape Board (Secret Rare) - 167/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Missing Clover (Secret Rare) - 168/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Peeking Red Card (Secret Rare) - 169/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Unit Energy GRW (Secret Rare) - 170/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Unit Energy LPM (Secret Rare) - 171/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Lunala GX (Secret Rare) - 172/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ultra Prism - Solgaleo GX (Secret Rare) - 173/156 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Weedle - 001/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Weedle - 001/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Kakuna - 002/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Kakuna - 002/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Beedrill - 003/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Beedrill - 003/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Exeggcute - 004/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Exeggcute - 004/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Cacnea - 005/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Cacnea - 005/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Cacturne - 006/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Cacturne - 006/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Karrablast - 007/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Karrablast - 007/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Shelmet - 008/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Shelmet - 008/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Accelgor - 009/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Accelgor - 009/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Skiddo - 010/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Skiddo - 010/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Gogoat - 011/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Gogoat - 011/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Alolan Marowak - 012/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Alolan Marowak - 012/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Numel - 013/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Numel - 013/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Camerupt - 014/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Camerupt - 014/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Staryu - 015/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Staryu - 015/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Starmie - 016/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Starmie - 016/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Magikarp - 017/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Magikarp - 017/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Gyarados GX - 018/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Swinub - 019/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Swinub - 019/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Piloswine - 020/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Piloswine - 020/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Mamoswine - 021/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Mamoswine - 021/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Remoraid - 022/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Remoraid - 022/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Octillery - 023/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Octillery - 023/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Corphish - 024/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Corphish - 024/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Crawdaunt - 025/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Crawdaunt - 025/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Feebas - 026/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Feebas - 026/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Milotic - 027/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Milotic - 027/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Regice - 028/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Regice - 028/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Shellos - 029/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Shellos - 029/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Pikachu - 030/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Pikachu - 030/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Alolan Raichu - 031/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Alolan Raichu - 031/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Alolan Geodude - 032/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Alolan Geodude - 032/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Alolan Graveler - 033/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Alolan Graveler - 033/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Alolan Golem GX - 034/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Emolga - 035/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Emolga - 035/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Gastly - 036/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Gastly - 036/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Haunter - 037/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Haunter - 037/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Gengar - 038/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Gengar - 038/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Misdreavus - 039/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Misdreavus - 039/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Mismagius - 040/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Mismagius - 040/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Spoink - 041/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Spoink - 041/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Grumpig - 042/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Grumpig - 042/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Chimecho - 043/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Chimecho - 043/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Pumpkaboo - 044/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Pumpkaboo - 044/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Gourgeist - 045/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Gourgeist - 045/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Salandit - 046/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Salandit - 046/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Salazzle - 047/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Salazzle - 047/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Oranguru - 048/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Oranguru - 048/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Nihilego GX - 049/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Mankey - 050/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Mankey - 050/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Primeape - 051/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Primeape - 051/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Cubone - 052/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Cubone - 052/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Regirock - 053/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Regirock - 053/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Gastrodon - 054/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Gastrodon - 054/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Stufful - 055/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Stufful - 055/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Bewear - 056/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Bewear - 056/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Buzzwole GX - 057/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Houndour - 058/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Houndour - 058/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Houndoom - 059/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Houndoom - 059/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Deino - 060/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Deino - 060/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Zweilous - 061/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Zweilous - 061/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Hydreigon - 062/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Hydreigon - 062/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Guzzlord GX - 063/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Mawile - 064/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Mawile - 064/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Aron - 065/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Aron - 065/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Lairon - 066/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Lairon - 066/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Aggron - 067/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Aggron - 067/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Registeel - 068/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Registeel - 068/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Escavalier - 069/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Escavalier - 069/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Kartana GX - 070/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Jigglypuff - 071/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Jigglypuff - 071/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Wigglytuff - 072/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Wigglytuff - 072/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Xerneas - 073/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Xerneas - 073/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Alolan Exeggutor GX - 074/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Jangmo-o - 075/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Jangmo-o - 075/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Hakamo-o - 076/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Hakamo-o - 076/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Kommo-o - 077/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Kommo-o - 077/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Miltank - 078/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Miltank - 078/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Swablu - 079/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Swablu - 079/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Altaria - 080/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Altaria - 080/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Starly - 081/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Starly - 081/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Staravia - 082/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Staravia - 082/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Staraptor - 083/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Staraptor - 083/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Regigigas - 084/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Regigigas - 084/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Minccino - 085/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Minccino - 085/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Cinccino - 086/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Cinccino - 086/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Bunnelby - 087/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Bunnelby - 087/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Diggersby - 088/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Diggersby - 088/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Type: Null - 089/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Type: Null - 089/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Silvally GX - 090/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Counter Catcher - 091/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Counter Catcher - 091/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Dashing Pouch - 092/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Dashing Pouch - 092/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Devoured Field - 093/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Devoured Field - 093/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Fighting Memory - 094/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Fighting Memory - 094/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Gladion - 095/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Gladion - 095/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Lusamine - 096/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Lusamine - 096/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Peeking Red Card - 097/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Peeking Red Card - 097/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Psychic Memory - 098/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Psychic Memory - 098/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Sea of Nothingness - 099/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Sea of Nothingness - 099/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Counter Energy - 100/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Counter Energy - 100/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Gyarados GX (Full Art) - 101/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Alolan Golem GX (Full Art) - 102/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Nihilego GX (Full Art) - 103/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Buzzwole GX (Full Art) - 104/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Guzzlord GX (Full Art) - 105/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Kartana GX (Full Art) - 106/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Alolan Exeggutor GX (Full Art) - 107/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Silvally GX (Full Art) - 108/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Gladion (Full Art) - 109/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Lusamine (Full Art) - 110/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Olivia (Full Art) - 111/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Gyarados GX (Secret) - 112/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Alolan Golem GX (Secret) - 113/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Nihilego GX (Secret) - 114/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Buzzwole GX (Secret) - 115/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Guzzlord GX (Secret) - 116/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Kartana GX (Secret) - 117/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Alolan Exeggutor GX (Secret) - 118/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Silvally GX (Secret) - 119/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Counter Catcher (Secret) - 120/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Wishful Baton (Secret) - 121/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Counter Energy (Secret) - 122/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Warp Energy (Secret) - 123/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crimson Invasion - Water Energy (Secret) - 124/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Bulbasaur - 001/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Bulbasaur - 001/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Ivysaur - 002/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Ivysaur - 002/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Venusaur - 003/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Venusaur - 003/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Shroomish - 004/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Shroomish - 004/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Breloom - 005/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Breloom - 005/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Carnivine - 006/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Carnivine - 006/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Shaymin - 007/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Shaymin - 007/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Virizion - 008/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Virizion - 008/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Shining Genesect - 009/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Entei GX - 010/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Torkoal - 011/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Torkoal - 011/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Larvesta - 012/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Larvesta - 012/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Volcarona - 013/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Volcarona - 013/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Reshiram - 014/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Reshiram - 014/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Litten - 015/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Litten - 015/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Torracat - 016/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Torracat - 016/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Incineroar - 017/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Incineroar - 017/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Totodile - 018/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Totodile - 018/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Croconaw - 019/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Croconaw - 019/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Feraligatr - 020/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Feraligatr - 020/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Qwilfish - 021/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Qwilfish - 021/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Buizel - 022/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Buizel - 022/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Floatzel - 023/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Floatzel - 023/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Palkia - 024/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Palkia - 024/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Manaphy - 025/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Manaphy - 025/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Keldeo - 026/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Keldeo - 026/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Shining Volcanion - 027/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Pikachu - 028/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Pikachu - 028/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Raichu GX - 029/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Voltorb - 030/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Voltorb - 030/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Electrode - 031/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Electrode - 031/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Raikou - 032/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Raikou - 032/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Plusle - 033/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Plusle - 033/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Minun - 034/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Minun - 034/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Zekrom - 035/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Zekrom - 035/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Ekans - 036/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Ekans - 036/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Arbok - 037/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Arbok - 037/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Jynx - 038/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Jynx - 038/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Mewtwo GX - 039/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Shining Mew - 040/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Latios - 041/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Latios - 041/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Shining Jirachi - 042/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Golett - 043/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Golett - 043/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Golurk - 044/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Golurk - 044/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Marshadow - 045/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Marshadow - 045/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Stunfisk - 046/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Stunfisk - 046/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Spiritomb - 047/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Spiritomb - 047/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Purrloin - 048/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Purrloin - 048/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Liepard - 049/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Liepard - 049/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Scraggy - 050/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Scraggy - 050/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Scrafty - 051/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Scrafty - 051/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Zorua - 052/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Zorua - 052/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Zoroark GX - 053/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Yveltal - 054/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Yveltal - 054/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Hoopa - 055/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Hoopa - 055/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Shining Rayquaza - 056/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Shining Arceus - 057/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Damage Mover - 058/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Damage Mover - 058/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Energy Retrieval - 059/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Energy Retrieval - 059/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Great Ball - 060/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Great Ball - 060/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Hau - 061/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Hau - 061/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Lillie - 062/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Lillie - 062/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Pokemon Breeder - 063/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Pokemon Breeder - 063/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Pokemon Catcher - 064/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Pokemon Catcher - 064/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Sophocles - 065/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Sophocles - 065/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Super Scoop Up - 066/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Super Scoop Up - 066/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Switch - 067/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Switch - 067/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Ultra Ball - 068/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Ultra Ball - 068/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Double Colorless Energy - 069/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Double Colorless Energy - 069/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Warp Energy - 070/73 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Warp Energy - 070/73 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Entei GX (Full Art) - 071/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Mewtwo GX (Full Art) - 072/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Pokemon Breeder (Full Art) - 073/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Entei GX (Secret) - 074/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Raichu GX (Secret) - 075/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Mewtwo GX (Secret) - 076/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Zoroark GX (Secret) - 077/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shining Legends - Mewtwo GX (Secret Shining) - 078/73 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Caterpie - 001/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Caterpie - 001/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Metapod - 002/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Metapod - 002/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Butterfree - 003/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Butterfree - 003/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Oddish - 004/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Oddish - 004/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Gloom - 005/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Gloom - 005/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Vileplume - 006/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Vileplume - 006/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Tangela - 007/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Tangela - 007/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Tangrowth - 008/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Tangrowth - 008/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Ledyba - 009/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Ledyba - 009/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Ledian - 010/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Ledian - 010/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Heracross - 011/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Heracross - 011/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Pansage - 012/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Pansage - 012/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Simisage - 013/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Simisage - 013/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Dewpider - 014/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Dewpider - 014/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Araquanid - 015/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Araquanid - 015/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Wimpod - 016/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Wimpod - 016/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Golisopod GX - 017/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Charmander - 018/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Charmander - 018/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Charmeleon - 019/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Charmeleon - 019/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Charizard GX - 020/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Ho-Oh GX - 021/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Pansear - 022/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Pansear - 022/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Simisear - 023/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Simisear - 023/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Heatmor - 024/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Heatmor - 024/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Salazzle GX - 025/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Turtonator - 026/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Turtonator - 026/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Alolan Vulpix - 027/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Alolan Vulpix - 027/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Alolan Ninetales - 028/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Alolan Ninetales - 028/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Horsea - 029/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Horsea - 029/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Seadra - 030/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Seadra - 030/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Kingdra - 031/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Kingdra - 031/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Magikarp - 032/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Magikarp - 032/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Gyarados - 033/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Gyarados - 033/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Marill - 034/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Marill - 034/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Azumarill - 035/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Azumarill - 035/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Panpour - 036/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Panpour - 036/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Simipour - 037/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Simipour - 037/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Bruxish - 038/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Bruxish - 038/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Tapu Fini GX - 039/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Pikachu - 040/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Pikachu - 040/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Raichu - 041/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Raichu - 041/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Electabuzz - 042/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Electabuzz - 042/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Electivire - 043/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Electivire - 043/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Tynamo - 044/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Tynamo - 044/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Eelektrik - 045/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Eelektrik - 045/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Eelektross - 046/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Eelektross - 046/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Togedemaru - 047/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Togedemaru - 047/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Slowking - 048/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Slowking - 048/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Wobbuffet - 049/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Wobbuffet - 049/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Seviper - 050/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Seviper - 050/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Duskull - 051/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Duskull - 051/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Dusclops - 052/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Dusclops - 052/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Dusknoir - 053/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Dusknoir - 053/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Croagunk - 054/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Croagunk - 054/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Toxicroak - 055/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Toxicroak - 055/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Venipede - 056/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Venipede - 056/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Whirlipede - 057/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Whirlipede - 057/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Scolipede - 058/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Scolipede - 058/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Espurr - 059/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Espurr - 059/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Meowstic - 060/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Meowstic - 060/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Sandygast - 061/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Sandygast - 061/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Palossand - 062/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Palossand - 062/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Necrozma GX - 063/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Machamp GX - 064/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Rhyhorn - 065/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Rhyhorn - 065/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Rhydon - 066/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Rhydon - 066/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Rhyperior - 067/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Rhyperior - 067/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Lunatone - 068/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Lunatone - 068/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Solrock - 069/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Solrock - 069/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Riolu - 070/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Riolu - 070/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Lucario - 071/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Lucario - 071/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Sawk - 072/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Sawk - 072/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Crabrawler - 073/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Crabrawler - 073/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Crabominable - 074/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Crabominable - 074/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Lycanroc (75) - 075/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Lycanroc (75) - 075/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Lycanroc (76) - 076/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Lycanroc (76) - 076/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Mudbray - 077/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Mudbray - 077/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Mudsdale - 078/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Mudsdale - 078/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Passimian - 079/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Passimian - 079/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Marshadow GX - 080/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Alolan Rattata - 081/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Alolan Rattata - 081/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Alolan Raticate - 082/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Alolan Raticate - 082/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Alolan Grimer - 083/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Alolan Grimer - 083/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Alolan Muk GX - 084/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Sneasel - 085/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Sneasel - 085/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Weavile - 086/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Weavile - 086/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Darkrai - 087/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Darkrai - 087/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Darkrai GX - 088/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Inkay - 089/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Inkay - 089/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Malamar - 090/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Malamar - 090/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Ralts - 091/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Ralts - 091/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Kirlia - 092/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Kirlia - 092/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Gardevoir GX - 093/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Diancie - 094/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Diancie - 094/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Cutiefly - 095/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Cutiefly - 095/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Ribombee - 096/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Ribombee - 096/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Morelull - 097/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Morelull - 097/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Shiinotic - 098/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Shiinotic - 098/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Noivern GX - 099/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Zygarde - 100/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Zygarde - 100/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Meowth - 101/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Meowth - 101/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Persian - 102/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Persian - 102/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Porygon - 103/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Porygon - 103/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Porygon2 - 104/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Porygon2 - 104/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Porygon-Z - 105/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Porygon-Z - 105/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Hoothoot - 106/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Hoothoot - 106/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Noctowl - 107/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Noctowl - 107/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Bouffalant - 108/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Bouffalant - 108/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Noibat - 109/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Noibat - 109/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Stufful - 110/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Stufful - 110/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Bewear - 111/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Bewear - 111/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Acerola - 112/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Acerola - 112/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Bodybuilding Dumbbells - 113/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Bodybuilding Dumbbells - 113/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Escape Rope - 114/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Escape Rope - 114/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Guzma - 115/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Guzma - 115/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Kiawe - 116/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Kiawe - 116/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Lana - 117/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Lana - 117/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Mount Lanakila - 118/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Mount Lanakila - 118/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Olivia - 119/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Olivia - 119/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Plumeria - 120/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Plumeria - 120/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Po Town - 121/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Po Town - 121/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Rotom Dex Poke Finder Mode - 122/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Rotom Dex Poke Finder Mode - 122/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Sophocles - 123/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Sophocles - 123/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Super Scoop Up - 124/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Super Scoop Up - 124/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Tormenting Spray - 125/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Tormenting Spray - 125/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Weakness Policy - 126/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Weakness Policy - 126/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Wicke - 127/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Wicke - 127/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Wishful Baton - 128/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Wishful Baton - 128/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Golisopod GX (Full Art) - 129/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Tapu Bulu GX (Full Art) - 130/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Ho-Oh GX (Full Art) - 131/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Salazzle GX (Full Art) - 132/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Tapu Fini GX (Full Art) - 133/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Necrozma GX (Full Art) - 134/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Machamp GX (Full Art) - 135/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Lycanroc GX (Full Art) - 136/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Marshadow GX (Full Art) - 137/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Alolan Muk GX (Full Art) - 138/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Darkrai GX (Full Art) - 139/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Gardevoir GX (Full Art) - 140/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Noivern GX (Full Art) - 141/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Acerola (Full Art) - 142/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Guzma (Full Art) - 143/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Kiawe (Full Art) - 144/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Plumeria (Full Art) - 145/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Sophocles (Full Art) - 146/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Wicke (Full Art) - 147/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Golisopod GX (Secret) - 148/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Tapu Bulu GX (Secret) - 149/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Charizard GX (Secret) - 150/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Salazzle GX (Secret) - 151/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Tapu Fini GX (Secret) - 152/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Necrozma GX (Secret) - 153/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Machamp GX (Secret) - 154/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Lycanroc GX (Secret) - 155/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Marshadow GX (Secret) - 156/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Alolan Muk GX (Secret) - 157/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Darkrai GX (Secret) - 158/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Gardevoir GX (Secret) - 159/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Noivern GX (Secret) - 160/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Bodybuilding Dumbbells (Secret) - 161/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Choice Band (Secret) - 162/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Escape Rope (Secret) - 163/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Multi Switch (Secret) - 164/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Rescue Stretcher (Secret) - 165/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Super Scoop Up (Secret) - 166/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Fire Energy (Secret) - 167/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Darkness Energy (Secret) - 168/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Burning Shadows - Fairy Energy (Secret) - 169/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Bellsprout - 001/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Bellsprout - 001/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Weepinbell - 002/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Weepinbell - 002/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Victreebel - 003/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Victreebel - 003/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Petilil - 004/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Petilil - 004/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Lilligant - 005/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Lilligant - 005/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Phantump - 006/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Phantump - 006/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Trevenant - 007/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Trevenant - 007/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Wimpod - 008/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Wimpod - 008/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Golisopod - 009/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Golisopod - 009/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Victini - 010/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Victini - 010/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Litwick - 011/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Litwick - 011/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Lampent - 012/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Lampent - 012/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Chandelure - 013/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Chandelure - 013/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Oricorio (14) - 014/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Oricorio (14) - 014/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Salandit - 015/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Salandit - 015/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Salazzle - 016/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Salazzle - 016/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Turtonator - 017/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Turtonator - 017/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Turtonator GX - 018/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Alolan Sandshrew - 019/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Alolan Sandshrew - 019/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Alolan Sandslash - 020/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Alolan Sandslash - 020/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Alolan Vulpix - 021/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Alolan Vulpix - 021/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Alolan Ninetales GX - 022/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Tentacool - 023/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Tentacool - 023/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Tentacruel - 024/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Tentacruel - 024/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Politoed - 025/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Politoed - 025/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Delibird - 026/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Delibird - 026/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Carvanha - 027/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Carvanha - 027/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Sharpedo - 028/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Sharpedo - 028/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Wailmer - 029/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Wailmer - 029/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Wailord - 030/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Wailord - 030/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Snorunt - 031/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Snorunt - 031/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Glalie - 032/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Glalie - 032/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Vanillite - 033/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Vanillite - 033/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Vanillish - 034/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Vanillish - 034/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Vanilluxe - 035/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Vanilluxe - 035/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Alomomola - 036/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Alomomola - 036/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Wishiwashi - 037/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Wishiwashi - 037/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Wishiwashi GX - 038/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Mareanie - 039/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Mareanie - 039/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Alolan Geodude - 040/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Alolan Geodude - 040/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Alolan Graveler - 041/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Alolan Graveler - 041/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Alolan Golem - 042/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Alolan Golem - 042/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Helioptile - 043/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Helioptile - 043/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Heliolisk - 044/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Heliolisk - 044/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Vikavolt GX - 045/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Oricorio (46) - 046/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Oricorio (46) - 046/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Tapu Koko GX - 047/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Slowpoke - 048/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Slowpoke - 048/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Slowbro - 049/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Slowbro - 049/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Trubbish - 050/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Trubbish - 050/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Garbodor - 051/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Garbodor - 051/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Gothita - 052/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Gothita - 052/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Gothorita - 053/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Gothorita - 053/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Gothitelle - 054/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Gothitelle - 054/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Oricorio (55) - 055/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Oricorio (55) - 055/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Oricorio (56) - 056/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Oricorio (56) - 056/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Toxapex GX - 057/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Mimikyu - 058/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Mimikyu - 058/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Dhelmise - 059/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Dhelmise - 059/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Tapu Lele GX - 060/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Lunala - 061/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Lunala - 061/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Machop (62) - 062/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Machop (62) - 062/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Machop (63) - 063/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Machop (63) - 063/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Machoke - 064/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Machoke - 064/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Machamp - 065/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Machamp - 065/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Sudowoodo - 066/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Sudowoodo - 066/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Gligar - 067/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Gligar - 067/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Gliscor - 068/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Gliscor - 068/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Nosepass - 069/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Nosepass - 069/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Barboach - 070/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Barboach - 070/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Whiscash - 071/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Whiscash - 071/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Pancham - 072/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Pancham - 072/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Rockruff - 073/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Rockruff - 073/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Lycanroc GX - 074/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Mudbray - 075/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Mudbray - 075/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Mudsdale - 076/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Mudsdale - 076/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Minior - 077/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Minior - 077/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Murkrow - 078/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Murkrow - 078/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Honchkrow - 079/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Honchkrow - 079/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Sableye - 080/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Sableye - 080/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Absol - 081/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Absol - 081/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Pangoro - 082/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Pangoro - 082/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Beldum - 083/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Beldum - 083/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Metang - 084/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Metang - 084/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Metagross GX - 085/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Probopass - 086/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Probopass - 086/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Solgaleo - 087/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Solgaleo - 087/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Clefairy - 088/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Clefairy - 088/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Clefable - 089/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Clefable - 089/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Cottonee - 090/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Cottonee - 090/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Whimsicott - 091/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Whimsicott - 091/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Sylveon GX - 092/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Comfey - 093/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Comfey - 093/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Goomy - 094/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Goomy - 094/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Sliggoo - 095/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Sliggoo - 095/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Goodra - 096/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Goodra - 096/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Drampa - 097/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Drampa - 097/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Jangmo-o - 098/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Jangmo-o - 098/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Hakamo-o - 099/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Hakamo-o - 099/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Kommo-o GX - 100/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Chansey - 101/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Chansey - 101/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Blissey - 102/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Blissey - 102/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Taillow - 103/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Taillow - 103/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Swellow - 104/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Swellow - 104/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Castform - 105/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Castform - 105/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Rayquaza - 106/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Rayquaza - 106/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Patrat - 107/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Patrat - 107/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Watchog - 108/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Watchog - 108/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Fletchling - 109/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Fletchling - 109/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Fletchinder - 110/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Fletchinder - 110/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Talonflame - 111/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Talonflame - 111/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Stufful - 112/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Stufful - 112/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Bewear - 113/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Bewear - 113/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Komala - 114/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Komala - 114/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Drampa GX - 115/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Aether Paradise Conservation Area - 116/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Aether Paradise Conservation Area - 116/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Altar of the Moone - 117/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Altar of the Moone - 117/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Altar of the Sunne - 118/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Altar of the Sunne - 118/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Aqua Patch - 119/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Aqua Patch - 119/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Brooklet Hill - 120/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Brooklet Hill - 120/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Choice Band - 121/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Choice Band - 121/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Energy Loto - 122/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Energy Loto - 122/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Energy Recycler - 123/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Energy Recycler - 123/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Enhanced Hammer - 124/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Enhanced Hammer - 124/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Field Blower - 125/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Field Blower - 125/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Hala - 126/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Hala - 126/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Mallow - 127/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Mallow - 127/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Max Potion - 128/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Max Potion - 128/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Multi Switch - 129/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Multi Switch - 129/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Rescue Stretcher - 130/145 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Rescue Stretcher - 130/145 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Turtonator GX (Full Art) - 131/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Alolan Ninetales GX (Full Art) - 132/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Wishiwashi GX (Full Art) - 133/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Vikavolt GX (Full Art) - 134/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Tapu Koko GX (Full Art) - 135/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Toxapex GX (Full Art) - 136/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Tapu Lele GX (Full Art) - 137/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Lycanroc GX (Full Art) - 138/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Metagross GX (Full Art) - 139/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Sylveon GX (Full Art) - 140/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Kommo-o GX (Full Art) - 141/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Drampa GX (Full Art) - 142/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Hala (Full Art) - 143/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Hau (Full Art) - 144/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Mallow (Full Art) - 145/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Decidueye GX (Secret) - 146/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Incineroar GX (Secret) - 147/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Turtonator GX (Secret) - 148/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Primarina GX (Secret) - 149/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Alolan Ninetales GX (Secret) - 150/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Wishiwashi GX (Secret) - 151/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Vikavolt GX (Secret) - 152/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Tapu Koko GX (Secret) - 153/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Toxapex GX (Secret) - 154/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Tapu Lele GX (Secret) - 155/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Lycanroc GX (Secret) - 156/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Metagross GX (Secret) - 157/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Sylveon GX (Secret) - 158/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Kommo-o GX (Secret) - 159/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Drampa GX (Secret) - 160/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Aqua Patch (Secret) - 161/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Enhanced Hammer (Secret) - 162/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Field Blower (Secret) - 163/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Max Potion (Secret) - 164/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Rare Candy (Secret) - 165/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Double Colorless Energy (Secret) - 166/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Grass Energy (Secret) - 167/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Lightning Energy (Secret) - 168/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Guardians Rising - Fighting Energy (Secret) - 169/145 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Caterpie - 001/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Caterpie - 001/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Metapod - 002/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Metapod - 002/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Butterfree - 003/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Butterfree - 003/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Paras - 004/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Paras - 004/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Parasect - 005/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Parasect - 005/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Pinsir - 006/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Pinsir - 006/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Surskit - 007/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Surskit - 007/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Masquerain - 008/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Masquerain - 008/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Rowlet - 009/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Rowlet - 009/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Dartrix - 010/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Dartrix - 010/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Decidueye - 011/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Decidueye - 011/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Decidueye GX - 012/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Grubbin - 013/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Grubbin - 013/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Fomantis - 014/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Fomantis - 014/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Lurantis GX - 015/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Morelull - 016/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Morelull - 016/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Shiinotic - 017/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Shiinotic - 017/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Bounsweet - 018/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Bounsweet - 018/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Steenee - 019/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Steenee - 019/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Tsareena - 020/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Tsareena - 020/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Growlithe - 021/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Growlithe - 021/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Arcanine - 022/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Arcanine - 022/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Torkoal - 023/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Torkoal - 023/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Litten - 024/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Litten - 024/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Torracat - 025/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Torracat - 025/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Incineroar - 026/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Incineroar - 026/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Incineroar GX - 027/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Psyduck - 028/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Psyduck - 028/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Golduck - 029/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Golduck - 029/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Poliwag - 030/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Poliwag - 030/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Poliwhirl - 031/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Poliwhirl - 031/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Poliwrath - 032/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Poliwrath - 032/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Shellder - 033/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Shellder - 033/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Cloyster - 034/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Cloyster - 034/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Lapras GX - 035/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Corsola - 036/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Corsola - 036/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Wingull - 037/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Wingull - 037/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Pelipper - 038/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Pelipper - 038/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Popplio - 039/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Popplio - 039/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Brionne - 040/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Brionne - 040/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Primarina - 041/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Primarina - 041/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Primarina GX - 042/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Crabominable - 043/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Crabominable - 043/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Wishiwashi - 044/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Wishiwashi - 044/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Dewpider - 045/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Dewpider - 045/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Araquanid - 046/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Araquanid - 046/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Pyukumuku - 047/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Pyukumuku - 047/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Bruxish - 048/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Bruxish - 048/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Chinchou - 049/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Chinchou - 049/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Lanturn - 050/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Lanturn - 050/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Charjabug - 051/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Charjabug - 051/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Vikavolt - 052/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Vikavolt - 052/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Togedemaru - 053/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Togedemaru - 053/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Zubat - 054/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Zubat - 054/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Golbat - 055/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Golbat - 055/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Crobat - 056/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Crobat - 056/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Alolan Grimer - 057/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Alolan Grimer - 057/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Alolan Muk - 058/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Alolan Muk - 058/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Drowzee - 059/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Drowzee - 059/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Hypno - 060/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Hypno - 060/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Espeon GX - 061/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Mareanie - 062/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Mareanie - 062/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Toxapex - 063/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Toxapex - 063/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Cosmog - 064/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Cosmog - 064/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Cosmoem - 065/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Cosmoem - 065/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Lunala GX - 066/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Makuhita - 067/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Makuhita - 067/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Hariyama - 068/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Hariyama - 068/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Roggenrola - 069/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Roggenrola - 069/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Boldore - 070/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Boldore - 070/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Gigalith - 071/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Gigalith - 071/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Crabrawler - 072/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Crabrawler - 072/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Passimian - 073/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Passimian - 073/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Sandygast - 074/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Sandygast - 074/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Palossand - 075/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Palossand - 075/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Alolan Rattata - 076/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Alolan Rattata - 076/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Alolan Raticate - 077/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Alolan Raticate - 077/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Alolan Meowth - 078/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Alolan Meowth - 078/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Alolan Persian - 079/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Alolan Persian - 079/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Umbreon GX - 080/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Carvanha - 081/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Carvanha - 081/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Sharpedo - 082/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Sharpedo - 082/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Sandile - 083/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Sandile - 083/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Krokorok - 084/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Krokorok - 084/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Krookodile - 085/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Krookodile - 085/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Alolan Diglett - 086/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Alolan Diglett - 086/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Alolan Dugtrio - 087/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Alolan Dugtrio - 087/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Skarmory - 088/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Skarmory - 088/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Solgaleo GX - 089/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Snubbull - 090/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Snubbull - 090/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Granbull - 091/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Granbull - 091/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Cutiefly - 092/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Cutiefly - 092/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Ribombee - 093/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Ribombee - 093/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Dratini - 094/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Dratini - 094/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Dragonair - 095/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Dragonair - 095/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Dragonite - 096/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Dragonite - 096/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Spearow - 097/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Spearow - 097/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Fearow - 098/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Fearow - 098/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Kangaskhan - 099/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Kangaskhan - 099/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Tauros GX - 100/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Eevee - 101/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Eevee - 101/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Spinda - 102/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Spinda - 102/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Lillipup - 103/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Lillipup - 103/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Herdier - 104/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Herdier - 104/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Stoutland - 105/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Stoutland - 105/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Pikipek - 106/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Pikipek - 106/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Trumbeak - 107/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Trumbeak - 107/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Toucannon - 108/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Toucannon - 108/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Yungoos - 109/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Yungoos - 109/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Gumshoos GX - 110/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Stufful - 111/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Stufful - 111/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Bewear - 112/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Bewear - 112/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Oranguru - 113/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Oranguru - 113/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Big Malasada - 114/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Big Malasada - 114/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Crushing Hammer - 115/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Crushing Hammer - 115/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Energy Retrieval - 116/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Energy Retrieval - 116/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Energy Switch - 117/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Energy Switch - 117/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Exp. Share - 118/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Exp. Share - 118/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Great Ball - 119/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Great Ball - 119/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Hau - 120/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Hau - 120/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Ilima - 121/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Ilima - 121/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Lillie - 122/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Lillie - 122/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Nest Ball - 123/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Nest Ball - 123/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Poison Barb - 124/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Poison Barb - 124/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Poke Ball - 125/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Poke Ball - 125/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Pokemon Catcher - 126/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Pokemon Catcher - 126/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Potion - 127/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Potion - 127/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Professor Kukui - 128/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Professor Kukui - 128/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Rare Candy - 129/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Rare Candy - 129/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Repel - 130/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Repel - 130/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Rotom Dex - 131/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Rotom Dex - 131/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Switch - 132/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Switch - 132/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Team Skull Grunt - 133/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Team Skull Grunt - 133/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Timer Ball - 134/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Timer Ball - 134/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Ultra Ball - 135/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Ultra Ball - 135/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Double Colorless Energy - 136/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Double Colorless Energy - 136/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Rainbow Energy - 137/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Rainbow Energy - 137/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Lurantis GX (Full Art) - 138/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Lapras GX (Full Art) - 139/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Espeon GX (Full Art) - 140/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Lunala GX (Full Art) - 141/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Umbreon GX (Full Art) - 142/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Solgaleo GX (Full Art) - 143/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Tauros GX (Full Art) - 144/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Gumshoos GX (Full Art) - 145/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Ilima (Full Art) - 146/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Lillie (Full Art) - 147/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Professor Kukui (Full Art) - 148/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Team Skull Grunt (Full Art) - 149/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Lurantis GX (Secret) - 150/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Lapras GX (Secret) - 151/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Espeon GX (Secret) - 152/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Lunala GX (Secret) - 153/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Umbreon GX (Secret) - 154/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Solgaleo GX (Secret) - 155/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Tauros GX (Secret) - 156/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Gumshoos GX (Secret) - 157/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Nest Ball (Secret) - 158/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Rotom Dex (Secret) - 159/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Switch (Secret) - 160/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Ultra Ball (Secret) - 161/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Psychic Energy (Secret) - 162/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sun and Moon - Metal Energy (Secret) - 163/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Venusaur EX - 001/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - M Venusaur EX - 002/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Caterpie - 003/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Caterpie - 003/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Metapod - 004/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Metapod - 004/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Weedle - 005/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Weedle - 005/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Kakuna - 006/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Kakuna - 006/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Beedrill - 007/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Beedrill - 007/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Tangela - 008/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Tangela - 008/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Charmander - 009/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Charmander - 009/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Charmeleon - 010/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Charmeleon - 010/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Charizard - 011/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Charizard - 011/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Charizard EX - 012/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - M Charizard EX - 013/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Vulpix - 014/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Vulpix - 014/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Ninetales - 015/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Ninetales - 015/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Ninetales BREAK - 016/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Growlithe - 017/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Growlithe - 017/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Arcanine - 018/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Arcanine - 018/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Ponyta - 019/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Ponyta - 019/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Magmar - 020/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Magmar - 020/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Blastoise EX - 021/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - M Blastoise EX - 022/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Poliwag - 023/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Poliwag - 023/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Poliwhirl - 024/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Poliwhirl - 024/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Poliwrath - 025/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Poliwrath - 025/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Slowbro EX - 026/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - M Slowbro EX - 027/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Seel - 028/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Seel - 028/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Dewgong - 029/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Dewgong - 029/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Staryu - 030/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Staryu - 030/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Starmie - 031/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Starmie - 031/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Starmie BREAK - 032/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Magikarp - 033/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Magikarp - 033/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Gyarados - 034/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Gyarados - 034/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Pikachu - 035/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Pikachu - 035/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Raichu - 036/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Raichu - 036/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Magnemite - 037/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Magnemite - 037/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Magneton - 038/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Magneton - 038/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Voltorb - 039/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Voltorb - 039/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Electrode - 040/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Electrode - 040/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Electabuzz - 041/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Electabuzz - 041/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Zapdos - 042/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Zapdos - 042/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Nidoran M - 043/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Nidoran M - 043/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Nidorino - 044/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Nidorino - 044/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Nidoking - 045/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Nidoking - 045/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Nidoking BREAK - 046/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Gastly - 047/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Gastly - 047/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Haunter - 048/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Haunter - 048/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Drowzee - 049/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Drowzee - 049/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Koffing - 050/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Koffing - 050/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Mewtwo - 051/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Mewtwo - 051/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Mewtwo EX - 052/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Mew - 053/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Mew - 053/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Sandshrew - 054/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Sandshrew - 054/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Diglett - 055/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Diglett - 055/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Dugtrio - 056/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Dugtrio - 056/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Machop - 057/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Machop - 057/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Machoke - 058/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Machoke - 058/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Machamp - 059/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Machamp - 059/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Machamp BREAK - 060/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Onix - 061/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Onix - 061/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Hitmonchan - 062/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Hitmonchan - 062/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Clefairy - 063/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Clefairy - 063/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Pidgeot EX - 064/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - M Pidgeot EX - 065/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Rattata - 066/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Rattata - 066/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Raticate - 067/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Raticate - 067/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Farfetch'd - 068/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Farfetch'd - 068/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Doduo - 069/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Doduo - 069/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Chansey - 070/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Chansey - 070/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Porygon - 071/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Porygon - 071/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Dragonite EX - 072/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Blastoise Spirit Link - 073/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Blastoise Spirit Link - 073/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Brock's Grit - 074/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Brock's Grit - 074/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Charizard Spirit Link - 075/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Charizard Spirit Link - 075/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Devolution Spray - 076/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Devolution Spray - 076/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Energy Retrieval - 077/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Energy Retrieval - 077/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Full Heal - 078/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Full Heal - 078/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Maintenance - 079/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Maintenance - 079/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Misty's Determination - 080/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Misty's Determination - 080/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Pidgeot Spirit Link - 081/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Pidgeot Spirit Link - 081/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Pokedex - 082/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Pokedex - 082/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Potion - 083/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Potion - 083/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Professor Oak's Hint - 084/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Professor Oak's Hint - 084/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Revive - 085/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Revive - 085/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Slowbro Spirit Link - 086/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Slowbro Spirit Link - 086/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Super Potion - 087/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Super Potion - 087/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Switch - 088/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Switch - 088/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Venusaur Spirit Link - 089/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Venusaur Spirit Link - 089/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Double Colorless Energy - 090/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Double Colorless Energy - 090/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Grass Energy - 091/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Grass Energy - 091/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Fire Energy - 092/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Fire Energy - 092/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Water Energy - 093/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Water Energy - 093/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Lightning Energy - 094/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Lightning Energy - 094/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Psychic Energy - 095/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Psychic Energy - 095/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Fighting Energy - 096/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Fighting Energy - 096/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Darkness Energy - 097/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Darkness Energy - 097/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Metal Energy - 098/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Metal Energy - 098/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Fairy Energy - 099/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Fairy Energy - 099/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - M Venusaur EX (Full Art) - 100/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - M Charizard EX (Full Art) - 101/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - M Blastoise EX (Full Art) - 102/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Mewtwo EX (Full Art) - 103/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Pidgeot EX (Full Art) - 104/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - M Pidgeot EX (Full Art) - 105/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Dragonite EX (Full Art) - 106/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Brock's Grit (Full Art) - 107/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Misty's Determination (Full Art) - 108/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Exeggutor - 109/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Flying Pikachu - 110/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Surfing Pikachu - 111/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Imakuni's Doduo - 112/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Evolutions - Here Comes Team Rocket! - 113/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Tangela - 001/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Tangela - 001/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Tangrowth - 002/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Tangrowth - 002/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Hoppip - 003/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Hoppip - 003/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Skiploom - 004/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Skiploom - 004/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Jumpluff - 005/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Jumpluff - 005/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Yanma - 006/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Yanma - 006/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Yanmega - 007/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Yanmega - 007/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Yanmega BREAK - 008/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Seedot - 009/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Seedot - 009/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Nuzleaf - 010/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Nuzleaf - 010/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Shiftry - 011/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Shiftry - 011/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Foongus - 012/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Foongus - 012/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Amoonguss - 013/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Amoonguss - 013/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Larvesta - 014/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Larvesta - 014/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Volcarona - 015/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Volcarona - 015/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Ponyta - 016/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Ponyta - 016/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Rapidash - 017/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Rapidash - 017/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Chimchar - 018/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Chimchar - 018/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Monferno - 019/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Monferno - 019/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Infernape - 020/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Infernape - 020/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Talonflame BREAK - 021/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Litleo - 022/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Litleo - 022/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Pyroar - 023/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Pyroar - 023/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Pyroar BREAK - 024/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Volcanion - 025/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Volcanion - 025/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Volcanion EX - 026/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Mantine - 027/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Mantine - 027/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Shellos - 028/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Shellos - 028/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Gastrodon - 029/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Gastrodon - 029/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Oshawott - 030/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Oshawott - 030/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Dewott - 031/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Dewott - 031/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Samurott - 032/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Samurott - 032/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Clauncher - 033/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Clauncher - 033/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Clawitzer - 034/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Clawitzer - 034/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Clawitzer BREAK - 035/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Bergmite - 036/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Bergmite - 036/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Avalugg - 037/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Avalugg - 037/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Mareep - 038/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Mareep - 038/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Flaaffy - 039/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Flaaffy - 039/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Ampharos - 040/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Ampharos - 040/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Joltik - 041/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Joltik - 041/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Galvantula - 042/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Galvantula - 042/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Nidoran M - 043/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Nidoran M - 043/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Nidorino - 044/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Nidorino - 044/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Nidoking - 045/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Nidoking - 045/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Drifloon - 046/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Drifloon - 046/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Drifblim - 047/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Drifblim - 047/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Litwick - 048/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Litwick - 048/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Lampent - 049/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Lampent - 049/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Chandelure - 050/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Chandelure - 050/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Hoopa - 051/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Hoopa - 051/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Mankey - 052/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Mankey - 052/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Primeape - 053/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Primeape - 053/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Nosepass - 054/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Nosepass - 054/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Probopass - 055/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Probopass - 055/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Anorith - 056/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Anorith - 056/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Armaldo - 057/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Armaldo - 057/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Croagunk - 058/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Croagunk - 058/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Toxicroak - 059/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Toxicroak - 059/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Sneasel - 060/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Sneasel - 060/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Weavile - 061/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Weavile - 061/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Spiritomb - 062/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Spiritomb - 062/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Pawniard - 063/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Pawniard - 063/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Bisharp - 064/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Bisharp - 064/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Yveltal - 065/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Yveltal - 065/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Yveltal BREAK - 066/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Steelix EX - 067/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - M Steelix EX - 068/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Shieldon - 069/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Shieldon - 069/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Bastiodon - 070/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Bastiodon - 070/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Klink - 071/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Klink - 071/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Klang - 072/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Klang - 072/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Klinklang - 073/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Klinklang - 073/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Cobalion - 074/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Cobalion - 074/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Magearna EX - 075/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Marill - 076/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Marill - 076/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Azumarill - 077/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Azumarill - 077/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Gardevoir EX - 078/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - M Gardevoir EX - 079/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Klefki - 080/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Klefki - 080/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Xerneas - 081/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Xerneas - 081/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Xerneas BREAK - 082/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Druddigon - 083/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Druddigon - 083/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Deino - 084/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Deino - 084/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Zweilous - 085/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Zweilous - 085/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Hydreigon - 086/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Hydreigon - 086/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Hydreigon BREAK - 087/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Meowth - 088/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Meowth - 088/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Persian - 089/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Persian - 089/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Aipom - 090/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Aipom - 090/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Ambipom - 091/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Ambipom - 091/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Rufflet - 092/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Rufflet - 092/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Braviary - 093/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Braviary - 093/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Fletchling - 094/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Fletchling - 094/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Fletchinder - 095/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Fletchinder - 095/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Talonflame - 096/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Talonflame - 096/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Hawlucha - 097/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Hawlucha - 097/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Armor Fossil Shieldon - 098/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Armor Fossil Shieldon - 098/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Captivating Poke Puff - 099/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Captivating Poke Puff - 099/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Claw Fossil Anorith - 100/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Claw Fossil Anorith - 100/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Gardevoir Spirit Link - 101/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Gardevoir Spirit Link - 101/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Greedy Dice - 102/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Greedy Dice - 102/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Ninja Boy - 103/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Ninja Boy - 103/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Pokemon Ranger - 104/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Pokemon Ranger - 104/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Special Charge - 105/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Special Charge - 105/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Steelix Spirit Link - 106/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Steelix Spirit Link - 106/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Volcanion EX (Full Art) - 107/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Steelix EX (Full Art) - 108/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - M Steelix EX (Full Art) - 109/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Magearna EX (Full Art) - 110/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Gardevoir EX (Full Art) - 111/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - M Gardevoir EX (Full Art) - 112/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Pokemon Ranger (Full Art) - 113/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Professor Sycamore - 114/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Volcanion EX (Secret) - 115/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Steam Siege - Gardevoir EX (Secret) - 116/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Shuckle - 001/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Shuckle - 001/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Burmy - 002/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Burmy - 002/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Wormadam - 003/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Wormadam - 003/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Mothim - 004/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Mothim - 004/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Snivy - 005/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Snivy - 005/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Servine - 006/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Servine - 006/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Serperior - 007/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Serperior - 007/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Deerling - 008/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Deerling - 008/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Moltres - 009/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Moltres - 009/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Fennekin (10) - 010/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Fennekin (10) - 010/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Fennekin (11) - 011/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Fennekin (11) - 011/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Braixen - 012/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Braixen - 012/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Delphox - 013/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Delphox - 013/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Delphox BREAK - 014/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Seel - 015/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Seel - 015/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Dewgong - 016/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Dewgong - 016/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Omanyte - 017/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Omanyte - 017/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Omastar - 018/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Omastar - 018/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Omastar BREAK - 019/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Glaceon EX - 020/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - White Kyurem - 021/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - White Kyurem - 021/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Binacle - 022/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Binacle - 022/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Barbaracle - 023/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Barbaracle - 023/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Rotom - 024/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Rotom - 024/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Alakazam EX - 025/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - M Alakazam EX - 026/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Koffing - 027/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Koffing - 027/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Weezing - 028/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Weezing - 028/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Mew - 029/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Mew - 029/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Spoink - 030/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Spoink - 030/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Grumpig - 031/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Grumpig - 031/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Gothita - 032/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Gothita - 032/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Solosis - 033/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Solosis - 033/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Duosion - 034/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Duosion - 034/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Reuniclus - 035/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Reuniclus - 035/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Diglett - 036/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Diglett - 036/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Marowak - 037/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Marowak - 037/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Kabuto - 038/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Kabuto - 038/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Kabutops - 039/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Kabutops - 039/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Larvitar (40) - 040/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Larvitar (40) - 040/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Larvitar (41) - 041/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Larvitar (41) - 041/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Pupitar - 042/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Pupitar - 042/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Regirock EX - 043/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Wormadam (44) - 044/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Wormadam (44) - 044/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Riolu (45) - 045/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Riolu (45) - 045/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Riolu (46) - 046/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Riolu (46) - 046/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Lucario (47) - 047/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Lucario (47) - 047/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Hawlucha - 048/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Hawlucha - 048/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Carbink (49) - 049/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Carbink (49) - 049/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Carbink (50) - 050/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Carbink (50) - 050/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Carbink BREAK - 051/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Zygarde (52) - 052/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Zygarde (52) - 052/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Zygarde (53) - 053/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Zygarde (53) - 053/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Zygarde EX - 054/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Umbreon EX - 055/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Tyranitar - 056/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Tyranitar - 056/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Vullaby - 057/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Vullaby - 057/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Mandibuzz - 058/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Mandibuzz - 058/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Wormadam (59) - 059/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Wormadam (59) - 059/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Bronzor - 060/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Bronzor - 060/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Bronzong - 061/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Bronzong - 061/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Bronzong BREAK - 062/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Lucario (63) - 063/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Lucario (63) - 063/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Genesect EX - 064/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Jigglypuff - 065/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Jigglypuff - 065/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Wigglytuff - 066/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Wigglytuff - 066/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Mr. Mime - 067/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Mr. Mime - 067/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Snubbull - 068/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Snubbull - 068/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - M Altaria EX - 069/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Cottonee - 070/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Cottonee - 070/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Whimsicott - 071/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Whimsicott - 071/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Diancie EX - 072/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Kingdra EX - 073/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Meowth - 074/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Meowth - 074/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Kangaskhan - 075/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Kangaskhan - 075/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Aerodactyl - 076/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Aerodactyl - 076/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Snorlax - 077/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Snorlax - 077/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Lugia - 078/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Lugia - 078/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Lugia BREAK - 079/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Whismur - 080/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Whismur - 080/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Loudred - 081/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Loudred - 081/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Exploud - 082/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Exploud - 082/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Altaria EX - 083/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Audino EX - 084/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - M Audino EX - 085/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Minccino (86) - 086/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Minccino (86) - 086/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Minccino (87) - 087/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Minccino (87) - 087/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Cinccino (88) - 088/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Cinccino (88) - 088/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Cinccino (89) - 089/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Cinccino (89) - 089/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Alakazam Spirit Link - 090/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Alakazam Spirit Link - 090/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Altaria Spirit Link - 091/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Altaria Spirit Link - 091/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Audino Spirit Link - 092/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Audino Spirit Link - 092/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Bent Spoon - 093/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Bent Spoon - 093/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Chaos Tower - 094/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Chaos Tower - 094/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Devolution Spray - 095/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Devolution Spray - 095/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Dome Fossil Kabuto - 096/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Dome Fossil Kabuto - 096/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Energy Pouch - 097/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Energy Pouch - 097/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Energy Reset - 098/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Energy Reset - 098/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Fairy Drop - 099/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Fairy Drop - 099/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Fairy Garden - 100/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Fairy Garden - 100/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Fossil Excavation Kit - 101/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Fossil Excavation Kit - 101/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Helix Fossil Omanyte - 102/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Helix Fossil Omanyte - 102/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Lass's Special - 103/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Lass's Special - 103/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Mega Catcher - 104/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Mega Catcher - 104/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - N (Supporter) - 105/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - N (Supporter) - 105/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Old Amber Aerodactyl - 106/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Old Amber Aerodactyl - 106/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Pokemon Fan Club - 107/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Pokemon Fan Club - 107/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Power Memory - 108/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Power Memory - 108/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Random Receiver - 109/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Random Receiver - 109/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Scorched Earth - 110/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Scorched Earth - 110/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Shauna - 111/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Shauna - 111/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Team Rocket's Handiwork - 112/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Team Rocket's Handiwork - 112/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Ultra Ball - 113/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Ultra Ball - 113/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Double Colorless Energy - 114/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Double Colorless Energy - 114/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Strong Energy - 115/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Strong Energy - 115/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Glaceon EX (Full Art) - 116/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Alakazam EX (Full Art) - 117/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - M Alakazam EX (Full Art) - 118/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Umbreon EX (Full Art) - 119/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Genesect EX (Full Art) - 120/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - M Altaria EX (Full Art) - 121/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Kingdra EX (Full Art) - 122/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Altaria EX (Full Art) - 123/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Team Rocket's Handiwork (Full Art) - 124/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Fates Collide - Alakazam EX (Secret) - 125/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Venusaur EX - 001/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - M Venusaur EX - 002/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Caterpie - 003/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Caterpie - 003/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Metapod - 004/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Metapod - 004/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Butterfree - 005/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Butterfree - 005/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Paras - 006/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Paras - 006/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Parasect - 007/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Parasect - 007/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Tangela - 008/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Tangela - 008/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Pinsir - 009/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Pinsir - 009/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Leafeon EX - 010/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Charizard EX - 011/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - M Charizard EX - 012/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Ninetales EX - 013/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Ponyta - 014/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Ponyta - 014/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Rapidash - 015/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Rapidash - 015/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Magmar - 016/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Magmar - 016/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Blastoise EX - 017/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - M Blastoise EX - 018/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Shellder - 019/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Shellder - 019/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Cloyster - 020/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Cloyster - 020/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Krabby - 021/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Krabby - 021/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Magikarp - 022/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Magikarp - 022/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Gyarados - 023/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Gyarados - 023/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Vaporeon EX - 024/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Articuno - 025/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Pikachu - 026/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Pikachu - 026/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Raichu - 027/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Raichu - 027/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Jolteon EX - 028/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Zapdos - 029/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Zubat - 030/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Zubat - 030/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Golbat - 031/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Golbat - 031/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Slowpoke - 032/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Slowpoke - 032/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Gastly - 033/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Gastly - 033/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Haunter - 034/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Haunter - 034/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Gengar - 035/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Gengar - 035/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Jynx - 036/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Jynx - 036/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Meowstic EX - 037/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Diglett - 038/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Diglett - 038/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Dugtrio - 039/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Dugtrio - 039/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Machop - 040/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Machop - 040/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Machoke - 041/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Machoke - 041/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Machamp - 042/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Machamp - 042/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Geodude - 043/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Geodude - 043/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Graveler - 044/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Graveler - 044/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Golem - 045/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Golem - 045/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Golem EX - 046/83 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Hitmonlee - 047/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Hitmonlee - 047/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Hitmonchan - 048/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Hitmonchan - 048/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Rhyhorn - 049/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Rhyhorn - 049/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Clefairy - 050/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Clefairy - 050/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Clefable - 051/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Clefable - 051/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Mr. Mime - 052/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Mr. Mime - 052/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Meowth - 053/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Meowth - 053/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Persian - 054/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Persian - 054/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Doduo - 055/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Doduo - 055/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Dodrio - 056/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Dodrio - 056/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Tauros - 057/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Tauros - 057/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Snorlax - 058/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Snorlax - 058/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Clemont - 059/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Clemont - 059/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Crushing Hammer - 060/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Crushing Hammer - 060/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Energy Switch - 061/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Energy Switch - 061/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Evosoda - 062/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Evosoda - 062/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Imakuni? - 063/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Imakuni? - 063/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Maintenance - 064/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Maintenance - 064/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Max Revive - 065/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Max Revive - 065/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Olympia - 066/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Olympia - 066/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Poke Ball - 067/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Poke Ball - 067/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Pokemon Center Lady - 068/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Pokemon Center Lady - 068/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Pokemon Fan Club - 069/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Pokemon Fan Club - 069/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Revitalizer - 070/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Revitalizer - 070/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Red Card - 071/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Red Card - 071/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Shauna - 072/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Shauna - 072/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Team Flare Grunt - 073/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Team Flare Grunt - 073/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Double Colorless Energy - 074/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Double Colorless Energy - 074/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Grass Energy - 075/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Grass Energy - 075/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Fire Energy - 076/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Fire Energy - 076/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Water Energy - 077/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Water Energy - 077/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Lightning Energy - 078/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Lightning Energy - 078/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Psychic Energy - 079/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Psychic Energy - 079/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Fighting Energy - 080/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Fighting Energy - 080/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Darkness Energy - 081/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Darkness Energy - 081/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Metal Energy - 082/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Metal Energy - 082/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Fairy Energy - 083/83 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Generations - Fairy Energy - 083/83 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Chikorita - 001/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Chikorita - 001/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Bayleef - 002/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Bayleef - 002/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Meganium - 003/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Meganium - 003/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Seedot - 004/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Seedot - 004/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Kricketot - 005/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Kricketot - 005/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Kricketune - 006/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Kricketune - 006/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Petilil - 007/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Petilil - 007/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Lilligant - 008/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Lilligant - 008/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Durant - 009/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Durant - 009/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Growlithe - 010/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Growlithe - 010/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Arcanine - 011/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Arcanine - 011/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Numel - 012/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Numel - 012/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Camerupt - 013/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Camerupt - 013/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Emboar EX - 014/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Heatmor - 015/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Heatmor - 015/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Psyduck - 016/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Psyduck - 016/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Golduck - 017/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Golduck - 017/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Golduck BREAK - 018/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Slowpoke - 019/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Slowpoke - 019/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Slowbro - 020/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Slowbro - 020/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Slowking - 021/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Slowking - 021/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Shellder (22) - 022/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Shellder (22) - 022/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Shellder (23) - 023/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Shellder (23) - 023/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Cloyster - 024/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Cloyster - 024/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Staryu - 025/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Staryu - 025/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Gyarados EX - 026/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - M Gyarados EX - 027/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Lapras - 028/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Lapras - 028/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Corsola - 029/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Corsola - 029/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Suicune - 030/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Suicune - 030/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Palkia EX - 031/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Manaphy EX - 032/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Tympole - 033/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Tympole - 033/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Palpitoad - 034/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Palpitoad - 034/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Seismitoad - 035/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Seismitoad - 035/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Ducklett - 036/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Ducklett - 036/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Swanna - 037/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Swanna - 037/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Froakie - 038/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Froakie - 038/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Frogadier - 039/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Frogadier - 039/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Greninja - 040/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Greninja - 040/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Greninja BREAK - 041/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Electabuzz - 042/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Electabuzz - 042/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Electivire - 043/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Electivire - 043/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Shinx - 044/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Shinx - 044/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Luxio - 045/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Luxio - 045/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Luxray - 046/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Luxray - 046/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Luxray BREAK - 047/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Blitzle - 048/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Blitzle - 048/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Zebstrika - 049/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Zebstrika - 049/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Drowzee - 050/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Drowzee - 050/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Hypno - 051/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Hypno - 051/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Espeon EX - 052/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Skorupi - 053/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Skorupi - 053/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Drapion - 054/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Drapion - 054/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Sigilyph - 055/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Sigilyph - 055/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Trubbish - 056/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Trubbish - 056/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Garbodor - 057/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Garbodor - 057/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Espurr - 058/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Espurr - 058/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Meowstic - 059/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Meowstic - 059/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Honedge - 060/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Honedge - 060/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Doublade - 061/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Doublade - 061/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Aegislash - 062/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Aegislash - 062/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Skrelp - 063/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Skrelp - 063/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Phantump - 064/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Phantump - 064/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Trevenant - 065/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Trevenant - 065/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Trevenant BREAK - 066/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Sudowoodo - 067/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Sudowoodo - 067/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Gible - 068/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Gible - 068/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Gabite - 069/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Gabite - 069/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Garchomp - 070/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Garchomp - 070/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Pancham - 071/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Pancham - 071/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Nuzleaf - 072/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Nuzleaf - 072/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Shiftry - 073/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Shiftry - 073/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Darkrai EX - 074/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Pangoro - 075/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Pangoro - 075/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Scizor EX - 076/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - M Scizor EX - 077/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Mawile - 078/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Mawile - 078/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Ferroseed - 079/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Ferroseed - 079/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Ferrothorn - 080/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Ferrothorn - 080/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Clefairy - 081/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Clefairy - 081/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Clefable - 082/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Clefable - 082/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Togekiss EX - 083/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Spritzee - 084/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Spritzee - 084/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Aromatisse - 085/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Aromatisse - 085/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Dragalge - 086/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Dragalge - 086/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Rattata - 087/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Rattata - 087/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Raticate - 088/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Raticate - 088/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Raticate BREAK - 089/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Dunsparce - 090/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Dunsparce - 090/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Stantler - 091/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Stantler - 091/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Ho-Oh EX - 092/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Glameow - 093/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Glameow - 093/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Purugly - 094/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Purugly - 094/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Furfrou - 095/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Furfrou - 095/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - All-Night Party - 096/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - All-Night Party - 096/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Bursting Balloon - 097/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Bursting Balloon - 097/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Delinquent - 098/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Delinquent - 098/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Fighting Fury Belt - 099/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Fighting Fury Belt - 099/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Great Ball - 100/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Great Ball - 100/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Gyarados Spirit Link - 101/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Gyarados Spirit Link - 101/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Max Elixir - 102/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Max Elixir - 102/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Max Potion - 103/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Max Potion - 103/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Misty's Determination - 104/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Misty's Determination - 104/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Pokemon Catcher - 105/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Pokemon Catcher - 105/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Potion - 106/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Potion - 106/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Professor Sycamore - 107/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Professor Sycamore - 107/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Psychic's Third Eye - 108/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Psychic's Third Eye - 108/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Puzzle of Time - 109/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Puzzle of Time - 109/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Reverse Valley - 110/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Reverse Valley - 110/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Scizor Spirit Link - 111/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Scizor Spirit Link - 111/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Tierno - 112/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Tierno - 112/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Splash Energy - 113/122 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Splash Energy - 113/122 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Gyarados EX (Full Art) - 114/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - M Gyarados EX (Full Art) - 115/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Manaphy EX (Full Art) - 116/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Espeon EX (Full Art) - 117/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Darkrai EX (Full Art) - 118/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Scizor EX (Full Art) - 119/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - M Scizor EX (Full Art) - 120/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Ho-Oh EX (Full Art) - 121/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Skyla - 122/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKpoint - Gyarados EX (Secret) - 123/122 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Paras - 001/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Paras - 001/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Parasect - 002/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Parasect - 002/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Pinsir - 003/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Pinsir - 003/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Cacnea - 004/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Cacnea - 004/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Pansage - 005/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Pansage - 005/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Simisage - 006/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Simisage - 006/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Chespin - 007/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Chespin - 007/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Chespin - 008/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Chespin - 008/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Chespin - 009/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Chespin - 009/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Quilladin - 010/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Quilladin - 010/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Chesnaught - 011/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Chesnaught - 011/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Chesnaught BREAK - 012/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Scatterbug - 013/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Scatterbug - 013/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Spewpa - 014/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Spewpa - 014/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Vivillon - 015/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Vivillon - 015/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Skiddo - 016/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Skiddo - 016/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Gogoat - 017/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Gogoat - 017/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Cyndaquil - 018/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Cyndaquil - 018/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Quilava - 019/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Quilava - 019/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Typhlosion - 020/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Typhlosion - 020/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Houndoom EX - 021/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - M Houndoom EX - 022/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Pansear - 023/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Pansear - 023/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Simisear - 024/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Simisear - 024/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Fennekin - 025/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Fennekin - 025/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Braixen - 026/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Braixen - 026/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Goldeen - 027/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Goldeen - 027/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Seaking - 028/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Seaking - 028/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Staryu - 029/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Staryu - 029/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Starmie - 030/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Starmie - 030/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Remoraid (31) - 031/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Remoraid (31) - 031/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Remoraid (32) - 032/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Remoraid (32) - 032/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Octillery - 033/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Octillery - 033/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Glalie EX - 034/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - M Glalie EX - 035/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Piplup - 036/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Piplup - 036/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Prinplup - 037/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Prinplup - 037/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Empoleon - 038/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Empoleon - 038/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Snover - 039/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Snover - 039/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Abomasnow - 040/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Abomasnow - 040/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Panpour - 041/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Panpour - 041/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Simipour - 042/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Simipour - 042/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Vanillite - 043/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Vanillite - 043/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Vanillish - 044/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Vanillish - 044/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Vanilluxe - 045/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Vanilluxe - 045/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Froakie - 046/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Froakie - 046/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Frogadier - 047/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Frogadier - 047/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Pikachu - 048/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Pikachu - 048/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Raichu - 049/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Raichu - 049/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Raichu BREAK - 050/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Magnemite (51) - 051/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Magnemite (51) - 051/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Magnemite (52) - 052/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Magnemite (52) - 052/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Magneton - 053/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Magneton - 053/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Magnezone - 054/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Magnezone - 054/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Raikou - 055/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Raikou - 055/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Stunfisk - 056/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Stunfisk - 056/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Dedenne - 057/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Dedenne - 057/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Gastly - 058/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Gastly - 058/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Haunter - 059/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Haunter - 059/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Gengar - 060/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Gengar - 060/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Mewtwo EX (61) - 061/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Mewtwo EX (62) - 062/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - M Mewtwo EX (63) - 063/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - M Mewtwo EX (64) - 064/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Misdreavus - 065/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Misdreavus - 065/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Mismagius - 066/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Mismagius - 066/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Wobbuffet - 067/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Wobbuffet - 067/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Ralts (68) - 068/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Ralts (68) - 068/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Kirlia - 069/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Kirlia - 069/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Cresselia - 070/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Cresselia - 070/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Woobat - 071/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Woobat - 071/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Swoobat - 072/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Swoobat - 072/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Elgyem - 073/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Elgyem - 073/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Beheeyem - 074/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Beheeyem - 074/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Sandshrew - 075/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Sandshrew - 075/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Sandslash - 076/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Sandslash - 076/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Cubone - 077/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Cubone - 077/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Marowak - 078/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Marowak - 078/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Marowak BREAK - 079/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Swinub - 080/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Swinub - 080/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Piloswine - 081/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Piloswine - 081/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Mamoswine - 082/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Mamoswine - 082/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Hippopotas - 083/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Hippopotas - 083/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Gallade - 084/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Gallade - 084/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Meloetta - 085/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Meloetta - 085/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Pancham - 086/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Pancham - 086/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Hawlucha - 087/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Hawlucha - 087/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Cacturne - 088/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Cacturne - 088/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Zorua (89) - 089/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Zorua (89) - 089/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Zorua (90) - 090/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Zorua (90) - 090/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Zoroark - 091/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Zoroark - 091/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Zoroark BREAK - 092/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Inkay - 093/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Inkay - 093/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Yveltal - 094/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Yveltal - 094/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Bronzor - 095/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Bronzor - 095/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Bronzong - 096/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Bronzong - 096/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Mr. Mime - 097/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Mr. Mime - 097/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Snubbull - 098/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Snubbull - 098/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Granbull - 099/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Granbull - 099/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Ralts (100) - 100/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Ralts (100) - 100/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Flabebe - 101/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Flabebe - 101/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Floette - 102/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Floette - 102/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Florges - 103/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Florges - 103/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Florges BREAK - 104/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Spritzee - 105/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Spritzee - 105/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Aromatisse - 106/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Aromatisse - 106/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Xerneas - 107/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Xerneas - 107/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Axew (108) - 108/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Axew (108) - 108/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Axew (109) - 109/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Axew (109) - 109/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Fraxure - 110/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Fraxure - 110/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Haxorus - 111/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Haxorus - 111/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Noivern - 112/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Noivern - 112/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Noivern BREAK - 113/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Meowth - 114/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Meowth - 114/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Doduo (115) - 115/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Doduo (115) - 115/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Doduo (116) - 116/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Doduo (116) - 116/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Dodrio - 117/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Dodrio - 117/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Snorlax - 118/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Snorlax - 118/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Hoothoot - 119/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Hoothoot - 119/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Noctowl - 120/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Noctowl - 120/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Teddiursa - 121/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Teddiursa - 121/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Ursaring - 122/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Ursaring - 122/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Smeargle - 123/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Smeargle - 123/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Swablu - 124/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Swablu - 124/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Starly - 125/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Starly - 125/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Staravia - 126/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Staravia - 126/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Staraptor - 127/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Staraptor - 127/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Chatot - 128/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Chatot - 128/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Rufflet - 129/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Rufflet - 129/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Braviary - 130/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Braviary - 130/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Noibat (131) - 131/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Noibat (131) - 131/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Noibat (132) - 132/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Noibat (132) - 132/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Assault Vest - 133/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Assault Vest - 133/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Brigette - 134/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Brigette - 134/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Buddy-Buddy Rescue - 135/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Buddy-Buddy Rescue - 135/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Fisherman - 136/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Fisherman - 136/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Float Stone - 137/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Float Stone - 137/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Giovanni's Scheme - 138/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Giovanni's Scheme - 138/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Glalie Spirit Link - 139/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Glalie Spirit Link - 139/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Heavy Ball - 140/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Heavy Ball - 140/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Heavy Boots - 141/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Heavy Boots - 141/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Houndoom Spirit Link - 142/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Houndoom Spirit Link - 142/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Judge - 143/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Judge - 143/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Mewtwo Spirit Link - 144/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Mewtwo Spirit Link - 144/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Parallel City - 145/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Parallel City - 145/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Professor's Letter - 146/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Professor's Letter - 146/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Reserved Ticket - 147/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Reserved Ticket - 147/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Skyla - 148/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Skyla - 148/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Super Rod - 149/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Super Rod - 149/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Town Map - 150/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Town Map - 150/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Burning Energy - 151/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Burning Energy - 151/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Rainbow Energy - 152/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Rainbow Energy - 152/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Houndoom EX (Full Art) - 153/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - M Houndoom EX (Full Art) - 154/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Glalie EX (Full Art) - 155/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - M Glalie EX (Full Art) - 156/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Mewtwo EX (157 Full Art) - 157/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Mewtwo EX (158 Full Art) - 158/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - M Mewtwo EX (159 Full Art) - 159/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - M Mewtwo EX (160 Full Art) - 160/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Brigette (Full Art) - 161/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Giovanni's Scheme (Full Art) - 162/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Mewtwo EX (163 Secret Full Art) - 163/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/BREAKthrough - Mewtwo EX (164 Secret Full Art) - 164/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Oddish - 001/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Oddish - 001/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Gloom - 002/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Gloom - 002/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Vileplume - 003/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Vileplume - 003/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Bellossom - 004/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Bellossom - 004/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Spinarak - 005/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Spinarak - 005/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Ariados - 006/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Ariados - 006/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Sceptile EX - 007/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - M Sceptile EX - 008/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Combee - 009/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Combee - 009/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Vespiquen (10) - 010/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Vespiquen (10) - 010/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Vespiquen (11) - 011/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Vespiquen (11) - 011/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Virizion - 012/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Virizion - 012/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Flareon - 013/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Flareon - 013/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Entei (14) - 014/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Entei (14) - 014/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Entei (15) - 015/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Entei (15) - 015/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Larvesta - 016/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Larvesta - 016/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Volcarona (17) - 017/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Volcarona (17) - 017/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Volcarona (18) - 018/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Volcarona (18) - 018/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Magikarp - 019/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Magikarp - 019/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Gyarados (20) - 020/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Gyarados (20) - 020/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Gyarados (21) - 021/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Gyarados (21) - 021/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Vaporeon - 022/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Vaporeon - 022/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Relicanth - 023/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Relicanth - 023/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Regice - 024/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Regice - 024/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Kyurem EX - 025/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Jolteon - 026/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Jolteon - 026/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Ampharos EX - 027/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - M Ampharos EX - 028/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Rotom - 029/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Rotom - 029/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Unown - 030/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Unown - 030/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Baltoy (31) - 031/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Baltoy (31) - 031/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Baltoy (32) - 032/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Baltoy (32) - 032/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Claydol - 033/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Claydol - 033/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Golett - 034/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Golett - 034/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Golurk (35) - 035/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Golurk (35) - 035/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Hoopa EX - 036/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Machamp EX - 037/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Wooper - 038/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Wooper - 038/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Quagsire - 039/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Quagsire - 039/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Regirock - 040/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Regirock - 040/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Golurk (41) - 041/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Golurk (41) - 041/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Tyranitar EX - 042/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - M Tyranitar EX - 043/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Sableye - 044/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Sableye - 044/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Inkay - 045/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Inkay - 045/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Malamar - 046/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Malamar - 046/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Beldum - 047/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Beldum - 047/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Metang - 048/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Metang - 048/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Metagross (49) - 049/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Metagross (49) - 049/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Metagross (50) - 050/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Metagross (50) - 050/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Registeel - 051/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Registeel - 051/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Ralts - 052/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Ralts - 052/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Kirlia - 053/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Kirlia - 053/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Gardevoir - 054/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Gardevoir - 054/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Cottonee - 055/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Cottonee - 055/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Whimsicott - 056/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Whimsicott - 056/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Giratina EX - 057/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Goomy - 058/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Goomy - 058/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Sliggoo - 059/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Sliggoo - 059/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Goodra - 060/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Goodra - 060/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Meowth - 061/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Meowth - 061/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Persian - 062/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Persian - 062/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Eevee - 063/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Eevee - 063/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Porygon - 064/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Porygon - 064/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Porygon2 - 065/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Porygon2 - 065/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Porygon-Z (66) - 066/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Porygon-Z (66) - 066/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Porygon-Z (67) - 067/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Porygon-Z (67) - 067/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Lugia EX - 068/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Ace Trainer - 069/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Ace Trainer - 069/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Ampharos Spirit Link - 070/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Ampharos Spirit Link - 070/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Eco Arm - 071/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Eco Arm - 071/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Energy Recycler - 072/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Energy Recycler - 072/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Faded Town - 073/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Faded Town - 073/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Forest of Giant Plants - 074/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Forest of Giant Plants - 074/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Hex Maniac - 075/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Hex Maniac - 075/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Level Ball - 076/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Level Ball - 076/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Lucky Helmet - 077/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Lucky Helmet - 077/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Lysandre - 078/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Lysandre - 078/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Paint Roller - 079/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Paint Roller - 079/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Sceptile Spirit Link - 080/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Sceptile Spirit Link - 080/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Tyranitar Spirit Link - 081/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Tyranitar Spirit Link - 081/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Dangerous Energy - 082/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Dangerous Energy - 082/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Flash Energy - 083/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Flash Energy - 083/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Sceptile EX (84 Full Art) - 084/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - M Sceptile EX (85 Full Art) - 085/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Kyurem EX (86 Full Art) - 086/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Ampharos EX (87 Full Art) - 087/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - M Ampharos EX (88 Full Art) - 088/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Hoopa EX (89 Full Art) - 089/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Machamp EX (90 Full Art) - 090/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Tyranitar EX (91 Full Art) - 091/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - M Tyranitar EX (92 Full Art) - 092/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Giratina EX (93 Full Art) - 093/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Lugia EX (94 Full Art) - 094/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Steven (Full Art) - 095/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Primal Kyogre EX (Shiny Full Art) - 096/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Primal Groudon EX (Shiny Full Art) - 097/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - M Rayquaza EX (Shiny Full Art) - 098/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Energy Retrieval - 099/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Ancient Origins - Trainers' Mail - 100/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Exeggcute - 001/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Exeggcute - 001/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Exeggutor - 002/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Exeggutor - 002/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Wurmple - 003/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Wurmple - 003/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Silcoon - 004/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Silcoon - 004/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Beautifly - 005/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Beautifly - 005/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Cascoon - 006/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Cascoon - 006/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Dustox - 007/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Dustox - 007/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Dustox (8 Delta) - 008/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Dustox (8 Delta) - 008/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Nincada - 009/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Nincada - 009/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Ninjask - 010/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Ninjask - 010/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Shedinja - 011/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Shedinja - 011/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Tropius - 012/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Tropius - 012/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Victini - 013/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Victini - 013/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Fletchinder - 014/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Fletchinder - 014/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Talonflame - 015/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Talonflame - 015/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Articuno (16) - 016/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Articuno (16) - 016/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Articuno (17 Delta) - 017/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Articuno (17 Delta) - 017/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Wingull - 018/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Wingull - 018/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Pelipper - 019/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Pelipper - 019/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Pikachu - 020/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Pikachu - 020/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Voltorb - 021/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Voltorb - 021/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Electrode - 022/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Electrode - 022/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Zapdos - 023/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Zapdos - 023/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Electrike - 024/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Electrike - 024/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Manectric - 025/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Manectric - 025/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Thundurus EX - 026/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Natu (27) - 027/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Natu (27) - 027/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Natu (28 Delta) - 028/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Natu (28 Delta) - 028/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Xatu - 029/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Xatu - 029/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Shuppet - 030/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Shuppet - 030/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Banette (31) - 031/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Banette (31) - 031/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Banette (32 Delta) - 032/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Banette (32 Delta) - 032/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Deoxys - 033/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Deoxys - 033/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Gallade EX - 034/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - M Gallade EX - 035/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Gligar - 036/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Gligar - 036/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Gliscor - 037/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Gliscor - 037/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Binacle - 038/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Binacle - 038/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Hawlucha - 039/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Hawlucha - 039/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Absol - 040/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Absol - 040/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Inkay - 041/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Inkay - 041/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Jirachi - 042/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Jirachi - 042/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Togepi - 043/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Togepi - 043/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Togetic - 044/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Togetic - 044/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Togekiss (45) - 045/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Togekiss (45) - 045/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Togekiss (46 Delta) - 046/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Togekiss (46 Delta) - 046/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Carbink - 047/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Carbink - 047/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Klefki - 048/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Klefki - 048/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Dratini - 049/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Dratini - 049/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Dragonair - 050/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Dragonair - 050/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Dragonite (51) - 051/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Dragonite (51) - 051/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Dragonite (52 Delta) - 052/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Dragonite (52 Delta) - 052/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Altaria (53) - 053/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Altaria (53) - 053/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Bagon (54) - 054/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Bagon (54) - 054/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Bagon (55) - 055/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Bagon (55) - 055/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Shelgon - 056/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Shelgon - 056/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Salamence - 057/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Salamence - 057/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Latios EX - 058/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - M Latios EX - 059/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Rayquaza EX (60) - 060/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - M Rayquaza EX (61) - 061/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Hydreigon EX - 062/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Reshiram - 063/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Reshiram - 063/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Zekrom - 064/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Zekrom - 064/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Spearow - 065/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Spearow - 065/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Fearow - 066/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Fearow - 066/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Meowth - 067/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Meowth - 067/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Dunsparce - 068/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Dunsparce - 068/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Skarmory - 069/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Skarmory - 069/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Taillow - 070/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Taillow - 070/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Swellow (71) - 071/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Swellow (71) - 071/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Swellow (72 Delta) - 072/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Swellow (72 Delta) - 072/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Swablu - 073/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Swablu - 073/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Altaria (74 Delta) - 074/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Altaria (74 Delta) - 074/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Rayquaza EX (75) - 075/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - M Rayquaza EX (76) - 076/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Shaymin EX - 077/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Pidove - 078/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Pidove - 078/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Tranquill - 079/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Tranquill - 079/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Unfezant (80) - 080/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Unfezant (80) - 080/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Unfezant (81 Delta) - 081/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Unfezant (81 Delta) - 081/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Fletchling - 082/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Fletchling - 082/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Gallade Spirit Link - 083/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Gallade Spirit Link - 083/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Healing Scarf - 084/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Healing Scarf - 084/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Latios Spirit Link - 085/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Latios Spirit Link - 085/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Mega Turbo - 086/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Mega Turbo - 086/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Rayquaza Spirit Link - 087/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Rayquaza Spirit Link - 087/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Revive - 088/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Revive - 088/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Sky Field - 089/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Sky Field - 089/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Steven - 090/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Steven - 090/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Switch - 091/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Switch - 091/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Trainers' Mail - 092/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Trainers' Mail - 092/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Ultra Ball - 093/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Ultra Ball - 093/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Wally - 094/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Wally - 094/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Wide Lens - 095/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Wide Lens - 095/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Winona - 096/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Winona - 096/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Double Dragon Energy - 097/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Double Dragon Energy - 097/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Thundurus EX (98 Full Art) - 098/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Gallade EX (99 Full Art) - 099/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - M Gallade EX (100 Full Art) - 100/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Latios EX (101 Full Art) - 101/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - M Latios EX (102 Full Art) - 102/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Hydreigon EX (103 Full Art) - 103/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Rayquaza EX (104 Full Art) - 104/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - M Rayquaza EX (105 Full Art) - 105/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Shaymin EX (106 Full Art) - 106/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Wally (107 Full Art) - 107/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Winona (108 Full Art) - 108/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - Energy Switch - 109/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Roaring Skies - VS Seeker - 110/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Numel - 001/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Numel - 001/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Camerupt - 002/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Camerupt - 002/34 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Spheal - 003/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Spheal - 003/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Sealeo - 004/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Sealeo - 004/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Walrein - 005/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Walrein - 005/34 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Kyogre EX - 006/34 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Grimer - 007/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Grimer - 007/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Muk - 008/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Muk - 008/34 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Seviper - 009/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Seviper - 009/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Baltoy - 010/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Baltoy - 010/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Claydol - 011/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Claydol - 011/34 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Aron - 012/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Aron - 012/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Lairon - 013/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Lairon - 013/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Aggron - 014/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Aggron - 014/34 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Groudon EX - 015/34 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Poochyena - 016/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Poochyena - 016/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Poochyena - 017/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Poochyena - 017/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Mightyena - 018/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Mightyena - 018/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Mightyena - 019/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Mightyena - 019/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Carvanha - 020/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Carvanha - 020/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Sharpedo - 021/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Sharpedo - 021/34 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Zangoose - 022/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Zangoose - 022/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Aqua Diffuser - 023/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Aqua Diffuser - 023/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Magma Pointer - 024/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Magma Pointer - 024/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua Admin - 025/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua Admin - 025/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua Grunt - 026/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua Grunt - 026/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Great Ball - 027/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Great Ball - 027/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Secret Base - 028/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Aqua's Secret Base - 028/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma Admin - 029/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma Admin - 029/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma Grunt - 030/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma Grunt - 030/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Great Ball - 031/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Great Ball - 031/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Secret Base - 032/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Team Magma's Secret Base - 032/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Double Aqua Energy - 033/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Double Aqua Energy - 033/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Double Magma Energy - 034/34 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Double Crisis - Double Magma Energy - 034/34 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Weedle - 001/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Weedle - 001/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Kakuna - 002/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Kakuna - 002/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Beedrill - 003/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Beedrill - 003/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Tangela - 004/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Tangela - 004/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Tangrowth - 005/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Tangrowth - 005/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Treecko - 006/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Treecko - 006/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Grovyle - 007/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Grovyle - 007/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Sceptile - 008/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Sceptile - 008/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Sceptile (Omega) - 009/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Sceptile (Omega) - 009/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Lotad - 010/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Lotad - 010/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Lombre - 011/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Lombre - 011/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Ludicolo - 012/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Ludicolo - 012/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Surskit - 013/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Surskit - 013/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Masquerain - 014/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Masquerain - 014/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Shroomish - 015/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Shroomish - 015/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Breloom - 016/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Breloom - 016/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Volbeat - 017/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Volbeat - 017/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Illumise - 018/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Illumise - 018/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Trevenant EX - 019/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Vulpix - 020/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Vulpix - 020/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Ninetales - 021/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Ninetales - 021/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Slugma - 022/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Slugma - 022/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Magcargo - 023/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Magcargo - 023/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Magcargo (Omega) - 024/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Magcargo (Omega) - 024/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Torchic - 025/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Torchic - 025/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Torchic (Omega) - 026/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Torchic (Omega) - 026/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Combusken - 027/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Combusken - 027/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Blaziken - 028/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Blaziken - 028/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Camerupt EX - 029/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Horsea - 030/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Horsea - 030/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Seadra - 031/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Seadra - 031/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Staryu - 032/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Staryu - 032/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Mudkip - 033/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Mudkip - 033/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Marshtomp - 034/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Marshtomp - 034/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Swampert - 035/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Swampert - 035/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Swampert (Alpha) - 036/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Swampert (Alpha) - 036/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Ludicolo (Alpha) - 037/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Ludicolo (Alpha) - 037/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Wailord EX - 038/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Barboach - 039/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Barboach - 039/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Whiscash - 040/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Whiscash - 040/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Whiscash (Alpha) - 041/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Whiscash (Alpha) - 041/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Corphish - 042/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Corphish - 042/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Feebas - 043/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Feebas - 043/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Milotic - 044/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Milotic - 044/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Spheal (45) - 045/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Spheal (45) - 045/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Spheal (46) - 046/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Spheal (46) - 046/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Sealeo - 047/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Sealeo - 047/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Walrein - 048/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Walrein - 048/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Clamperl - 049/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Clamperl - 049/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Huntail - 050/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Huntail - 050/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Gorebyss - 051/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Gorebyss - 051/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Gorebyss (Alpha) - 052/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Gorebyss (Alpha) - 052/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Kyogre - 053/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Kyogre - 053/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Kyogre EX - 054/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Primal Kyogre EX (Alpha) - 055/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Manaphy - 056/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Manaphy - 056/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Chinchou - 057/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Chinchou - 057/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Lanturn - 058/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Lanturn - 058/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Electrike - 059/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Electrike - 059/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Electrike (Omega) - 060/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Electrike (Omega) - 060/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Manectric - 061/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Manectric - 061/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Tynamo - 062/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Tynamo - 062/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Eelektrik - 063/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Eelektrik - 063/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Eelektrik (Alpha) - 064/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Eelektrik (Alpha) - 064/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Eelektross - 065/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Eelektross - 065/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Nidoran (F) - 066/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Nidoran (F) - 066/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Nidorina - 067/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Nidorina - 067/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Nidoqueen - 068/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Nidoqueen - 068/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Nidoqueen (Omega) - 069/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Nidoqueen (Omega) - 069/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Tentacool - 070/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Tentacool - 070/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Tentacool (Alpha) - 071/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Tentacool (Alpha) - 071/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Tentacruel - 072/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Tentacruel - 072/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Starmie - 073/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Starmie - 073/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Rhyhorn - 074/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Rhyhorn - 074/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Rhydon - 075/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Rhydon - 075/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Rhyperior - 076/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Rhyperior - 076/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Rhyperior (Omega) - 077/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Rhyperior (Omega) - 077/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Nosepass - 078/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Nosepass - 078/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Meditite - 079/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Meditite - 079/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Medicham - 080/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Medicham - 080/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Medicham (Omega) - 081/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Medicham (Omega) - 081/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Trapinch - 082/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Trapinch - 082/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Solrock - 083/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Solrock - 083/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Groudon - 084/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Groudon - 084/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Groudon EX - 085/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Primal Groudon EX (Omega) - 086/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Hippopotas - 087/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Hippopotas - 087/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Hippowdon - 088/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Hippowdon - 088/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Drilbur - 089/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Drilbur - 089/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Diggersby - 090/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Diggersby - 090/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Sharpedo EX - 091/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Crawdaunt - 092/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Crawdaunt - 092/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Aggron EX - 093/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - M Aggron EX - 094/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Probopass - 095/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Probopass - 095/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Excadrill - 096/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Excadrill - 096/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Excadrill (Omega) - 097/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Excadrill (Omega) - 097/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Honedge - 098/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Honedge - 098/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Doublade - 099/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Doublade - 099/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Aegislash - 100/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Aegislash - 100/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Mr. Mime - 101/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Mr. Mime - 101/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Marill - 102/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Marill - 102/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Azumarill - 103/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Azumarill - 103/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Azumarill (Alpha) - 104/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Azumarill (Alpha) - 104/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Gardevoir EX - 105/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - M Gardevoir EX - 106/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Kingdra - 107/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Kingdra - 107/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Kingdra (Alpha) - 108/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Kingdra (Alpha) - 108/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Vibrava - 109/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Vibrava - 109/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Flygon - 110/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Flygon - 110/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Zigzagoon - 111/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Zigzagoon - 111/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Linoone - 112/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Linoone - 112/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Skitty - 113/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Skitty - 113/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Delcatty - 114/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Delcatty - 114/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Spinda - 115/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Spinda - 115/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Bidoof - 116/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Bidoof - 116/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Bidoof (Alpha) - 117/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Bidoof (Alpha) - 117/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Bibarel - 118/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Bibarel - 118/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Bouffalant - 119/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Bouffalant - 119/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Bunnelby - 120/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Bunnelby - 120/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Bunnelby (Omega) - 121/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Bunnelby (Omega) - 121/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Acro Bike - 122/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Acro Bike - 122/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Aggron Spirit Link - 123/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Aggron Spirit Link - 123/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Archie's Ace in the Hole - 124/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Archie's Ace in the Hole - 124/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Dive Ball - 125/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Dive Ball - 125/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Energy Retrieval - 126/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Energy Retrieval - 126/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Escape Rope - 127/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Escape Rope - 127/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Exp. Share - 128/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Exp. Share - 128/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Fresh Water Set - 129/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Fresh Water Set - 129/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Gardevoir Spirit Link - 130/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Gardevoir Spirit Link - 130/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Groudon Spirit Link - 131/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Groudon Spirit Link - 131/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Kyogre Spirit Link - 132/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Kyogre Spirit Link - 132/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Maxie's Hidden Ball Trick - 133/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Maxie's Hidden Ball Trick - 133/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Professor Birch's Observations - 134/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Professor Birch's Observations - 134/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Rare Candy - 135/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Rare Candy - 135/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Repeat Ball - 136/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Repeat Ball - 136/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Rough Seas - 137/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Rough Seas - 137/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Scorched Earth - 138/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Scorched Earth - 138/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Shrine of Memories - 139/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Shrine of Memories - 139/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Silent Lab - 140/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Silent Lab - 140/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Teammates - 141/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Teammates - 141/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Weakness Policy - 142/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Weakness Policy - 142/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Shield Energy - 143/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Shield Energy - 143/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Wonder Energy - 144/160 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Wonder Energy - 144/160 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Trevenant EX (145 Full Art) - 145/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Camerupt EX (146 Full Art) - 146/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Wailord EX (147 Full Art) - 147/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Kyogre EX (148 Full Art) - 148/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Primal Kyogre EX (Alpha - 149 Full Art) - 149/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Groudon EX (150 Full Art) - 150/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Primal Groudon EX (Omega - 151 Full Art) - 151/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Sharpedo EX (152 Full Art) - 152/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Aggron EX (153 Full Art) - 153/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - M Aggron EX (154 Full Art) - 154/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Gardevoir EX (155 Full Art) - 155/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - M Gardevoir EX (156 Full Art) - 156/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Archie's Ace in the Hole (157 Full Art) - 157/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Maxie's Hidden Ball Trick (158 Full Art) - 158/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Professor Birch's Observations (159 Full Art) - 159/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Teammates (160 Full Art) - 160/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Dive Ball (161 Secret Rare) - 161/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Enhanced Hammer (162 Secret Rare) - 162/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Switch (163 Secret Rare) - 163/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Primal Clash - Weakness Policy (164 Secret Rare) - 164/160 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Venonat - 001/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Venonat - 001/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Venomoth - 002/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Venomoth - 002/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Yanma - 003/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Yanma - 003/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Yanmega - 004/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Yanmega - 004/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Sewaddle - 005/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Sewaddle - 005/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Swadloon - 006/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Swadloon - 006/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Leavanny - 007/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Leavanny - 007/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Karrablast - 008/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Karrablast - 008/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Fletchinder - 009/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Fletchinder - 009/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Talonflame - 010/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Talonflame - 010/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Litleo - 011/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Litleo - 011/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Pyroar - 012/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Pyroar - 012/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Krabby - 013/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Krabby - 013/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Kingler - 014/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Kingler - 014/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Totodile - 015/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Totodile - 015/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Croconaw - 016/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Croconaw - 016/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Feraligatr - 017/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Feraligatr - 017/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Finneon - 018/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Finneon - 018/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Lumineon - 019/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Lumineon - 019/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Frillish - 020/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Frillish - 020/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Jellicent - 021/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Jellicent - 021/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Alomomola - 022/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Alomomola - 022/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Manectric EX - 023/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - M Manectric EX - 024/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Pachirisu - 025/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Pachirisu - 025/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Joltik - 026/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Joltik - 026/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Galvantula - 027/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Galvantula - 027/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Helioptile (28) - 028/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Helioptile (28) - 028/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Helioptile (29) - 029/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Helioptile (29) - 029/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Heliolisk - 030/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Heliolisk - 030/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Zubat - 031/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Zubat - 031/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Golbat - 032/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Golbat - 032/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Crobat - 033/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Crobat - 033/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Gengar EX - 034/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - M Gengar EX - 035/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Wobbuffet - 036/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Wobbuffet - 036/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Gulpin - 037/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Gulpin - 037/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Swalot - 038/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Swalot - 038/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Munna - 039/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Munna - 039/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Musharna - 040/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Musharna - 040/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Litwick - 041/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Litwick - 041/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Lampent - 042/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Lampent - 042/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Chandelure - 043/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Chandelure - 043/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Pumpkaboo - 044/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Pumpkaboo - 044/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Gourgeist - 045/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Gourgeist - 045/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Gligar - 046/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Gligar - 046/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Gliscor - 047/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Gliscor - 047/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Roggenrola - 048/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Roggenrola - 048/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Boldore - 049/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Boldore - 049/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Gigalith - 050/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Gigalith - 050/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Murkrow - 051/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Murkrow - 051/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Honchkrow - 052/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Honchkrow - 052/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Poochyena - 053/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Poochyena - 053/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Mightyena - 054/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Mightyena - 054/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Spiritomb - 055/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Spiritomb - 055/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Purrloin - 056/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Purrloin - 056/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Liepard - 057/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Liepard - 057/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Malamar EX - 058/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Skarmory - 059/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Skarmory - 059/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Bronzor - 060/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Bronzor - 060/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Bronzong - 061/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Bronzong - 061/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Dialga EX - 062/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Heatran - 063/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Heatran - 063/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Escavalier - 064/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Escavalier - 064/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Aegislash EX - 065/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Klefki - 066/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Klefki - 066/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Florges EX - 067/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Swirlix - 068/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Swirlix - 068/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Slurpuff - 069/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Slurpuff - 069/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Dedenne - 070/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Dedenne - 070/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Diancie - 071/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Diancie - 071/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Deino - 072/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Deino - 072/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Zweilous - 073/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Zweilous - 073/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Hydreigon - 074/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Hydreigon - 074/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Goomy - 075/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Goomy - 075/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Sliggoo - 076/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Sliggoo - 076/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Goodra - 077/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Goodra - 077/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Spearow - 078/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Spearow - 078/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Fearow - 079/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Fearow - 079/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Chansey - 080/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Chansey - 080/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Blissey - 081/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Blissey - 081/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Girafarig - 082/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Girafarig - 082/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Whismur - 083/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Whismur - 083/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Loudred - 084/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Loudred - 084/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Exploud - 085/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Exploud - 085/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Regigigas - 086/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Regigigas - 086/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Bunnelby - 087/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Bunnelby - 087/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Diggersby - 088/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Diggersby - 088/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Fletchling - 089/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Fletchling - 089/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Furfrou - 090/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Furfrou - 090/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - AZ - 091/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - AZ - 091/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Battle Compressor - 092/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Battle Compressor - 092/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Dimension Valley - 093/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Dimension Valley - 093/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Enhanced Hammer - 094/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Enhanced Hammer - 094/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Gengar Spirit Link - 095/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Gengar Spirit Link - 095/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Hand Scope - 096/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Hand Scope - 096/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Head Ringer - 097/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Head Ringer - 097/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Jamming Net - 098/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Lysandre's Trump Card - 099/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Lysandre's Trump Card - 099/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Manectric Spirit Link - 100/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Manectric Spirit Link - 100/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Professor Sycamore - 101/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Professor Sycamore - 101/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Robo Substitute - 102/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Robo Substitute - 102/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Roller Skates - 103/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Roller Skates - 103/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Shauna - 104/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Shauna - 104/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Steel Shelter - 105/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Steel Shelter - 105/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Target Whistle - 106/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Target Whistle - 106/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Tierno - 107/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Tierno - 107/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Trick Coin - 108/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Trick Coin - 108/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - VS Seeker - 109/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - VS Seeker - 109/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Xerosic - 110/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Xerosic - 110/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Double Colorless Energy - 111/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Double Colorless Energy - 111/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Mystery Energy - 112/119 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Mystery Energy - 112/119 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Manectric EX (113 Full Art) - 113/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Gengar EX (114 Full Art) - 114/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Malamar EX (115 Full Art) - 115/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Florges EX (116 Full Art) - 116/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - AZ (117 Full Art) - 117/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Lysandre's Trump Card (118 Full Art) - 118/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Xerosic (119 Full Art) - 119/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - M Manectric EX (120 Secret Rare) - 120/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - M Gengar EX (121 Secret Rare) - 121/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Phantom Forces - Dialga EX (122 Secret Rare) - 122/119 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Bellsprout - 001/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Bellsprout - 001/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Weepinbell - 002/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Weepinbell - 002/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Victreebel - 003/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Victreebel - 003/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Heracross EX - 004/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - M Heracross EX - 005/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Shroomish - 006/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Shroomish - 006/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Leafeon - 007/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Leafeon - 007/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Shelmet - 008/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Shelmet - 008/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Accelgor - 009/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Accelgor - 009/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Magmar - 010/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Magmar - 010/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Magmortar - 011/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Magmortar - 011/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Torchic - 012/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Torchic - 012/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Combusken - 013/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Combusken - 013/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Blaziken - 014/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Blaziken - 014/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Poliwag - 015/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Poliwag - 015/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Poliwhirl - 016/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Poliwhirl - 016/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Poliwrath - 017/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Poliwrath - 017/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Politoed - 018/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Politoed - 018/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Glaceon - 019/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Glaceon - 019/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Seismitoad EX - 020/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Cubchoo - 021/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Cubchoo - 021/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Beartic - 022/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Beartic - 022/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Clauncher - 023/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Clauncher - 023/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Clawitzer - 024/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Clawitzer - 024/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Amaura - 025/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Amaura - 025/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Aurorus - 026/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Aurorus - 026/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Pikachu - 027/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Pikachu - 027/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Raichu - 028/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Raichu - 028/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Electabuzz - 029/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Electabuzz - 029/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Electivire - 030/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Electivire - 030/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Plusle - 031/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Plusle - 031/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Minun - 032/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Minun - 032/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Thundurus - 033/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Thundurus - 033/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Dedenne - 034/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Dedenne - 034/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Drowzee - 035/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Drowzee - 035/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Hypno - 036/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Hypno - 036/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Jynx - 037/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Jynx - 037/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Skorupi - 038/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Skorupi - 038/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Gothita - 039/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Gothita - 039/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Gothorita - 040/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Gothorita - 040/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Gothitelle - 041/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Gothitelle - 041/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Golett - 042/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Golett - 042/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Golurk - 043/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Golurk - 043/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Machop - 044/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Machop - 044/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Machoke - 045/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Machoke - 045/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Machamp - 046/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Machamp - 046/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Hitmonlee - 047/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Hitmonlee - 047/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Hitmonchan - 048/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Hitmonchan - 048/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Hitmontop - 049/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Hitmontop - 049/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Breloom - 050/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Breloom - 050/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Makuhita - 051/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Makuhita - 051/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Hariyama - 052/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Hariyama - 052/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Trapinch - 053/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Trapinch - 053/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Lucario EX - 054/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - M Lucario EX - 055/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Mienfoo - 056/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Mienfoo - 056/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Mienshao - 057/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Mienshao - 057/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Landorus - 058/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Landorus - 058/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Pancham (59) - 059/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Pancham (59) - 059/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Pancham (60) - 060/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Pancham (60) - 060/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Tyrunt - 061/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Tyrunt - 061/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Tyrantrum - 062/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Tyrantrum - 062/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Hawlucha - 063/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Hawlucha - 063/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Hawlucha EX - 064/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Drapion - 065/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Drapion - 065/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Scraggy - 066/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Scraggy - 066/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Scrafty - 067/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Scrafty - 067/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Pangoro - 068/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Pangoro - 068/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Clefairy (69) - 069/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Clefairy (69) - 069/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Clefairy (70) - 070/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Clefairy (70) - 070/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Clefable - 071/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Clefable - 071/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Sylveon - 072/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Sylveon - 072/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Klefki - 073/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Klefki - 073/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Dragonite EX - 074/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Vibrava - 075/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Vibrava - 075/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Flygon - 076/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Flygon - 076/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Noivern - 077/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Noivern - 077/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Lickitung - 078/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Lickitung - 078/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Lickilicky - 079/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Lickilicky - 079/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Eevee - 080/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Eevee - 080/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Slakoth - 081/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Slakoth - 081/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Vigoroth - 082/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Vigoroth - 082/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Slaking - 083/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Slaking - 083/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Patrat - 084/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Patrat - 084/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Watchog - 085/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Watchog - 085/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Tornadus - 086/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Tornadus - 086/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Noibat - 087/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Noibat - 087/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Battle Reporter - 088/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Battle Reporter - 088/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Energy Switch - 089/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Energy Switch - 089/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Fighting Stadium - 090/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Fighting Stadium - 090/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Focus Sash - 091/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Focus Sash - 091/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Fossil Researcher - 092/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Fossil Researcher - 092/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Full Heal - 093/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Full Heal - 093/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Jaw Fossil - 094/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Jaw Fossil - 094/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Korrina - 095/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Korrina - 095/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Maintenance - 096/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Maintenance - 096/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Mountain Ring - 097/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Mountain Ring - 097/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Sail Fossil - 098/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Sail Fossil - 098/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Sparkling Robe - 099/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Sparkling Robe - 099/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Super Scoop Up - 100/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Super Scoop Up - 100/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Tool Retriever - 101/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Tool Retriever - 101/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Training Center - 102/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Training Center - 102/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Herbal Energy - 103/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Herbal Energy - 103/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Strong Energy - 104/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Strong Energy - 104/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Heracross EX (105 Full Art) - 105/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Seismitoad EX (106 Full Art) - 106/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Lucario EX (107 Full Art) - 107/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Dragonite EX (108 Full Art) - 108/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Battle Reporter (109 Full Art) - 109/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Fossil Researcher (110 Full Art) - 110/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - Korrina (111 Full Art) - 111/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - M Heracross EX (Secret) - 112/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Furious Fists - M Lucario EX (Secret) - 113/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Caterpie - 001/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Caterpie - 001/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Metapod - 002/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Metapod - 002/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Butterfree - 003/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Butterfree - 003/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Pineco - 004/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Pineco - 004/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Seedot - 005/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Seedot - 005/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Nuzleaf - 006/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Nuzleaf - 006/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Shiftry - 007/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Shiftry - 007/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Roselia - 008/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Roselia - 008/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Roserade - 009/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Roserade - 009/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Maractus - 010/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Maractus - 010/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Charizard EX (11) - 011/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Charizard EX (12) - 012/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - M Charizard EX (Y) - 013/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Ponyta - 014/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Ponyta - 014/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Rapidash - 015/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Rapidash - 015/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Torkoal - 016/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Torkoal - 016/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Fletchinder - 017/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Fletchinder - 017/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Litleo (18) - 018/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Litleo (18) - 018/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Litleo (19) - 019/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Litleo (19) - 019/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Pyroar - 020/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Pyroar - 020/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Qwilfish - 021/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Qwilfish - 021/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Feebas - 022/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Feebas - 022/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Milotic - 023/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Milotic - 023/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Spheal - 024/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Spheal - 024/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Sealeo - 025/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Sealeo - 025/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Walrein - 026/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Walrein - 026/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Luvdisc - 027/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Luvdisc - 027/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Buizel - 028/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Buizel - 028/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Floatzel - 029/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Floatzel - 029/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Bergmite - 030/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Bergmite - 030/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Avalugg - 031/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Avalugg - 031/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Shinx - 032/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Shinx - 032/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Luxio - 033/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Luxio - 033/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Luxray - 034/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Luxray - 034/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Magnezone EX - 035/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Helioptile - 036/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Helioptile - 036/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Heliolisk - 037/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Heliolisk - 037/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Duskull - 038/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Duskull - 038/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Dusclops - 039/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Dusclops - 039/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Dusknoir - 040/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Dusknoir - 040/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Toxicroak EX - 041/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Espurr - 042/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Espurr - 042/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Meowstic - 043/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Meowstic - 043/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Skrelp - 044/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Skrelp - 044/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Geodude - 045/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Geodude - 045/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Graveler - 046/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Graveler - 046/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Golem - 047/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Golem - 047/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Binacle - 048/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Binacle - 048/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Barbaracle - 049/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Barbaracle - 049/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Sneasel (50) - 050/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Sneasel (50) - 050/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Sneasel (51) - 051/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Sneasel (51) - 051/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Weavile - 052/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Weavile - 052/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Stunky (53) - 053/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Stunky (53) - 053/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Stunky (54) - 054/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Stunky (54) - 054/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Skuntank - 055/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Skuntank - 055/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Sandile - 056/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Sandile - 056/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Krokorok - 057/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Krokorok - 057/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Scraggy - 058/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Scraggy - 058/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Scrafty - 059/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Scrafty - 059/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Forretress - 060/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Forretress - 060/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Durant - 061/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Durant - 061/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Flabebe (62) - 062/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Flabebe (62) - 062/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Flabebe (63) - 063/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Flabebe (63) - 063/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Floette (64) - 064/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Floette (64) - 064/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Floette (65) - 065/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Floette (65) - 065/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Florges - 066/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Florges - 066/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Spritzee - 067/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Spritzee - 067/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Carbink - 068/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Carbink - 068/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - M Charizard EX (X) - 069/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Druddigon - 070/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Druddigon - 070/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Dragalge - 071/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Dragalge - 071/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Goomy - 072/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Goomy - 072/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Sliggoo - 073/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Sliggoo - 073/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Goodra - 074/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Goodra - 074/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Pidgey - 075/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Pidgey - 075/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Pidgeotto - 076/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Pidgeotto - 076/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Pidgeot - 077/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Pidgeot - 077/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Kangaskhan EX - 078/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - M Kangaskhan EX - 079/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Snorlax - 080/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Snorlax - 080/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Sentret - 081/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Sentret - 081/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Furret - 082/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Furret - 082/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Miltank - 083/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Miltank - 083/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Buneary - 084/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Buneary - 084/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Lopunny - 085/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Lopunny - 085/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Fletchling - 086/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Fletchling - 086/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Furfrou - 087/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Furfrou - 087/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Blacksmith - 088/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Blacksmith - 088/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Fiery Torch - 089/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Fiery Torch - 089/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Lysandre - 090/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Lysandre - 090/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Magnetic Storm - 091/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Magnetic Storm - 091/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Pal Pad - 092/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Pal Pad - 092/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Pokemon Center Lady - 093/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Pokemon Center Lady - 093/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Pokemon Fan Club - 094/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Pokemon Fan Club - 094/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Protection Cube - 095/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Protection Cube - 095/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Sacred Ash - 096/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Sacred Ash - 096/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Startling Megaphone - 097/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Startling Megaphone - 097/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Trick Shovel - 098/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Trick Shovel - 098/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Ultra Ball - 099/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Ultra Ball - 099/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Charizard EX (100 Full Art) - 100/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Magnezone EX (101 Full Art) - 101/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Toxicroak EX (102 Full Art) - 102/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Kangaskhan EX (103 Full Art) - 103/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Lysandre (104 Full Art) - 104/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Pokemon Center Lady (105 Full Art) - 105/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - Pokemon Fan Club (106 Full Art) - 106/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - M Charizard EX (Y) (Secret) - 107/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - M Charizard EX (X) (Secret) - 108/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Flashfire - M Kangaskhan EX (109 Secret Rare) - 109/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Weedle - 001/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Pansage - 002/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Chespin - 003/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Chespin - 003/39 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Quilladin - 004/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Quilladin - 004/39 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Chesnaught - 005/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Chesnaught - 005/39 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Slugma - 006/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Pansear - 007/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Fennekin - 008/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Fennekin - 008/39 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Braixen - 009/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Braixen - 009/39 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Delphox - 010/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Delphox - 010/39 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Panpour - 011/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Froakie - 012/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Froakie - 012/39 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Frogadier - 013/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Frogadier - 013/39 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Greninja - 014/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Greninja - 014/39 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Clauncher - 015/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Poochyena - 016/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Mightyena - 017/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Inkay - 018/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Pawniard - 019/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Bisharp - 020/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Honedge - 021/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Snubbull - 022/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Granbull - 023/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Swirlix - 024/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Farfetch'd - 025/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Snorlax - 026/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Miltank - 027/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Skitty - 028/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Bidoof - 029/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Bunnelby - 030/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Fletchling - 031/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Furfrou (32) - 032/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Furfrou (33) - 033/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Crushing Hammer - 034/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Poke Ball - 035/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Pokemon Catcher - 036/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Potion - 037/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Switch - 038/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Kalos Starter Set - Tierno - 039/39 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Venusaur EX - 001/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - M Venusaur EX - 002/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Weedle - 003/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Weedle - 003/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Kakuna - 004/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Kakuna - 004/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Beedrill - 005/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Beedrill - 005/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Ledyba - 006/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Ledyba - 006/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Ledian - 007/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Ledian - 007/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Volbeat - 008/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Volbeat - 008/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Illumise - 009/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Illumise - 009/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Pansage - 010/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Pansage - 010/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Simisage - 011/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Simisage - 011/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Chespin - 012/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Chespin - 012/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Quilladin - 013/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Quilladin - 013/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Chesnaught - 014/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Chesnaught - 014/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Scatterbug - 015/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Scatterbug - 015/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Spewpa - 016/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Spewpa - 016/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Vivillon (High Plains [Orange]) - 017/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Vivillon (High Plains [Orange]) - 017/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Vivillon (Meadow [Pink]) - 017/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Vivillon (Meadow [Pink]) - 017/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Skiddo - 018/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Skiddo - 018/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Gogoat - 019/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Gogoat - 019/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Slugma - 020/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Slugma - 020/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Magcargo - 021/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Magcargo - 021/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Pansear - 022/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Pansear - 022/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Simisear - 023/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Simisear - 023/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Fennekin - 024/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Fennekin - 024/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Braixen - 025/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Braixen - 025/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Delphox - 026/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Delphox - 026/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Fletchinder - 027/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Fletchinder - 027/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Talonflame - 028/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Talonflame - 028/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Blastoise EX - 029/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - M Blastoise EX - 030/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Shellder - 031/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Shellder - 031/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Cloyster - 032/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Cloyster - 032/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Staryu - 033/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Staryu - 033/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Starmie - 034/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Starmie - 034/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Lapras - 035/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Lapras - 035/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Corsola - 036/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Corsola - 036/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Panpour - 037/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Panpour - 037/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Simipour - 038/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Simipour - 038/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Froakie - 039/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Froakie - 039/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Frogadier - 040/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Frogadier - 040/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Greninja - 041/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Greninja - 041/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Pikachu - 042/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Pikachu - 042/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Raichu - 043/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Raichu - 043/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Voltorb - 044/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Voltorb - 044/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Electrode - 045/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Electrode - 045/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Emolga EX - 046/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Ekans - 047/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Ekans - 047/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Arbok - 048/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Arbok - 048/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Spoink - 049/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Spoink - 049/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Grumpig - 050/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Grumpig - 050/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Venipede - 051/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Venipede - 051/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Whirlipede - 052/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Whirlipede - 052/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Scolipede - 053/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Scolipede - 053/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Phantump - 054/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Phantump - 054/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Trevenant - 055/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Trevenant - 055/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Pumpkaboo - 056/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Pumpkaboo - 056/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Gourgeist - 057/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Gourgeist - 057/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Diglett - 058/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Diglett - 058/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Dugtrio - 059/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Dugtrio - 059/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Rhyhorn - 060/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Rhyhorn - 060/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Rhydon - 061/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Rhydon - 061/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Rhyperior - 062/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Rhyperior - 062/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Lunatone - 063/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Lunatone - 063/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Solrock - 064/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Solrock - 064/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Timburr - 065/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Timburr - 065/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Gurdurr - 066/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Gurdurr - 066/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Conkeldurr - 067/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Conkeldurr - 067/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Sableye - 068/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Sableye - 068/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Sandile - 069/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Sandile - 069/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Krokorok - 070/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Krokorok - 070/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Krookodile - 071/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Krookodile - 071/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Zorua - 072/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Zorua - 072/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Zoroark - 073/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Zoroark - 073/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Inkay (74) - 074/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Inkay (74) - 074/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Inkay (75) - 075/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Inkay (75) - 075/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Malamar (76) - 076/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Malamar (76) - 076/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Malamar (77) - 077/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Malamar (77) - 077/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Yveltal - 078/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Yveltal - 078/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Yveltal EX - 079/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Skarmory EX - 080/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Pawniard - 081/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Pawniard - 081/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Bisharp - 082/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Bisharp - 082/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Honedge - 083/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Honedge - 083/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Doublade - 084/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Doublade - 084/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Aegislash (85) - 085/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Aegislash (85) - 085/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Aegislash (86) - 086/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Aegislash (86) - 086/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Jigglypuff (87) - 087/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Jigglypuff (87) - 087/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Jigglypuff (88) - 088/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Jigglypuff (88) - 088/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Wigglytuff (89) - 089/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Wigglytuff (89) - 089/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Wigglytuff (90) - 090/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Wigglytuff (90) - 090/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Mr. Mime - 091/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Mr. Mime - 091/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Spritzee - 092/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Spritzee - 092/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Aromatisse - 093/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Aromatisse - 093/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Swirlix - 094/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Swirlix - 094/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Slurpuff - 095/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Slurpuff - 095/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Xerneas - 096/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Xerneas - 096/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Xerneas EX - 097/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Doduo - 098/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Doduo - 098/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Dodrio - 099/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Dodrio - 099/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Tauros - 100/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Tauros - 100/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Dunsparce - 101/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Dunsparce - 101/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Taillow - 102/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Taillow - 102/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Swellow - 103/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Swellow - 103/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Skitty - 104/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Skitty - 104/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Delcatty - 105/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Delcatty - 105/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Bidoof - 106/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Bidoof - 106/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Bibarel - 107/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Bibarel - 107/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Lillipup - 108/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Lillipup - 108/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Herdier - 109/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Herdier - 109/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Stoutland - 110/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Stoutland - 110/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Bunnelby - 111/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Bunnelby - 111/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Diggersby - 112/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Diggersby - 112/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Fletchling - 113/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Fletchling - 113/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Furfrou - 114/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Furfrou - 114/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Cassius - 115/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Cassius - 115/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Evosoda - 116/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Evosoda - 116/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Fairy Garden - 117/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Fairy Garden - 117/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Great Ball - 118/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Great Ball - 118/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Hard Charm - 119/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Hard Charm - 119/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Max Revive - 120/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Max Revive - 120/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Muscle Band - 121/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Muscle Band - 121/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Professor Sycamore - 122/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Professor Sycamore - 122/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Professor's Letter - 123/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Professor's Letter - 123/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Red Card - 124/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Red Card - 124/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Roller Skates - 125/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Roller Skates - 125/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Shadow Circle - 126/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Shadow Circle - 126/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Shauna - 127/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Shauna - 127/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Super Potion - 128/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Super Potion - 128/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Team Flare Grunt - 129/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Team Flare Grunt - 129/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Double Colorless Energy - 130/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Double Colorless Energy - 130/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Rainbow Energy - 131/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Rainbow Energy - 131/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Grass Energy - 132/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Fire Energy - 133/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Water Energy - 134/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Lightning Energy - 135/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Psychic Energy - 136/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Fighting Energy - 137/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Darkness Energy (Basic) - 138/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Metal Energy (Basic) - 139/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Fairy Energy - 140/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Venusaur EX (141 Full Art) - 141/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Blastoise EX (142 Full Art) - 142/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Emolga EX (143 Full Art) - 143/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Yveltal EX (144 Full Art) - 144/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Skarmory EX (145 Full Art) - 145/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/X and Y - Xerneas EX (146 Full Art) - 146/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Tangela - 001/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Tangela - 001/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Tangrowth - 002/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Tangrowth - 002/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Shuckle - 003/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Shuckle - 003/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Cherubi - 004/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Cherubi - 004/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Carnivine - 005/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Carnivine - 005/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Snivy - 006/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Snivy - 006/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Servine - 007/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Servine - 007/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Serperior - 008/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Serperior - 008/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Sewaddle - 009/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Sewaddle - 009/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Sewaddle (10) - 010/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Sewaddle (10) - 010/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Swadloon - 011/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Swadloon - 011/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Leavanny - 012/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Leavanny - 012/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Dwebble - 013/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Dwebble - 013/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Crustle - 014/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Crustle - 014/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Virizion - 015/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Virizion - 015/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Genesect - 016/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Genesect - 016/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Charmander - 017/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Charmander - 017/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Charmeleon - 018/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Charmeleon - 018/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Charizard - 019/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Charizard - 019/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Vulpix - 020/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Vulpix - 020/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Ninetales - 021/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Ninetales - 021/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Moltres - 022/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Moltres - 022/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Victini - 023/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Victini - 023/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Victini EX - 024/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Tepig - 025/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Tepig - 025/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Pignite - 026/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Pignite - 026/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Emboar - 027/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Emboar - 027/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Reshiram - 028/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Reshiram - 028/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Reshiram EX - 029/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Magikarp - 030/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Magikarp - 030/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Gyarados - 031/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Gyarados - 031/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Articuno - 032/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Articuno - 032/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Piplup - 033/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Piplup - 033/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Prinplup - 034/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Prinplup - 034/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Empoleon - 035/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Empoleon - 035/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Phione - 036/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Phione - 036/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Oshawott - 037/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Oshawott - 037/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Dewott - 038/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Dewott - 038/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Samurott - 039/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Samurott - 039/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Tympole - 040/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Tympole - 040/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Palpitoad - 041/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Palpitoad - 041/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Seismitoad - 042/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Seismitoad - 042/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Kyurem - 043/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Kyurem - 043/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Kyurem EX - 044/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Keldeo EX - 045/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Zapdos - 046/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Zapdos - 046/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Plusle - 047/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Plusle - 047/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Minun - 048/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Minun - 048/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Emolga - 049/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Emolga - 049/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Thundurus - 050/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Thundurus - 050/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Zekrom - 051/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Zekrom - 051/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Zekrom EX - 052/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Mewtwo - 053/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Mewtwo - 053/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Mewtwo EX - 054/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Natu - 055/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Natu - 055/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Xatu - 056/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Xatu - 056/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Misdreavus - 057/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Misdreavus - 057/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Mismagius - 058/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Mismagius - 058/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Ralts - 059/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Ralts - 059/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Kirlia - 060/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Kirlia - 060/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Sableye - 061/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Sableye - 061/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Croagunk - 062/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Croagunk - 062/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Toxicroak - 063/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Toxicroak - 063/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Woobat - 064/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Woobat - 064/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Swoobat - 065/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Swoobat - 065/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Sigilyph - 066/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Sigilyph - 066/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Trubbish - 067/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Trubbish - 067/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Garbodor - 068/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Garbodor - 068/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Gothita (69) - 069/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Gothita (69) - 069/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Gothita (70) - 070/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Gothita (70) - 070/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Gothorita - 071/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Gothorita - 071/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Gothitelle - 072/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Gothitelle - 072/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Solosis (73) - 073/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Solosis (73) - 073/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Solosis (74) - 074/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Solosis (74) - 074/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Duosion - 075/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Duosion - 075/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Reuniclus - 076/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Reuniclus - 076/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Chandelure EX - 077/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Meloetta (78) - 078/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Meloetta (78) - 078/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Riolu - 079/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Riolu - 079/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Lucario - 080/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Lucario - 080/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Gallade - 081/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Gallade - 081/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Excadrill EX - 082/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Stunfisk - 083/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Stunfisk - 083/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Terrakion - 084/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Terrakion - 084/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Landorus - 085/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Landorus - 085/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Meloetta (86) - 086/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Meloetta (86) - 086/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Spiritomb - 087/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Spiritomb - 087/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Darkrai EX - 088/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Zorua - 089/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Zorua - 089/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Zoroark - 090/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Zoroark - 090/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Cobalion - 091/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Cobalion - 091/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Altaria - 092/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Altaria - 092/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Rayquaza - 093/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Rayquaza - 093/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Gible - 094/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Gible - 094/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Gabite - 095/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Gabite - 095/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Garchomp - 096/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Garchomp - 096/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Deino - 097/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Deino - 097/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Zweilous - 098/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Zweilous - 098/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Hydreigon - 099/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Hydreigon - 099/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Black Kyurem EX - 100/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - White Kyurem EX - 101/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Lugia EX (Team Plasma) - 102/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Swablu - 103/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Swablu - 103/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Minccino - 104/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Minccino - 104/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Cinccino - 105/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Cinccino - 105/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Druddigon - 106/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Druddigon - 106/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Bouffalant - 107/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Bouffalant - 107/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Tornadus - 108/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Tornadus - 108/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Bianca - 109/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Bianca - 109/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Cedric Juniper - 110/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Cedric Juniper - 110/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Crushing Hammer - 111/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Crushing Hammer - 111/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Energy Switch - 112/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Energy Switch - 112/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Double Colorless Energy - 113/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Double Colorless Energy - 113/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Reshiram (114 Full Art Secret Rare) - 114/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legendary Treasures - Zekrom (115 Full Art Secret Rare) - 115/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Surskit - 001/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Surskit - 001/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Masquerain (Team Plasma) - 002/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Masquerain (Team Plasma) - 002/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Lileep - 003/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Lileep - 003/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Cradily - 004/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Cradily - 004/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Tropius - 005/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Tropius - 005/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Karrablast - 006/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Karrablast - 006/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Shelmet - 007/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Shelmet - 007/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Accelgor (Team Plasma) - 008/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Accelgor (Team Plasma) - 008/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Virizion EX - 009/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Genesect - 010/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Genesect - 010/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Genesect EX (Team Plasma) - 011/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Larvesta - 012/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Larvesta - 012/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Volcarona (Team Plasma) - 013/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Volcarona (Team Plasma) - 013/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Squirtle - 014/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Squirtle - 014/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Wartortle - 015/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Wartortle - 015/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Blastoise - 016/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Blastoise - 016/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Lapras - 017/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Lapras - 017/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Remoraid - 018/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Remoraid - 018/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Octillery (Team Plasma) - 019/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Octillery (Team Plasma) - 019/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Suicune - 020/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Suicune - 020/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Snorunt - 021/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Snorunt - 021/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Glalie (Team Plasma) - 022/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Glalie (Team Plasma) - 022/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Froslass (Team Plasma) - 023/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Froslass (Team Plasma) - 023/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Relicanth (Team Plasma) - 024/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Relicanth (Team Plasma) - 024/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Snover - 025/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Snover - 025/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Abomasnow - 026/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Abomasnow - 026/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Tirtouga - 027/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Tirtouga - 027/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Carracosta - 028/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Carracosta - 028/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Ducklett - 029/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Ducklett - 029/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Kyurem EX - 030/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Tynamo - 031/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Tynamo - 031/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Eelektrik - 032/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Eelektrik - 032/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Eelektross (Team Plasma) - 033/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Eelektross (Team Plasma) - 033/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Drifloon - 034/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Drifloon - 034/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Drifblim - 035/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Drifblim - 035/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Uxie - 036/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Uxie - 036/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Mesprit - 037/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Mesprit - 037/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Azelf - 038/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Azelf - 038/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Munna - 039/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Munna - 039/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Musharna - 040/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Musharna - 040/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Sigilyph - 041/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Sigilyph - 041/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Solosis - 042/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Solosis - 042/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Duosion - 043/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Duosion - 043/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Reuniclus - 044/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Reuniclus - 044/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Golett - 045/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Golett - 045/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Golurk - 046/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Golurk - 046/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Machop - 047/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Machop - 047/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Machoke - 048/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Machoke - 048/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Machamp - 049/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Machamp - 049/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Machamp - 050/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Machamp - 050/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Throh - 051/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Throh - 051/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Sawk - 052/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Sawk - 052/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Archen - 053/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Archen - 053/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Archeops - 054/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Archeops - 054/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Houndour - 055/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Houndour - 055/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Houndoom - 056/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Houndoom - 056/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Aron - 057/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Aron - 057/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Lairon - 058/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Lairon - 058/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Aggron - 059/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Aggron - 059/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Jirachi EX - 060/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Escavalier (Team Plasma) - 061/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Escavalier (Team Plasma) - 061/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Bagon - 062/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Bagon - 062/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Shelgon - 063/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Shelgon - 063/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Salamence - 064/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Salamence - 064/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Dialga EX (Team Plasma) - 065/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Palkia EX (Team Plasma) - 066/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Axew - 067/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Axew - 067/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Fraxure - 068/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Fraxure - 068/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Haxorus - 069/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Haxorus - 069/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Druddigon - 070/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Druddigon - 070/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Kangaskhan - 071/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Kangaskhan - 071/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Porygon - 072/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Porygon - 072/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Porygon2 - 073/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Porygon2 - 073/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Porygon-Z (Team Plasma) - 074/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Porygon-Z (Team Plasma) - 074/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Teddiursa - 075/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Teddiursa - 075/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Ursaring (Team Plasma) - 076/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Ursaring (Team Plasma) - 076/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Chatot (Team Plasma) - 077/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Chatot (Team Plasma) - 077/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Caitlin - 078/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Caitlin - 078/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Cover Fossil - 079/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Cover Fossil - 079/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Energy Retrieval - 080/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Energy Retrieval - 080/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Iris - 081/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Iris - 081/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Plume Fossil - 082/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Plume Fossil - 082/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Pokemon Catcher - 083/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Pokemon Catcher - 083/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Professor Juniper - 084/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Professor Juniper - 084/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Rare Candy - 085/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Rare Candy - 085/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Reversal Trigger (Team Plasma) - 086/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Reversal Trigger (Team Plasma) - 086/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Root Fossil Lileep - 087/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Root Fossil Lileep - 087/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Silver Bangle - 088/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Silver Bangle - 088/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Silver Mirror - 089/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Silver Mirror - 089/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Ultra Ball - 090/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Ultra Ball - 090/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Plasma Energy (Team Plasma) - 091/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Plasma Energy (Team Plasma) - 091/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - G Booster (Team Plasma) - 092/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - G Scope (Team Plasma) - 093/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Master Ball - 094/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Scoop Up Cyclone - 095/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Virizion EX (Full Art) - 096/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Genesect EX (Team Plasma) (97 Full Art) - 097/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Jirachi EX (98 Full Art) - 098/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Dialga EX (Team Plasma) (99 Full Art) - 099/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Palkia EX (Team Plasma) (100 Full Art) - 100/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Iris (101 Full Art) - 101/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Exeggcute (102 Secret Rare) - 102/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Virizion (Secret) - 103/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Dusknoir (104 Secret Rare) - 104/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Blast - Rare Candy (105 Secret Rare) - 105/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Weedle - 001/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Weedle - 001/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Kakuna - 002/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Kakuna - 002/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Beedrill - 003/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Beedrill - 003/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Exeggcute - 004/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Exeggcute - 004/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Exeggutor - 005/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Exeggutor - 005/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Treecko - 006/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Treecko - 006/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Grovyle - 007/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Grovyle - 007/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Sceptile - 008/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Sceptile - 008/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Cacnea - 009/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Cacnea - 009/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Cacturne - 010/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Cacturne - 010/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Leafeon (Team Plasma) - 011/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Leafeon (Team Plasma) - 011/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Flareon (Team Plasma) - 012/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Flareon (Team Plasma) - 012/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Heatran EX (Team Plasma) - 013/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Litwick - 014/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Litwick - 014/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Lampent - 015/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Lampent - 015/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Chandelure (Team Plasma) - 016/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Chandelure (Team Plasma) - 016/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Reshiram - 017/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Reshiram - 017/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Horsea - 018/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Horsea - 018/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Seadra - 019/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Seadra - 019/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Vaporeon (Team Plasma) - 020/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Vaporeon (Team Plasma) - 020/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Wooper - 021/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Wooper - 021/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Quagsire - 022/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Quagsire - 022/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Glaceon (Team Plasma) - 023/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Glaceon (Team Plasma) - 023/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Tympole - 024/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Tympole - 024/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Palpitoad - 025/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Palpitoad - 025/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Seismitoad (Team Plasma) - 026/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Seismitoad (Team Plasma) - 026/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Vanillite - 027/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Vanillite - 027/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Vanillish - 028/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Vanillish - 028/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Vanilluxe (Team Plasma) - 029/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Vanilluxe (Team Plasma) - 029/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Cryogonal (Team Plasma) - 030/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Cryogonal (Team Plasma) - 030/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Kyurem (Team Plasma) - 031/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Kyurem (Team Plasma) - 031/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Voltorb - 032/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Voltorb - 032/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Electrode - 033/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Electrode - 033/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Jolteon (Team Plasma) - 034/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Jolteon (Team Plasma) - 034/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Chinchou - 035/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Chinchou - 035/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Lanturn - 036/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Lanturn - 036/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Pachirisu - 037/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Pachirisu - 037/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Thundurus EX (Team Plasma) - 038/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Zekrom - 039/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Zekrom - 039/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Nidoran F - 040/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Nidoran F - 040/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Nidorina - 041/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Nidorina - 041/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Nidoqueen - 042/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Nidoqueen - 042/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Nidoran M - 043/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Nidoran M - 043/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Nidorino - 044/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Nidorino - 044/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Grimer - 045/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Grimer - 045/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Muk (Team Plasma) - 046/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Muk (Team Plasma) - 046/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Mr. Mime - 047/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Mr. Mime - 047/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Espeon (Team Plasma) - 048/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Espeon (Team Plasma) - 048/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Sableye (Team Plasma) - 049/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Sableye (Team Plasma) - 049/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Beldum - 050/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Beldum - 050/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Metang - 051/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Metang - 051/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Metagross (Team Plasma) - 052/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Metagross (Team Plasma) - 052/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Deoxys EX (Team Plasma) - 053/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Yamask (54) - 054/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Yamask (54) - 054/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Yamask (55) - 055/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Yamask (55) - 055/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Cofagrigus (Team Plasma) (56) - 056/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Cofagrigus (Team Plasma) (56) - 056/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Cofagrigus (Team Plasma) (57) - 057/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Cofagrigus (Team Plasma) (57) - 057/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Nidoking - 058/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Nidoking - 058/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Mankey - 059/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Mankey - 059/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Primeape - 060/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Primeape - 060/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Onix - 061/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Onix - 061/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Makuhita - 062/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Makuhita - 062/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Hariyama - 063/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Hariyama - 063/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Umbreon (Team Plasma) - 064/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Umbreon (Team Plasma) - 064/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Sneasel - 065/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Sneasel - 065/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Weavile (Team Plasma) - 066/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Weavile (Team Plasma) - 066/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Absol (Team Plasma) - 067/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Absol (Team Plasma) - 067/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Sandile - 068/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Sandile - 068/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Krokorok - 069/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Krokorok - 069/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Krookodile (Team Plasma) - 070/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Krookodile (Team Plasma) - 070/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Pawniard - 071/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Pawniard - 071/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Pawniard - 072/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Pawniard - 072/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Bisharp (73) - 073/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Bisharp (73) - 073/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Bisharp (74) - 074/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Bisharp (74) - 074/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Deino (75) - 075/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Deino (75) - 075/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Deino (76) - 076/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Deino (76) - 076/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Zweilous - 077/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Zweilous - 077/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Hydreigon (Team Plasma) - 078/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Hydreigon (Team Plasma) - 078/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Steelix (Team Plasma) - 079/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Steelix (Team Plasma) - 079/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Mawile - 080/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Mawile - 080/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Dratini - 081/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Dratini - 081/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Dragonair - 082/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Dragonair - 082/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Dragonite - 083/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Dragonite - 083/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Kingdra - 084/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Kingdra - 084/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Latias EX - 085/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Latios EX - 086/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Rattata - 087/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Rattata - 087/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Raticate (Team Plasma) - 088/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Raticate (Team Plasma) - 088/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Eevee (89) - 089/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Eevee (89) - 089/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Eevee (90) - 090/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Eevee (90) - 090/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Hoothoot - 091/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Hoothoot - 091/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Noctowl - 092/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Noctowl - 092/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Miltank - 093/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Miltank - 093/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Kecleon - 094/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Kecleon - 094/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Starly - 095/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Starly - 095/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Staravia - 096/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Staravia - 096/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Staraptor - 097/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Staraptor - 097/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Tornadus EX (Team Plasma) - 098/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Float Stone - 099/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Float Stone - 099/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Frozen City (Team Plasma) - 100/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Frozen City (Team Plasma) - 100/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Ghetsis (Team Plasma) - 101/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Ghetsis (Team Plasma) - 101/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Shadow Triad (Team Plasma) - 102/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Shadow Triad (Team Plasma) - 102/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Superior Energy Retrieval - 103/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Superior Energy Retrieval - 103/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Team Plasma Badge (Team Plasma) - 104/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Team Plasma Badge (Team Plasma) - 104/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Team Plasma Ball (Team Plasma) - 105/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Team Plasma Ball (Team Plasma) - 105/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Plasma Energy (Team Plasma) - 106/116 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Plasma Energy (Team Plasma) - 106/116 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Life Dew - 107/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Rock Guard - 108/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Heatran EX (Team Plasma) (Full Art) - 109/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Thundurus EX (Team Plasma) (110 Full Art) - 110/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Deoxys EX (Team Plasma) (Full Art) - 111/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Latias EX (112 Full Art) - 112/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Latios EX (113 Full Art) - 113/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Tornadus EX (Team Plasma) (114 Full Art) - 114/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Ghetsis (Team Plasma) (Full Art) - 115/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Professor Juniper (116 Full Art) - 116/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Empoleon (117 Secret Rare) - 117/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Sigilyph - 118/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Garbodor (119 Secret Rare) - 119/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Garchomp (120 Secret Rare) - 120/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Max Potion (121 Secret Rare) - 121/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Freeze - Ultra Ball - 122/116 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Turtwig - 001/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Turtwig - 001/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Grotle - 002/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Grotle - 002/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Torterra (Team Plasma) - 003/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Torterra (Team Plasma) - 003/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Combee - 004/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Combee - 004/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Vespiquen - 005/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Vespiquen - 005/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Cherubi - 006/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Cherubi - 006/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Cherrim - 007/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Cherrim - 007/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Sewaddle - 008/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Sewaddle - 008/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Swadloon - 009/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Swadloon - 009/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Leavanny - 010/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Leavanny - 010/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Maractus - 011/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Maractus - 011/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Foongus - 012/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Foongus - 012/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Amoonguss (Team Plasma) - 013/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Amoonguss (Team Plasma) - 013/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Moltres EX (Team Plasma) - 014/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Chimchar - 015/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Chimchar - 015/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Monferno - 016/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Monferno - 016/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Infernape (Team Plasma) - 017/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Infernape (Team Plasma) - 017/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Victini EX - 018/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Pansear - 019/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Pansear - 019/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Simisear - 020/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Simisear - 020/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Litwick - 021/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Litwick - 021/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Lampent - 022/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Lampent - 022/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Heatmor - 023/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Heatmor - 023/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Squirtle - 024/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Squirtle - 024/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Articuno EX (Team Plasma) - 025/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Swinub - 026/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Swinub - 026/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Piloswine - 027/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Piloswine - 027/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Mamoswine - 028/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Mamoswine - 028/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Lotad - 029/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Lotad - 029/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Lombre - 030/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Lombre - 030/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Ludicolo - 031/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Ludicolo - 031/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Carvanha - 032/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Carvanha - 032/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Sharpedo (Team Plasma) - 033/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Sharpedo (Team Plasma) - 033/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Manaphy - 034/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Manaphy - 034/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Vanillite - 035/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Vanillite - 035/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Vanillish - 036/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Vanillish - 036/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Vanilluxe - 037/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Vanilluxe - 037/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Frillish - 038/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Frillish - 038/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Jellicent (Team Plasma) - 039/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Jellicent (Team Plasma) - 039/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Cubchoo - 040/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Cubchoo - 040/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Beartic (Team Plasma) - 041/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Beartic (Team Plasma) - 041/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Magnemite - 042/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Magnemite - 042/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Magnemite - 043/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Magnemite - 043/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Magneton (44) - 044/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Magneton (44) - 044/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Magneton (45) - 045/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Magneton (45) - 045/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Magnezone (Team Plasma) - 046/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Magnezone (Team Plasma) - 046/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Magnezone - 047/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Magnezone - 047/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Zapdos EX (Team Plasma) - 048/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Rotom (Team Plasma) - 049/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Rotom (Team Plasma) - 049/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Joltik - 050/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Joltik - 050/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Galvantula - 051/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Galvantula - 051/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Zubat (52) - 052/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Zubat (52) - 052/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Zubat (53) - 053/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Zubat (53) - 053/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Golbat - 054/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Golbat - 054/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Crobat (Team Plasma) - 055/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Crobat (Team Plasma) - 055/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Koffing (56) - 056/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Koffing (56) - 056/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Koffing (57) - 057/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Koffing (57) - 057/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Weezing (Team Plasma) - 058/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Weezing (Team Plasma) - 058/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Ralts - 059/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Ralts - 059/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Kirlia - 060/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Kirlia - 060/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Gallade - 061/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Gallade - 061/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Giratina (Team Plasma) - 062/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Giratina (Team Plasma) - 062/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Trubbish (63) - 063/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Trubbish (63) - 063/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Trubbish (64) - 064/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Trubbish (64) - 064/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Trubbish (65) - 065/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Trubbish (65) - 065/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Garbodor (Team Plasma) (66) - 066/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Garbodor (Team Plasma) (66) - 066/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Garbodor (Team Plasma) (67) - 067/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Garbodor (Team Plasma) (67) - 067/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Elgyem (68) - 068/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Elgyem (68) - 068/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Elgyem (69) - 069/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Elgyem (69) - 069/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Beheeyem (Team Plasma) - 070/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Beheeyem (Team Plasma) - 070/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Phanpy - 071/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Phanpy - 071/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Donphan - 072/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Donphan - 072/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Lunatone - 073/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Lunatone - 073/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Solrock - 074/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Solrock - 074/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Riolu (75) - 075/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Riolu (75) - 075/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Riolu (76) - 076/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Riolu (76) - 076/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Lucario - 077/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Lucario - 077/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Lucario - 078/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Lucario - 078/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Timburr - 079/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Timburr - 079/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Gurdurr - 080/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Gurdurr - 080/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Conkeldurr - 081/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Conkeldurr - 081/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Purrloin (82) - 082/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Purrloin (82) - 082/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Purrloin (83) - 083/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Purrloin (83) - 083/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Liepard (Team Plasma) - 084/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Liepard (Team Plasma) - 084/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Scraggy - 085/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Scraggy - 085/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Scrafty (Team Plasma) - 086/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Scrafty (Team Plasma) - 086/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Skarmory (Team Plasma) - 087/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Skarmory (Team Plasma) - 087/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Klink - 088/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Klink - 088/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Klang - 089/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Klang - 089/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Klinklang (Team Plasma) - 090/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Klinklang (Team Plasma) - 090/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Durant (Team Plasma) (91) - 091/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Durant (Team Plasma) (91) - 091/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Durant (Team Plasma) (92) - 092/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Durant (Team Plasma) (92) - 092/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Cobalion EX - 093/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Druddigon (Team Plasma) - 094/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Druddigon (Team Plasma) - 094/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Black Kyurem EX - 095/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - White Kyurem EX - 096/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Clefairy - 097/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Clefairy - 097/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Clefable - 098/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Clefable - 098/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Doduo - 099/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Doduo - 099/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Dodrio - 100/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Dodrio - 100/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Snorlax (Team Plasma) - 101/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Snorlax (Team Plasma) - 101/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Togepi - 102/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Togepi - 102/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Togetic - 103/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Togetic - 103/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Togekiss - 104/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Togekiss - 104/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Whismur - 105/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Whismur - 105/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Loudred - 106/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Loudred - 106/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Exploud - 107/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Exploud - 107/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Lugia EX (Team Plasma) - 108/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Skitty - 109/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Skitty - 109/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Patrat (110) - 110/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Patrat (110) - 110/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Patrat (111) - 111/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Patrat (111) - 111/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Watchog (Team Plasma) - 112/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Watchog (Team Plasma) - 112/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Watchog (Team Plasma) - 113/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Watchog (Team Plasma) - 113/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Bouffalant (Team Plasma) - 114/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Bouffalant (Team Plasma) - 114/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Rufflet - 115/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Rufflet - 115/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Braviary - 116/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Braviary - 116/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Bicycle - 117/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Bicycle - 117/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Colress (Team Plasma) - 118/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Colress (Team Plasma) - 118/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Colress Machine (Team Plasma) - 119/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Colress Machine (Team Plasma) - 119/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Escape Rope - 120/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Escape Rope - 120/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Ether - 121/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Ether - 121/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Eviolite - 122/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Eviolite - 122/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Hypnotoxic Laser (Team Plasma) - 123/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Hypnotoxic Laser (Team Plasma) - 123/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Plasma Frigate (Team Plasma) - 124/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Plasma Frigate (Team Plasma) - 124/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Team Plasma Grunt (Team Plasma) - 125/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Team Plasma Grunt (Team Plasma) - 125/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Virbank City Gym - 126/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Virbank City Gym - 126/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Plasma Energy (Team Plasma) - 127/135 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Plasma Energy (Team Plasma) - 127/135 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Dowsing Machine - 128/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Scramble Switch - 129/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Victory Piece - 130/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Victini EX (131 Full Art) - 131/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Articuno EX (Team Plasma) (132 Full Art) - 132/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Cobalion EX (133 Full Art) - 133/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Lugia EX (Team Plasma) (134 Full Art) - 134/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Colress (Team Plasma) (135 Full Art) - 135/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Charizard - 136/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Blastoise - 137/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Plasma Storm - Random Receiver - 138/135 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Oddish - 001/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Oddish - 001/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Gloom - 002/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Gloom - 002/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Vileplume - 003/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Vileplume - 003/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Bellossom - 004/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Bellossom - 004/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Tangela - 005/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Tangela - 005/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Tangrowth - 006/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Tangrowth - 006/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Scyther - 007/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Scyther - 007/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Heracross - 008/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Heracross - 008/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Celebi EX - 009/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Shaymin - 010/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Shaymin - 010/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Snivy - 011/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Snivy - 011/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Servine - 012/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Servine - 012/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Serperior - 013/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Serperior - 013/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Cottonee - 014/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Cottonee - 014/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Whimsicott - 015/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Whimsicott - 015/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Petilil - 016/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Petilil - 016/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Lilligant - 017/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Lilligant - 017/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Charmander - 018/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Charmander - 018/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Charmeleon - 019/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Charmeleon - 019/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Charizard - 020/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Charizard - 020/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Numel - 021/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Numel - 021/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Camerupt - 022/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Camerupt - 022/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Victini - 023/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Victini - 023/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Tepig - 024/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Tepig - 024/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Pignite - 025/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Pignite - 025/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Emboar - 026/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Emboar - 026/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Darumaka - 027/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Darumaka - 027/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Darmanitan - 028/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Darmanitan - 028/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Squirtle - 029/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Squirtle - 029/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Wartortle - 030/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Wartortle - 030/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Blastoise - 031/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Blastoise - 031/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Psyduck (32) - 032/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Psyduck (32) - 032/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Psyduck (33) - 033/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Psyduck (33) - 033/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Golduck (34) - 034/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Golduck (34) - 034/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Golduck (35) - 035/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Golduck (35) - 035/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Marill - 036/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Marill - 036/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Azumarill - 037/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Azumarill - 037/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Delibird - 038/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Delibird - 038/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Oshawott - 039/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Oshawott - 039/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Dewott - 040/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Dewott - 040/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Samurott - 041/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Samurott - 041/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Ducklett - 042/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Ducklett - 042/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Swanna - 043/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Swanna - 043/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Frillish - 044/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Frillish - 044/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Jellicent - 045/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Jellicent - 045/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Cryogonal - 046/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Cryogonal - 046/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Keldeo - 047/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Keldeo - 047/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Keldeo - 048/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Keldeo - 048/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Keldeo EX - 049/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Pikachu - 050/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Pikachu - 050/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Voltorb - 051/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Voltorb - 051/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Electrode - 052/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Electrode - 052/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Electabuzz - 053/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Electabuzz - 053/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Electivire - 054/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Electivire - 054/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Chinchou - 055/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Chinchou - 055/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Blitzle - 056/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Blitzle - 056/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Zebstrika - 057/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Zebstrika - 057/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Wobbuffet - 058/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Wobbuffet - 058/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Spoink - 059/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Spoink - 059/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Grumpig - 060/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Grumpig - 060/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Duskull - 061/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Duskull - 061/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Dusclops - 062/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Dusclops - 062/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Dusknoir - 063/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Dusknoir - 063/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Croagunk (64) - 064/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Croagunk (64) - 064/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Croagunk (65) - 065/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Croagunk (65) - 065/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Toxicroak - 066/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Toxicroak - 066/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Cresselia EX - 067/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Munna - 068/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Munna - 068/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Musharna - 069/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Musharna - 069/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Woobat - 070/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Woobat - 070/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Swoobat - 071/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Swoobat - 071/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Venipede - 072/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Venipede - 072/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Whirlipede - 073/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Whirlipede - 073/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Scolipede - 074/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Scolipede - 074/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Gothita - 075/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Gothita - 075/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Gothorita - 076/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Gothorita - 076/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Meloetta - 077/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Meloetta - 077/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Sandshrew - 078/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Sandshrew - 078/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Sandslash - 079/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Sandslash - 079/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Gligar - 080/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Gligar - 080/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Gliscor - 081/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Gliscor - 081/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Makuhita - 082/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Makuhita - 082/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Trapinch - 083/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Trapinch - 083/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Dwebble - 084/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Dwebble - 084/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Crustle - 085/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Crustle - 085/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Mienfoo (86) - 086/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Mienfoo (86) - 086/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Mienfoo (87) - 087/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Mienfoo (87) - 087/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Mienshao - 088/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Mienshao - 088/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Landorus EX - 089/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Purrloin - 090/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Purrloin - 090/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Liepard - 091/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Liepard - 091/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Vullaby - 092/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Vullaby - 092/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Mandibuzz - 093/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Mandibuzz - 093/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Scizor - 094/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Scizor - 094/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Skarmory (95) - 095/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Skarmory (95) - 095/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Skarmory (96) - 096/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Skarmory (96) - 096/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Klink - 097/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Klink - 097/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Vibrava - 098/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Vibrava - 098/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Flygon - 099/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Flygon - 099/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Black Kyurem - 100/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Black Kyurem - 100/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Black Kyurem EX - 101/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - White Kyurem - 102/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - White Kyurem - 102/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - White Kyurem EX - 103/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Rattata - 104/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Rattata - 104/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Raticate - 105/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Raticate - 105/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Meowth - 106/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Meowth - 106/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Farfetch'd - 107/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Farfetch'd - 107/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Ditto - 108/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Ditto - 108/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Snorlax - 109/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Snorlax - 109/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Togepi - 110/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Togepi - 110/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Dunsparce - 111/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Dunsparce - 111/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Taillow - 112/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Taillow - 112/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Skitty - 113/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Skitty - 113/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Delcatty - 114/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Delcatty - 114/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Spinda - 115/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Spinda - 115/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Buneary - 116/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Buneary - 116/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Lopunny - 117/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Lopunny - 117/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Patrat - 118/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Patrat - 118/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Watchog - 119/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Watchog - 119/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Lillipup - 120/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Lillipup - 120/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Herdier - 121/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Herdier - 121/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Stoutland - 122/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Stoutland - 122/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Pidove - 123/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Pidove - 123/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Tranquill - 124/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Tranquill - 124/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Unfezant - 125/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Unfezant - 125/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Audino - 126/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Audino - 126/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Aspertia City Gym - 127/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Aspertia City Gym - 127/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Energy Search - 128/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Energy Search - 128/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Great Ball - 129/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Great Ball - 129/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Hugh - 130/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Hugh - 130/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Poke Ball - 131/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Poke Ball - 131/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Potion - 132/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Potion - 132/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Rocky Helmet - 133/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Rocky Helmet - 133/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Skyla - 134/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Skyla - 134/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Switch - 135/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Switch - 135/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Town Map - 136/149 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Town Map - 136/149 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Computer Search - 137/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Crystal Edge - 138/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Crystal Wall - 139/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Gold Potion - 140/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Celebi EX (141 Full Art) - 141/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Keldeo EX (142 Full Art) - 142/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Cresselia EX (143 Full Art) - 143/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Landorus EX (144 Full Art) - 144/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Black Kyurem EX (145 Full Art) - 145/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - White Kyurem EX (146 Full Art) - 146/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Bianca (147 Full Art) - 147/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Cheren (148 Full Art) - 148/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Skyla (149 Full Art) - 149/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Golurk - 150/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Terrakion - 151/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Altaria - 152/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Boundaries Crossed - Rocky Helmet (Secret) - 153/149 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Hoppip - 001/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Hoppip - 001/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Skiploom - 002/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Skiploom - 002/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Jumpluff - 003/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Jumpluff - 003/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Yanma - 004/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Yanma - 004/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Yanmega - 005/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Yanmega - 005/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Wurmple - 006/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Wurmple - 006/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Silcoon - 007/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Silcoon - 007/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Beautifly - 008/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Beautifly - 008/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Cascoon - 009/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Cascoon - 009/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Nincada - 010/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Nincada - 010/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Ninjask - 011/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Ninjask - 011/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Roselia (12) - 012/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Roselia (12) - 012/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Roselia (13) - 013/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Roselia (13) - 013/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Roserade - 014/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Roserade - 014/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Roserade - 015/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Roserade - 015/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Maractus - 016/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Maractus - 016/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Foongus - 017/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Foongus - 017/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Vulpix - 018/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Vulpix - 018/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Ninetales - 019/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Ninetales - 019/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Magmar - 020/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Magmar - 020/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Magmortar - 021/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Magmortar - 021/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Ho-Oh EX - 022/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Magikarp - 023/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Magikarp - 023/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Gyarados - 024/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Gyarados - 024/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Wailmer - 025/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Wailmer - 025/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Wailord - 026/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Wailord - 026/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Feebas - 027/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Feebas - 027/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Milotic - 028/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Milotic - 028/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Spheal - 029/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Spheal - 029/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Sealeo - 030/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Sealeo - 030/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Walrein - 031/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Walrein - 031/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Buizel - 032/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Buizel - 032/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Floatzel - 033/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Floatzel - 033/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Tympole - 034/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Tympole - 034/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Palpitoad - 035/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Palpitoad - 035/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Seismitoad - 036/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Seismitoad - 036/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Alomomola - 037/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Alomomola - 037/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Mareep - 038/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Mareep - 038/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Flaaffy - 039/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Flaaffy - 039/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Ampharos - 040/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Ampharos - 040/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Electrike (41) - 041/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Electrike (41) - 041/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Electrike (42) - 042/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Electrike (42) - 042/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Manectric (43) - 043/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Manectric (43) - 043/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Manectric (44) - 044/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Manectric (44) - 044/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Emolga - 045/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Emolga - 045/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Mew EX - 046/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Dustox - 047/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Dustox - 047/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Shedinja - 048/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Shedinja - 048/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Drifloon (49) - 049/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Drifloon (49) - 049/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Drifloon (50) - 050/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Drifloon (50) - 050/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Drifblim - 051/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Drifblim - 051/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Sigilyph - 052/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Sigilyph - 052/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Trubbish - 053/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Trubbish - 053/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Garbodor - 054/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Garbodor - 054/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Gothita - 055/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Gothita - 055/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Gothorita - 056/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Gothorita - 056/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Gothitelle - 057/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Gothitelle - 057/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Golett - 058/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Golett - 058/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Golurk - 059/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Golurk - 059/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Cubone - 060/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Cubone - 060/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Marowak - 061/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Marowak - 061/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Nosepass - 062/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Nosepass - 062/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Baltoy - 063/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Baltoy - 063/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Claydol - 064/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Claydol - 064/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Roggenrola - 065/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Roggenrola - 065/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Boldore - 066/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Boldore - 066/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Gigalith - 067/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Gigalith - 067/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Throh - 068/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Throh - 068/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Sawk - 069/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Sawk - 069/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Stunfisk - 070/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Stunfisk - 070/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Terrakion EX - 071/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Murkrow - 072/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Murkrow - 072/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Honchkrow - 073/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Honchkrow - 073/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Houndour - 074/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Houndour - 074/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Houndoom - 075/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Houndoom - 075/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Stunky - 076/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Stunky - 076/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Skuntank - 077/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Skuntank - 077/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Aron - 078/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Aron - 078/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Lairon - 079/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Lairon - 079/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Aggron - 080/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Aggron - 080/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Registeel EX - 081/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Probopass - 082/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Probopass - 082/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Durant - 083/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Durant - 083/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Altaria - 084/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Altaria - 084/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Rayquaza EX - 085/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Gible (86) - 086/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Gible (86) - 086/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Gible (87) - 087/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Gible (87) - 087/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Gabite (88) - 088/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Gabite (88) - 088/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Gabite (89) - 089/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Gabite (89) - 089/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Garchomp (90) - 090/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Garchomp (90) - 090/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Garchomp (91) - 091/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Garchomp (91) - 091/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Giratina EX - 092/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Deino (93) - 093/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Deino (93) - 093/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Deino (94) - 094/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Deino (94) - 094/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Zweilous (95) - 095/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Zweilous (95) - 095/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Zweilous (96) - 096/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Zweilous (96) - 096/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Hydreigon (97) - 097/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Hydreigon (97) - 097/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Hydreigon (98) - 098/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Hydreigon (98) - 098/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Aipom - 099/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Aipom - 099/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Ambipom - 100/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Ambipom - 100/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Slakoth - 101/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Slakoth - 101/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Vigoroth - 102/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Vigoroth - 102/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Slaking - 103/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Slaking - 103/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Swablu (104) - 104/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Swablu (104) - 104/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Swablu (105) - 105/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Swablu (105) - 105/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Bidoof - 106/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Bidoof - 106/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Bibarel - 107/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Bibarel - 107/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Audino - 108/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Audino - 108/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Minccino - 109/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Minccino - 109/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Bouffalant - 110/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Bouffalant - 110/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Rufflet - 111/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Rufflet - 111/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Braviary - 112/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Braviary - 112/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Devolution Spray - 113/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Devolution Spray - 113/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Giant Cape - 114/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Giant Cape - 114/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Rescue Scarf - 115/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Rescue Scarf - 115/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Tool Scrapper - 116/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Tool Scrapper - 116/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Blend Energy GFPD - 117/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Blend Energy GFPD - 117/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Blend Energy WLFM - 118/124 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Blend Energy WLFM - 118/124 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Ho-Oh Ex (119 Full Art) - 119/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Mew EX (120 Full Art) - 120/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Terrakion EX (121 Full Art) - 121/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Registeel EX (122 Full Art) - 122/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Rayquaza EX (123 Full Art) - 123/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Giratina EX (124 Full Art) - 124/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Serperior - 125/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Reuniclus - 126/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Krookodile - 127/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragons Exalted - Rayquaza - 128/124 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Bulbasaur - 001/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Bulbasaur - 001/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Ivysaur - 002/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Ivysaur - 002/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Venusaur - 003/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Venusaur - 003/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Scyther - 004/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Scyther - 004/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Carnivine - 005/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Carnivine - 005/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Leafeon - 006/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Leafeon - 006/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Dwebble - 007/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Dwebble - 007/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Crustle - 008/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Crustle - 008/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Karrablast - 009/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Karrablast - 009/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Shelmet - 010/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Shelmet - 010/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Accelgor - 011/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Accelgor - 011/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Flareon - 012/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Flareon - 012/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Entei EX - 013/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Torchic (14) - 014/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Torchic (14) - 014/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Torchic (15) - 015/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Torchic (15) - 015/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Combusken - 016/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Combusken - 016/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Blaziken - 017/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Blaziken - 017/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Torkoal - 018/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Torkoal - 018/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Heatmor - 019/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Heatmor - 019/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Larvesta (20) - 020/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Larvesta (20) - 020/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Larvesta (21) - 021/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Larvesta (21) - 021/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Volcarona - 022/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Volcarona - 022/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Slowpoke - 023/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Slowpoke - 023/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Slowbro - 024/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Slowbro - 024/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Vaporeon - 025/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Vaporeon - 025/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Kyogre EX - 026/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Piplup - 027/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Piplup - 027/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Prinplup - 028/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Prinplup - 028/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Empoleon - 029/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Empoleon - 029/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Glaceon - 030/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Glaceon - 030/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Tympole - 031/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Tympole - 031/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Palpitoad - 032/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Palpitoad - 032/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Vanillite - 033/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Vanillite - 033/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Vanillish - 034/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Vanillish - 034/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Ducklett - 035/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Ducklett - 035/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Swanna - 036/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Swanna - 036/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Jolteon - 037/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Jolteon - 037/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Raikou EX - 038/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Plusle - 039/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Plusle - 039/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Minun - 040/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Minun - 040/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Joltik (41) - 041/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Joltik (41) - 041/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Joltik (42) - 042/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Joltik (42) - 042/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Galvantula - 043/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Galvantula - 043/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Tynamo (44) - 044/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Tynamo (44) - 044/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Tynamo (45) - 045/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Tynamo (45) - 045/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Eelektrik - 046/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Eelektrik - 046/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Eelektross - 047/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Eelektross - 047/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Espeon - 048/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Espeon - 048/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Slowking - 049/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Slowking - 049/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Woobat - 050/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Woobat - 050/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Yamask - 051/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Yamask - 051/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Cofagrigus - 052/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Cofagrigus - 052/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Aerodactyl - 053/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Aerodactyl - 053/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Groudon EX - 054/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Drilbur - 055/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Drilbur - 055/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Excadrill (56) - 056/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Excadrill (56) - 056/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Excadrill (57) - 057/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Excadrill (57) - 057/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Timburr - 058/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Timburr - 058/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Gurdurr - 059/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Gurdurr - 059/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Umbreon (60) - 060/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Umbreon (60) - 060/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Umbreon (61) - 061/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Umbreon (61) - 061/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Sableye - 062/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Sableye - 062/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Darkrai EX - 063/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Sandile - 064/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Sandile - 064/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Krokorok - 065/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Krokorok - 065/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Krookodile - 066/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Krookodile - 066/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Scraggy - 067/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Scraggy - 067/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Scrafty - 068/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Scrafty - 068/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Zorua (69) - 069/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Zorua (69) - 069/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Zorua (70) - 070/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Zorua (70) - 070/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Zoroark - 071/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Zoroark - 071/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Bisharp (72) - 072/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Bisharp (72) - 072/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Vullaby - 073/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Vullaby - 073/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Escavalier - 074/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Escavalier - 074/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Klink - 075/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Klink - 075/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Klang - 076/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Klang - 076/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Klinklang - 077/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Klinklang - 077/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Pawniard - 078/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Pawniard - 078/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Bisharp (79) - 079/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Bisharp (79) - 079/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Chansey (80) - 080/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Chansey (80) - 080/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Chansey (81) - 081/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Chansey (81) - 081/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Blissey - 082/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Blissey - 082/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Eevee (83) - 083/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Eevee (83) - 083/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Eevee (84) - 084/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Eevee (84) - 084/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Chatot - 085/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Chatot - 085/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Lillipup - 086/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Lillipup - 086/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Herdier - 087/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Herdier - 087/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Stoutland - 088/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Stoutland - 088/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Haxorus - 089/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Haxorus - 089/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Tornadus EX - 090/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Cheren - 091/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Cheren - 091/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Dark Claw - 092/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Dark Claw - 092/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Dark Patch - 093/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Dark Patch - 093/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Enhanced Hammer - 094/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Enhanced Hammer - 094/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Hooligans Jim & Cas - 095/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Hooligans Jim & Cas - 095/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - N (Supporter) - 096/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - N (Supporter) - 096/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Old Amber Aerodactyl - 097/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Old Amber Aerodactyl - 097/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Professor Juniper - 098/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Professor Juniper - 098/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Random Receiver - 099/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Random Receiver - 099/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Rare Candy - 100/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Rare Candy - 100/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Twist Mountain - 101/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Twist Mountain - 101/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Ultra Ball - 102/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Ultra Ball - 102/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Entei EX (103 Full Art) - 103/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Kyogre EX (104 Full Art) - 104/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Raikou EX (105 Full Art) - 105/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Raikou EX (105 Full Art) - 106/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Darkrai EX (107 Full Art) - 107/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Tornadus EX (108 Full Art) - 108/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Gardevoir - 109/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Archeops - 110/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dark Explorers - Pokemon Catcher (Gold) - 111/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Pinsir - 001/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Pinsir - 001/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Seedot - 002/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Seedot - 002/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Kricketot - 003/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Kricketot - 003/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Kricketune - 004/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Kricketune - 004/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Shaymin EX - 005/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Pansage - 006/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Pansage - 006/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Simisage - 007/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Simisage - 007/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Foongus - 008/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Foongus - 008/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Amoonguss - 009/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Amoonguss - 009/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Growlithe (10) - 010/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Growlithe (10) - 010/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Growlithe (11) - 011/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Growlithe (11) - 011/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Arcanine (12) - 012/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Arcanine (12) - 012/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Arcanine (13) - 013/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Arcanine (13) - 013/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Moltres - 014/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Moltres - 014/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Pansear - 015/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Pansear - 015/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Simisear - 016/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Simisear - 016/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Darumaka - 017/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Darumaka - 017/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Litwick - 018/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Litwick - 018/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Lampent - 019/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Lampent - 019/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Chandelure - 020/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Chandelure - 020/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Reshiram - 021/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Reshiram - 021/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Reshiram EX - 022/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Staryu - 023/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Staryu - 023/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Starmie - 024/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Starmie - 024/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Lapras (25) - 025/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Lapras (25) - 025/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Lapras (26) - 026/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Lapras (26) - 026/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Articuno - 027/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Articuno - 027/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Panpour - 028/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Panpour - 028/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Simipour - 029/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Simipour - 029/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Basculin - 030/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Basculin - 030/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Vanillite - 031/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Vanillite - 031/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Vanillish - 032/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Vanillish - 032/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Vanilluxe - 033/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Vanilluxe - 033/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Frillish - 034/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Frillish - 034/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Jellicent - 035/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Jellicent - 035/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Cubchoo - 036/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Cubchoo - 036/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Beartic - 037/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Beartic - 037/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Kyurem EX - 038/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Pikachu - 039/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Pikachu - 039/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Raichu - 040/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Raichu - 040/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Zapdos - 041/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Zapdos - 041/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Shinx (42) - 042/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Shinx (42) - 042/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Shinx (43) - 043/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Shinx (43) - 043/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Luxio (44) - 044/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Luxio (44) - 044/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Luxio (45) - 045/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Luxio (45) - 045/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Luxray - 046/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Luxray - 046/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Blitzle - 047/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Blitzle - 047/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Zebstrika - 048/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Zebstrika - 048/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Emolga - 049/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Emolga - 049/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Zekrom - 050/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Zekrom - 050/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Zekrom EX - 051/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Grimer - 052/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Grimer - 052/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Muk - 053/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Muk - 053/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Mewtwo EX - 054/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Ralts - 055/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Ralts - 055/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Kirlia - 056/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Kirlia - 056/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Gardevoir - 057/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Gardevoir - 057/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Munna - 058/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Munna - 058/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Musharna - 059/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Musharna - 059/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Darmanitan - 060/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Darmanitan - 060/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Elgyem - 061/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Elgyem - 061/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Beheeyem - 062/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Beheeyem - 062/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Riolu - 063/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Riolu - 063/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Lucario - 064/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Lucario - 064/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Hippopotas - 065/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Hippopotas - 065/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Hippowdon - 066/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Hippowdon - 066/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Mienfoo - 067/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Mienfoo - 067/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Mienshao - 068/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Mienshao - 068/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Sneasel - 069/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Sneasel - 069/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Weavile - 070/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Weavile - 070/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Nuzleaf - 071/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Nuzleaf - 071/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Shiftry - 072/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Shiftry - 072/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Scraggy - 073/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Scraggy - 073/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Scrafty - 074/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Scrafty - 074/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Bronzor - 075/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Bronzor - 075/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Bronzong - 076/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Bronzong - 076/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Ferroseed - 077/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Ferroseed - 077/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Jigglypuff - 078/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Jigglypuff - 078/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Wigglytuff - 079/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Wigglytuff - 079/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Meowth - 080/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Meowth - 080/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Persian - 081/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Persian - 081/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Regigigas EX - 082/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Pidove - 083/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Pidove - 083/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Minccino - 084/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Minccino - 084/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Cinccino - 085/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Cinccino - 085/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Cilan - 086/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Cilan - 086/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Exp. Share - 087/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Exp. Share - 087/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Heavy Ball - 088/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Heavy Ball - 088/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Level Ball - 089/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Level Ball - 089/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Pokemon Center - 090/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Pokemon Center - 090/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Skyarrow Bridge - 091/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Skyarrow Bridge - 091/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Double Colorless Energy - 092/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Double Colorless Energy - 092/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Prism Energy - 093/99 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Prism Energy - 093/99 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Shaymin EX (94 Full Art) - 094/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Reshiram EX (95 Full Art) - 095/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Kyurem EX (96 Full Art) - 096/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Zekrom EX (97 Full Art) - 097/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Mewtwo EX (98 Full Art) - 098/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Regigigas EX (99 Full Art) - 099/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Emboar - 100/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Chandelure (Secret) - 101/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Zoroark - 102/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Next Destinies - Hydreigon - 103/99 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Sewaddle - 001/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Sewaddle - 001/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Swadloon - 002/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Swadloon - 002/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Leavanny - 003/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Leavanny - 003/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Petilil - 004/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Petilil - 004/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Lilligant - 005/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Lilligant - 005/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Dwebble - 006/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Dwebble - 006/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Crustle - 007/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Crustle - 007/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Karrablast - 008/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Karrablast - 008/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Foongus - 009/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Foongus - 009/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Amoonguss - 010/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Amoonguss - 010/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Shelmet - 011/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Shelmet - 011/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Accelgor - 012/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Accelgor - 012/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Virizion - 013/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Virizion - 013/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Victini (14) - 014/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Victini (14) - 014/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Victini (15) - 015/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Victini (15) - 015/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Pansear - 016/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Pansear - 016/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Simisear - 017/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Simisear - 017/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Heatmor - 018/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Heatmor - 018/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Larvesta (19) - 019/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Larvesta (19) - 019/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Larvesta (20) - 020/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Larvesta (20) - 020/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Volcarona - 021/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Volcarona - 021/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Tympole - 022/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Tympole - 022/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Palpitoad - 023/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Palpitoad - 023/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Seismitoad - 024/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Seismitoad - 024/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Tirtouga - 025/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Tirtouga - 025/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Carracosta - 026/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Carracosta - 026/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Vanillite - 027/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Vanillite - 027/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Vanillish - 028/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Vanillish - 028/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Vanilluxe - 029/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Vanilluxe - 029/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Frillish - 030/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Frillish - 030/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Jellicent - 031/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Jellicent - 031/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Cryogonal (32) - 032/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Cryogonal (32) - 032/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Cryogonal (33) - 033/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Cryogonal (33) - 033/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Kyurem - 034/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Kyurem - 034/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Blitzle - 035/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Blitzle - 035/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Zebstrika - 036/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Zebstrika - 036/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Emolga - 037/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Emolga - 037/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Tynamo (38) - 038/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Tynamo (38) - 038/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Tynamo (39) - 039/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Tynamo (39) - 039/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Eelektrik - 040/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Eelektrik - 040/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Eelektross - 041/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Eelektross - 041/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Stunfisk (42) - 042/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Stunfisk (42) - 042/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Victini (43) - 043/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Victini (43) - 043/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Yamask (44) - 044/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Yamask (44) - 044/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Yamask (45) - 045/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Yamask (45) - 045/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Cofagrigus (46) - 046/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Cofagrigus (46) - 046/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Cofagrigus (47) - 047/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Cofagrigus (47) - 047/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Trubbish - 048/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Trubbish - 048/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Garbodor - 049/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Garbodor - 049/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Solosis - 050/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Solosis - 050/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Duosion - 051/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Duosion - 051/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Reuniclus (52) - 052/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Reuniclus (52) - 052/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Reuniclus (53) - 053/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Reuniclus (53) - 053/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Elgyem (54) - 054/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Elgyem (54) - 054/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Elgyem (55) - 055/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Elgyem (55) - 055/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Beheeyem - 056/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Beheeyem - 056/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Litwick (57) - 057/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Litwick (57) - 057/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Litwick (58) - 058/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Litwick (58) - 058/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Lampent - 059/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Lampent - 059/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Chandelure - 060/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Chandelure - 060/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Gigalith - 061/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Gigalith - 061/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Timburr - 062/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Timburr - 062/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Gurdurr - 063/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Gurdurr - 063/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Conkeldurr (64) - 064/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Conkeldurr (64) - 064/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Conkeldurr (65) - 065/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Conkeldurr (65) - 065/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Archen - 066/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Archen - 066/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Archeops - 067/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Archeops - 067/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Stunfisk (68) - 068/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Stunfisk (68) - 068/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Mienfoo - 069/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Mienfoo - 069/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Mienshao - 070/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Mienshao - 070/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Golett - 071/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Golett - 071/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Golurk - 072/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Golurk - 072/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Terrakion - 073/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Terrakion - 073/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Landorus - 074/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Landorus - 074/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Pawniard (75) - 075/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Pawniard (75) - 075/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Bisharp (76) - 076/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Bisharp (76) - 076/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Deino - 077/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Deino - 077/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Zweilous - 078/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Zweilous - 078/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Hydreigon - 079/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Hydreigon - 079/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Escavalier - 080/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Escavalier - 080/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Pawniard (81) - 081/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Pawniard (81) - 081/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Bisharp (82) - 082/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Bisharp (82) - 082/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Durant - 083/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Durant - 083/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Cobalion - 084/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Cobalion - 084/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Audino - 085/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Audino - 085/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Axew - 086/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Axew - 086/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Fraxure - 087/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Fraxure - 087/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Haxorus - 088/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Haxorus - 088/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Druddigon - 089/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Druddigon - 089/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Cover Fossil - 090/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Cover Fossil - 090/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Eviolite - 091/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Eviolite - 091/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - N (Supporter) - 092/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - N (Supporter) - 092/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Plume Fossil - 093/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Plume Fossil - 093/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Rocky Helmet - 094/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Rocky Helmet - 094/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Super Rod - 095/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Super Rod - 095/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Xtransceiver - 096/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Xtransceiver - 096/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Virizion (Full Art) - 097/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Victini (98 Full Art) - 098/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Terrakion (99 Full Art) - 099/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Cobalion (100 Full Art) - 100/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - N (Supporter) (Full Art) - 101/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Noble Victories - Meowth - 102/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Pansage - 001/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Pansage - 001/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Simisage - 002/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Simisage - 002/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Sewaddle - 003/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Sewaddle - 003/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Sewaddle - 004/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Sewaddle - 004/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Swadloon - 005/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Swadloon - 005/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Swadloon - 006/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Swadloon - 006/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Leavanny - 007/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Leavanny - 007/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Leavanny - 008/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Leavanny - 008/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Cottonee - 009/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Cottonee - 009/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Cottonee (10) - 010/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Cottonee (10) - 010/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Whimsicott (11) - 011/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Whimsicott (11) - 011/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Whimsicott (12) - 012/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Whimsicott (12) - 012/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Petilil - 013/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Petilil - 013/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Lilligant - 014/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Lilligant - 014/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Deerling - 015/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Deerling - 015/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Sawsbuck - 016/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Sawsbuck - 016/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Virizion - 017/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Virizion - 017/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Pansear - 018/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Pansear - 018/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Simisear - 019/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Simisear - 019/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Darumaka - 020/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Darumaka - 020/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Darmanitan - 021/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Darmanitan - 021/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Panpour - 022/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Panpour - 022/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Simipour - 023/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Simipour - 023/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Basculin (24) - 024/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Basculin (24) - 024/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Basculin (25) - 025/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Basculin (25) - 025/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Ducklett - 026/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Ducklett - 026/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Swanna - 027/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Swanna - 027/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Cubchoo (28) - 028/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Cubchoo (28) - 028/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Cubchoo (29) - 029/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Cubchoo (29) - 029/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Beartic (30) - 030/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Beartic (30) - 030/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Beartic (31) - 031/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Beartic (31) - 031/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Emolga - 032/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Emolga - 032/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Joltik - 033/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Joltik - 033/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Galvantula - 034/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Galvantula - 034/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Thundurus - 035/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Thundurus - 035/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Woobat - 036/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Woobat - 036/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Swoobat - 037/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Swoobat - 037/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Venipede - 038/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Venipede - 038/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Whirlipede - 039/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Whirlipede - 039/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Scolipede - 040/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Scolipede - 040/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Sigilyph (41) - 041/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Sigilyph (41) - 041/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Sigilyph (42) - 042/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Sigilyph (42) - 042/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Gothita (43) - 043/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Gothita (43) - 043/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Gothita (44) - 044/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Gothita (44) - 044/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Gothorita (45) - 045/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Gothorita (45) - 045/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Gothorita (46) - 046/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Gothorita (46) - 046/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Gothitelle (47) - 047/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Gothitelle (47) - 047/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Gothitelle (48) - 048/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Gothitelle (48) - 048/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Roggenrola (49) - 049/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Roggenrola (49) - 049/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Roggenrola (50) - 050/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Roggenrola (50) - 050/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Boldore (51) - 051/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Boldore (51) - 051/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Boldore (52) - 052/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Boldore (52) - 052/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Gigalith (53) - 053/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Gigalith (53) - 053/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Drilbur (54) - 054/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Drilbur (54) - 054/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Drilbur (55) - 055/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Drilbur (55) - 055/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Excadrill (56) - 056/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Excadrill (56) - 056/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Excadrill (57) - 057/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Excadrill (57) - 057/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Throh - 058/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Throh - 058/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Sawk - 059/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Sawk - 059/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Sandile - 060/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Sandile - 060/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Krokorok - 061/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Krokorok - 061/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Krookodile - 062/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Krookodile - 062/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Terrakion - 063/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Terrakion - 063/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Purrloin - 064/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Purrloin - 064/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Liepard - 065/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Liepard - 065/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Zorua - 066/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Zorua - 066/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Zoroark - 067/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Zoroark - 067/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Vullaby - 068/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Vullaby - 068/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Mandibuzz - 069/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Mandibuzz - 069/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Ferroseed (70) - 070/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Ferroseed (70) - 070/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Ferroseed (71) - 071/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Ferroseed (71) - 071/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Ferrothorn - 072/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Ferrothorn - 072/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Ferrothorn - 073/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Ferrothorn - 073/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Klink - 074/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Klink - 074/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Klang - 075/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Klang - 075/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Klinklang - 076/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Klinklang - 076/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Cobalion - 077/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Cobalion - 077/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Patrat - 078/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Patrat - 078/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Watchog - 079/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Watchog - 079/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Pidove - 080/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Pidove - 080/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Tranquill - 081/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Tranquill - 081/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Unfezant - 082/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Unfezant - 082/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Audino - 083/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Audino - 083/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Minccino - 084/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Minccino - 084/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Cinccino - 085/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Cinccino - 085/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Rufflet (86) - 086/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Rufflet (86) - 086/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Rufflet (87) - 087/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Rufflet (87) - 087/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Braviary - 088/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Braviary - 088/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Tornadus - 089/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Tornadus - 089/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Bianca - 090/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Bianca - 090/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Cheren - 091/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Cheren - 091/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Crushing Hammer - 092/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Crushing Hammer - 092/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Great Ball - 093/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Great Ball - 093/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Max Potion - 094/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Max Potion - 094/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Pokemon Catcher - 095/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Pokemon Catcher - 095/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Recycle - 096/98 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Recycle - 096/98 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Thundurus (97 Full Art) - 097/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerging Powers - Tornadus (98 Full Art) - 098/98 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Snivy - 001/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Snivy - 001/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Snivy - 002/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Snivy - 002/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Servine - 003/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Servine - 003/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Servine - 004/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Servine - 004/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Serperior - 005/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Serperior - 005/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Serperior - 006/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Serperior - 006/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Pansage - 007/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Pansage - 007/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Simisage - 008/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Simisage - 008/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Petilil - 009/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Petilil - 009/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Lilligant - 010/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Lilligant - 010/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Maractus - 011/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Maractus - 011/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Maractus - 012/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Maractus - 012/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Deerling - 013/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Deerling - 013/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Sawsbuck - 014/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Sawsbuck - 014/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Tepig (15) - 015/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Tepig (15) - 015/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Tepig (16) - 016/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Tepig (16) - 016/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Pignite (17) - 017/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Pignite (17) - 017/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Pignite (18) - 018/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Pignite (18) - 018/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Emboar (19) - 019/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Emboar (19) - 019/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Emboar (20) - 020/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Emboar (20) - 020/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Pansear - 021/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Pansear - 021/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Simisear - 022/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Simisear - 022/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Darumaka (23) - 023/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Darumaka (23) - 023/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Darumaka (24) - 024/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Darumaka (24) - 024/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Darmanitan - 025/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Darmanitan - 025/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Reshiram - 026/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Reshiram - 026/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Oshawott (27) - 027/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Oshawott (27) - 027/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Oshawott (28) - 028/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Oshawott (28) - 028/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Dewott (29) - 029/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Dewott (29) - 029/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Dewott (30) - 030/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Dewott (30) - 030/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Samurott (31) - 031/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Samurott (31) - 031/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Samurott (32) - 032/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Samurott (32) - 032/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Panpour - 033/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Panpour - 033/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Simipour - 034/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Simipour - 034/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Basculin - 035/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Basculin - 035/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Ducklett - 036/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Ducklett - 036/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Swanna - 037/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Swanna - 037/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Alomomola (38) - 038/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Alomomola (38) - 038/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Alomomola (39) - 039/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Alomomola (39) - 039/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Blitzle (40) - 040/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Blitzle (40) - 040/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Blitzle (41) - 041/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Blitzle (41) - 041/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Zebstrika (42) - 042/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Zebstrika (42) - 042/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Zebstrika (43) - 043/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Zebstrika (43) - 043/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Joltik (44) - 044/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Joltik (44) - 044/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Joltik (45) - 045/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Joltik (45) - 045/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Galvantula - 046/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Galvantula - 046/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Zekrom - 047/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Zekrom - 047/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Munna - 048/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Munna - 048/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Musharna - 049/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Musharna - 049/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Woobat - 050/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Woobat - 050/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Swoobat - 051/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Swoobat - 051/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Venipede - 052/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Venipede - 052/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Whirlipede - 053/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Whirlipede - 053/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Scolipede - 054/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Scolipede - 054/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Solosis - 055/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Solosis - 055/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Duosion - 056/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Duosion - 056/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Reuniclus - 057/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Reuniclus - 057/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Timburr (58) - 058/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Timburr (58) - 058/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Timburr (59) - 059/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Timburr (59) - 059/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Gurdurr - 060/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Gurdurr - 060/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Throh - 061/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Throh - 061/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Sawk - 062/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Sawk - 062/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Sandile - 063/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Sandile - 063/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Krokorok - 064/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Krokorok - 064/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Krookodile - 065/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Krookodile - 065/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Purrloin - 066/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Purrloin - 066/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Liepard - 067/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Liepard - 067/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Scraggy - 068/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Scraggy - 068/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Scrafty - 069/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Scrafty - 069/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Zorua - 070/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Zorua - 070/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Zoroark - 071/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Zoroark - 071/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Vullaby - 072/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Vullaby - 072/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Mandibuzz - 073/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Mandibuzz - 073/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Klink - 074/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Klink - 074/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Klang - 075/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Klang - 075/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Klinklang - 076/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Klinklang - 076/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Patrat (77) - 077/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Patrat (77) - 077/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Patrat (78) - 078/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Patrat (78) - 078/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Watchog - 079/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Watchog - 079/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Lillipup (80) - 080/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Lillipup (80) - 080/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Lillipup (81) - 081/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Lillipup (81) - 081/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Herdier - 082/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Herdier - 082/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Stoutland - 083/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Stoutland - 083/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Pidove - 084/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Pidove - 084/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Tranquill - 085/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Tranquill - 085/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Unfezant - 086/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Unfezant - 086/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Audino - 087/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Audino - 087/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Minccino - 088/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Minccino - 088/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Cinccino - 089/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Cinccino - 089/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Bouffalant (90) - 090/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Bouffalant (90) - 090/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Bouffalant (91) - 091/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Bouffalant (91) - 091/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Energy Retrieval - 092/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Energy Retrieval - 092/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Energy Search - 093/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Energy Search - 093/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Energy Switch - 094/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Energy Switch - 094/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Full Heal - 095/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Full Heal - 095/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - PlusPower - 096/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - PlusPower - 096/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Poke Ball - 097/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Poke Ball - 097/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Pokedex - 098/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Pokedex - 098/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Pokemon Communication - 099/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Pokemon Communication - 099/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Potion - 100/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Potion - 100/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Professor Juniper - 101/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Professor Juniper - 101/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Revive - 102/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Revive - 102/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Super Scoop Up - 103/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Super Scoop Up - 103/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Switch - 104/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Switch - 104/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Grass Energy - 105/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Grass Energy - 105/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Fire Energy - 106/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Fire Energy - 106/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Water Energy - 107/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Water Energy - 107/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Lightning Energy - 108/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Psychic Energy - 109/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Fighting Energy - 110/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Fighting Energy - 110/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Darkness Energy (Basic) - 111/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Darkness Energy (Basic) - 111/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Metal Energy (Basic) - 112/114 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Metal Energy (Basic) - 112/114 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Reshiram (113 Full Art) - 113/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Zekrom (114 Full Art) - 114/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White - Pikachu - 115/114 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Snivy (BW01) - 001/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Tepig (BW02) - 002/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Oshawott (BW03) - 003/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Reshiram (BW004) - 004/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Zekrom (BW005) - 005/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Snivy (BW06) - 006/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Tepig (BW07) - 007/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Oshawott (BW08) - 008/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Zoroark (BW09) - 009/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Axew (BW10) - 010/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Pansage (BW11) - 011/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Arcanine - 12/99 (Prerelease Promo) [Staff] - 012/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Arcanine - 12/99 (Prerelease Promo) - 012/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Zorua - 012/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Minccino (Cosmos Holo) - 013/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Minccino (Cracked Ice Holo) - 013/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Pansage (BW14) - 014/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Pidove - 015/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Axew (BW16) - 016/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Axew (BW16) - 016/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Ducklett - 017/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Darumaka - 018/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Zoroark (BW19) - 019/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Serperior - 020/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Emboar - 021/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Samurott - 022/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Reshiram (BW23) - 023/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Zekrom (BW24) - 024/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Scraggy (Cracked Ice Holo) - 025/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Scraggy (Cosmos Holo) - 025/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Axew (BW26) - 026/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Litwick - 027/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Tropical Beach (Staff) - 028/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Tropical Beach (Top 16) - 028/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Tropical Beach - 028/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Tropical Beach (Top 32) - 028/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Victory Cup (3rd - Spring 2012) - 029/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Victory Cup (3rd - Autumn 2012) - 029/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Victory Cup (3rd - Spring 2013) - 029/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Victory Cup (3rd - Autumn 2011) - 029/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Victory Cup (2nd - Spring 2012) - 030/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Victory Cup (2nd - Autumn 2012) - 030/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Victory Cup (2nd - Spring 2013) - 030/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Victory Cup (2nd - Autumn 2011) - 030/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Victory Cup (1st - Spring 2012) - 031/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Victory Cup (1st - Autumn 2011) - 031/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Victory Cup (1st - Spring 2013) - 031/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Victory Cup (1st - Autumn 2012) - 031/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Victini - 032/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Riolu (Cosmos Holo) - 033/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Riolu - 033/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Luxio (Cracked Ice Holo) - 034/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Luxio (Cosmos Holo) - 034/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Meowth - 035/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Reshiram EX - 036/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Kyurem EX - 037/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Zekrom EX - 038/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Battle City - 039/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Volcarona (Prerelease) - 040/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Volcarona (Staff Prerelease) - 040/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Thundurus - 041/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Tornadus - 042/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Landorus (BW43) - 043/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Kyurem - 044/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Mewtwo EX - 045/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Darkrai EX - 046/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Rayquaza EX - 047/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Altaria (Prerelease Promo) - 048/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Altaria (Prerelease Promo) [Staff] - 048/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Lilligant - 049/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Tropical Beach (Semi Finalist - BW50) - 050/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Tropical Beach (Top 32 - BW50) - 050/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Tropical Beach (BW50) - 050/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Tropical Beach (Staff - BW50) - 050/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Tropical Beach (Top 16 - BW50) - 050/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Crobat (Team Plasma - Staff Prerelease) - 051/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Crobat (Team Plasma - Prerelease) - 051/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Lillipup - 052/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Gigalith - 53/98 (Prerelease Promo) [Staff] - 053/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Flygon (Prerelease) - 053/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Flygon (Staff Prerelease) - 053/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Gigalith - 53/98 (Prerelease Promo) - 053/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Pikachu - 054/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Pikachu - 054/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Pikachu (Non-Holo) - 054/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Elgyem - 055/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Empoleon - 056/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Haxorus - 057/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Black Kyurem - 058/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - White Kyurem - 059/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Keldeo (Tinsel Holo) - 060/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Keldeo (Non Holo) - 060/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Keldeo EX - 061/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Black Kyurem EX - 062/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - White Kyurem EX - 063/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Drifblim - 064/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Jigglypuff - 065/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Ninetales - 066/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Ampharos - 067/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Meloetta - BW68 - 068/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Meloetta - BW69 - 069/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Virizion - 070/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Terrakion - 071/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Cobalion - 072/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Darkrai (Team Plasma) - 073/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Giratina (Team Plasma) - 074/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Metagross (Team Plasma)(Prerelease) - 075/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Metagross (Team Plasma)(Staff Prerelease) - 075/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Electrode (Team Plasma) - 076/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Landorus (Team Plasma) - 079/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Druddigon (Team Plasma) - 080/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Druddigon (Team Plasma) (Cosmos Holo) - 080/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Thundurus EX (Team Plasma) - 081/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Deoxys EX (Team Plasma) - 082/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Lugia EX (Team Plasma) - 083/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Porygon-Z (Team Plasma)(Staff Prerelease) - 084/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Porygon-Z (Team Plasma)(Prerelease) - 084/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Lucario - 085/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Genesect (Team Plasma) - 086/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Leafeon - 087/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Flareon - 088/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Vaporeon - 089/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Glaceon - 090/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Jolteon - 091/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Espeon - 092/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Umbreon - 093/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Eevee (BW94) - 094/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Champions Festival (2013) - 095/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Champions Festival (2013) [Staff] - 095/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Tornadus EX (Staff Prerelease) - 096/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Tornadus EX (Prerelease) - 096/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Eevee (BW97) - 097/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Mew - 098/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Genesect - 099/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - N (Supporter) - 100/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Genesect (Legend Awakened Promo) - 101/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Pikachu (JP PKM BW National Illustrated Encyclopedia Promo) - 163/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Pikachu - 229/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Darmanitan - 25/114 (Prerelease Promo) - 025/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Darmanitan - 25/114 (Prerelease Promo) [Staff] - 025/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Victini - 43/101 (Prerelease Promo) [Staff] - 043/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Black and White Promos - Victini - 43/101 (Prerelease Promo) - 043/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Deoxys (Shiny) - 001/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Clefable - 001/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Clefable - 001/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Dialga (Shiny) - 002/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Deoxys - 002/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Deoxys - 002/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Entei (Shiny) - 003/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Dialga - 003/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Dialga - 003/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Espeon - 004/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Espeon - 004/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Groudon (Shiny) - 004/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Ho-Oh (Shiny) - 005/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Forretress - 005/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Forretress - 005/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Kyogre (Shiny) - 006/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Groudon - 006/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Groudon - 006/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Gyarados - 007/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Gyarados - 007/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Lugia (Shiny) - 007/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Palkia (Shiny) - 008/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Hitmontop - 008/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Hitmontop - 008/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Ho-Oh - 009/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Ho-Oh - 009/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Raikou (Shiny) - 009/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Rayquaza (Shiny) - 010/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Houndoom - 010/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Houndoom - 010/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Suicune (Shiny) - 011/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Jirachi - 011/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Jirachi - 011/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Kyogre - 012/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Kyogre - 012/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Leafeon - 013/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Leafeon - 013/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Lucario - 014/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Lucario - 014/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Lugia - 015/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Lugia - 015/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Magmortar - 016/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Magmortar - 016/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Ninetales - 017/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Ninetales - 017/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Pachirisu - 018/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Pachirisu - 018/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Palkia - 019/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Palkia - 019/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Rayquaza - 020/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Rayquaza - 020/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Smeargle - 021/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Smeargle - 021/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Umbreon - 022/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Umbreon - 022/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Ampharos - 023/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Ampharos - 023/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Cleffa - 024/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Cleffa - 024/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Feraligatr - 025/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Feraligatr - 025/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Granbull - 026/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Granbull - 026/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Meganium - 027/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Meganium - 027/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Mismagius - 028/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Mismagius - 028/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Mr. Mime - 029/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Mr. Mime - 029/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Pidgeot - 030/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Pidgeot - 030/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Skarmory - 031/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Skarmory - 031/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Slowking - 032/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Slowking - 032/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Snorlax - 033/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Snorlax - 033/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Tangrowth - 034/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Tangrowth - 034/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Typhlosion - 035/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Typhlosion - 035/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Tyrogue - 036/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Tyrogue - 036/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Ursaring - 037/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Ursaring - 037/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Weezing - 038/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Weezing - 038/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Zangoose - 039/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Zangoose - 039/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Bayleef - 040/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Bayleef - 040/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Croconaw - 041/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Croconaw - 041/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Donphan - 042/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Donphan - 042/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Flaaffy - 043/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Flaaffy - 043/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Flareon - 044/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Flareon - 044/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Jolteon - 045/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Jolteon - 045/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Magby - 046/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Magby - 046/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Mime Jr. - 047/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Mime Jr. - 047/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Pidgeotto - 048/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Pidgeotto - 048/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Quilava - 049/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Quilava - 049/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Riolu - 050/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Riolu - 050/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Seviper - 051/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Seviper - 051/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Vaporeon - 052/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Vaporeon - 052/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Chikorita - 053/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Chikorita - 053/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Clefairy - 054/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Clefairy - 054/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Cyndaquil - 055/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Cyndaquil - 055/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Eevee - 056/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Eevee - 056/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Hitmonchan - 057/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Hitmonchan - 057/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Hitmonlee - 058/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Hitmonlee - 058/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Houndour - 059/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Houndour - 059/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Koffing - 060/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Koffing - 060/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Magikarp - 061/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Magikarp - 061/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Magmar - 062/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Magmar - 062/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Mareep - 063/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Mareep - 063/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Mawile - 064/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Mawile - 064/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Misdreavus - 065/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Misdreavus - 065/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Phanpy - 066/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Phanpy - 066/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Pidgey - 067/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Pidgey - 067/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Pineco - 068/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Pineco - 068/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Relicanth - 069/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Relicanth - 069/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Slowpoke - 070/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Slowpoke - 070/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Snubbull - 071/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Snubbull - 071/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Tangela - 072/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Tangela - 072/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Teddiursa - 073/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Teddiursa - 073/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Totodile - 074/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Totodile - 074/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Vulpix - 075/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Vulpix - 075/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Cheerleader's Cheer - 076/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Cheerleader's Cheer - 076/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Copycat - 077/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Copycat - 077/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Dual Ball - 078/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Dual Ball - 078/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Interviewer's Questions - 079/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Interviewer's Questions - 079/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Lost Remover - 080/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Lost Remover - 080/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Lost World - 081/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Lost World - 081/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Professor Elm's Training Method - 082/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Professor Elm's Training Method - 082/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Professor Oak's New Theory - 083/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Professor Oak's New Theory - 083/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Research Record - 084/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Research Record - 084/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Research Record - 085/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Research Record - 085/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Darkness Energy (Special) - 086/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Darkness Energy (Special) - 086/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Metal Energy (Special) - 087/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Metal Energy (Special) - 087/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Grass Energy - 088/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Fire Energy - 089/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Water Energy - 090/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Lightning Energy - 091/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Psychic Energy - 092/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Fighting Energy - 093/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Darkness Energy (Basic) - 094/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Call of Legends - Metal Energy (Basic) - 095/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Aggron - 001/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Aggron - 001/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Altaria - 002/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Altaria - 002/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Celebi - 003/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Celebi - 003/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Alph Lithograph - 004/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Drapion - 004/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Drapion - 004/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Mamoswine - 005/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Mamoswine - 005/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Nidoking - 006/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Nidoking - 006/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Porygon-Z - 007/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Porygon-Z - 007/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Rapidash - 008/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Rapidash - 008/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Solrock - 009/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Solrock - 009/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Spiritomb - 010/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Spiritomb - 010/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Venomoth - 011/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Venomoth - 011/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Victreebel - 012/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Victreebel - 012/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Ambipom - 013/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Ambipom - 013/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Banette - 014/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Banette - 014/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Bronzong - 015/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Bronzong - 015/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Carnivine - 016/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Carnivine - 016/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Ditto - 017/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Ditto - 017/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Dragonite - 018/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Dragonite - 018/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Dugtrio - 019/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Dugtrio - 019/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Electivire - 020/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Electivire - 020/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Elekid - 021/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Elekid - 021/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Golduck - 022/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Golduck - 022/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Grumpig - 023/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Grumpig - 023/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Kricketune - 024/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Kricketune - 024/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Lunatone - 025/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Lunatone - 025/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Machamp - 026/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Machamp - 026/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Magmortar - 027/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Magmortar - 027/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Nidoqueen - 028/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Nidoqueen - 028/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Pidgeot - 029/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Pidgeot - 029/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Sharpedo - 030/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Sharpedo - 030/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Wailord - 031/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Wailord - 031/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Dragonair - 032/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Dragonair - 032/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Electabuzz - 033/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Electabuzz - 033/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Electrode - 034/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Electrode - 034/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Haunter - 035/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Haunter - 035/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Kangaskhan - 036/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Kangaskhan - 036/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Lairon - 037/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Lairon - 037/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Lickilicky - 038/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Lickilicky - 038/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Luvdisc - 039/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Luvdisc - 039/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Machoke - 040/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Machoke - 040/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Magby - 041/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Magby - 041/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Magmar - 042/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Magmar - 042/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Magneton - 043/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Magneton - 043/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Marowak - 044/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Marowak - 044/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Nidorina - 045/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Nidorina - 045/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Nidorino - 046/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Nidorino - 046/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Pidgeotto - 047/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Pidgeotto - 047/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Piloswine - 048/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Piloswine - 048/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Porygon2 - 049/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Porygon2 - 049/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Tentacruel - 050/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Tentacruel - 050/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Unown - 051/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Unown - 051/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Wailmer - 052/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Wailmer - 052/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Weepinbell - 053/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Weepinbell - 053/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Yanmega - 054/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Yanmega - 054/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Aipom - 055/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Aipom - 055/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Aron - 056/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Aron - 056/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Bellsprout - 057/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Bellsprout - 057/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Bronzor - 058/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Bronzor - 058/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Carvanha - 059/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Carvanha - 059/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Cubone - 060/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Cubone - 060/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Diglett - 061/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Diglett - 061/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Dratini - 062/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Dratini - 062/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Gastly - 063/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Gastly - 063/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Illumise - 064/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Illumise - 064/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Kricketot - 065/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Kricketot - 065/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Lickitung - 066/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Lickitung - 066/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Machop - 067/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Machop - 067/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Magnemite - 068/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Magnemite - 068/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Nidoran F - 069/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Nidoran F - 069/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Nidoran M - 070/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Nidoran M - 070/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Pidgey - 071/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Pidgey - 071/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Ponyta - 072/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Ponyta - 072/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Porygon - 073/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Porygon - 073/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Psyduck - 074/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Psyduck - 074/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Shuppet - 075/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Shuppet - 075/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Skorupi - 076/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Skorupi - 076/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Spoink - 077/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Spoink - 077/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Swablu - 078/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Swablu - 078/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Swinub - 079/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Swinub - 079/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Tentacool - 080/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Tentacool - 080/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Venonat - 081/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Venonat - 081/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Volbeat - 082/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Volbeat - 082/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Voltorb - 083/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Voltorb - 083/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Yanma - 084/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Yanma - 084/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Black Belt - 085/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Black Belt - 085/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Indigo Plateau - 086/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Indigo Plateau - 086/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Junk Arm - 087/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Junk Arm - 087/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Seeker - 088/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Seeker - 088/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Twins - 089/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Twins - 089/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Rescue Energy - 090/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Rescue Energy - 090/102 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Absol (Prime) - 091/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Celebi (Prime) - 092/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Electrode (Prime) - 093/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Gengar (Prime) - 094/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Machamp (Prime) - 095/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Magnezone (Prime) - 096/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Mew (Prime) - 097/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Yanmega (Prime) - 098/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Darkrai & Cresselia Legend (Top) - 099/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Darkrai & Cresselia Legend (Bottom) - 100/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Palkia & Dialga Legend (Top) - 101/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Palkia & Dialga Legend (Bottom) - 102/102 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Triumphant - Celebi - 3/102 (Non-Holo Movie Exclusive) - 003/102 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Bellossom - 001/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Bellossom - 001/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Espeon - 002/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Espeon - 002/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Alph Lithograph - 003/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Forretress - 003/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Forretress - 003/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Gliscor - 004/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Gliscor - 004/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Houndoom - 005/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Houndoom - 005/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Magcargo - 006/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Magcargo - 006/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Scizor - 007/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Scizor - 007/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Smeargle - 008/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Smeargle - 008/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Togekiss - 009/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Togekiss - 009/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Umbreon - 010/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Umbreon - 010/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Dodrio - 011/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Dodrio - 011/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Drifblim - 012/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Drifblim - 012/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Forretress (13) - 013/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Forretress (13) - 013/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Hariyama - 014/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Hariyama - 014/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Honchkrow (15) - 015/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Honchkrow (15) - 015/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Honchkrow (16) - 016/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Honchkrow (16) - 016/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Leafeon - 017/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Leafeon - 017/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Metagross - 018/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Metagross - 018/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Mismagius - 019/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Mismagius - 019/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Rotom - 020/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Rotom - 020/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Skarmory - 021/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Skarmory - 021/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Tropius - 022/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Tropius - 022/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Vespiquen - 023/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Vespiquen - 023/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Vileplume - 024/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Vileplume - 024/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Weavile - 025/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Weavile - 025/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Flareon - 026/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Flareon - 026/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Gloom - 027/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Gloom - 027/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Jolteon - 028/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Jolteon - 028/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Lairon - 029/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Lairon - 029/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Metang - 030/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Metang - 030/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Muk - 031/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Muk - 031/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Pinsir - 032/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Pinsir - 032/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Raichu - 033/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Raichu - 033/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Raticate - 034/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Raticate - 034/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Sableye - 035/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Sableye - 035/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Scyther (36) - 036/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Scyther (36) - 036/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Skuntank - 037/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Skuntank - 037/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Slowbro - 038/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Slowbro - 038/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Togetic - 039/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Togetic - 039/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Unown - 040/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Unown - 040/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Vaporeon - 041/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Vaporeon - 041/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Aron - 042/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Aron - 042/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Beldum - 043/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Beldum - 043/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Combee - 044/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Combee - 044/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Doduo - 045/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Doduo - 045/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Drifloon - 046/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Drifloon - 046/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Eevee (47) - 047/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Eevee (47) - 047/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Eevee (48) - 048/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Eevee (48) - 048/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Gligar - 049/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Gligar - 049/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Grimer - 050/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Grimer - 050/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Hitmonchan - 051/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Hitmonchan - 051/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Hitmonlee - 052/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Hitmonlee - 052/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Houndour (53) - 053/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Houndour (53) - 053/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Houndour (54) - 054/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Houndour (54) - 054/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Makuhita - 055/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Makuhita - 055/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Mawile - 056/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Mawile - 056/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Misdreavus - 057/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Misdreavus - 057/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Murkrow (58) - 058/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Murkrow (58) - 058/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Murkrow (59) - 059/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Murkrow (59) - 059/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Oddish - 060/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Oddish - 060/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Pikachu - 061/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Pikachu - 061/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Pineco (62) - 062/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Pineco (62) - 062/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Pineco (63) - 063/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Pineco (63) - 063/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Rattata - 064/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Rattata - 064/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Scyther (65) - 065/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Scyther (65) - 065/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Slowpoke - 066/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Slowpoke - 066/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Slugma - 067/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Slugma - 067/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Sneasel - 068/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Sneasel - 068/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Stunky - 069/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Stunky - 069/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Togepi - 070/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Togepi - 070/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Burned Tower - 071/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Burned Tower - 071/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Defender - 072/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Defender - 072/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Energy Exchanger - 073/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Energy Exchanger - 073/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Flower Shop Lady - 074/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Flower Shop Lady - 074/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Legend Box - 075/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Legend Box - 075/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Ruins Of Alph - 076/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Ruins Of Alph - 076/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Sage's Training - 077/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Sage's Training - 077/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Team Rocket's Trickery - 078/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Team Rocket's Trickery - 078/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Darkness Energy (Special) - 079/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Darkness Energy (Special) - 079/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Metal Energy (Special) - 080/90 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Metal Energy (Special) - 080/90 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Espeon (Prime) - 081/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Houndoom (Prime) - 082/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Raichu (Prime) - 083/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Scizor (Prime) - 084/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Slowking (Prime) - 085/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Umbreon (Prime) - 086/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Kyogre & Groudon Legend (Top) - 087/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Kyogre & Groudon Legend (Bottom) - 088/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Rayquaza & Deoxys Legend (Top) - 089/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Undaunted - Rayquaza & Deoxys Legend (Bottom) - 090/90 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Jirachi - 001/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Jirachi - 001/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Magmortar - 002/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Magmortar - 002/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Alph Lithograph - 002/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Manaphy - 003/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Manaphy - 003/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Metagross - 004/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Metagross - 004/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Mismagius - 005/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Mismagius - 005/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Octillery - 006/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Octillery - 006/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Politoed - 007/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Politoed - 007/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Shaymin - 008/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Shaymin - 008/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Sudowoodo - 009/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Sudowoodo - 009/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Torterra - 010/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Torterra - 010/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Xatu - 011/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Xatu - 011/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Beedrill - 012/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Beedrill - 012/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Blastoise - 013/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Blastoise - 013/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Crobat - 014/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Crobat - 014/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Fearow - 015/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Fearow - 015/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Floatzel - 016/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Floatzel - 016/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Kingdra - 017/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Kingdra - 017/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Lanturn - 018/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Lanturn - 018/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Lucario - 019/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Lucario - 019/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Ninetales - 020/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Ninetales - 020/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Poliwrath - 021/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Poliwrath - 021/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Primeape - 022/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Primeape - 022/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Roserade - 023/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Roserade - 023/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Steelix - 024/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Steelix - 024/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Torkoal - 025/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Torkoal - 025/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Tyranitar - 026/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Tyranitar - 026/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Ursaring - 027/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Ursaring - 027/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Cherrim - 028/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Cherrim - 028/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Dunsparce - 029/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Dunsparce - 029/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Golbat - 030/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Golbat - 030/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Grotle - 031/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Grotle - 031/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Kakuna - 032/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Kakuna - 032/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Metang - 033/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Metang - 033/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Minun - 034/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Minun - 034/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Numel - 035/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Numel - 035/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Plusle - 036/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Plusle - 036/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Poliwhirl - 037/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Poliwhirl - 037/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Pupitar (38) - 038/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Pupitar (38) - 038/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Pupitar (39) - 039/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Pupitar (39) - 039/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Seadra - 040/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Seadra - 040/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Tauros - 041/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Tauros - 041/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Wartortle - 042/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Wartortle - 042/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Aipom - 043/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Aipom - 043/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Beldum - 044/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Beldum - 044/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Buizel - 045/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Buizel - 045/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Carnivine - 046/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Carnivine - 046/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Cherubi - 047/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Cherubi - 047/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Chinchou - 048/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Chinchou - 048/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Horsea - 049/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Horsea - 049/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Larvitar (50) - 050/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Larvitar (50) - 050/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Larvitar (51) - 051/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Larvitar (51) - 051/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Magmar - 052/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Magmar - 052/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Mankey - 053/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Mankey - 053/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Misdreavus - 054/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Misdreavus - 054/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Natu - 055/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Natu - 055/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Onix (56) - 056/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Onix (56) - 056/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Onix (57) - 057/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Onix (57) - 057/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Poliwag - 058/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Poliwag - 058/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Remoraid - 059/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Remoraid - 059/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Riolu - 060/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Riolu - 060/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Roselia - 061/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Roselia - 061/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Spearow - 062/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Spearow - 062/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Squirtle - 063/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Squirtle - 063/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Stantler - 064/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Stantler - 064/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Teddiursa - 065/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Teddiursa - 065/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Tropius - 066/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Tropius - 066/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Turtwig - 067/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Turtwig - 067/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Vulpix - 068/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Vulpix - 068/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Weedle - 069/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Weedle - 069/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Zubat - 070/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Zubat - 070/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Cheerleader's Cheer - 071/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Cheerleader's Cheer - 071/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Dual Ball - 072/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Dual Ball - 072/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Emcee's Chatter - 073/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Emcee's Chatter - 073/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Energy Returner - 074/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Energy Returner - 074/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Engineer's Adjustments - 075/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Engineer's Adjustments - 075/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Good Rod - 076/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Good Rod - 076/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Interviewer's Questions - 077/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Interviewer's Questions - 077/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Judge - 078/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Judge - 078/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Life Herb - 079/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Life Herb - 079/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - PlusPower - 080/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - PlusPower - 080/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Pokemon Circulator - 081/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Pokemon Circulator - 081/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Rare Candy - 082/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Rare Candy - 082/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Super Scoop Up - 083/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Super Scoop Up - 083/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Crobat (Prime) - 084/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Kingdra (Prime) - 085/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Lanturn (Prime) - 086/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Steelix (Prime) - 087/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Tyranitar (Prime) - 088/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Ursaring (Prime) - 089/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Entei & Raikou Legend (top) - 090/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Entei & Raikou Legend (bottom) - 091/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Raikou & Suicune Legend (Top) - 092/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Raikou & Suicune Legend (Bottom) - 093/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Suicune & Entei Legend (Top) - 094/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Suicune & Entei Legend (Bottom) - 095/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unleashed - Kingdra - 2011 (Gustavo Wada) - 085/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Arcanine - 001/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Arcanine - 001/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Alph Lithograph - 001/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Azumarill - 002/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Azumarill - 002/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Clefable - 003/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Clefable - 003/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Gyarados - 004/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Gyarados - 004/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Hitmontop - 005/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Hitmontop - 005/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Jumpluff - 006/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Jumpluff - 006/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Ninetales - 007/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Ninetales - 007/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Noctowl - 008/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Noctowl - 008/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Quagsire - 009/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Quagsire - 009/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Raichu - 010/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Raichu - 010/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Shuckle - 011/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Shuckle - 011/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Slowking - 012/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Slowking - 012/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Wobbuffet - 013/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Wobbuffet - 013/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Ampharos - 014/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Ampharos - 014/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Ariados - 015/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Ariados - 015/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Butterfree - 016/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Butterfree - 016/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Cleffa - 017/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Cleffa - 017/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Exeggutor - 018/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Exeggutor - 018/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Farfetch'd - 019/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Farfetch'd - 019/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Feraligatr - 020/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Feraligatr - 020/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Furret - 021/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Furret - 021/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Granbull - 022/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Granbull - 022/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Hypno - 023/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Hypno - 023/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Lapras - 024/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Lapras - 024/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Ledian - 025/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Ledian - 025/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Meganium - 026/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Meganium - 026/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Persian - 027/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Persian - 027/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Pichu - 028/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Pichu - 028/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Sandslash - 029/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Sandslash - 029/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Smoochum - 030/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Smoochum - 030/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Sunflora - 031/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Sunflora - 031/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Typhlosion - 032/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Typhlosion - 032/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Tyrogue - 033/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Tyrogue - 033/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Weezing - 034/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Weezing - 034/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Bayleef - 035/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Bayleef - 035/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Blissey - 036/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Blissey - 036/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Corsola - 037/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Corsola - 037/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Croconaw - 038/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Croconaw - 038/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Delibird - 039/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Delibird - 039/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Donphan - 040/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Donphan - 040/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Dunsparce - 041/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Dunsparce - 041/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Flaaffy - 042/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Flaaffy - 042/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Heracross - 043/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Heracross - 043/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Igglybuff - 044/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Igglybuff - 044/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Mantine - 045/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Mantine - 045/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Metapod - 046/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Metapod - 046/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Miltank - 047/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Miltank - 047/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Parasect - 048/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Parasect - 048/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Quilava - 049/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Quilava - 049/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Qwilfish - 050/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Qwilfish - 050/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Skiploom - 051/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Skiploom - 051/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Slowbro - 052/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Slowbro - 052/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Starmie - 053/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Starmie - 053/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Unown (54) - 054/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Unown (54) - 054/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Unown (55) - 055/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Unown (55) - 055/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Wigglytuff - 056/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Wigglytuff - 056/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Caterpie - 057/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Caterpie - 057/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Chansey - 058/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Chansey - 058/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Chikorita - 059/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Chikorita - 059/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Clefairy - 060/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Clefairy - 060/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Cyndaquil - 061/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Cyndaquil - 061/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Drowzee - 062/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Drowzee - 062/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Exeggcute - 063/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Exeggcute - 063/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Girafarig - 064/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Girafarig - 064/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Growlithe - 065/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Growlithe - 065/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Hoothoot - 066/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Hoothoot - 066/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Hoppip - 067/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Hoppip - 067/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Jigglypuff - 068/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Jigglypuff - 068/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Jynx - 069/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Jynx - 069/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Koffing - 070/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Koffing - 070/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Ledyba - 071/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Ledyba - 071/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Magikarp - 072/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Magikarp - 072/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Mareep - 073/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Mareep - 073/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Marill - 074/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Marill - 074/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Meowth - 075/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Meowth - 075/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Paras - 076/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Paras - 076/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Phanpy - 077/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Phanpy - 077/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Pikachu - 078/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Pikachu - 078/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Sandshrew - 079/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Sandshrew - 079/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Sentret - 080/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Sentret - 080/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Slowpoke - 081/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Slowpoke - 081/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Snubbull - 082/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Snubbull - 082/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Spinarak - 083/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Spinarak - 083/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Staryu - 084/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Staryu - 084/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Sunkern - 085/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Sunkern - 085/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Totodile - 086/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Totodile - 086/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Vulpix - 087/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Vulpix - 087/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Wooper - 088/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Wooper - 088/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Bill - 089/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Bill - 089/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Copycat - 090/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Copycat - 090/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Energy Switch - 091/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Energy Switch - 091/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Fisherman - 092/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Fisherman - 092/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Full Heal - 093/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Full Heal - 093/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Moomoo Milk - 094/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Moomoo Milk - 094/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Poke Ball - 095/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Poke Ball - 095/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Pokegear 3.0 - 096/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Pokegear 3.0 - 096/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Pokemon Collector - 097/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Pokemon Collector - 097/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Pokemon Communication - 098/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Pokemon Communication - 098/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Pokemon Reversal - 099/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Pokemon Reversal - 099/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Professor Elm's Training Method - 100/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Professor Elm's Training Method - 100/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Professor Oak's New Theory - 101/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Professor Oak's New Theory - 101/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Switch - 102/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Switch - 102/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Double Colorless Energy - 103/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Double Colorless Energy - 103/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Rainbow Energy - 104/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Rainbow Energy - 104/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Ampharos (Prime) - 105/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Blissey (Prime) - 106/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Donphan (Prime) - 107/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Feraligatr (Prime) - 108/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Meganium (Prime) - 109/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Typhlosion (Prime) - 110/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Ho-Oh Legend (Top) - 111/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Ho-Oh Legend (Bottom) - 112/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Lugia Legend (Top) - 113/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Lugia Legend (Bottom) - 114/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Grass Energy - 115/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Fire Energy - 116/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Water Energy - 117/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Lightning Energy - 118/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Psychic Energy - 119/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Fighting Energy - 120/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Darkness Energy (Basic) - 121/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Metal Energy (Basic) - 122/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Shining Gyarados - 123/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/HeartGold and SoulSilver - Shining Gyarados - 123/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Absol G - 001/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Absol G - 001/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Blaziken FB - 002/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Blaziken FB - 002/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Drifblim FB - 003/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Drifblim FB - 003/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Electivire FB - 004/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Electivire FB - 004/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Garchomp - 005/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Garchomp - 005/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Magmortar - 006/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Magmortar - 006/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Milotic (Shiny) - 007/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Metagross - 007/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Metagross - 007/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Rayquaza C - 008/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Rayquaza C - 008/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Relicanth (Shiny) - 008/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Yanma (Shiny) - 009/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Regigigas FB - 009/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Regigigas FB - 009/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Rhyperior - 010/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Rhyperior - 010/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Staraptor FB - 011/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Staraptor FB - 011/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Swampert - 012/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Swampert - 012/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Venusaur - 013/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Venusaur - 013/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Yanmega - 014/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Yanmega - 014/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Arcanine G - 015/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Arcanine G - 015/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Articuno (16) - 016/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Articuno (16) - 016/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Butterfree FB - 017/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Butterfree FB - 017/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Camerupt - 018/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Camerupt - 018/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Camerupt G - 019/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Camerupt G - 019/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Charizard G - 020/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Charizard G - 020/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Chimecho - 021/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Chimecho - 021/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Claydol - 022/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Claydol - 022/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Crawdaunt G - 023/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Crawdaunt G - 023/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Dewgong - 024/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Dewgong - 024/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Dodrio - 025/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Dodrio - 025/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Dusknoir FB - 026/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Dusknoir FB - 026/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Empoleon FB - 027/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Empoleon FB - 027/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Exploud - 028/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Exploud - 028/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Honchkrow - 029/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Honchkrow - 029/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Lickilicky C - 030/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Lickilicky C - 030/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Lucario C - 031/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Lucario C - 031/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Lunatone - 032/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Lunatone - 032/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Mawile - 033/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Mawile - 033/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Medicham - 034/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Medicham - 034/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Milotic C - 035/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Milotic C - 035/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Moltres (36) - 036/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Moltres (36) - 036/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Mr. Mime - 037/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Mr. Mime - 037/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Parasect - 038/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Parasect - 038/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Primeape - 039/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Primeape - 039/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Roserade C - 040/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Roserade C - 040/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Sableye G - 041/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Sableye G - 041/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Sandslash - 042/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Sandslash - 042/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Seaking - 043/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Seaking - 043/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Shedinja - 044/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Shedinja - 044/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Solrock - 045/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Solrock - 045/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Spinda - 046/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Spinda - 046/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Wailord - 047/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Wailord - 047/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Zapdos (48) - 048/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Zapdos (48) - 048/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Altaria C - 049/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Altaria C - 049/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Arcanine - 050/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Arcanine - 050/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Bibarel - 051/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Bibarel - 051/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Breloom - 052/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Breloom - 052/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Carnivine - 053/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Carnivine - 053/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Chatot G - 054/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Chatot G - 054/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Cherrim - 055/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Cherrim - 055/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Dragonite FB - 056/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Dragonite FB - 056/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Drifblim - 057/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Drifblim - 057/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Floatzel - 058/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Floatzel - 058/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Gabite - 059/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Gabite - 059/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Garchomp C - 060/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Garchomp C - 060/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Hippopotas - 061/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Hippopotas - 061/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Ivysaur - 062/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Ivysaur - 062/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Lopunny - 063/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Lopunny - 063/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Loudred - 064/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Loudred - 064/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Magmar - 065/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Magmar - 065/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Manectric G - 066/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Manectric G - 066/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Marshtomp - 067/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Marshtomp - 067/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Masquerain - 068/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Masquerain - 068/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Metang - 069/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Metang - 069/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Milotic - 070/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Milotic - 070/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Minun - 071/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Minun - 071/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Murkrow - 072/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Murkrow - 072/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Ninjask - 073/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Ninjask - 073/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Numel - 074/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Numel - 074/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Pinsir - 075/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Pinsir - 075/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Plusle - 076/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Plusle - 076/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Raichu - 077/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Raichu - 077/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Raticate G - 078/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Raticate G - 078/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Relicanth - 079/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Relicanth - 079/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Rhydon - 080/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Rhydon - 080/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Roserade - 081/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Roserade - 081/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Rotom - 082/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Rotom - 082/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Skarmory FB - 083/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Skarmory FB - 083/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Spiritomb C - 084/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Spiritomb C - 084/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Staravia - 085/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Staravia - 085/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Togekiss C - 086/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Togekiss C - 086/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Wailmer - 087/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Wailmer - 087/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Yanma - 088/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Yanma - 088/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Baltoy - 089/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Baltoy - 089/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Beldum - 090/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Beldum - 090/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Bidoof - 091/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Bidoof - 091/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Buizel - 092/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Buizel - 092/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Bulbasaur - 093/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Bulbasaur - 093/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Buneary - 094/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Buneary - 094/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Chatot - 095/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Chatot - 095/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Cherubi - 096/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Cherubi - 096/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Chimchar - 097/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Chimchar - 097/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Chingling - 098/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Chingling - 098/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Combee - 099/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Combee - 099/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Corphish - 100/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Corphish - 100/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Croagunk - 101/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Croagunk - 101/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Doduo - 102/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Doduo - 102/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Drifloon - 103/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Drifloon - 103/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Feebas - 104/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Feebas - 104/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Geodude - 105/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Geodude - 105/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Gible - 106/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Gible - 106/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Goldeen - 107/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Goldeen - 107/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Growlithe - 108/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Growlithe - 108/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Kricketot - 109/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Kricketot - 109/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Magikarp - 110/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Magikarp - 110/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Magnemite - 111/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Magnemite - 111/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Mankey - 112/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Mankey - 112/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Meditite - 113/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Meditite - 113/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Meowth - 114/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Meowth - 114/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Mime Jr. - 115/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Mime Jr. - 115/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Mudkip - 116/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Mudkip - 116/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Nincada - 117/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Nincada - 117/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Pachirisu - 118/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Pachirisu - 118/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Paras - 119/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Paras - 119/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Pikachu - 120/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Pikachu - 120/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Piplup - 121/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Piplup - 121/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Rhyhorn - 122/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Rhyhorn - 122/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Roselia - 123/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Roselia - 123/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Sandshrew - 124/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Sandshrew - 124/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Seel - 125/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Seel - 125/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Shinx - 126/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Shinx - 126/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Shroomish - 127/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Shroomish - 127/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Skorupi - 128/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Skorupi - 128/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Starly - 129/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Starly - 129/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Surskit - 130/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Surskit - 130/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Turtwig - 131/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Turtwig - 131/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Whismur - 132/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Whismur - 132/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Zubat - 133/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Zubat - 133/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Battle Tower - 134/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Battle Tower - 134/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Champion's Room - 135/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Champion's Room - 135/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Cynthia's Guidance - 136/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Cynthia's Guidance - 136/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Cyrus's Initiative - 137/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Cyrus's Initiative - 137/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Night Teleporter - 138/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Night Teleporter - 138/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Palmer's Contribution - 139/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Palmer's Contribution - 139/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - VS Seeker - 140/147 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - VS Seeker - 140/147 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Absol G Lv.X - 141/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Blaziken FB Lv.X - 142/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Charizard G Lv.X - 143/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Electivire FB Lv.X - 144/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Garchomp C Lv.X - 145/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Rayquaza C Lv.X - 146/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Staraptor FB Lv.X - 147/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Articuno (148) - 148/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Moltres (149) - 149/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Supreme Victors - Zapdos (150) - 150/147 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Arcanine - 001/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Arcanine - 001/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Fan Rotom - 001/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Bastiodon GL - 002/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Bastiodon GL - 002/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Frost Rotom - 002/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Darkrai G - 003/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Darkrai G - 003/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Heat Rotom - 003/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Floatzel GL - 004/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Floatzel GL - 004/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Mow Rotom - 004/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Flygon - 005/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Flygon - 005/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Wash Rotom - 005/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Froslass GL - 006/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Froslass GL - 006/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Charon's Choice - 006/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Jirachi - 007/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Jirachi - 007/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Lucario GL - 008/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Lucario GL - 008/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Luxray GL - 009/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Luxray GL - 009/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Mismagius GL - 010/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Mismagius GL - 010/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Rampardos GL - 011/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Rampardos GL - 011/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Roserade GL - 012/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Roserade GL - 012/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Shiftry - 013/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Shiftry - 013/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Aggron - 014/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Aggron - 014/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Beedrill - 015/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Beedrill - 015/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Bronzong E4 - 016/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Bronzong E4 - 016/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Drapion E4 - 017/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Drapion E4 - 017/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Espeon E4 - 018/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Espeon E4 - 018/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Flareon - 019/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Flareon - 019/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Gallade E4 - 020/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Gallade E4 - 020/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Gastrodon East Sea - 021/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Gastrodon East Sea - 021/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Gastrodon (West Sea) - 022/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Gastrodon (West Sea) - 022/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Golem E4 - 023/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Golem E4 - 023/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Heracross E4 - 024/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Heracross E4 - 024/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Hippowdon - 025/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Hippowdon - 025/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Jolteon - 026/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Jolteon - 026/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Mamoswine GL - 027/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Mamoswine GL - 027/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Mr. Mime E4 - 028/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Mr. Mime E4 - 028/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Nidoking - 029/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Nidoking - 029/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Nidoqueen - 030/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Nidoqueen - 030/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Raichu GL - 031/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Raichu GL - 031/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Rhyperior E4 - 032/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Rhyperior E4 - 032/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Snorlax (33) - 033/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Snorlax (33) - 033/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Vaporeon - 034/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Vaporeon - 034/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Vespiquen E4 - 035/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Vespiquen E4 - 035/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Walrein - 036/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Walrein - 036/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Yanmega E4 - 037/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Yanmega E4 - 037/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Alakazam E4 - 038/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Alakazam E4 - 038/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Electrode G - 039/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Electrode G - 039/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Gengar GL - 040/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Gengar GL - 040/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Glaceon - 041/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Glaceon - 041/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Hippowdon E4 - 042/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Hippowdon E4 - 042/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Infernape E4 - 043/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Infernape E4 - 043/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Lairon - 044/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Lairon - 044/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Leafeon - 045/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Leafeon - 045/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Machamp GL - 046/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Machamp GL - 046/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Rapidash E4 - 047/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Rapidash E4 - 047/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Scizor E4 - 048/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Scizor E4 - 048/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Sharpedo - 049/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Sharpedo - 049/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Starmie - 050/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Starmie - 050/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Steelix GL - 051/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Steelix GL - 051/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Tropius - 052/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Tropius - 052/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Vibrava - 053/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Vibrava - 053/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Whiscash E4 - 054/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Whiscash E4 - 054/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Aerodactyl GL - 055/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Aerodactyl GL - 055/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Ambipom G - 056/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Ambipom G - 056/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Aron - 057/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Aron - 057/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Carvanha - 058/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Carvanha - 058/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Eevee - 059/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Eevee - 059/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Flareon E4 - 060/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Flareon E4 - 060/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Forretress G - 061/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Forretress G - 061/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Gliscor E4 - 062/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Gliscor E4 - 062/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Growlithe - 063/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Growlithe - 063/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Hippopotas - 064/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Hippopotas - 064/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Houndoom E4 - 065/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Houndoom E4 - 065/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Kakuna - 066/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Kakuna - 066/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Kecleon - 067/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Kecleon - 067/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Koffing - 068/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Koffing - 068/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Munchlax (69) - 069/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Munchlax (69) - 069/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Munchlax (70) - 070/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Munchlax (70) - 070/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Nidoran F - 071/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Nidoran F - 071/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Nidoran M - 072/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Nidoran M - 072/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Nidorina - 073/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Nidorina - 073/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Nidorino - 074/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Nidorino - 074/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Nuzleaf - 075/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Nuzleaf - 075/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Quagsire GL - 076/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Quagsire GL - 076/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Sealeo - 077/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Sealeo - 077/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Seedot - 078/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Seedot - 078/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Shellos East Sea - 079/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Shellos East Sea - 079/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Shellos West Sea - 080/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Shellos West Sea - 080/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Snorlax (81) - 081/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Snorlax (81) - 081/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Spheal - 082/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Spheal - 082/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Staryu - 083/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Staryu - 083/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Trapinch - 084/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Trapinch - 084/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Turtwig GL - 085/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Turtwig GL - 085/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Weedle - 086/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Weedle - 086/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Weezing - 087/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Weezing - 087/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Aaron's Collection - 088/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Aaron's Collection - 088/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Bebe's Search - 089/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Bebe's Search - 089/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Bertha's Warmth - 090/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Bertha's Warmth - 090/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Flint's Willpower - 091/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Flint's Willpower - 091/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Lucian's Assignment - 092/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Lucian's Assignment - 092/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Pokemon Contest Hall - 093/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Pokemon Contest Hall - 093/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Sunyshore City Gym - 094/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Sunyshore City Gym - 094/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Team Galactic's Invention G-107 Technical Mach. G - 095/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Team Galactic's Invention G-107 Technical Mach. G - 095/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Team Galactic's Invention G-109 SP Radar - 096/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Team Galactic's Invention G-109 SP Radar - 096/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Underground Expedition - 097/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Underground Expedition - 097/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Volkner's Philosophy - 098/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Volkner's Philosophy - 098/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Darkness Energy (Special) - 099/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Darkness Energy (Special) - 099/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Metal Energy (Special) - 100/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Metal Energy (Special) - 100/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - SP Energy - 101/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - SP Energy - 101/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Upper Energy - 102/111 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Upper Energy - 102/111 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Alakazam E4 Lv.X - 103/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Floatzel GL Lv.X - 104/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Flygon Lv.X - 105/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Gallade E4 Lv.X - 106/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Hippowdon Lv.X - 107/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Infernape E4 Lv.X - 108/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Luxray GL Lv.X - 109/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Mismagius GL Lv.X - 110/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Snorlax Lv.X - 111/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Pikachu - 112/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Flying Pikachu - 113/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Rising Rivals - Surfing Pikachu - 114/111 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Ampharos - 001/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Ampharos - 001/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Blastoise - 002/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Blastoise - 002/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Blaziken - 003/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Blaziken - 003/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Lotad (Shiny) - 004/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Delcatty - 004/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Delcatty - 004/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Dialga Lv. 72 - 005/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Dialga Lv. 72 - 005/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Swablu (Shiny) - 005/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Vulpix (Shiny) - 006/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Dialga Lv. 70 - 006/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Dialga Lv. 70 - 006/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Dialga G - 007/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Dialga G - 007/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Gardevoir - 008/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Gardevoir - 008/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Giratina Lv. 55 - 009/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Giratina Lv. 55 - 009/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Giratina Lv. 63 - 010/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Giratina Lv. 63 - 010/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Manectric - 011/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Manectric - 011/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Palkia G - 012/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Palkia G - 012/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Rampardos - 013/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Rampardos - 013/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Shaymin (14) - 014/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Shaymin (14) - 014/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Shaymin (15) - 015/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Shaymin (15) - 015/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Slaking - 016/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Slaking - 016/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Weavile G - 017/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Weavile G - 017/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Altaria - 018/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Altaria - 018/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Banette - 019/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Banette - 019/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Bastiodon - 020/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Bastiodon - 020/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Beautifly - 021/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Beautifly - 021/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Blissey - 022/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Blissey - 022/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Dialga Lv. 73 - 023/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Dialga Lv. 73 - 023/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Dugtrio - 024/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Dugtrio - 024/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Dustox - 025/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Dustox - 025/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Empoleon - 026/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Empoleon - 026/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Giratina Lv. 59 - 027/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Giratina Lv. 59 - 027/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Giratina Lv. 70 - 028/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Giratina Lv. 70 - 028/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Golduck - 029/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Golduck - 029/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Gyarados G - 030/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Gyarados G - 030/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Infernape - 031/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Infernape - 031/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Kricketune - 032/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Kricketune - 032/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Lickilicky - 033/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Lickilicky - 033/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Ludicolo - 034/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Ludicolo - 034/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Luvdisc - 035/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Luvdisc - 035/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Ninetales - 036/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Ninetales - 036/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Palkia - 037/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Palkia - 037/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Shaymin (38) - 038/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Shaymin (38) - 038/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Torterra - 039/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Torterra - 039/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Toxicroak G - 040/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Toxicroak G - 040/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Bronzong G - 041/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Bronzong G - 041/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Cacturne - 042/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Cacturne - 042/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Carnivine (43) - 043/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Carnivine (43) - 043/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Cascoon - 044/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Cascoon - 044/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Combusken - 045/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Combusken - 045/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Cranidos - 046/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Cranidos - 046/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Crobat G - 047/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Crobat G - 047/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Flaaffy - 048/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Flaaffy - 048/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Grotle - 049/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Grotle - 049/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Houndoom G - 050/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Houndoom G - 050/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Kirlia - 051/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Kirlia - 051/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Lombre - 052/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Lombre - 052/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Lucario - 053/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Lucario - 053/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Mightyena - 054/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Mightyena - 054/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Mismagius - 055/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Mismagius - 055/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Monferno - 056/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Monferno - 056/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Muk - 057/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Muk - 057/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Octillery - 058/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Octillery - 058/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Prinplup - 059/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Prinplup - 059/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Probopass - 060/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Probopass - 060/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Seviper - 061/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Seviper - 061/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Shieldon - 062/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Shieldon - 062/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Silcoon - 063/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Silcoon - 063/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Vigoroth - 064/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Vigoroth - 064/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Wartortle - 065/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Wartortle - 065/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Zangoose - 066/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Zangoose - 066/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Cacnea - 067/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Cacnea - 067/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Carnivine (68) - 068/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Carnivine (68) - 068/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Chansey - 069/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Chansey - 069/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Chimchar - 070/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Chimchar - 070/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Combee - 071/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Combee - 071/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Diglett - 072/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Diglett - 072/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Dunsparce - 073/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Dunsparce - 073/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Electrike - 074/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Electrike - 074/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Grimer - 075/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Grimer - 075/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Happiny - 076/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Happiny - 076/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Honchkrow G - 077/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Honchkrow G - 077/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Kricketot - 078/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Kricketot - 078/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Lapras - 079/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Lapras - 079/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Lickitung - 080/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Lickitung - 080/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Lotad - 081/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Lotad - 081/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Mareep - 082/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Mareep - 082/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Misdreavus - 083/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Misdreavus - 083/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Nosepass - 084/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Nosepass - 084/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Piplup - 085/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Piplup - 085/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Poochyena - 086/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Poochyena - 086/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Psyduck - 087/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Psyduck - 087/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Purugly G - 088/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Purugly G - 088/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Ralts - 089/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Ralts - 089/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Remoraid - 090/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Remoraid - 090/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Riolu - 091/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Riolu - 091/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Shuppet - 092/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Shuppet - 092/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Skitty - 093/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Skitty - 093/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Skuntank G - 094/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Skuntank G - 094/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Slakoth - 095/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Slakoth - 095/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Squirtle - 096/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Squirtle - 096/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Swablu - 097/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Swablu - 097/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Tauros - 098/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Tauros - 098/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Torchic - 099/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Torchic - 099/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Torkoal - 100/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Torkoal - 100/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Turtwig - 101/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Turtwig - 101/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Vulpix - 102/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Vulpix - 102/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Wurmple - 103/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Wurmple - 103/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Broken Time-Space - 104/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Broken Time-Space - 104/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Cyrus's Conspiracy - 105/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Cyrus's Conspiracy - 105/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Galactic HQ - 106/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Galactic HQ - 106/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Level Max - 107/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Level Max - 107/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Life Herb - 108/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Life Herb - 108/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Looker's Investigation - 109/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Looker's Investigation - 109/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Memory Berry - 110/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Memory Berry - 110/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Miasma Valley - 111/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Miasma Valley - 111/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - PlusPower - 112/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - PlusPower - 112/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Poke Ball - 113/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Poke Ball - 113/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Pokedex HANDY910is - 114/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Pokedex HANDY910is - 114/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Pokemon Rescue - 115/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Pokemon Rescue - 115/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Team Galactic's Invention G-101 Energy Gain - 116/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Team Galactic's Invention G-101 Energy Gain - 116/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Team Galactic's Invention G-103 Power Spray - 117/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Team Galactic's Invention G-103 Power Spray - 117/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Team Galactic's Invention G-105 Poke Turn - 118/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Team Galactic's Invention G-105 Poke Turn - 118/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Armor Fossil - 119/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Armor Fossil - 119/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Skull Fossil - 120/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Skull Fossil - 120/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Rainbow Energy - 121/127 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Rainbow Energy - 121/127 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Dialga G Lv.X - 122/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Drapion Lv.X - 123/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Giratina Lv.X - 124/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Palkia G Lv.X - 125/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Shaymin Lv.X (Land Forme) - 126/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Shaymin Lv.X (Sky Forme) - 127/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Electabuzz - 128/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Hitmonchan - 129/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Platinum - Scyther - 130/127 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Aggron - 001/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Aggron - 001/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Alakazam - 002/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Alakazam - 002/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Ambipom - 003/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Ambipom - 003/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Azelf - 004/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Azelf - 004/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Blissey - 005/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Blissey - 005/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Bronzong - 006/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Bronzong - 006/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Celebi - 007/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Celebi - 007/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Feraligatr - 008/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Feraligatr - 008/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Garchomp - 009/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Garchomp - 009/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Honchkrow - 010/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Honchkrow - 010/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Lumineon - 011/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Lumineon - 011/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Magmortar - 012/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Magmortar - 012/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Meganium - 013/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Meganium - 013/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Mesprit - 014/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Mesprit - 014/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Raichu - 015/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Raichu - 015/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Typhlosion - 016/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Typhlosion - 016/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Tyranitar - 017/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Tyranitar - 017/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Uxie - 018/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Uxie - 018/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Abomasnow - 019/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Abomasnow - 019/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Ariados - 020/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Ariados - 020/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Bastiodon - 021/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Bastiodon - 021/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Chimecho - 022/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Chimecho - 022/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Crobat - 023/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Crobat - 023/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Exeggutor - 024/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Exeggutor - 024/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Glalie - 025/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Glalie - 025/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Gyarados - 026/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Gyarados - 026/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Kricketune - 027/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Kricketune - 027/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Manectric - 028/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Manectric - 028/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Mantine - 029/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Mantine - 029/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Mr. Mime - 030/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Mr. Mime - 030/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Nidoqueen - 031/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Nidoqueen - 031/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Ninetales - 032/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Ninetales - 032/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Rampardos - 033/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Rampardos - 033/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Slaking - 034/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Slaking - 034/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Sudowoodo - 035/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Sudowoodo - 035/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Toxicroak - 036/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Toxicroak - 036/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Unown [I] - 037/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Unown [I] - 037/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Ursaring - 038/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Ursaring - 038/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Walrein - 039/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Walrein - 039/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Whiscash - 040/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Whiscash - 040/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Bayleef - 041/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Bayleef - 041/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Chingling - 042/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Chingling - 042/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Cranidos - 043/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Cranidos - 043/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Croconaw - 044/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Croconaw - 044/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Dewgong - 045/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Dewgong - 045/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Dodrio - 046/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Dodrio - 046/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Dunsparce - 047/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Dunsparce - 047/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Gabite - 048/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Gabite - 048/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Girafarig - 049/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Girafarig - 049/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Golbat - 050/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Golbat - 050/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Graveler - 051/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Graveler - 051/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Happiny - 052/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Happiny - 052/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Lairon - 053/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Lairon - 053/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Magmar - 054/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Magmar - 054/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Masquerain - 055/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Masquerain - 055/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Nidorina - 056/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Nidorina - 056/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Octillery - 057/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Octillery - 057/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Parasect - 058/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Parasect - 058/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Pupitar - 059/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Pupitar - 059/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Quilava - 060/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Quilava - 060/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Sandslash - 061/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Sandslash - 061/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Sealeo - 062/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Sealeo - 062/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Shieldon - 063/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Shieldon - 063/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Tropius - 064/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Tropius - 064/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Unown [E] - 065/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Unown [E] - 065/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Unown [M] - 066/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Unown [M] - 066/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Unown [T] - 067/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Unown [T] - 067/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Vigoroth - 068/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Vigoroth - 068/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Abra - 069/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Abra - 069/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Aipom - 070/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Aipom - 070/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Aron - 071/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Aron - 071/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Barboach - 072/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Barboach - 072/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Bidoof - 073/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Bidoof - 073/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Bronzor - 074/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Bronzor - 074/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Buizel - 075/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Buizel - 075/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Chansey - 076/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Chansey - 076/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Chikorita - 077/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Chikorita - 077/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Croagunk - 078/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Croagunk - 078/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Cyndaquil - 079/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Cyndaquil - 079/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Doduo - 080/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Doduo - 080/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Electrike - 081/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Electrike - 081/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Exeggcute - 082/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Exeggcute - 082/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Finneon - 083/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Finneon - 083/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Geodude - 084/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Geodude - 084/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Gible - 085/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Gible - 085/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Kricketot - 086/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Kricketot - 086/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Larvitar - 087/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Larvitar - 087/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Magby - 088/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Magby - 088/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Magikarp - 089/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Magikarp - 089/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Murkrow - 090/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Murkrow - 090/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Nidoran F - 091/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Nidoran F - 091/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Paras - 092/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Paras - 092/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Pichu - 093/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Pichu - 093/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Pikachu - 094/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Pikachu - 094/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Remoraid - 095/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Remoraid - 095/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Sandshrew - 096/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Sandshrew - 096/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Seel - 097/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Seel - 097/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Shinx - 098/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Shinx - 098/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Slakoth - 099/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Slakoth - 099/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Snorunt - 100/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Snorunt - 100/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Snover - 101/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Snover - 101/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Spheal - 102/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Spheal - 102/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Spinarak - 103/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Spinarak - 103/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Surskit - 104/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Surskit - 104/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Teddiursa - 105/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Teddiursa - 105/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Totodile - 106/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Totodile - 106/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Vulpix - 107/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Vulpix - 107/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Zubat - 108/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Zubat - 108/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Bebe's Search - 109/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Bebe's Search - 109/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Dusk Ball - 110/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Dusk Ball - 110/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Fossil Excavator - 111/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Fossil Excavator - 111/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Lake Boundary - 112/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Lake Boundary - 112/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Night Maintenance - 113/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Night Maintenance - 113/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Quick Ball - 114/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Quick Ball - 114/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Team Galactic's Wager - 115/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Team Galactic's Wager - 115/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Armor Fossil - 116/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Armor Fossil - 116/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Skull Fossil - 117/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Skull Fossil - 117/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Multi Energy - 118/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Multi Energy - 118/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Darkness Energy (Special) - 119/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Darkness Energy (Special) - 119/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Metal Energy (Special) - 120/123 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Metal Energy (Special) - 120/123 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Electivire Lv.X - 121/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Lucario Lv.X - 122/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Magmortar Lv.X - 123/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Mysterious Treasures - Time-Space Distortion - 124/123 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Drifloon (Shiny) - 001/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Dusknoir - 001/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Dusknoir - 001/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Duskull (Shiny) - 002/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Empoleon - 002/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Empoleon - 002/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Infernape - 003/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Infernape - 003/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Voltorb (Shiny) - 003/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Lumineon - 004/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Lumineon - 004/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Magnezone - 005/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Magnezone - 005/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Magnezone - 006/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Magnezone - 006/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Mismagius - 007/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Mismagius - 007/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Raichu - 008/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Raichu - 008/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Regigigas - 009/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Regigigas - 009/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Sceptile - 010/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Sceptile - 010/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Torterra - 011/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Torterra - 011/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Abomasnow - 012/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Abomasnow - 012/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Bronzong - 013/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Bronzong - 013/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Cherrim - 014/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Cherrim - 014/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Drapion - 015/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Drapion - 015/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Drifblim - 016/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Drifblim - 016/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Dusknoir (17) - 017/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Dusknoir (17) - 017/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Gengar - 018/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Gengar - 018/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Gyarados - 019/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Gyarados - 019/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Machamp - 020/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Machamp - 020/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Mamoswine - 021/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Mamoswine - 021/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Rapidash - 022/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Rapidash - 022/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Roserade - 023/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Roserade - 023/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Salamence - 024/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Salamence - 024/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Scizor - 025/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Scizor - 025/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Skuntank - 026/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Skuntank - 026/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Staraptor - 027/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Staraptor - 027/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Steelix - 028/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Steelix - 028/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Tangrowth - 029/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Tangrowth - 029/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Tyranitar - 030/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Tyranitar - 030/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Vespiquen - 031/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Vespiquen - 031/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Bibarel - 032/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Bibarel - 032/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Budew - 033/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Budew - 033/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Dusclops (34) - 034/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Dusclops (34) - 034/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Dusclops (35) - 035/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Dusclops (35) - 035/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Electrode (36) - 036/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Electrode (36) - 036/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Electrode (37) - 037/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Electrode (37) - 037/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Farfetch'd - 038/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Farfetch'd - 038/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Grovyle - 039/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Grovyle - 039/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Haunter - 040/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Haunter - 040/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Machoke - 041/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Machoke - 041/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Magneton (42) - 042/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Magneton (42) - 042/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Magneton (43) - 043/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Magneton (43) - 043/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Miltank - 044/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Miltank - 044/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Pichu - 045/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Pichu - 045/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Piloswine - 046/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Piloswine - 046/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Pupitar - 047/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Pupitar - 047/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Sableye - 048/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Sableye - 048/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Scyther - 049/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Scyther - 049/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Shelgon - 050/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Shelgon - 050/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Skarmory - 051/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Skarmory - 051/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Staravia - 052/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Staravia - 052/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Bagon - 053/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Bagon - 053/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Bidoof - 054/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Bidoof - 054/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Bronzor - 055/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Bronzor - 055/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Cherubi - 056/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Cherubi - 056/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Combee - 057/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Combee - 057/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Drifloon - 058/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Drifloon - 058/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Duskull (59) - 059/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Duskull (59) - 059/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Duskull (60) - 060/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Duskull (60) - 060/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Finneon - 061/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Finneon - 061/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Gastly - 062/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Gastly - 062/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Larvitar - 063/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Larvitar - 063/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Machop - 064/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Machop - 064/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Magikarp - 065/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Magikarp - 065/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Magnemite (66) - 066/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Magnemite (66) - 066/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Magnemite (67) - 067/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Magnemite (67) - 067/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Misdreavus - 068/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Misdreavus - 068/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Onix - 069/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Onix - 069/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Pikachu - 070/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Pikachu - 070/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Ponyta - 071/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Ponyta - 071/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Roselia - 072/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Roselia - 072/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Skorupi - 073/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Skorupi - 073/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Snover - 074/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Snover - 074/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Starly - 075/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Starly - 075/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Stunky - 076/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Stunky - 076/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Swinub - 077/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Swinub - 077/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Tangela - 078/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Tangela - 078/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Treecko - 079/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Treecko - 079/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Voltorb (80) - 080/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Voltorb (80) - 080/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Voltorb (81) - 081/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Voltorb (81) - 081/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Conductive Quarry - 082/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Conductive Quarry - 082/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Energy Link - 083/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Energy Link - 083/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Energy Switch - 084/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Energy Switch - 084/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Great Ball - 085/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Great Ball - 085/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Luxury Ball - 086/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Luxury Ball - 086/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Marley's Request - 087/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Marley's Request - 087/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Poke Blower + - 088/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Poke Blower + - 088/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Poke Drawer + - 089/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Poke Drawer + - 089/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Poke Healer + - 090/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Poke Healer + - 090/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Premier Ball - 091/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Premier Ball - 091/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Potion - 092/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Potion - 092/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Switch - 093/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Switch - 093/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Cyclone Energy - 094/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Cyclone Energy - 094/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Warp Energy - 095/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Warp Energy - 095/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Dusknoir Lv.X - 096/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Heatran Lv.X - 097/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Machamp Lv.X - 098/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Raichu Lv.X - 099/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Regigigas Lv.X - 100/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Charmander - 101/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Charmeleon - 102/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stormfront - Charizard - 103/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Deoxys Normal Forme - 001/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Deoxys Normal Forme - 001/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Dragonite - 002/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Dragonite - 002/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Froslass - 003/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Froslass - 003/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Giratina - 004/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Giratina - 004/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Gliscor - 005/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Gliscor - 005/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Heatran - 006/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Heatran - 006/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Kingdra - 007/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Kingdra - 007/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Luxray - 008/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Luxray - 008/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Mamoswine - 009/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Mamoswine - 009/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Metagross - 010/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Metagross - 010/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Mewtwo - 011/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Mewtwo - 011/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Politoed - 012/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Politoed - 012/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Probopass - 013/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Probopass - 013/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Rayquaza - 014/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Rayquaza - 014/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Regigigas (15) - 015/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Regigigas (15) - 015/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Spiritomb - 016/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Spiritomb - 016/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Yanmega - 017/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Yanmega - 017/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Armaldo - 018/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Armaldo - 018/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Azelf - 019/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Azelf - 019/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Bellossom - 020/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Bellossom - 020/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Cradily - 021/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Cradily - 021/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Crawdaunt - 022/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Crawdaunt - 022/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Delcatty - 023/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Delcatty - 023/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Deoxys Attack Forme - 024/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Deoxys Attack Forme - 024/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Deoxys Defense Forme - 025/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Deoxys Defense Forme - 025/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Deoxys Speed Forme - 026/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Deoxys Speed Forme - 026/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Ditto - 027/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Ditto - 027/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Forretress - 028/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Forretress - 028/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Groudon - 029/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Groudon - 029/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Heatran (30) - 030/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Heatran (30) - 030/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Jirachi - 031/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Jirachi - 031/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Kyogre - 032/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Kyogre - 032/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Lopunny - 033/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Lopunny - 033/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Mesprit - 034/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Mesprit - 034/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Poliwrath - 035/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Poliwrath - 035/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Regice - 036/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Regice - 036/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Regigigas (37) - 037/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Regigigas (37) - 037/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Regirock - 038/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Regirock - 038/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Registeel - 039/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Registeel - 039/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Shedinja - 040/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Shedinja - 040/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Torkoal - 041/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Torkoal - 041/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Unown (!) - 042/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Unown (!) - 042/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Uxie - 043/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Uxie - 043/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Victreebel - 044/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Victreebel - 044/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Vileplume - 045/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Vileplume - 045/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Anorith - 046/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Anorith - 046/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Camerupt - 047/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Camerupt - 047/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Castform - 048/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Castform - 048/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Castform Rain Form - 049/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Castform Rain Form - 049/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Castform Snow-Cloud Form - 050/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Castform Snow-Cloud Form - 050/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Castform Sunny Form - 051/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Castform Sunny Form - 051/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Dragonair - 052/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Dragonair - 052/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Drifblim - 053/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Drifblim - 053/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Exeggutor - 054/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Exeggutor - 054/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Gliscor (55) - 055/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Gliscor (55) - 055/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Grumpig - 056/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Grumpig - 056/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Houndoom - 057/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Houndoom - 057/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Lanturn (58) - 058/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Lanturn (58) - 058/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Lanturn (59) - 059/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Lanturn (59) - 059/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Ledian - 060/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Ledian - 060/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Lucario - 061/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Lucario - 061/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Luxio - 062/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Luxio - 062/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Marowak - 063/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Marowak - 063/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Metang (64) - 064/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Metang (64) - 064/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Metang (65) - 065/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Metang (65) - 065/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Mightyena - 066/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Mightyena - 066/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Ninjask - 067/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Ninjask - 067/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Persian - 068/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Persian - 068/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Piloswine - 069/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Piloswine - 069/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Seadra - 070/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Seadra - 070/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Starmie - 071/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Starmie - 071/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Swalot - 072/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Swalot - 072/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Swellow - 073/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Swellow - 073/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Tauros - 074/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Tauros - 074/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Tentacruel - 075/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Tentacruel - 075/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Unown (J) - 076/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Unown (J) - 076/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Unown (R) - 077/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Unown (R) - 077/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Unown (U) - 078/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Unown (U) - 078/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Unown (V) - 079/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Unown (V) - 079/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Unown (W) - 080/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Unown (W) - 080/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Unown (Y) - 081/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Unown (Y) - 081/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Unown (?) - 082/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Unown (?) - 082/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Beldum (83) - 083/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Beldum (83) - 083/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Beldum (84) - 084/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Beldum (84) - 084/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Bellsprout - 085/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Bellsprout - 085/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Buneary - 086/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Buneary - 086/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Chinchou (87) - 087/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Chinchou (87) - 087/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Chinchou (88) - 088/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Chinchou (88) - 088/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Corphish - 089/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Corphish - 089/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Cubone - 090/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Cubone - 090/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Dratini - 091/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Dratini - 091/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Drifloon - 092/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Drifloon - 092/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Exeggcute - 093/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Exeggcute - 093/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Gligar (94) - 094/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Gligar (94) - 094/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Gligar (95) - 095/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Gligar (95) - 095/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Gloom (96) - 096/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Gloom (96) - 096/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Gloom (97) - 097/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Gloom (97) - 097/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Gulpin - 098/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Gulpin - 098/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Hitmonchan - 099/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Hitmonchan - 099/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Hitmonlee - 100/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Hitmonlee - 100/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Hitmontop - 101/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Hitmontop - 101/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Horsea - 102/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Horsea - 102/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Houndour - 103/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Houndour - 103/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Ledyba - 104/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Ledyba - 104/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Lileep - 105/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Lileep - 105/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Meowth - 106/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Meowth - 106/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Misdreavus - 107/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Misdreavus - 107/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Nincada - 108/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Nincada - 108/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Nosepass - 109/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Nosepass - 109/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Numel - 110/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Numel - 110/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Oddish (111) - 111/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Oddish (111) - 111/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Oddish (112) - 112/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Oddish (112) - 112/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Pineco - 113/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Pineco - 113/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Poliwag - 114/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Poliwag - 114/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Poliwhirl - 115/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Poliwhirl - 115/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Poochyena - 116/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Poochyena - 116/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Riolu - 117/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Riolu - 117/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Shinx - 118/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Shinx - 118/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Skitty - 119/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Skitty - 119/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Sneasel - 120/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Sneasel - 120/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Spoink - 121/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Spoink - 121/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Staryu - 122/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Staryu - 122/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Swinub - 123/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Swinub - 123/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Taillow - 124/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Taillow - 124/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Tentacool - 125/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Tentacool - 125/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Tyrogue - 126/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Tyrogue - 126/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Weepinbell - 127/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Weepinbell - 127/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Yanma - 128/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Yanma - 128/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Bubble Coat - 129/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Bubble Coat - 129/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Buck's Training - 130/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Buck's Training - 130/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Buck's Training - 130/146 (Prerelease Promo) - 130/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Cynthia's Feelings - 131/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Cynthia's Feelings - 131/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Energy Pickup - 132/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Energy Pickup - 132/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Poke Radar - 133/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Poke Radar - 133/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Snowpoint Temple - 134/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Snowpoint Temple - 134/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Stark Mountain - 135/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Stark Mountain - 135/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Technical Machine TS-1 - 136/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Technical Machine TS-1 - 136/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Technical Machine TS-2 - 137/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Technical Machine TS-2 - 137/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Claw Fossil - 138/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Claw Fossil - 138/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Root Fossil - 139/146 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Root Fossil - 139/146 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Azelf Lv.X - 140/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Gliscor Lv.X - 141/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Magnezone Lv.X - 142/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Mesprit Lv.X - 143/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Mewtwo Lv.X - 144/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Rhyperior Lv.X - 145/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legends Awakened - Uxie Lv.X - 146/146 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Articuno - 001/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Articuno - 001/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Cresselia - 002/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Cresselia - 002/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Darkrai - 003/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Darkrai - 003/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Dialga - 004/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Dialga - 004/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Glaceon - 005/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Glaceon - 005/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Kabutops - 006/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Kabutops - 006/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Leafeon - 007/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Leafeon - 007/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Manaphy - 008/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Manaphy - 008/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Mewtwo - 009/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Mewtwo - 009/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Moltres - 010/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Moltres - 010/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Palkia - 011/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Palkia - 011/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Phione (12) - 012/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Phione (12) - 012/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Rotom - 013/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Rotom - 013/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Zapdos - 014/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Zapdos - 014/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Aerodactyl - 015/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Aerodactyl - 015/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Bronzong - 016/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Bronzong - 016/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Empoleon - 017/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Empoleon - 017/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Espeon - 018/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Espeon - 018/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Flareon - 019/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Flareon - 019/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Glaceon (20) - 020/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Glaceon (20) - 020/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Hippowdon - 021/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Hippowdon - 021/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Infernape - 022/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Infernape - 022/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Jolteon - 023/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Jolteon - 023/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Leafeon (24) - 024/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Leafeon (24) - 024/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Minun - 025/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Minun - 025/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Omastar - 026/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Omastar - 026/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Phione (27) - 027/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Phione (27) - 027/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Plusle - 028/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Plusle - 028/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Scizor - 029/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Scizor - 029/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Torterra - 030/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Torterra - 030/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Toxicroak - 031/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Toxicroak - 031/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Umbreon - 032/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Umbreon - 032/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Unown [P] - 033/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Unown [P] - 033/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Vaporeon - 034/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Vaporeon - 034/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Ambipom - 035/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Ambipom - 035/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Fearow - 036/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Fearow - 036/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Grotle - 037/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Grotle - 037/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Kangaskhan - 038/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Kangaskhan - 038/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Lickitung - 039/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Lickitung - 039/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Manectric - 040/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Manectric - 040/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Monferno - 041/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Monferno - 041/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Mothim - 042/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Mothim - 042/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Pachirisu - 043/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Pachirisu - 043/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Prinplup - 044/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Prinplup - 044/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Raichu - 045/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Raichu - 045/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Scyther - 046/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Scyther - 046/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Staravia - 047/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Staravia - 047/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Sudowoodo - 048/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Sudowoodo - 048/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Unown [Q] - 049/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Unown [Q] - 049/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Aipom (50) - 050/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Aipom (50) - 050/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Aipom (51) - 051/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Aipom (51) - 051/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Bronzor - 052/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Bronzor - 052/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Buneary - 053/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Buneary - 053/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Burmy Sandy Cloak - 054/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Burmy Sandy Cloak - 054/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Chatot - 055/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Chatot - 055/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Chimchar (56) - 056/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Chimchar (56) - 056/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Chimchar (57) - 057/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Chimchar (57) - 057/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Chingling - 058/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Chingling - 058/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Combee - 059/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Combee - 059/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Croagunk - 060/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Croagunk - 060/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Drifloon - 061/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Drifloon - 061/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Eevee (62) - 062/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Eevee (62) - 062/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Eevee (63) - 063/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Eevee (63) - 063/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Electrike - 064/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Electrike - 064/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Glameow - 065/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Glameow - 065/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Hippopotas - 066/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Hippopotas - 066/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Kabuto - 067/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Kabuto - 067/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Munchlax - 068/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Munchlax - 068/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Omanyte - 069/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Omanyte - 069/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Pikachu - 070/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Pikachu - 070/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Piplup (71) - 071/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Piplup (71) - 071/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Piplup (72) - 072/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Piplup (72) - 072/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Shellos East Sea - 073/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Shellos East Sea - 073/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Spearow - 074/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Spearow - 074/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Starly - 075/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Starly - 075/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Stunky - 076/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Stunky - 076/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Turtwig (77) - 077/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Turtwig (77) - 077/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Turtwig (78) - 078/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Turtwig (78) - 078/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Dawn Stadium - 079/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Dawn Stadium - 079/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Dusk Ball - 080/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Dusk Ball - 080/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Energy Restore - 081/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Energy Restore - 081/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Fossil Excavator - 082/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Fossil Excavator - 082/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Mom's Kindness - 083/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Mom's Kindness - 083/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Old Amber - 084/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Old Amber - 084/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Poke Ball - 085/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Poke Ball - 085/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Quick Ball - 086/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Quick Ball - 086/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Super Scoop Up - 087/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Super Scoop Up - 087/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Warp Point - 088/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Warp Point - 088/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Dome Fossil - 089/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Dome Fossil - 089/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Energy Search - 090/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Energy Search - 090/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Helix Fossil - 091/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Helix Fossil - 091/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Call Energy - 092/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Call Energy - 092/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Darkness Energy (Special) - 093/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Darkness Energy (Special) - 093/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Health Energy - 094/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Health Energy - 094/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Metal Energy (Special) - 095/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Metal Energy (Special) - 095/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Recover Energy - 096/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Recover Energy - 096/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Garchomp Lv.X - 097/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Glaceon Lv.X - 098/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Leafeon Lv.X - 099/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Majestic Dawn - Porygon-Z Lv.X - 100/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Blaziken - 001/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Blaziken - 001/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Cresselia - 002/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Cresselia - 002/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Darkrai - 003/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Darkrai - 003/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Darkrai - 004/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Darkrai - 004/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Pachirisu - 005/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Pachirisu - 005/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Porygon-Z - 006/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Porygon-Z - 006/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Rotom - 007/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Rotom - 007/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Sceptile - 008/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Sceptile - 008/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Swampert - 009/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Swampert - 009/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Tangrowth - 010/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Tangrowth - 010/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Togekiss - 011/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Togekiss - 011/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Altaria - 012/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Altaria - 012/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Beedrill - 013/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Beedrill - 013/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Butterfree - 014/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Butterfree - 014/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Claydol - 015/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Claydol - 015/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Dialga - 016/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Dialga - 016/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Exploud - 017/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Exploud - 017/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Houndoom - 018/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Houndoom - 018/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Hypno - 019/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Hypno - 019/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Kingler - 020/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Kingler - 020/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Lapras - 021/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Lapras - 021/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Latias - 022/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Latias - 022/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Latios - 023/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Latios - 023/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Mawile - 024/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Mawile - 024/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Milotic - 025/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Milotic - 025/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Palkia - 026/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Palkia - 026/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Primeape - 027/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Primeape - 027/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Slowking - 028/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Slowking - 028/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Unown [H] - 029/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Unown [H] - 029/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Wailord - 030/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Wailord - 030/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Weezing - 031/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Weezing - 031/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Wigglytuff - 032/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Wigglytuff - 032/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Arbok - 033/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Arbok - 033/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Cacturne - 034/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Cacturne - 034/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Combusken - 035/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Combusken - 035/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Delibird - 036/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Delibird - 036/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Floatzel - 037/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Floatzel - 037/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Gorebyss - 038/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Gorebyss - 038/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Granbull - 039/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Granbull - 039/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Grovyle - 040/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Grovyle - 040/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Hariyama - 041/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Hariyama - 041/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Huntail - 042/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Huntail - 042/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Linoone - 043/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Linoone - 043/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Loudred - 044/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Loudred - 044/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Magcargo - 045/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Magcargo - 045/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Marshtomp - 046/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Marshtomp - 046/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Metapod - 047/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Metapod - 047/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Pelipper - 048/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Pelipper - 048/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Porygon2 - 049/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Porygon2 - 049/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Purugly - 050/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Purugly - 050/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Relicanth - 051/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Relicanth - 051/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Seviper - 052/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Seviper - 052/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Skarmory - 053/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Skarmory - 053/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Slowbro - 054/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Slowbro - 054/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Togetic - 055/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Togetic - 055/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Unown [F] - 056/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Unown [F] - 056/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Unown [G] - 057/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Unown [G] - 057/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Wailmer - 058/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Wailmer - 058/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Zangoose - 059/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Zangoose - 059/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Baltoy - 060/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Baltoy - 060/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Buizel - 061/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Buizel - 061/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Cacnea - 062/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Cacnea - 062/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Caterpie - 063/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Caterpie - 063/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Clamperl - 064/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Clamperl - 064/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Drowzee - 065/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Drowzee - 065/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Ekans - 066/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Ekans - 066/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Feebas - 067/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Feebas - 067/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Glameow - 068/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Glameow - 068/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Houndour - 069/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Houndour - 069/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Igglybuff - 070/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Igglybuff - 070/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Illumise - 071/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Illumise - 071/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Jigglypuff - 072/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Jigglypuff - 072/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Kakuna - 073/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Kakuna - 073/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Koffing - 074/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Koffing - 074/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Krabby - 075/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Krabby - 075/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Lunatone - 076/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Lunatone - 076/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Luvdisc - 077/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Luvdisc - 077/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Makuhita - 078/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Makuhita - 078/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Mankey - 079/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Mankey - 079/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Mudkip - 080/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Mudkip - 080/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Porygon - 081/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Porygon - 081/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Slowpoke - 082/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Slowpoke - 082/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Slugma - 083/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Slugma - 083/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Snubbull - 084/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Snubbull - 084/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Solrock - 085/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Solrock - 085/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Swablu - 086/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Swablu - 086/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Tangela - 087/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Tangela - 087/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Togepi - 088/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Togepi - 088/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Torchic - 089/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Torchic - 089/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Treecko - 090/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Treecko - 090/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Unown [L] - 091/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Unown [L] - 091/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Volbeat - 092/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Volbeat - 092/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Weedle - 093/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Weedle - 093/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Whismur - 094/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Whismur - 094/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Wingull - 095/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Wingull - 095/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Zigzagoon - 096/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Zigzagoon - 096/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Amulet Coin - 097/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Amulet Coin - 097/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Felicity's Drawing - 098/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Felicity's Drawing - 098/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Leftovers - 099/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Leftovers - 099/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Moonlight Stadium - 100/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Moonlight Stadium - 100/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Premier Ball - 101/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Premier Ball - 101/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Rare Candy - 102/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Rare Candy - 102/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Cresselia Lv.X - 103/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Darkrai Lv.X - 104/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Dialga Lv.X - 105/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Great Encounters - Palkia Lv.X - 106/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Ampharos - 001/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Ampharos - 001/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Blastoise - 002/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Blastoise - 002/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Charizard - 003/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Charizard - 003/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Entei - 004/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Entei - 004/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Flygon - 005/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Flygon - 005/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Gallade - 006/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Gallade - 006/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Gardevoir - 007/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Gardevoir - 007/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Gastrodon East Sea - 008/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Gastrodon East Sea - 008/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Gastrodon West Sea - 009/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Gastrodon West Sea - 009/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Ho-oh - 010/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Ho-oh - 010/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Jumpluff - 011/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Jumpluff - 011/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Lickilicky - 012/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Lickilicky - 012/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Ludicolo - 013/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Ludicolo - 013/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Lugia - 014/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Lugia - 014/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Mew - 015/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Mew - 015/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Raikou - 016/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Raikou - 016/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Roserade - 017/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Roserade - 017/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Salamence - 018/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Salamence - 018/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Suicune - 019/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Suicune - 019/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Venusaur - 020/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Venusaur - 020/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Absol - 021/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Absol - 021/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Arcanine - 022/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Arcanine - 022/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Banette - 023/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Banette - 023/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Dugtrio - 024/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Dugtrio - 024/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Electivire - 025/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Electivire - 025/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Electrode - 026/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Electrode - 026/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Furret - 027/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Furret - 027/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Golduck - 028/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Golduck - 028/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Golem - 029/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Golem - 029/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Jynx - 030/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Jynx - 030/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Magmortar - 031/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Magmortar - 031/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Minun - 032/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Minun - 032/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Mothim - 033/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Mothim - 033/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Nidoking - 034/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Nidoking - 034/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Pidgeot - 035/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Pidgeot - 035/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Plusle - 036/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Plusle - 036/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Sharpedo - 037/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Sharpedo - 037/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Sunflora - 038/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Sunflora - 038/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Unown [S] - 039/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Unown [S] - 039/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Weavile - 040/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Weavile - 040/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Wormadam Plant Cloak - 041/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Wormadam Plant Cloak - 041/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Wormadam Sandy Cloak - 042/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Wormadam Sandy Cloak - 042/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Wormadam Trash Cloak - 043/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Wormadam Trash Cloak - 043/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Xatu - 044/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Xatu - 044/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Breloom - 045/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Breloom - 045/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Charmeleon - 046/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Charmeleon - 046/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Cloyster - 047/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Cloyster - 047/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Donphan - 048/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Donphan - 048/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Farfetch'd - 049/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Farfetch'd - 049/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Flaaffy - 050/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Flaaffy - 050/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Ivysaur - 051/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Ivysaur - 051/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Kecleon - 052/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Kecleon - 052/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Kirlia - 053/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Kirlia - 053/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Lombre - 054/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Lombre - 054/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Miltank - 055/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Miltank - 055/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Muk - 056/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Muk - 056/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Nidorino - 057/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Nidorino - 057/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Pidgeotto - 058/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Pidgeotto - 058/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Pinsir - 059/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Pinsir - 059/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Quagsire - 060/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Quagsire - 060/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Raticate - 061/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Raticate - 061/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Roselia - 062/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Roselia - 062/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Sableye - 063/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Sableye - 063/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Shelgon - 064/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Shelgon - 064/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Skiploom - 065/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Skiploom - 065/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Smeargle - 066/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Smeargle - 066/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Smoochum - 067/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Smoochum - 067/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Unown [K] - 068/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Unown [K] - 068/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Unown [N] - 069/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Unown [N] - 069/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Unown [O] - 070/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Unown [O] - 070/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Unown [X] - 071/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Unown [X] - 071/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Unown [Z] - 072/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Unown [Z] - 072/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Venomoth - 073/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Venomoth - 073/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Vibrava - 074/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Vibrava - 074/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Wartortle - 075/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Wartortle - 075/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Bagon - 076/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Bagon - 076/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Bulbasaur - 077/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Bulbasaur - 077/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Burmy Plant Cloak - 078/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Burmy Plant Cloak - 078/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Burmy Sandy Cloak - 079/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Burmy Sandy Cloak - 079/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Burmy Trash Cloak - 080/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Burmy Trash Cloak - 080/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Carvanha - 081/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Carvanha - 081/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Charmander - 082/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Charmander - 082/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Clefairy - 083/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Clefairy - 083/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Corsola - 084/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Corsola - 084/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Diglett - 085/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Diglett - 085/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Duskull - 086/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Duskull - 086/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Electabuzz - 087/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Electabuzz - 087/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Grimer - 088/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Grimer - 088/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Growlithe - 089/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Growlithe - 089/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Hoppip - 090/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Hoppip - 090/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Lickitung - 091/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Lickitung - 091/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Lotad - 092/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Lotad - 092/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Magmar - 093/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Magmar - 093/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Mareep - 094/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Mareep - 094/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Murkrow - 095/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Murkrow - 095/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Natu - 096/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Natu - 096/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Nidoran M - 097/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Nidoran M - 097/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Phanpy - 098/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Phanpy - 098/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Pidgey - 099/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Pidgey - 099/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Psyduck - 100/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Psyduck - 100/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Qwilfish - 101/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Qwilfish - 101/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Ralts - 102/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Ralts - 102/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Rattata - 103/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Rattata - 103/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Sentret - 104/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Sentret - 104/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Shellder - 105/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Shellder - 105/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Shellos East Sea - 106/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Shellos East Sea - 106/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Shellos West Sea - 107/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Shellos West Sea - 107/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Shroomish - 108/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Shroomish - 108/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Shuckle - 109/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Shuckle - 109/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Shuppet - 110/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Shuppet - 110/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Spinda - 111/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Spinda - 111/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Squirtle - 112/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Squirtle - 112/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Stantler - 113/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Stantler - 113/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Sunkern - 114/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Sunkern - 114/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Trapinch - 115/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Trapinch - 115/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Venonat - 116/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Venonat - 116/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Voltorb - 117/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Voltorb - 117/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Wooper - 118/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Wooper - 118/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Bebe's Search - 119/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Bebe's Search - 119/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Night Maintenance - 120/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Night Maintenance - 120/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - PlusPower - 121/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - PlusPower - 121/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Professor Oak's Visit - 122/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Professor Oak's Visit - 122/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Professor Rowan - 123/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Professor Rowan - 123/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Rival - 124/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Rival - 124/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Roseanne's Research - 125/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Roseanne's Research - 125/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Team Galactic's Mars - 126/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Team Galactic's Mars - 126/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Potion - 127/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Potion - 127/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Switch - 128/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Switch - 128/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Darkness Energy (Special) - 129/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Darkness Energy (Special) - 129/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Metal Energy (Special) - 130/132 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Metal Energy (Special) - 130/132 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Gardevoir Lv.X - 131/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Secret Wonders - Honchkrow Lv.X - 132/132 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Aggron - 001/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Aggron - 001/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Altaria - 002/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Altaria - 002/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Armaldo - 003/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Armaldo - 003/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Banette - 004/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Banette - 004/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Blaziken - 005/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Blaziken - 005/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Charizard - 006/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Charizard - 006/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Cradily - 007/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Cradily - 007/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Delcatty - 008/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Delcatty - 008/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Gardevoir - 009/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Gardevoir - 009/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Kabutops - 010/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Kabutops - 010/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Machamp - 011/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Machamp - 011/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Raichu - 012/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Raichu - 012/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Slaking - 013/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Slaking - 013/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Dusclops - 014/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Dusclops - 014/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Lanturn - 015/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Lanturn - 015/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Magneton - 016/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Magneton - 016/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Mawile - 017/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Mawile - 017/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Mightyena - 018/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Mightyena - 018/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Ninetales - 019/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Ninetales - 019/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Omastar - 020/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Omastar - 020/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Pichu - 021/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Pichu - 021/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Sableye - 022/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Sableye - 022/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Seviper - 023/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Seviper - 023/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Wobbuffet - 024/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Wobbuffet - 024/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Zangoose - 025/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Zangoose - 025/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Anorith - 026/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Anorith - 026/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Cacturne - 027/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Cacturne - 027/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Charmeleon - 028/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Charmeleon - 028/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Combusken - 029/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Combusken - 029/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Glalie - 030/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Glalie - 030/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Kirlia - 031/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Kirlia - 031/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Lairon - 032/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Lairon - 032/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Machoke - 033/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Machoke - 033/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Medicham - 034/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Medicham - 034/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Metang - 035/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Metang - 035/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Nuzleaf - 036/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Nuzleaf - 036/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Sealeo - 037/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Sealeo - 037/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Sharpedo - 038/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Sharpedo - 038/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Shelgon - 039/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Shelgon - 039/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Vibrava - 040/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Vibrava - 040/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Vigoroth - 041/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Vigoroth - 041/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Aron - 042/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Aron - 042/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Bagon - 043/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Bagon - 043/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Baltoy - 044/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Baltoy - 044/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Beldum - 045/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Beldum - 045/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Cacnea - 046/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Cacnea - 046/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Carvanha - 047/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Carvanha - 047/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Charmander - 048/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Charmander - 048/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Chinchou - 049/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Chinchou - 049/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Duskull - 050/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Duskull - 050/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Kabuto - 051/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Kabuto - 051/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Lileep - 052/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Lileep - 052/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Machop - 053/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Machop - 053/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Magnemite - 054/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Magnemite - 054/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Meditite - 055/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Meditite - 055/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Omanyte - 056/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Omanyte - 056/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Pikachu - 057/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Pikachu - 057/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Poochyena - 058/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Poochyena - 058/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Ralts - 059/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Ralts - 059/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Seedot - 060/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Seedot - 060/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Shuppet - 061/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Shuppet - 061/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Skitty - 062/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Skitty - 062/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Slakoth - 063/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Slakoth - 063/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Snorunt - 064/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Snorunt - 064/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Spheal - 065/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Spheal - 065/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Swablu - 066/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Swablu - 066/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Torchic - 067/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Torchic - 067/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Trapinch - 068/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Trapinch - 068/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Vulpix - 069/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Vulpix - 069/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Wynaut - 070/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Wynaut - 070/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Battle Frontier - 071/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Battle Frontier - 071/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Drake's Stadium - 072/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Drake's Stadium - 072/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Energy Recycle System - 073/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Energy Recycle System - 073/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Energy Removal 2 - 074/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Energy Removal 2 - 074/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Energy Switch - 075/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Energy Switch - 075/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Glacia's Stadium - 076/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Glacia's Stadium - 076/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Great Ball - 077/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Great Ball - 077/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Master Ball - 078/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Master Ball - 078/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Phoebe's Stadium - 079/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Phoebe's Stadium - 079/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Professor Birch - 080/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Professor Birch - 080/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Scott - 081/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Scott - 081/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Sidney's Stadium - 082/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Sidney's Stadium - 082/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Steven's Advice - 083/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Steven's Advice - 083/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Claw Fossil - 084/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Claw Fossil - 084/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Mysterious Fossil - 085/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Mysterious Fossil - 085/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Root Fossil - 086/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Root Fossil - 086/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Darkness Energy (Special) - 087/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Darkness Energy (Special) - 087/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Metal Energy (Special) - 088/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Metal Energy (Special) - 088/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Multi Energy - 089/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Multi Energy - 089/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Multi Energy - 089/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Cyclone Energy - 090/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Cyclone Energy - 090/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Warp Energy - 091/108 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Warp Energy - 091/108 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Absol ex - 092/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Claydol ex - 093/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Flygon ex - 094/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Metagross ex - 095/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Salamence ex - 096/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Shiftry ex - 097/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Skarmory ex - 098/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Walrein ex - 099/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Flareon Star Gold Star - 100/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Jolteon Gold Star - 101/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Vaporeon Gold Star - 102/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Grass Energy - 103/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Fire Energy - 104/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Water Energy - 105/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Lightning Energy - 106/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Psychic Energy - 107/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Power Keepers - Fighting Energy - 108/108 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Ampharos (Delta Species) - 001/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Ampharos (Delta Species) - 001/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Feraligatr (Delta Species) - 002/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Feraligatr (Delta Species) - 002/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Heracross (Delta Species) - 003/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Heracross (Delta Species) - 003/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Meganium (Delta Species) - 004/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Meganium (Delta Species) - 004/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Milotic (Delta Species) - 005/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Milotic (Delta Species) - 005/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Nidoking (Delta Species) - 006/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Nidoking (Delta Species) - 006/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Nidoqueen (Delta Species) - 007/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Nidoqueen (Delta Species) - 007/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Ninetales (Delta Species) - 008/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Ninetales (Delta Species) - 008/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Pinsir (Delta Species) - 009/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Pinsir (Delta Species) - 009/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Snorlax (Delta Species) - 010/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Snorlax (Delta Species) - 010/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Togetic (Delta Species) - 011/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Togetic (Delta Species) - 011/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Typhlosion (Delta Species) - 012/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Typhlosion (Delta Species) - 012/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Arbok (Delta Species) - 013/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Arbok (Delta Species) - 013/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Cloyster (Delta Species) - 014/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Cloyster (Delta Species) - 014/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Dewgong (Delta Species) - 015/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Dewgong (Delta Species) - 015/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Gligar (Delta Species) - 016/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Gligar (Delta Species) - 016/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Jynx (Delta Species) - 017/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Jynx (Delta Species) - 017/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Ledian (Delta Species) - 018/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Ledian (Delta Species) - 018/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Lickitung (Delta Species) - 019/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Lickitung (Delta Species) - 019/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Mantine (Delta Species) - 020/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Mantine (Delta Species) - 020/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Quagsire (Delta Species) - 021/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Quagsire (Delta Species) - 021/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Seadra (22) (Delta Species) - 022/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Seadra (22) (Delta Species) - 022/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Tropius (Delta Species) - 023/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Tropius (Delta Species) - 023/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Vibrava (24 - Delta Species) - 024/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Vibrava (24 - Delta Species) - 024/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Xatu (Delta Species) - 025/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Xatu (Delta Species) - 025/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Bayleef (Delta Species) - 026/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Bayleef (Delta Species) - 026/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Croconaw (Delta Species) - 027/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Croconaw (Delta Species) - 027/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Dragonair (Delta Species) - 028/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Dragonair (Delta Species) - 028/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Electabuzz (Delta Species) - 029/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Electabuzz (Delta Species) - 029/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Flaaffy (Delta Species) - 030/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Flaaffy (Delta Species) - 030/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Horsea (31 - Delta Species) - 031/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Horsea (31 - Delta Species) - 031/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Kirlia - 032/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Kirlia - 032/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Kirlia (Delta Species) - 033/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Kirlia (Delta Species) - 033/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Nidorina (Delta Species) - 034/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Nidorina (Delta Species) - 034/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Nidorino (Delta Species) - 035/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Nidorino (Delta Species) - 035/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Quilava (Delta Species) - 036/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Quilava (Delta Species) - 036/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Seadra (37) (Delta Species) - 037/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Seadra (37) (Delta Species) - 037/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Shelgon (Delta Species) - 038/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Shelgon (Delta Species) - 038/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Smeargle (Delta Species) - 039/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Smeargle (Delta Species) - 039/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Swellow (Delta Species) - 040/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Swellow (Delta Species) - 040/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Togepi (Delta Species) - 041/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Togepi (Delta Species) - 041/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Vibrava (42 - Delta Species) - 042/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Vibrava (42 - Delta Species) - 042/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Bagon (Delta Species) - 043/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Bagon (Delta Species) - 043/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Chikorita (Delta Species) - 044/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Chikorita (Delta Species) - 044/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Cyndaquil (Delta Species) - 045/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Cyndaquil (Delta Species) - 045/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Dratini (Delta Species) - 046/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Dratini (Delta Species) - 046/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Ekans (Delta Species) - 047/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Ekans (Delta Species) - 047/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Elekid (Delta Species) - 048/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Elekid (Delta Species) - 048/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Feebas (Delta Species) - 049/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Feebas (Delta Species) - 049/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Horsea (50 - Delta Species) - 050/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Horsea (50 - Delta Species) - 050/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Larvitar - 051/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Larvitar - 051/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Larvitar (Delta Species) - 052/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Larvitar (Delta Species) - 052/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Ledyba (Delta Species) - 053/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Ledyba (Delta Species) - 053/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Mareep (Delta Species) - 054/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Mareep (Delta Species) - 054/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Natu (Delta Species) - 055/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Natu (Delta Species) - 055/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Nidoran F (Delta Species) - 056/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Nidoran F (Delta Species) - 056/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Nidoran M (Delta Species) - 057/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Nidoran M (Delta Species) - 057/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Pupitar - 058/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Pupitar - 058/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Pupitar (Delta Species) - 059/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Pupitar (Delta Species) - 059/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Ralts - 060/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Ralts - 060/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Ralts (Delta Species) - 061/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Ralts (Delta Species) - 061/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Seel (Delta Species) - 062/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Seel (Delta Species) - 062/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Shellder (Delta Species) - 063/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Shellder (Delta Species) - 063/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Smoochum (Delta Species) - 064/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Smoochum (Delta Species) - 064/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Swablu (Delta Species) - 065/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Swablu (Delta Species) - 065/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Taillow (Delta Species) - 066/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Taillow (Delta Species) - 066/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Totodile (Delta Species) - 067/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Totodile (Delta Species) - 067/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Trapinch (68 - Delta Species) - 068/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Trapinch (68 - Delta Species) - 068/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Trapinch (69 - Delta Species) - 069/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Trapinch (69 - Delta Species) - 069/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Vulpix (Delta Species) - 070/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Vulpix (Delta Species) - 070/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Wooper (Delta Species) - 071/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Wooper (Delta Species) - 071/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Buffer Piece - 072/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Buffer Piece - 072/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Copycat - 073/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Copycat - 073/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Holon Legacy - 074/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Holon Legacy - 074/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Holon Mentor - 075/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Holon Mentor - 075/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Island Hermit - 076/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Island Hermit - 076/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Mr. Stone's Project - 077/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Mr. Stone's Project - 077/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Old Rod - 078/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Old Rod - 078/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Professor Elm's Training Method - 079/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Professor Elm's Training Method - 079/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Professor Oak's Research - 080/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Professor Oak's Research - 080/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Strength Charm - 081/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Strength Charm - 081/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - TV Reporter - 082/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - TV Reporter - 082/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Switch - 083/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Switch - 083/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Holon Energy FF - 084/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Holon Energy FF - 084/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Holon Energy GL - 085/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Holon Energy GL - 085/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Holon Energy WP - 086/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Holon Energy WP - 086/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Boost Energy - 087/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Boost Energy - 087/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Delta Species Rainbow Energy - 088/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Delta Species Rainbow Energy - 088/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Scramble Energy - 089/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Scramble Energy - 089/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Altaria ex (Delta Species) - 090/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Dragonite ex (Delta Species) - 091/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Flygon ex (Delta Species) - 092/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Gardevoir ex (Delta Species) - 093/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Kingdra ex (Delta Species) - 094/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Latias ex (Delta Species) - 095/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Latios ex (Delta Species) - 096/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Rayquaza ex (Delta Species) - 097/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Salamence ex (Delta Species) - 098/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Tyranitar ex (Delta Species) - 099/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Charizard Gold Star (Delta Species) - 100/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Mew Gold Star (Delta Species) - 101/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon Frontiers - Dragonair (Delta Species) - 28/101 (Prerelease Promo) - 028/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Armaldo (Delta Species) - 001/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Armaldo (Delta Species) - 001/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Cradily (Delta Species) - 002/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Cradily (Delta Species) - 002/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Deoxys (Delta Species - Attack Forme) - 003/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Deoxys (Delta Species - Attack Forme) - 003/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Deoxys (Delta Species - Defense Forme) - 004/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Deoxys (Delta Species - Defense Forme) - 004/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Deoxys (Delta Species - Normal Forme) - 005/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Deoxys (Delta Species - Normal Forme) - 005/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Deoxys (Delta Species - Speed Forme) - 006/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Deoxys (Delta Species - Speed Forme) - 006/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Flygon (Delta Species) - 007/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Flygon (Delta Species) - 007/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Gyarados (Delta Species) - 008/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Gyarados (Delta Species) - 008/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Kabutops (Delta Species) - 009/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Kabutops (Delta Species) - 009/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Kingdra (Delta Species) - 010/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Kingdra (Delta Species) - 010/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Latias (11 - Delta Species) - 011/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Latias (11 - Delta Species) - 011/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Latios (12 - Delta Species) - 012/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Latios (12 - Delta Species) - 012/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Omastar (Delta Species) - 013/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Omastar (Delta Species) - 013/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Pidgeot (Delta Species) - 014/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Pidgeot (Delta Species) - 014/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Raichu (Delta Species) - 015/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Raichu (Delta Species) - 015/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Rayquaza (16 - Delta Species) - 016/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Rayquaza (16 - Delta Species) - 016/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Vileplume (Delta Species) - 017/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Vileplume (Delta Species) - 017/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Absol - 018/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Absol - 018/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Bellossom (Delta Species) - 019/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Bellossom (Delta Species) - 019/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Blaziken - 020/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Blaziken - 020/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Latias (Delta Species) - 021/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Latias (Delta Species) - 021/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Latios (Delta Species) - 022/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Latios (Delta Species) - 022/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Mawile - 023/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Mawile - 023/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Mewtwo (Delta Species) - 024/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Mewtwo (Delta Species) - 024/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Nosepass - 025/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Nosepass - 025/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Rayquaza (26 - Delta Species) - 026/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Rayquaza (26 - Delta Species) - 026/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Regice - 027/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Regice - 027/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Regirock - 028/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Regirock - 028/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Registeel - 029/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Registeel - 029/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Relicanth - 030/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Relicanth - 030/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Sableye - 031/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Sableye - 031/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Seviper - 032/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Seviper - 032/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Torkoal - 033/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Torkoal - 033/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Zangoose - 034/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Zangoose - 034/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Aerodactyl (Delta Species) - 035/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Aerodactyl (Delta Species) - 035/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Camerupt - 036/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Camerupt - 036/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Chimecho (Delta Species) - 037/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Chimecho (Delta Species) - 037/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Claydol - 038/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Claydol - 038/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Combusken - 039/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Combusken - 039/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Donphan - 040/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Donphan - 040/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Exeggutor (Delta Species) - 041/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Exeggutor (Delta Species) - 041/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Gloom (Delta Species) - 042/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Gloom (Delta Species) - 042/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Golduck (Delta Species) - 043/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Golduck (Delta Species) - 043/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Holon's Castform - 044/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Holon's Castform - 044/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Lairon - 045/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Lairon - 045/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Manectric - 046/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Manectric - 046/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Masquerain - 047/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Masquerain - 047/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Persian (Delta Species) - 048/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Persian (Delta Species) - 048/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Pidgeotto (Delta Species) - 049/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Pidgeotto (Delta Species) - 049/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Primeape (Delta Species) - 050/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Primeape (Delta Species) - 050/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Raichu - 051/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Raichu - 051/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Seadra (Delta Species) - 052/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Seadra (Delta Species) - 052/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Sharpedo (Delta Species) - 053/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Sharpedo (Delta Species) - 053/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Vibrava (Delta Species) - 054/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Vibrava (Delta Species) - 054/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Whiscash - 055/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Whiscash - 055/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Wobbuffet - 056/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Wobbuffet - 056/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Anorith (Delta Species) - 057/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Anorith (Delta Species) - 057/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Aron - 058/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Aron - 058/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Baltoy - 059/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Baltoy - 059/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Barboach - 060/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Barboach - 060/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Carvanha (Delta Species) - 061/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Carvanha (Delta Species) - 061/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Corphish (62) - 062/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Corphish (62) - 062/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Corphish (63) - 063/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Corphish (63) - 063/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Electrike - 064/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Electrike - 064/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Exeggcute (Delta Species) - 065/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Exeggcute (Delta Species) - 065/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Horsea (Delta Species) - 066/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Horsea (Delta Species) - 066/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Kabuto (Delta Species) - 067/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Kabuto (Delta Species) - 067/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Lileep (Delta Species) - 068/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Lileep (Delta Species) - 068/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Magikarp (Delta Species) - 069/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Magikarp (Delta Species) - 069/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Mankey (Delta Species) - 070/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Mankey (Delta Species) - 070/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Meowth (Delta Species) - 071/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Meowth (Delta Species) - 071/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Numel - 072/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Numel - 072/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Oddish (Delta Species) - 073/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Oddish (Delta Species) - 073/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Omanyte (Delta Species) - 074/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Omanyte (Delta Species) - 074/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Phanpy - 075/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Phanpy - 075/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Pichu (Delta Species) - 076/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Pichu (Delta Species) - 076/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Pidgey (Delta Species) - 077/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Pidgey (Delta Species) - 077/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Pikachu - 078/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Pikachu - 078/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Pikachu (Delta Species) - 079/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Pikachu (Delta Species) - 079/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Poochyena - 080/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Poochyena - 080/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Psyduck (Delta Species) - 081/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Psyduck (Delta Species) - 081/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Surskit - 082/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Surskit - 082/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Torchic - 083/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Torchic - 083/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Trapinch (Delta Species) - 084/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Trapinch (Delta Species) - 084/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Holon Adventurer - 085/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Holon Adventurer - 085/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Holon Fossil - 086/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Holon Fossil - 086/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Holon Lake - 087/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Holon Lake - 087/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Mr. Stone's Project - 088/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Mr. Stone's Project - 088/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Professor Cozmo's Discovery - 089/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Professor Cozmo's Discovery - 089/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Rare Candy - 090/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Rare Candy - 090/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Claw Fossil - 091/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Claw Fossil - 091/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Mysterious Fossil - 092/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Mysterious Fossil - 092/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Root Fossil - 093/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Root Fossil - 093/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Darkness Energy (Special) - 094/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Darkness Energy (Special) - 094/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Metal Energy (Special) - 095/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Metal Energy (Special) - 095/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Multi Energy - 096/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Multi Energy - 096/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Dark Metal Energy - 097/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Dark Metal Energy - 097/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Delta Species Rainbow Energy - 098/110 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Delta Species Rainbow Energy - 098/110 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Crawdaunt ex - 099/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Mew ex - 100/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Mightyena ex - 101/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Gyarados Gold Star (Delta Species) - 102/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Mewtwo Gold Star - 103/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Pikachu Gold Star - 104/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Grass Energy - 105/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Fire Energy - 106/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Water Energy - 107/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Lightning Energy - 108/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Psychic Energy - 109/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Fighting Energy - 110/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Holon Phantoms - Mew - 111/110 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Aerodactyl - 001/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Aerodactyl - 001/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Aggron - 002/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Aggron - 002/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Cradily - 003/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Cradily - 003/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Delcatty - 004/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Delcatty - 004/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Gengar - 005/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Gengar - 005/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Golem - 006/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Golem - 006/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Kabutops - 007/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Kabutops - 007/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Lapras - 008/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Lapras - 008/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Machamp - 009/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Machamp - 009/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Mew - 010/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Mew - 010/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Muk - 011/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Muk - 011/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Shiftry - 012/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Shiftry - 012/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Victreebel - 013/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Victreebel - 013/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Wailord - 014/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Wailord - 014/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Absol - 015/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Absol - 015/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Girafarig - 016/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Girafarig - 016/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Gorebyss - 017/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Gorebyss - 017/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Huntail - 018/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Huntail - 018/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Lanturn - 019/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Lanturn - 019/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Lunatone - 020/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Lunatone - 020/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Magmar - 021/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Magmar - 021/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Magneton - 022/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Magneton - 022/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Omastar - 023/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Omastar - 023/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Pinsir - 024/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Pinsir - 024/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Solrock - 025/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Solrock - 025/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Spinda - 026/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Spinda - 026/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Torkoal - 027/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Torkoal - 027/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Wobbuffet - 028/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Wobbuffet - 028/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Anorith - 029/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Anorith - 029/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Cascoon - 030/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Cascoon - 030/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Dunsparce - 031/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Dunsparce - 031/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Electrode - 032/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Electrode - 032/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Furret - 033/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Furret - 033/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Graveler - 034/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Graveler - 034/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Haunter - 035/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Haunter - 035/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Kabuto - 036/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Kabuto - 036/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Kecleon - 037/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Kecleon - 037/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Lairon - 038/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Lairon - 038/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Machoke - 039/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Machoke - 039/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Misdreavus - 040/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Misdreavus - 040/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Nuzleaf - 041/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Nuzleaf - 041/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Roselia - 042/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Roselia - 042/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Sealeo - 043/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Sealeo - 043/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Tangela - 044/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Tangela - 044/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Tentacruel - 045/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Tentacruel - 045/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Vibrava - 046/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Vibrava - 046/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Weepinbell - 047/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Weepinbell - 047/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Aron - 048/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Aron - 048/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Bellsprout - 049/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Bellsprout - 049/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Chinchou - 050/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Chinchou - 050/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Clamperl - 051/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Clamperl - 051/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Gastly - 052/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Gastly - 052/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Geodude - 053/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Geodude - 053/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Grimer - 054/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Grimer - 054/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Growlithe - 055/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Growlithe - 055/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Lileep - 056/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Lileep - 056/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Machop - 057/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Machop - 057/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Magby - 058/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Magby - 058/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Magnemite - 059/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Magnemite - 059/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Omanyte - 060/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Omanyte - 060/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Seedot - 061/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Seedot - 061/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Sentret - 062/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Sentret - 062/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Shuppet - 063/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Shuppet - 063/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Skitty - 064/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Skitty - 064/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Spheal - 065/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Spheal - 065/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Tentacool - 066/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Tentacool - 066/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Trapinch - 067/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Trapinch - 067/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Voltorb - 068/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Voltorb - 068/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Wailmer - 069/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Wailmer - 069/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Wurmple - 070/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Wurmple - 070/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Wynaut - 071/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Wynaut - 071/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Cursed Stone - 072/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Cursed Stone - 072/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Fieldworker - 073/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Fieldworker - 073/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Full Flame - 074/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Full Flame - 074/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Giant Stump - 075/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Giant Stump - 075/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Power Tree - 076/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Power Tree - 076/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Strange Cave - 077/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Strange Cave - 077/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Claw Fossil - 078/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Claw Fossil - 078/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Mysterious Fossil - 079/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Mysterious Fossil - 079/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Root Fossil - 080/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Root Fossil - 080/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Rainbow Energy - 081/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Rainbow Energy - 081/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - React Energy - 082/92 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - React Energy - 082/92 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Arcanine ex - 083/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Armaldo ex - 084/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Banette ex - 085/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Dustox ex - 086/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Flygon ex - 087/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Mew ex - 088/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Walrein ex - 089/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Regice Gold Star - 090/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Regirock Gold Star - 091/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Registeel Star - 092/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Legend Maker - Pikachu (Delta Species) - 093/92 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Beedrill (Delta Species) - 001/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Beedrill (Delta Species) - 001/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Crobat (Delta Species) - 002/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Crobat (Delta Species) - 002/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Dragonite (Delta Species) - 003/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Dragonite (Delta Species) - 003/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Espeon (Delta Species) - 004/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Espeon (Delta Species) - 004/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Flareon (Delta Species) - 005/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Flareon (Delta Species) - 005/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Gardevoir (Delta Species) - 006/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Gardevoir (Delta Species) - 006/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Jolteon (Delta Species) - 007/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Jolteon (Delta Species) - 007/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Latias (Delta Species) - 008/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Latias (Delta Species) - 008/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Latios (Delta Species) - 009/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Latios (Delta Species) - 009/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Marowak (Delta Species) - 010/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Marowak (Delta Species) - 010/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Metagross (Delta Species) - 011/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Metagross (Delta Species) - 011/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Mewtwo (Delta Species) - 012/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Mewtwo (Delta Species) - 012/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Rayquaza (Delta Species) - 013/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Rayquaza (Delta Species) - 013/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Salamence (Delta Species) - 014/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Salamence (Delta Species) - 014/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Starmie (15 - Delta Species) - 015/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Starmie (15 - Delta Species) - 015/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Tyranitar (Delta Species) - 016/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Tyranitar (Delta Species) - 016/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Umbreon (Delta Species) - 017/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Umbreon (Delta Species) - 017/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Vaporeon (Delta Species) - 018/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Vaporeon (Delta Species) - 018/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Azumarill (Delta Species) - 019/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Azumarill (Delta Species) - 019/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Azurill - 020/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Azurill - 020/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon's Electrode - 021/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon's Electrode - 021/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon's Magneton - 022/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon's Magneton - 022/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Hypno - 023/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Hypno - 023/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Mightyena (Delta Species) - 024/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Mightyena (Delta Species) - 024/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Porygon2 - 025/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Porygon2 - 025/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Rain Castform - 026/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Rain Castform - 026/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Sandslash (Delta Species) - 027/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Sandslash (Delta Species) - 027/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Slowking - 028/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Slowking - 028/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Snow-cloud Castform - 029/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Snow-cloud Castform - 029/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Starmie (30 - Delta Species) - 030/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Starmie (30 - Delta Species) - 030/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Sunny Castform - 031/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Sunny Castform - 031/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Swellow - 032/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Swellow - 032/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Weezing - 033/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Weezing - 033/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Castform - 034/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Castform - 034/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto - 035/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto - 035/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto (Bulbasaur) - 036/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto (Bulbasaur) - 036/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto (37 - Charmander) - 037/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto (37 - Charmander) - 037/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto (Mr. Mime) - 038/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto (Mr. Mime) - 038/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto (39 - Pikachu) - 039/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto (39 - Pikachu) - 039/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto (40 - Squirtle) - 040/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto (40 - Squirtle) - 040/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Dragonair (41 - Delta Species) - 041/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Dragonair (41 - Delta Species) - 041/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Dragonair (42 - Delta Species) - 042/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Dragonair (42 - Delta Species) - 042/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Golbat - 043/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Golbat - 043/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Hariyama - 044/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Hariyama - 044/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Illumise - 045/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Illumise - 045/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Kakuna - 046/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Kakuna - 046/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Kirlia - 047/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Kirlia - 047/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Magneton - 048/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Magneton - 048/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Metang (Delta Species) - 049/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Metang (Delta Species) - 049/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Persian - 050/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Persian - 050/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Pupitar (Delta Species) - 051/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Pupitar (Delta Species) - 051/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Rapidash - 052/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Rapidash - 052/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Shelgon (53 - Delta Species) - 053/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Shelgon (53 - Delta Species) - 053/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Shelgon (54 - Delta Species) - 054/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Shelgon (54 - Delta Species) - 054/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Skarmory - 055/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Skarmory - 055/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Volbeat - 056/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Volbeat - 056/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Bagon (57 - Delta Species) - 057/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Bagon (57 - Delta Species) - 057/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Bagon (58 - Delta Species) - 058/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Bagon (58 - Delta Species) - 058/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Beldum (Delta Species) - 059/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Beldum (Delta Species) - 059/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Cubone - 060/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Cubone - 060/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto (61 - Charmander) - 061/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto (61 - Charmander) - 061/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto (Geodude) - 062/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto (Geodude) - 062/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto (63 - Pikachu) - 063/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto (63 - Pikachu) - 063/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto (64 - Squirtle) - 064/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ditto (64 - Squirtle) - 064/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Dratini (65 - Delta Species) - 065/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Dratini (65 - Delta Species) - 065/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Dratini (66 - Delta Species) - 066/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Dratini (66 - Delta Species) - 066/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Drowzee - 067/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Drowzee - 067/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Eevee - 068/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Eevee - 068/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Eevee - 069/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Eevee - 069/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon's Magnemite - 070/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon's Magnemite - 070/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon's Voltorb - 071/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon's Voltorb - 071/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Koffing - 072/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Koffing - 072/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Larvitar (Delta Species) - 073/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Larvitar (Delta Species) - 073/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Magnemite - 074/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Magnemite - 074/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Makuhita - 075/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Makuhita - 075/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Marill - 076/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Marill - 076/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Meowth - 077/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Meowth - 077/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ponyta - 078/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ponyta - 078/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Poochyena - 079/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Poochyena - 079/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Porygon - 080/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Porygon - 080/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ralts - 081/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Ralts - 081/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Sandshrew - 082/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Sandshrew - 082/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Slowpoke - 083/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Slowpoke - 083/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Staryu (84) - 084/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Staryu (84) - 084/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Staryu (85) - 085/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Staryu (85) - 085/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Taillow - 086/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Taillow - 086/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Weedle - 087/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Weedle - 087/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Zubat - 088/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Zubat - 088/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Dual Ball - 089/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Dual Ball - 089/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Great Ball - 090/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Great Ball - 090/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Farmer - 091/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Farmer - 091/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Lass - 092/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Lass - 092/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Mentor - 093/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Mentor - 093/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Research Tower - 094/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Research Tower - 094/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Researcher - 095/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Researcher - 095/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Ruins - 096/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Ruins - 096/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Scientist - 097/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Scientist - 097/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Transceiver - 098/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Transceiver - 098/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Master Ball - 099/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Master Ball - 099/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Super Scoop Up - 100/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Super Scoop Up - 100/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Potion - 101/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Potion - 101/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Switch - 102/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Switch - 102/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Darkness Energy (Special) - 103/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Darkness Energy (Special) - 103/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Energy FF - 104/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Energy FF - 104/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Energy GL - 105/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Energy GL - 105/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Energy WP - 106/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Holon Energy WP - 106/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Metal Energy (Special) - 107/113 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Metal Energy (Special) - 107/113 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Flareon ex - 108/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Jolteon ex - 109/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Vaporeon ex - 110/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Groudon Gold Star - 111/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Kyogre Gold Star - 112/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Metagross Gold Star - 113/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Delta Species - Azumarill - 114/113 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Ampharos - 001/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Ampharos - 001/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Ariados - 002/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Ariados - 002/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Bellossom - 003/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Bellossom - 003/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Feraligatr - 004/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Feraligatr - 004/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Flareon - 005/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Flareon - 005/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Forretress - 006/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Forretress - 006/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Houndoom - 007/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Houndoom - 007/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Jolteon - 008/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Jolteon - 008/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Meganium - 009/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Meganium - 009/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Octillery - 010/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Octillery - 010/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Poliwrath - 011/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Poliwrath - 011/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Porygon2 - 012/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Porygon2 - 012/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Slowbro - 013/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Slowbro - 013/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Slowking - 014/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Slowking - 014/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Sudowoodo - 015/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Sudowoodo - 015/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Sunflora - 016/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Sunflora - 016/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Typhlosion - 017/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Typhlosion - 017/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Ursaring - 018/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Ursaring - 018/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Vaporeon - 019/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Vaporeon - 019/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Chansey - 020/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Chansey - 020/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Cleffa - 021/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Cleffa - 021/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Electabuzz - 022/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Electabuzz - 022/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Elekid - 023/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Elekid - 023/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Hitmonchan - 024/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Hitmonchan - 024/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Hitmonlee - 025/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Hitmonlee - 025/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Hitmontop - 026/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Hitmontop - 026/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Ho-Oh - 027/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Ho-Oh - 027/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Jynx - 028/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Jynx - 028/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Lugia - 029/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Lugia - 029/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Murkrow - 030/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Murkrow - 030/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Smoochum - 031/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Smoochum - 031/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Stantler - 032/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Stantler - 032/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Tyrogue - 033/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Tyrogue - 033/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Aipom - 034/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Aipom - 034/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Bayleef - 035/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Bayleef - 035/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Clefable - 036/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Clefable - 036/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Corsola - 037/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Corsola - 037/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Croconaw - 038/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Croconaw - 038/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Granbull - 039/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Granbull - 039/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Lanturn - 040/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Lanturn - 040/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Magcargo - 041/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Magcargo - 041/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Miltank - 042/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Miltank - 042/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Noctowl - 043/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Noctowl - 043/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Quagsire - 044/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Quagsire - 044/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Quilava - 045/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Quilava - 045/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Scyther - 046/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Scyther - 046/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Shuckle - 047/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Shuckle - 047/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Smeargle - 048/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Smeargle - 048/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Xatu - 049/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Xatu - 049/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Yanma - 050/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Yanma - 050/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Chikorita - 051/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Chikorita - 051/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Chinchou - 052/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Chinchou - 052/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Clefairy - 053/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Clefairy - 053/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Cyndaquil - 054/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Cyndaquil - 054/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Eevee - 055/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Eevee - 055/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Flaaffy - 056/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Flaaffy - 056/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Gligar - 057/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Gligar - 057/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Gloom - 058/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Gloom - 058/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Hoothoot - 059/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Hoothoot - 059/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Houndour - 060/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Houndour - 060/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Larvitar - 061/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Larvitar - 061/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Mareep - 062/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Mareep - 062/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Natu - 063/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Natu - 063/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Oddish - 064/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Oddish - 064/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Onix - 065/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Onix - 065/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Pineco - 066/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Pineco - 066/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Poliwag - 067/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Poliwag - 067/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Poliwhirl - 068/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Poliwhirl - 068/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Porygon - 069/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Porygon - 069/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Pupitar - 070/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Pupitar - 070/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Remoraid - 071/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Remoraid - 071/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Slowpoke - 072/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Slowpoke - 072/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Slugma - 073/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Slugma - 073/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Snubbull - 074/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Snubbull - 074/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Spinarak - 075/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Spinarak - 075/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Sunkern - 076/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Sunkern - 076/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Teddiursa - 077/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Teddiursa - 077/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Totodile - 078/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Totodile - 078/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Wooper - 079/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Wooper - 079/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Curse Powder - 080/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Curse Powder - 080/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Energy Recycle System - 081/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Energy Recycle System - 081/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Energy Removal 2 - 082/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Energy Removal 2 - 082/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Energy Root - 083/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Energy Root - 083/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Energy Switch - 084/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Energy Switch - 084/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Fluffy Berry - 085/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Fluffy Berry - 085/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Mary's Request - 086/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Mary's Request - 086/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Poke Ball - 087/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Poke Ball - 087/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Pokemon Reversal - 088/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Pokemon Reversal - 088/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Professor Elm's Training Method - 089/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Professor Elm's Training Method - 089/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Protective Orb - 090/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Protective Orb - 090/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Sitrus Berry - 091/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Sitrus Berry - 091/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Solid Rage - 092/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Solid Rage - 092/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Warp Point - 093/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Warp Point - 093/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Energy Search - 094/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Energy Search - 094/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Potion - 095/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Potion - 095/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Darkness Energy (Special) - 096/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Darkness Energy (Special) - 096/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Metal Energy (Special) - 097/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Metal Energy (Special) - 097/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Boost Energy - 098/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Boost Energy - 098/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Cyclone Energy - 099/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Cyclone Energy - 099/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Warp Energy - 100/115 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Warp Energy - 100/115 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Blissey ex - 101/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Espeon ex - 102/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Feraligatr ex - 103/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Ho-Oh ex - 104/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Lugia ex - 105/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Meganium ex - 106/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Politoed ex - 107/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Scizor ex - 108/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Steelix ex - 109/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Typhlosion ex - 110/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Tyranitar ex - 111/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Umbreon ex - 112/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Entei Gold Star - 113/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Raikou Gold Star - 114/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Suicune Gold Star - 115/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Rocket's Persian ex - 116/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Unseen Forces - Celebi ex - 117/115 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Blaziken - 001/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Blaziken - 001/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Deoxys (Speed Forme) - 002/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Deoxys (Speed Forme) - 002/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Exploud - 003/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Exploud - 003/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Gardevoir - 004/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Gardevoir - 004/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Groudon (05) - 005/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Groudon (05) - 005/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Kyogre (06) - 006/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Kyogre (06) - 006/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Manectric (07) - 007/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Manectric (07) - 007/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Milotic - 008/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Milotic - 008/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Rayquaza - 009/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Rayquaza - 009/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Sceptile - 010/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Sceptile - 010/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Swampert - 011/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Swampert - 011/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Chimecho - 012/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Chimecho - 012/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Glalie - 013/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Glalie - 013/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Groudon (14) - 014/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Groudon (14) - 014/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Kyogre (15) - 015/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Kyogre (15) - 015/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Manectric (16) - 016/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Manectric (16) - 016/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Nosepass - 017/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Nosepass - 017/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Relicanth - 018/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Relicanth - 018/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Rhydon - 019/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Rhydon - 019/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Seviper - 020/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Seviper - 020/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Zangoose - 021/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Zangoose - 021/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Breloom - 022/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Breloom - 022/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Camerupt - 023/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Camerupt - 023/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Claydol - 024/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Claydol - 024/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Combusken - 025/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Combusken - 025/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Dodrio - 026/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Dodrio - 026/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Electrode - 027/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Electrode - 027/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Grovyle - 028/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Grovyle - 028/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Grumpig (29) - 029/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Grumpig (29) - 029/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Grumpig (30) - 030/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Grumpig (30) - 030/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Hariyama - 031/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Hariyama - 031/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Illumise - 032/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Illumise - 032/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Kirlia - 033/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Kirlia - 033/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Linoone - 034/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Linoone - 034/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Loudred - 035/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Loudred - 035/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Marshtomp - 036/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Marshtomp - 036/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Minun - 037/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Minun - 037/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Ninetales - 038/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Ninetales - 038/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Plusle - 039/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Plusle - 039/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Swalot - 040/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Swalot - 040/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Swellow - 041/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Swellow - 041/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Volbeat - 042/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Volbeat - 042/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Baltoy - 043/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Baltoy - 043/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Cacnea - 044/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Cacnea - 044/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Doduo - 045/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Doduo - 045/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Duskull - 046/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Duskull - 046/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Electrike (47) - 047/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Electrike (47) - 047/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Electrike (48) - 048/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Electrike (48) - 048/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Feebas (49) - 049/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Feebas (49) - 049/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Feebas (50) - 050/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Feebas (50) - 050/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Gulpin - 051/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Gulpin - 051/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Larvitar - 052/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Larvitar - 052/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Luvdisc - 053/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Luvdisc - 053/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Makuhita - 054/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Makuhita - 054/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Meditite - 055/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Meditite - 055/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Mudkip - 056/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Mudkip - 056/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Numel (57) - 057/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Numel (57) - 057/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Numel (58) - 058/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Numel (58) - 058/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Pichu - 059/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Pichu - 059/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Pikachu - 060/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Pikachu - 060/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Ralts - 061/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Ralts - 061/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Rhyhorn - 062/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Rhyhorn - 062/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Shroomish - 063/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Shroomish - 063/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Snorunt - 064/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Snorunt - 064/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Spoink (65) - 065/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Spoink (65) - 065/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Spoink (66) - 066/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Spoink (66) - 066/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Swablu - 067/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Swablu - 067/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Taillow - 068/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Taillow - 068/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Torchic - 069/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Torchic - 069/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Treecko - 070/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Treecko - 070/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Voltorb - 071/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Voltorb - 071/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Vulpix - 072/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Vulpix - 072/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Whismur - 073/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Whismur - 073/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Zigzagoon - 074/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Zigzagoon - 074/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Battle Frontier - 075/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Battle Frontier - 075/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Double Full Heal - 076/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Double Full Heal - 076/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Lanette's Net Search - 077/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Lanette's Net Search - 077/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Lum Berry - 078/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Lum Berry - 078/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Mr. Stone's Project - 079/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Mr. Stone's Project - 079/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Oran Berry - 080/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Oran Berry - 080/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Pokenav - 081/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Pokenav - 081/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Professor Birch - 082/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Professor Birch - 082/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Rare Candy - 083/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Rare Candy - 083/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Scott - 084/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Scott - 084/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Wally's Training - 085/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Wally's Training - 085/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Darkness Energy (Special) - 086/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Darkness Energy (Special) - 086/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Double Rainbow Energy - 087/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Double Rainbow Energy - 087/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Metal Energy (Special) - 088/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Metal Energy (Special) - 088/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Multi Energy - 089/106 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Multi Energy - 089/106 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Altaria ex - 090/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Cacturne ex - 091/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Camerupt ex - 092/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Deoxys ex (Speed Forme) - 093/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Dusclops ex - 094/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Medicham ex - 095/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Milotic ex - 096/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Raichu ex - 097/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Regice ex - 098/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Regirock ex - 099/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Registeel ex - 100/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Grass Energy - 101/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Fire Energy - 102/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Water Energy - 103/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Lightning Energy - 104/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Psychic Energy - 105/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Fighting Energy - 106/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Emerald - Farfetch'd (Secret Rare) - 107/106 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Altaria - 001/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Altaria - 001/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Beautifly - 002/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Beautifly - 002/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Breloom - 003/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Breloom - 003/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Camerupt - 004/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Camerupt - 004/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Claydol - 005/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Claydol - 005/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Crawdaunt - 006/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Crawdaunt - 006/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Dusclops - 007/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Dusclops - 007/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Gyarados - 008/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Gyarados - 008/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Jirachi - 009/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Jirachi - 009/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Ludicolo (10) - 010/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Ludicolo (10) - 010/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Metagross - 011/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Metagross - 011/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Mightyena - 012/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Mightyena - 012/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Ninjask - 013/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Ninjask - 013/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Shedinja - 014/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Shedinja - 014/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Slaking - 015/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Slaking - 015/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Deoxys (Normal Forme) - 016/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Deoxys (Normal Forme) - 016/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Deoxys (Attack Forme) - 017/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Deoxys (Attack Forme) - 017/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Deoxys (Defense Forme) - 018/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Deoxys (Defense Forme) - 018/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Ludicolo (19) - 019/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Ludicolo (19) - 019/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Magcargo (20) - 020/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Magcargo (20) - 020/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Pelipper - 021/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Pelipper - 021/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Rayquaza - 022/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Rayquaza - 022/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Sableye - 023/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Sableye - 023/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Seaking - 024/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Seaking - 024/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Shiftry - 025/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Shiftry - 025/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Skarmory - 026/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Skarmory - 026/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Tropius - 027/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Tropius - 027/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Whiscash - 028/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Whiscash - 028/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Xatu - 029/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Xatu - 029/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Donphan - 030/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Donphan - 030/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Golbat - 031/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Golbat - 031/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Grumpig - 032/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Grumpig - 032/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Lombre (33) - 033/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Lombre (33) - 033/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Lombre (34) - 034/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Lombre (34) - 034/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Lotad (35) - 035/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Lotad (35) - 035/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Lunatone - 036/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Lunatone - 036/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Magcargo (37) - 037/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Magcargo (37) - 037/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Manectric - 038/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Manectric - 038/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Masquerain - 039/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Masquerain - 039/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Metang - 040/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Metang - 040/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Minun - 041/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Minun - 041/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Nosepass - 042/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Nosepass - 042/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Nuzleaf - 043/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Nuzleaf - 043/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Plusle - 044/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Plusle - 044/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Shelgon - 045/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Shelgon - 045/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Silcoon - 046/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Silcoon - 046/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Solrock - 047/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Solrock - 047/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Starmie - 048/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Starmie - 048/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Swellow - 049/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Swellow - 049/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Vigoroth - 050/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Vigoroth - 050/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Weezing - 051/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Weezing - 051/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Bagon - 052/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Bagon - 052/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Baltoy - 053/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Baltoy - 053/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Barboach - 054/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Barboach - 054/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Beldum - 055/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Beldum - 055/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Carvanha - 056/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Carvanha - 056/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Corphish - 057/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Corphish - 057/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Duskull - 058/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Duskull - 058/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Electrike (59) - 059/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Electrike (59) - 059/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Electrike - 060/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Electrike - 060/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Goldeen - 061/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Goldeen - 061/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Koffing - 062/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Koffing - 062/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Lotad (63) - 063/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Lotad (63) - 063/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Magikarp - 064/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Magikarp - 064/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Makuhita - 065/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Makuhita - 065/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Natu - 066/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Natu - 066/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Nincada - 067/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Nincada - 067/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Numel - 068/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Numel - 068/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Phanpy - 069/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Phanpy - 069/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Poochyena - 070/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Poochyena - 070/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Seedot - 071/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Seedot - 071/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Shroomish - 072/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Shroomish - 072/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Slakoth - 073/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Slakoth - 073/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Slugma (74) - 074/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Slugma (74) - 074/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Slugma (75) - 075/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Slugma (75) - 075/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Spoink - 076/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Spoink - 076/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Staryu - 077/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Staryu - 077/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Surskit - 078/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Surskit - 078/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Swablu - 079/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Swablu - 079/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Taillow - 080/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Taillow - 080/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Wingull - 081/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Wingull - 081/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Wurmple - 082/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Wurmple - 082/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Zubat - 083/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Zubat - 083/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Balloon Berry - 084/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Balloon Berry - 084/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Crystal Shard - 085/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Crystal Shard - 085/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Energy Charge - 086/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Energy Charge - 086/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Lady Outing - 087/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Lady Outing - 087/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Master Ball - 088/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Master Ball - 088/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Meteor Falls - 089/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Meteor Falls - 089/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Professor Cozmo's Discovery - 090/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Professor Cozmo's Discovery - 090/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Space Center - 091/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Space Center - 091/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Strength Charm - 092/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Strength Charm - 092/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Boost Energy - 093/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Boost Energy - 093/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Heal Energy - 094/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Heal Energy - 094/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Scramble Energy - 095/107 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Scramble Energy - 095/107 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Crobat ex - 096/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Deoxys ex (Normal Forme) - 097/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Deoxys ex (Attack Forme) - 098/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Deoxys ex (Defense Forme) - 099/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Hariyama ex - 100/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Manectric ex - 101/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Rayquaza ex - 102/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Salamence ex - 103/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Sharpedo ex - 104/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Latias Gold Star - 105/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Latios Gold Star - 106/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Rayquaza Gold Star - 107/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Deoxys - Rocket's Raikou ex - 108/107 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Azumarill - 001/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Azumarill - 001/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Ampharos - 002/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Ampharos - 002/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Crobat - 003/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Crobat - 003/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Electrode - 004/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Electrode - 004/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Houndoom - 005/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Houndoom - 005/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Hypno - 006/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Hypno - 006/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Marowak - 007/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Marowak - 007/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Octillery - 008/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Octillery - 008/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Slowking - 009/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Slowking - 009/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Steelix - 010/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Steelix - 010/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Jumpluff - 011/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Jumpluff - 011/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Kingdra - 012/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Kingdra - 012/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Piloswine - 013/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Piloswine - 013/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Togetic - 014/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Togetic - 014/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Dragonite - 015/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Dragonite - 015/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Muk - 016/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Muk - 016/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Raticate - 017/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Raticate - 017/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Sandslash - 018/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Sandslash - 018/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Tyranitar (19) - 019/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Tyranitar (19) - 019/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Tyranitar (20) - 020/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Tyranitar (20) - 020/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Delibird - 021/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Delibird - 021/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Furret - 022/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Furret - 022/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Ledian - 023/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Ledian - 023/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Magby - 024/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Magby - 024/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Misdreavus - 025/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Misdreavus - 025/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Quagsire - 026/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Quagsire - 026/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Qwilfish - 027/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Qwilfish - 027/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Yanma - 028/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Yanma - 028/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Arbok - 029/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Arbok - 029/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Ariados - 030/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Ariados - 030/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Dragonair (31) - 031/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Dragonair (31) - 031/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Dragonair (32) - 032/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Dragonair (32) - 032/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Flaaffy - 033/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Flaaffy - 033/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Golbat - 034/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Golbat - 034/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Golduck - 035/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Golduck - 035/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Gyarados - 036/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Gyarados - 036/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Houndoom (37) - 037/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Houndoom (37) - 037/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Magcargo - 038/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Magcargo - 038/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Magneton - 039/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Magneton - 039/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Pupitar (40) - 040/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Pupitar (40) - 040/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Pupitar (41) - 041/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Pupitar (41) - 041/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Weezing - 042/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Weezing - 042/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Heracross - 043/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Heracross - 043/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Magmar - 044/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Magmar - 044/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Mantine - 045/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Mantine - 045/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Meowth - 046/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Meowth - 046/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Wobbuffet - 047/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Wobbuffet - 047/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Seadra - 048/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Seadra - 048/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Skiploom - 049/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Skiploom - 049/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Togepi - 050/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Togepi - 050/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Cubone - 051/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Cubone - 051/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dratini (52) - 052/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dratini (52) - 052/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dratini (53) - 053/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dratini (53) - 053/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Drowzee - 054/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Drowzee - 054/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Ekans - 055/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Ekans - 055/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Grimer - 056/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Grimer - 056/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Hoppip - 057/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Hoppip - 057/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Horsea - 058/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Horsea - 058/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Houndour (59) - 059/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Houndour (59) - 059/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Houndour (60) - 060/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Houndour (60) - 060/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Koffing - 061/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Koffing - 061/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Larvitar (62) - 062/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Larvitar (62) - 062/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Larvitar (63) - 063/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Larvitar (63) - 063/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Ledyba - 064/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Ledyba - 064/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Magikarp - 065/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Magikarp - 065/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Magnemite - 066/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Magnemite - 066/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Mareep - 067/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Mareep - 067/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Marill - 068/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Marill - 068/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Onix - 069/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Onix - 069/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Psyduck - 070/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Psyduck - 070/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rattata (71) - 071/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rattata (71) - 071/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rattata (72) - 072/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rattata (72) - 072/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Remoraid - 073/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Remoraid - 073/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Sandshrew - 074/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Sandshrew - 074/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Sentret - 075/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Sentret - 075/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Slowpoke - 076/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Slowpoke - 076/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Slugma - 077/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Slugma - 077/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Spinarak - 078/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Spinarak - 078/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Swinub - 079/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Swinub - 079/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Voltorb - 080/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Voltorb - 080/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Wooper - 081/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Wooper - 081/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Zubat - 082/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Zubat - 082/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Copycat - 083/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Copycat - 083/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Pokemon Retriever - 084/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Pokemon Retriever - 084/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Pow! Hand Extension - 085/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Pow! Hand Extension - 085/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Admin. - 086/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Admin. - 086/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Hideout - 087/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Hideout - 087/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Mission - 088/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Mission - 088/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Poke Ball - 089/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Poke Ball - 089/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Tricky Gym - 090/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Tricky Gym - 090/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Surprise! Time Machine - 091/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Surprise! Time Machine - 091/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Swoop! Teleporter - 092/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Swoop! Teleporter - 092/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Venture Bomb - 093/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Venture Bomb - 093/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Metal Energy - 094/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Dark Metal Energy - 094/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - R Energy - 095/109 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - R Energy - 095/109 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Articuno ex - 096/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Entei ex - 097/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Hitmonchan ex - 098/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Mewtwo ex - 099/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Moltres ex - 100/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Scizor ex - 101/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Scyther ex - 102/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Sneasel ex - 103/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Snorlax ex - 104/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Suicune ex - 105/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Rocket's Zapdos ex - 106/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Mudkip Gold Star - 107/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Torchic Gold Star - 108/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Treecko Gold Star - 109/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Charmeleon - 110/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Rocket Returns - Here Comes Team Rocket! - 111/109 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Beedrill - 001/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Beedrill - 001/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Butterfree - 002/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Butterfree - 002/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Dewgong - 003/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Dewgong - 003/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Ditto - 004/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Ditto - 004/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Exeggutor - 005/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Exeggutor - 005/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Kangaskhan - 006/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Kangaskhan - 006/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Marowak - 007/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Marowak - 007/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Nidoking - 008/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Nidoking - 008/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Nidoqueen - 009/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Nidoqueen - 009/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Pidgeot - 010/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Pidgeot - 010/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Poliwrath - 011/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Poliwrath - 011/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Raichu - 012/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Raichu - 012/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Rapidash - 013/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Rapidash - 013/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Slowbro - 014/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Slowbro - 014/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Snorlax - 015/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Snorlax - 015/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Tauros - 016/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Tauros - 016/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Victreebel - 017/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Victreebel - 017/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Arcanine - 018/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Arcanine - 018/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Chansey - 019/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Chansey - 019/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Cloyster - 020/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Cloyster - 020/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Dodrio - 021/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Dodrio - 021/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Dugtrio - 022/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Dugtrio - 022/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Farfetch'd - 023/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Farfetch'd - 023/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Fearow - 024/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Fearow - 024/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Hypno - 025/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Hypno - 025/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Kingler - 026/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Kingler - 026/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Magneton - 027/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Magneton - 027/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Primeape - 028/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Primeape - 028/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Scyther - 029/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Scyther - 029/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Tangela - 030/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Tangela - 030/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Charmeleon - 031/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Charmeleon - 031/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Drowzee - 032/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Drowzee - 032/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Exeggcute - 033/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Exeggcute - 033/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Haunter - 034/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Haunter - 034/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Ivysaur - 035/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Ivysaur - 035/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Kakuna - 036/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Kakuna - 036/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Lickitung - 037/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Lickitung - 037/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Mankey - 038/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Mankey - 038/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Metapod - 039/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Metapod - 039/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Nidorina - 040/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Nidorina - 040/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Nidorino - 041/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Nidorino - 041/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Onix - 042/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Onix - 042/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Parasect - 043/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Parasect - 043/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Persian - 044/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Persian - 044/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Pidgeotto - 045/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Pidgeotto - 045/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Poliwhirl - 046/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Poliwhirl - 046/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Porygon - 047/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Porygon - 047/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Raticate - 048/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Raticate - 048/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Venomoth - 049/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Venomoth - 049/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Wartortle - 050/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Wartortle - 050/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Weepinbell - 051/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Weepinbell - 051/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Wigglytuff - 052/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Wigglytuff - 052/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Bellsprout - 053/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Bellsprout - 053/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Bulbasaur (54) - 054/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Bulbasaur (54) - 054/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Bulbasaur (55) - 055/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Bulbasaur (55) - 055/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Caterpie - 056/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Caterpie - 056/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Charmander (57) - 057/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Charmander (57) - 057/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Charmander (58) - 058/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Charmander (58) - 058/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Clefairy - 059/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Clefairy - 059/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Cubone - 060/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Cubone - 060/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Diglett - 061/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Diglett - 061/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Doduo - 062/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Doduo - 062/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Gastly - 063/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Gastly - 063/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Growlithe - 064/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Growlithe - 064/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Jigglypuff - 065/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Jigglypuff - 065/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Krabby - 066/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Krabby - 066/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Magikarp - 067/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Magikarp - 067/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Magnemite - 068/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Magnemite - 068/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Meowth - 069/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Meowth - 069/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Nidoran F - 070/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Nidoran F - 070/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Nidoran M - 071/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Nidoran M - 071/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Paras - 072/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Paras - 072/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Pidgey - 073/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Pidgey - 073/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Pikachu - 074/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Pikachu - 074/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Poliwag - 075/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Poliwag - 075/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Ponyta - 076/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Ponyta - 076/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Rattata - 077/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Rattata - 077/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Seel - 078/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Seel - 078/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Shellder - 079/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Shellder - 079/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Slowpoke - 080/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Slowpoke - 080/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Spearow - 081/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Spearow - 081/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Squirtle (82) - 082/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Squirtle (82) - 082/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Squirtle (83) - 083/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Squirtle (83) - 083/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Venonat - 084/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Venonat - 084/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Voltorb - 085/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Voltorb - 085/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Weedle - 086/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Weedle - 086/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Bill's Maintenance - 087/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Bill's Maintenance - 087/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Celio's Network - 088/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Celio's Network - 088/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Energy Removal 2 - 089/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Energy Removal 2 - 089/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Energy Switch - 090/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Energy Switch - 090/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - EXP.ALL - 091/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - EXP.ALL - 091/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Great Ball - 092/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Great Ball - 092/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Life Herb - 093/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Life Herb - 093/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Mt. Moon - 094/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Mt. Moon - 094/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Poke Ball - 095/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Poke Ball - 095/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - PokeDex (HANDY909) - 096/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - PokeDex (HANDY909) - 096/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Pokemon Reversal - 097/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Pokemon Reversal - 097/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Prof. Oak's Research - 098/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Prof. Oak's Research - 098/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Super Scoop Up - 099/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Super Scoop Up - 099/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - VS Seeker - 100/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - VS Seeker - 100/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Potion - 101/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Potion - 101/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Switch - 102/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Switch - 102/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Multi Energy - 103/112 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Multi Energy - 103/112 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Blastoise ex - 104/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Charizard ex - 105/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Clefable ex - 106/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Electrode ex - 107/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Gengar ex - 108/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Gyarados ex - 109/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Mr. Mime ex (110) - 110/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Mr. Mime ex (111) - 111/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Venusaur ex - 112/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Charmander (113 Secret Rare) - 113/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Articuno ex - 114/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Moltres ex - 115/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/FireRed & LeafGreen - Zapdos ex - 116/112 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Banette - 001/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Banette - 001/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Claydol - 002/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Claydol - 002/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Crobat - 003/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Crobat - 003/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Dark Celebi - 004/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Dark Celebi - 004/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Electrode - 005/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Electrode - 005/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Exploud - 006/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Exploud - 006/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Heracross - 007/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Heracross - 007/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Jirachi - 008/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Jirachi - 008/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Machamp - 009/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Machamp - 009/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Medicham (10) - 010/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Medicham (10) - 010/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Metagross - 011/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Metagross - 011/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Milotic - 012/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Milotic - 012/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Pinsir - 013/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Pinsir - 013/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Shiftry - 014/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Shiftry - 014/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Walrein - 015/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Walrein - 015/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Bellossom - 016/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Bellossom - 016/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Chimecho - 017/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Chimecho - 017/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Gorebyss - 018/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Gorebyss - 018/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Huntail - 019/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Huntail - 019/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Masquerain - 020/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Masquerain - 020/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Metang (21) - 021/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Metang (21) - 021/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Ninetales - 022/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Ninetales - 022/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Rain Castform - 023/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Rain Castform - 023/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Relicanth - 024/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Relicanth - 024/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Snow-cloud Castform - 025/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Snow-cloud Castform - 025/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Sunny Castform - 026/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Sunny Castform - 026/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Tropius - 027/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Tropius - 027/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Beldum (28) - 028/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Beldum (28) - 028/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Beldum (29) - 029/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Beldum (29) - 029/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Castform - 030/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Castform - 030/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Claydol (31) - 031/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Claydol (31) - 031/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Corsola - 032/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Corsola - 032/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Dodrio - 033/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Dodrio - 033/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Glalie - 034/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Glalie - 034/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Gloom - 035/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Gloom - 035/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Golbat - 036/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Golbat - 036/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Igglybuff - 037/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Igglybuff - 037/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Lanturn - 038/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Lanturn - 038/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Loudred - 039/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Loudred - 039/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Luvdisc - 040/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Luvdisc - 040/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Machoke - 041/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Machoke - 041/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Medicham (42) - 042/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Medicham (42) - 042/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Metang (43) - 043/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Metang (43) - 043/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Metang (44) - 044/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Metang (44) - 044/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Nuzleaf - 045/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Nuzleaf - 045/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Rhydon - 046/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Rhydon - 046/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Sealeo - 047/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Sealeo - 047/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Spinda - 048/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Spinda - 048/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Starmie - 049/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Starmie - 049/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Swalot - 050/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Swalot - 050/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Tentacruel - 051/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Tentacruel - 051/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Baltoy (52) - 052/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Baltoy (52) - 052/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Baltoy (53) - 053/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Baltoy (53) - 053/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Beldum (54) - 054/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Beldum (54) - 054/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Chikorita - 055/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Chikorita - 055/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Chinchou (56) - 056/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Chinchou (56) - 056/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Chinchou (57) - 057/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Chinchou (57) - 057/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Clamperl - 058/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Clamperl - 058/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Cyndaquil - 059/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Cyndaquil - 059/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Doduo - 060/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Doduo - 060/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Feebas - 061/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Feebas - 061/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Gulpin - 062/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Gulpin - 062/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Jigglypuff - 063/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Jigglypuff - 063/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Machop - 064/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Machop - 064/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Meditite (65) - 065/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Meditite (65) - 065/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Meditite (66) - 066/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Meditite (66) - 066/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Minun - 067/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Minun - 067/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Oddish - 068/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Oddish - 068/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Plusle - 069/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Plusle - 069/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Rhyhorn - 070/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Rhyhorn - 070/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Seedot - 071/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Seedot - 071/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Shuppet - 072/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Shuppet - 072/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Snorunt - 073/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Snorunt - 073/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Spheal - 074/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Spheal - 074/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Staryu - 075/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Staryu - 075/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Surskit - 076/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Surskit - 076/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Tentacool - 077/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Tentacool - 077/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Togepi - 078/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Togepi - 078/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Totodile - 079/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Totodile - 079/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Voltorb - 080/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Voltorb - 080/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Vulpix - 081/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Vulpix - 081/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Whismur - 082/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Whismur - 082/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Zubat - 083/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Zubat - 083/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Ancient Technical Machine [Ice] - 084/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Ancient Technical Machine [Ice] - 084/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Ancient Technical Machine [Rock] - 085/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Ancient Technical Machine [Rock] - 085/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Ancient Technical Machine [Steel] - 086/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Ancient Technical Machine [Steel] - 086/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Ancient Tomb - 087/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Ancient Tomb - 087/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Desert Ruins - 088/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Desert Ruins - 088/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Island Cave - 089/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Island Cave - 089/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Life Herb - 090/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Life Herb - 090/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Magnetic Storm - 091/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Magnetic Storm - 091/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Steven's Advice - 092/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Steven's Advice - 092/101 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Groudon ex - 093/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Kyogre ex - 094/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Metagross ex - 095/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Ninetales ex - 096/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Regice ex - 097/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Regirock ex - 098/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Registeel ex - 099/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Vileplume ex - 100/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Wigglytuff ex - 101/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Groudon - 102/101 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Beldum - 029/101 (Gym Challenge) - 029/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Hidden Legends - Island Cave - 2005 (Curran Hill) - 089/101 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Cacturne - 001/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Cacturne - 001/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Crawdaunt - 002/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Crawdaunt - 002/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Kyogre - 003/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Kyogre - 003/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Manectric - 004/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Manectric - 004/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Sharpedo - 005/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Sharpedo - 005/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Walrein - 006/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Walrein - 006/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Aggron - 007/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Aggron - 007/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Claydol - 008/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Claydol - 008/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Groudon - 009/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Groudon - 009/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Houndoom (10) - 010/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Houndoom (10) - 010/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Rhydon (11) - 011/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Rhydon (11) - 011/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Torkoal - 012/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Torkoal - 012/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Raichu - 013/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Raichu - 013/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Crawdaunt (14) - 014/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Crawdaunt (14) - 014/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Mightyena (15) - 015/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Mightyena (15) - 015/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Sealeo (16) - 016/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Sealeo (16) - 016/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Seviper - 017/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Seviper - 017/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Sharpedo (18) - 018/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Sharpedo (18) - 018/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Camerupt - 019/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Camerupt - 019/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Lairon (20) - 020/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Lairon (20) - 020/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Mightyena (21) - 021/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Mightyena (21) - 021/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Rhydon (22) - 022/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Rhydon (22) - 022/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Zangoose - 023/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Zangoose - 023/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Cacnea - 24/95 - 024/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Cacnea - 24/95 - 024/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Carvanha (25) - 025/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Carvanha (25) - 025/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Corphish (26) - 026/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Corphish (26) - 026/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Electrike (27) - 027/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Electrike (27) - 027/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Lanturn - 028/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Lanturn - 028/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Manectric (29) - 029/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Manectric (29) - 029/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Mightyena (30) - 030/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Mightyena (30) - 030/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Sealeo (31) - 031/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Sealeo (31) - 031/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Baltoy (32) - 032/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Baltoy (32) - 032/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Claydol (33) - 033/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Claydol (33) - 033/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Houndoom (34) - 034/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Houndoom (34) - 034/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Houndour (35) - 035/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Houndour (35) - 035/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Lairon (36) - 036/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Lairon (36) - 036/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Mightyena (37) - 037/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Mightyena (37) - 037/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Rhyhorn (38) - 038/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Rhyhorn (38) - 038/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Bulbasaur - 039/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Bulbasaur - 039/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Cubone - 040/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Cubone - 040/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Jigglypuff - 041/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Jigglypuff - 041/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Meowth - 042/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Meowth - 042/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Pikachu - 043/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Pikachu - 043/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Psyduck - 044/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Psyduck - 044/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Slowpoke - 045/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Slowpoke - 045/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Squirtle - 046/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Squirtle - 046/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Carvanha (47) - 047/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Carvanha (47) - 047/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Carvanha (48) - 048/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Carvanha (48) - 048/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Chinchou - 049/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Chinchou - 049/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Corphish (50) - 050/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Corphish (50) - 050/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Corphish (51) - 051/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Corphish (51) - 051/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Electrike (52) - 052/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Electrike (52) - 052/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Electrike (53) - 053/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Electrike (53) - 053/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Poochyena (54) - 054/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Poochyena (54) - 054/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Poochyena (55) - 055/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Poochyena (55) - 055/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Spheal (56) - 056/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Spheal (56) - 056/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Spheal (57) - 057/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua's Spheal (57) - 057/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Aron (58) - 058/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Aron (58) - 058/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Aron (59) - 059/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Aron (59) - 059/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Baltoy (60) - 060/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Baltoy (60) - 060/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Baltoy (61) - 061/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Baltoy (61) - 061/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Houndour (62) - 062/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Houndour (62) - 062/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Houndour (63) - 063/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Houndour (63) - 063/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Numel - 064/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Numel - 064/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Poochyena (65) - 065/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Poochyena (65) - 065/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Poochyena (66) - 066/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Poochyena (66) - 066/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Rhyhorn (67) - 067/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Rhyhorn (67) - 067/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Rhyhorn (68) - 068/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma's Rhyhorn (68) - 068/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua Schemer - 069/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua Schemer - 069/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma Schemer - 070/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma Schemer - 070/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Archie - 071/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Archie - 071/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Dual Ball - 072/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Dual Ball - 072/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Maxie - 073/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Maxie - 073/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Strength Charm - 074/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Strength Charm - 074/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua Ball - 075/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua Ball - 075/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua Belt - 076/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua Belt - 076/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua Conspirator - 077/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua Conspirator - 077/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua Hideout - 078/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua Hideout - 078/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua Technical Machine 01 - 079/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Aqua Technical Machine 01 - 079/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma Ball - 080/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma Ball - 080/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma Belt - 081/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma Belt - 081/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma Conspirator - 082/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma Conspirator - 082/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma Hideout - 083/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma Hideout - 083/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma Technical Machine 01 - 084/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Team Magma Technical Machine 01 - 084/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Warp Point - 085/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Warp Point - 085/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Aqua Energy - 086/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Aqua Energy - 086/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Magma Energy - 087/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Magma Energy - 087/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Double Rainbow Energy - 088/95 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Double Rainbow Energy - 088/95 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Blaziken ex - 089/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Cradily ex - 090/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Entei ex - 091/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Raikou ex - 092/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Sceptile ex - 093/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Suicune ex - 094/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Swampert ex - 095/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Absol - 096/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Team Magma vs Team Aqua - Jirachi - 097/95 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Absol - 001/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Absol - 001/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Altaria - 002/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Altaria - 002/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Crawdaunt - 003/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Crawdaunt - 003/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Flygon - 004/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Flygon - 004/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Golem - 005/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Golem - 005/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Grumpig - 006/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Grumpig - 006/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Minun - 007/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Minun - 007/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Plusle - 008/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Plusle - 008/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Roselia - 009/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Roselia - 009/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Salamence (10) - 010/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Salamence (10) - 010/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Shedinja - 011/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Shedinja - 011/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Torkoal - 012/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Torkoal - 012/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Crawdaunt (13) - 013/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Crawdaunt (13) - 013/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Dragonair - 014/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Dragonair - 014/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Flygon (15) - 015/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Flygon (15) - 015/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Girafarig - 016/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Girafarig - 016/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Magneton (17) - 017/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Magneton (17) - 017/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Ninjask (18) - 018/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Ninjask (18) - 018/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Salamence (19) - 019/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Salamence (19) - 019/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Shelgon (20) - 020/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Shelgon (20) - 020/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Skarmory - 021/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Skarmory - 021/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Vibrava (22) - 022/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Vibrava (22) - 022/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Bagon (23) - 023/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Bagon (23) - 023/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Camerupt - 024/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Camerupt - 024/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Combusken - 025/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Combusken - 025/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Dratini - 026/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Dratini - 026/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Flaaffy - 027/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Flaaffy - 027/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Forretress - 028/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Forretress - 028/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Graveler (29) - 029/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Graveler (29) - 029/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Graveler (30) - 030/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Graveler (30) - 030/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Grovyle - 031/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Grovyle - 031/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Gyarados - 032/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Gyarados - 032/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Horsea (33) - 033/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Horsea (33) - 033/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Houndoom - 034/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Houndoom - 034/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Magneton (35) - 035/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Magneton (35) - 035/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Marshtomp - 036/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Marshtomp - 036/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Meditite - 037/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Meditite - 037/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Ninjask (38) - 038/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Ninjask (38) - 038/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Seadra (39) - 039/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Seadra (39) - 039/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Seadra (40) - 040/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Seadra (40) - 040/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Shelgon (41) - 041/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Shelgon (41) - 041/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Shelgon (42) - 042/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Shelgon (42) - 042/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Shuppet - 043/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Shuppet - 043/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Snorunt - 044/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Snorunt - 044/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Swellow - 045/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Swellow - 045/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Vibrava (46) - 046/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Vibrava (46) - 046/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Vibrava (47) - 047/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Vibrava (47) - 047/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Whiscash - 048/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Whiscash - 048/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Bagon (49) - 049/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Bagon (49) - 049/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Bagon (50) - 050/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Bagon (50) - 050/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Barboach - 051/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Barboach - 051/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Corphish (52) - 052/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Corphish (52) - 052/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Corphish (53) - 053/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Corphish (53) - 053/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Corphish (54) - 054/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Corphish (54) - 054/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Geodude (55) - 055/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Geodude (55) - 055/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Geodude (56) - 056/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Geodude (56) - 056/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Grimer - 057/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Grimer - 057/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Horsea (58) - 058/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Horsea (58) - 058/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Houndour - 059/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Houndour - 059/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Magikarp - 060/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Magikarp - 060/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Magnemite (61) - 061/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Magnemite (61) - 061/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Magnemite (62) - 062/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Magnemite (62) - 062/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Magnemite (63) - 063/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Magnemite (63) - 063/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Mareep - 064/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Mareep - 064/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Mudkip - 065/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Mudkip - 065/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Nincada (66) - 066/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Nincada (66) - 066/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Nincada (67) - 067/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Nincada (67) - 067/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Nincada (68) - 068/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Nincada (68) - 068/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Numel (69) - 069/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Numel (69) - 069/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Numel - 070/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Numel - 070/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Pineco - 071/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Pineco - 071/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Slugma - 072/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Slugma - 072/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Spoink (73) - 073/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Spoink (73) - 073/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Spoink (74) - 074/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Spoink (74) - 074/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Swablu - 075/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Swablu - 075/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Taillow - 076/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Taillow - 076/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Torchic - 077/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Torchic - 077/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Trapinch (78) - 078/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Trapinch (78) - 078/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Trapinch (79) - 079/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Trapinch (79) - 079/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Treecko - 080/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Treecko - 080/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Wurmple - 081/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Wurmple - 081/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Balloon Berry - 082/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Balloon Berry - 082/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Buffer Piece - 083/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Buffer Piece - 083/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Energy Recycle System - 084/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Energy Recycle System - 084/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - High Pressure System - 085/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - High Pressure System - 085/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Low Pressure System - 086/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Low Pressure System - 086/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Mr. Briney's Compassion - 087/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Mr. Briney's Compassion - 087/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - TV Reporter - 088/97 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - TV Reporter - 088/97 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Ampharos ex - 089/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Dragonite ex - 090/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Golem ex - 091/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Kingdra ex - 092/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Latias ex - 093/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Latios ex - 094/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Magcargo ex - 095/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Muk ex - 096/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Rayquaza ex - 097/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Charmander - 098/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Charmeleon - 099/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Dragon - Charizard - 100/97 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Armaldo - 001/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Armaldo - 001/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Cacturne - 002/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Cacturne - 002/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Cradily - 003/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Cradily - 003/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Dusclops - 004/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Dusclops - 004/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Flareon - 005/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Flareon - 005/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Jolteon - 006/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Jolteon - 006/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Ludicolo - 007/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Ludicolo - 007/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Lunatone - 008/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Lunatone - 008/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Mawile - 009/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Mawile - 009/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Sableye - 010/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Sableye - 010/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Seviper - 011/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Seviper - 011/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Shiftry (12) - 012/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Shiftry (12) - 012/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Solrock - 013/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Solrock - 013/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Zangoose - 014/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Zangoose - 014/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Arcanine - 015/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Arcanine - 015/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Espeon - 016/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Espeon - 016/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Golduck - 017/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Golduck - 017/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Kecleon - 018/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Kecleon - 018/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Omastar - 019/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Omastar - 019/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Pichu - 020/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Pichu - 020/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Sandslash - 021/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Sandslash - 021/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Shiftry (22) - 022/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Shiftry (22) - 022/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Steelix - 023/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Steelix - 023/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Umbreon - 024/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Umbreon - 024/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Vaporeon - 025/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Vaporeon - 025/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Wobbuffet - 026/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Wobbuffet - 026/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Anorith (27) - 027/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Anorith (27) - 027/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Anorith (28) - 028/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Anorith (28) - 028/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Arbok - 029/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Arbok - 029/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Azumarill - 030/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Azumarill - 030/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Azurill - 031/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Azurill - 031/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Baltoy - 032/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Baltoy - 032/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Breloom - 033/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Breloom - 033/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Delcatty - 034/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Delcatty - 034/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Electabuzz - 035/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Electabuzz - 035/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Elekid - 036/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Elekid - 036/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Fearow - 037/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Fearow - 037/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Illumise - 038/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Illumise - 038/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Kabuto - 039/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Kabuto - 039/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Kirlia - 040/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Kirlia - 040/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Lairon - 041/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Lairon - 041/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Lileep (42) - 042/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Lileep (42) - 042/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Lileep (43) - 043/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Lileep (43) - 043/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Linoone - 044/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Linoone - 044/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Lombre (45) - 045/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Lombre (45) - 045/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Lombre (46) - 046/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Lombre (46) - 046/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Murkrow - 047/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Murkrow - 047/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Nuzleaf (48) - 048/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Nuzleaf (48) - 048/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Nuzleaf (49) - 049/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Nuzleaf (49) - 049/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Pelipper - 050/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Pelipper - 050/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Quilava - 051/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Quilava - 051/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Vigoroth - 052/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Vigoroth - 052/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Volbeat - 053/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Volbeat - 053/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Wynaut - 054/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Wynaut - 054/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Xatu - 055/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Xatu - 055/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Aron - 056/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Aron - 056/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Cacnea (57) - 057/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Cacnea (57) - 057/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Cacnea (58) - 058/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Cacnea (58) - 058/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Cyndaquil - 059/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Cyndaquil - 059/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Dunsparce - 060/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Dunsparce - 060/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Duskull (61) - 061/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Duskull (61) - 061/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Duskull (62) - 062/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Duskull (62) - 062/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Eevee - 063/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Eevee - 063/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Ekans - 064/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Ekans - 064/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Growlithe - 065/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Growlithe - 065/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Lotad (66) - 066/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Lotad (66) - 066/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Lotad (67) - 067/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Lotad (67) - 067/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Marill - 068/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Marill - 068/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Natu - 069/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Natu - 069/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Omanyte - 070/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Omanyte - 070/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Onix - 071/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Onix - 071/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Pikachu - 072/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Pikachu - 072/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Psyduck - 073/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Psyduck - 073/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Ralts - 074/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Ralts - 074/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Sandshrew - 075/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Sandshrew - 075/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Seedot (76) - 076/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Seedot (76) - 076/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Seedot (77) - 077/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Seedot (77) - 077/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Shroomish - 078/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Shroomish - 078/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Skitty - 079/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Skitty - 079/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Slakoth - 080/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Slakoth - 080/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Spearow - 081/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Spearow - 081/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Trapinch - 082/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Trapinch - 082/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Wailmer - 083/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Wailmer - 083/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Wingull - 084/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Wingull - 084/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Zigzagoon - 085/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Zigzagoon - 085/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Double Full Heal - 086/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Double Full Heal - 086/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Lanette's Net Search - 087/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Lanette's Net Search - 087/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Rare Candy - 088/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Rare Candy - 088/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Wally's Training - 089/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Wally's Training - 089/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Claw Fossil - 090/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Claw Fossil - 090/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Mysterious Fossil - 091/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Mysterious Fossil - 091/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Root Fossil - 092/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Root Fossil - 092/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Multi Energy - 093/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Multi Energy - 093/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Aerodactyl ex - 094/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Aggron ex - 095/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Gardevoir ex - 096/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Kabutops ex - 097/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Raichu ex - 098/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Typhlosion ex - 099/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Wailord ex - 100/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Multi Energy - 2004 (Chris Fulop) - 093/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sandstorm - Multi Energy - 2004 (Reed Weichler) - 093/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Parasect - 001/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Roserade - 002/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Charizard - 003/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Chandelure - 004/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Pikachu - 005/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Gengar - 006/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Banette - 007/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Hisuian Arcanine - 008/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Spiritomb - 009/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Snorlax - 010/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Castform - 011/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Orbeetle V - 012/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Orbeetle VMAX - 013/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Centiskorch V - 014/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Centiskorch VMAX - 015/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Pikachu V - 016/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Pikachu VMAX - 017/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Enamorus V - 018/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Gallade V - 019/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Crobat V - 020/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Eternatus V - 021/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Eternatus VMAX - 022/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Adventurer's Discovery - 023/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Boss's Orders - 024/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Cook - 025/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Kabu - 026/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Nessa - 027/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Opal - 028/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Pikachu VMAX (Secret) - 029/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin Trainer Gallery - Mew VMAX (Secret) - 030/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Oddish - 001/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Oddish - 001/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Gloom - 002/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Gloom - 002/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Vileplume - 003/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Vileplume - 003/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Paras - 004/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Paras - 004/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Parasect - 005/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Parasect - 005/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Wurmple - 006/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Wurmple - 006/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Silcoon - 007/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Silcoon - 007/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Beautifly - 008/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Beautifly - 008/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Cascoon - 009/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Cascoon - 009/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Dustox - 010/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Dustox - 010/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Seedot - 011/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Seedot - 011/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Nuzleaf - 012/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Nuzleaf - 012/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Shiftry - 013/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Shiftry - 013/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Roselia - 014/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Roselia - 014/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Roserade - 015/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Roserade - 015/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Phantump - 016/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Phantump - 016/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Trevenant - 017/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Trevenant - 017/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Blipbug - 018/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Blipbug - 018/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Dottler - 019/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Dottler - 019/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Orbeetle - 020/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Orbeetle - 020/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Slugma - 021/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Slugma - 021/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Magcargo - 022/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Magcargo - 022/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Torkoal - 023/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Torkoal - 023/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Litwick - 024/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Litwick - 024/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lampent - 025/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lampent - 025/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Chandelure - 026/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Chandelure - 026/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Delphox V - 027/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Litleo - 028/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Litleo - 028/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Pyroar - 029/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Pyroar - 029/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Poliwag - 030/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Poliwag - 030/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Poliwhirl - 031/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Poliwhirl - 031/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Politoed - 032/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Politoed - 032/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Seel - 033/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Seel - 033/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Dewgong - 034/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Dewgong - 034/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Horsea - 035/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Horsea - 035/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Seadra - 036/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Seadra - 036/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Kingdra - 037/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Kingdra - 037/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Luvdisc - 038/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Luvdisc - 038/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Shellos - 039/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Shellos - 039/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Finneon - 040/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Finneon - 040/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lumineon - 041/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lumineon - 041/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Snover - 042/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Snover - 042/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Abomasnow - 043/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Abomasnow - 043/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Basculin - 044/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Basculin - 044/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Basculegion - 045/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Basculegion - 045/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Ducklett - 046/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Ducklett - 046/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Swanna - 047/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Swanna - 047/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Kyurem V - 048/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Kyurem VMAX - 049/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Cramorant - 050/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Cramorant - 050/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Glastrier - 051/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Glastrier - 051/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Pikachu - 052/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Pikachu - 052/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Raichu - 053/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Raichu - 053/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Electrike - 054/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Electrike - 054/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Manectric - 055/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Manectric - 055/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Magnezone V - 056/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Magnezone VSTAR - 057/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Rotom V - 058/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Tynamo - 059/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Tynamo - 059/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Eelektrik - 060/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Eelektrik - 060/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Eelektross - 061/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Eelektross - 061/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Clefairy - 062/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Clefairy - 062/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Clefable - 063/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Clefable - 063/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Gastly - 064/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Gastly - 064/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Haunter - 065/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Haunter - 065/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Gengar - 066/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Gengar - 066/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Mr. Mime - 067/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Mr. Mime - 067/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Jynx - 068/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Jynx - 068/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Jynx - 068/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Radiant Gardevoir - 069/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Sableye - 070/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Sableye - 070/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Mawile - 071/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Mawile - 071/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Shuppet - 072/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Shuppet - 072/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Banette - 073/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Banette - 073/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Cresselia - 074/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Cresselia - 074/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Zorua - 075/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Zorua - 075/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Zoroark - 076/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Zoroark - 076/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Inkay - 077/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Inkay - 077/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Malamar - 078/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Malamar - 078/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Comfey - 079/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Comfey - 079/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Mimikyu - 080/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Mimikyu - 080/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Spectrier - 081/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Spectrier - 081/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Enamorus V - 082/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Growlithe - 083/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Growlithe - 083/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Arcanine - 084/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Arcanine - 084/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Poliwrath - 085/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Poliwrath - 085/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Machop - 086/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Machop - 086/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Machoke - 087/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Machoke - 087/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Machamp - 088/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Machamp - 088/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Rhyhorn - 089/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Rhyhorn - 089/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Rhydon - 090/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Rhydon - 090/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Rhyperior - 091/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Rhyperior - 091/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Aerodactyl V - 092/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Aerodactyl VSTAR - 093/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Sudowoodo - 094/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Sudowoodo - 094/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Gligar - 095/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Gligar - 095/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Gliscor - 096/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Gliscor - 096/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Makuhita - 097/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Makuhita - 097/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hariyama - 098/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hariyama - 098/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Meditite - 099/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Meditite - 099/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Medicham - 100/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Medicham - 100/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Relicanth - 101/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Relicanth - 101/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Gastrodon - 102/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Gastrodon - 102/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Mienfoo - 103/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Mienfoo - 103/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Mienshao - 104/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Mienshao - 104/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Landorus - 105/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Landorus - 105/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Binacle - 106/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Binacle - 106/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Barbaracle - 107/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Barbaracle - 107/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Carbink - 108/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Carbink - 108/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Rockruff - 109/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Rockruff - 109/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Falinks - 110/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Falinks - 110/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Stonjourner - 111/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Stonjourner - 111/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Spinarak - 112/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Spinarak - 112/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Ariados - 113/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Ariados - 113/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Murkrow - 114/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Murkrow - 114/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Honchkrow - 115/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Honchkrow - 115/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Seviper - 116/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Seviper - 116/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Spiritomb - 117/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Spiritomb - 117/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Drapion V - 118/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Drapion VSTAR - 119/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Darkrai - 120/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Darkrai - 120/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Inkay - 121/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Inkay - 121/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hoopa - 122/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hoopa - 122/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Radiant Hisuian Sneasler - 123/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Radiant Steelix - 124/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Bronzor - 125/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Bronzor - 125/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Bronzong - 126/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Bronzong - 126/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Galarian Stunfisk - 127/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Galarian Stunfisk - 127/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Magearna - 128/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Magearna - 128/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Galarian Perrserker V - 129/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Giratina V - 130/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Giratina VSTAR - 131/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Goomy - 132/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Goomy - 132/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Sliggoo - 133/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Sliggoo - 133/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Goodra - 134/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Goodra - 134/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Goodra V - 135/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Goodra VSTAR - 136/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Pidgeot V - 137/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lickitung - 138/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lickitung - 138/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lickilicky - 139/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lickilicky - 139/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Porygon - 140/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Porygon - 140/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Porygon2 - 141/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Porygon2 - 141/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Porygon-Z - 142/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Porygon-Z - 142/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Snorlax - 143/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Snorlax - 143/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Aipom - 144/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Aipom - 144/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Ambipom - 145/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Ambipom - 145/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Zoroark V - 146/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Zoroark VSTAR - 147/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Bouffalant - 148/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Bouffalant - 148/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Komala - 149/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Komala - 149/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Skwovet - 150/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Skwovet - 150/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Greedent - 151/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Greedent - 151/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Arc Phone - 152/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Arc Phone - 152/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Arezu - 153/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Arezu - 153/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Box of Disaster - 154/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Box of Disaster - 154/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Colress's Experiment - 155/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Colress's Experiment - 155/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Damage Pump - 156/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Damage Pump - 156/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Fantina - 157/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Fantina - 157/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Iscan - 158/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Iscan - 158/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lady - 159/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lady - 159/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lake Acuity - 160/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lake Acuity - 160/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lost City - 161/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lost City - 161/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lost Vacuum - 162/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lost Vacuum - 162/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Mirage Gate - 163/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Mirage Gate - 163/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Miss Fortune Sisters - 164/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Miss Fortune Sisters - 164/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Panic Mask - 165/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Panic Mask - 165/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Riley - 166/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Riley - 166/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Thorton - 167/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Thorton - 167/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Tool Box - 168/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Tool Box - 168/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Volo - 169/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Volo - 169/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Windup Arm - 170/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Windup Arm - 170/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Gift Energy - 171/196 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Gift Energy - 171/196 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Electrode V (Full Art) - 172/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Delphox V (Full Art) - 173/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Kyurem V (Full Art) - 174/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Magnezone V (Full Art) - 175/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Rotom V (Full Art) - 176/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Rotom V (Alternate Full Art) - 177/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Enamorus V (Full Art) - 178/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Aerodactyl V (Full Art) - 179/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Aerodactyl V (Alternate Full Art) - 180/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Gallade V (Full Art) - 181/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Drapion V (Full Art) - 182/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Galarian Perrserker V (Full Art) - 183/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Galarian Perrserker V (Alternate Full Art) - 184/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Giratina V (Full Art) - 185/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Giratina V (Alternate Full Art) - 186/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Goodra V (Full Art) - 187/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Pidgeot V (Full Art) - 188/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Arezu (Full Art) - 189/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Colress's Experiment (Full Art) - 190/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Fantina (Full Art) - 191/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Iscan (Full Art) - 192/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lady (Full Art) - 193/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Miss Fortune Sisters (Full Art) - 194/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Thorton (Full Art) - 195/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Volo (Full Art) - 196/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Kyurem VMAX (Secret) - 197/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Magnezone VSTAR (Secret) - 198/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Aerodactyl VSTAR (Secret) - 199/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Drapion VSTAR (Secret) - 200/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Giratina VSTAR (Secret) - 201/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Goodra VSTAR (Secret) - 202/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Zoroark VSTAR (Secret) - 203/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Arezu (Secret) - 204/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Colress's Experiment (Secret) - 205/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Fantina (Secret) - 206/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Iscan (Secret) - 207/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lady (Secret) - 208/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Miss Fortune Sisters (Secret) - 209/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Thorton (Secret) - 210/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Volo (Secret) - 211/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Giratina VSTAR (Secret) - 212/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Hisuian Zoroark VSTAR (Secret) - 213/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Box of Disaster (Secret) - 214/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Collapsed Stadium (Secret) - 215/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Dark Patch (Secret) - 216/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Lost Origin - Lost Vacuum (Secret) - 217/196 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pikachu (JP) - 001/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Sprigatito - 001/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Fuecoco - 002/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Quaxly - 003/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Mimikyu ex - 004/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Quaquaval - 005/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Quaquaval - 005/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pawmot - 006/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pawmot - 006/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Hawlucha - 007/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Hawlucha - 007/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Revavroom - 008/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Revavroom - 008/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Spidops - 009/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Espathra - 010/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Arcanine - 011/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Dondozo - 012/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Miraidon - 013/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Miraidon - 013/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Koraidon - 014/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Koraidon - 014/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Flaaffy - 015/103 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Ampharos ex - 016/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Lucario ex - 017/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Cyclizar ex - 018/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Baxcalibur - 019/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Baxcalibur - 019/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Tinkaton - 020/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Tinkaton - 020/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Murkrow - 021/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pelipper - 022/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Smoliv - 023/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Growlithe - 024/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Tinkatink - 025/103 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Tinkatink - 025/103 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Varoom - 026/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pikachu - 027/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pikachu - 027/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Miraidon ex - 028/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Koraidon ex - 029/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Chien-Pao ex - 030/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Tinkaton ex - 031/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Annihilape ex - 032/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Meowscarada ex - 033/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Skeledirge ex - 034/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Quaquaval ex - 035/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Palafin - 036/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Palafin - 036/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Cleffa - 037/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Cleffa - 037/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Togekiss - 038/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Mawile - 039/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pawmi - 040/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Paldean Wooper - 041/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Houndstone - 042/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Eevee - 043/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Charmander - 044/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Charmander - 044/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Paradise Resort - 045/103 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Bulbasaur - 046/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Charmander - 047/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Squirtle - 048/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Zapdos ex - 049/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Alakazam ex - 050/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Snorlax - 051/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Snorlax - 051/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Mewtwo - 052/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Mew ex - 053/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Charizard ex - 056/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Chi-Yu - 057/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Iron Bundle - 058/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Xatu - 059/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Aegislash - 060/103 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pineco - 061/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Sinistea - 062/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Cetitan - 063/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Arctibax - 064/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Scream Tail - 065/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Iron Bundle - 066/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pikachu with Grey Felt Hat - 085/103 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Detective Pikachu (JP) - 098/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Oddish - 102/103 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Squirtle - 007/103 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Arboliva - 023/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Armarouge - 041/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Dondozo - 061/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pawmot - 076/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Espeon (Best Buy Exclusive) - 086/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Tinkaton - 105/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Tinkaton - 105/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Umbreon - 130/103 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Lechonk (Pokemon Center Exclusive) - 155/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Grookey - 001/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Scorbunny - 002/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Sobble - 003/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Meowth V - 004/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Meowth VMAX - 005/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Rillaboom - 006/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Rillaboom - 006/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Frosmoth - 007/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Frosmoth - 007/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Perrserker - 008/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Perrserker - 008/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cinccino - 009/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cinccino - 009/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Gossifleur - 010/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Wooloo - 011/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko - 012/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Ponyta - 013/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Rillaboom V - 014/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cinderace V - 015/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Inteleon V - 016/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Toxtricity V - 017/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Zacian V - 018/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Zamazenta V - 019/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu - 020/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Polteageist V - 021/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Flapple - 022/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Luxray - 023/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Coalossal - 024/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Garbodor - 025/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Mantine - 026/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Noctowl - 027/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Duraludon - 028/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Rayquaza - 029/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Copperajah V - 030/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko - 031/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Snorlax - 032/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Zacian - 033/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Zamazenta - 034/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Decidueye - 035/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Decidueye - 035/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Arctozolt - 036/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Arctozolt - 036/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu Rescue Team DX 036/S-P (JP) - 036/308 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Hydreigon - 037/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Hydreigon - 037/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Kangaskhan - 038/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Kangaskhan - 038/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu - 039/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Hatenna - 040/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Flareon - 041/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Eevee - 042/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Sirfetch'd V - 043/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Eternatus V - 044/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Eternatus VMAX - 045/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Eldegoss - 046/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Drednaw - 047/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Centiskorch - 048/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Dubwool V - 049/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Charizard V - 050/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Lapras - 051/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Gengar - 052/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Machamp - 053/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Coalossal - 054/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Hatterene V - 055/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V - 056/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Grimmsnarl V - 057/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Alcremie - 058/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Obstagoon - 059/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Duraludon - 060/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu V - 061/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu VMAX - 062/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu V - 063/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Eternatus V - 064/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Eevee V - 065/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Charizard - 066/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Charizard - 066/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Donphan - 067/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Donphan - 067/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Snorlax - 068/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Snorlax - 068/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Lugia - 069/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Lugia - 069/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Grookey - 070/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Scorbunny - 071/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Vaporeon - 072/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Sobble - 073/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Special Delivery Pikachu - 074/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Special Delivery Charizard - 075/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Zacian V - 076/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Zamazenta V - 077/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Orbeetle V - 078/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Mr. Rime - 079/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Dedenne - 080/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Polteageist - 081/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Bunnelby - 082/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Alakazam V - 083/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Eldegoss V - 084/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Boltund V - 085/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cramorant V - 086/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Eevee VMAX - 087/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cherrim - 088/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Octillery - 089/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Houndoom - 090/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Bronzong - 091/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Charmander - 092/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Arrokuda - 093/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Jolteon - 094/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Eevee - 095/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Dragapult V - 096/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Dragapult VMAX - 097/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Crobat V - 098/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Crobat VMAX - 099/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Venusaur V - 100/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Blastoise V - 101/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Venusaur VMAX - 102/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Blastoise VMAX - 103/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Victini V - 104/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Gardevoir V - 105/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Single Strike Urshifu V - 106/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Rapid Strike Urshifu V - 107/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Empoleon V - 108/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Tyranitar V - 109/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Crobat V - 110/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Rapidash V - 111/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cinderace - 112/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cinderace - 112/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Inteleon - 113/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cresselia - 114/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cresselia - 114/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Passimian - 115/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Passimian - 115/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko - 116/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Phanpy - 117/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Eevee - 118/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Snorlax - 119/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Marnie - 120/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Marnie - 121/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Flaaffy - 122/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Articuno - 123/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Zapdos - 124/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Moltres - 125/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Slowpoke - 126/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Eevee - 127/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Eiscue - 128/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Umbreon - 129/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Ice Rider Calyrex V - 130/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Shadow Rider Calyrex V - 131/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Dragapult (Prime) - 132/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Lance's Charizard V - 133/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Dark Sylveon V - 134/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Zacian LV. X - 135/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Mimikyu (Delta Species) - 136/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Light Toxtricity - 137/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Hydreigon C lv.61 - 138/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu V-Union - 139/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu V-Union - 140/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu V-Union - 141/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu V-Union - 142/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Charizard - 143/308 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu V - 143/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Greninja Star - 144/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu Kanazawa Promo (JP) - 144/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu V - 145/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Poke Ball - 146/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu Kanazawa Promo (JP) - 147/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Rayquaza V - 147/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Noivern V - 148/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Flareon V - 149/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Vaporeon V - 150/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Jolteon V - 151/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Professor's Research - 152/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Dragonite V - 154/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Greninja V-UNION - 155/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Greninja V-UNION - 156/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Greninja V-UNION - 157/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Greninja V-UNION - 158/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Mewtwo V-UNION - 159/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Mewtwo V-UNION - 160/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Mewtwo V-UNION - 161/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Mewtwo V-UNION - 162/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Zacian V-UNION - 163/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Zacian V-UNION - 164/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Zacian V-UNION - 165/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Zacian V-UNION - 166/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Professor Burnet - 167/308 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Professor Burnet - 167/308 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Oricorio - 168/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pyukumuku - 169/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Deoxys - 170/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Latias - 171/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Tepig - 172/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Blitzle - 173/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Espeon - 174/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Eevee - 175/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Hoopa V - 176/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Special Delivery Bidoof - 177/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Professor's Research - 178/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Professor's Research - 178/308 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Flareon V - 179/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Flareon VMAX - 180/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Vaporeon V - 181/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Vaporeon VMAX - 182/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Jolteon V - 183/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Jolteon VMAX - 184/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Moltres - 185/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Lucario - 186/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Liepard - 187/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Bibarel - 188/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Flapple - 189/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Espeon VMAX (JP) - 189/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Eevee - 190/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Leafeon - 191/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Glaceon - 192/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Obstagoon - 193/308 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Leafeon V - 194/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Leafeon VSTAR - 195/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Glaceon V - 196/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Glaceon VSTAR - 197/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu V - 198/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Lycanroc V - 199/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Corviknight V - 200/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Espeon V - 201/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Sylveon V - 202/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Umbreon V - 203/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Arceus V - 204/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Hisuian Basculegion - 205/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Wyrdeer - 206/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Hisuian Samurott - 207/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Magnezone - 208/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Toxel - 209/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Oricorio - 210/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Sylveon - 211/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Eevee - 212/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Lucario V - 213/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Lucario VSTAR - 214/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-Union - 215/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-Union - 216/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-Union - 217/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-Union - 218/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-Union [Set of 4] - 218/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Boltund V - 219/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Rowlet - 220/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cyndaquil - 221/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cyndaquil - 221/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Oshawott - 222/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Mewtwo V - 223/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Melmetal V - 224/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Alolan Exeggutor V - 225/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Spark - 226/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Blanche - 227/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Candela - 228/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Mewtwo V - 229/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Radiant Eevee - 230/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Bulbasaur - 231/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Charmander - 232/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Piplup - 232/308 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Squirtle - 233/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu - 234/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Dragonite V - 235/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Dragonite VSTAR - 236/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Hisuian Typhlosion V - 237/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Hisuian Decidueye V - 238/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Hisuian Samurott V - 239/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Finneon - 240/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Gengar - 241/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Comfey - 242/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Machamp - 243/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Scorbunny - 244/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Croagunk - 245/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Weavile - 246/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Regigigas - 247/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Kleavor V (Full Art) - 248/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Kleavor VSTAR - 249/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Lumineon V - 250/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Boss's Orders (Full Art) - 251/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Infernape V - 252/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Origin Forme Palkia V - 253/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Origin Forme Palkia VSTAR - 254/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Origin Forme Dialga V - 255/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Origin Forme Dialga VSTAR - 256/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Rotom V - 257/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Gallade V - 258/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Giratina V - 259/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Charizard V - 260/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Charizard VMAX - 261/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Charizard VSTAR - 262/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Zeraora V - 263/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Zeraora VMAX - 264/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Zeraora VSTAR - 265/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Deoxys V - 266/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Deoxys VMAX - 267/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Deoxys VSTAR - 268/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Sunflora - 269/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Sunflora - 269/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Rapidash - 270/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Rapidash - 270/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Kirlia - 271/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Kirlia - 271/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Archeops - 272/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Archeops - 272/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Hisuian Basculin - 273/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cranidos - 274/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Manaphy - 275/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Togetic - 276/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Rillaboom - 277/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cinderace - 278/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Inteleon - 279/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Regieleki V - 280/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Regidrago V - 281/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Articuno - 282/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Zapdos - 283/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Moltres - 284/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu V - 285/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu VMAX - 286/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-Union - 287/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-Union - 288/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-Union - 289/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-Union - 290/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-Union (SWSH287-290) (Set of 4) - 290/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Lucario VSTAR - 291/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Zacian V (Shiny) - 292/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Zamazenta V (Shiny) - 293/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Hisuian Electrode V - 294/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Virizion V - 295/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Champions Festival - 296/308 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Hisuian Zoroark V - 297/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Hisuian Zoroark VSTAR - 298/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Unown V - 300/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Lugia V - 301/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Klara (Full Art) - 302/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Bulbasaur (Illustration Contest 2022) - 303/308 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Arcanine (Illustration Contest 2022) - 304/308 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Greninja (Illustration Contest 2022) - 305/308 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Arceus V - 306/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Arceus VSTAR - 307/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu (JP) - 323/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Weedle - 001/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Weedle - 001/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Kakuna - 002/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Kakuna - 002/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Beedrill - 003/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Beedrill - 003/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Ledyba - 004/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Ledyba - 004/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Ledian - 005/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Ledian - 005/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Heracross - 006/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Heracross - 006/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Celebi V - 007/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Celebi VMAX - 008/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Snover - 009/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Snover - 009/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Abomasnow - 010/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Abomasnow - 010/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Deerling - 011/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Deerling - 011/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Sawsbuck - 012/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Sawsbuck - 012/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Bounsweet - 013/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Bounsweet - 013/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Steenee - 014/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Steenee - 014/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Tsareena - 015/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Tsareena - 015/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Grookey - 016/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Grookey - 016/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Thwackey - 017/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Thwackey - 017/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Rillaboom - 018/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Rillaboom - 018/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Zarude - 019/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Zarude - 019/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Blaziken V - 020/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Blaziken VMAX - 021/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Castform Sunny Form - 022/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Castform Sunny Form - 022/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Larvesta - 023/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Larvesta - 023/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Volcarona - 024/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Volcarona - 024/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Volcanion V - 025/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Scorbunny - 026/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Scorbunny - 026/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Raboot - 027/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Raboot - 027/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Cinderace - 028/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Cinderace - 028/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Lapras - 029/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Lapras - 029/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Sneasel - 030/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Sneasel - 030/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Weavile - 031/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Weavile - 031/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Delibird - 032/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Delibird - 032/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Castform Rainy Form - 033/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Castform Rainy Form - 033/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Castform Snowy Form - 034/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Castform Snowy Form - 034/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Snorunt - 035/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Snorunt - 035/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Froslass - 036/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Froslass - 036/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Spheal - 037/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Spheal - 037/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Sealeo - 038/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Sealeo - 038/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Walrein - 039/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Walrein - 039/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Tapu Fini - 040/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Tapu Fini - 040/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Sobble - 041/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Sobble - 041/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Drizzile - 042/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Drizzile - 042/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Inteleon - 043/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Inteleon - 043/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Rapid Strike Urshifu - 044/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Rapid Strike Urshifu - 044/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Ice Rider Calyrex V - 045/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Ice Rider Calyrex VMAX - 046/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Mareep - 047/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Mareep - 047/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Flaaffy - 048/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Flaaffy - 048/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Ampharos - 049/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Ampharos - 049/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Blitzle - 050/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Blitzle - 050/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Zebstrika - 051/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Zebstrika - 051/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Thundurus - 052/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Thundurus - 052/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Zeraora V - 053/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Slowpoke - 054/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Slowpoke - 054/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Gastly - 055/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Gastly - 055/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Haunter - 056/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Haunter - 056/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Gengar - 057/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Gengar - 057/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Articuno V - 058/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Ralts - 059/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Ralts - 059/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Kirlia - 060/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Kirlia - 060/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Gardevoir - 061/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Gardevoir - 061/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Shuppet - 062/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Shuppet - 062/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Banette - 063/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Banette - 063/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Cresselia - 064/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Cresselia - 064/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Golett - 065/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Golett - 065/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Golurk - 066/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Golurk - 066/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Swirlix - 067/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Swirlix - 067/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Slurpuff - 068/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Slurpuff - 068/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Inkay - 069/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Inkay - 069/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Malamar - 070/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Malamar - 070/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Hatenna - 071/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Hatenna - 071/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Hattrem - 072/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Hattrem - 072/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Hatterene - 073/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Hatterene - 073/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Shadow Rider Calyrex V - 074/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Shadow Rider Calyrex VMAX - 075/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Diglett - 076/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Diglett - 076/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Dugtrio - 077/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Dugtrio - 077/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Farfetch'd - 078/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Farfetch'd - 078/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Sirfetch'd - 079/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Sirfetch'd - 079/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Zapdos V - 080/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Gallade - 081/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Gallade - 081/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Yamask - 082/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Yamask - 082/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Runerigus - 083/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Runerigus - 083/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Crabrawler - 084/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Crabrawler - 084/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Crabominable - 085/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Crabominable - 085/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Rockruff - 086/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Rockruff - 086/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Lycanroc - 087/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Lycanroc - 087/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Passimian - 088/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Passimian - 088/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Sandaconda V - 089/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Sandaconda VMAX - 090/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Clobbopus - 091/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Clobbopus - 091/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Grapploct - 092/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Grapploct - 092/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Kubfu - 093/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Kubfu - 093/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Koffing - 094/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Koffing - 094/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Weezing - 095/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Weezing - 095/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Weezing - 096/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Weezing - 096/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Moltres V - 097/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Slowking - 098/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Slowking - 098/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Slowking V - 099/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Slowking VMAX - 100/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Qwilfish - 101/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Qwilfish - 101/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Seviper - 102/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Seviper - 102/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Spiritomb - 103/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Spiritomb - 103/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Liepard V - 104/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Venipede - 105/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Venipede - 105/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Whirlipede - 106/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Whirlipede - 106/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Scolipede - 107/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Scolipede - 107/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Single Strike Urshifu - 108/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Single Strike Urshifu - 108/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Aron - 109/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Aron - 109/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Lairon - 110/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Lairon - 110/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Aggron - 111/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Aggron - 111/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Metagross V - 112/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Metagross VMAX - 113/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Cobalion - 114/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Cobalion - 114/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Tauros - 115/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Tauros - 115/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Porygon - 116/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Porygon - 116/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Porygon2 - 117/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Porygon2 - 117/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Porygon-Z - 118/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Porygon-Z - 118/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Blissey V - 119/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Zangoose - 120/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Zangoose - 120/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Castform - 121/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Castform - 121/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Kecleon - 122/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Kecleon - 122/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Shaymin - 123/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Shaymin - 123/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Tornadus V - 124/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Tornadus VMAX - 125/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Furfrou - 126/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Furfrou - 126/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Skwovet - 127/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Skwovet - 127/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Greedent - 128/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Greedent - 128/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Agatha - 129/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Agatha - 129/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Avery - 130/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Avery - 130/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Brawly - 131/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Brawly - 131/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Caitlin - 132/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Caitlin - 132/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Crushing Gloves - 133/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Crushing Gloves - 133/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Doctor - 134/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Doctor - 134/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Dyna Tree Hill - 135/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Dyna Tree Hill - 135/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Echoing Horn - 136/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Echoing Horn - 136/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Expedition Uniform - 137/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Expedition Uniform - 137/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Fire-Resistant Gloves - 138/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Fire-Resistant Gloves - 138/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Flannery - 139/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Flannery - 139/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Fog Crystal - 140/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Fog Crystal - 140/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Chestplate - 141/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Chestplate - 141/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Honey - 142/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Honey - 142/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Justified Gloves - 143/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Justified Gloves - 143/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Karen's Conviction - 144/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Karen's Conviction - 144/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Klara - 145/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Klara - 145/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Melony - 146/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Melony - 146/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Old Cemetery - 147/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Old Cemetery - 147/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Path to the Peak - 148/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Path to the Peak - 148/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Peonia - 149/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Peonia - 149/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Peony - 150/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Peony - 150/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Rapid Strike Scroll of the Skies - 151/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Rapid Strike Scroll of the Skies - 151/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Rugged Helmet - 152/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Rugged Helmet - 152/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Siebold - 153/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Siebold - 153/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Single Strike Scroll of Piercing - 154/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Single Strike Scroll of Piercing - 154/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Weeding Gloves - 155/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Weeding Gloves - 155/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Welcoming Lantern - 156/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Welcoming Lantern - 156/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Impact Energy - 157/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Impact Energy - 157/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Lucky Energy - 158/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Lucky Energy - 158/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Spiral Energy - 159/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Spiral Energy - 159/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Celebi V (Full Art) - 160/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Blaziken V (Full Art) - 161/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Volcanion V (Full Art) - 162/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Ice Rider Calyrex V (Full Art) - 163/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Ice Rider Calyrex V (Alternate Full Art) - 164/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Zeraora V (Full Art) - 165/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Zeraora V (Alternate Full Art) - 166/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Rapidash V (Full Art) - 167/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Rapidash V (Alternate Full Art) - 168/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Articuno V (Full Art) - 169/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Articuno V (Alternate Full Art) - 170/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Shadow Rider Calyrex V (Full Art) - 171/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Shadow Rider Calyrex V (Alternate Full Art) - 172/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Zapdos V (Full Art) - 173/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Zapdos V (Alternate Full Art) - 174/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Sandaconda V (Full Art) - 175/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Moltres V (Full Art) - 176/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Moltres V (Alternate Full Art) - 177/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Slowking V (Full Art) - 178/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Slowking V (Alternate Full Art) - 179/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Liepard V (Full Art) - 180/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Metagross V (Full Art) - 181/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Blissey V (Full Art) - 182/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Blissey V (Alternate Full Art) - 183/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Tornadus V (Full Art) - 184/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Tornadus V (Alternate Full Art) - 185/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Agatha (Full Art) - 186/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Avery (Full Art) - 187/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Brawly (Full Art) - 188/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Caitlin (Full Art) - 189/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Doctor (Full Art) - 190/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Flannery (Full Art) - 191/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Honey (Full Art) - 192/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Karen's Conviction (Full Art) - 193/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Klara (Full Art) - 194/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Melony (Full Art) - 195/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Peonia (Full Art) - 196/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Peony (Full Art) - 197/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Siebold (Full Art) - 198/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Celebi VMAX (Secret) - 199/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Blaziken VMAX (Secret) - 200/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Blaziken VMAX (Alternate Art Secret) - 201/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Ice Rider Calyrex VMAX (Secret) - 202/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Ice Rider Calyrex VMAX (Alternate Art Secret) - 203/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Shadow Rider Calyrex VMAX (Secret) - 204/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Shadow Rider Calyrex VMAX (Alternate Art Secret) - 205/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Sandaconda VMAX (Secret) - 206/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Galarian Slowking VMAX (Secret) - 207/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Metagross VMAX (Secret) - 208/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Tornadus VMAX (Secret) - 209/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Agatha (Secret) - 210/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Avery (Secret) - 211/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Brawly (Secret) - 212/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Caitlin (Secret) - 213/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Doctor (Secret) - 214/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Flannery (Secret) - 215/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Karen's Conviction (Secret) - 216/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Klara (Secret) - 217/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Melony (Secret) - 218/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Peonia (Secret) - 219/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Peony (Secret) - 220/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Siebold (Secret) - 221/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Electrode (Secret) - 222/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Bronzong (Secret) - 223/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Snorlax (Secret) - 224/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Echoing Horn (Secret) - 225/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Fan of Waves (Secret) - 226/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Fog Crystal (Secret) - 227/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Rugged Helmet (Secret) - 228/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Urn of Vitality (Secret) - 229/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Welcoming Lantern (Secret) - 230/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Water Energy (Secret) - 231/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Psychic Energy (Secret) - 232/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Chilling Reign - Fighting Energy (Secret) - 233/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Abomasnow - 001/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Flapple - 002/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Kingdra - 003/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Frosmoth - 004/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Gardevoir - 005/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Wyrdeer - 006/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Falinks - 007/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Kleavor - 008/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Mightyena - 009/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Galarian Obstagoon - 010/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Bronzong - 011/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Hoothoot - 012/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Starmie V - 013/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Ice Rider Calyrex V - 014/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Ice Rider Calyrex VMAX - 015/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Galarian Articuno V - 016/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Shadow Rider Calyrex V - 017/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Shadow Rider Calyrex VMAX - 018/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Galarian Zapdos V - 019/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Galarian Moltres V - 020/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Zacian V - 021/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Zamazenta V - 022/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Garchomp V - 023/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Allister - 024/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Bea - 025/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Melony - 026/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Milo - 027/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Piers - 028/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Ice Rider Calyrex VMAX (Secret) - 029/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance Trainer Gallery - Shadow Rider Calyrex VMAX (Secret) - 030/30 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Beedrill V - 001/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Voltorb - 002/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Voltorb - 002/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Electrode - 003/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Electrode - 003/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Scyther (004) - 004/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Scyther (004) - 004/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Scyther (005) - 005/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Scyther (005) - 005/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Yanma - 006/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Yanma - 006/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Yanmega - 007/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Yanmega - 007/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Heracross - 008/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Heracross - 008/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Kricketot - 009/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Kricketot - 009/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Kricketune - 010/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Kricketune - 010/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Combee - 011/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Combee - 011/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Vespiquen - 012/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Vespiquen - 012/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Leafeon - 013/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Leafeon - 013/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Shaymin - 014/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Shaymin - 014/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Petilil - 015/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Petilil - 015/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Lilligant - 016/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Lilligant - 016/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Lilligant V - 017/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Lilligant VSTAR - 018/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Rowlet - 019/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Rowlet - 019/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Dartrix - 020/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Dartrix - 020/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Ponyta - 021/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Ponyta - 021/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Rapidash - 022/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Rapidash - 022/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Cyndaquil - 023/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Cyndaquil - 023/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Quilava - 024/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Quilava - 024/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Heatran V - 025/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Heatran VMAX - 026/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Radiant Heatran - 027/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Psyduck - 028/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Psyduck - 028/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Golduck - 029/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Golduck - 029/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Starmie V - 030/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Swinub - 031/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Swinub - 031/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Piloswine - 032/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Piloswine - 032/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Mamoswine - 033/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Mamoswine - 033/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Mantine - 034/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Mantine - 034/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Barboach - 035/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Barboach - 035/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Whiscash - 036/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Whiscash - 036/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Regice - 037/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Regice - 037/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Glaceon - 038/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Glaceon - 038/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Origin Forme Palkia V - 039/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Origin Forme Palkia VSTAR - 040/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Oshawott - 041/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Oshawott - 041/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Dewott - 042/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Dewott - 042/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Basculin - 043/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Basculin - 043/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Basculegion - 044/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Basculegion - 044/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Keldeo - 045/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Keldeo - 045/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Radiant Greninja - 046/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Bergmite - 047/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Bergmite - 047/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Avalugg - 048/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Avalugg - 048/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Galarian Mr. Rime V - 049/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Luxray V - 050/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Regieleki - 051/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Regieleki - 051/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Typhlosion - 052/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Typhlosion - 052/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Typhlosion V - 053/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Typhlosion VSTAR - 054/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Togepi - 055/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Togepi - 055/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Togetic - 056/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Togetic - 056/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Togekiss - 057/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Togekiss - 057/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Misdreavus - 058/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Misdreavus - 058/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Mismagius - 059/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Mismagius - 059/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Ralts - 060/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Ralts - 060/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Kirlia - 061/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Kirlia - 061/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Gallade - 062/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Gallade - 062/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Gallade - 062/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Drifloon - 063/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Drifloon - 063/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Drifblim - 064/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Drifblim - 064/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Uxie - 065/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Uxie - 065/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Mesprit - 066/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Mesprit - 066/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Azelf - 067/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Azelf - 067/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Diancie - 068/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Diancie - 068/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Wyrdeer - 069/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Wyrdeer - 069/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Growlithe - 070/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Growlithe - 070/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Arcanine - 071/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Arcanine - 071/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Machamp V - 072/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Machamp VMAX - 073/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Sudowoodo - 074/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Sudowoodo - 074/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Regirock - 075/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Regirock - 075/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Cranidos - 076/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Cranidos - 076/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Rampardos - 077/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Rampardos - 077/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Lucario V - 078/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hippopotas - 079/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hippopotas - 079/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hippowdon - 080/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hippowdon - 080/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Radiant Hawlucha - 081/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Decidueye - 082/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Decidueye - 082/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Decidueye V - 083/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Decidueye VSTAR - 084/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Kleavor (85) - 085/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Kleavor (85) - 085/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Kleavor (86) - 086/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Kleavor (86) - 086/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Kleavor V - 087/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Qwilfish (88) - 088/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Qwilfish (88) - 088/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Qwilfish (89) - 089/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Qwilfish (89) - 089/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Overqwil (90) - 090/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Overqwil (90) - 090/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Overqwil (91) - 091/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Overqwil (91) - 091/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Sneasel - 092/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Sneasel - 092/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Sneasler - 093/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Sneasler - 093/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Sneasler V - 094/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Poochyena - 095/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Poochyena - 095/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Mightyena - 096/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Mightyena - 096/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Absol - 097/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Absol - 097/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Darkrai V - 098/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Darkrai VSTAR - 099/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Samurott - 100/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Samurott - 100/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Samurott V - 101/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Samurott VSTAR - 102/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Nickit - 103/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Nickit - 103/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Thievul - 104/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Thievul - 104/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Magnemite - 105/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Magnemite - 105/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Magneton - 106/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Magneton - 106/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Magnezone - 107/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Magnezone - 107/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Registeel - 108/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Registeel - 108/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Shieldon - 109/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Shieldon - 109/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Bastiodon - 110/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Bastiodon - 110/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Bronzor - 111/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Bronzor - 111/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Bronzong - 112/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Bronzong - 112/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Origin Forme Dialga V - 113/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Origin Forme Dialga VSTAR - 114/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Pawniard - 115/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Pawniard - 115/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Bisharp - 116/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Bisharp - 116/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Garchomp V - 117/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Regidrago - 118/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Regidrago - 118/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Eevee - 119/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Eevee - 119/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hoothoot - 120/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hoothoot - 120/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Noctowl - 121/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Noctowl - 121/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Teddiursa - 122/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Teddiursa - 122/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Ursaring - 123/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Ursaring - 123/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Ursaluna - 124/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Ursaluna - 124/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Stantler - 125/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Stantler - 125/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Miltank - 126/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Miltank - 126/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Glameow - 127/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Glameow - 127/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Purugly - 128/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Purugly - 128/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Chatot - 129/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Chatot - 129/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Regigigas - 130/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Regigigas - 130/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Rufflet - 131/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Rufflet - 131/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Braviary - 132/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Braviary - 132/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Oranguru V - 133/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Wyrdeer V - 134/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Adaman - 135/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Adaman - 135/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Canceling Cologne - 136/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Canceling Cologne - 136/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Choy - 137/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Choy - 137/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Cyllene - 138/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Cyllene - 138/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Dark Patch - 139/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Dark Patch - 139/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Energy Loto - 140/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Energy Loto - 140/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Feather Ball - 141/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Feather Ball - 141/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Gapejaw Bog - 142/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Gapejaw Bog - 142/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Gardenia's Vigor - 143/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Gardenia's Vigor - 143/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Grant - 144/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Grant - 144/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Gutsy Pickaxe - 145/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Gutsy Pickaxe - 145/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Heavy Ball - 146/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Heavy Ball - 146/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Irida - 147/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Irida - 147/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Jubilife Village - 148/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Jubilife Village - 148/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Kamado - 149/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Kamado - 149/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Roxanne - 150/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Roxanne - 150/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Spicy Seasoned Curry - 151/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Spicy Seasoned Curry - 151/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Supereffective Glasses - 152/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Supereffective Glasses - 152/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Sweet Honey - 153/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Sweet Honey - 153/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Switch Cart - 154/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Switch Cart - 154/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Temple of Sinnoh - 155/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Temple of Sinnoh - 155/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Trekking Shoes - 156/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Trekking Shoes - 156/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Unidentified Fossil - 157/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Unidentified Fossil - 157/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Wait and See Turbo - 158/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Wait and See Turbo - 158/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Zisu - 159/189 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Zisu - 159/189 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Beedrill V (Full Art) - 160/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Beedrill V (Alternate Full Art) - 161/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Lilligant V (Full Art) - 162/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Lilligant V (Alternate Full Art) - 163/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Heatran V (Full Art) - 165/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Starmie V (Full Art) - 166/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Origin Forme Palkia V (Alternate Full Art) - 167/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Luxray V (Full Art) - 168/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Typhlosion V (Full Art) - 169/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Jirachi V (Full Art) - 170/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Machamp V (Full Art) - 171/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Machamp V (Alternate Full Art) - 172/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Decidueye V (Full Art) - 173/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Sneasler V (Full Art) - 174/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Sneasler V (Alternate Full Art) - 175/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Samurott V (Full Art) - 176/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Origin Forme Dialga V (Alternate Full Art) - 177/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Garchomp V (Full Art) - 178/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Oranguru V (Full Art) - 179/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Wyrdeer V (Full Art) - 180/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Adaman (Full Art) - 181/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Choy (Full Art) - 182/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Cyllene (Full Art) - 183/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Gardenia's Vigor (Full Art) - 184/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Grant (Full Art) - 185/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Irida (Full Art) - 186/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Kamado (Full Art) - 187/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Roxanne (Full Art) - 188/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Zisu (Full Art) - 189/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Lilligant VSTAR (Secret) - 190/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Heatran VMAX (Secret) - 191/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Origin Forme Palkia VSTAR (Secret) - 192/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Typhlosion VSTAR (Secret) - 193/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Machamp VMAX (Secret) - 194/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Decidueye VSTAR (Secret) - 195/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Kleavor VSTAR (Secret) - 196/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Samurott VSTAR (Secret) - 197/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Origin Forme Dialga VSTAR (Secret) - 198/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Adaman (Secret) - 199/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Choy (Secret) - 200/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Cyllene (Secret) - 201/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Gardenia's Vigor (Secret) - 202/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Grant (Secret) - 203/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Irida (Secret) - 204/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Kamado (Secret) - 205/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Roxanne (Secret) - 206/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Zisu (Secret) - 207/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Origin Forme Palkia VSTAR - 208/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Hisuian Samurott VSTAR (Secret) (209) - 209/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Origin Forme Dialga VSTAR (Secret) (210) - 210/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Choice Belt (Secret) - 211/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Jubilife Village (Secret) - 212/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Path to the Peak (Secret) - 213/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Temple of Sinnoh (Secret) - 214/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Trekking Shoes (Secret) - 215/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Double Turbo Energy (Secret) - 216/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Bulbasaur - 001/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Bulbasaur - 001/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Ivysaur - 002/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Ivysaur - 002/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Venusaur - 003/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Venusaur - 003/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Radiant Venusaur - 004/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Alolan Exeggutor V - 005/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Spinarak - 006/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Spinarak - 006/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Spinarak (Peelable Ditto) - 006/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Ariados - 007/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Ariados - 007/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Charmander - 008/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Charmander - 008/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Charmeleon - 009/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Charmeleon - 009/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Charizard - 010/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Charizard - 010/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Radiant Charizard - 011/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Moltres - 012/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Moltres - 012/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Numel - 013/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Numel - 013/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Numel (Peelable Ditto) - 013/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Camerupt - 014/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Camerupt - 014/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Squirtle - 015/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Squirtle - 015/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Wartortle - 016/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Wartortle - 016/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Blastoise - 017/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Blastoise - 017/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Radiant Blastoise - 018/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Slowpoke - 019/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Slowpoke - 019/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Slowbro - 020/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Slowbro - 020/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Magikarp - 021/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Magikarp - 021/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Gyarados - 022/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Gyarados - 022/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Lapras - 023/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Lapras - 023/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Articuno - 024/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Articuno - 024/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Wimpod - 025/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Wimpod - 025/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Golisopod - 026/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Golisopod - 026/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Pikachu (27) - 027/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Pikachu (27) - 027/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Pikachu - 028/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Pikachu - 028/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Zapdos - 029/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Zapdos - 029/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Mewtwo V - 030/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Mewtwo VSTAR - 031/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Natu - 032/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Natu - 032/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Xatu - 033/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Xatu - 033/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Lunatone - 034/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Lunatone - 034/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Sylveon - 035/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Sylveon - 035/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Onix - 036/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Onix - 036/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Larvitar - 037/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Larvitar - 037/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Pupitar - 038/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Pupitar - 038/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Solrock - 039/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Solrock - 039/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Conkeldurr V - 040/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Alolan Rattata - 041/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Alolan Rattata - 041/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Alolan Raticate - 042/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Alolan Raticate - 042/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Tyranitar - 043/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Tyranitar - 043/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Steelix - 044/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Steelix - 044/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Meltan - 045/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Meltan - 045/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Melmetal - 046/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Melmetal - 046/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Melmetal V - 047/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Melmetal VMAX - 048/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Dragonite V - 049/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Dragonite VSTAR - 050/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Chansey - 051/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Chansey - 051/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Blissey - 052/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Blissey - 052/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Ditto - 053/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Eevee - 054/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Eevee - 054/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Snorlax - 055/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Snorlax - 055/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Aipom - 056/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Aipom - 056/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Ambipom - 057/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Ambipom - 057/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Slaking V - 058/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Bidoof - 059/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Bidoof - 059/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Bidoof (Peelable Ditto) - 059/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Bibarel - 060/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Bibarel - 060/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Pidove - 061/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Pidove - 061/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Tranquill - 062/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Tranquill - 062/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Unfezant - 063/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Unfezant - 063/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Blanche - 064/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Blanche - 064/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Candela - 065/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Candela - 065/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Egg Incubator - 066/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Egg Incubator - 066/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Lure Module - 067/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Lure Module - 067/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - PokeStop - 068/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - PokeStop - 068/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Rare Candy - 069/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Rare Candy - 069/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Spark - 070/78 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Spark - 070/78 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Alolan Exeggutor V (Full Art) - 071/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Mewtwo V (Alternate Full Art) - 072/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Conkeldurr V (Full Art) - 073/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Conkeldurr V (Alternate Full Art) - 074/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Melmetal V (Full Art) - 075/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Dragonite V (Full Art) - 076/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Slaking V (Full Art) - 077/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Professor's Research (Full Art) - 078/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Mewtwo VSTAR (79) (Secret) - 079/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Melmetal VMAX (Secret) - 080/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Dragonite VSTAR (Secret) - 081/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Blanche (Secret) - 082/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Candela (Secret) - 083/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Professor's Research (Secret) - 084/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Spark (Secret) - 085/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Mewtwo VSTAR (86) (Secret) - 086/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Egg Incubator (Secret) - 087/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Pokemon Go - Lure Module (Secret) - 088/78 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Surskit - 001/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Surskit - 001/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Masquerain - 002/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Masquerain - 002/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Froslass ex - 003/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Pansage - 004/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Pansage - 004/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Simisage - 005/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Simisage - 005/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Dwebble - 006/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Dwebble - 006/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Crustle - 007/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Crustle - 007/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Bounsweet - 008/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Bounsweet - 008/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Steenee - 009/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Steenee - 009/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Blipbug - 010/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Blipbug - 010/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Dottler - 011/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Dottler - 011/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Orbeetle - 012/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Orbeetle - 012/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Nymble - 013/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Nymble - 013/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Nymble - 014/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Nymble - 014/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Toedscool - 015/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Toedscool - 015/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Toedscool - 016/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Toedscool - 016/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Toedscruel - 017/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Toedscruel - 017/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Wo-Chien - 018/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Wo-Chien - 018/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Magby - 019/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Magby - 019/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Pansear - 020/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Pansear - 020/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Simisear - 021/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Simisear - 021/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Volcanion - 022/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Volcanion - 022/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Fuecoco - 023/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Fuecoco - 023/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Crocalor - 024/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Crocalor - 024/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Charcadet - 025/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Charcadet - 025/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Charcadet - 026/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Charcadet - 026/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Armarouge ex - 027/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Iron Moth - 028/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Iron Moth - 028/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Chi-Yu - 029/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Chi-Yu - 029/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Horsea - 030/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Horsea - 030/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Seadra - 031/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Seadra - 031/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Kingdra - 032/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Kingdra - 032/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Remoraid - 033/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Remoraid - 033/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Octillery - 034/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Octillery - 034/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Feebas - 035/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Feebas - 035/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Milotic - 036/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Milotic - 036/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Snorunt - 037/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Snorunt - 037/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Garchomp ex - 038/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Mantyke - 039/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Mantyke - 039/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Palkia - 040/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Palkia - 040/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Panpour - 041/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Panpour - 041/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Simipour - 042/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Simipour - 042/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Vanillite - 043/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Vanillite - 043/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Vanillish - 044/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Vanillish - 044/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Vanilluxe - 045/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Vanilluxe - 045/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tsareena ex - 046/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Wimpod - 047/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Wimpod - 047/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Wimpod - 048/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Wimpod - 048/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Golisopod - 049/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Golisopod - 049/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Golisopod ex - 050/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Wiglett - 051/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Wiglett - 051/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Wiglett - 052/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Wiglett - 052/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Wugtrio - 053/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Wugtrio - 053/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Veluza - 054/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Veluza - 054/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Dondozo - 055/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Dondozo - 055/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Iron Bundle - 056/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Iron Bundle - 056/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Chien-Pao - 057/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Chien-Pao - 057/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Mewtwo ex - 058/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Elekid - 059/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Elekid - 059/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Plusle - 060/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Plusle - 060/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Minun - 061/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Minun - 061/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Blitzle - 062/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Blitzle - 062/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Zebstrika - 063/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Zebstrika - 063/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Joltik - 064/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Joltik - 064/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Galvantula - 065/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Galvantula - 065/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Zekrom - 066/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Zekrom - 066/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Oricorio - 067/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Oricorio - 067/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tapu Koko ex - 068/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Toxel - 069/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Toxel - 069/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Iron Hands ex - 070/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Natu - 071/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Natu - 071/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Xatu - 072/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Xatu - 072/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Latios - 073/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Latios - 073/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Deoxys - 074/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Deoxys - 074/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Yamask - 075/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Yamask - 075/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Cofagrigus ex - 076/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Pumpkaboo - 077/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Pumpkaboo - 077/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Gourgeist - 078/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Gourgeist - 078/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Flittle - 079/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Flittle - 079/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Flittle - 080/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Flittle - 080/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Espathra - 081/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Espathra - 081/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tinkatink - 082/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tinkatink - 082/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tinkatink - 083/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tinkatink - 083/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tinkatuff - 084/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tinkatuff - 084/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tinkaton - 085/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tinkaton - 085/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Scream Tail - 086/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Scream Tail - 086/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Gimmighoul - 087/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Gimmighoul - 087/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Gimmighoul - 088/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Gimmighoul - 088/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Iron Valiant ex - 089/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Onix - 090/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Onix - 090/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Gligar - 091/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Gligar - 091/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Gliscor - 092/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Gliscor - 092/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Groudon - 093/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Groudon - 093/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Gible - 094/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Gible - 094/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Gabite - 095/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Gabite - 095/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Mienfoo - 096/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Mienfoo - 096/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Mienshao - 097/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Mienshao - 097/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Hoopa ex - 098/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Minior - 099/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Minior - 099/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Toxtricity ex - 100/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Nacli - 101/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Nacli - 101/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Nacli - 102/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Nacli - 102/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Naclstack - 103/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Naclstack - 103/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Garganacl - 104/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Garganacl - 104/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Klawf - 105/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Klawf - 105/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Flamigo - 106/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Flamigo - 106/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Slither Wing - 107/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Slither Wing - 107/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Sandy Shocks ex - 108/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Ting-Lu - 109/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Ting-Lu - 109/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Zubat - 110/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Zubat - 110/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Golbat - 111/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Golbat - 111/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Crobat - 112/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Crobat - 112/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Absol - 113/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Absol - 113/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Purrloin - 114/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Purrloin - 114/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Liepard - 115/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Liepard - 115/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Trubbish - 116/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Trubbish - 116/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Garbodor - 117/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Garbodor - 117/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Yveltal - 118/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Yveltal - 118/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Nickit - 119/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Nickit - 119/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Thievul - 120/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Thievul - 120/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Morpeko - 121/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Morpeko - 121/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Lokix - 122/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Lokix - 122/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Brute Bonnet - 123/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Brute Bonnet - 123/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Roaring Moon ex - 124/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Steelix - 125/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Steelix - 125/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Jirachi - 126/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Jirachi - 126/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Ferroseed - 127/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Ferroseed - 127/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Ferrothorn - 128/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Ferrothorn - 128/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Durant - 129/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Durant - 129/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Honedge - 130/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Honedge - 130/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Honedge - 131/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Honedge - 131/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Doublade - 132/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Doublade - 132/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Doublade - 133/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Doublade - 133/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Aegislash - 134/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Aegislash - 134/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Aegislash ex - 135/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Zacian - 136/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Zacian - 136/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Skeledirge ex - 137/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Orthworm - 138/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Orthworm - 138/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Gholdengo ex - 139/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Altaria ex - 140/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tatsugiri - 141/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tatsugiri - 141/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Porygon - 142/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Porygon - 142/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Porygon2 - 143/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Porygon2 - 143/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Porygon-Z - 144/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Porygon-Z - 144/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Aipom - 145/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Aipom - 145/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Ambipom - 146/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Ambipom - 146/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Miltank - 147/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Miltank - 147/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Whismur - 148/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Whismur - 148/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Loudred - 149/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Loudred - 149/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Exploud - 150/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Exploud - 150/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Spinda - 151/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Spinda - 151/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Swablu - 152/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Swablu - 152/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tandemaus - 153/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tandemaus - 153/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tandemaus - 154/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tandemaus - 154/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Maushold ex - 155/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Bombirdier ex - 156/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Cyclizar - 157/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Cyclizar - 157/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Iron Jugulis - 158/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Iron Jugulis - 158/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Ancient Booster Energy Capsule - 159/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Ancient Booster Energy Capsule - 159/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Counter Catcher - 160/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Counter Catcher - 160/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Cursed Duster - 161/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Cursed Duster - 161/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Defiance Vest - 162/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Defiance Vest - 162/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Earthen Vessel - 163/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Earthen Vessel - 163/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Future Booster Energy Capsule - 164/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Future Booster Energy Capsule - 164/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Larry - 165/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Larry - 165/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Luxurious Cape - 166/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Luxurious Cape - 166/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Mela - 167/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Mela - 167/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Norman - 168/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Norman - 168/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Parasol Lady - 169/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Parasol Lady - 169/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Professor Sada's Vitality - 170/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Professor Sada's Vitality - 170/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Professor Turo's Scenario - 171/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Professor Turo's Scenario - 171/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Rika - 172/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Rika - 172/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Roark - 173/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Roark - 173/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Shauntal - 174/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Shauntal - 174/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Snorlax Doll - 175/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Snorlax Doll - 175/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Technical Machine: Blindside - 176/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Technical Machine: Blindside - 176/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Technical Machine: Devolution - 177/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Technical Machine: Devolution - 177/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Technical Machine: Evolution - 178/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Technical Machine: Evolution - 178/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Technical Machine: Turbo Energize - 179/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Technical Machine: Turbo Energize - 179/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Techno Radar - 180/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Techno Radar - 180/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tulip - 181/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tulip - 181/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Medical Energy - 182/182 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Medical Energy - 182/182 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Crustle - 183/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Dottler - 184/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Toedscruel - 185/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Magby - 186/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Iron Moth - 187/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Snorunt - 188/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Mantyke - 189/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Vanillish - 190/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Wimpod - 191/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Veluza - 192/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Plusle - 193/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Minun - 194/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Blitzle - 195/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Joltik - 196/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Espathra - 197/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Gimmighoul - 198/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Groudon - 199/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Mienshao - 200/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Minior - 201/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Garganacl - 202/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Slither Wing - 203/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Garbodor - 204/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Yveltal - 205/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Morpeko - 206/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Brute Bonnet - 207/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Steelix - 208/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Ferrothorn - 209/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Aegislash - 210/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Aipom - 211/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Loudred - 212/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Swablu - 213/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Porygon-Z - 214/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Cyclizar - 215/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Iron Jugulis - 216/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Froslass ex - 217/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Armarouge ex - 218/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Garchomp ex - 219/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tsareena ex - 220/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Golisopod ex - 221/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tapu Koko ex - 222/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Iron Hands ex - 223/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Cofagrigus ex - 224/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Iron Valiant ex - 225/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Hoopa ex - 226/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Toxtricity ex - 227/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Sandy Shocks ex - 228/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Roaring Moon ex - 229/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Aegislash ex - 230/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Gholdengo ex - 231/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Altaria ex - 232/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Maushold ex - 233/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Bombirdier ex - 234/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Larry - 235/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Mela - 236/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Norman - 237/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Parasol Lady - 238/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Professor Sada's Vitality - 239/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Professor Turo's Scenario - 240/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Rika - 241/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Roark - 242/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Shauntal - 243/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tulip - 244/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Garchomp ex - 245/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Golisopod ex - 246/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tapu Koko ex - 247/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Iron Hands ex - 248/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Iron Valiant ex - 249/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Sandy Shocks ex - 250/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Roaring Moon ex - 251/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Gholdengo ex - 252/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Altaria ex - 253/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Mela - 254/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Parasol Lady - 255/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Professor Sada's Vitality - 256/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Professor Turo's Scenario - 257/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Rika - 258/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Tulip - 259/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Garchomp ex - 260/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Iron Valiant ex - 261/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Roaring Moon ex - 262/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Beach Court - 263/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Counter Catcher - 264/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Luxurious Cape - 265/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Reversal Energy - 266/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Basic Grass Energy (Cosmos Holo) - 001/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Basic Fire Energy (Cosmos Holo) - 002/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Basic Water Energy (Cosmos Holo) - 003/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Basic Lightning Energy (Cosmos Holo) - 004/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Basic Psychic Energy (Cosmos Holo) - 005/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Basic Fighting Energy (Cosmos Holo) - 006/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Basic Darkness Energy (Cosmos Holo) - 007/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Basic Metal Energy (Cosmos Holo) - 008/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Bulbasaur - 001/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Bulbasaur - 001/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Ivysaur - 002/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Ivysaur - 002/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Venusaur ex - 003/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Charmander - 004/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Charmander - 004/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Charmeleon - 005/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Charmeleon - 005/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Charizard ex - 006/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Squirtle - 007/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Squirtle - 007/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Wartortle - 008/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Wartortle - 008/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Blastoise ex - 009/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Caterpie - 010/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Caterpie - 010/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Metapod - 011/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Metapod - 011/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Butterfree - 012/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Butterfree - 012/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Weedle - 013/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Weedle - 013/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Kakuna - 014/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Kakuna - 014/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Beedrill - 015/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Beedrill - 015/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Pidgey - 016/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Pidgey - 016/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Pidgeotto - 017/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Pidgeotto - 017/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Pidgeot - 018/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Pidgeot - 018/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Rattata - 019/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Rattata - 019/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Raticate - 020/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Raticate - 020/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Spearow - 021/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Spearow - 021/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Fearow - 022/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Fearow - 022/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Ekans - 023/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Ekans - 023/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Arbok ex - 024/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Pikachu - 025/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Pikachu - 025/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Raichu - 026/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Raichu - 026/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Sandshrew - 027/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Sandshrew - 027/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Sandslash - 028/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Sandslash - 028/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Nidoran F - 029/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Nidoran F - 029/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Nidorina - 030/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Nidorina - 030/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Nidoqueen - 031/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Nidoqueen - 031/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Nidoran M - 032/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Nidoran M - 032/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Nidorino - 033/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Nidorino - 033/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Nidoking - 034/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Nidoking - 034/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Clefairy - 035/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Clefairy - 035/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Clefable - 036/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Clefable - 036/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Vulpix - 037/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Vulpix - 037/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Ninetales ex - 038/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Jigglypuff - 039/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Jigglypuff - 039/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Wigglytuff ex - 040/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Zubat - 041/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Zubat - 041/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Golbat - 042/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Golbat - 042/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Oddish - 043/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Oddish - 043/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Gloom - 044/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Gloom - 044/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Vileplume - 045/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Vileplume - 045/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Paras - 046/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Paras - 046/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Parasect - 047/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Parasect - 047/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Venonat - 048/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Venonat - 048/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Venomoth - 049/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Venomoth - 049/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Diglett - 050/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Diglett - 050/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Dugtrio - 051/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Dugtrio - 051/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Meowth - 052/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Meowth - 052/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Persian - 053/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Persian - 053/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Psyduck - 054/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Psyduck - 054/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Golduck - 055/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Golduck - 055/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Mankey - 056/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Mankey - 056/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Primeape - 057/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Primeape - 057/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Growlithe - 058/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Growlithe - 058/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Arcanine - 059/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Arcanine - 059/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Poliwag - 060/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Poliwag - 060/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Poliwhirl - 061/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Poliwhirl - 061/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Poliwrath - 062/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Poliwrath - 062/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Abra - 063/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Abra - 063/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Kadabra - 064/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Kadabra - 064/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Alakazam ex - 065/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Machop - 066/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Machop - 066/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Machoke - 067/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Machoke - 067/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Machamp - 068/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Machamp - 068/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Bellsprout - 069/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Bellsprout - 069/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Weepinbell - 070/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Weepinbell - 070/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Victreebel - 071/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Victreebel - 071/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Tentacool - 072/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Tentacool - 072/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Tentacruel - 073/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Tentacruel - 073/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Geodude - 074/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Geodude - 074/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Graveler - 075/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Graveler - 075/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Golem ex - 076/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Ponyta - 077/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Ponyta - 077/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Rapidash - 078/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Rapidash - 078/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Slowpoke - 079/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Slowpoke - 079/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Slowbro - 080/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Slowbro - 080/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Magnemite - 081/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Magnemite - 081/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Magneton - 082/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Magneton - 082/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Farfetch'd - 083/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Farfetch'd - 083/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Doduo - 084/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Doduo - 084/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Dodrio - 085/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Dodrio - 085/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Seel - 086/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Seel - 086/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Dewgong - 087/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Dewgong - 087/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Grimer - 088/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Grimer - 088/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Muk - 089/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Muk - 089/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Shellder - 090/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Shellder - 090/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Cloyster - 091/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Cloyster - 091/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Gastly - 092/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Gastly - 092/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Haunter - 093/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Haunter - 093/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Gengar - 094/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Gengar - 094/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Onix - 095/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Onix - 095/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Drowzee - 096/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Drowzee - 096/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Hypno - 097/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Hypno - 097/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Krabby - 098/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Krabby - 098/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Kingler - 099/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Kingler - 099/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Voltorb - 100/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Voltorb - 100/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Electrode - 101/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Electrode - 101/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Exeggcute - 102/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Exeggcute - 102/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Exeggutor - 103/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Exeggutor - 103/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Cubone - 104/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Cubone - 104/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Marowak - 105/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Marowak - 105/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Hitmonlee - 106/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Hitmonlee - 106/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Hitmonchan - 107/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Hitmonchan - 107/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Lickitung - 108/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Lickitung - 108/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Koffing - 109/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Koffing - 109/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Weezing - 110/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Weezing - 110/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Rhyhorn - 111/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Rhyhorn - 111/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Rhydon - 112/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Rhydon - 112/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Chansey - 113/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Chansey - 113/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Tangela - 114/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Tangela - 114/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Kangaskhan ex - 115/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Horsea - 116/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Horsea - 116/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Seadra - 117/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Seadra - 117/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Goldeen - 118/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Goldeen - 118/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Seaking - 119/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Seaking - 119/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Staryu - 120/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Staryu - 120/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Starmie - 121/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Starmie - 121/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Mr. Mime - 122/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Mr. Mime - 122/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Scyther - 123/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Scyther - 123/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Jynx ex - 124/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Electabuzz - 125/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Electabuzz - 125/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Magmar - 126/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Magmar - 126/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Pinsir - 127/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Pinsir - 127/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Tauros - 128/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Tauros - 128/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Magikarp - 129/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Magikarp - 129/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Gyarados - 130/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Gyarados - 130/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Lapras - 131/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Lapras - 131/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Ditto - 132/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Ditto - 132/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Eevee - 133/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Eevee - 133/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Vaporeon - 134/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Vaporeon - 134/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Jolteon - 135/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Jolteon - 135/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Flareon - 136/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Flareon - 136/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Porygon - 137/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Porygon - 137/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Omanyte - 138/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Omanyte - 138/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Omastar - 139/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Omastar - 139/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Kabuto - 140/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Kabuto - 140/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Kabutops - 141/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Kabutops - 141/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Aerodactyl - 142/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Aerodactyl - 142/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Snorlax - 143/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Snorlax - 143/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Articuno - 144/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Articuno - 144/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Zapdos ex - 145/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Moltres - 146/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Moltres - 146/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Dratini - 147/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Dratini - 147/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Dragonair - 148/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Dragonair - 148/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Dragonite - 149/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Dragonite - 149/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Mewtwo - 150/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Mewtwo - 150/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Mew ex - 151/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Antique Dome Fossil - 152/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Antique Dome Fossil - 152/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Antique Helix Fossil - 153/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Antique Helix Fossil - 153/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Antique Old Amber - 154/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Antique Old Amber - 154/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Big Air Balloon - 155/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Big Air Balloon - 155/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Bill's Transfer - 156/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Bill's Transfer - 156/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Cycling Road - 157/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Cycling Road - 157/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Daisy's Help - 158/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Daisy's Help - 158/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Energy Sticker - 159/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Energy Sticker - 159/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Erika's Invitation - 160/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Erika's Invitation - 160/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Giovanni's Charisma - 161/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Giovanni's Charisma - 161/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Grabber - 162/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Grabber - 162/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Leftovers - 163/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Leftovers - 163/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Protective Goggles - 164/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Protective Goggles - 164/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Rigid Band - 165/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Rigid Band - 165/165 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Bulbasaur - 166/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Ivysaur - 167/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Charmander - 168/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Charmeleon - 169/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Squirtle - 170/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Wartortle - 171/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Caterpie - 172/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Pikachu - 173/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Nidoking - 174/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Psyduck - 175/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Poliwhirl - 176/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Machoke - 177/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Tangela - 178/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Mr. Mime - 179/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Omanyte - 180/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Dragonair - 181/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Venusaur ex - 182/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Charizard ex - 183/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Blastoise ex - 184/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Arbok ex - 185/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Ninetales ex - 186/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Wigglytuff ex - 187/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Alakazam ex - 188/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Golem ex - 189/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Kangaskhan ex - 190/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Jynx ex - 191/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Zapdos ex - 192/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Mew ex - 193/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Bill's Transfer - 194/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Daisy's Help - 195/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Erika's Invitation - 196/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Giovanni's Charisma - 197/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Venusaur ex - 198/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Charizard ex - 199/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Blastoise ex - 200/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Alakazam ex - 201/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Zapdos ex - 202/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Erika's Invitation - 203/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Giovanni's Charisma - 204/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Mew ex Metal Card - 205/165 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Mew ex - 205/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Switch - 206/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/SV: 151 - Basic Psychic Energy - 207/165 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pineco - 001/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pineco - 001/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Heracross - 002/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Heracross - 002/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Shroomish - 003/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Shroomish - 003/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Breloom - 004/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Breloom - 004/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Cacnea - 005/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Cacnea - 005/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Cacturne - 006/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Cacturne - 006/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Tropius - 007/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Tropius - 007/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Scatterbug - 008/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Scatterbug - 008/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Spewpa - 009/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Spewpa - 009/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Vivillon - 010/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Vivillon - 010/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Skiddo - 011/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Skiddo - 011/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Gogoat - 012/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Gogoat - 012/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Sprigatito - 013/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Sprigatito - 013/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Floragato - 014/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Floragato - 014/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Meowscarada - 015/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Meowscarada - 015/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Tarountula - 016/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Tarountula - 016/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Tarountula - 017/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Tarountula - 017/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Tarountula - 018/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Tarountula - 018/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Spidops ex - 019/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Smoliv - 020/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Smoliv - 020/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Smoliv - 021/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Smoliv - 021/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Dolliv - 022/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Dolliv - 022/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Arboliva - 023/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Arboliva - 023/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Toedscool - 024/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Toedscool - 024/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Toedscool - 025/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Toedscool - 025/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Toedscruel - 026/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Toedscruel - 026/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Capsakid - 027/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Capsakid - 027/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Capsakid - 028/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Capsakid - 028/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Scovillain - 029/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Scovillain - 029/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Growlithe - 030/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Growlithe - 030/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Growlithe - 031/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Growlithe - 031/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Arcanine ex - 032/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Houndour - 033/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Houndour - 033/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Houndoom - 034/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Houndoom - 034/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Torkoal - 035/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Torkoal - 035/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Fuecoco - 036/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Fuecoco - 036/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Crocalor - 037/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Crocalor - 037/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Skeledirge - 038/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Skeledirge - 038/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Charcadet - 039/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Charcadet - 039/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Charcadet - 040/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Charcadet - 040/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Armarouge - 041/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Armarouge - 041/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Slowpoke - 042/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Slowpoke - 042/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Slowbro - 043/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Slowbro - 043/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Magikarp - 044/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Magikarp - 044/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Gyarados ex - 045/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Buizel - 046/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Buizel - 046/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Floatzel - 047/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Floatzel - 047/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Alomomola - 048/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Alomomola - 048/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Clauncher - 049/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Clauncher - 049/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Clawitzer - 050/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Clawitzer - 050/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Bruxish - 051/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Bruxish - 051/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Quaxly - 052/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Quaxly - 052/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Quaxwell - 053/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Quaxwell - 053/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Quaquaval - 054/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Quaquaval - 054/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Wiglett - 055/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Wiglett - 055/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Wiglett - 056/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Wiglett - 056/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Wugtrio - 057/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Wugtrio - 057/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Cetoddle - 058/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Cetoddle - 058/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Cetoddle - 059/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Cetoddle - 059/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Cetitan - 060/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Cetitan - 060/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Dondozo - 061/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Dondozo - 061/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Tatsugiri - 062/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Tatsugiri - 062/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Magnemite - 063/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Magnemite - 063/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Magneton - 064/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Magneton - 064/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Magnezone ex - 065/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Mareep - 066/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Mareep - 066/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Flaaffy - 067/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Flaaffy - 067/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pachirisu - 068/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pachirisu - 068/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Rotom - 069/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Rotom - 069/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Rotom - 070/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Rotom - 070/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Toxel - 071/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Toxel - 071/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Toxtricity - 072/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Toxtricity - 072/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pawmi - 073/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pawmi - 073/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pawmi - 074/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pawmi - 074/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pawmo - 075/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pawmo - 075/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pawmot - 076/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pawmot - 076/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Wattrel - 077/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Wattrel - 077/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Wattrel - 078/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Wattrel - 078/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Kilowattrel - 079/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Kilowattrel - 079/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Miraidon - 080/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Miraidon - 080/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Miraidon ex - 081/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Drowzee - 082/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Drowzee - 082/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Hypno - 083/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Hypno - 083/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Ralts - 084/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Ralts - 084/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Kirlia - 085/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Kirlia - 085/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Gardevoir ex - 086/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Shuppet - 087/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Shuppet - 087/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Banette ex - 088/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Drifloon - 089/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Drifloon - 089/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Drifblim - 090/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Drifblim - 090/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Flabebe - 091/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Flabebe - 091/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Floette - 092/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Floette - 092/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Florges - 093/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Florges - 093/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Dedenne - 094/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Dedenne - 094/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Dedenne - 095/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Dedenne - 095/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Klefki - 096/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Klefki - 096/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Fidough - 097/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Fidough - 097/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Fidough - 098/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Fidough - 098/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Dachsbun - 099/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Dachsbun - 099/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Flittle - 100/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Flittle - 100/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Flittle - 101/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Flittle - 101/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Flittle - 102/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Flittle - 102/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Espathra - 103/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Espathra - 103/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Greavard - 104/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Greavard - 104/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Greavard - 105/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Greavard - 105/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Houndstone - 106/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Houndstone - 106/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Mankey - 107/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Mankey - 107/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Primeape - 108/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Primeape - 108/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Annihilape - 109/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Annihilape - 109/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Meditite - 110/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Meditite - 110/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Medicham - 111/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Medicham - 111/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Riolu - 112/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Riolu - 112/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Riolu - 113/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Riolu - 113/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Lucario - 114/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Lucario - 114/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Sandile - 115/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Sandile - 115/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Krokorok - 116/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Krokorok - 116/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Krookodile - 117/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Krookodile - 117/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Hawlucha - 118/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Hawlucha - 118/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Silicobra - 119/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Silicobra - 119/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Sandaconda - 120/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Sandaconda - 120/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Stonjourner - 121/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Stonjourner - 121/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Klawf - 122/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Klawf - 122/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Great Tusk ex - 123/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Koraidon - 124/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Koraidon - 124/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Koraidon ex - 125/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Grimer - 126/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Grimer - 126/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Muk - 127/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Muk - 127/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Seviper - 128/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Seviper - 128/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Spiritomb - 129/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Spiritomb - 129/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Croagunk - 130/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Croagunk - 130/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Toxicroak ex - 131/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pawniard - 132/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pawniard - 132/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Bisharp - 133/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Bisharp - 133/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Kingambit - 134/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Kingambit - 134/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Maschiff - 135/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Maschiff - 135/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Maschiff - 136/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Maschiff - 136/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Mabosstiff - 137/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Mabosstiff - 137/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Bombirdier - 138/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Bombirdier - 138/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Forretress - 139/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Forretress - 139/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Varoom - 140/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Varoom - 140/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Varoom - 141/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Varoom - 141/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Revavroom - 142/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Revavroom - 142/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Iron Treads ex - 143/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Chansey - 144/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Chansey - 144/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Blissey - 145/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Blissey - 145/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Zangoose - 146/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Zangoose - 146/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Zangoose - 147/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Zangoose - 147/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Starly - 148/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Starly - 148/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Staravia - 149/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Staravia - 149/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Staraptor - 150/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Staraptor - 150/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Skwovet - 151/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Skwovet - 151/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Greedent - 152/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Greedent - 152/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Indeedee - 153/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Indeedee - 153/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Lechonk - 154/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Lechonk - 154/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Lechonk - 155/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Lechonk - 155/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Lechonk - 156/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Lechonk - 156/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Oinkologne - 157/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Oinkologne - 157/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Oinkologne ex - 158/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Tandemaus - 159/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Tandemaus - 159/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Tandemaus - 160/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Tandemaus - 160/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Maushold - 161/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Maushold - 161/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Squawkabilly - 162/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Squawkabilly - 162/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Cyclizar - 163/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Cyclizar - 163/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Cyclizar - 164/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Cyclizar - 164/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Flamigo - 165/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Flamigo - 165/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Arven - 166/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Arven - 166/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Beach Court - 167/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Beach Court - 167/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Crushing Hammer - 168/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Crushing Hammer - 168/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Defiance Band - 169/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Defiance Band - 169/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Electric Generator - 170/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Electric Generator - 170/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Energy Retrieval - 171/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Energy Retrieval - 171/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Energy Search - 172/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Energy Search - 172/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Energy Switch - 173/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Energy Switch - 173/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Exp. Share - 174/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Exp. Share - 174/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Jacq - 175/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Jacq - 175/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Judge - 176/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Judge - 176/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Katy - 177/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Katy - 177/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Mesagoza - 178/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Mesagoza - 178/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Miriam - 179/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Miriam - 179/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Nemona - 180/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Nemona - 180/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Nest Ball - 181/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Nest Ball - 181/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pal Pad - 182/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pal Pad - 182/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Penny - 183/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Penny - 183/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Picnic Basket - 184/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Picnic Basket - 184/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Poke Ball - 185/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Poke Ball - 185/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pokegear 3.0 - 186/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pokegear 3.0 - 186/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pokemon Catcher - 187/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pokemon Catcher - 187/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Potion - 188/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Potion - 188/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Professor's Research (Professor Sada) - 189/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Professor's Research (Professor Sada) - 189/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Professor's Research (Professor Turo) - 190/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Professor's Research (Professor Turo) - 190/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Rare Candy - 191/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Rare Candy - 191/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Rock Chestplate - 192/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Rock Chestplate - 192/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Rocky Helmet - 193/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Rocky Helmet - 193/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Switch - 194/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Switch - 194/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Team Star Grunt - 195/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Team Star Grunt - 195/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Ultra Ball - 196/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Ultra Ball - 196/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Vitality Band - 197/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Vitality Band - 197/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Youngster - 198/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Youngster - 198/198 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Tarountula - 199/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Dolliv - 200/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Toedscool - 201/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Scovillain - 202/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Armarouge - 203/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Slowpoke - 204/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Clauncher - 205/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Wiglett - 206/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Dondozo - 207/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pachirisu - 208/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Pawmot - 209/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Drowzee - 210/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Ralts - 211/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Kirlia - 212/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Fidough - 213/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Greavard - 214/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Riolu - 215/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Sandile - 216/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Klawf - 217/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Mabosstiff - 218/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Bombirdier - 219/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Kingambit - 220/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Starly - 221/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Skwovet - 222/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Spidops ex - 223/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Arcanine ex - 224/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Gyarados ex - 225/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Magnezone ex - 226/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Miraidon ex - 227/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Gardevoir ex - 228/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Banette ex - 229/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Great Tusk ex - 230/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Koraidon ex - 231/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Toxicroak ex - 232/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Iron Treads ex - 233/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Oinkologne ex - 234/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Arven - 235/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Jacq - 236/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Katy - 237/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Miriam - 238/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Penny - 239/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Professor's Research (professor Sada) - 240/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Professor's Research (Professor Turo) - 241/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Team Star Grunt - 242/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Spidops ex - 243/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Miraidon ex - 244/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Gardevoir ex - 245/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Great Tusk ex - 246/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Koraidon ex - 247/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Iron Treads ex - 248/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Arven - 249/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Jacq - 250/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Miriam - 251/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Penny - 252/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Miraidon ex - 253/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Koraidon ex - 254/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Nest Ball - 255/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Rare Candy - 256/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Lightning Energy - 257/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Fighting Energy - 258/198 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Oddish - 001/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Oddish - 001/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Gloom - 002/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Gloom - 002/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bellossom - 003/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bellossom - 003/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Scyther - 004/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Scyther - 004/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Shuckle - 005/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Shuckle - 005/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Surskit - 006/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Surskit - 006/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Masquerain - 007/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Masquerain - 007/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Combee - 008/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Combee - 008/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Foongus - 009/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Foongus - 009/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Amoonguss - 010/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Amoonguss - 010/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Phantump - 011/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Phantump - 011/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Trevenant - 012/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Trevenant - 012/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Rowlet - 013/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Rowlet - 013/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Dartrix - 014/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Dartrix - 014/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Decidueye ex - 015/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bounsweet - 016/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bounsweet - 016/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Steenee - 017/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Steenee - 017/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Tsareena - 018/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Tsareena - 018/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Smoliv - 019/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Smoliv - 019/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Dolliv - 020/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Dolliv - 020/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Arboliva - 021/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Arboliva - 021/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Toedscruel ex - 022/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Capsakid - 023/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Capsakid - 023/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Capsakid - 024/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Capsakid - 024/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Scovillain - 025/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Scovillain - 025/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Charmander - 026/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Charmander - 026/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Charmeleon - 027/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Charmeleon - 027/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Vulpix - 028/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Vulpix - 028/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Ninetales - 029/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Ninetales - 029/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Entei - 030/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Entei - 030/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Numel - 031/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Numel - 031/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Camerupt - 032/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Camerupt - 032/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Victini ex - 033/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Darumaka - 034/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Darumaka - 034/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Darmanitan - 035/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Darmanitan - 035/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Litwick - 036/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Litwick - 036/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Lampent - 037/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Lampent - 037/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Chandelure - 038/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Chandelure - 038/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Heatmor - 039/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Heatmor - 039/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Larvesta - 040/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Larvesta - 040/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Volcarona - 041/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Volcarona - 041/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Eiscue ex - 042/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Charcadet - 043/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Charcadet - 043/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Armarouge - 044/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Armarouge - 044/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Lapras - 045/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Lapras - 045/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Carvanha - 046/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Carvanha - 046/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Sharpedo - 047/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Sharpedo - 047/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Buizel - 048/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Buizel - 048/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Floatzel - 049/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Floatzel - 049/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Tympole - 050/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Tympole - 050/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Palpitoad - 051/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Palpitoad - 051/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Seismitoad - 052/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Seismitoad - 052/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Cubchoo - 053/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Cubchoo - 053/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Beartic - 054/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Beartic - 054/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Cryogonal - 055/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Cryogonal - 055/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Froakie - 056/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Froakie - 056/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Frogadier - 057/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Frogadier - 057/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Wiglett - 058/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Wiglett - 058/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Wugtrio - 059/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Wugtrio - 059/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Finizen 60/197 - 060/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Finizen 60/197 - 060/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Finizen 061/197 - 061/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Finizen 061/197 - 061/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Palafin - 062/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Palafin - 062/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Magnemite - 063/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Magnemite - 063/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Magneton - 064/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Magneton - 064/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Magnezone - 065/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Magnezone - 065/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Tyranitar ex - 066/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Tynamo - 067/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Tynamo - 067/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Eelektrik - 068/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Eelektrik - 068/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Eelektross - 069/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Eelektross - 069/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Thundurus - 070/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Thundurus - 070/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Toxel - 071/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Toxel - 071/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Toxtricity - 072/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Toxtricity - 072/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Pawmot ex - 073/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Tadbulb 074/197 - 074/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Tadbulb 074/197 - 074/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Tadbulb 075/197 - 075/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Tadbulb 075/197 - 075/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Tadbulb 076/197 - 076/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Tadbulb 076/197 - 076/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bellibolt 077/197 - 077/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bellibolt 077/197 - 077/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bellibolt 078/197 - 078/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bellibolt 078/197 - 078/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Miraidon ex - 079/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Cleffa - 080/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Cleffa - 080/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Clefairy - 081/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Clefairy - 081/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Clefable ex - 082/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Togepi - 083/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Togepi - 083/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Togetic - 084/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Togetic - 084/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Togekiss - 085/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Togekiss - 085/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Espeon - 086/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Espeon - 086/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Snubbull - 087/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Snubbull - 087/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Granbull - 088/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Granbull - 088/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Mawile - 089/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Mawile - 089/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Spoink - 090/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Spoink - 090/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Grumpig - 091/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Grumpig - 091/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Lunatone - 092/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Lunatone - 092/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Solrock - 093/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Solrock - 093/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Baltoy - 094/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Baltoy - 094/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Claydol - 095/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Claydol - 095/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Vespiquen ex - 096/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Sinistea - 097/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Sinistea - 097/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Polteageist - 098/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Polteageist - 098/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Greavard - 099/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Greavard - 099/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Greavard - 100/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Greavard - 100/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Houndstone - 101/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Houndstone - 101/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Houndstone ex - 102/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Diglett - 103/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Diglett - 103/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Dugtrio - 104/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Dugtrio - 104/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Larvitar - 105/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Larvitar - 105/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Pupitar - 106/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Pupitar - 106/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Nosepass - 107/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Nosepass - 107/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Barboach - 108/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Barboach - 108/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Whiscash - 109/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Whiscash - 109/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bonsly - 110/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bonsly - 110/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Drilbur - 111/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Drilbur - 111/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Stunfisk - 112/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Stunfisk - 112/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Diggersby - 113/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Diggersby - 113/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Crabrawler - 114/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Crabrawler - 114/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Crabominable - 115/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Crabominable - 115/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Rockruff - 116/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Rockruff - 116/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Lycanroc - 117/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Lycanroc - 117/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Toedscool - 118/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Toedscool - 118/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Toedscruel - 119/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Toedscruel - 119/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Klawf ex - 120/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Glimmet - 121/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Glimmet - 121/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Glimmet - 122/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Glimmet - 122/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Glimmora ex - 123/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Koraidon ex - 124/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Charizard ex - 125/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Paldean Wooper - 126/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Paldean Wooper - 126/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Paldean Wooper - 127/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Paldean Wooper - 127/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Paldean Clodsire - 128/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Paldean Clodsire - 128/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Paldean Clodsire - 129/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Paldean Clodsire - 129/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Umbreon - 130/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Umbreon - 130/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Houndour - 131/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Houndour - 131/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Houndour - 132/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Houndour - 132/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Houndoom - 133/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Houndoom - 133/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Houndoom ex - 134/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Absol ex - 135/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Darkrai - 136/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Darkrai - 136/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Inkay - 137/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Inkay - 137/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Malamar - 138/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Malamar - 138/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Salandit - 139/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Salandit - 139/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Salazzle - 140/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Salazzle - 140/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Scizor - 141/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Scizor - 141/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Skarmory - 142/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Skarmory - 142/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Mawile - 143/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Mawile - 143/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bronzor - 144/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bronzor - 144/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bronzong - 145/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bronzong - 145/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Probopass - 146/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Probopass - 146/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Excadrill - 147/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Excadrill - 147/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Pawniard - 148/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Pawniard - 148/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bisharp - 149/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bisharp - 149/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Kingambit - 150/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Kingambit - 150/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Togedemaru - 151/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Togedemaru - 151/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Meltan - 152/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Meltan - 152/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Melmetal ex - 153/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Varoom - 154/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Varoom - 154/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Varoom - 155/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Varoom - 155/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Revavroom ex - 156/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Dratini - 157/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Dratini - 157/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Dragonair - 158/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Dragonair - 158/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Dragonite ex - 159/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Altaria - 160/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Altaria - 160/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Drampa - 161/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Drampa - 161/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Pidgey - 162/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Pidgey - 162/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Pidgeotto - 163/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Pidgeotto - 163/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Pidgeot ex - 164/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Kangaskhan - 165/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Kangaskhan - 165/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Eevee - 166/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Eevee - 166/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Zigzagoon - 167/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Zigzagoon - 167/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Linoone - 168/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Linoone - 168/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Swablu - 169/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Swablu - 169/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Lillipup - 170/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Lillipup - 170/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Herdier - 171/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Herdier - 171/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Stoutland - 172/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Stoutland - 172/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Audino - 173/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Audino - 173/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bouffalant - 174/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bouffalant - 174/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bunnelby - 175/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bunnelby - 175/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Yungoos - 176/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Yungoos - 176/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Gumshoos - 177/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Gumshoos - 177/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Skwovet - 178/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Skwovet - 178/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Greedent ex - 179/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Lechonk - 180/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Lechonk - 180/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Lechonk - 181/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Lechonk - 181/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Lechonk - 182/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Lechonk - 182/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Oinkologne - 183/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Oinkologne - 183/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Oinkologne - 184/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Oinkologne - 184/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Flamigo - 185/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Flamigo - 185/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Arven - 186/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Arven - 186/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Brassius - 187/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Brassius - 187/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Geeta - 188/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Geeta - 188/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Letter of Encouragement - 189/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Letter of Encouragement - 189/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Ortega - 190/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Ortega - 190/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Patrol Cap - 191/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Patrol Cap - 191/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Pokemon League Headquarters - 192/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Pokemon League Headquarters - 192/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Poppy - 193/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Poppy - 193/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Ryme - 194/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Ryme - 194/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Team Star Grunt - 195/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Team Star Grunt - 195/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Town Store - 196/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Town Store - 196/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Vengeful Punch - 197/197 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Vengeful Punch - 197/197 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Gloom - 198/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Ninetales- 199/197 - 199/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Palafin - 200/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Bellibolt - 201/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Cleffa - 202/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Larvitar - 203/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Houndour - 204/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Scizor - 205/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Varoom - 206/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Pidgey - 207/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Pidgeotto - 208/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Lechonk - 209/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Eiscue ex - 210/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Tyranitar ex - 211/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Vespiquen ex - 212/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Glimmora ex - 213/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Absol ex - 214/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Charizard ex - 215/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Revavroom ex - 216/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Pidgeot ex - 217/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Geeta - 218/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Ortega - 219/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Poppy - 220/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Ryme - 221/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Eiscue ex - 222/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Charizard ex - 223/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Revavroom ex - 224/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Pidgeot ex - 225/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Geeta - 226/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Poppy - 227/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Charizard ex - 228/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Artazon - 229/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Obsidian Flames - Basic Fire Energy - 230/197 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Hoppip - 001/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Hoppip - 001/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Skiploom - 002/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Skiploom - 002/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Jumpluff - 003/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Jumpluff - 003/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pineco - 004/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pineco - 004/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Forretress ex - 005/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Heracross - 006/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Heracross - 006/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tropius - 007/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tropius - 007/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Combee - 008/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Combee - 008/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Vespiquen - 009/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Vespiquen - 009/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Snover - 010/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Snover - 010/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Abomasnow - 011/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Abomasnow - 011/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Sprigatito - 012/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Sprigatito - 012/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Sprigatito - 013/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Sprigatito - 013/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Floragato - 014/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Floragato - 014/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Meowscarada ex - 015/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tarountula - 016/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tarountula - 016/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tarountula - 017/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tarountula - 017/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Spidops - 018/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Spidops - 018/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Nymble - 019/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Nymble - 019/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Nymble - 020/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Nymble - 020/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Lokix - 021/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Lokix - 021/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Bramblin - 022/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Bramblin - 022/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Bramblin - 023/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Bramblin - 023/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Brambleghast - 024/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Brambleghast - 024/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Rellor - 025/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Rellor - 025/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Rellor - 026/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Rellor - 026/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Wo-Chien ex - 027/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Paldean Tauros - 028/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Paldean Tauros - 028/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Fletchinder - 029/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Fletchinder - 029/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Talonflame - 030/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Talonflame - 030/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Litleo - 031/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Litleo - 031/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pyroar - 032/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pyroar - 032/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Oricorio - 033/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Oricorio - 033/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Fuecoco - 034/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Fuecoco - 034/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Fuecoco - 035/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Fuecoco - 035/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Crocalor - 036/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Crocalor - 036/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Skeledirge ex - 037/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Charcadet - 038/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Charcadet - 038/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Charcadet - 039/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Charcadet - 039/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Chi-Yu ex - 040/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Paldean Tauros - 041/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Paldean Tauros - 041/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Magikarp - 042/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Magikarp - 042/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Gyarados - 043/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Gyarados - 043/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Marill - 044/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Marill - 044/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Azumarill - 045/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Azumarill - 045/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Delibird - 046/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Delibird - 046/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Luvdisc - 047/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Luvdisc - 047/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Eiscue - 048/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Eiscue - 048/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Quaxly - 049/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Quaxly - 049/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Quaxly - 050/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Quaxly - 050/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Quaxwell - 051/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Quaxwell - 051/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Quaquaval ex - 052/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Cetoddle - 053/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Cetoddle - 053/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Cetoddle - 054/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Cetoddle - 054/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Cetitan - 055/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Cetitan - 055/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Veluza - 056/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Veluza - 056/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Frigibax - 057/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Frigibax - 057/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Frigibax - 058/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Frigibax - 058/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Arctibax - 059/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Arctibax - 059/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Baxcalibur - 060/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Baxcalibur - 060/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pikachu - 062/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pikachu - 062/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pikachu ex - 063/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Raichu - 064/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Raichu - 064/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Magnemite - 065/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Magnemite - 065/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Voltorb - 066/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Voltorb - 066/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Electrode - 067/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Electrode - 067/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Shinx - 068/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Shinx - 068/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Shinx - 069/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Shinx - 069/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Luxio - 070/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Luxio - 070/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Luxray - 071/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Luxray - 071/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pincurchin - 072/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pincurchin - 072/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pincurchin - 073/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pincurchin - 073/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pawmi - 074/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pawmi - 074/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pawmo - 075/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pawmo - 075/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pawmot - 076/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pawmot - 076/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tadbulb - 077/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tadbulb - 077/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tadbulb - 078/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tadbulb - 078/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Bellibolt ex - 079/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Wattrel - 080/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Wattrel - 080/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Wattrel - 081/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Wattrel - 081/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Kilowattrel - 082/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Kilowattrel - 082/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Jigglypuff - 083/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Jigglypuff - 083/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Wigglytuff - 084/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Wigglytuff - 084/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Slowpoke - 085/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Slowpoke - 085/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Slowking ex - 086/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Misdreavus - 087/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Misdreavus - 087/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Mismagius - 088/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Mismagius - 088/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Spiritomb - 089/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Spiritomb - 089/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Gothita - 090/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Gothita - 090/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Gothorita - 091/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Gothorita - 091/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Gothitelle - 092/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Gothitelle - 092/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Dedenne ex - 093/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Oranguru - 094/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Oranguru - 094/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Sandygast - 095/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Sandygast - 095/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Palossand - 096/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Palossand - 096/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Mimikyu - 097/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Mimikyu - 097/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Ceruledge - 098/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Ceruledge - 098/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Rabsca - 099/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Rabsca - 099/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tinkatink - 100/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tinkatink - 100/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tinkatink - 101/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tinkatink - 101/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tinkatink - 102/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tinkatink - 102/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tinkatuff - 103/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tinkatuff - 103/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tinkatuff - 104/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tinkatuff - 104/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tinkaton - 105/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tinkaton - 105/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Mankey - 106/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Mankey - 106/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Primeape - 107/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Primeape - 107/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Paldean Tauros - 108/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Paldean Tauros - 108/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Sudowoodo - 109/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Sudowoodo - 109/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Larvitar - 110/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Larvitar - 110/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pupitar - 111/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pupitar - 111/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Makuhita - 112/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Makuhita - 112/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Hariyama - 113/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Hariyama - 113/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Croagunk - 114/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Croagunk - 114/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Toxicroak - 115/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Toxicroak - 115/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Rockruff - 116/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Rockruff - 116/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Lycanroc ex - 117/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Passimian - 118/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Passimian - 118/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Falinks - 119/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Falinks - 119/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Nacli - 120/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Nacli - 120/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Nacli - 121/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Nacli - 121/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Naclstack - 122/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Naclstack - 122/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Garganacl - 123/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Garganacl - 123/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Glimmet - 124/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Glimmet - 124/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Glimmet - 125/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Glimmet - 125/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Glimmora - 126/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Glimmora - 126/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Ting-Lu ex - 127/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Paldean Wooper - 128/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Paldean Wooper - 128/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Paldean Wooper - 129/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Paldean Wooper - 129/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Paldean Clodsire ex - 130/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Murkrow - 131/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Murkrow - 131/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Honchkrow - 132/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Honchkrow - 132/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Sneasel - 133/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Sneasel - 133/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Weavile - 134/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Weavile - 134/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tyranitar - 135/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tyranitar - 135/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Sableye - 136/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Sableye - 136/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Seviper - 137/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Seviper - 137/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Deino - 138/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Deino - 138/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Zweilous - 139/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Zweilous - 139/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Hydreigon - 140/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Hydreigon - 140/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Maschiff - 141/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Maschiff - 141/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Maschiff - 142/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Maschiff - 142/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Mabosstiff - 143/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Mabosstiff - 143/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Shroodle - 144/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Shroodle - 144/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Shroodle - 145/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Shroodle - 145/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Grafaiai - 146/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Grafaiai - 146/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Bombirdier - 147/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Bombirdier - 147/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Corviknight - 148/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Corviknight - 148/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Cufant - 149/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Cufant - 149/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Copperajah ex - 150/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Orthworm - 151/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Orthworm - 151/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Noibat - 152/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Noibat - 152/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Noivern ex - 153/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Girafarig - 154/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Girafarig - 154/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Farigiraf - 155/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Farigiraf - 155/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Dunsparce - 156/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Dunsparce - 156/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Dudunsparce - 157/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Dudunsparce - 157/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Wingull - 158/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Wingull - 158/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pelipper - 159/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pelipper - 159/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Slakoth - 160/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Slakoth - 160/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Vigoroth - 161/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Vigoroth - 161/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Slaking - 162/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Slaking - 162/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Fletchling - 163/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Fletchling - 163/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Rookidee - 164/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Rookidee - 164/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Corvisquire - 165/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Corvisquire - 165/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tandemaus - 166/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tandemaus - 166/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tandemaus - 167/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tandemaus - 167/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Maushold - 168/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Maushold - 168/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Squawkabilly ex - 169/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Flamigo - 170/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Flamigo - 170/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Artazon - 171/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Artazon - 171/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Boss's Orders - 172/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Boss's Orders - 172/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Bravery Charm - 173/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Bravery Charm - 173/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Calamitous Snowy Mountain - 174/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Calamitous Snowy Mountain - 174/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Calamitous Wasteland - 175/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Calamitous Wasteland - 175/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Choice Belt - 176/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Choice Belt - 176/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Clavell - 177/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Clavell - 177/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Delivery Drone - 178/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Delivery Drone - 178/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Dendra - 179/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Dendra - 179/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Falkner - 180/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Falkner - 180/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Fighting Au Lait - 181/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Fighting Au Lait - 181/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Giacomo - 182/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Giacomo - 182/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Great Ball - 183/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Great Ball - 183/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Grusha - 184/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Grusha - 184/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Iono - 185/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Iono - 185/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Practice Studio - 186/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Practice Studio - 186/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Saguaro - 187/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Saguaro - 187/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Super Rod - 188/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Super Rod - 188/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Superior Energy Retrieval - 189/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Superior Energy Retrieval - 189/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Jet Energy - 190/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Jet Energy - 190/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Luminous Energy - 191/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Luminous Energy - 191/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Reversal Energy - 192/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Reversal Energy - 192/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Therapeutic Energy - 193/193 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Therapeutic Energy - 193/193 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Heracross - 194/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tropius - 195/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Sprigatito - 196/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Floragato - 197/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Bramblin - 198/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Fletchinder - 199/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Pyroar - 200/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Fuecoco - 201/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Crocalor - 202/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Magikarp - 203/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Marill - 204/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Eiscue - 205/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Quaxly - 206/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Quaxwell - 207/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Frigibax - 208/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Arctibax - 209/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Baxcalibur - 210/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Raichu - 211/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Mismagius - 212/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Gothorita - 213/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Sandygast - 214/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Rabsca - 215/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tinkatink - 216/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tinkatuff - 217/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Paldean Tauros - 218/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Sudowoodo - 219/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Nacli - 220/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Paldean Wooper - 221/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tyranitar - 222/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Grafaiai - 223/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Orthworm - 224/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Rookidee - 225/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Maushold - 226/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Flamigo - 227/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Farigiraf - 228/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Dudunsparce - 229/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Forretress ex - 230/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Meowscarada ex - 231/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Wo-Chien ex - 232/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Skeledirge ex - 233/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Chi-Yu ex - 234/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Quaquaval ex - 235/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Chien-Pao ex - 236/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Bellibolt ex - 237/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Slowking ex - 238/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Dedenne ex - 239/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tinkaton ex - 240/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Lycanroc ex - 241/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Annihilape ex - 242/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Ting-Lu ex - 243/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Paldean Clodsire ex - 244/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Copperajah ex - 245/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Noivern ex - 246/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Squawkabilly ex - 247/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Boss's Orders - 248/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Clavell - 249/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Dendra - 250/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Falkner - 251/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Giacomo - 252/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Grusha - 253/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Iono - 254/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Saguaro - 255/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Meowscarada ex - 256/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Wo-Chien ex - 257/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Skeledirge ex - 258/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Chi-Yu ex - 259/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Quaquaval ex - 260/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Chien-Pao ex - 261/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Tinkaton ex - 262/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Ting-Lu ex - 263/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Squawkabilly ex - 264/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Boss's Orders - 265/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Dendra - 266/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Giacomo - 267/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Grusha - 268/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Iono - 269/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Saguaro - 270/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Meowscarada ex - 271/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Skeledirge ex - 272/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Quaquaval ex - 273/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Chien-Pao ex - 274/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Ting-Lu ex - 275/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Super Rod - 276/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Superior Energy Retrieval - 277/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Basic Grass Energy - 278/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Basic Water Energy - 279/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Murkrow - 021/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pelipper - 022/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Banette - 001/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Banette - 001/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Blastoise (Delta Species) - 002/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Blastoise (Delta Species) - 002/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Camerupt - 003/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Camerupt - 003/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Charizard (Delta Species) - 004/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Charizard (Delta Species) - 004/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Dugtrio - 005/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Dugtrio - 005/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Ludicolo (Delta Species) - 006/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Ludicolo (Delta Species) - 006/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Luvdisc - 007/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Luvdisc - 007/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Manectric - 008/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Manectric - 008/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Mawile - 009/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Mawile - 009/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Sableye - 010/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Sableye - 010/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Swalot - 011/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Swalot - 011/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Tauros - 012/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Tauros - 012/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Wigglytuff - 013/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Wigglytuff - 013/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Blastoise - 014/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Blastoise - 014/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Cacturne (Delta Species) - 015/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Cacturne (Delta Species) - 015/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Combusken (16) - 016/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Combusken (16) - 016/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Dusclops - 017/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Dusclops - 017/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Fearow (Delta Species) - 018/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Fearow (Delta Species) - 018/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Grovyle (Delta Species) - 019/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Grovyle (Delta Species) - 019/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Grumpig - 020/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Grumpig - 020/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Igglybuff - 021/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Igglybuff - 021/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Kingler (Delta Species) - 022/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Kingler (Delta Species) - 022/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Loudred - 023/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Loudred - 023/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Marshtomp (24) - 024/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Marshtomp (24) - 024/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Medicham - 025/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Medicham - 025/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Pelipper (Delta Species) - 026/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Pelipper (Delta Species) - 026/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Swampert - 027/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Swampert - 027/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Venusaur - 028/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Venusaur - 028/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Charmeleon - 029/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Charmeleon - 029/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Charmeleon (Delta Species) - 030/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Charmeleon (Delta Species) - 030/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Combusken (31) - 031/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Combusken (31) - 031/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Grovyle - 032/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Grovyle - 032/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Gulpin - 033/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Gulpin - 033/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Ivysaur (34) - 034/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Ivysaur (34) - 034/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Ivysaur (35) - 035/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Ivysaur (35) - 035/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Lairon - 036/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Lairon - 036/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Lombre - 037/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Lombre - 037/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Marshtomp (38) - 038/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Marshtomp (38) - 038/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Nuzleaf - 039/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Nuzleaf - 039/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Shuppet - 040/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Shuppet - 040/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Skitty - 041/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Skitty - 041/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Wartortle (42) - 042/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Wartortle (42) - 042/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Wartortle (43) - 043/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Wartortle (43) - 043/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Aron - 044/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Aron - 044/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Bulbasaur (45) - 045/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Bulbasaur (45) - 045/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Bulbasaur (46) - 046/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Bulbasaur (46) - 046/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Cacnea - 047/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Cacnea - 047/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Charmander - 048/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Charmander - 048/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Charmander (Delta Species) - 049/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Charmander (Delta Species) - 049/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Diglett - 050/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Diglett - 050/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Duskull - 051/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Duskull - 051/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Electrike - 052/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Electrike - 052/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Jigglypuff - 053/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Jigglypuff - 053/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Krabby - 054/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Krabby - 054/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Lotad - 055/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Lotad - 055/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Meditite - 056/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Meditite - 056/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Mudkip (57) - 057/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Mudkip (57) - 057/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Mudkip (58) - 058/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Mudkip (58) - 058/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Numel - 059/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Numel - 059/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Seedot - 060/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Seedot - 060/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Spearow - 061/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Spearow - 061/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Spoink - 062/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Spoink - 062/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Squirtle (63) - 063/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Squirtle (63) - 063/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Squirtle (64) - 064/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Squirtle (64) - 064/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Torchic (65) - 065/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Torchic (65) - 065/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Torchic (66) - 066/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Torchic (66) - 066/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Treecko - 067/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Treecko - 067/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Treecko (Delta Species) - 068/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Treecko (Delta Species) - 068/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Whismur - 069/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Whismur - 069/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Wingull - 070/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Wingull - 070/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Bill's Maintenance - 071/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Bill's Maintenance - 071/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Castaway - 072/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Castaway - 072/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Celio's Network - 073/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Celio's Network - 073/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Cessation Crystal - 074/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Cessation Crystal - 074/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Crystal Beach - 075/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Crystal Beach - 075/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Crystal Shard - 076/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Crystal Shard - 076/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Double Full Heal - 077/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Double Full Heal - 077/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Dual Ball - 078/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Dual Ball - 078/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Holon Circle - 079/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Holon Circle - 079/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Memory Berry - 080/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Memory Berry - 080/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Mysterious Shard - 081/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Mysterious Shard - 081/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Poke Ball - 082/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Poke Ball - 082/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Pokenav - 083/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Pokenav - 083/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Warp Point - 084/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Warp Point - 084/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Windstorm - 085/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Windstorm - 085/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Energy Search - 086/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Energy Search - 086/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Potion - 087/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Potion - 087/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Double Rainbow Energy - 088/100 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Double Rainbow Energy - 088/100 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Aggron ex - 089/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Blaziken ex - 090/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Delcatty ex - 091/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Exploud ex - 092/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Groudon ex - 093/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Jirachi ex - 094/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Kyogre ex - 095/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Sceptile ex (Delta Species) - 096/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Shiftry ex - 097/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Swampert ex - 098/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Alakazam Gold Star - 099/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Crystal Guardians - Celebi Gold Star - 100/100 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Tinkatink - 025/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Scream Tail - 065/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Iron Bundle - 066 (Pokemon Center Exclusive) - 066/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Basic Grass Energy - 001/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Basic Fire Energy - 002/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Basic Water Energy - 003/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Basic Lightning Energy - 004/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Basic Psychic Energy - 005/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Basic Fighting Energy - 006/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Basic Darkness Energy - 007/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Base Set - Basic Metal Energy - 008/198 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Exeggcute - 001/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Exeggcute - 001/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Exeggutor - 002/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Exeggutor - 002/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Shroomish - 003/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Shroomish - 003/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Breloom - 004/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Breloom - 004/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Tropius - 005/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Tropius - 005/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Turtwig - 006/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Turtwig - 006/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Grotle - 007/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Grotle - 007/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Torterra - 008/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Torterra - 008/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Burmy - 009/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Burmy - 009/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Wormadam (10) - 010/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Wormadam (10) - 010/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Mothim - 011/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Mothim - 011/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Cherubi - 012/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Cherubi - 012/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Shaymin V - 013/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Shaymin VSTAR - 014/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Karrablast - 015/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Karrablast - 015/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Zarude V - 016/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Charizard V - 017/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Charizard VSTAR - 018/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Magmar - 019/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Magmar - 019/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Magmortar - 020/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Magmortar - 020/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Moltres - 021/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Moltres - 021/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Entei V - 022/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Torkoal - 023/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Torkoal - 023/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Chimchar - 024/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Chimchar - 024/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Monferno - 025/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Monferno - 025/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Infernape - 026/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Infernape - 026/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Simisear V - 027/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Kingler V - 028/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Kingler VMAX - 029/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Staryu - 030/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Staryu - 030/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Lapras - 031/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Lapras - 031/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Corphish - 032/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Corphish - 032/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Crawdaunt - 033/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Crawdaunt - 033/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Snorunt - 034/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Snorunt - 034/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Piplup - 035/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Piplup - 035/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Prinplup - 036/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Prinplup - 036/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Empoleon - 037/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Empoleon - 037/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Buizel - 038/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Buizel - 038/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Floatzel - 039/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Floatzel - 039/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Lumineon V - 040/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Manaphy - 041/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Manaphy - 041/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Cubchoo - 042/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Cubchoo - 042/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Beartic - 043/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Beartic - 043/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Eiscue - 044/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Eiscue - 044/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Raichu V - 045/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Electabuzz - 046/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Electabuzz - 046/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Electivire - 047/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Electivire - 047/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Raikou V - 048/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Shinx - 049/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Shinx - 049/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Luxio - 050/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Luxio - 050/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Luxray - 051/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Luxray - 051/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Pachirisu - 052/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Pachirisu - 052/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Clefairy - 053/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Clefairy - 053/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Clefable - 054/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Clefable - 054/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Starmie - 055/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Starmie - 055/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Mewtwo - 056/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Mewtwo - 056/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Granbull V - 057/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Baltoy - 058/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Baltoy - 058/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Claydol - 059/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Claydol - 059/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Duskull - 060/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Duskull - 060/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Dusclops - 061/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Dusclops - 061/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Dusknoir - 062/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Dusknoir - 062/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Chimecho - 063/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Chimecho - 063/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Whimsicott V - 064/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Whimsicott VSTAR - 065/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Sigilyph - 066/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Sigilyph - 066/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Dedenne - 067/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Dedenne - 067/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Mimikyu V - 068/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Mimikyu VMAX - 069/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Milcery - 070/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Milcery - 070/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Alcremie - 071/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Alcremie - 071/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Hitmontop - 072/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Hitmontop - 072/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Nosepass - 073/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Nosepass - 073/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Trapinch - 074/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Trapinch - 074/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Vibrava - 075/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Vibrava - 075/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Flygon - 076/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Flygon - 076/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Wormadam (77) - 077/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Wormadam (77) - 077/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Riolu - 078/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Riolu - 078/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Lucario - 079/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Lucario - 079/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Throh - 080/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Throh - 080/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Sawk - 081/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Sawk - 081/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Golett - 082/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Golett - 082/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Golurk - 083/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Golurk - 083/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Grimer - 084/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Grimer - 084/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Muk - 085/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Muk - 085/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Sneasel - 086/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Sneasel - 086/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Weavile - 087/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Weavile - 087/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Honchkrow V - 088/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Spiritomb - 089/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Spiritomb - 089/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Purrloin - 090/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Purrloin - 090/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Liepard - 091/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Liepard - 091/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Impidimp - 092/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Impidimp - 092/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Morgrem - 093/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Morgrem - 093/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Grimmsnarl - 094/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Grimmsnarl - 094/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Morpeko V - 095/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Aggron V - 096/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Aggron VMAX - 097/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Wormadam (98) - 098/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Wormadam (98) - 098/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Probopass - 099/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Probopass - 099/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Heatran - 100/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Heatran - 100/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Escavalier - 101/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Escavalier - 101/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Klink - 102/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Klink - 102/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Klang - 103/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Klang - 103/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Klinklang - 104/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Klinklang - 104/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Zamazenta V - 105/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Flygon V - 106/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Gible - 107/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Gible - 107/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Gabite - 108/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Gabite - 108/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Garchomp - 109/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Garchomp - 109/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Axew - 110/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Axew - 110/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Fraxure - 111/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Fraxure - 111/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Haxorus - 112/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Haxorus - 112/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Druddigon - 113/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Druddigon - 113/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Dracovish V - 114/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Farfetch'd - 115/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Farfetch'd - 115/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Castform - 116/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Castform - 116/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Starly - 117/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Starly - 117/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Staravia - 118/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Staravia - 118/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Staraptor - 119/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Staraptor - 119/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Bidoof - 120/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Bidoof - 120/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Bibarel - 121/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Bibarel - 121/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Arceus V - 122/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Arceus VSTAR - 123/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Minccino - 124/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Minccino - 124/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Cinccino - 125/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Cinccino - 125/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Tornadus - 126/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Tornadus - 126/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Hawlucha - 127/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Hawlucha - 127/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Drampa V - 128/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Acerola's Premonition - 129/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Acerola's Premonition - 129/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Barry - 130/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Barry - 130/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Blunder Policy - 131/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Blunder Policy - 131/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Boss's Orders - 132/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Boss's Orders - 132/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Cafe Master - 133/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Cafe Master - 133/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Cheren's Care - 134/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Cheren's Care - 134/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Choice Belt - 135/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Choice Belt - 135/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Cleansing Gloves - 136/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Cleansing Gloves - 136/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Collapsed Stadium - 137/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Collapsed Stadium - 137/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Cynthia's Ambition - 138/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Cynthia's Ambition - 138/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Fresh Water Set - 139/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Fresh Water Set - 139/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Friends in Galar - 140/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Friends in Galar - 140/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Gloria - 141/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Gloria - 141/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Hunting Gloves - 142/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Hunting Gloves - 142/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Kindler - 143/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Kindler - 143/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Magma Basin - 144/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Magma Basin - 144/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Marnie's Pride - 145/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Marnie's Pride - 145/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Pot Helmet - 146/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Pot Helmet - 146/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Professor's Research - 147/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Professor's Research - 147/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Roseanne's Backup - 148/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Roseanne's Backup - 148/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Team Yell's Cheer - 149/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Team Yell's Cheer - 149/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Ultra Ball - 150/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Ultra Ball - 150/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Double Turbo Energy - 151/172 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Double Turbo Energy - 151/172 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Shaymin V (Full Art) - 152/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Charizard V (Full Art) - 153/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Charizard V (Alternate Full Art) - 154/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Lumineon V (Full Art) - 155/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Lumineon V (Alternate Full Art) - 156/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Pikachu V (Full Art) - 157/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Raichu V (Full Art) - 158/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Granbull V (Full Art) - 159/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Whimsicott V (Full Art) - 160/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Honchkrow V (Full Art) - 161/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Honchkrow V (Alternate Full Art) - 162/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Zamazenta V (Full Art) - 163/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Flygon V (Full Art) - 164/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Arceus V (Full Art) - 165/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Arceus V (Alternate Full Art) - 166/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Barry (Full Art) - 167/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Cheren's Care (Full Art) - 168/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Cynthia's Ambition (Full Art) - 169/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Kindler (Full Art) - 170/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Marnie's Pride (Full Art) - 171/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Roseanne's Backup (Full Art) - 172/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Shaymin VSTAR (Secret) - 173/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Charizard VSTAR (Secret) - 174/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Whimsicott VSTAR (Secret) - 175/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Arceus VSTAR (Secret) (176) - 176/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Cheren's Care (Secret) - 177/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Cynthia's Ambition (Secret) - 178/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Kindler (Secret) - 179/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Roseanne's Backup (Secret) - 180/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Galarian Articuno V (Secret) - 181/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Galarian Zapdos V (Secret) - 182/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Galarian Moltres V (Secret) - 183/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Arceus VSTAR (Secret) (184) - 184/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Magma Basin (Secret) - 185/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Brilliant Stars - Ultra Ball (Secret) - 186/172 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Iron Valiant ex - 068/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Kangaskhan ex - 055/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Pineco - 001/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Pineco - 001/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Forretress ex - 002/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Maractus - 003/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Maractus - 003/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Toedscool - 004/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Toedscool - 004/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Toedscruel ex - 005/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Espathra ex - 006/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Charmander - 007/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Charmander - 007/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Charmeleon - 008/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Charmeleon - 008/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Magmar - 009/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Magmar - 009/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Magmortar - 010/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Magmortar - 010/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Numel - 011/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Numel - 011/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Camerupt - 012/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Camerupt - 012/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Heat Rotom - 013/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Heat Rotom - 013/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Charcadet - 014/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Charcadet - 014/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Armarouge - 015/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Armarouge - 015/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Lapras - 016/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Lapras - 016/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Frigibax - 017/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Frigibax - 017/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Hawlucha - 175/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Pikachu - 018/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Pikachu - 018/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Raichu - 019/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Raichu - 019/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Chinchou - 020/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Chinchou - 020/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Lanturn - 021/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Lanturn - 021/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Kilowattrel - 022/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Kilowattrel - 022/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Exeggcute - 023/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Exeggcute - 023/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Exeggutor - 024/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Exeggutor - 024/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Xatu - 026/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Xatu - 026/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Ralts - 027/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Ralts - 027/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Kirlia - 028/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Kirlia - 028/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Gardevoir ex - 029/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Chimecho - 030/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Chimecho - 030/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Mime Jr. - 031/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Mime Jr. - 031/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Woobat - 032/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Woobat - 032/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Swoobat - 033/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Swoobat - 033/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Cottonee - 034/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Cottonee - 034/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Whimsicott - 035/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Whimsicott - 035/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Dedenne - 036/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Dedenne - 036/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Mimikyu - 037/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Mimikyu - 037/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Fidough - 038/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Fidough - 038/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Dachsbun - 039/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Dachsbun - 039/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Ceruledge - 040/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Ceruledge - 040/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Flittle - 041/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Flittle - 041/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Greavard - 042/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Greavard - 042/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Houndstone - 043/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Houndstone - 043/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Gimmighoul - 044/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Gimmighoul - 044/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Mankey - 045/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Mankey - 045/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Primeape - 046/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Primeape - 046/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Annihilape - 047/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Annihilape - 047/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Phanpy - 048/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Phanpy - 048/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Donphan - 049/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Donphan - 049/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Barboach - 050/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Barboach - 050/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Clobbopus - 051/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Clobbopus - 051/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Grapploct - 052/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Grapploct - 052/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Great Tusk ex - 053/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Charizard ex - 054/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Gastly - 055/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Gastly - 055/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Haunter - 056/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Haunter - 056/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Gengar - 057/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Gengar - 057/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Paldean Wooper - 058/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Paldean Wooper - 058/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Paldean Clodsire ex - 059/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Scraggy - 060/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Scraggy - 060/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Scrafty - 061/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Scrafty - 061/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Maschiff - 062/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Maschiff - 062/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Mabosstiff - 063/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Mabosstiff - 063/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Varoom - 064/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Varoom - 064/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Revavroom - 065/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Revavroom - 065/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Iron Treads ex - 066/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Gholdengo - 067/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Gholdengo - 067/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Noibat - 068/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Noibat - 068/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Noivern ex - 069/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Cyclizar - 070/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Cyclizar - 070/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Lechonk - 071/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Lechonk - 071/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Oinkologne - 072/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Oinkologne - 072/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Tandemaus - 073/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Tandemaus - 073/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Maushold - 074/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Maushold - 074/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Squawkabilly ex - 075/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Artazon - 076/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Artazon - 076/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Atticus - 077/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Atticus - 077/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Clive - 078/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Clive - 078/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Electric Generator - 079/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Electric Generator - 079/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Iono - 080/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Iono - 080/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Moonlit Hill - 081/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Moonlit Hill - 081/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Nemona - 082/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Nemona - 082/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Nemona's Backpack - 083/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Nemona's Backpack - 083/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Nest Ball - 084/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Nest Ball - 084/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Paldean Student - 085/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Paldean Student - 085/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Paldean Student - 086/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Paldean Student - 086/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Gardevoir ex - 233/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Professorâs Research - 087/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Professorâs Research - 087/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Professor's Research - 088/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Professor's Research - 088/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Rare Candy - 089/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Rare Candy - 089/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Technical Machine: Crisis Punch - 090/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Technical Machine: Crisis Punch - 090/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Ultra Ball - 091/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Ultra Ball - 091/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Oddish - 092/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Gloom - 093/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Vileplume - 094/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Scyther - 095/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Hoppip - 096/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Skiploom - 097/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Jumpluff - 098/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Pineco - 099/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Snover - 100/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Abomasnow - 101/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Smoliv - 102/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Dolliv - 103/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Arboliva - 104/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Toedscool - 105/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Capsakid - 106/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Scovillain - 107/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Rellor - 108/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Charmander - 109/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Charmeleon - 110/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Paldean Tauros - 111/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Entei - 112/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Oricorio - 113/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Charcadet - 114/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Armarouge - 115/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Slowpoke - 116/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Slowbro - 117/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Staryu - 118/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Starmie - 119/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Paldean Tauros - 120/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Wiglett - 121/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Wugtrio - 122/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Finizen - 123/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Palafin - 124/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Veluza - 125/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Dondozo - 126/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Tatsugiri - 127/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Arctibax - 129/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Baxcalibur - 130/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Pikachu - 131/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Raichu - 132/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Voltorb - 133/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Electrode - 134/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Shinx - 135/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Luxray - 137/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Luxio - 136/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Pachirisu - 138/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Thundurus - 139/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Toxel - 140/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Toxtricity - 141/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Pawmi - 142/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Pawmo - 143/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Pawmot - 144/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Wattrel - 145/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Kilowattrel - 146/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Wigglytuff - 147/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Abra - 148/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Kadabra - 149/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Cleffa - 150/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Natu - 151/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Xatu - 152/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Ralts - 153/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Kirlia - 154/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Drifloon - 155/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Drifblim - 156/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Mime Jr. - 157/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Spiritomb - 158/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Klefki - 159/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Mimikyu - 160/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Dachsbun - 161/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Ceruledge - 162/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Rabsca - 163/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Flittle - 164/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Tinkatink - 165/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Tinkatuff - 166/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Tinkaton - 167/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Houndstone - 168/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Mankey - 169/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Primeape - 170/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Annihilape - 171/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Riolu - 173/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Lucario - 174/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Nacli - 176/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Naclstack - 177/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Garganacl - 178/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Glimmet - 179/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Paldean Wooper - 180/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Murkrow - 181/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Sneasel - 182/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Weavile - 183/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Sableye - 184/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Pawniard - 185/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Bisharp - 186/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Kingambit - 187/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Mabosstiff - 188/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Shroodle - 189/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Grafaiai - 190/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Scizor - 191/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Varoom - 192/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Revavroom - 193/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Noibat - 194/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Cyclizar - 195/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Pidgey - 196/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Pidgeotto - 197/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Jigglypuff - 198/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Doduo - 199/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Dodrio - 200/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Ditto - 201/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Snorlax - 202/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Wingull - 203/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Pelipper - 204/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Skwovet - 205/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Greedent - 206/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Lechonk - 207/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Oinkologne - 208/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Maushold - 210/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Flamigo - 211/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Forretress ex - 212/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Toedscruel ex - 213/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Espathra ex 214/091 - 214/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Alakazam ex - 215/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Mew ex - 216/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Gardevoir ex - 217/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Glimmora ex - 218/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Paldean Clodsire ex - 219/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Noivern ex - 220/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Pidgeot ex - 221/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Wigglytuff ex - 222/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Squawkabilly ex - 223/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Wugtrio - 224/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Palafin - 225/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Pawmi - 226/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Clive - 227/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Judge - 228/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Nemona - 229/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Paldean Student - 230/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Paldean Student - 231/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Mew ex - 232/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Charizard ex - 234/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Arven - 235/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Clive - 236/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Iono 237/091 - 237/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Nemona - 238/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Penny - 239/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Chi-Yu - 241/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Chien-Pao - 242/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Miraidon ex - 243/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Ting-Lu - 244/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Koraidon ex - 245/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Natu - 025/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Natu - 025/91 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Mimikyu - 075/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Paldean Tauros - 172/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Frigibax - 128/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Tandemaus - 209/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Heat Rotom - 013/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Mimikyu - 037/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Cyclizar - 070/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Professorâs Research - 087/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Professor's Research - 088/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Varoom - 026/103 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Iron Moth - 028/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Brute Bonnet - 123/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Fidough - 069/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Greavard - 070/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Maschiff - 071/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Mimikyu - 075/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Greninja ex - 054/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Togekiss - 038/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Mawile - 039/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Roaring Moon ex - 067/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Great Tusk ex - 072/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Iron Treads ex - 073/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Charizard ex - 074/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Sprigatito - 076/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Floragato - 077/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Meowscarada ex - 078/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Fuecoco - 079/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Crocalor - 080/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Skeledirge ex - 081/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Quaxly - 082/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Quaxwell - 083/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Quaquaval ex - 084/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Noivern ex - 220/91 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Ceruledge - 040/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Scyther - 001/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Scyther - 001/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Pineco - 002/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Pineco - 002/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Seedot - 003/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Seedot - 003/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Nuzleaf - 004/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Nuzleaf - 004/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Shiftry - 005/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Shiftry - 005/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Shroomish - 006/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Shroomish - 006/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Breloom - 007/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Breloom - 007/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Roselia - 008/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Roselia - 008/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Roserade - 009/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Roserade - 009/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Turtwig - 010/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Turtwig - 010/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Grotle - 011/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Grotle - 011/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Torterra ex - 012/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Shaymin - 013/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Shaymin - 013/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Cottonee - 014/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Cottonee - 014/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Whimsicott - 015/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Whimsicott - 015/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Deerling - 016/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Deerling - 016/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Sawsbuck - 017/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Sawsbuck - 017/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Grubbin - 018/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Grubbin - 018/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Dhelmise - 019/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Dhelmise - 019/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Bramblin - 020/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Bramblin - 020/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Brambleghast - 021/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Brambleghast - 021/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Scovillain ex - 022/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Rellor - 023/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Rellor - 023/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Rabsca - 024/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Rabsca - 024/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Leaves ex - 025/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Ponyta - 026/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Ponyta - 026/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Rapidash - 027/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Rapidash - 027/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Slugma - 028/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Slugma - 028/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Magcargo - 029/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Magcargo - 029/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Victini - 030/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Victini - 030/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Heatmor - 031/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Heatmor - 031/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Litten - 032/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Litten - 032/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Torracat - 033/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Torracat - 033/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Incineroar ex - 034/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Turtonator - 035/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Turtonator - 035/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Sizzlipede - 036/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Sizzlipede - 036/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Centiskorch - 037/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Centiskorch - 037/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Gouging Fire ex - 038/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Totodile - 039/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Totodile - 039/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Croconaw - 040/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Croconaw - 040/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Feraligatr - 041/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Feraligatr - 041/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Carvanha - 042/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Carvanha - 042/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Sharpedo - 043/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Sharpedo - 043/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Keldeo - 044/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Keldeo - 044/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Snom - 045/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Snom - 045/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Frosmoth - 046/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Frosmoth - 046/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Wiglett - 047/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Wiglett - 047/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Finizen - 048/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Finizen - 048/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Palafin - 049/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Palafin - 049/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Walking Wake ex - 050/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Pikachu - 051/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Pikachu - 051/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Raichu - 052/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Raichu - 052/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Electabuzz - 053/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Electabuzz - 053/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Electivire - 054/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Electivire - 054/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Charjabug - 055/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Charjabug - 055/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Vikavolt - 056/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Vikavolt - 056/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Zeraora - 057/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Zeraora - 057/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Yamper - 058/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Yamper - 058/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Boltund - 059/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Boltund - 059/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Wugtrio ex - 060/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Hands - 061/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Hands - 061/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Thorns - 062/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Thorns - 062/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Mr. Mime - 063/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Mr. Mime - 063/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Marill - 064/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Marill - 064/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Azumarill - 065/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Azumarill - 065/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Girafarig - 066/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Girafarig - 066/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Latias - 067/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Latias - 067/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Bronzor - 068/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Bronzor - 068/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Bronzong - 069/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Bronzong - 069/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Solosis - 070/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Solosis - 070/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Duosion - 071/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Duosion - 071/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Reuniclus - 072/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Reuniclus - 072/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Elgyem - 073/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Elgyem - 073/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Beheeyem - 074/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Beheeyem - 074/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Cutiefly - 075/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Cutiefly - 075/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Ribombee - 076/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Ribombee - 076/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Scream Tail - 077/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Scream Tail - 077/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Flutter Mane - 078/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Flutter Mane - 078/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Valiant - 079/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Valiant - 079/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Valiant - 080/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Valiant - 080/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Crown ex - 081/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Meditite - 082/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Meditite - 082/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Medicham - 083/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Medicham - 083/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Relicanth - 084/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Relicanth - 084/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Drilbur - 085/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Drilbur - 085/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Excadrill - 086/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Excadrill - 086/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Golett - 087/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Golett - 087/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Golurk - 088/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Golurk - 088/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Rockruff - 089/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Rockruff - 089/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Lycanroc - 090/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Lycanroc - 090/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Mudbray - 091/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Mudbray - 091/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Mudsdale - 092/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Mudsdale - 092/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Rolycoly - 093/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Rolycoly - 093/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Carkol - 094/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Carkol - 094/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Coalossal - 095/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Coalossal - 095/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Great Tusk - 096/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Great Tusk - 096/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Great Tusk - 097/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Great Tusk - 097/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Sandy Shocks - 098/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Sandy Shocks - 098/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Boulder ex - 099/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Ekans - 100/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Ekans - 100/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Arbok - 101/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Arbok - 101/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Gastly - 102/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Gastly - 102/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Haunter - 103/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Haunter - 103/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Gengar ex - 104/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Poochyena - 105/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Poochyena - 105/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Mightyena - 106/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Mightyena - 106/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Sableye - 107/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Sableye - 107/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Farigiraf ex - 108/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Roaring Moon - 109/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Roaring Moon - 109/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Forretress - 110/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Forretress - 110/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Scizor ex - 111/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Mawile - 112/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Mawile - 112/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Beldum - 113/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Beldum - 113/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Metang - 114/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Metang - 114/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Metagross - 115/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Metagross - 115/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Meltan - 116/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Meltan - 116/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Melmetal - 117/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Melmetal - 117/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Treads - 118/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Treads - 118/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Koraidon - 119/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Koraidon - 119/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Koraidon ex - 120/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Miraidon - 121/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Miraidon - 121/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Miraidon ex - 122/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Raging Bolt ex - 123/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Lickitung - 124/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Lickitung - 124/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Lickilicky - 125/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Lickilicky - 125/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Hoothoot - 126/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Hoothoot - 126/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Noctowl - 127/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Noctowl - 127/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Dunsparce - 128/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Dunsparce - 128/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Dudunsparce - 129/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Dudunsparce - 129/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Skitty - 130/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Skitty - 130/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Delcatty - 131/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Delcatty - 131/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Chatot - 132/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Chatot - 132/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Pidove - 133/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Pidove - 133/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Tranquill - 134/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Tranquill - 134/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Unfezant - 135/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Unfezant - 135/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Minccino - 136/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Minccino - 136/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Cinccino - 137/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Cinccino - 137/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Drampa - 138/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Drampa - 138/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Jugulis - 139/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Jugulis - 139/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Ancient Booster Energy Capsule - 140/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Ancient Booster Energy Capsule - 140/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Awakening Drum - 141/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Bianca's Devotion - 142/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Bianca's Devotion - 142/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Boxed Order - 143/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Boxed Order - 143/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Buddy-Buddy Poffin - 144/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Buddy-Buddy Poffin - 144/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Ciphermaniac's Codebreaking - 145/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Ciphermaniac's Codebreaking - 145/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Eri - 146/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Eri - 146/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Explorer's Guidance - 147/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Explorer's Guidance - 147/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Full Metal Lab - 148/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Full Metal Lab - 148/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Future Booster Energy Capsule - 149/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Future Booster Energy Capsule - 149/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Hand Trimmer - 150/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Hand Trimmer - 150/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Heavy Baton - 151/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Heavy Baton - 151/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Hero's Cape - 152/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Master Ball - 153/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Maximum Belt - 154/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Morty's Conviction - 155/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Morty's Conviction - 155/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Perilous Jungle - 156/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Perilous Jungle - 156/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Prime Catcher - 157/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Reboot Pod - 158/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Rescue Board - 159/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Rescue Board - 159/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Salvatore - 160/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Salvatore - 160/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Mist Energy - 161/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Mist Energy - 161/162 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Neo Upper Energy - 162/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Shiftry - 163/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Grotle - 164/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Deerling - 165/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Sawsbuck - 166/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Litten - 167/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Snom - 168/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Charjabug - 169/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Bronzor - 170/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Reuniclus - 171/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Cutiefly - 172/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Relicanth - 173/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Excadrill - 174/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Mudsdale - 175/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Arbok - 176/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Gastly - 177/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Metagross - 178/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Meltan - 179/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Lickitung - 180/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Chatot - 181/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Minccino - 182/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Cinccino - 183/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Drampa - 184/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Torterra ex - 185/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Leaves ex - 186/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Incineroar ex - 187/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Gouging Fire ex - 188/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Walking Wake ex - 189/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Wugtrio ex - 190/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Crown ex - 191/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Boulder ex - 192/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Gengar ex - 193/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Farigiraf ex - 194/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Scizor ex - 195/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Raging Bolt ex - 196/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Bianca's Devotion - 197/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Ciphermaniac's Codebreaking - 198/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Eri - 199/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Explorer's Guidance - 200/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Morty's Conviction - 201/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Salvatore - 202/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Leaves ex - 203/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Gouging Fire ex - 204/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Walking Wake ex - 205/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Crown ex - 206/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Boulder ex - 207/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Raging Bolt ex - 208/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Bianca's Devotion - 209/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Eri - 210/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Morty's Conviction - 211/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Salvatore - 212/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Leaves ex - 213/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Gouging Fire ex - 214/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Walking Wake ex - 215/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Crown ex - 216/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Iron Boulder ex - 217/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Raging Bolt ex - 218/162 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Mabosstiff ex - 086/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Sprigatito ex - 087 - 087/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pikachu - 088 - 088/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Flutter Mane - 097 - 097/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Iron Thorns - 098 - 098/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Awakening Drum - 141/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Prime Catcher - 157/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Temporal Forces - Reboot Pod - 158/162 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Cyclizar - 096 - 096/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Cleffa - 095 - 095/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Bellibolt - 094 - 094/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Koraidon - 091 - 091/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Miraidon - 092 - 092/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Carvanha - 093 - 093/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Feraligatr - 089/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Metang - 090/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Rillaboom (Staff Prerelease) - 006/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Rillaboom (Prerelease) - 006/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Frosmoth (Prerelease) - 007/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Frosmoth (Staff Prerelease) - 007/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Perrserker (Prerelease) - 008/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Perrserker (Staff Prerelease) - 008/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cinccino (Prerelease) - 009/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cinccino (Staff Prerelease) - 009/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Flapple (Prerelease) - 022/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Flapple (Staff Prerelease) - 022/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Luxray (Prerelease) - 023/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Luxray (Staff Prerelease) - 023/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Coalossal (Prerelease) - 024/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Coalossal (Staff Prerelease) - 024/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Garbodor (Prerelease) - 025/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Garbodor (Staff Prerelease) - 025/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Decidueye (Prerelease) - 035/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Decidueye (Staff Prerelease) - 035/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Arctozolt (Prerelease) - 036/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Arctozolt (Staff Prerelease) - 036/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Hydreigon (Prerelease) - 037/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Hydreigon (Staff Prerelease) - 037/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Kangaskhan (Prerelease) - 038/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Kangaskhan (Staff Prerelease) - 038/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Charizard (Prerelease) - 066/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Charizard (Staff Prerelease) - 066/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Donphan (Prerelease) - 067/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Donphan (Staff Prerelease) - 067/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Snorlax (Prerelease) - 068/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Snorlax (Staff Prerelease) - 068/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Lugia (Prerelease) - 069/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Lugia (Staff Prerelease) - 069/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cherrim (Prerelease) - 088/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Octillery (Prerelease) - 089/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Houndoom (Prerelease) - 090/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Bronzong (Prerelease) - 091/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cinderace (Prerelease) - 112/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cinderace (Staff Prerelease) - 112/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Inteleon (Prerelease) - 113/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cresselia (Staff Prerelease) - 114/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cresselia (Prerelease) - 114/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Passimian (Prerelease) - 115/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Passimian (Staff Prerelease) - 115/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Flaaffy (Prerelease) - 122/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Articuno (Prerelease) - 123/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Zapdos (Prerelease) - 124/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Galarian Moltres (Prerelease) - 125/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Oricorio (Prerelease) - 168/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pyukumuku (Prerelease) - 169/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Deoxys (Prerelease) - 170/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Latias (Prerelease) - 171/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Finneon (Prerelease) - 240/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Gengar (Prerelease) - 241/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Comfey (Prerelease) - 242/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Machamp (Prerelease) - 243/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Sunflora (Prerelease) - 269/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Sunflora (Staff Prerelease) - 269/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Rapidash (Prerelease) - 270/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Rapidash (Staff Prerelease) - 270/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Kirlia (Prerelease) - 271/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Kirlia (Staff Prerelease) - 271/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Archeops (Prerelease) - 272/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Archeops (Staff Prerelease) - 272/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Lucario ex - 017/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Shroodle - 099/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Grafaiai ex - 100/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Iono - 124/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Flutter Mane - 097/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Iron Thorns - 098/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Wo-Chien - 240/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pawmot - 006/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pawmot - 006/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Hawlucha - 007/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Revavroom - 008/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Revavroom - 008/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Baxcalibur - 019/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Baxcalibur - 019/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Tinkaton - 020/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Tinkaton - 020/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Murkrow - 021/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Murkrow - 021/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pelipper - 022/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pelipper - 022/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Palafin - 036/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Palafin - 036/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Cleffa - 037/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Cleffa - 037/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Togekiss - 038/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Togekiss - 038/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Mawile - 039/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Mawile - 039/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Chi-Yu - 057/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Iron Bundle - 058/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Xatu - 059/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Aegislash - 060/103 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Feraligatr - 089/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Metang - 090/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Koraidon - 091/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Miraidon - 092/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Chi-Yu - 057/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Iron Bundle - 058/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Xatu - 059/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Aegislash - 060/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Feraligatr - 089/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Metang - 090/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Koraidon - 091/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Miraidon - 092/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Houndoom ex - 103/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Melmetal ex - 104/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Astral Radiance - Virizion V (Full Art) - 164/189 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Tangela - 001/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Tangela - 001/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Tangrowth - 002/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Tangrowth - 002/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Spinarak - 004/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Spinarak - 004/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Ariados - 005/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Ariados - 005/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Sunkern - 006/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Sunkern - 006/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Heracross - 008/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Heracross - 008/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Volbeat - 009/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Volbeat - 009/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Illumise - 010/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Illumise - 010/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Leafeon - 011/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Leafeon - 011/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Phantump - 012/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Phantump - 012/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Trevenant - 013/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Trevenant - 013/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Grookey - 014/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Grookey - 014/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Thwackey - 015/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Thwackey - 015/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Rillaboom - 016/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Rillaboom - 016/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Iron Leaves - 019/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Iron Leaves - 019/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Sinistcha - 022/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Sinistcha - 022/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Teal Mask Ogerpon - 024/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Teal Mask Ogerpon - 024/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Vulpix - 026/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Vulpix - 026/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Ninetales - 027/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Ninetales - 027/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Slugma - 028/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Slugma - 028/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Chimchar - 031/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Chimchar - 031/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Monferno - 032/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Monferno - 032/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Darumaka - 034/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Darumaka - 034/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Darmanitan - 035/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Darmanitan - 035/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Litwick - 036/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Litwick - 036/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Lampent - 037/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Lampent - 037/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Chandelure - 038/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Chandelure - 038/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Chi-Yu - 039/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Chi-Yu - 039/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Poliwag - 041/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Poliwag - 041/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Poliwhirl - 042/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Poliwhirl - 042/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Poliwrath - 043/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Poliwrath - 043/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Goldeen - 044/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Goldeen - 044/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Seaking - 045/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Seaking - 045/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Jynx - 046/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Jynx - 046/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Corphish - 047/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Corphish - 047/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Crawdaunt - 048/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Crawdaunt - 048/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Feebas - 049/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Feebas - 049/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Milotic - 050/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Milotic - 050/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Snorunt - 051/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Snorunt - 051/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Glalie - 052/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Glalie - 052/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Glaceon - 054/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Glaceon - 054/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Froakie - 056/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Froakie - 056/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Frogadier - 057/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Frogadier - 057/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Finizen - 059/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Finizen - 059/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Palafin - 060/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Palafin - 060/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Iron Bundle - 062/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Iron Bundle - 062/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Walking Wake - 063/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Walking Wake - 063/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Zapdos - 065/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Zapdos - 065/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Shinx - 066/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Shinx - 066/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Luxio - 067/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Luxio - 067/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Emolga - 069/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Emolga - 069/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Helioptile - 070/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Helioptile - 070/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Morpeko - 072/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Morpeko - 072/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Tadbulb - 073/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Tadbulb - 073/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Bellibolt - 074/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Bellibolt - 074/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Kilowattrel - 076/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Kilowattrel - 076/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Clefairy - 078/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Clefairy - 078/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Clefable - 079/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Clefable - 079/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Abra - 080/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Abra - 080/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Kadabra - 081/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Kadabra - 081/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Alakazam - 082/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Alakazam - 082/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Girafarig - 083/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Girafarig - 083/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Farigiraf - 084/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Farigiraf - 084/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Flabebe - 086/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Flabebe - 086/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Floette - 087/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Floette - 087/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Florges - 088/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Florges - 088/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Swirlix - 089/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Swirlix - 089/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Slurpuff - 090/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Slurpuff - 090/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Sandygast - 091/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Sandygast - 091/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Palossand - 092/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Palossand - 092/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Munkidori - 095/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Munkidori - 095/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Fezandipiti - 096/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Fezandipiti - 096/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Sandshrew - 097/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Sandshrew - 097/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Sandslash - 098/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Sandslash - 098/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Hisuian Arcanine - 100/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Hisuian Arcanine - 100/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Nosepass - 101/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Nosepass - 101/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Gurdurr - 104/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Gurdurr - 104/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Conkeldurr - 105/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Conkeldurr - 105/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Hawlucha - 107/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Hawlucha - 107/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Glimmet - 108/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Glimmet - 108/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Glimmora - 109/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Glimmora - 109/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Ting-Lu - 110/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Ting-Lu - 110/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Okidogi - 111/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Okidogi - 111/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Poochyena - 113/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Poochyena - 113/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Mightyena - 114/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Mightyena - 114/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Venipede - 115/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Venipede - 115/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Whirlipede - 116/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Whirlipede - 116/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Scolipede - 117/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Scolipede - 117/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Brute Bonnet - 118/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Brute Bonnet - 118/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Skarmory - 119/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Skarmory - 119/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Aron - 120/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Aron - 120/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Aggron - 122/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Aggron - 122/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Heatran - 123/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Heatran - 123/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Varoom - 124/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Varoom - 124/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Revavroom - 125/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Revavroom - 125/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Dreepy - 128/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Dreepy - 128/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Drakloak - 129/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Drakloak - 129/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Farfetch'd - 132/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Farfetch'd - 132/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Snorlax - 136/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Snorlax - 136/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Aipom - 137/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Aipom - 137/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Ambipom - 138/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Ambipom - 138/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Ducklett - 139/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Ducklett - 139/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Swanna - 140/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Swanna - 140/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Accompanying Flute - 142/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Accompanying Flute - 142/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Bug Catching Set - 143/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Bug Catching Set - 143/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Community Center - 146/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Community Center - 146/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Cook - 147/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Cook - 147/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Festival Grounds - 149/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Festival Grounds - 149/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Handheld Fan - 150/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Handheld Fan - 150/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Hyper Aroma - 152/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Jamming Tower - 153/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Jamming Tower - 153/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Love Ball - 156/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Love Ball - 156/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Lucky Helmet - 158/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Lucky Helmet - 158/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Ogre's Mask - 159/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Ogre's Mask - 159/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Raifort - 161/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Raifort - 161/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Scoop Up Cyclone - 162/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Secret Box - 163/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Survival Brace - 164/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Unfair Stamp - 165/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Boomerang Energy - 166/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Boomerang Energy - 166/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Legacy Energy - 167/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Buddy-Buddy Poffin - 223/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Rescue Board - 225/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Luminous Energy - 226/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Pinsir - 003/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Pinsir - 003/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Sunflora - 007/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Sunflora - 007/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Applin - 017/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Applin - 017/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Dipplin - 018/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Dipplin - 018/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Poltchageist - 020/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Poltchageist - 020/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Poltchageist - 021/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Poltchageist - 021/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Sinistcha ex - 023/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Teal Mask Ogerpon ex - 025/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Magcargo ex - 029/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Torkoal - 030/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Torkoal - 030/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Infernape - 033/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Infernape - 033/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Hearthflame Mask Ogerpon ex - 040/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Froslass - 053/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Froslass - 053/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Phione - 055/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Phione - 055/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Cramorant - 058/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Cramorant - 058/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Palafin ex - 061/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Wellspring Mask Ogerpon ex - 064/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Luxray ex - 068/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Heliolisk - 071/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Heliolisk - 071/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Wattrel - 075/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Wattrel - 075/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Iron Thorns ex - 077/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Chimecho - 085/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Chimecho - 085/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Enamorus - 093/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Enamorus - 093/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Scream Tail ex - 094/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Hisuian Growlithe - 099/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Hisuian Growlithe - 099/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Probopass - 102/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Probopass - 102/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Timburr - 103/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Timburr - 103/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Greninja ex - 106/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Cornerstone Mask Ogerpon ex - 112/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Lairon - 121/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Lairon - 121/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Applin - 126/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Applin - 126/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Dipplin - 127/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Dipplin - 127/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Dragapult ex - 130/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Tatsugiri - 131/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Tatsugiri - 131/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Chansey - 133/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Chansey - 133/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Blissey ex - 134/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Eevee - 135/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Eevee - 135/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Bloodmoon Ursaluna ex - 141/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Caretaker - 144/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Caretaker - 144/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Carmine - 145/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Carmine - 145/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Enhanced Hammer - 148/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Enhanced Hammer - 148/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Hassel - 151/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Hassel - 151/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Kieran - 154/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Kieran - 154/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Lana's Aid - 155/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Lana's Aid - 155/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Lucian - 157/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Lucian - 157/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Perrin - 160/167 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Perrin - 160/167 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Pinsir - 168/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Sunflora - 169/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Dipplin - 170/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Poltchageist - 171/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Torkoal - 172/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Infernape - 173/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Froslass - 174/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Phione - 175/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Cramorant - 176/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Heliolisk - 177/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Wattrel - 178/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Chimecho - 179/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Enamorus - 180/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Hisuian Growlithe - 181/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Probopass - 182/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Timburr - 183/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Lairon - 184/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Applin - 185/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Tatsugiri - 186/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Chansey - 187/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Eevee - 188/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Sinistcha ex - 189/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Teal Mask Ogerpon ex - 190/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Magcargo ex - 191/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Hearthflame Mask Ogerpon ex - 192/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Palafin ex - 193/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Wellspring Mask Ogerpon ex - 194/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Luxray ex - 195/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Iron Thorns ex - 196/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Scream Tail ex - 197/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Greninja ex - 198/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Cornerstone Mask Ogerpon ex - 199/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Dragapult ex - 200/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Blissey ex - 201/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Bloodmoon Ursaluna ex - 202/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Caretaker - 203/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Carmine - 204/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Hassel - 205/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Kieran - 206/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Lana's Aid - 207/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Lucian - 208/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Perrin - 209/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Sinistcha ex - 210/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Teal Mask Ogerpon ex - 211/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Hearthflame Mask Ogerpon ex - 212/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Wellspring Mask Ogerpon ex - 213/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Greninja ex - 214/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Cornerstone Mask Ogerpon ex - 215/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Bloodmoon Ursaluna ex - 216/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Carmine - 217/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Kieran - 218/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Lana's Aid - 219/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Perrin - 220/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Teal Mask Ogerpon ex - 221/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Bloodmoon Ursaluna ex - 222/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Twilight Masquerade - Enhanced Hammer - 224/167 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldean Fates - Ceruledge - 040/91 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Arboliva - 023/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Armarouge - 041/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Dondozo - 061/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pawmot - 076/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Tinkaton - 105/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Tinkaton - 105/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Umbreon - 130/103 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Picnicker - 114/103 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Thwackey - 115/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Infernape - 116/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Froslass - 117/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Tatsugiri - 118/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Toxel - 119/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pupitar - 120/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Revavroom - 121/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Snorlax - 122/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Teal Mask Ogerpon - 123/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Teal Mask Ogerpon - 123/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Walking Wake ex - 127/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Iron Leaves ex - 128/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Sinistcha - 022/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Pikachu  (JP) - 227/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Aegislash - 060/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Toxel - 119/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pupitar - 120/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Palafin ex - 126/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Palafin - 062/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Garganacl (Cosmo Holo) - 123/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paradox Rift - Steelix - 125/182 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Armarouge ex - 125/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-UNION - 215/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-UNION - 216/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-UNION - 217/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-UNION - 218/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-UNION - 218/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-UNION - 287/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-UNION - 288/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-UNION - 289/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-UNION - 290/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Morpeko V-UNION - 290/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Revavroom - 121/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pikachu - 190/103 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Cetitan - 063/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Arctibax - 064/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Revavroom - 121/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Kingambit - 130/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Kingdra ex - 131/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Greninja ex - 132/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pecharunt - 149/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Joltik - 001/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Joltik - 001/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Galvantula - 002/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Galvantula - 002/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Rowlet - 003/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Rowlet - 003/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Dartrix - 004/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Dartrix - 004/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Decidueye - 005/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Decidueye - 005/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Tapu Bulu - 006/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Tapu Bulu - 006/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Houndour - 007/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Houndour - 007/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Houndoom - 008/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Houndoom - 008/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Iron Moth - 009/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Iron Moth - 009/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Horsea - 010/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Horsea - 010/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Seadra - 011/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Seadra - 011/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Kingdra ex - 012/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Sneasel - 013/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Sneasel - 013/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Weavile - 014/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Weavile - 014/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Revavroom ex - 015/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Drowzee - 016/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Drowzee - 016/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Hypno - 017/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Hypno - 017/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Duskull - 018/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Duskull - 018/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Dusclops - 019/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Dusclops - 019/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Dusknoir - 020/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Dusknoir - 020/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Cresselia - 021/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Cresselia - 021/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Sylveon - 022/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Sylveon - 022/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Croagunk - 023/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Croagunk - 023/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Toxicroak - 024/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Toxicroak - 024/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Bloodmoon Ursaluna - 025/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Bloodmoon Ursaluna - 025/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Slither Wing - 026/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Slither Wing - 026/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Zubat - 027/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Zubat - 027/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Golbat - 028/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Golbat - 028/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Crobat - 029/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Crobat - 029/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Absol - 030/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Absol - 030/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Zorua - 031/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Zorua - 031/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Zoroark - 032/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Zoroark - 032/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Inkay - 033/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Inkay - 033/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Malamar - 034/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Malamar - 034/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Yveltal - 035/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Yveltal - 035/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Okidogi ex - 036/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Munkidori ex - 037/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Fezandipiti ex - 038/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Pecharunt ex - 039/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Genesect - 040/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Genesect - 040/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Cufant - 041/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Cufant - 041/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Copperajah - 042/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Copperajah - 042/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Varoom - 043/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Varoom - 043/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Axew - 044/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Axew - 044/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Fraxure - 045/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Fraxure - 045/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Haxorus - 046/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Haxorus - 046/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Kyurem - 047/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Kyurem - 047/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Meowth - 048/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Meowth - 048/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Persian - 049/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Persian - 049/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Eevee - 050/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Eevee - 050/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Furfrou - 051/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Furfrou - 051/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Stufful - 052/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Stufful - 052/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Bewear - 053/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Bewear - 053/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Academy at Night - 054/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Academy at Night - 054/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Binding Mochi - 055/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Binding Mochi - 055/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Cassiopeia - 056/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Cassiopeia - 056/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Colress's Tenacity - 057/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Colress's Tenacity - 057/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Dangerous Laser - 058/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Janine's Secret Technique - 059/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Janine's Secret Technique - 059/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Night Stretcher - 061/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Night Stretcher - 061/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Poke-Vital A - 062/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Powerglass - 063/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Powerglass - 063/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Xerosic's Machinations - 064/64 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Xerosic's Machinations - 064/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Tapu Bulu - 065/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Houndoom - 066/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Horsea - 067/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Duskull - 068/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Dusclops - 069/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Dusknoir - 070/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Cresselia - 071/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Munkidori - 072/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Fezandipiti - 073/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Okidogi - 074/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Zorua - 075/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Cufant - 076/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Fraxure - 077/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Persian - 078/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Bewear - 079/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Kingdra ex - 080/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Revavroom ex - 081/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Okidogi ex - 082/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Munkidori ex - 083/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Fezandipiti ex - 084/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Pecharunt ex - 085/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Cassiopeia - 086/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Colress's Tenacity - 087/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Janine's Secret Art - 088/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Xerosic's Machinations - 089/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Okidogi ex - 090/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Munkidori ex - 091/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Fezandipiti ex - 092/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Pecharunt ex - 093/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Cassiopeia - 094/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Pecharunt ex - 095/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Earthen Vessel - 096/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Powerglass - 097/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Basic Darkness Energy - 098/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Basic Metal Energy - 099/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Basic Grass Energy - 001/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Basic Fire Energy - 002/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Basic Lightning Energy - 004/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Basic Psychic Energy - 005/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Basic Fighting Energy - 006/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Basic Darkness Energy (Reverse Holofoil) - 007/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Basic Metal Energy (Reverse Holofil) - 008/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Chien-Pao ex - 061/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Neutralization Zone - 060/64 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Shrouded Fable - Basic Water Energy - 003/64 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Paldea Evolved - Frigibax - 058/193 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pecharunt - 129/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Thwackey - 115/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Infernape - 116/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Froslass - 117/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Tatsugiri - 118/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Pecharunt - 129/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Sword & Shield Promo - Cramorant  (JP) - 226/308 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Venusaur ex - 001/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Ledyba - 002/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Ledyba - 002/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Celebi - 004/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Celebi - 004/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lileep - 005/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lileep - 005/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Cradily - 006/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Cradily - 006/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Carnivine - 007/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Carnivine - 007/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Mow Rotom - 008/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Mow Rotom - 008/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Grubbin - 009/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Grubbin - 009/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Gossifleur - 010/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Gossifleur - 010/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Eldegoss - 011/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Eldegoss - 011/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Applin - 012/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Applin - 012/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Dipplin - 013/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Dipplin - 013/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Nymble - 015/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Nymble - 015/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lokix - 016/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lokix - 016/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Toedscool - 017/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Toedscool - 017/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Toedscruel - 018/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Toedscruel - 018/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Ponyta - 019/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Ponyta - 019/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Rapidash - 020/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Rapidash - 020/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Pansear - 021/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Pansear - 021/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Reshiram - 022/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Reshiram - 022/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Salandit - 023/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Salandit - 023/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Salazzle - 024/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Salazzle - 024/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Scorbunny - 026/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Scorbunny - 026/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Charcadet - 029/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Charcadet - 029/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Blastoise ex - 030/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lapras - 031/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lapras - 031/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Marill - 033/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Marill - 033/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Azumarill - 034/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Azumarill - 034/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Finneon - 035/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Finneon - 035/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lumineon - 036/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lumineon - 036/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Tirtouga - 037/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Tirtouga - 037/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Carracosta - 038/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Carracosta - 038/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Froakie - 039/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Froakie - 039/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Frogadier - 040/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Frogadier - 040/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Greninja ex - 041/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Chewtle - 043/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Chewtle - 043/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Drednaw - 044/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Drednaw - 044/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Veluza - 045/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Veluza - 045/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Electabuzz - 046/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Electabuzz - 046/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Electivire - 047/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Electivire - 047/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Chinchou - 048/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Chinchou - 048/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lanturn - 049/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lanturn - 049/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Charjabug - 052/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Charjabug - 052/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Vikavolt - 053/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Vikavolt - 053/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Togedemaru - 054/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Togedemaru - 054/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Pawmi - 056/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Pawmi - 056/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Slowpoke - 057/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Slowpoke - 057/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Slowking - 058/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Slowking - 058/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Mewtwo - 059/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Mewtwo - 059/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Drifloon - 060/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Drifloon - 060/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Drifblim - 061/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Drifblim - 061/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Yamask - 062/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Yamask - 062/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Comfey - 063/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Comfey - 063/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Alcremie - 065/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Alcremie - 065/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Fidough - 066/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Fidough - 066/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Flittle - 068/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Flittle - 068/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Espathra - 069/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Espathra - 069/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Greavard - 070/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Greavard - 070/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Iron Boulder - 071/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Iron Boulder - 071/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Cubone - 072/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Cubone - 072/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Marowak - 073/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Marowak - 073/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Rhyhorn - 074/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Rhyhorn - 074/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Rhydon - 075/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Rhydon - 075/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Rhyperior - 076/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Rhyperior - 076/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Medicham - 079/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Medicham - 079/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Riolu - 081/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Riolu - 081/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lucario ex - 082/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Mienfoo - 083/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Mienfoo - 083/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Mienshao - 084/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Mienshao - 084/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Pancham - 085/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Pancham - 085/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Diancie - 086/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Diancie - 086/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Crabrawler - 087/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Crabrawler - 087/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Falinks - 088/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Falinks - 088/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Garganacl ex - 089/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Koraidon - 090/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Koraidon - 090/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Swalot - 092/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Swalot - 092/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Pangoro - 093/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Pangoro - 093/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Impidimp - 094/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Impidimp - 094/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Morgrem - 095/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Morgrem - 095/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Grimmsnarl - 096/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Grimmsnarl - 096/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Bombirdier - 097/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Bombirdier - 097/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Jirachi - 098/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Jirachi - 098/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Klink - 099/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Klink - 099/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Klang - 100/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Klang - 100/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Klinklang - 101/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Klinklang - 101/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Melmetal - 104/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Melmetal - 104/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Melmetal ex - 105/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Duraludon - 106/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Duraludon - 106/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Varoom - 108/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Varoom - 108/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Revavroom - 109/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Revavroom - 109/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Raging Bolt - 111/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Raging Bolt - 111/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Tauros - 112/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Tauros - 112/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Eevee - 113/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Eevee - 113/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Hoothoot - 114/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Hoothoot - 114/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Noctowl - 115/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Noctowl - 115/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Glameow - 116/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Glameow - 116/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Purugly - 117/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Purugly - 117/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Fan Rotom - 118/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Fan Rotom - 118/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Bouffalant - 119/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Bouffalant - 119/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Tornadus - 120/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Tornadus - 120/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Fletchling - 121/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Fletchling - 121/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Fletchinder - 122/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Fletchinder - 122/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Talonflame - 123/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Talonflame - 123/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Wooloo - 124/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Wooloo - 124/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Dubwool - 125/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Dubwool - 125/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lechonk - 126/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lechonk - 126/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Cyclizar - 127/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Cyclizar - 127/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Antique Cover Fossil - 129/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Antique Cover Fossil - 129/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Antique Root Fossil - 130/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Antique Root Fossil - 130/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Deluxe Bomb - 134/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Glass Trumpet - 135/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Glass Trumpet - 135/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Grand Tree - 136/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Gravity Gemstone - 137/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Gravity Gemstone - 137/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Occa Berry - 140/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Occa Berry - 140/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Payapa Berry - 141/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Payapa Berry - 141/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Sparkling Crystal - 142/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Bulbasaur - 143/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Squirtle - 148/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Bravery Charm - 175/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Noctowl - 141/103 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Basic Grass Energy - 009/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Basic Fire Energy - 010/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Basic Water Energy - 011/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Basic Lightning Energy - 012/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Basic Psychic Energy - 013/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Basic Fighting Energy - 014/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Basic Darkness Energy - 015/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Basic Metal Energy - 016/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Ledian - 133/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Drifblim - 135/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Bouffalant - 136/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Noctowl - 141/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Noctowl (Pokemon Center Exclusive) - 141/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Crabominable - 134/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Ledian - 003/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Ledian - 003/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Hydrapple ex - 014/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Turtonator - 025/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Turtonator - 025/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Raboot - 027/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Raboot - 027/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Galvantula ex - 051/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Zeraora - 055/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Zeraora - 055/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Milcery - 064/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Milcery - 064/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Dachsbun ex - 067/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Meditite - 077/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Meditite - 077/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Meditite - 078/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Meditite - 078/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Medicham ex - 080/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Gulpin - 091/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Gulpin - 091/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Meltan - 102/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Meltan - 102/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Meltan - 103/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Meltan - 103/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Orthworm ex - 110/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Terapagos ex - 128/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Area Zero Underdepths - 131/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Area Zero Underdepths - 131/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Briar - 132/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Briar - 132/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Crispin - 133/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Crispin - 133/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Kofu - 138/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Kofu - 138/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Ledian - 144/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Turtonator - 146/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Raboot - 147/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Joltik - 150/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Milcery - 152/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Meditite - 153/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Gulpin - 154/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Archaludon - 155/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Hydrapple ex - 156/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Galvantula ex - 159/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Medicham ex - 161/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Orthworm ex - 162/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Briar - 163/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Crispin - 164/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Hydrapple ex - 167/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Dachsbun ex - 169/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Terapagos ex - 170/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Briar - 171/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Terapagos ex - 173/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Area Zero Underdepths - 174/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Cinderace ex - 028/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lapras ex - 032/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Crabominable - 042/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Crabominable - 042/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Joltik - 050/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Joltik - 050/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Archaludon - 107/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Archaludon - 107/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lacey - 139/142 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lacey - 139/142 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lileep - 145/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Crabominable - 149/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Zeraora - 151/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Cinderace ex - 157/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lapras ex - 158/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Dachsbun ex - 160/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Kofu - 165/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lacey - 166/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Galvantula ex - 168/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Stellar Crown - Lacey - 172/142 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Mew ex - 053/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Paradise Resort - 150/103 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Ledian - 133/103 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Drifblim - 135/103 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Bouffalant - 136/103 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Noctowl - 141/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Noctowl - 141/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Gouging Fire ex - 144/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Raging Bolt ex - 145/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Iron Crown ex - 146/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Iron Boulder ex - 147/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Paradise Resort - 150/103 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Crabominable - 134/103 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Horsea - 137/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Porygon2 - 138/103 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Latias - 139/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Tinkaton - 140/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Frigibax - 058/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Garganacl (Cosmo Holo) - 123/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Garganacl (Cosmos Holo) - 123/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Scarlet & Violet Promo - Charizard ex - 161/103 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Exeggcute - 001/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Exeggcute - 001/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Exeggcute - 002/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Exeggcute - 002/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Exeggutor - 003/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Exeggutor - 003/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Durant ex - 004/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Scatterbug - 005/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Scatterbug - 005/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Spewpa - 006/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Spewpa - 006/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Vivillon - 007/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Vivillon - 007/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Morelull - 008/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Morelull - 008/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Shiinotic - 009/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Shiinotic - 009/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Dhelmise - 010/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Dhelmise - 010/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Zarude - 011/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Zarude - 011/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Capsakid - 012/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Capsakid - 012/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Rellor - 013/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Rellor - 013/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Rabsca - 014/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Rabsca - 014/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Wo-Chien - 015/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Wo-Chien - 015/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Vulpix - 016/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Vulpix - 016/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Ninetales - 017/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Ninetales - 017/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Paldean Tauros - 018/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Paldean Tauros - 018/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Ho-Oh - 019/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Ho-Oh - 019/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Castform Sunny Form - 020/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Castform Sunny Form - 020/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Victini - 021/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Victini - 021/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Pansear - 022/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Pansear - 022/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Simisear - 023/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Simisear - 023/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Larvesta - 024/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Larvesta - 024/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Volcarona - 025/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Volcarona - 025/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Oricorio - 026/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Oricorio - 026/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Sizzlipede - 027/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Sizzlipede - 027/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Centiskorch - 028/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Centiskorch - 028/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Fuecoco - 029/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Fuecoco - 029/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Crocalor - 030/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Crocalor - 030/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Skeledirge - 031/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Skeledirge - 031/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Charcadet - 032/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Charcadet - 032/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Charcadet - 033/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Charcadet - 033/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Armarouge - 034/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Armarouge - 034/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Ceruledge - 035/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Ceruledge - 035/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Ceruledge ex - 036/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Scovillain ex - 037/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Gouging Fire - 038/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Gouging Fire - 038/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Paldean Tauros - 039/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Paldean Tauros - 039/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Mantine - 040/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Mantine - 040/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Feebas - 041/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Feebas - 041/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Milotic ex - 042/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Spheal - 043/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Spheal - 043/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Sealeo - 044/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Sealeo - 044/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Walrein - 045/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Walrein - 045/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Shellos - 046/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Shellos - 046/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Cryogonal - 047/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Cryogonal - 047/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Black Kyurem ex - 048/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Bruxish - 049/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Bruxish - 049/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Quaxly - 050/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Quaxly - 050/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Quaxwell - 051/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Quaxwell - 051/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Quaquaval - 052/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Quaquaval - 052/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Cetoddle - 053/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Cetoddle - 053/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Cetitan - 054/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Cetitan - 054/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Iron Bundle - 055/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Iron Bundle - 055/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Chien-Pao - 056/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Chien-Pao - 056/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Pikachu ex - 057/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Magnemite - 058/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Magnemite - 058/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Magneton - 059/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Magneton - 059/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Magnezone - 060/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Magnezone - 060/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Rotom - 061/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Rotom - 061/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Blitzle - 062/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Blitzle - 062/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Zebstrika - 063/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Zebstrika - 063/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Stunfisk - 064/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Stunfisk - 064/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Tapu Koko - 065/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Tapu Koko - 065/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Wattrel - 066/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Wattrel - 066/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Kilowattrel - 067/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Kilowattrel - 067/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Kilowattrel ex - 068/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Miraidon - 069/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Miraidon - 069/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Togepi - 070/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Togepi - 070/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Togetic - 071/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Togetic - 071/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Togekiss - 072/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Togekiss - 072/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Marill - 073/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Marill - 073/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Azumarill - 074/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Azumarill - 074/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Smoochum - 075/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Smoochum - 075/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Latias ex - 076/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Latios - 077/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Latios - 077/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Uxie - 078/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Uxie - 078/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Mesprit - 079/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Mesprit - 079/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Azelf - 080/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Azelf - 080/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Sigilyph - 081/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Sigilyph - 081/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Yamask - 082/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Yamask - 082/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Cofagrigus - 083/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Cofagrigus - 083/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Espurr - 084/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Espurr - 084/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Meowstic - 085/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Meowstic - 085/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Sylveon ex - 086/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Dedenne - 087/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Dedenne - 087/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Xerneas - 088/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Xerneas - 088/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Oricorio - 089/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Oricorio - 089/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Sandygast - 090/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Sandygast - 090/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Palossand ex - 091/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Tapu Lele - 092/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Tapu Lele - 092/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Indeedee - 093/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Indeedee - 093/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Flittle - 094/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Flittle - 094/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Espathra - 095/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Espathra - 095/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Flutter Mane - 096/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Flutter Mane - 096/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Gimmighoul - 097/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Gimmighoul - 097/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Mankey - 098/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Mankey - 098/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Primeape - 099/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Primeape - 099/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Annihilape - 100/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Annihilape - 100/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Paldean Tauros - 101/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Paldean Tauros - 101/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Phanpy - 102/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Phanpy - 102/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Donphan - 103/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Donphan - 103/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Trapinch - 104/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Trapinch - 104/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Vibrava - 105/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Vibrava - 105/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Flygon ex - 106/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Gastrodon - 107/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Gastrodon - 107/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Drilbur - 108/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Drilbur - 108/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Excadrill - 109/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Excadrill - 109/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Landorus - 110/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Landorus - 110/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Passimian - 111/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Passimian - 111/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Clobbopus - 112/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Clobbopus - 112/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Grapploct - 113/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Grapploct - 113/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Glimmet - 114/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Glimmet - 114/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Glimmora - 115/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Glimmora - 115/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Koraidon - 116/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Koraidon - 116/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Deino - 117/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Deino - 117/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Zweilous - 118/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Zweilous - 118/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Hydreigon ex - 119/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Shroodle - 120/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Shroodle - 120/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Grafaiai - 121/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Grafaiai - 121/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Alolan Diglett - 122/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Alolan Diglett - 122/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Alolan Dugtrio - 123/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Alolan Dugtrio - 123/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Skarmory - 124/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Skarmory - 124/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Registeel - 125/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Registeel - 125/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Bronzor - 126/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Bronzor - 126/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Bronzong - 127/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Bronzong - 127/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Klefki - 128/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Klefki - 128/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Duraludon - 129/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Duraludon - 129/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Archaludon ex - 130/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Gholdengo - 131/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Gholdengo - 131/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Iron Crown - 132/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Iron Crown - 132/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Alolan Exeggutor ex - 133/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Altaria - 134/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Altaria - 134/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Dialga - 135/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Dialga - 135/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Palkia - 136/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Palkia - 136/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Turtonator - 137/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Turtonator - 137/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Applin - 138/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Applin - 138/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Flapple - 139/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Flapple - 139/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Appletun - 140/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Appletun - 140/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Eternatus - 141/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Eternatus - 141/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Tatsugiri ex - 142/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Eevee - 143/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Eevee - 143/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Snorlax - 144/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Snorlax - 144/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Slakoth - 145/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Slakoth - 145/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Vigoroth - 146/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Vigoroth - 146/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Slaking ex - 147/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Swablu - 148/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Swablu - 148/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Zangoose - 149/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Zangoose - 149/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Kecleon - 150/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Kecleon - 150/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Bouffalant - 151/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Bouffalant - 151/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Rufflet - 152/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Rufflet - 152/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Braviary - 153/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Braviary - 153/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Helioptile - 154/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Helioptile - 154/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Heliolisk - 155/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Heliolisk - 155/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Oranguru - 156/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Oranguru - 156/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Tandemaus - 157/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Tandemaus - 157/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Maushold - 158/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Maushold - 158/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Cyclizar ex - 159/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Flamigo ex - 160/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Terapagos - 161/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Terapagos - 161/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Amulet of Hope - 162/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Babiri Berry - 163/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Babiri Berry - 163/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Brilliant Blender - 164/203 - Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Call Bell - 165/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Call Bell - 165/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Chill Teaser Toy - 166/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Chill Teaser Toy - 166/203 - Reverse Holofoil" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
    <Route path="/Pokemon kaart/Surging Sparks - Clemont's Quick Wit - 167/203 - Normal" element={<MainComponent firstComponent={ProductType.Single} />}></Route>
  </Routes>
);

export default App;
