export type TShoppingCart = {
    items: TShoppingCartItem[];
}

export type TReviews = {
    reviews: IReview[];
    average: number;
    count: number;
}

export interface IReview extends TReview {
    child?: React.ReactNode
}

export type TReview = {
    id: number;
    name: string;
    comment: string;
    stars: number;
    date: string;
}

export type TBTCAddress = {
    Address: string;
    Success: boolean;
    ErrorString: string;
}

export type TInvoice = {
    invoice?: string;
    qrCode?: string;
    id?: string;
    sats?: number;
    Ammount?: number;
    AmmountReceived?: number;
    Validated?: boolean;
    errorString?: string;
    success: boolean;
}

export type TSetAmmounts = {
    setname:string;
    ammount:number;
}

export type TSale = {
    name: string;
    email: string;
    postalcode: string;
    street: string;
    number: string;
    city: string;
    products: TSaleProduct[];
}

export type TSaleProduct = {
    id: number;
    setid: number;
    ammount: number;
}

export type TShoppingCartItem = {
    product: TProduct;
    ammount: number;
}

export type TProduct = {
    id: number;
    title?: string;
    available: number;
    priceInUSD?: number;
    rarety?: string;
    setID?: number;
    setName?: string;
    number?: number;
    imageUrls?: string[];
    isProduct?: boolean;
    name?: string;
    accr?: string;
    expansion?: string;
    expansionAccr?: string;
    size?: number;
    extraSize?: number;
    parentid?: number;
    scardnumber?: string;
    scost?: string;
    cost?: number;
    imageNames?: string;
    version?: string;
    order?: number;
    description?: string;
    priceInEUR?: number;
    priceInSats?: number;
    pricedIn?: PricingType;
    tags?: TProductTag[];
    lastAddedDate?: Date;
    lastAddedAmmout?: number;
    showInSets?: string[];
};

export type TSet = {
    id: number;
    name: string;
    accr: string;
    expansion: string;
    expansionAccr: string;
    size: number;
    extrasize: number;
    parentId: number;
    scardnumber: string;
    scost: string;
    cost: number;
    imageNames: string;
    cardcount: number;
    allwaysShowOrder: number;
    releasedate: Date;
};

export type TSize = {
    x: number;
    y: number;
}

export enum PricingType {
    EUR = "EUR",
    USD = "USD",
    SATS = "SATS",
  }

export type TProductTag = {
    title: string;
    value: string;
}

export enum ProductType {
    Booster = "Booster",
    Collection = "Collection",
    Misc = "Misc",
    Cards = "Cards",
    All = "All",
    Promo = "Promo",
    Promo2 = "Promo2",
    Single = "Single"
}