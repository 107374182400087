export const Nederlands : TStrings = {
    MinimalInCart:"Minimale aantal in winkelwagen",
    RemovedFromCart: "verwijderd uit winkelwagen",
    Related:"Gerelateerde artikelen",
    Hide: "Verberg",
    NoCardsAvailable: "Momenteel geen kaarten beschikbaar voor deze selectie...",
    MoreCardsSearch: "Meer kaarten beschikbaar op basis van deze zoekterm.",
    HeaderText: "De nieuwste Pokémon kaarten",
    Cards: "Kaarten",
    Collections: "Collecties",
    Misc: "Diverse",
    Shoppingcart: "Winkelwagen",
    ShoppingcartEmpty: "Je winkelwagen is nog leeg",
    PriceHighToLow: "Prijs hoog naar laag",
    PriceToLowHigh: "Prijs laag naar hoog",
    CardNumberHighToLow: "Kaart nummer hoog naar laag",
    CardNumberLowToHigh: "Kaart nummer laag naar hoog",
    SearchInAllSets: "Zoeken in alle sets",
    RecentlyAdded: "Recent toegevoegd",
    SiteText: `<h2 style="color:rgb(0, 107, 170);">Over Ons</h2>
    We weten hoe vervelend het kan zijn om net die paar kaarten te missen om een set compleet te maken. Hier willen we verzamelaars de kans geven om tegen een kleine prijs en zonder overhead die kaarten te bemachtigen.<br/>
    <br/>
    Wij zijn geen grote onderneming en hebben niet de intentie dat te worden. Dit betekend dat we u een eerlijke prijs kunnen bieden. Er komen regelmatig nieuwe kaarten bij dus kom nog eens terug als uw kaart nu niet beschikbaar is. Alle kaarten zijn nieuw (pack fresh). Alle kaarten worden netjes behandeld en verstuurd in sleeves. Bij de zeldzame kaarten voegen we een toploader toe om zeker te zijn dat de kaart in top conditie bij u aan komt.<br/>
    <br/>
    Voor vragen kunt u contact opnemen via whatsapp of e-mail. (Zie onderaan de pagina)<br/>
    <br/>
    We hopen dat we uw collectie compleet kunnen maken.<br/>
    <br/>
    Got to catch them all!!!!!!<br/>
    <br/>
    <h2 style="color:rgb(0, 107, 170);">Heb je vragen of suggesties?</h2>
    Neem gerust contact met ons op<br/>
    <br/>
    Bezoek/Post-adres:<br/>
    <span style='color:red;'>Uitsluitend op afspraak</span><br/>
    Zandtong 6<br/>
    5658 AC<br/>
    Eindhoven<br/>
    KvK NR: 58481232<br/>
    BTW NR: NL001697200B57`,
    AlgemeneVoorwaarden: "We weten hoe vervelend het kan zijn om net die paar kaarten te missen om een set compleet te maken. Hier willen we verzamelaars de kans geven om tegen een kleine prijs en zonder overhead die kaarten te bemachtigen.<br/><br/>Wij zijn geen grote onderneming en hebben niet de intentie dat te worden. Dit betekend dat we u een eerlijke prijs kunnen bieden. Er komen regelmatig nieuwe kaarten bij dus kom nog eens terug als uw kaart nu niet beschikbaar is. Alle kaarten zijn nieuw (pack fresh). Alle kaarten worden netjes behandeld en verstuurd in sleeves. Bij de zeldzame kaarten voegen we een toploader toe om zeker te zijn dat de kaart in top conditie bij u aan komt.<br/><br/>Voor vragen kunt u contact opnemen via whatsapp of e-mail. (Zie onderaan de pagina)<br/><br/>We hopen dat we uw collectie compleet kunnen maken.<br/><br/>Got to catch them all!!!!!!<br/><br/><b>Heb je vragen of suggesties?</b><br/>Neem gerust contact met ons op<br/><br/>Bezoek/Post-adres:<br/><span style='color:red;'>Uitsluitend op afspraak</span><br/>Zandtong 6<br/>5658 AC<br/>Eindhoven",
    PrivacyBeleid: `Pokemoncollectie.nl, gevestigd aan Zandtong 6 5658 AC Eindhoven, is verantwoordelijk voor de verwerking van persoonsgegevens zoals weergegeven in deze privacyverklaring.<br/>
    <h2 style="color:rgb(0, 107, 170);">Persoonsgegevens die wij verwerken</h2>
Pokemoncollectie.nl verwerkt je persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt. Hieronder vind je een overzicht van de persoonsgegevens die wij verwerken:<br/>
<br/>
- Voor- en achternaam<br/>
- Adresgegevens<br/>
- E-mailadres<br/>
- IP-adres<br/>
- Internetbrowser en apparaat type<br/>
- Bankrekeningnummer<br/>
<br/>
<h2 style="color:rgb(0, 107, 170);">Bijzondere en/of gevoelige persoonsgegevens die wij verwerken</h2>
Pokemoncollectie.nl verwerkt de volgende bijzondere en/of gevoelige persoonsgegevens van jou:<br/>

Bijzondere en/of gevoelige persoonsgegevens die wij verwerken<br/>
Onze website en/of dienst heeft niet de intentie gegevens te verzamelen over websitebezoekers die jonger zijn dan 16 jaar. Tenzij ze toestemming hebben van ouders of voogd. We kunnen echter niet controleren of een bezoeker ouder dan 16 is. Wij raden ouders dan ook aan betrokken te zijn bij de online activiteiten van hun kinderen, om zo te voorkomen dat er gegevens over kinderen verzameld worden zonder ouderlijke toestemming. Als je er van overtuigd bent dat wij zonder die toestemming persoonlijke gegevens hebben verzameld over een minderjarige, neem dan contact met ons op via contact@pokemoncollectie.nl, dan verwijderen wij deze informatie.<br/>
<h2 style="color:rgb(0, 107, 170);">Met welk doel en op basis van welke grondslag wij persoonsgegevens verwerken</h2>
Pokemoncollectie.nl verwerkt jouw persoonsgegevens voor de volgende doelen:<br/>
<br/>
- Het afhandelen van jouw betaling<br/>
- Je te informeren over wijzigingen van onze diensten en producten<br/>
- Om goederen en diensten bij je af te leveren<br/>
<h2 style="color:rgb(0, 107, 170);">Hoe lang we persoonsgegevens bewaren</h2>
Pokemoncollectie.nl bewaart je persoonsgegevens niet langer dan strikt nodig is om de doelen te realiseren waarvoor je gegevens worden verzameld. Wij hanteren de volgende bewaartermijnen voor de volgende (categorieën) van persoonsgegevens: 1 jaar<br/>
<h2 style="color:rgb(0, 107, 170);">Delen van persoonsgegevens met derden</h2>
Pokemoncollectie.nl deelt jouw persoonsgegevens met verschillende derden als dit noodzakelijk is voor het uitvoeren van de overeenkomst en om te voldoen aan een eventuele wettelijke verplichting. Pokemoncollectie.nl blijft verantwoordelijk voor deze verwerkingen. Daarnaast verstrekt Pokemoncollectie.nl jouw persoonsgegevens aan andere derden. Dit doen wij alleen met jouw nadrukkelijke toestemming.<br/>
<br/>
- Logistieke dienstverlener : Je voor-en achternaam, adres gegevens.<br/>
- Betalingsverwerker        : Je maakt direct contact met onze betalingsverwerker. Pokemoncollectie.nl staat geen gegevens op.<br/>
<h2 style="color:rgb(0, 107, 170);">Delen van persoonsgegevens met derden</h2>
Pokemoncollectie.nl verstrekt uitsluitend aan derden en alleen als dit nodig is voor de uitvoering van onze overeenkomst met jou of om te voldoen aan een wettelijke verplichting.<br/>
<h2 style="color:rgb(0, 107, 170);">Cookies, of vergelijkbare technieken, die wij gebruiken</h2>
Pokemoncollectie.nl gebruikt geen cookies of vergelijkbare technieken.<br/>
<h2 style="color:rgb(0, 107, 170);">Gegevens inzien, aanpassen of verwijderen</h2>
Je hebt het recht om je persoonsgegevens in te zien, te corrigeren of te verwijderen. Daarnaast heb je het recht om je eventuele toestemming voor de gegevensverwerking in te trekken of bezwaar te maken tegen de verwerking van jouw persoonsgegevens door Pokemoncollectie.nl en heb je het recht op gegevensoverdraagbaarheid. Dat betekent dat je bij ons een verzoek kan indienen om de persoonsgegevens die wij van jou beschikken in een computerbestand naar jou of een ander, door jou genoemde organisatie, te sturen. Je kunt een verzoek tot inzage, correctie, verwijdering, gegevensoverdraging van je persoonsgegevens of verzoek tot intrekking van je toestemming of bezwaar op de verwerking van jouw persoonsgegevens sturen naar contact@pokemoncollectie.nl. Om er zeker van te zijn dat het verzoek tot inzage door jou is gedaan, vragen wij jou een kopie van je identiteitsbewijs met het verzoek mee te sturen. Maak in deze kopie je pasfoto, MRZ (machine readable zone, de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) zwart. Dit ter bescherming van je privacy. We reageren zo snel mogelijk, maar binnen vier weken, op jouw verzoek . Pokemoncollectie.nl wil je er tevens op wijzen dat je de mogelijkheid hebt om een klacht in te dienen bij de nationale toezichthouder, de Autoriteit Persoonsgegevens. Dat kan via de volgende link: https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons<br/>
<h2 style="color:rgb(0, 107, 170);">Hoe wij persoonsgegevens beveiligen</h2>
Pokemoncollectie.nl neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op met onze klantenservice of via contact@pokemoncollectie.nl<br/>
<h2 style="color:rgb(0, 107, 170);">Hoe wij persoonsgegevens beveiligen</h2>
Pokemoncollectie.nl neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er zijn aanwijzingen van misbruik, neem dan contact op met onze klantenservice of via contact@pokemoncollectie.nl.<br/>`,
    Retourbeleid: `<h2 style="color:rgb(0, 107, 170);">Bedenktijd/retour</h2>
    Het kan wel eens voorkomen dat u een bestelling retour wilt sturen. Wellicht omdat het product toch niet bevalt of misschien is er een andere reden waarom u de bestelling toch niet zou willen hebben.<br/>
    Wat de reden ook is, u heeft het recht uw bestelling tot 30 dagen na ontvangst zonder opgave van reden te annuleren.<br/>
    <br/>
    U krijgt dan het volledige orderbedrag inclusief verzendkosten gecrediteerd. Enkel de kosten voor retour van u thuis naar de webwinkel zijn voor eigen rekening. Deze kosten bedragen circa 6,95 per pakket, raadpleeg voor de exacte tarieven de website van uw vervoerder. Indien u gebruik maakt van uw herroepingsrecht, zal het product met alle geleverde toebehoren en – indien redelijkerwijze mogelijk – in de originele staat en verpakking aan de ondernemer geretourneerd worden.<br/>
    <br/>
    Wij zullen vervolgens het verschuldigde orderbedrag binnen 14 dagen na aanmelding van uw retour terugstorten mits het product reeds in goede orde retour ontvangen is.<br/>
    <br/>Mocht het product beschadigd of de verpakking meer beschadigd zijn dan nodig is om het product te proberen, dan kunnen we deze waardevermindering van het product aan u doorberekenen. Behandel het product dus met zorg en zorg ervoor dat deze bij een retour goed verpakt is.<br/>
    <h2 style="color:rgb(0, 107, 170);">Product ruilen?</h2>
    Wilt u uw product ruilen? Dat is helemaal geen probleem.  Wel draagt u zelf de kosten voor terugzending van het product.<br/>
    <h2 style="color:rgb(0, 107, 170);">Ik heb een verkeerd bezorgadres doorgegeven!</h2>
    Per ongeluk een verkeerd bezorgadres doorgegeven? Neem dan zo snel mogelijk contact met ons op. We proberen dan de bestelling nog voor u te wijzigen.`,
    FooterText: "Heb je vragen of suggesties? Neem contact met ons op :",
    SoldoutToggle: "Toon uitverkocht",
    Animations: "Animaties",
    CardsSleeved: "Elke kaart is gesleeved",
    PicturesOnrequest: "Foto's van gewenste kaarten op aanvraag",
    TT: "Track & Trace",
    PaymentMethods: "Betaling via iDeal, Bitcoin lightning en onchain",
    BonusCards: "Gratis energies, TCG code cards bij elke bestelling",
    Available: "Beschikbaar",
    Average: "Gemiddeld",
    BuyOnMarktplaats: "Kopen via Marktplaats",
    Products: "Producten",
    SoldOut: "Uitverkocht",
    SearchInSet: "Zoeken in set",
    LoadMoreCards: "Laad meer kaarten",
    ScrollToTop: "Scroll naar boven",
    Trust: "Vertrouwen",
    BuyMarktplaatsText: "",
    HowDoesItWork: "Hoe werkt het?",
    Back: "Terug",
    SendingCost: "Verzendkosten",
    Total: "Totaal",
    PaymentNote: "De totale kosten kan wat afwijken ivm schommelingen in de BTC prijs.",
    CheckOut: "Afrekenen",
    CheckOutMainText: "Afrekenen",
    ReAddEmail: "Voer uw email nogmaals in",
    RecipientName: "Naam ontvanger",
    Postalcode: "Postcode",
    Number: "Nummer",
    Addition: "Toev.",
    Street: "Straat",
    City: "Woonplaats",
    CheckOutWithBitcoin: "Afrekenen met Bitcoin",
    CheckOutWithIDeal: "Afrekenen met iDeal",
    IDealCost: "iDeal kosten",
    KeepShopping: "Verder winkelen",
    MorePicturesOnRequest: "Meer foto's op aanvraag",
    Disclaimer1: "Alle getoonde prijzen en beschikbaarheid zijn onder voorbehoud.",
    Disclaimer2: "Alle prijzen zijn inclusief 21% btw.",
    AddToCart: "Toevoegen aan winkelwagen",
    RecentlyAddedMore1: "Er zijn recent meer kaarten toegevoegd. Gebruik",
    RecentlyAddedMore2: "om alle kaarten te zien.",
    SearchLowerCase: "zoeken",
    RecentlyAddedNone: "Er zijn recent geen kaarten toegevoegd aan deze set.",
    View: "Bekijken",
    Close: "Sluiten",
    MarktplaatsPart1: "Het is een groot goed, een cliche, maar op het internet een zonder meer waar. Kopers van Pokémon kaarten komen soms bedrogen uit op het internet en we begrijpen dat klanten kunnen aarzelen ons hun vertrouwen te gunnen.<br/><br/>Om uw vertrouwen te winnen bieden we klanten aan via <a href='https://www.marktplaats.nl/u/mark-pokemoncollectie/50043038/' target='_blank'>Marktplaats.nl</a> een bestelling te plaatsen. Dit kan met of zonder <a href='https://help.marktplaats.nl/s/article/hoe-werkt-kopersbescherming-voor-kopers' target='_blank'>kopersbescherming</a>.",
    MarktplaatsPart2: "<li>Neem contact op via 1 van onze <a href='https://www.marktplaats.nl/u/mark-pokemoncollectie/50043038/' target='_blank'>advertenties</a>.</li><li>Stuur een bericht met de kaarten die u wilt bestellen.</li><li>Geef aan of u gebruik wil maken van <a href='https://help.marktplaats.nl/s/article/hoe-werkt-kopersbescherming-voor-kopers' target='_blank'>kopersbescherming</a>.</li><li>We zullen u een betaalverzoek sturen</li><li>We versturen uw bestelling via <a href='https://www.marktplaats.nl/u/mark-pokemoncollectie/50043038/' target='_blank'>Marktplaats.nl</a>.</li>",
    MarktplaatsPart3: "* Let op: Bij bestellingen via <a href='https://www.marktplaats.nl/u/mark-pokemoncollectie/50043038/' target='_blank'>Marktplaats.nl</a> worden extra onkosten doorberekend.",
    Version: "Versie",
    FullName: "Volledige naam",
    EMailWhenAvailable: "e-Mail wanneer beschikbaar",
    AlertAdded:"Successvol toegevoegd. U krijgt een email zodra het product beschikbaar is",
    AlertFailed:"Er is iets mis gegaan bij het toevoegen van uw alert. Probeer het aub nog een keer",
    AlertTitle:"e-Mail bij beschikbaar",
    Cancel:"Annuleer",
    NotifyMe:"Notificeer me",
    ShowHow:"Laat zien hoe",
    NotAvailable:"Niet meer van op voorraad",
    AddedToCart:"toegevoegd aan winkelwagen"
}

export const English : TStrings = {
    AlgemeneVoorwaarden: "We weten hoe vervelend het kan zijn om net die paar kaarten te missen om een set compleet te maken. Hier willen we verzamelaars de kans geven om tegen een kleine prijs en zonder overhead die kaarten te bemachtigen.<br/><br/>Wij zijn geen grote onderneming en hebben niet de intentie dat te worden. Dit betekend dat we u een eerlijke prijs kunnen bieden. Er komen regelmatig nieuwe kaarten bij dus kom nog eens terug als uw kaart nu niet beschikbaar is. Alle kaarten zijn nieuw (pack fresh). Alle kaarten worden netjes behandeld en verstuurd in sleeves. Bij de zeldzame kaarten voegen we een toploader toe om zeker te zijn dat de kaart in top conditie bij u aan komt.<br/><br/>Voor vragen kunt u contact opnemen via whatsapp of e-mail. (Zie onderaan de pagina)<br/><br/>We hopen dat we uw collectie compleet kunnen maken.<br/><br/>Got to catch them all!!!!!!<br/><br/><b>Heb je vragen of suggesties?</b><br/>Neem gerust contact met ons op<br/><br/>Bezoek/Post-adres:<br/><span style='color:red;'>Uitsluitend op afspraak</span><br/>Zandtong 6<br/>5658 AC<br/>Eindhoven",
    PrivacyBeleid: "We weten hoe vervelend het kan zijn om net die paar kaarten te missen om een set compleet te maken. Hier willen we verzamelaars de kans geven om tegen een kleine prijs en zonder overhead die kaarten te bemachtigen.<br/><br/>Wij zijn geen grote onderneming en hebben niet de intentie dat te worden. Dit betekend dat we u een eerlijke prijs kunnen bieden. Er komen regelmatig nieuwe kaarten bij dus kom nog eens terug als uw kaart nu niet beschikbaar is. Alle kaarten zijn nieuw (pack fresh). Alle kaarten worden netjes behandeld en verstuurd in sleeves. Bij de zeldzame kaarten voegen we een toploader toe om zeker te zijn dat de kaart in top conditie bij u aan komt.<br/><br/>Voor vragen kunt u contact opnemen via whatsapp of e-mail. (Zie onderaan de pagina)<br/><br/>We hopen dat we uw collectie compleet kunnen maken.<br/><br/>Got to catch them all!!!!!!<br/><br/><b>Heb je vragen of suggesties?</b><br/>Neem gerust contact met ons op<br/><br/>Bezoek/Post-adres:<br/><span style='color:red;'>Uitsluitend op afspraak</span><br/>Zandtong 6<br/>5658 AC<br/>Eindhoven",
    Retourbeleid: "We weten hoe vervelend het kan zijn om net die paar kaarten te missen om een set compleet te maken. Hier willen we verzamelaars de kans geven om tegen een kleine prijs en zonder overhead die kaarten te bemachtigen.<br/><br/>Wij zijn geen grote onderneming en hebben niet de intentie dat te worden. Dit betekend dat we u een eerlijke prijs kunnen bieden. Er komen regelmatig nieuwe kaarten bij dus kom nog eens terug als uw kaart nu niet beschikbaar is. Alle kaarten zijn nieuw (pack fresh). Alle kaarten worden netjes behandeld en verstuurd in sleeves. Bij de zeldzame kaarten voegen we een toploader toe om zeker te zijn dat de kaart in top conditie bij u aan komt.<br/><br/>Voor vragen kunt u contact opnemen via whatsapp of e-mail. (Zie onderaan de pagina)<br/><br/>We hopen dat we uw collectie compleet kunnen maken.<br/><br/>Got to catch them all!!!!!!<br/><br/><b>Heb je vragen of suggesties?</b><br/>Neem gerust contact met ons op<br/><br/>Bezoek/Post-adres:<br/><span style='color:red;'>Uitsluitend op afspraak</span><br/>Zandtong 6<br/>5658 AC<br/>Eindhoven",
    RemovedFromCart: "removed from shopping cart",
    NotAvailable:"No more of available",
    AddedToCart:"added to shopping cart",
    Version: "Version",
    MarktplaatsPart1: "It's hard earned, very true but makes it hard for startups got gain footing. Buyers of Pokémon cards sometimes have a hard time finding a trustworthy source, scams are prevelent and we understand that potential customers have a hard time trusting our site.<br/><br/>To win your trust we offer customers the posibility of purchising our products through <a href='https://www.marktplaats.nl/u/mark-pokemoncollectie/50043038/' target='_blank'>Marktplaats.nl</a>. There you have the posibility to use <a href='https://help.marktplaats.nl/s/article/hoe-werkt-kopersbescherming-voor-kopers' target='_blank'>kopersbescherming</a>.",
    MarktplaatsPart2: "<li>Contact us through one of our <a href='https://www.marktplaats.nl/u/mark-pokemoncollectie/50043038/' target='_blank'>advertisements</a>.</li><li>Send a message with the cards you like to order.</li><li>Decide if you want to make use of <a href='https://help.marktplaats.nl/s/article/hoe-werkt-kopersbescherming-voor-kopers' target='_blank'>kopersbescherming</a>.</li><li>We will send you a payment request</li><li>We will send your order through <a href='https://www.marktplaats.nl/u/mark-pokemoncollectie/50043038/' target='_blank'>Marktplaats.nl</a>.</li>",
    MarktplaatsPart3: "* Attention: Orders placed at <a href='https://www.marktplaats.nl/u/mark-pokemoncollectie/50043038/' target='_blank'>Marktplaats.nl</a> may have additional costs.",
    Close: "Close",
    Disclaimer1: "All prices and availability are subject to change.",
    Disclaimer2: "All prices are including 21% VAT.",
    NoCardsAvailable: "Currently no cards available for this selection...",
    MoreCardsSearch: "There are more cards availble...",
    HeaderText: "The newest Pokémon cards",
    Cards: "Cards",
    Collections: "Collections",
    Misc: "Miscellaneous",
    Shoppingcart: "Shopping cart",
    ShoppingcartEmpty: "Your shopping cart is empty",
    PriceHighToLow: "Price hight to low",
    PriceToLowHigh: "Price low to high",
    CardNumberHighToLow: "Card number high to low",
    CardNumberLowToHigh: "Card number low to high",
    SearchInAllSets: "Search in all sets",
    RecentlyAdded: "Recently added",
    SiteText: "We know from experience how frustrating it can be to miss those last few cards to complete a set. With this site we want to offer collectors the chance to complete their sets, for a fair price without unnessesary overhead costs.<br/><br/>New cards are added regularly so please visit again if your desired cards are currently not available. All our cards are pack fresh and not been played with. All cards are handeled with great care and are send in sleeves.<br/><br/>In case of any questions you can contact us through whatsapp or e-mail. (See links at bottom of page)<br/><br/>We hope we can complete your collection.<br/><br/>Got to catch 'em all!!!!!!!<br/><br/><b>Please contact us with any questions or suggestions.",
    FooterText: "Do you have questions or remarks? Please contact us through :",
    SoldoutToggle: "Show sold out",
    Animations: "Animations",
    CardsSleeved: "All cards are sleeved",
    PicturesOnrequest: "Additional pictures on request",
    TT: "Track & Trace",
    PaymentMethods: "Payment with iDeal, Bitcoin onchain or lightning",
    BonusCards: "Free energies, TCG code cards with every order",
    Average: "Average",
    Available: "Available",
    BuyOnMarktplaats: "Buy through Marktplaats",
    Products: "Products",
    SoldOut: "Sold out",
    SearchInSet: "Search in set",
    LoadMoreCards: "Load more cards",
    ScrollToTop: "Scroll to top",
    Trust: "Trust",
    BuyMarktplaatsText: "",
    HowDoesItWork: "How does it work?",
    Back: "Back",
    SendingCost: "Sending cost",
    Total: "Total",
    PaymentNote: "The total cost can deviate a bit because of price de/in-preciation of Bitcoin",
    CheckOut: "Check out",
    CheckOutMainText: "Checking out",
    ReAddEmail: "Add your email again",
    RecipientName: "Name recipient",
    Postalcode: "Postal code",
    Number: "Number",
    Addition: "Addi.",
    Street: "Street",
    City: "City",
    CheckOutWithBitcoin: "Pay with Bitcoin",
    CheckOutWithIDeal: "Pay with iDeal",
    IDealCost: "iDeal cost",
    KeepShopping: "Back to shop",
    MorePicturesOnRequest: "More pictures on request",
    AddToCart: "Add to cart",
    RecentlyAddedMore1: "Recently there were more cards added. Use",
    RecentlyAddedMore2: "to view all cards.",
    SearchLowerCase: "search",
    RecentlyAddedNone: "Recently there were no new cards added to this set.",
    View: "View",
    FullName: "Full Name",
    EMailWhenAvailable: "e-Mail when avaiable",
    AlertAdded:"Alert successfully added. You will receive an email when the product is available",
    AlertFailed:"Something went wrong. Please try again",
    AlertTitle:"Create e-mail Alert",
    Cancel:"Cancel",
    NotifyMe:"Create alert",
    ShowHow:"Show how",
    Hide:"Hide",
    Related:"Related products",
    MinimalInCart:"Minimal ammount in cart"
}

export type TStrings = {
    AlgemeneVoorwaarden: string;
    PrivacyBeleid: string;
    Retourbeleid: string;
    MinimalInCart: string;
    RemovedFromCart: string;
    Related:string;
    NotAvailable:string;
    AddedToCart:string;
    Hide:string;
    ShowHow:string;
    AlertTitle:string;
    NotifyMe:string;
    Cancel: string;
    AlertAdded:string;
    AlertFailed:string;
    EMailWhenAvailable:string;
    FullName:string;
    Version: string;
    MarktplaatsPart1: string;
    MarktplaatsPart2: string;
    MarktplaatsPart3: string;
    Close: string;
    View: string;
    RecentlyAddedNone: string;
    RecentlyAddedMore1: string;
    SearchLowerCase: string;
    RecentlyAddedMore2: string;
    AddToCart: string;
    Disclaimer1: string;
    Disclaimer2: string;
    NoCardsAvailable: string;
    MoreCardsSearch: string;
    HeaderText: string;
    Cards: string;
    Collections: string;
    Misc: string;
    Shoppingcart: string;
    ShoppingcartEmpty: string;
    PriceHighToLow: string;
    PriceToLowHigh: string;
    CardNumberHighToLow: string;
    CardNumberLowToHigh: string;
    SearchInAllSets: string;
    RecentlyAdded: string;
    SiteText: string;
    FooterText: string;
    SoldoutToggle: string;
    Animations: string;
    CardsSleeved: string;
    PicturesOnrequest: string;
    TT: string;
    PaymentMethods: string;
    BonusCards: string;
    Average: string;
    Available: string;
    BuyOnMarktplaats: string;
    Products: string;
    SoldOut: string;
    SearchInSet: string;
    LoadMoreCards: string;
    ScrollToTop: string;
    Trust: string;
    BuyMarktplaatsText: string;
    HowDoesItWork: string;
    Back: string;
    SendingCost: string;
    Total: string;
    PaymentNote: string;
    CheckOut: string;
    CheckOutMainText: string;
    ReAddEmail: string;
    RecipientName: string;
    Postalcode: string;
    Number: string;
    Addition: string;
    Street: string;
    City: string;
    CheckOutWithBitcoin: string;
    CheckOutWithIDeal: string;
    IDealCost: string;
    KeepShopping: string;
    MorePicturesOnRequest: string;
}