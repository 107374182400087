import { TStrings } from "../constants/languages/lang";
import { GetConfiguration } from "../services/DBConnect";

class GlobalProperties {
    public Init(data,refresh:Function) {
        this._refresh = refresh;
        data.forEach(d => {
            switch (d.name) {
                case "HIDDEN_SETS":
                    this._HIDDEN_SETS = d.value;
                    break;
                case "selectedBaseSetKey":
                    this._selectedBaseSetKey = Number(d.value);
                    break;
                case "selectedSetKey":
                    this._selectedSetKey = Number(d.value);
                    break;
                case "CAROUSEL_PROMO_SET_ID":
                    this._CAROUSEL_PROMO_SET_ID = d.value;
                    break;
                case "PROMO1_IMAGE_URL":
                    this._PROMO1_IMAGE_URL = d.value;
                    break;
                case "PROMO2_IMAGE_URL":
                    this._PROMO2_IMAGE_URL = d.value;
                    break;
                case "CONFIG_PROMO2_IMAGE_URL":
                    this._CONFIG_PROMO2_IMAGE_URL = d.value;
                    break;
                case "selectedSetName":
                    this._selectedSetName = d.value;
                    break;
                case "SITE_MESSAGE":
                    this._SITE_MESSAGE = d.value;
                    break;
                case "SITE_MESSAGE_START_DATE":
                    this._SITE_MESSAGE_START_DATE = new Date(d.value);
                    break;
                case "SITE_MESSAGE_END_DATE":
                    this._SITE_MESSAGE_END_DATE = new Date(d.value);
                    break;
                case "MainCarouselSets":
                    const parts = d.value.split(',');
                    let mcs: number[] = [];
                    parts.forEach(p => {
                        mcs.push(Number(p));
                    });
                    this.MainCarouselSets = mcs;
                    break;
                case "Test":
                    this._Test = d.value;
                    break;
                default:
                    break;
            }
        });
        this._initDone = true;
        this._Callbacks.forEach(f => {
            f();
        });
    }

    private _Callbacks: Function[] = [];
    set Callback(f: Function) {
        this._Callbacks.push(f);
        if (this.InitDone) {
            f();
        }
    }

    private _IsDebug: boolean;
    get IsDebug(): boolean {
        return this._IsDebug;
    }
    set IsDebug(value:boolean) {
        this._IsDebug = value;
    }

    private _MainCarouselSets: number[];
    get MainCarouselSets(): number[] {
        return this._MainCarouselSets;
    }
    set MainCarouselSets(value:number[]) {
        this._MainCarouselSets = value;
    }

    private _pageWidth: number;
    get PageWidth(): number {
        return this._pageWidth;
    }
    set PageWidth(value: number) {
        this._pageWidth = value;
    }

    private _isMobile: boolean;
    get IsMobile(): boolean {
        return this._isMobile;
    }
    set IsMobile(value: boolean) {
        this._isMobile = value;
    }

    private _showMessage: Function;
    get ShowMessage(): Function {
        return this._showMessage;
    }
    set ShowMessage(showMessage: Function) {
        this._showMessage = showMessage;
    }

    private _Test: any;
    get Test(): any {
        return this._Test;
    }

    private _ToastMessage: string;
    get ToastMessage(): string {
        return this._ToastMessage;
    }
    set ToastMessage(message:string) {
        this._ToastMessage = message;
    }

    private _SITE_MESSAGE_START_DATE: Date;
    get SITE_MESSAGE_START_DATE() : Date {
        return this._SITE_MESSAGE_START_DATE;
    }

    private _SITE_MESSAGE_END_DATE: Date;
    get SITE_MESSAGE_END_DATE() : Date {
        return this._SITE_MESSAGE_END_DATE;
    }

    private _refresh: Function;
    get Refresh() : Function {
        return this._refresh;
    }

    private _lang : TStrings;
    get Strings(): TStrings {
        return this._lang;
    }
    set Strings(val: TStrings) {
        this._lang = val;
    }

    private _initDone : boolean = false;
    get InitDone(): boolean {
        return this._initDone;
    }

    private _SITE_MESSAGE : string;
    get SITE_MESSAGE(): string {
        return this._SITE_MESSAGE;
    }
    
    private _HIDDEN_SETS : string;
    get HIDDEN_SETS(): string {
        return this._HIDDEN_SETS;
    }

    private _selectedBaseSetKey : number;
    get selectedBaseSetKey(): number {
        return this._selectedBaseSetKey;
    }
    set selectedBaseSetKey(value:number){
        this._selectedBaseSetKey = value;
    }

    private _selectedSetKey : number;
    get selectedSetKey(): number {
        return this._selectedSetKey;
    }
    set selectedSetKey(value:number){
        this._selectedSetKey = value;
    }

    private _selectedSetName : string;
    get selectedSetName(): string {
        return this._selectedSetName;
    }
    set selectedSetName(value:string){
        this._selectedSetName = value;
    }

    private _CAROUSEL_PROMO_SET_ID : string;
    get CAROUSEL_PROMO_SET_ID(): string {
        return this._CAROUSEL_PROMO_SET_ID;
    }
    
    private _PROMO1_IMAGE_URL : string;
    get PROMO1_IMAGE_URL(): string {
        return this._PROMO1_IMAGE_URL;
    }

    private _PROMO2_IMAGE_URL : string;
    get PROMO2_IMAGE_URL(): string {
        return this._PROMO2_IMAGE_URL;
    }

    private _CONFIG_PROMO2_IMAGE_URL : string;
    get CONFIG_PROMO2_IMAGE_URL(): string {
        return this._CONFIG_PROMO2_IMAGE_URL;
    }
}

export default new GlobalProperties();