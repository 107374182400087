import { Image, ImageFit, Label, Stack} from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';


export interface IPromo2ComponentProps {
    windowWidth: number;
    satprice: number;
    eurprice: number;
}

export interface IPromo2ComponentState {

};
  

export default class Promo2Component extends React.Component<IPromo2ComponentProps, IPromo2ComponentState> {
    constructor(props: IPromo2ComponentProps) {
        super(props);
    }

    public render(): React.ReactElement<IPromo2ComponentProps> {
        return (   
            <Stack horizontalAlign='center' verticalAlign='start' tokens={{padding:5}}>
                <Image src={`${process.env.PUBLIC_URL}/assets/Promo/SV_TemporalForces/1.webp`} alt="" imageFit={ImageFit.cover} />
                <Stack className={styles.aPanel}>
                    <Stack horizontalAlign='center'>
                        {/* <Stack horizontal horizontalAlign='center'>
                            <Image style={{maxWidth:300}} src={`${process.env.PUBLIC_URL}/assets/promo/sv_temporalforces/sv05_logo_169_en.webp`} alt="" imageFit={ImageFit.cover} />
                        </Stack> */}
                        <br/>
                        <Label style={{fontSize:'normal',fontFamily: "Flexo-Demi"}}>Krachten uit het verleden en de toekomst volharden!<br/>
De gelederen van Ancient en Future Pokémon blijven groeien! Walking Wake ex bevrijdt zichzelf van het verleden naast Raging Bolt ex, terwijl Iron Leaves ex hoogtechnologische gerechtigheid brengt met Iron Crown ex. Buiten Area Zero veranderen Wugtrio en Farigiraf van type als Tera Pokémon ex. Pokémon Trainers bereiden zich voor op de terugkeer van ACE SPEC-kaarten met unieke en krachtige effecten. Een scheur in de tijd brengt wilde beesten en cybervisies naar het gevecht in Pokémon TCG: Scarlet & Violet – Temporal Forces.</Label>
                    </Stack>
                    
                    <Stack horizontal horizontalAlign='center'>
                        <Image style={{maxWidth:100}} src={`${process.env.PUBLIC_URL}/assets/promo/sv_temporalforces/sv05_logo_169_en.webp`} alt="" imageFit={ImageFit.cover} />
                    </Stack>

                    <Stack horizontal horizontalAlign='center'>
                        <Image style={{maxWidth:200}} src={`${process.env.PUBLIC_URL}/assets/promo/sv_temporalforces/p1.webp`} alt="" imageFit={ImageFit.cover} />
                        {this.props.windowWidth > 599 && <Image style={{maxWidth:200}} src={`${process.env.PUBLIC_URL}/assets/promo/sv_temporalforces/p2.webp`} alt="" imageFit={ImageFit.cover} />}
                        {this.props.windowWidth > 599 && <Image style={{maxWidth:200}} src={`${process.env.PUBLIC_URL}/assets/promo/sv_temporalforces/p3.webp`} alt="" imageFit={ImageFit.cover} />}
                        {this.props.windowWidth > 599 && <Image style={{maxWidth:200}} src={`${process.env.PUBLIC_URL}/assets/promo/sv_temporalforces/p4.webp`} alt="" imageFit={ImageFit.cover} />}
                        <Stack>
                            <p className={styles.cardpriceEUR} style={{marginLeft:-2,marginTop:3}}>€ 5,75</p>      
                            <p className={styles.cardpriceBTC} style={{marginLeft:-2,marginTop:3}}>{Math.round((5.75 / this.props.eurprice) / this.props.satprice)} sats</p>     
                        </Stack>  
                    </Stack>

                    <Stack horizontal horizontalAlign='center'>
                        <Image style={{maxWidth:100}} src={`${process.env.PUBLIC_URL}/assets/promo/sv_temporalforces/sv05_logo_169_en.webp`} alt="" imageFit={ImageFit.cover} />
                    </Stack>

                    <Stack horizontal horizontalAlign='center'>
                        <Image style={{maxWidth:400}} src={`${process.env.PUBLIC_URL}/assets/promo/sv_temporalforces/s1.webp`} alt="" imageFit={ImageFit.cover} />
                        {this.props.windowWidth > 599 && <Image style={{maxWidth:400}} src={`${process.env.PUBLIC_URL}/assets/promo/sv_temporalforces/s2.webp`} alt="" imageFit={ImageFit.cover} />}
                        <Stack>
                            <p className={styles.cardpriceEUR} style={{marginLeft:-2}}>€ 49,99</p>
                            <p className={styles.cardpriceBTC} style={{marginLeft:-2,marginTop:3}}>{Math.round((49.99 / this.props.eurprice) / this.props.satprice)} sats</p> 
                        </Stack> 
                    </Stack>

                    {/* <Stack horizontal horizontalAlign='center' tokens={{childrenGap:20}}>
                        <Image style={{maxWidth:400}} src={`${process.env.PUBLIC_URL}/assets/promo/sv_temporalforces/3.webp`} alt="" imageFit={ImageFit.cover} />
                    </Stack> */}

                    <Stack horizontal horizontalAlign='center'>
                        <Image style={{maxWidth:100}} src={`${process.env.PUBLIC_URL}/assets/promo/sv_temporalforces/sv05_logo_169_en.webp`} alt="" imageFit={ImageFit.cover} />
                    </Stack>

                    <Stack horizontal horizontalAlign='center' tokens={{childrenGap:20}}>
                        <Image style={{maxWidth:300}} src={`${process.env.PUBLIC_URL}/assets/promo/sv_temporalforces/ss1.webp`} alt="" imageFit={ImageFit.cover} />
                        <Stack>
                            <p className={styles.cardpriceEUR} style={{marginLeft:-2}}>€ 39,99</p>
                            <p className={styles.cardpriceBTC} style={{marginLeft:-2,marginTop:3}}>{Math.round((39.99 / this.props.eurprice) / this.props.satprice)} sats</p> 
                        </Stack> 
                    </Stack>

                    <Stack horizontal horizontalAlign='center'>
                        <Image style={{maxWidth:100}} src={`${process.env.PUBLIC_URL}/assets/promo/sv_temporalforces/sv05_logo_169_en.webp`} alt="" imageFit={ImageFit.cover} />
                    </Stack>
                </Stack>
            </Stack>
        );
    }
}