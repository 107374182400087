import { Stack, Image, ImageFit, Label, FontIcon, IStackTokens } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';
import { TProduct, TSize } from '../constants/types';
import Product from './productComponent';
import GlobalProperties from '../helpers/GlobalProperties';

export interface IproductenComponentProps {
    satprice: number;
    eurprice: number;
    windowSize: TSize;
    onImageClick: Function;
    isdev: boolean;
    onBuyClick: Function;
    products : TProduct[];
    showMinimize?: boolean;
    SetSelectedProductForAlert?: Function;
    AlertCoachmarkVisible: boolean;
}

export interface IproductenComponentState {
};
  

export default class Producten extends React.Component<IproductenComponentProps, IproductenComponentState> {
    private productTokens: IStackTokens = {
        childrenGap: 10,
        padding: 5
    };

    constructor(props: IproductenComponentProps) {
        super(props);
    }

    public render(): React.ReactElement<IproductenComponentProps> {
        return (   
            <Stack className={styles.mainSection}>
                <Stack horizontal style={{width:"100%", cursor:this.props.showMinimize?"pointer":"cursor"}}>
                    <Stack horizontal className={styles.boosterLabelMain}>
                        <Label className={styles.boosterLabel}>{GlobalProperties.Strings.Related}</Label>
                    </Stack>
                </Stack> 
                {this.props.products.length   && <Stack horizontal wrap tokens={this.productTokens} horizontalAlign='start' key={this.props.products.length ? this.props.products[0].id : 1}>
                                                    {this.props.products.map(product => {
                                                        return  <div style={{maxWidth:this.props.windowSize.x < 960 ? 430 : ""}}>
                                                                    <Product hideSwipe={this.props.windowSize.x < 800 ? false : true} 
                                                                        SetSelectedProductForAlert={this.props.SetSelectedProductForAlert}
                                                                        AlertCoachmarkVisible={this.props.AlertCoachmarkVisible}
                                                                        onBuyClick={this.props.onBuyClick} 
                                                                        imageSize={{x: this.props.windowSize.x <= 960 ? 280 : 430, y: this.props.windowSize.x <= 960 ? 220 : 500}} 
                                                                        eurprice={this.props.eurprice} 
                                                                        satprice={this.props.satprice}
                                                                        onImageClick={this.props.onImageClick} 
                                                                        product={product} />
                                                                </div>
                                                    })}
                                                </Stack>}
                                    
            </Stack>
        );
    }
}