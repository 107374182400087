import { DefaultButton, Dialog, IContextualMenuItem, IStackTokens, IconButton, Image, ImageFit, Label, Stack, TextField, Toggle} from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';
import Header from './headerComponent';
import Footer from './footerComponent';
import { TProduct, TShoppingCart, TSize } from '../constants/types';
import PokemonCards from './cardsComponent';
import { SearchCards } from '../services/DBConnect';
import Shoppingcart from './shoppingcartComponent';
import { NavLink, NavigateFunction, useNavigate } from 'react-router-dom';
import { NavHome } from './navigate';
import { Nederlands } from '../constants/languages/lang';
import GlobalProperties from '../helpers/GlobalProperties';


export interface ISearchComponentProps {
    UpdateStateExtern: Function;
    windowSize: TSize;
    animationsEnabled: boolean;
    eurprice: number;
    satprice: number;
    onBuyClick: Function;
    onImageClick: Function;
    isDev: boolean;
    usdprice: number;
    allMenu: IContextualMenuItem[];
    shoppingkart: TShoppingCart;
    onPreCheckOut: Function;
    onSmallCartClick: Function;
    navigate: NavigateFunction;
    AlertCoachmarkVisible: boolean;
    showMessage: Function;
}

export interface ISearchComponentState {
    searchText: string;
    pageCardSet: TProduct[];
    numberOfPages: number;
    pageIndex: number;
    showAvailableOnly: boolean;
    searchReady:boolean;
    storedSearchString:string;
    popupUrl?:string;
};

class SearchComponent extends React.Component<ISearchComponentProps, ISearchComponentState> {
    private cardTokens: IStackTokens = {
        childrenGap: 10,
        padding: 5
    };

    constructor(props: ISearchComponentProps) {
        super(props);

        this.toggleAvailable = this.toggleAvailable.bind(this);
        this.onImageClick = this.onImageClick.bind(this);
        this.onCheckOut = this.onCheckOut.bind(this);
        this.onSmallCartClick = this.onSmallCartClick.bind(this);

        this.state = {
            searchText: "",
            pageCardSet: [],
            numberOfPages: 0,
            pageIndex: 0,
            showAvailableOnly: true,
            searchReady: true,
            storedSearchString: "",
        };
    }

    private onSearchBoxChange(searchText?: string | undefined) {
        this.setState({searchText: searchText});
        if (this.state.searchReady === false) {
            setTimeout(() => {
                this.onSearchBoxChange(searchText);
            }, 200);
        } else {
            this.setState({searchReady: false});
                SearchCards(searchText, this.state.showAvailableOnly, this.props.isDev).then(cards => {
                cards.forEach(card => {
                    if (card.number > 99) {
                        card.scardnumber = `${card.number}`;
                    } else if (card.number > 9) {
                        card.scardnumber = `0${card.number}`;
                    } else {
                        card.scardnumber = `00${card.number}`;
                    }
                    card.scardnumber += `/${card.size}`;

                    //TODO: move to backend, minimale prijs toevoegen
                    card.priceInUSD = Math.round(card.priceInUSD * 100) /100;
                    card.cost = Math.round(card.priceInUSD / this.props.satprice);
                    card.scost = `${card.cost}`;
                    let imageUrls: string[] = [];
                    card.imageNames.split(',').forEach(imageName => {
                        imageUrls.push(`${process.env.PUBLIC_URL}/assets/pokemon/${card.setID}/${card.number.toString()}/${imageName}`);
                    });
                    imageUrls.push(`${process.env.PUBLIC_URL}/assets/pokemon/cardback.webp`);
                    card.imageUrls = imageUrls;
                });

                cards.sort((a,b) => a.priceInUSD < b.priceInUSD ? 1 : -1);

                this.setState({pageCardSet: cards, searchReady: true});
            }).catch(err => {
                console.log(err);
                this.setState({searchReady: true});
            });
        }
    }

    private toggleAvailable() {
        this.setState({showAvailableOnly: !this.state.showAvailableOnly}, () => {
            this.onSearchBoxChange(this.state.searchText);
        });
    }

    private onImageClick(url:string) {
        this.setState({popupUrl: url});
    }

    private onCheckOut() {
        this.props.navigate('/');
        this.props.onPreCheckOut();
    }

    private onSmallCartClick() {
        this.props.navigate('/');
        this.props.onSmallCartClick();
    }

    public render(): React.ReactElement<ISearchComponentProps> {
        return (   
            <div className={styles.mainDiv}>
                {/* PopUp image popup*/}
                <Dialog
                    hidden={!this.state.popupUrl}
                    modalProps={{isBlocking: false, styles: { main : { maxHeight: this.props.windowSize.y - ((this.props.windowSize.y/100) *10), width: "100%", maxWidth: "90%!important", backgroundColor: "transparent", boxShadow: "none", overflowY:"visible", overflowX:"visible"}}}}
                    onDismiss={() => {this.setState({popupUrl : undefined})}}
                >
                    <Stack horizontalAlign='center'>
                        <Image className={styles.popupImage}
                            style={{maxHeight: this.props.windowSize.y - ((this.props.windowSize.y/100) * 20)}}
                            // onTouchMove={this.onMouseOverImg}
                            onError={(e) => { e.currentTarget.onerror = null;
                                              e.currentTarget.src=`${process.env.PUBLIC_URL}/assets/NothingFound.webp`}}
                            onClick={() => {this.setState({popupUrl : undefined})}}
                            src={this.state.popupUrl}
                            alt=""
                            //onMouseMove={this.onMouseOverImg}
                        />
                    </Stack>
                </Dialog>
                <Stack horizontalAlign='center'>
                    <Header />
                    <div className={styles.mainSection}>
                        <div className={styles.headerMainMenu} style={{overflowY:'visible'}}>
                            <Stack horizontal style={{width:"100%"}}  >
                                <div className={styles.mainMenuItems}>
                                    <Stack horizontal horizontalAlign='start' tokens={this.cardTokens}>
                                        <NavLink to={'/'}>
                                            <Image className={styles.homeIcon} src={`${process.env.PUBLIC_URL}/assets/Home.webp`} alt="Menu" />
                                        </NavLink>
                                    </Stack>
                                </div>
                                <Stack horizontal style={{width:"100%"}} horizontalAlign='center'>
                                    <div className={`${styles.mainMenuItems} ${styles.searchBox}`}>
                                        <Stack horizontal tokens={this.cardTokens}>
                                            <TextField  type="Search"
                                                        iconProps={{
                                                            iconName:'search',
                                                            onClick:()=> {this.onSearchBoxChange(this.state.searchText)}
                                                        }}
                                                        className={styles.searchBox}
                                                        value={this.state.searchText}
                                                        placeholder="Kaart zoeken"
                                                        onChange={(e,newvalue) => this.onSearchBoxChange(newvalue)} />
                                        </Stack>
                                    </div>
                                    <div className={`${styles.mainMenuItems} ${styles.mainMenuItemsBigRes}`}>
                                        <Stack tokens={this.cardTokens} className={`${styles.mainMenuItemsBigRes}`}>
                                            
                                        </Stack>
                                    </div>
                                </Stack>
                            </Stack>
                        </div>
                        <div className={`${styles.divTable}`}>
                            <div className={styles.divTableRow}>
                                <div className={`${styles.divTableCell} ${styles.cardsSection}`}>
                                    <Stack style={{whiteSpace:"nowrap",paddingTop:5,paddingLeft:5}} verticalAlign='center' horizontalAlign='start' horizontal>
                                        <Toggle label={GlobalProperties.Strings.SoldoutToggle} checked={!this.state.showAvailableOnly} inlineLabel onChange={this.toggleAvailable} />
                                    </Stack>
                                    {this.state.pageCardSet.length ?
                                        <Stack className={styles.mainSection}>
                                            <Stack horizontal style={{width:"100%", cursor:"pointer"}} > {/* onClick={() => {this.setState({showcards: !this.state.showcards})}}> */}
                                                <Stack horizontal className={styles.boosterLabelMain}>
                                                    <Stack>
                                                        <Label className={styles.boosterLabel} >{GlobalProperties.Strings.Cards}</Label>
                                                    </Stack>
                                                </Stack>
                                            </Stack>
                                            <Stack horizontal wrap tokens={this.cardTokens} horizontalAlign='start' style={{maxWidth:""}}>
                                                {this.state.pageCardSet.map(card => {
                                                    return <PokemonCards hideSwipe={this.props.windowSize.x < 800 ? false : true} 
                                                                         AlertCoachmarkVisible={this.props.AlertCoachmarkVisible}
                                                                         eurprice={this.props.eurprice} 
                                                                         animationsEnabled={this.props.animationsEnabled} 
                                                                         card={card} 
                                                                         onBuyClick={this.props.onBuyClick} 
                                                                         satprice={this.props.satprice} 
                                                                         onImageClick={this.onImageClick}/>
                                                })}
                                            </Stack>
                                            <br/>
                                            <Stack horizontal horizontalAlign='center' tokens={this.cardTokens}>
                                                {this.state.pageCardSet.length >= 49 &&
                                                <Label>{GlobalProperties.Strings.MoreCardsSearch}</Label>}
                                            </Stack>
                                        </Stack>
                                        : <Stack horizontalAlign='center'>
                                            <Image src={process.env.PUBLIC_URL + `/assets/NothingFound.webp`} alt="" imageFit={ImageFit.contain} style={{height:300}}/>
                                            </Stack>}
                                </div>
                                <div className={`${styles.divTableCell}`} >
                                    <div className={`${styles.shoppingcart}`} style={{float:"right"}}>
                                        <Stack style={{width:"100%"}} horizontalAlign='end' >
                                            <Shoppingcart eurprice={this.props.eurprice} 
                                                          showPopup={this.onImageClick} 
                                                          ShowMessage={this.props.showMessage}
                                                          satprice={this.props.satprice} 
                                                          usdprice={this.props.usdprice} 
                                                          shoppingcart={this.props.shoppingkart} 
                                                          windowsSize={this.props.windowSize}
                                                          onCheckOut={this.onCheckOut} 
                                                          onSmallCartClick={this.onSmallCartClick}/>
                                            
                                            <div className={`${styles.setLogoContainer}`}>
                                                <Stack style={{width:"100%"}} horizontalAlign='center' horizontal>
                                                    <IconButton iconProps={{iconName: 'AddNotes'}} styles={{
                                                        icon: {color: 'blue', fontSize: this.props.windowSize.x > 960 ? 28 : 14},
                                                        root: {
                                                            margin: 0,
                                                            padding: 0,
                                                            selectors: {
                                                            '& .ms-Button-icon:hover, .ms-Button-flexContainer:hover': {
                                                                backgroundColor: 'lightblue',
                                                                color: 'darkblue'
                                                            },
                                                            }
                                                        },
                                                        }} title="Meer" src={`${process.env.PUBLIC_URL}/assets/Hamburger_icon.svg.webp`} 
                                                        menuProps={{items: this.props.allMenu, shouldFocusOnMount: true, subMenuHoverDelay: 250}} />
                                                </Stack>
                                            </div>
                                        </Stack>
                                    </div>
                                </div>
                            </div>
                            <Footer />
                        </div>
                    </div>
                </Stack>
            </div>
        );
    }
}

function Search(props) {
    let navigate = useNavigate();
    return <SearchComponent {...props} navigate={navigate} />
}

export default Search;