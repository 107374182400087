import { IStackTokens, Stack, Image, ImageFit, Label, FontIcon } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';
import { TProduct, TSize } from '../constants/types';
import { GetCard, GetProduct } from '../services/DBConnect';
import { ProcessProducts } from '../helpers/General';
import GlobalProperties from '../helpers/GlobalProperties';
import { AlertButtonComponent } from './alertButtonComponent';
import PulseLabelv2 from './pulseLabelv2Component';
import { NavLink } from 'react-router-dom';

export interface IProductViewerComponentProps {
    allProducts: TProduct[];
    windowSize: TSize;
    onBuyClick: Function;
    EurPrice: number;
    SatPrice: number;
    AlertCoachmarkVisible: boolean;
    SetSelectedProductForAlert?: Function;
    onImageClick: Function;
    ShowToast: Function;
    SetSelectedSet?: Function;
}

export interface IProductViewerComponentState {
    popupUrl?: string;
    product?: TProduct;
    card?: TProduct;
    added?: boolean;
    notAdded?: boolean;
};

class ProductViewerComponent extends React.Component<IProductViewerComponentProps, IProductViewerComponentState> {
    private cardTokens: IStackTokens = {
        childrenGap: 10,
        padding: 5
    };

    constructor(props: IProductViewerComponentProps) {
        super(props);
        console.warn = console.error = () => { }; //disable warnings

        this.onImageClick = this.onImageClick.bind(this);

        this.state = {
            product: null
        };

        const pathName = decodeURI(window.location.pathname);
        const prodName = pathName.substring(1, pathName.length);
        if (prodName.indexOf('/') === -1) {
            let filtered = props.allProducts.filter(b => b.title === prodName);
            if (filtered.length === 1) {
                this.state = {
                    product: filtered[0]
                };
            } else {
                this.state = {
                    product: null
                };
                GetProduct(null, prodName).then((product: TProduct) => {
                    console.log({product});
                    
                    this.setState({ product: ProcessProducts([product])[0] });
                });
            }
        } else {
            const parts = prodName.split('/');
            const type = parts[0];
            if (type === "Pokemon kaart") {
                //SV: 151 - Snorlax - Reverse Holofoil
                //console.log(parts[1]);
                let [setname, cardname, number, version] = (parts[1] + parts[2]).split(" - ");
                GetCard(setname, cardname, version).then(cards => {
                    cards.forEach(card => {
                        if (version === card.version) {
                            if (card.number > 99) {
                                card.scardnumber = `${card.number}`;
                            } else if (card.number > 9) {
                                card.scardnumber = `0${card.number}`;
                            } else {
                                card.scardnumber = `00${card.number}`;
                            }
                            card.scardnumber += `/${card.size}`;

                            //TODO: move to backend, minimale prijs toevoegen
                            card.priceInUSD = Math.round(card.priceInUSD * 100) / 100;
                            card.cost = Math.round(card.priceInUSD / this.props.SatPrice);
                            card.scost = `${card.cost}`;
                            let imageUrls: string[] = [];
                            card.imageNames.split(',').forEach(imageName => {
                                imageUrls.push(`${process.env.PUBLIC_URL}/assets/pokemon/${card.setID}/${card.number.toString()}/${imageName}`);
                            });
                            imageUrls.push(`${process.env.PUBLIC_URL}/assets/pokemon/cardback.webp`);
                            card.imageUrls = imageUrls;
                            this.setState({ product: card });
                        }
                    });
                }).catch(err => {
                    console.log(err);
                });
            }
        }
    }

    private onImageClick(url: string) {
        this.setState({ popupUrl: url });
    }

    private tryAddToChart(card: TProduct) {
        let done = false;
        if (card.available > 0) {
            done = this.props.onBuyClick(card);
        }
        if (done) {
            this.setState({ added: true });
            setTimeout(() => {
                this.setState({ added: false });
            }, 500);
        } else {
            GlobalProperties.ToastMessage = "Niet meer beschikbaar";
            this.setState({ notAdded: true });
            setTimeout(() => {
                this.setState({ notAdded: false });
            }, 500);
        }
    }

    SetSelectedSet = () => {
        this.props.SetSelectedSet(this.state.product.setID);
    }

    public render(): React.ReactElement<IProductViewerComponentProps> {
        return (
            this.state?.product &&
            <div className={`${styles.mainDiv} ${styles.sPanel}`} style={{ maxWidth: "100%", paddingLeft: 10, overflow: "hidden" }} >
                <Stack horizontalAlign='center' horizontal={window.innerWidth > 650 ? true : false}>
                    <Stack className={styles.sPanel} style={{ overflow: "hidden", marginRight: window.innerWidth > 650 ? 10 : 0}} 
                           horizontalAlign={window.innerWidth > 650 ? 'start' : 'center'}
                           onClick={ () => {this.props.onImageClick(this.state.product.imageUrls[0]); }} >
                        <Image className={styles.singleImage}
                            style={{ cursor: "default" }}
                            onError={(e) => {
                                e.currentTarget.onerror = null;
                                e.currentTarget.src = `${process.env.PUBLIC_URL}${this.state.product.imageUrls[0]}`
                            }}
                            src={`${process.env.PUBLIC_URL}${this.state.product.imageUrls[0]}`.replace(".webp", ".png")}
                        />
                    </Stack>
                    <Stack style={{ marginRight: 10 }} >
                        <div className={styles.divTable} style={{ width: "100%", whiteSpace: "nowrap" }}>
                            <div className={`${styles.divTable}, ${styles.sPanel}`} style={{ marginTop: 10 }}>
                                <div className={styles.divTableRow} >
                                    <div className={styles.divTableCell} style={{ verticalAlign: "middle" }}>
                                        {this.state.product.available === 0 ?
                                            <h3 style={{ margin: 0, color: "red" }}>Uitverkocht</h3>
                                            : this.state.product.available > 5 ?
                                                <h3 style={{ margin: 0, color: "rgb(70, 65, 65)" }}>Nog {">"} 5</h3>
                                                : <h3 style={{ margin: 0, color: "red" }}>Nog {"<"} 5</h3>}
                                    </div>
                                    <div className={styles.divTableCell} style={{ paddingLeft: 10, verticalAlign: "middle" }}>
                                        <Stack horizontal>
                                            <Stack style={{ paddingTop: 1, verticalAlign: "center" }}>
                                                <PulseLabelv2 style={{ color: 'rgb(70, 65, 65)' }} text={`€ ${this.state.product.priceInUSD === 0 ? "-" : parseFloat((this.state.product.priceInUSD * this.props.EurPrice).toString()).toFixed(2)}`} />
                                                <Stack horizontal>
                                                    <Image className={styles.btcicon}
                                                        style={{ cursor: "default" }}
                                                        src={`${process.env.PUBLIC_URL}/assets/btcicon.png`}
                                                    />
                                                    <PulseLabelv2 style={{ color: 'rgb(70, 65, 65)' }} text={`${this.state.product.priceInUSD === 0 ? "-" : Math.round(this.state.product.priceInUSD / this.props.SatPrice)} sats`} />
                                                </Stack>
                                            </Stack>
                                            <Stack horizontal className={styles.butButtonDiv} style={{ paddingLeft: 10, paddingTop: 2 }}>
                                                {this.state.product.priceInUSD != 0 && this.state.product.available > 0 ?
                                                    <FontIcon style={{ width: 100, height: "100%", fontSize: 25, backgroundColor: "#30a7d7" }}
                                                        aria-label={GlobalProperties.Strings.AddToCart}
                                                        onClick={() => { this.tryAddToChart(this.state.product); }}
                                                        className={`${styles.buyButtonSingle} ${this.state.added ? styles.added : this.state.notAdded ? styles.notAdded : ""}`}
                                                        iconName={this.state.added ? "ShoppingCartSolid" : "ShoppingCart"} />
                                                    :
                                                    <AlertButtonComponent Visible={this.props.AlertCoachmarkVisible} OnClick={() => { this.props.SetSelectedProductForAlert(this.state.product); }} />
                                                }
                                            </Stack>
                                        </Stack>
                                    </div>
                                    <div className={styles.divTableCell}>

                                    </div>
                                    <div className={styles.divTableCell} style={{ width: "100%" }}>

                                    </div>
                                </div>
                            </div>
                            <div className={styles.divTableRow}>
                                <div className={styles.divTableCell}>
                                    <Stack horizontal>
                                        <span style={{ fontSize: "x-large", marginTop: 10, color: "black", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{this.state.product.title}</span>
                                    </Stack>
                                </div>
                                <div className={styles.divTableCell} style={{ width: "100%" }}>

                                </div>
                                <div className={styles.divTableCell}>
                                </div>
                            </div>
                        </div>
                        <div className={styles.divTable} style={{ verticalAlign: 'start', width: "100%", height: "100%", whiteSpace: "nowrap"}}>
                            <div className={styles.divTable} style={{ paddingTop: 10 }}>
                                <div className={styles.divTableRow}>
                                    <div className={styles.divTableCell}>
                                        <span>Nummer</span>
                                    </div>
                                    <div className={styles.divTableCell} style={{ paddingLeft: 10 }}>

                                    </div>
                                    <div className={styles.divTableCell}>
                                        <span className={styles.generalspanSingle}>{this.state.product.scardnumber}</span>
                                    </div>
                                    <div className={styles.divTableCell} style={{ width: "100%" }}>

                                    </div>
                                </div>
                                <div className={styles.divTableRow}>
                                    <div className={styles.divTableCell}>
                                        <span>Zeldzaamheid</span>
                                    </div>
                                    <div className={styles.divTableCell} style={{ paddingLeft: 10 }}>

                                    </div>
                                    <div className={styles.divTableCell}>
                                        <span className={styles.generalspanSingle}>{this.state.product.rarety}</span>
                                    </div>
                                    <div className={styles.divTableCell} style={{ width: "100%" }}>

                                    </div>
                                </div>
                                <div className={styles.divTableRow}>
                                    <div className={styles.divTableCell}>
                                        <span>Versie</span>
                                    </div>
                                    <div className={styles.divTableCell} style={{ paddingLeft: 10 }}>

                                    </div>
                                    <div className={styles.divTableCell}>
                                        <span className={styles.generalspanSingle}>{this.state.product.version}</span>
                                    </div>
                                    <div className={styles.divTableCell} style={{ width: "100%" }}>

                                    </div>
                                </div>
                                <div className={styles.divTableRow}>
                                    <div className={styles.divTableCell}>
                                        <span className={styles.generalspanSingle}>{this.state.product.description}</span>
                                    </div>
                                    <div className={styles.divTableCell} style={{ paddingLeft: 10 }}>

                                    </div>
                                    <div className={styles.divTableCell}>
                                        <div className={styles.divTable} style={{ width: "max-content" }}>
                                            {this.state.product.tags?.map(tag => {
                                                return <div className={styles.divTableRow}>
                                                    <div className={styles.divTableCell}>
                                                        <Label><b>{tag.title}</b></Label>
                                                    </div>
                                                    <div className={styles.divTableCell}>
                                                        <Label>&nbsp;&nbsp;{tag.value}</Label>
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </div>
                                    <div className={styles.divTableCell} style={{ width: "100%" }}>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: "100%", paddingBottom: 5, paddingTop: 5 }}>
                            <Stack>
                                <NavLink to={"/Pokemon Kaarten/" + this.state.product.name}>
                                    <Stack className={styles.aPanel} onClick={this.SetSelectedSet} horizontalAlign='center' style={{ paddingBottom: 20 }}>
                                        <Image className={styles.setLogoMenuSingle} src={process.env.PUBLIC_URL + `/assets/pokemon/${this.state.product.setID.toString()}/${this.state.product.setID.toString()}.webp`} alt={this.state.product.name} />
                                        <Label style={{ textDecoration: "none" }}>Ga naar set</Label>
                                    </Stack>
                                </NavLink>
                            </Stack>
                        </div>
                    </Stack>
                </Stack>
            </div>
        );
    }
}

function Search(props) {
    return <ProductViewerComponent {...props} />
}

export default Search;