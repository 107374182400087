import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { mergeStyles } from '@fluentui/react';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/scrollToTop';

// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
  },
});

//ReactDOM.render(<App />, document.getElementById('root'));

ReactDOM.render((
    <BrowserRouter>
      <ScrollToTop />
      <App />
    </BrowserRouter>
    ), document.getElementById('root')
  );
