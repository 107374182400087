import { TBTCAddress, TInvoice, TSale } from "../constants/types";

export function  GetInvoice(isDev : boolean = false, sats: number) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }
    return fetch(`${hostUrl}/GetInvoice?sats=${sats}`, {
        method: 'GET',
    }).then((response) => response.json()).then((data) => data as TInvoice);
}

export function  CheckInvoice(isDev : boolean = false, id: string) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }
    return fetch(`${hostUrl}/CheckInvoice?ID=${id}`, {
        method: 'GET',
    }).then((response) => response.json()).then((data) => data as TInvoice);
}

export function  CheckInvoiceV3(isDev : boolean = false, id: string) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }
    return fetch(`${hostUrl}/CheckKrakenLightningInvoice?address=${id}`, {
        method: 'GET',
    }).then((response) => response.json()).then((data) => data as boolean);
}

export function  GetInvoiceV3(isDev : boolean = false, data: TSale) {
    let hostUrl = "https://pokemoncollectie.nl/Backend";
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }
    return fetch(`${hostUrl}/GetKrakenLightningInvoice`, {
        method: "Post", 
        mode: "cors", 
        headers: {
         "Accept": "application/json",
         "Content-Type": "application/json",
         "Accept-Encoding": "gzip, deflate ,br"
        },
        referrerPolicy: "origin-when-cross-origin", 
        body: JSON.stringify(data), 
    }).then((response) => response.json()).then((data) => data as TInvoice);
}

export function  GetInvoiceV2(isDev : boolean = false, data: TSale) {
    let hostUrl = "https://pokemoncollectie.nl/Backend";
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }
    return fetch(`${hostUrl}/PostInvoiceV2`, {
        method: "Post", 
        mode: "cors", 
        headers: {
         "Accept": "application/json",
         "Content-Type": "application/json",
         "Accept-Encoding": "gzip, deflate ,br"
        },
        referrerPolicy: "origin-when-cross-origin", 
        body: JSON.stringify(data), 
    }).then((response) => response.json()).then((data) => data as TInvoice);
}

export function  GetBTCOnchain(isDev : boolean = false, id: string) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }
    return fetch(`${hostUrl}/GetBTCOnchainAddress?ID=${id}`, {
        method: 'GET',
    }).then((response) => response.json()).then((data) => data as TBTCAddress);
}

export function CheckIDealPayment(isDev: boolean = false, id: string) {
    let hostUrl = "https://pokemoncollectie.nl/Backend"
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }
    return fetch(`${hostUrl}/MollieCheckPayment?paymentid=${id}`, {
        method: 'GET',
    }).then((response) => response.json()).then((data) => data);
}

export function GetIDealPayment(isDev: boolean = false, data: TSale) {
    let hostUrl = "https://pokemoncollectie.nl/Backend";
    if (isDev) {
        hostUrl = "https://localhost:7254";
    }
    return fetch(`${hostUrl}/MolliePayment`, {
        method: "Post", 
        mode: "cors", 
        headers: {
         "Accept": "application/json",
         "Content-Type": "application/json",
         "Accept-Encoding": "gzip, deflate ,br"
        },
        referrerPolicy: "origin-when-cross-origin", 
        body: JSON.stringify(data), 
    }).then((response) => response.json()).then((rtn) => rtn);
}