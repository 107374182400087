import { Stack, Image, ImageFit, Label, PrimaryButton, DefaultButton, FontIcon } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';
import { TProduct, TSize } from '../constants/types';
import PulseLabel from './pulseLabelComponent';
import GlobalProperties from '../helpers/GlobalProperties';
import { AlertButtonComponent } from './alertButtonComponent';

export interface IPokemonCardsProps {
    card: TProduct;
    onBuyClick: Function;
    satprice: number;
    onImageClick: Function;
    animationsEnabled: boolean;
    eurprice: number;
    hideSwipe: boolean;
    SetSelectedProductForAlert?: Function;
    AlertCoachmarkVisible: boolean;
}

export interface IPokemonCardsState {
    selectedImageIdex: number;
    added: boolean;
    notAdded: boolean;
    startSwipe: number;
    endSwipe: number;
    swipeHidden: boolean;
};

export default class PokemonCards extends React.Component<IPokemonCardsProps, IPokemonCardsState> {
    constructor(props: IPokemonCardsProps) {
        super(props);

        this.UpdateComponent = this.UpdateComponent.bind(this);
        this.tryAddToChart = this.tryAddToChart.bind(this);
        this.swipeTimeout = this.swipeTimeout.bind(this);
        this.previousImage = this.previousImage.bind(this);
        this.nextImage = this.nextImage.bind(this);

        this.state = {
            selectedImageIdex: 0,
            added: false,
            notAdded: false,
            startSwipe: -1,
            endSwipe: -1,
            swipeHidden: this.props.hideSwipe,
        };
    }

    private UpdateComponent() {
        let imageUrls : string[] = [];
        imageUrls.push(`${process.env.PUBLIC_URL}/assets/pokemon/cardback.webp`);

        this.setState( {
            selectedImageIdex: 0
        });
    }

    componentDidMount(): void {
        this.UpdateComponent();
        if (this.props.hideSwipe === false) {
            this.swipeTimeout();
        }
    }

    swipeTimeout() {
        setTimeout(() => {
            this.setState({swipeHidden: true});
                setTimeout(() => {
                    this.setState({swipeHidden: false});
                    this.swipeTimeout();
                }, 60000);
        }, 2000);
    }

    componentDidUpdate(prevProps: Readonly<IPokemonCardsProps>, prevState: Readonly<IPokemonCardsState>, snapshot?: any): void {
        if (prevProps.card.id != this.props.card.id) {
            this.UpdateComponent();
        }
    }

    private tryAddToChart(card:TProduct) {
        let done = false;
        if (card.available > 0) {
            done = this.props.onBuyClick(card); 
        }
        if (done) {
            this.setState({added:true});
            setTimeout(() => {
                this.setState({added:false});
            }, 500);
        } else {
            GlobalProperties.ToastMessage = "Niet meer beschikbaar";
            this.setState({notAdded:true});
            setTimeout(() => {
                this.setState({notAdded:false});
            }, 500);
        }
    }

    swipeEnd(e) {
        if (this.state.startSwipe != -1 && this.state.endSwipe != -1) {
            if ((this.state.endSwipe - this.state.startSwipe) > 20) {
                this.state.selectedImageIdex > 0 ? this.setState({selectedImageIdex : this.state.selectedImageIdex-1}) : this.setState({selectedImageIdex : this.props.card.imageUrls.length -1});
            } else if ((this.state.startSwipe - this.state.endSwipe) > 20) {
                this.state.selectedImageIdex+1 < this.props.card.imageUrls.length ? this.setState({selectedImageIdex : this.state.selectedImageIdex+1}) : this.setState({selectedImageIdex : 0});
            }
            this.setState({startSwipe: -1, endSwipe: -1});
        }
    }

    private nextImage() {
        let newIndex = this.state.selectedImageIdex + 1;
        if (newIndex < this.props.card.imageUrls.length) {
            this.setState({selectedImageIdex : newIndex});
        } else {
            this.setState({selectedImageIdex : 0});
            newIndex = 0;
        }
        this.props.onImageClick(this.props.card.imageUrls[newIndex], this.nextImage, this.previousImage);
    }

    private previousImage() {
        let newIndex = this.state.selectedImageIdex - 1;
        if (this.state.selectedImageIdex > 0) {
            this.setState({selectedImageIdex : this.state.selectedImageIdex - 1});
        } else {
            this.setState({selectedImageIdex : this.props.card.imageUrls.length -1});
            newIndex = this.props.card.imageUrls.length -1;
        }
        this.props.onImageClick(this.props.card.imageUrls[newIndex], this.nextImage, this.previousImage);
    }

    public render(): React.ReactElement<IPokemonCardsProps> {
        const card = this.props.card;
        let shimmerStyle = "";
        if (this.props.animationsEnabled && card.rarety !== "Common" && card.rarety !== "Uncommon") {
            const r = Math.floor(Math.random() * 4);
            shimmerStyle = styles[`cardshimmer${r}`]
        }
        return (
            <Stack className={styles.card}>
                <Stack>
                    <Stack horizontal>
                        <div className={`${styles.cardTitleContainer}`}>
                            <Stack horizontal horizontalAlign='space-between'>
                                <Stack horizontal horizontalAlign='start'>
                                </Stack>
                                <Stack horizontal horizontalAlign='end' className={styles.cardHeaderBanner}>
                                    {card.available === 0 && <Image {...{imageFit: ImageFit.centerContain,
                                            className: styles.soldOut,
                                            src: process.env.PUBLIC_URL + `/assets/soldout.webp`,
                                            styles: props => ({ root: { margin: 0, marginTop:-15, zIndex: 1} }),
                                        }} alt="" />}
                                </Stack>
                            </Stack>
                        </div>
                    </Stack>
                    <Stack horizontal verticalAlign='center' horizontalAlign='center' style={{overflow:"hidden"}} onClick={ () => {this.props.onImageClick(card.imageUrls[this.state.selectedImageIdex], 
                                                                                                                                   this.nextImage.bind(this), 
                                                                                                                                   this.previousImage.bind(this)); }}>
                        <div className={`${styles.cardshine} ${styles.cardCompImg}`}>
                            <Image {...{imageFit: ImageFit.centerContain,
                                        className: styles.cardCompImg,
                                        src: card.imageUrls[this.state.selectedImageIdex],
                                        onTouchStart: (e) => { this.setState({startSwipe: e.targetTouches[0].clientX}); },
                                        onTouchMove: (e) => { this.setState({endSwipe: e.targetTouches[0].clientX}); },
                                        onTouchEnd: this.swipeEnd.bind(this),
                                        onError: ( e ) => { e.currentTarget.onerror = null; 
                                                            e.currentTarget.src=`${process.env.PUBLIC_URL}/assets/NoImgFound.webp`},
                                        styles: props => ({ root: { margin: 0, cursor: "pointer", backgroundColor: "transparent" } }),
                                    }} alt="" />
                            <div className={shimmerStyle}></div>
                        </div>
                        {card.imageUrls.length > 1 && //only show swipe when there are multiple images
                            <Image {...{imageFit: ImageFit.centerContain,
                                            className: styles.swipe,
                                            width: 80,
                                            height: 80,
                                            src: process.env.PUBLIC_URL + `/assets/swipe.gif`,
                                            styles: props => ({ root: { margin: 0, zIndex: 1, visibility:this.state.swipeHidden ? "hidden" : "visible" } }),
                                        }} alt="" />}
                    </Stack>
                    {this.props.hideSwipe &&
                    <Stack horizontalAlign='center' horizontal verticalAlign='center'>
                        <FontIcon aria-label="Vorige" className={styles.prevNextIcon} onClick={() => {this.state.selectedImageIdex > 0 ? this.setState({selectedImageIdex : this.state.selectedImageIdex-1}) : this.setState({selectedImageIdex : card.imageUrls.length -1})}} iconName="ChevronLeft" />
                        {this.state.selectedImageIdex+1} / {card.imageUrls.length} 
                        <FontIcon aria-label="Volgende" className={styles.prevNextIcon} onClick={() => {this.state.selectedImageIdex+1 < card.imageUrls.length ? this.setState({selectedImageIdex : this.state.selectedImageIdex+1}) : this.setState({selectedImageIdex : 0})}} iconName="ChevronRight" />
                    </Stack>}
                    <Stack className={styles.cardInfo}>
                        <Stack horizontal horizontalAlign='center'>
                            <Stack horizontal horizontalAlign='center' style={{maxWidth:"100%", display:"inline-block"}}>
                                <Label className={`${styles.cardnameLabel} ${styles.rarityLabel} ${styles.generalLabel}`}>{card.title}</Label>
                            </Stack>
                        </Stack>
                        <Stack horizontal>
                            <Stack horizontal horizontalAlign='start' style={{maxWidth:"100%", display:"inline-block"}}>
                                <Label className={`${styles.rarityLabel} ${styles.generalLabel}`}>{card.rarety}</Label>
                            </Stack>
                            <Stack horizontal horizontalAlign='end'>
                                <Label className={styles.generalLabel}>{card.scardnumber}</Label>
                            </Stack>
                        </Stack>
                        <hr className={styles.cardInfohr} />
                        <Stack horizontal horizontalAlign='space-between'>
                            <Stack horizontal horizontalAlign='end'>
                                <Label className={styles.generalLabel}>{GlobalProperties.Strings.Version}:</Label>
                            </Stack>
                            <Stack horizontal horizontalAlign='end'>
                                <Label className={styles.generalLabel}>{card.version}</Label>
                            </Stack>
                        </Stack>
                        <Stack horizontal horizontalAlign='space-between'>
                            <Stack horizontal horizontalAlign='end'>
                                <Label className={styles.generalLabel}>Set:</Label>
                            </Stack>
                            <Stack horizontal horizontalAlign='end'>
                                <Label className={styles.generalLabel}>{card.name}</Label>
                            </Stack>
                        </Stack>
                        {/* <Stack horizontal horizontalAlign='space-between'>
                            <Stack horizontalAlign='start'>
                                <Label className={styles.generalLabel}>{GlobalProperties.Strings.Available}: </Label>
                            </Stack>
                            <Stack horizontalAlign='start' horizontal>
                                <Label className={styles.generalLabel}>{card.available}</Label>
                            </Stack>
                        </Stack> */}
                        <hr className={styles.cardInfohr} />
                        <Stack horizontal horizontalAlign='space-between'>
                            <Stack>
                                <PulseLabel style={{fontSize:"small!important",fontWeight:"bold"}} text={`€ ${card.priceInUSD === 0 ? "-" : parseFloat((card.priceInUSD * this.props.eurprice).toString()).toFixed(2)}`} />
                                <Stack horizontal>
                                    <Image className={styles.btciconsmall}
                                        style={{ cursor: "default" }}
                                        src={`${process.env.PUBLIC_URL}/assets/btcicon.png`}
                                    />
                                    <PulseLabel style={{fontSize:"xx-small!important",whiteSpace:"nowrap"}} text={`${card.priceInUSD === 0 ? "-" : Math.round(card.priceInUSD / this.props.satprice)} sats`} />
                                </Stack>
                            </Stack>
                            <Stack horizontal horizontalAlign='end' className={styles.butButtonDiv}>
                                {card.priceInUSD != 0 && card.available > 0 ?
                                    <FontIcon style={{width:90, maxHeight:"90%", verticalAlign:"middle", fontSize:25, backgroundColor:"#30a7d7"}} 
                                              aria-label={GlobalProperties.Strings.AddToCart} onClick={() => {this.tryAddToChart(card);}} 
                                              className={`${styles.buyButton} ${this.state.added ? styles.added : this.state.notAdded ? styles.notAdded : ""}`} 
                                              iconName={this.state.added ? "ShoppingCartSolid" : "ShoppingCart"} />
                                    :
                                    <AlertButtonComponent Visible={this.props.AlertCoachmarkVisible} OnClick={() => { this.props.SetSelectedProductForAlert(card); }} />
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack> 
        );
    }
}