import { Image, Label, FontIcon, Stack } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';
import GlobalProperties from '../helpers/GlobalProperties';

export interface IHeaderInfoComponentProps {
}

export interface IHeaderInfoComponentState {

};

export default class HeaderInfoComponent extends React.Component<IHeaderInfoComponentProps, IHeaderInfoComponentState> {

    constructor(props: IHeaderInfoComponentProps) {
        super(props);
    }

    public render(): React.ReactElement<IHeaderInfoComponentProps> {
        return (
            <div className={`${styles.divTable} ${styles.headerInfoMain}`}>
                <div className={styles.divTableRow}>
                    <div className={styles.divTableCell} style={{width:25}}>
                        <div className={styles.headerInfoImg}></div>
                    </div>
                    <div className={styles.divTableCell}>
                        <Label className={styles.headerInfoLabel}>{GlobalProperties.Strings.CardsSleeved}</Label>
                    </div>
                </div>
                {/* <div className={styles.divTableRow}>
                    <div className={styles.divTableCell} style={{width:40}}>
                        &nbsp;<FontIcon aria-label="Fotos" iconName="PhotoCollection" className={styles.headerInfoIcon} />
                    </div>
                    <div className={styles.divTableCell}>
                        <Label className={styles.headerInfoLabel}>{GlobalProperties.Strings.PicturesOnrequest}</Label>
                    </div>
                </div> */}
                <div className={styles.divTableRow}>
                    <div className={styles.divTableCell} style={{width:25}}>
                        &nbsp;<FontIcon aria-label="Track en Trace" iconName="ExerciseTracker" className={styles.headerInfoIcon} />
                    </div>
                    <div className={styles.divTableCell}>
                        <Label className={styles.headerInfoLabel}>{GlobalProperties.Strings.TT}</Label>
                    </div>
                </div>
                <div className={styles.divTableRow}>
                    <div className={styles.divTableCell} style={{width:25}}>
                    &nbsp;<FontIcon aria-label="Betaling" iconName="PaymentCard" className={styles.headerInfoIcon} />
                    </div>
                    <div className={styles.divTableCell}>
                        <Label className={styles.headerInfoLabel}>{GlobalProperties.Strings.PaymentMethods}</Label>
                    </div>
                </div>
                <div className={styles.divTableRow}>
                    <div className={styles.divTableCell} style={{width:25}}>
                        <div className={styles.headerInfoImgFree}></div>
                    </div>
                    <div className={styles.divTableCell}>
                        <Label className={styles.headerInfoLabel}>{GlobalProperties.Strings.BonusCards}</Label>
                    </div>
                </div>
            </div>
        );
    }
}