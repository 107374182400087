import { Callout, ComboBox, IComboBoxOption, IconButton, Label, Stack } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';
import GlobalProperties from '../helpers/GlobalProperties';
import { English, Nederlands } from '../constants/languages/lang';
import { useBoolean, useId } from '@fluentui/react-hooks';
import { TSize } from '../constants/types';

type Props = {
}

export const LanguageComponent : React.FunctionComponent<Props> = (props) => {
  const [isCalloutVisible, { toggle: toggleIsCalloutVisible }] = useBoolean(false);
  const buttonId = useId('callout-lang');

  return (
    <>
      <div className={`${GlobalProperties.Strings.Cards === "Cards" ? styles.eng : styles.nl} ${styles.langComponent}`}
           id={buttonId}
           onMouseEnter={() => { !isCalloutVisible && toggleIsCalloutVisible(); }}
           onTouchStart={() => { !isCalloutVisible && toggleIsCalloutVisible(); }}
           onClick={()=>{ GlobalProperties.Strings = Nederlands; 
                          GlobalProperties.Refresh(); }}>
      </div>
      {isCalloutVisible && (
        <Callout
          style={{padding:10}}
          role="dialog"
          gapSpace={0}
          target={`#${buttonId}`}
          onMouseLeave={toggleIsCalloutVisible}
          onDismiss={() => { toggleIsCalloutVisible(); }}
          onMouseUp={() => { setTimeout(() => {
            toggleIsCalloutVisible();
          }, 100);  }}
          setInitialFocus
        >
          <div>
            <Stack horizontal className={styles.aPanel}>
              <div className={styles.nl} onClick={()=>{GlobalProperties.Strings = Nederlands; GlobalProperties.Refresh(); }}></div> 
              <Label style={{marginTop:-3, marginLeft:5, cursor:"pointer" }} onClick={()=>{GlobalProperties.Strings = Nederlands; GlobalProperties.Refresh(); }}>Nederlands</Label>
            </Stack>
            <Stack horizontal style={{marginTop:5}} className={styles.aPanel}>
              <div className={styles.eng} onClick={()=>{GlobalProperties.Strings = English; GlobalProperties.Refresh(); }}></div>
              <Label style={{marginTop:-3, marginLeft:5, cursor:"pointer"}} onClick={()=>{GlobalProperties.Strings = English; GlobalProperties.Refresh(); }}>English</Label>
            </Stack>
          </div>
        </Callout>
      )}
    </>
  );
};