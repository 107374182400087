import { Stack, Image, PrimaryButton, FontIcon, TextField, DefaultButton, Dialog, DialogType, Label, Rating, RatingSize, IStackTokens, ImageFit } from '@fluentui/react';
import React, { FormEvent } from 'react';
import styles from '../styles/pokemon.module.scss';
import BTCPriceComponent from './btcPriceComponent';
import { Nederlands } from '../constants/languages/lang';
import GlobalProperties from '../helpers/GlobalProperties';
import { LanguageComponent } from './languageComponent';

export interface IHeaderComponentProps {
    btcprice?: number;
    eurprice?: number;
}

export interface IHeaderComponentState {
};
  

export default class Header extends React.Component<IHeaderComponentProps, IHeaderComponentState> {
    private cardTokens: IStackTokens = {
        childrenGap: 10,
        padding: 5
    };
    
    constructor(props: IHeaderComponentProps) {
        super(props);
    }

    public render(): React.ReactElement<IHeaderComponentProps> {
        return (   
            <div className={`${styles.headerMain}`}>
                <Stack horizontal horizontalAlign='center'>
                    <Image onClick={() => {window.location.href = '/'}} className={styles.mainLogo} src={`${process.env.PUBLIC_URL}/assets/pokemon kaarten.webp`} alt="" imageFit={ImageFit.contain} />  
                    {this.props.btcprice && this.props.eurprice && <LanguageComponent />}
                    {this.props.btcprice && this.props.eurprice && <BTCPriceComponent btcprice={this.props.btcprice} eurprice={this.props.eurprice} />}
                </Stack>
                {/* <Stack horizontal tokens={this.cardTokens}>
                    <Label className={styles.headerText}>{GlobalProperties.Strings.HeaderText}</Label>
                </Stack> */}
            </div>
        );
    }
}