import { Stack, Image, FontIcon, Label, ImageFit, PrimaryButton, Dialog, TextField, DialogType, DefaultButton } from '@fluentui/react';
import React from 'react';
import styles from '../styles/pokemon.module.scss';
import { TProduct } from '../constants/types';
import { BooleanLiteral } from 'typescript';
import GlobalProperties from '../helpers/GlobalProperties';

export interface IEmailAlertComponentProps {
    card: TProduct;
    userEmail: string;
    callBack: Function;
}

export interface IEmailAlertComponentState {
    hidden: boolean;
    email: string;
};
  

export default class EmailAlertComponent extends React.Component<IEmailAlertComponentProps, IEmailAlertComponentState> {
    constructor(props: IEmailAlertComponentProps) {
        super(props);
        this.state = {
            hidden: false,
            email: props.userEmail
        };
    }

    private validation() : boolean {
        let rtnVal = false;
        let reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (!this.state.email || reg.test(this.state.email) === false) {
            rtnVal = true;
        }
        return rtnVal;
    }

    public render(): React.ReactElement<IEmailAlertComponentProps> {
        return (   
            <Dialog
                title={GlobalProperties.Strings.AlertTitle}
                hidden={this.state.hidden}
                dialogContentProps={{type:DialogType.normal}}
                onDismiss={() => {this.setState({hidden : true});}}
            >
                <Stack horizontalAlign='center'>
                    <Stack horizontal verticalAlign='center'>
                        <TextField label="e-mail" type={"email"} placeholder={"voorbeeld@mail.com"} value={this.state.email} onChange={(e,v) => {this.setState({email: v});}}></TextField>
                    </Stack>
                    <br/>
                    {this.props.card?.imageUrls.length && 
                            <Image {...{imageFit: ImageFit.centerContain,
                                className: styles.cardCompImg,
                                src: this.props.card.imageUrls[0],
                                onError: ( e ) => { e.currentTarget.onerror = null; 
                                                    e.currentTarget.src=`${process.env.PUBLIC_URL}/assets/NoImgFound.webp`},
                                styles: props => ({ root: { margin: 0, cursor: "pointer", backgroundColor: "transparent" } }),
                            }} alt="" />}
                    <br/>
                    <Stack horizontal>
                        <DefaultButton text={GlobalProperties.Strings.Cancel} onClick={()=>{this.props.callBack(this.state.email);}} />
                        &nbsp;
                        <PrimaryButton disabled={this.validation()} text={GlobalProperties.Strings.NotifyMe} onClick={()=>{this.props.callBack(this.state.email, this.props.card);}} />
                    </Stack>
                </Stack>
            </Dialog>
        );
    }
}